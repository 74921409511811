import { gql } from "@apollo/client";
import client from "../utils/gqlclient";
import _ from "lodash";

/**
 * Model for cost form.
 * @param {object} props - Function properties.
 * @param {object} props.id - Opportinity id.
 * @param {boolean} props.isComplete - Tells BE to get all fields.
 * @param {Array} props.slugs - Current form state.
 * @returns {Promise<object>}
 */
export async function getOpportunitiesCosts({
  uuid = "",
  isComplete = false,
  slugs = [],
}) {
  if (!_.isEmpty(uuid)) {
    const data = await client.query({
      query: gqlGetCosts,
      variables: {
        uuid,
      },
    });

    return data?.data?.getCosts;
  }

  const data = await Promise.resolve({
    data: {
      getCosts: {
        toDeposit: {
          amount: "90.00 €",
          title: "20% al contado - 80% CAD",
        },
        toPercive: {
          amount: "100.00 €",
          title: "20% al contado - 80% CAD",
        },
        statusCost: {
          logistic: true,
          finance: false,
          isAccepted: true,
        },
        netMargin: "-10.00 €",
        costs: [
          {
            optional: false,
            slug: "transport",
            value: null,
            editable: true,
            active: false,
            name: "Transporte",
            unit: "€",
          },
          {
            optional: false,
            slug: "exchange",
            value: null,
            editable: false,
            active: false,
            name: "Exchange",
          },
          {
            optional: false,
            slug: "fee",
            value: 1.8,
            editable: false,
            active: false,
            name: "Fee",
          },
          {
            optional: true,
            slug: "customFee",
            value: null,
            editable: true,
            active: false,
            name: "Custom Fee",
          },
          {
            optional: true,
            slug: "psic",
            value: null,
            editable: false,
            active: false,
            name: "PSIC",
          },
          {
            optional: true,
            slug: "insurance",
            value: null,
            editable: false,
            active: false,
            name: "Insurance",
          },
          {
            optional: false,
            slug: "inspection",
            value: null,
            editable: true,
            active: false,
            name: "Inspección",
          },
          {
            optional: false,
            slug: "cad",
            value: 30,
            editable: false,
            active: false,
            name: "CAD",
          },
          {
            optional: false,
            slug: "hedge_currency",
            value: null,
            editable: true,
            active: false,
            name: "Hedge Divisa",
          },
          {
            optional: true,
            slug: "hedge_lme",
            value: null,
            editable: true,
            active: false,
            name: "Hedge LME",
          },
          {
            optional: true,
            slug: "financing",
            value: null,
            editable: true,
            active: false,
            name: "Financiación",
          },
        ],
        statusCosts: {
          logistic: false,
          finance: false,
          isAccepted: false,
        },
      },
    },
  });
  return data.errors ? { errors: data.errors } : data.data.getCosts;
}

export async function getOpportunitiesCostsForTransaction({
  uuid = "",
  isComplete = false,
  slugs = [],
}) {
  if (!_.isEmpty(uuid)) {
    const data = await client.query({
      query: gqlGetCostsForTransaction,
      variables: {
        uuid,
      },
    });

    return data?.data?.getCostsForTransaction;
  }

  const data = await Promise.resolve({
    data: {
      getCosts: {
        toDeposit: {
          amount: "90.00 €",
          title: "20% al contado - 80% CAD",
        },
        toPercive: {
          amount: "100.00 €",
          title: "20% al contado - 80% CAD",
        },
        netMargin: "-10.00 €",
        costs: [
          {
            optional: false,
            slug: "transport",
            value: null,
            editable: true,
            active: false,
            name: "Transporte",
          },
          {
            optional: false,
            slug: "exchange",
            value: null,
            editable: false,
            active: false,
            name: "Exchange",
          },
          {
            optional: false,
            slug: "fee",
            value: 1.8,
            editable: false,
            active: false,
            name: "Fee",
          },
          {
            optional: true,
            slug: "customFee",
            value: null,
            editable: true,
            active: false,
            name: "Custom Fee",
          },
          {
            optional: true,
            slug: "psic",
            value: null,
            editable: false,
            active: false,
            name: "PSIC",
          },
          {
            optional: true,
            slug: "insurance",
            value: null,
            editable: false,
            active: false,
            name: "Insurance",
          },
          {
            optional: false,
            slug: "inspection",
            value: null,
            editable: true,
            active: false,
            name: "Inspección",
          },
          {
            optional: false,
            slug: "cad",
            value: 30,
            editable: false,
            active: false,
            name: "CAD",
          },
          {
            optional: false,
            slug: "hedge_currency",
            value: null,
            editable: true,
            active: false,
            name: "Hedge Divisa",
          },
          {
            optional: true,
            slug: "hedge_lme",
            value: null,
            editable: true,
            active: false,
            name: "Hedge LME",
          },
          {
            optional: true,
            slug: "financing",
            value: null,
            editable: true,
            active: false,
            name: "Financiación",
          },
        ],
        statusCosts: {
          logistic: false,
          finance: false,
          isAccepted: false,
        },
      },
    },
  });
  return data.errors ? { errors: data.errors } : data.data.getCosts;
}

export async function getOpportunity({ locale, uuid, edit = false }) {
  if (uuid) {
    const data = await client.query({
      query: gqlGetOpportunity,
      variables: {
        lang: locale,
        uuid,
      },
    });

    if (edit) {
      const dataForEdit = {
        slug: "UPDATE_OPPORTUNITY",
        uuidUser: undefined,
        opportunityOrg: data?.data?.getOpportunity?.uuidOrg,
        orgName: data?.data?.getOpportunity?.orgName,
        selectedAddress: {
          uuidFavourite: data?.data?.getOpportunity?.address?.id,
          type: data?.data?.getOpportunity?.address?.type,
          port: data?.data?.getOpportunity?.address?.port?.uuid
            ? data?.data?.getOpportunity?.address?.port?.uuid
            : data?.data?.getOpportunity?.address?.port,
        },
        title: data?.data?.getOpportunity?.title,
        opportunityType: data?.data?.getOpportunity?.opportunityType,
        opportunityTypeDesc: data?.data?.getOpportunity?.opportunityTypeDesc,
        material: data?.data?.getOpportunity?.material,
        amount: parseFloat(data?.data?.getOpportunity?.amount || "0.0"),
        proposedPrice: parseFloat(data?.data?.getOpportunity?.price || "0.0"),
        currency: data?.data?.getOpportunity?.currency,
        paymentMethod: data?.data?.getOpportunity?.paymentMethod,
        specification: data?.data?.getOpportunity?.specifications,
        incoterm: data?.data?.getOpportunity?.incoterm,
        files: data?.data?.getOpportunity?.files,
        canPublish: data?.data?.getOpportunity?.canPublish,
      };
      return dataForEdit;
    }

    return {
      ...data?.data?.getOpportunity,
      ...{
        slug: "UPDATE_OPPORTUNITY",
      },
    };
  }

  const data = await Promise.resolve({
    data: {
      getOpportunity: {
        slug: "NEW_OPPORTUNITY",
        uuidUser: undefined,
        opportunityOrg: undefined,
        selectedAddress: {
          uuidFavourite: undefined,
          type: undefined,
          port: undefined,
        },
        title: undefined,
        opportunityType: undefined,
        material: {},
        amount: undefined,
        proposedPrice: undefined,
        currency: "EUR",
        paymentMethod: undefined,
        specification: undefined,
        incoterm: undefined,
        files: [],
        canPublish: false,
      },
    },
  });
  return data.data.getOpportunity;
}

const gqlGetOpportunity = gql`
  query ($lang: String!, $uuid: String!) {
    getOpportunity(lang: $lang, uuidOpportunity: $uuid) {
      uuidOrg
      orgName
      address {
        city
        countryCode
        country
        street
        state
        postalCode
        id
        type
        port {
          uuid
          name
        }
      }
      title
      opportunityType
      opportunityTypeDesc
      material {
        name
        uuid
      }
      amount
      price
      currency
      paymentMethod {
        name
        uuid
      }
      specifications
      files {
        location
        name
        uuid
      }
      incoterm {
        name
        uuid
      }
      updatedDate
      boUser {
        uuid
        name
      }
      canPublish
    }
  }
`;

export async function getOpportunityOffers({ locale, uuid }) {
  if (uuid) {
    const data = await client.query({
      query: gqlGetOpportunityOfferList,
      variables: {
        lang: locale,
        uuid,
      },
    });

    return data?.data?.getOpportunityOffer;
  }

  const data = await Promise.resolve({
    data: {
      getOpportunityOffers: {
        offers: [
          {
            uuid: "124124",
            title: "Oferta ID1",
            state: "accepted",
            amount: "25t",
            price: "7920",
            currency: "EUR",
            orgUuid: "",
            orgName: "Scrappers",
            incoterm: null,
            paymentMethod: null,
            updatedDate: Date.now(),
            address: {
              city: "Madrid",
              countryCode: 724,
              country: "España",
              street: "Fake st 3",
              state: "Madrid",
              postalCode: "28400",
              id: "123",
              port: undefined,
            },
            specifications: "Podéis ver los docs adjuntos",
            files: [
              {
                location: "https://picsum.photos/200",
                name: "https://picsum.photos/200",
                uuid: "1",
              },
            ],
          },
          {
            uuid: "124124325235",
            title: "Oferta ID2",
            state: "accepted",
            amount: "25t",
            price: "7920",
            currency: "EUR",
            orgUuid: "",
            orgName: "Scrappers",
            updatedDate: Date.now(),
            incoterm: "DDP",
            paymentMethod: "100% al contado",
            address: {
              city: "Madrid",
              countryCode: 724,
              country: "España",
              street: "Fake st 3",
              state: "Madrid",
              postalCode: "28400",
              id: "123",
              port: undefined,
            },
            specifications: "Podéis ver los docs adjuntos",
            files: [
              {
                location: "https://picsum.photos/200",
                name: "https://picsum.photos/200",
                uuid: "1",
              },
            ],
          },
        ],
      },
    },
  });
  return data.data.getOpportunityOffers;
}

export async function getOpportunityOfferDetail({
  locale,
  uuid,
  opportunityId,
  mock,
  edit,
}) {
  if (mock) {
    return {
      uuid: "124124325235",
      title: "Oferta ID2",
      state: "accepted",
      amount: "25t",
      price: "7920",
      currency: "EUR",
      orgUuid: "",
      orgName: "Scrappers",
      updatedDate: Date.now(),
      incoterm: "DDP",
      paymentMethod: "100% al contado",
      address: {
        city: "Madrid",
        countryCode: 724,
        country: "España",
        street: "Fake st 3",
        state: "Madrid",
        postalCode: "28400",
        id: "123",
        port: undefined,
      },
      specifications: "Podéis ver los docs adjuntos",
      files: [
        {
          location: "https://picsum.photos/200",
          name: "https://picsum.photos/200",
          uuid: "1",
        },
      ],
    };
  }

  if (uuid) {
    const data = await client.query({
      query: gqlGetOpportunityOfferDetail,
      variables: {
        lang: locale,
        uuid,
      },
    });

    if (edit) {
      return {
        slug: "UPDATE_OFFER_OPPORTUNITY",
        uuidUser: undefined,
        //uuidEvent: opportunityId,
        //uuidOfferOpportunity: uuid,
        uuidEvent: uuid,
        interestedOrg: data?.data?.getOpportunityOfferDetail?.uuidOrg,
        orgName: data?.data?.getOpportunityOfferDetail?.orgName,
        specification: data?.data?.getOpportunityOfferDetail?.specifications,
        proposedPrice: data?.data?.getOpportunityOfferDetail?.price * 1,
        currency: data?.data?.getOpportunityOfferDetail?.currency,
        amount: data?.data?.getOpportunityOfferDetail?.amount * 1,
        incoterm: data?.data?.getOpportunityOfferDetail?.incoterm,
        paymentMethod: data?.data?.getOpportunityOfferDetail?.paymentMethod,
        selectedAddress: {
          uuidFavourite: data?.data?.getOpportunityOfferDetail?.address?.id,
          port: data?.data?.getOpportunityOfferDetail?.address?.port,
        },
        files: data?.data?.getOpportunityOfferDetail?.files || [],
      };
    }

    return data?.data?.getOpportunityOfferDetail;
  }

  return {
    slug: "CREATE_OFFER_OPPORTUNITY",
    uuidUser: undefined,
    uuidEvent: opportunityId,
    //uuidOfferOpportunity: undefined,
    interestedOrg: undefined,
    specification: undefined,
    proposedPrice: undefined,
    currency: "EUR",
    amount: undefined,
    incoterm: undefined,
    paymentMethod: undefined,
    selectedAddress: {
      uuidFavourite: undefined,
      port: undefined,
    },
    files: [],
  };
}

const gqlGetOpportunityOfferDetail = gql`
  query ($lang: String!, $uuid: String!) {
    getOpportunityOfferDetail(lang: $lang, uuidOffer: $uuid) {
      uuidOffer
      uuidOrg
      orgName
      status
      address {
        city
        countryCode
        country
        street
        state
        postalCode
        id
        type
        port {
          uuid
          name
        }
      }
      amount
      price
      currency
      paymentMethod {
        name
        uuid
      }
      specifications
      files {
        location
        name
        uuid
      }
      incoterm {
        name
        uuid
      }
      updatedDate
    }
  }
`;
const gqlGetCosts = gql`
  query ($uuid: String!) {
    getCosts(uuid: $uuid) {
      costs {
        optional
        slug
        value
        editable
        active
        name
        unit
      }
      statusCosts {
        logistic
        finance
      }
      offerData {
        amount
        buyerPaymentMethod
        buyerTerms {
          active
          amount
          desc
          uuidDeposit
        }
        isAccepted
        marginScrapAd
        proposedPrice
        sellerPaymentMethod
        sellerTerms {
          active
          amount
          desc
          uuidDeposit
        }
        totalBuyyer
        totalSeller
        pricePerTonBuyer
        pricePerTonSeller
      }
    }
  }
`;

// const gqlGetCostsForTransaction = gql`
//   query ($uuid: String!) {
//     getCostsForTransaction(uuid: $uuid) {
//       toDeposit {
//         amount
//         title
//         depositTerms {
//           desc
//           amount
//           date
//           done
//         }
//       }
//       toPercive {
//         amount
//         title
//         depositTerms {
//           desc
//           amount
//           date
//           done
//         }
//       }
//       netMargin
//       costs {
//         optional
//         slug
//         value
//         editable
//         active
//         name
//       }
//       statusCosts {
//         logistic
//         finance
//         isAccepted
//       }
//     }
//   }
// `;
// const gqlGetCostsForTransaction = gql`
//   query ($uuid: String!) {
//     getCostsForTransaction(uuid: $uuid) {
//       toDeposit {
//         amount
//         title
//       }
//       toPercive {
//         amount
//         title
//       }
//       netMargin
//       costs {
//         optional
//         slug
//         value
//         editable
//         active
//         name
//       }
//       statusCosts {
//         logistic
//         finance
//         isAccepted
//       }
//     }
//   }
// `;

const gqlGetCostsForTransaction = gql`
  query ($uuid: String!) {
    getCostsForTransaction(uuid: $uuid) {
      costs {
        slug
        name
        optional
        editable
        value
        active
        unit
      }
      statusCosts {
        logistic
        finance
      }
      offerData {
        amount
        buyerPaymentMethod
        buyerTerms {
          active
          amount
          desc
          slug
          date
        }
        isAccepted
        marginScrapAd
        proposedPrice
        sellerPaymentMethod
        sellerTerms {
          active
          amount
          desc
          slug
          date
        }
        totalBuyyer
        totalSeller
      }
    }
  }
`;

const gqlGetOpportunityOfferList = gql`
  query ($lang: String!, $uuid: String!) {
    getOpportunityOffer(lang: $lang, uuidOpportunity: $uuid) {
      uuidOrg
      uuidOffer
      uuidTransaction
      orgName
      amount
      price
      currency
      updatedDate
      nameBO
      uuidBOUser
      status
      uuidUserCounterOffer
    }
  }
`;

export async function getCheckNifExist({ nif }) {
  if (!_.isEmpty(nif)) {
    const data = await client.query({
      query: gqlGetCheckNifExist,
      variables: { uuid: nif },
    });

    return data.data.checkFiscalId;
  }
}

const gqlGetCheckNifExist = gql`
  query ($uuid: String!) {
    checkFiscalId(fiscalId: $uuid)
  }
`;
