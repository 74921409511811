import React from "react";
import { useTranslation } from "../../../utils/hooks";
import styles from "./ExpandToggler.module.scss";
import { Icon } from "@scrapadev/scrapad-front-sdk";

function ExpandToggler({ open, onClick, slug }) {
  const { t } = useTranslation(["common"]);

  return (
    <div
      className={styles["toggle"]}
      onClick={onClick}
      data-cy={slug || undefined}
    >
      <span className={styles["label"]}>
        {open ? t("see-less") : t("see-more")}
      </span>
      <Icon name={open ? "expand_less" : "expand_more"} color="#9E9E9E" />
    </div>
  );
}

export default ExpandToggler;
