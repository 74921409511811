import { Icon, WARM_NEUTRAL_70 } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import styles from "./LinkWithIcon.module.scss";

function LinkWithIcon({ iconName, onClick, children }) {
  return (
    <div className={styles["link-with-icon"]} onClick={onClick}>
      {iconName && (
        <Icon
          name={iconName}
          color={WARM_NEUTRAL_70}
          style={{ fontSize: "16px" }}
        />
      )}
      <span className={styles["text"]}>{children}</span>
    </div>
  );
}

export default LinkWithIcon;

