import React from "react";
import Chip from "./Chip";
import styles from "./Chips.module.scss";

/**
 * UI List of chips component
 * @param {Object} props - Component properties.
 * @param {Array} props.items - Items to show.
 * @param {Function} props.handleRemove - Handler for remove items.
 * @param {{ uuid: String, text: String }} props.model - Model mapping for chip key and text.
 * @param {Object} props.extraData - Extra data for the callback handler.
 * @param {React.Component} props.beforeChips - Component to show before chips.
 * @param {React.Component} props.afterChips - EComponent to show after chips.
 * @returns {JSX.Element}
 */
const Chips = ({
  items,
  handleRemove,
  model,
  extraData,
  beforeChips,
  afterChips,
}) => {
  if (items === null) return <></>;

  const handleRemoveItem = (index) => (e) => {
    if (handleRemove) handleRemove(index, e, extraData);
  };

  return (
    <div className={styles["sc-chips"]}>
      {beforeChips && beforeChips}
      <div className={styles["sc-chips--chips"]}>
        {items &&
          items.map((item, index) => {
            return (
              <Chip
                key={model ? item[model.uuid] : index}
                type="data"
                big
                handleClick={handleRemoveItem(index, extraData)}
                showIcon={handleRemove ? true : false}
                text={model ? item[model.text] : item}
              />
            );
          })}
      </div>
      {afterChips && afterChips}
    </div>
  );
};

export default Chips;
