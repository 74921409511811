import React, { useState } from "react";
import WidgetDataWrapper from "../../wrappers/DataWrapper";
import { useTranslation } from "../../../utils/hooks";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import {
  Alert,
  BREAKPOINT_MD,
  Button,
  InputText,
  Label,
  Paper,
  Row,
  Spacer,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { handleCountryClick, handleOnChange } from "../../../utils/functions";
import { getAddressModel } from "../../../model/address";
import InputCountries from "../../../components/ui/input/country/Country";
import CatalogSearch from "../../../components/ui/input/search/CatalogSearch";
import _ from "lodash";
import { handleCRUD } from "../../../utils/crud/functions";
import { buildNewOpportunityAddress } from "../../../utils/opportunities/functions";

function NewAddressForm({ orgUuid, onSuccess = () => {} }) {
  const handleUpdateStateCallback = (state, childIsDirty) => {};

  return (
    <WidgetDataWrapper
      id={""}
      fnData={getAddressModel}
      Component={NewAddressFormWidget}
      fnParameters={{}}
      extraData={{}}
      updateStateCallback={handleUpdateStateCallback}
      parentCallback={(e) => {}}
      onSuccess={onSuccess}
      orgUuid={orgUuid}
    />
  );
}

function NewAddressFormWidget({
  id,
  state,
  dispatch,
  actions,
  isDirty,
  handleDiscard,
  handleParentCallback,
  fetchData,
  extraData,
  onSuccess = () => {},
  orgUuid,
}) {
  const { t } = useTranslation(["common", "transactions", "business"]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);

  const handleSubmitWidget = async (e) => {
    e.preventDefault();
    handleParentCallback();
    setSubmitLoading(true);
    const data = buildNewOpportunityAddress(state, true);
    await handleCRUD({
      endpoint: `org/${orgUuid}/location`,
      method: "POST",
      data: {
        address: data,
      },
      successCallback: (e) => {
        onSuccess();
      },
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading: setSubmitLoading,
    });
  };

  const checkPortDisabled = () => {
    if (
      !_.isEmpty(state?.city) ||
      !_.isEmpty(`${state?.countryCode}`) ||
      !_.isEmpty(state?.postalCode) ||
      !_.isEmpty(state?.state) ||
      !_.isEmpty(state?.streetNumber) ||
      !_.isEmpty(state?.street)
    ) {
      return true;
    }
    return false;
  };

  const isPortDisabled = checkPortDisabled();

  return (
    <form onSubmit={handleSubmitWidget}>
      <Paper whiteMode style={{ alignItems: "stretch", padding: "20px 25px" }}>
        <Alert type="info">{t("set_port_or_address")}</Alert>
        <Row>
          <Label text={`${t("port")}`} noPadding>
            <CatalogSearch
              disabled={isPortDisabled}
              remote
              id="SelectedPort"
              inputProps={{
                required: true,
                placeholder: t("write-or-select"),
                mode: "underline",
                disabled: isPortDisabled,
              }}
              initialValue={
                state?.port && state?.port !== "empty"
                  ? state?.port?.name || state?.port
                  : undefined
              }
              catalogName={"ports"}
              avoidInitalLoad={true}
              onClickCallback={(option, open, setOpen, value, setValue) => {
                handleOnChange(
                  "port",
                  dispatch
                )({
                  target: {
                    value: option?.uuid,
                  },
                });
                setTimeout(() => {
                  setOpen(false);
                }, 100);
              }}
              onChangeCallback={(e) => {
                if (_.isEmpty(e.target.value)) {
                  handleOnChange(
                    "port",
                    dispatch
                  )({
                    target: {
                      value: undefined,
                    },
                  });
                }
              }}
              mode="underline"
            />
          </Label>
        </Row>
        <Row gap={30}>
          <Label text={t("street")} noPadding>
            <InputText
              disabled={state?.port}
              required
              value={state?.street}
              onChange={handleOnChange("street", dispatch)}
              placeholder={t("write-here")}
              mode="underline"
            />
          </Label>
          <Row gap={30} style={{ width: "100%" }}>
            <Label text={t("number")} noPadding>
              <InputText
                disabled={state?.port}
                required
                value={state?.streetNumber}
                onChange={handleOnChange("streetNumber", dispatch)}
                placeholder={t("write-here")}
                mode="underline"
              />
            </Label>
            <Label text={t("postal-code")} noPadding>
              <InputText
                disabled={state?.port}
                required
                value={state?.postalCode}
                onChange={handleOnChange("postalCode", dispatch)}
                placeholder={t("write-here")}
                mode="underline"
              />
            </Label>
          </Row>
        </Row>
        <Row gap={30}>
          <Label text={t("city")} noPadding>
            <InputText
              disabled={state?.port}
              required
              value={state?.city}
              onChange={handleOnChange("city", dispatch)}
              placeholder={t("write-here")}
              mode="underline"
            />
          </Label>
          <Label text={t("country")} noPadding>
            <InputCountries
              disabled={state?.port}
              required={true}
              handleItemClick={handleCountryClick("countryCode", dispatch)}
              value={state?.countryCode}
              mode="underline"
              onInnerValueChanged={(e) => {
                if (_.isEmpty(e)) {
                  handleOnChange(
                    "countryCode",
                    dispatch
                  )({
                    target: {
                      value: "",
                    },
                  });
                }
              }}
            />
          </Label>
        </Row>
        {/* <Row gap={30} align="flex-start">
          <Label>
            <Row>
              <CheckBox
                text={t("is-port")}
                id={`is_port_business_register`}
                checked={state?.["is-port"]}
                onChange={() => {
                  handleOnChange(
                    "is-port",
                    dispatch
                  )({
                    target: {
                      value: !state?.["is-port"],
                    },
                  });
                }}
              />
            </Row>
          </Label>
          {state?.["is-port"] && (
            <Label text={`${t("delivery_port")}`} noPadding>
              <CatalogSearch
                remote
                id="SelectedPort"
                inputProps={{
                  required: true,
                  placeholder: t("write-or-select"),
                  mode: "underline",
                }}
                initialValue={
                  state?.selectedAddress?.port &&
                  state?.selectedAddress?.port !== "empty"
                    ? state?.selectedAddress?.port?.name ||
                      state?.selectedAddress?.port
                    : undefined
                }
                catalogName={"ports"}
                avoidInitalLoad={true}
                onClickCallback={(option, open, setOpen, value, setValue) => {
                  handleOnChange(
                    "port",
                    dispatch
                  )({
                    target: {
                      value: option?.uuid,
                    },
                  });
                  setTimeout(() => {
                    setOpen(false);
                  }, 100);
                }}
                mode="underline"
              />
            </Label>
          )}
        </Row> */}
      </Paper>
      <Spacer height={15} />
      <Row justify="flex-end">
        <Button
          fullwidth={matches ? true : undefined}
          component="button"
          mode="info"
          text={t("save")}
          loading={submitLoading}
          style={
            submitLoading ? { paddingLeft: "4rem", paddingRight: "4rem" } : {}
          }
        />
      </Row>
    </form>
  );
}

export default NewAddressForm;
