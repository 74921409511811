import {
  Icon,
  Loading,
  Paper,
  toastError,
  WARM_NEUTRAL_60,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "../../components/ui/container/Container";
import NavBar from "../../components/ui/navbar/NavBar";
import LayoutDetail from "../../layout/detail/Detail";
import { useDocumentTitle } from "../../utils/document/hooks";
import { formatDate } from "../../utils/functions";
import { useSetDataUser, useSignOut } from "../../providers/AuthHooks";
import { gqlWrapper } from "../../utils/gqlwrapper";
import { useStepsTransaction, useTranslation } from "../../utils/hooks";
import Steps from "../../components/ui/steps/Steps";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { getTransactionDataBO, getTransactionDetail } from "../../model/transactions";
import TransactionHeader from "./header/TransactionHeader";
import TransactionDetailHeader from "./header/Detail";
import WidgetOpportunitiesCostsTransaction from "../../widgets/transactions/costs/CostsTransaction";
import ChipOutlined from "../../components/ui/chip/ChipOutlined";

/**
 * Costs page
 */
const Costs = () => {
  const { id } = useParams();
  const { t, i18n, values } = useTranslation(["common", "transactions"]);
  const navigate = useNavigate();
  const [data, setData] = useState({ header: [], body: [] });
  const [isLoading, setIsLoading] = useState(false);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const transactionUuid = id?.split("-");
  const { steps } = useStepsTransaction(id);
  const { canManageTransaction, canReadLogistics } = useOperationsProvider();
  useDocumentTitle(
    `${t("transaction-documents")} ${transactionUuid?.[0]}`,
    values
  );

  useEffect(() => {
    fetchData();
  }, [i18n.language]);

  const fetchData = async (quiet = false) => {
    if (!quiet) setIsLoading(true);
    try {
      // const fn = await gqlWrapper(getTransactionDetail, setDataUser, signOut);
      const fn = await gqlWrapper(getTransactionDataBO, setDataUser, signOut);
      const results = await fn(i18n.language, id);
      if (results === null) {
        setData([]);
      } else {
        if (results?.errors?.length > 0) {
          setData([]);
          toastError(results?.errors[0]?.message);
        } else {
          setData(results);
        }
      }
    } catch (e) {
      setData([]);
      toastError(e.message || e);
    } finally {
      if (!quiet) setIsLoading(false);
    }
  };

  // const subtitle = `ID ${
  //   data?.header?.uuidConversation?.split("-")?.[0]
  // } - iniciada por ${data?.header?.startedBy} el ${formatDate(
  //   data?.header?.startedAt,
  //   i18n,
  //   false
  // )}`;

  let typeIcon = (
    <Icon
      name={"swap_horiz"}
      color={WARM_NEUTRAL_60}
      style={{
        fontSize: "16px",
      }}
    />
  );
  const subtitle = (
    <ChipOutlined iconComponent={typeIcon}>
      <span style={{ textTransform: "uppercase" }}>
        {data?.header?.uuidConversation?.split("-")?.[0]}
      </span>
    </ChipOutlined>
  );
  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor={"#fff"}
        headerComponent={
          <>
            <Container>
              <div className="row">
                <div className="col-12">
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <TransactionHeader
                        goBack
                        breadcrumbs={[
                          {
                            description: t("transactions"),
                            onClick: () =>
                              navigate("/transactions", {
                                replace: true,
                              }),
                          },
                          { description: data?.header?.adTitle },
                          { description: t("costs") },
                        ]}
                        imageUrl={data?.sideData?.originAd?.image}
                        title={data?.header?.adTitle}
                        subtitle={subtitle}
                        rightArea={
                          <TransactionDetailHeader
                            uuidEvent={id}
                            title={data?.header?.adTitle}
                            subtitle={subtitle}
                            fetchData={fetchData}
                            permission={canManageTransaction}
                            permissionLogistics={canReadLogistics}
                            onlyMenu
                          />
                        }
                      />
                    </>
                  )}
                </div>
              </div>
            </Container>
          </>
        }
        bodyComponent={
          <>
            <Container style={{ marginBottom: "10px" }}>
              <Steps
                steps={steps}
                currentStep={"costs"}
                checkCurrentByLabel
                minimal
              />
            </Container>
            <Container>
              <Paper>
                <WidgetOpportunitiesCostsTransaction
                  opportunityId={id}
                  uuid={id}
                  // opportunityId={"b3ff9713-2e71-4db3-a234-684e310001fa"}
                  // uuid={"b3ff9713-2e71-4db3-a234-684e310001fa"}
                />
              </Paper>
            </Container>
          </>
        }
      />
    </>
  );
};

export default Costs;
