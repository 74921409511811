import {
  BLUE_10,
  BLUE_20,
  BLUE_60,
  Chip,
  Flex,
  Row,
  Typography,
  WARM_NEUTRAL_30,
  WARM_NEUTRAL_50,
  WARM_NEUTRAL_60,
} from "@scrapadev/scrapad-front-sdk";
import React from "react";
import { useGQL } from "../../../utils/gql/hooks";
import { getCatalogNoTerm } from "../../../model/filters";
import { useTranslation } from "../../../utils/hooks";

export const catalogs = {
  CURRENCY: "currency",
  PAYMENT_METHODS: "payment-methods",
  EQUIPMENTS: "equipments",
  FILE_TYPE: "file-types",
};

const LoadPossibilities = ({
  title,
  description,
  handleClick,
  typeFn,
  showIconFn,
  data,
  extraStyles,
}) => {
  const { t, i18n } = useTranslation(["common"]);
  const { data: equipmentsData } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: catalogs.EQUIPMENTS,
  });

  const properties = {
    title: title || "",
    description: description || "",
    handleClick: handleClick || function () {},
    typeFn:
      typeFn ||
      function () {
        return false;
      },
    showIconFn:
      showIconFn ||
      function () {
        return false;
      },
    data:
      data ||
      equipmentsData?.map((item) => {
        return { type: item.uuid, text: t(item.name) };
      }) ||
      [],
    extraStyles: extraStyles || {},
  };

  return (
    <Flex flexDirection="column" alignItems="stretch" style={{ gap: "10px" }}>
      <Typography
        small
        style={{ marginBottom: 0, fontWeight: 500, color: WARM_NEUTRAL_50 }}
      >
        {properties.title}
      </Typography>
      <Row gap={10} wrap>
        {properties?.data?.map(({ type, text }) => {
          const selected = properties.showIconFn(type, text);
          return (
            <Chip
              key={type}
              big
              type={
                properties.typeFn(type, text) === true
                  ? "outlined-active"
                  : "outlined"
              }
              showIcon={selected}
              text={t(text)}
              handleClick={() => {
                properties.handleClick(type, text);
              }}
              closeStyle={{
                backgroundColor: BLUE_20,
              }}
              iconStyle={{
                position: "relative",
                top: -1,
              }}
              style={{
                justifyContent: "center",
                color: selected ? BLUE_60 : WARM_NEUTRAL_60,
                backgroundColor: selected ? BLUE_10 : "white",
                border: `.5 solid ${selected ? BLUE_20 : WARM_NEUTRAL_30}`,
                ...properties.extraStyles,
              }}
            />
          );
        })}
      </Row>
    </Flex>
  );
};

export default LoadPossibilities;
