import {
  Accordion,
  Button,
  Chip,
  Flex,
  Heading,
  Loading,
  Row,
  toastError,
  UnderlinedLink,
} from "@scrapadev/scrapad-front-sdk";
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import { useOperationsProvider } from "../../../providers/organization/OperationsContext";
import ItemText from "../../../components/ui/item/ItemText";
import FloatingCard from "../../../components/ui/floating-card/FloatingCard";
import ProviderCard from "../../../components/ui/provider-card/ProviderCard";
import { DataProvider } from "../../../providers/data/DataContext";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import { modalTypes } from "../../../utils/variables";
import { gqlWrapper } from "../../../utils/gqlwrapper";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import { getConversationLogisticQuotation } from "../../../model/transactions";
import { format } from "date-fns";
import _ from "lodash";

function QuotationBlock({ id, uuidBuyer, uuidSeller }) {
  const { t, i18n } = useTranslation(["common", "transactions"]);
  const [addQuotationOpen, setAddQuotationOpen] = useState(false);
  const { canManageLogistics } = useOperationsProvider();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (quiet = false) => {
    if (!quiet) setIsLoading(true);
    try {
      // const fn = await gqlWrapper(getTransactionDetail, setDataUser, signOut);
      const fn = await gqlWrapper(
        getConversationLogisticQuotation,
        setDataUser,
        signOut
      );
      const results = await fn(i18n.language, id);
      if (results === null) {
        setData([]);
      } else {
        if (results?.errors?.length > 0) {
          setData([]);
          toastError(results?.errors[0]?.message);
        } else {
          setData(results);
        }
      }
    } catch (e) {
      setData([]);
      toastError(e.message || e);
    } finally {
      if (!quiet) setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Flex flexDirection="column" alignItems="stretch">
        <Row justify="space-between">
          <Row gap={15}>
            <Heading type={6} style={{ margin: 0 }}>
              {t("quotation")}
            </Heading>
            <Chip
              text={
                _.isEmpty(data?.state)
                  ? t("pending")
                  : t(data?.state?.toLowerCase())
              }
              small={false}
              type={
                _.isEmpty(data?.state) ? "pending" : data?.state?.toLowerCase()
              }
            />
          </Row>
          {canManageLogistics() ? (
            <>
              {_.isEmpty(data?.state) ? (
                <Button
                  component="button"
                  type="button"
                  mode={"info"}
                  text={t("add_quotation")}
                  reduced
                  onClick={() => {
                    setAddQuotationOpen(true);
                  }}
                />
              ) : (
                <UnderlinedLink
                  onClick={() => {
                    setAddQuotationOpen(true);
                  }}
                >
                  {t("edit")}
                </UnderlinedLink>
              )}
            </>
          ) : (
            <></>
          )}
        </Row>
        {data?.services?.map((service, index) => (
          <Accordion
            minimal
            defaultOpen
            toggleAtLead
            title={
              <Row gap={25} align="center">
                <Heading type={7} style={{ margin: "0 auto 0 0", padding: 0 }}>
                  {t("service")} {index}
                </Heading>
                <FloatingCard
                  content={
                    <ProviderCard
                      name={service?.supplier?.name}
                      email={service?.supplier?.email}
                    />
                  }
                >
                  {service?.supplier?.name}
                </FloatingCard>
              </Row>
            }
          >
            <div style={{ paddingLeft: "17px" }}>
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                style={{ gap: "5px" }}
              >
                <ItemText
                  alt
                  label={t("transit_time")}
                  value={service?.transitDaysDesc}
                />
                <ItemText
                  alt
                  label={t("expire-date")}
                  value={`${format(
                    Number(service?.expirationDate),
                    "dd/MM/yyyy HH:mm"
                  )}`}
                />
              </Flex>
            </div>
          </Accordion>
        ))}
      </Flex>
      {addQuotationOpen && (
        <DataProvider>
          <ModalFactory
            key={""}
            uuidQuote={""}
            uuidBuyer={uuidBuyer}
            uuidSeller={uuidSeller}
            type={modalTypes.LOGISTICS_ADD_QUOTATION}
            open={addQuotationOpen}
            setOpen={setAddQuotationOpen}
            isEdit={false}
          />
        </DataProvider>
      )}
    </>
  );
}

export default QuotationBlock;

