import { Container, Paper, Spacer } from "@scrapadev/scrapad-front-sdk";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import LayoutDetail from "../../layout/detail/Detail";
import { useTranslation } from "../../utils/hooks";
import OpportunityInfo from "../../widgets/opportunities/OpportunityInfo";
import OpportunityOffers from "../../widgets/opportunities/OpportunityOffers";
import WidgetDataWrapper from "../../widgets/wrappers/DataWrapper";
import { getOpportunity } from "../../model/opportunities";
import { useState } from "react";

const OpportunitiesDetail = () => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { i18n } = useTranslation(["common"]);
  const [oTitle, setOTitle] = useState();

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header
                  goBack
                  middleStyles={{
                    margin: 0,
                  }}
                  breadcrumbs={[
                    {
                      description: t("opportunities"),
                      onClick: () => navigate("/opportunities"),
                    },
                    {
                      description: oTitle || "",
                    },
                  ]}
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <div style={{ width: "100%" }}>
            <WidgetDataWrapper
              id={id}
              Component={OpportunityDetailWrapper}
              fnData={getOpportunity}
              fnParameters={{
                uuid: id,
                locale: i18n.language,
              }}
              updateStateCallback={(state) => {
                setOTitle(state?.title);
              }}
            />
          </div>
        }
      />
    </>
  );
};

function OpportunityDetailWrapper({
  loading,
  setLoading,
  state,
  dispatch,
  actions,
  fetchData,
  extraData,
  isDirty,
  setIsDirty,
  bufferState,
  checkIfStateIsDirty,
  id,
  ...props
}) {
  return (
    <Container>
      <div className="row">
        <div className="col-12">
          <Paper style={{ padding: "15px" }}>
            <OpportunityInfo state={state} loading={loading} id={id} />
          </Paper>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <Spacer />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <OpportunityOffers state={state} loading={loading} id={id} />
        </div>
      </div>
    </Container>
  );
}

export default OpportunitiesDetail;
