import { useState } from "react";
import InputText from "../text/Text";

const InputNif = ({ ...props }) => {
  const [focused, setFocused] = useState(false);

  const handleValue = () => {
    if (props.value && !focused) {
      const prefix = props.value.substring(0, 2);
      const value = props.value.substring(2);
      return `${prefix}   ${value}`;
    }
    return props.value;
  };

  const handleOnChange = (e) => {
    const val = e.target.value.replace(/\s/g, "");
    const newE = { ...e, target: { ...e.target, value: val } }; // A little monkeypatching...
    if (props.onChange) props.onChange(newE);
  };

  return (
    <InputText
      {...props}
      value={handleValue()}
      onChange={handleOnChange}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  );
};

export default InputNif;
