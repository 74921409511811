import Container from "../../../components/ui/container/Container";
import WidgetFieldSaveDiscard from "../../fields/Save";
import WidgetFieldLocation from "../../fields/Location";
import { handleCRUD } from "../../../utils/crud/functions";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import { useTranslation } from "../../../utils/hooks";
import { useState } from "react";
import Label from "../../../components/ui/input/label/Label";
import InputCountries from "../../../components/ui/input/country/Country";
import {
  checkPermission,
  handleCountryClick,
  renderComponentWithPermission,
  renderDisabledComponentWithTooltip,
} from "../../../utils/functions";
import { useHeaderProvider } from "../../../providers/header/HeaderContext";
import { orgStatus } from "../../../utils/variables";

const WidgetBusinessFiscal = ({
  id,
  state,
  dispatch,
  actions,
  isDirty,
  handleDiscard,
  handleParentCallback,
  fetchData,
  extraData,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { t } = useTranslation(["common", "business"]);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const { state: headerState } = useHeaderProvider();
  const { permissionManage = null, permissionRead = null } = extraData || {};

  const handleSubmitWidget = async (e) => {
    e.preventDefault();
    handleParentCallback();
    setSubmitLoading(true);
    const data = { ...state };
    data.type = "fiscal";
    delete data.__typename;
    await handleCRUD({
      endpoint: `org/${id}/location/${id}`,
      method: "PUT",
      data: { address: data },
      successCallback: () => fetchData(),
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading: setSubmitLoading,
    });
  };

  const handleOnChange = (name) => (e) => {
    const obj = {};
    obj[name] = e.target.value;
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: obj,
    });
  };

  return (
    <form onSubmit={handleSubmitWidget}>
      <Container>
        {renderComponentWithPermission(
          permissionRead,
          <WidgetFieldLocation
            streetValue={state?.street}
            postalValue={state?.postalCode}
            townValue={state?.city}
            stateValue={state?.state}
            handleOnChange={handleOnChange}
            streetProps={{
              readOnly: !checkPermission(permissionManage),
            }}
            postalProps={{
              readOnly: !checkPermission(permissionManage),
            }}
            townProps={{
              readOnly: !checkPermission(permissionManage),
              required: true,
            }}
            stateProps={{
              readOnly: !checkPermission(permissionManage),
            }}
            postalKey={"postalCode"}
            townKey={"city"}
            extraComponent={
              <div className="col-12 col-lg-6">
                <Label>{t("country")} *</Label>
                <InputCountries
                  required
                  readOnly={!checkPermission(permissionManage)}
                  handleItemClick={handleCountryClick("countryCode", dispatch)}
                  value={state?.countryCode}
                  skipRequiredHelp
                />
              </div>
            }
          />
        )}

        <WidgetFieldSaveDiscard
          permission={permissionManage}
          isDirty={isDirty}
          handleDiscard={handleDiscard}
          loading={submitLoading}
        />
      </Container>
    </form>
  );
};

export default WidgetBusinessFiscal;
