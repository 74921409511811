import { Modal } from "@scrapadev/scrapad-front-sdk/dist/index";
import ModalManageAccess from "./modals/ModalManageAccess";
import ModalUploadFile from "./modals/ModalUploadFile";
import ModalDocumentShare from "./modals/ModalDocumentShare";
import ModalDocumentMove from "./modals/ModalDocumentMove";
import ModalDocumentRename from "./modals/ModalDocumentRename";
import ModalDocumentDelete from "./modals/ModalDocumentDelete";

/**
 * Modal factory.
 * @param {Object} props - Component properties.
 * @param {Boolean} [props.open=false] - Open/close modal state.
 * @param {Function} props.setOpen - Setter for the open state.
 * @param {String} props.type - Type of modal.
 * @returns
 */
const DocumentModalFactory = ({ open, setOpen, type, ...props }) => {
  const options = {
    open,
    setOpen,
    ...props,
  };
  const lookUpTable = {
    [documentModalTypes.MANAGE_ACCESS]: <ModalManageAccess {...options} />,
    [documentModalTypes.UPLOAD_FILES]: <ModalUploadFile {...options} />,
    [documentModalTypes.SHARE]: <ModalDocumentShare {...options} />,
    [documentModalTypes.MOVE]: <ModalDocumentMove {...options} />,
    [documentModalTypes.RENAME]: <ModalDocumentRename {...options} />,
    [documentModalTypes.DELETE]: <ModalDocumentDelete {...options} />,
  };
  const getModalComponent = () => {
    const modal = lookUpTable[type];
    return modal ? modal : <Modal {...options} />;
  };

  return getModalComponent();
};

export const documentModalTypes = {
  MANAGE_ACCESS: "MANAGE_ACCESS",
  UPLOAD_FILES: "UPLOAD_FILES",
  SHARE: "SHARE",
  MOVE: "MOVE",
  RENAME: "RENAME",
  DELETE: "DELETE",
};
export default DocumentModalFactory;
