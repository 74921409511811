import { Button, Flex, Icon, Spacer } from "@scrapadev/scrapad-front-sdk";
import Text from "../../../components/ui/text/Text";
import { useTranslation } from "../../../utils/hooks";
import { GRAY_100 } from "../../../utils/variables";

/**
 * Component to show wwhen there is no transport quotes.
 * @returns {JSX.Element}
 */
const EmptyQuotes = ({ onClick }) => {
  const { t } = useTranslation(["logistics", "common"]);
  //const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      style={{
        border: `1px solid ${GRAY_100}`,
        paddingTop: 32,
        paddingBottom: 32,
        paddingLeft: 16,
        paddingRight: 16,
        borderRadius: "10px",
      }}
    >
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        style={{ maxWidth: 400, textAlign: "center" }}
      >
        <Text bold style={{ fontWeight: "600" }}>
          {t("not-requested-quotes-yet")}
        </Text>
        <Spacer height={25} />
        <Flex
          style={{
            backgroundColor: "#F1F6FF",
            borderRadius: 12,
            width: "50px",
            height: "50px",
          }}
        >
          <Icon name={"directions_bus"} color={"#3784F6"} />
        </Flex>
        <Spacer />
        <Text>{t("request-compare-quotes-1")}</Text>
        <Text>{t("request-compare-quotes-2")}</Text>
        <Spacer />
        <Button
          text={t("new_request")}
          ultrawide
          mode="info"
          onClick={onClick}
        />
      </Flex>
    </Flex>
  );
};

export default EmptyQuotes;
