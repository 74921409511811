import React from "react";
import Alert from "../alert/Alert";
import styles from "./LoginWrap.module.scss";

function LoginWrap({
  children,
  title,
  description,
  errorMessage,
  sucessMessage,
}) {
  return (
    <div className={`${styles["c-login-wrap"]}`}>
      <div className={`${styles["c-login-wrap__inner"]}`}>
        {title ? (
          <h2 className={`${styles["c-login-wrap__heading"]}`}>{title}</h2>
        ) : (
          <></>
        )}
        {description ? (
          <p className={`${styles["c-login-wrap__desc"]}`}>{description}</p>
        ) : (
          <></>
        )}
        {children}

        {errorMessage ? (
          <>
            <Alert type="error">{errorMessage}</Alert>
          </>
        ) : (
          <></>
        )}
        {sucessMessage ? (
          <>
            <Alert type="sucess">{sucessMessage}</Alert>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default LoginWrap;
