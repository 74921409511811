import { Flex, Spacer, TableManager } from "@scrapadev/scrapad-front-sdk";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import SearchFilterExport from "../../components/ui/search-filter-export/SearchFilterExport";
import LayoutTable from "../../layout/table/Table";
import { getIncomeFinances } from "../../model/finances";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { useTableDataProvider } from "../../providers/TableContext";
import { searchOnTable } from "../../utils/functions";
import { useTranslation } from "../../utils/hooks";
import {
  financesIncomeToChip,
  financesStatusOptions,
  modalTypes,
  QUERY_UPPER_LIMIT,
} from "../../utils/variables";

const InnerWidgetFinancesIncome = () => {
  const { t, i18n } = useTranslation(["common", "finances"]);
  const { data, setData, dataBuffer, loading, setLoading, fetchData } =
    useTableDataProvider();
  const { canManageFinances } = useOperationsProvider();

  const handleStatusActionClick = (item) => async (uuid) => {
    console.log(item, uuid);
  };
  return (
    <>
      <Flex justifyContent={"space-between"}>
        <SearchFilterExport
          isLocalSearch
          searchPlaceholder={t("search")}
          searchOnChange={searchOnTable(
            data,
            setData,
            dataBuffer.current,
            i18n
          )}
          exportProps={{
            fnData: getIncomeFinances,
            fnParameters: {
              locale: i18n.language,
              limit: QUERY_UPPER_LIMIT,
            },
            fileName: "finances-incomes",
          }}
          hasFilters={false}
          hasSeparator={false}
        />
      </Flex>
      <Spacer height={35} />
      <div id="ModalContainer"></div>
      <TableManager
        loading={loading}
        data={data}
        setData={setData}
        emptyText={t("empty-data")}
        rowTriggerPageChange={false}
        itemsProps={{
          modal: {
            domNode: document.getElementById("ModalContainer"),
            render: ({ open, setOpen, uuid, value, colRef, cell, props }) => {
              if (colRef === "id_transaction") {
                return (
                  <ModalFactory
                    key={uuid}
                    type={modalTypes.FINANCES_LINK}
                    open={open}
                    setOpen={setOpen}
                    uuid={uuid}
                    permission={canManageFinances}
                    {...props}
                  />
                );
              }
              if (colRef === "comment") {
                const idIncome = data?.body
                  ?.filter((e) => e.uuid === uuid)[0]
                  ?.cells?.filter((c) => c.colRef === "id_income")[0]?.value;
                return (
                  <ModalFactory
                    key={uuid}
                    type={modalTypes.FINANCES_COMMENT}
                    open={open}
                    setOpen={setOpen}
                    uuid={idIncome}
                    {...props}
                  />
                );
              }
            },
          },
          chip: {
            mapSlugValues: financesIncomeToChip,
            translate: (code) => t(code),
            style: {
              paddingTop: 9,
              paddingBottom: 9,
            },
          },
          chip_dropdown: {
            translate: (code) => t(code),
            mapValues: financesIncomeToChip,
          },
        }}
      />
    </>
  );
};

const WidgetFinancesIncome = () => {
  const { i18n } = useTranslation(["common", "finances"]);
  return (
    <LayoutTable
      fnData={getIncomeFinances}
      fnParameters={{
        locale: i18n.language,
        limit: QUERY_UPPER_LIMIT,
      }}
    >
      <InnerWidgetFinancesIncome />
    </LayoutTable>
  );
};

export default WidgetFinancesIncome;
