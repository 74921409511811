import { Button } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";
import Email from "../ui/input/email/Email";
import Label from "../ui/label/Label";
import LinkStyled from "../ui/link/LinkStyled";
import LoginClaim from "../ui/texts/LoginClaim";

export const RecoverForm = (props) => {
  const { t } = useTranslation(["login", "common"]);

  return (
    <>
      <form method="POST" onSubmit={props.doRecover}>
        <Label text={t("email")}>
          <Email
            required={true}
            name="email"
            style={{ marginBottom: 0, padding: "0.7rem 0.9rem" }}
            id="email"
          />
        </Label>
        <Label>
          <Button
            component="button"
            fullwidth={true}
            type="submit"
            text={t("recover", { ns: "login" })}
          />
        </Label>
      </form>
      <Label>
        <LinkStyled href={"/login"}>
          <LoginClaim />
        </LinkStyled>
      </Label>
    </>
  );
};
