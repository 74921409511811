import React, { useState } from "react";
import styles from "./DropdownRow.module.scss";
import { Icon, Paper } from "@scrapadev/scrapad-front-sdk";

function DropdownRow({ label, children, defaultOpen = false }) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div className={styles["dropdown-row"]}>
      <div className={styles["dropdown-row__header"]}>
        <span className={styles["dropdown-row__label"]}>{label}</span>
        <span
          className={styles["dropdown-row__action"]}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <span className={styles["label"]}>
            Ver {isOpen ? "menos" : "más"}
          </span>{" "}
          <Icon
            name={isOpen ? "expand_less" : "expand_more"}
            style={{
              fontSize: "14px",
            }}
          />
        </span>
      </div>
      {isOpen && (
        <div className={styles["content"]}>
          <Paper style={{ width: "100%" }}>{children}</Paper>
        </div>
      )}
    </div>
  );
}

export default DropdownRow;
