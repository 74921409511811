import {
  BREAKPOINT_LG,
  Flex,
  Heading,
  Modal,
  toastError,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import React, { useState } from "react";
import { useTranslation } from "../../../../utils/hooks";
import TransactionFiles from "../../../../components/ui/input/file/File";
import DocumentLocationItem from "../../components/document-location-item/DocumentLocationItem";
import DocumentLocationSelector from "../../components/location-selector/DocumentLocationSelector";
import {
  DocumentsProvider,
  useDocumentsProvider,
} from "../../DocumentsContext";
import _ from "lodash";
import { useCRUD } from "../../../../utils/crud/hooks";

function ModalDocumentMove({
  open = false,
  setOpen,
  files = [],
  bulkSelected = [],
  filesTree = {},
  ...props
}) {
  const { CRUD } = useCRUD();
  const { t } = useTranslation(["common"]);
  const { state, reloadData } = useDocumentsProvider();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [loading, setLoading] = useState(false);
  const filesInner = _.uniq([...files, ...bulkSelected]);
  const [filesComplete, setFilesComplete] = useState(
    _.cloneDeep(filesTree?.files?.filter((f) => filesInner?.includes(f?.id)))
  );
  const [selectedFolder, setSelectedFolder] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (_.isEmpty(filesComplete)) {
      toastError(t("select_some_files"));
      return;
    }

    if (_.isEmpty(selectedFolder)) {
      toastError(t("set_destination"));
      return;
    }

    setLoading(true);

    await CRUD({
      external: false,
      data: {
        id: filesComplete?.map((e) => e?.id),
        names: filesComplete?.map((e) => e?.name),
        destination: selectedFolder?.id,
      },
      endpoint: `integration/move`,
      method: "PUT",
      successCallback: async () => {
        reloadData(500);
        setOpen(false);
        setLoading(false);
      },
      errorCallback: async (e) => {
        setLoading(false);
        toastError(`Error: ${e?.message}`);
      },
    });

    if (props.submitCallback) props.submitCallback(e, loading, setLoading);
  };

  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("move")}
      hasForm={true}
      onCancel={props.onCancel}
      onSubmit={handleSubmit}
      submitText={t("move")}
      cancelText={t("cancel")}
      actionsMode="end"
      submitProps={{
        ultrawide: true,
        style: {
          width: "195px",
        },
      }}
      cancelProps={{
        ultrawide: true,
        style: {
          width: "195px",
        },
      }}
      loading={loading}
      maxWidth={900}
      modalStyles={{ width: matches ? "auto" : 900 }}
      BodyComponent={
        <>
          <div>
            <Flex
              flexDirection="column"
              alignItems="stretch"
              style={{ gap: "15px" }}
            >
              <TransactionFiles
                files={filesComplete}
                readOnly={false}
                hideFileType={true}
                showFilesAndInput={false}
                hideFileInput
                forceShowFileList
                selectedLabel={t("selected_elements")}
                hideRename
                hideDelete
              />
              <Heading type={4} style={{ margin: 0 }}>
                {t("choose_location")}
              </Heading>
              <DocumentLocationItem locationName={state?.dataVisible?.name} />
              <DocumentsProvider
                transactionUuid={state?.transactionUuid}
                data={[]}
              >
                <DocumentLocationSelector
                  selectedFolder={selectedFolder}
                  onSelected={(e) => {
                    setSelectedFolder(e);
                  }}
                />
              </DocumentsProvider>
            </Flex>
          </div>
        </>
      }
    />
  );
}

export default ModalDocumentMove;
