import { toastError } from "@scrapadev/scrapad-front-sdk";
import React, {
  useContext,
  useReducer,
  useMemo,
  useState,
  useEffect,
} from "react";
import { gqlWrapper } from "../../utils/gqlwrapper";
import { useTranslation } from "../../utils/hooks";
import { useSetDataUser, useSignOut } from "../AuthHooks";

const HeaderContext = React.createContext({
  state: null,
  dispatch: null,
});

const getInitialState = () => {
  return {};
};

export const actions = {
  INITIAL_LOAD: "INITIAL_LOAD",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.INITIAL_LOAD:
      return { ...action.payload };
    default:
      return state;
  }
};

export const HeaderProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const [loading, setLoading] = useState(false);

  const contextValue = useMemo(
    () => ({ state, dispatch, loading, setLoading }),
    [state, dispatch, loading]
  );

  return (
    <HeaderContext.Provider value={contextValue}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeaderProvider = (locale, uuidOrg, fnData) => {
  const { t } = useTranslation("common");
  const { state, dispatch, loading, setLoading } = useContext(HeaderContext);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();

  useEffect(() => {
    if (uuidOrg !== state.id) {
      fetchData();
    }
  }, [uuidOrg]);

  const fetchData = async () => {
    const fn = await gqlWrapper(fnData, setDataUser, signOut);
    if (!fn) {
      return;
    }
    try {
      setLoading(true);
      const response = await fn({ locale: locale, org: uuidOrg });
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: { id: uuidOrg, ...response },
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
      toastError(t("default-error"));
      setLoading(false);
    }
  };

  return {
    state,
    dispatch,
    loading,
    setLoading,
    fetchData,
  };
};
