import { useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import {
  BREAKPOINT_LG,
  Flex,
  Loading,
  Modal,
  SearchAutoComplete,
  Typography,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import { useCRUD } from "../../../utils/crud/hooks";
import ModalText from "../../ui/modal/text/ModalText";
import { useUser } from "../../../providers/AuthHooks";
import { handleFormOK } from "../../../utils/functions";
import {
  buildChangeFee,
  buildChangePaymentMethod,
} from "../../forms/transaction/functions";
import { useGQL } from "../../../utils/gql/hooks";
import { getCatalogNoTerm } from "../../../model/filters";

/**
 * Modal for changing payment method.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalChangePaymentMethod = ({ open = false, setOpen, ...props }) => {
  const { uuidEvent, fetchData, method } = props;
  const { CRUD } = useCRUD();
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation(["transactions", "common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [methodNew, setMethodNew] = useState(method?.uuid);
  const { data: methodData, loading: methodLoading } = useGQL(
    getCatalogNoTerm,
    {
      locale: i18n.language,
      name: "payment-methods",
    }
  );

  const handleSubmit = async (e) => {
    if (!uuidEvent) return;
    const data = buildChangePaymentMethod(user.userUuid, uuidEvent, methodNew);
    setLoading(true);
    await CRUD({
      external: true,
      data,
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      successCallback: async () => {
        await fetchData();
        setLoading(false);
        handleFormOK(t, fetchData);
      },
    });
  };

  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("change-payment-method")}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("save-changes")}
      cancelText={t("cancel")}
      loading={loading}
      maxWidth={850}
      modalStyles={{ width: matches ? "auto" : 850 }}
      BodyComponent={
        <Flex flexDirection="column" alignItems={"flex-start"}>
          <ModalText
            text={t("edit-payment-method")}
            style={{ marginBottom: 20 }}
          />
          <Typography
            type="2"
            style={{
              fontWeight: 500,
              marginBottom: 12,
            }}
          >
            {t("new-payment-method")}
          </Typography>
          {methodLoading ? (
            <Loading />
          ) : (
            <SearchAutoComplete
              required={true}
              newMultipleItemFormat
              value={
                methodData?.errors
                  ? []
                  : methodData?.find((f) => f?.uuid === methodNew)?.name
              }
              options={methodData?.errors ? [] : methodData}
              inputProps={{ placeholder: t("write-or-select") }}
              placeholder={t("select-a-value")}
              model={{ uuid: "uuid", text: "name" }}
              containerStyles={{ width: "100%" }}
              handleItemClick={(option, open, setOpen, e) => {
                e.stopPropagation();
                setMethodNew(option.uuid);
              }}
              handleOnClickWithEvent={(e, value, open, setOpen) => {
                setOpen(!open);
              }}
            />
          )}
        </Flex>
      }
    />
  );
};

export default ModalChangePaymentMethod;
