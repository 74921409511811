import { Center, Container, Empty, Spacer } from "@scrapadev/scrapad-front-sdk";
import NavBar from "../../components/ui/navbar/NavBar";
import { useTranslation } from "../../utils/hooks";

const NotFound = () => {
  const { t } = useTranslation("common");
  return (
    <>
      <NavBar />
      <Container>
        <div className="row">
          <div className="col-12">
            <Spacer height={100} />
            <Center>
              <Empty heading={t("page-not-found")} />
            </Center>
          </div>
        </div>
      </Container>
    </>
  );
};

export default NotFound;
