import {
  Accordion,
  Heading,
  Icon,
  Modal,
  Typography,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";
import React from "react";

function IncotermInfoModal({ open, setOpen, ...props }) {
  const { t } = useTranslation("common");

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hasForm={false}
      maxWidth={1000}
      modalStyles={{
        width: "1000px",
      }}
      hasActions={false}
      title={t("incoterms")}
      BodyComponent={
        <>
          <Typography>{t("incoterms_desc")}</Typography>

          <Accordion
            title={t("incoterms_by_sea")}
            beforeTitle={
              <Icon
                name="directions_boat"
                color="#5CC684"
                style={{ marginRight: "7px" }}
              />
            }
            minimal
          >
            <div>
              {/*<Heading
                type={6}
                weight="medium"
                style={{ fontSize: "0.75rem", marginBottom: "10px" }}
              >
                {t("fas_title")}
              </Heading>
          <Typography>{t("fas_desc")}</Typography>*/}

              {/*<Heading
                type={6}
                weight="medium"
                style={{ fontSize: "0.75rem", marginBottom: "10px" }}
              >
                {t("fob_title")}
              </Heading>
              <Typography>{t("fob_desc")}</Typography>*/}

              <Heading
                type={6}
                weight="medium"
                style={{ fontSize: "0.75rem", marginBottom: "10px" }}
              >
                {t("cfr_title")}
              </Heading>
              <Typography>{t("cfr_desc")}</Typography>

              <Heading
                type={6}
                weight="medium"
                style={{ fontSize: "0.75rem", marginBottom: "10px" }}
              >
                {t("cif_title")}
              </Heading>
              <Typography>{t("cif_desc")}</Typography>
            </div>
          </Accordion>

          <Accordion
            title={t("incoterms_all_purpose")}
            beforeTitle={
              <Icon
                name="directions_bus_filled"
                color="#5CC684"
                style={{ marginRight: "7px" }}
              />
            }
            minimal
          >
            <div>
              <Heading
                type={6}
                weight="medium"
                style={{ fontSize: "0.75rem", marginBottom: "10px" }}
              >
                {t("exw_title")}
              </Heading>
              <Typography>{t("exw_desc")}</Typography>

              {/*<Heading
                type={6}
                weight="medium"
                style={{ fontSize: "0.75rem", marginBottom: "10px" }}
              >
                {t("fca_title")}
              </Heading>
              <Typography>{t("fca_desc")}</Typography>*/}

              {/*<Heading
                type={6}
                weight="medium"
                style={{ fontSize: "0.75rem", marginBottom: "10px" }}
              >
                {t("cpt_title")}
              </Heading>
              <Typography>{t("cpt_desc")}</Typography>*/}

              {/*<Heading
                type={6}
                weight="medium"
                style={{ fontSize: "0.75rem", marginBottom: "10px" }}
              >
                {t("cip_title")}
              </Heading>
              <Typography>{t("cip_desc")}</Typography>*/}
              {/*<Heading
                type={6}
                weight="medium"
                style={{ fontSize: "0.75rem", marginBottom: "10px" }}
              >
                {t("dap_title")}
              </Heading>
              <Typography>{t("dap_desc")}</Typography>*/}
              <Heading
                type={6}
                weight="medium"
                style={{ fontSize: "0.75rem", marginBottom: "10px" }}
              >
                {t("dpu_title")}
              </Heading>
              <Typography>{t("dpu_desc")}</Typography>
              <Heading
                type={6}
                weight="medium"
                style={{ fontSize: "0.75rem", marginBottom: "10px" }}
              >
                {t("ddp_title")}
              </Heading>
              <Typography>{t("ddp_desc")}</Typography>
            </div>
          </Accordion>
        </>
      }
      {...props}
    />
  );
}

export default IncotermInfoModal;
