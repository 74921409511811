export const walletTypesIn = {
  withdraw: "balance-withdraw",
  deposit: "balance-deposit",
};

export const walletTypesOut = {
  income: "balance-income",
  payment: "balance-payment",
  "money-out": "money-out",
};

export const walletStatus = {
  APPROVED: "Success",
  DENEGATED: "Cancelled",
  PENDING: "Pending",
  ERROR_LEMONWAY: "LemonWay-Error",
  ERROR_PSP: "Error-PSP",
  VALIDATION_PENDING: "Validation-Pending",
  REQUESTED: "requested",
  DENIED: "denied",
};

export const typeToWalletOrigin = {
  withdraw: "wallet",
  deposit: "wallet",
  "money-out": "wallet",
  income: "transaction",
  payment: "transaction",
};

export const WALLET_LIMIT = 10;
