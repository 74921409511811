import React from "react";
import styles from "./ItemText.module.scss";
import _ from "lodash";

function ItemText({ label, value, alt }) {
  return (
    <div className={`${styles["item-text"]} ${alt ? styles["alt"] : ""}`}>
      <span className={styles["label"]}>{label}</span>
      {!_.isEmpty(value) && <span className={styles["value"]}>{value}</span>}
    </div>
  );
}

export default ItemText;
