export const actions = {
  UPDATE_PAGINATION: "UPDATE_PAGINATION",
  ADD_FILTER_TYPE: "ADD_FILTER_TYPE",
  DELETE_FILTER_TYPE: "DELETE_FILTER_TYPE",
  UPDATE_FILTER_DATE: "UPDATE_FILTER_DATE",
  ADD_FILTER_DATE: "ADD_FILTER_DATE",
  DELETE_FILTER_DATE: "DELETE_FILTER_DATE",
};

export const reducer = (state, action) => {
  switch (action.type) {
    case actions.ADD_FILTER_TYPE:
      return {
        ...state,
        pagination: { current: 0 },
        filters: {
          ...state.filters,
          type: {
            ...state.filters.type,
            selected: [...state.filters.type.selected, action.payload],
          },
        },
      };
    case actions.DELETE_FILTER_TYPE:
      return {
        ...state,
        pagination: { current: 0 },
        filters: {
          ...state.filters,
          type: {
            ...state.filters.type,
            selected: state.filters.type.selected.filter(
              (f) => f.slug !== action.payload.slug
            ),
          },
        },
      };
    case actions.ADD_FILTER_DATE:
      return {
        ...state,
        pagination: { current: 0 },
        filters: {
          ...state.filters,
          date: {
            ...state.filters.date,
            selected: { ...action.payload },
          },
        },
      };
    case actions.UPDATE_FILTER_DATE:
      return {
        ...state,
        pagination: { current: 0 },
        filters: {
          ...state.filters,
          date: {
            ...state.filters.date,
            ...action.payload,
          },
        },
      };
    case actions.DELETE_FILTER_DATE:
      return {
        ...state,
        pagination: { current: 0 },
        filters: {
          ...state.filters,
          date: {
            ...state.filters.date,
            selected: {
              from: null,
              to: null,
            },
          },
        },
      };
    case actions.UPDATE_PAGINATION:
      return { ...state, pagination: { current: action.payload } };
    default:
      return state;
  }
};
