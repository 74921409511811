import {
  GREEN_COLOR_100,
  Loading,
  Separator,
  Switch,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";
import { TEXT_COLOR_LIGHT } from "../../../utils/variables";
import { useWalletApproveAccount } from "../../../utils/wallet/hooks";

/**
 * Helper widget to approve or not an account.
 * @param {object} props - Component properties.
 * @param {string} uuid - Account uuid.
 * @param {boolean} disable - New disable status.
 * @param {Function} fetchData - Function to refetch data and reflect changes.
 * @returns {JSX.Element}
 */
const WidgetWalletApprove = ({ uuid, checked, fetchData }) => {
  const { t } = useTranslation(["common"]);
  const { loading, handleApproveAccount } = useWalletApproveAccount();

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Switch
          isLabelInLeft
          label={t("approved-account")}
          labelStyle={{
            color: TEXT_COLOR_LIGHT,
            fontSize: 14,
            lineHeight: "21px",
            fontWeight: 300,
          }}
          checked={checked}
          onChange={() => {
            handleApproveAccount(uuid, checked, fetchData);
          }}
          inputStyle={
            checked
              ? {
                  color: GREEN_COLOR_100,
                  backgroundColor: GREEN_COLOR_100,
                }
              : {}
          }
        />
      )}
      <Separator />
    </>
  );
};

export default WidgetWalletApprove;
