import { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Container } from "@scrapadev/scrapad-front-sdk/dist/index";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import Steps from "../../components/ui/steps/Steps";
import LayoutDetail from "../../layout/detail/Detail";
import { getBusinessAbout, getHeaderInfo } from "../../model/business";
import WidgetBusinessFiscal from "../../widgets/business/about/Fiscal";
import WidgetBusinessInfo from "../../widgets/business/about/Info";
import WidgetBusinessLegal from "../../widgets/business/about/Legal";
import WidgetDataWrapper from "../../widgets/wrappers/DataWrapper";
import styles from "./BusinessAbout.module.scss";
import { useDocumentTitle } from "../../utils/document/hooks";
import HistoryBlockWrapper from "../../wrappers/HistoryBlockWrapper";
import { useTranslation, useSteps } from "../../utils/hooks";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";

const BusinessAbout = () => {
  const { id } = useParams();
  const { t, values, i18n } = useTranslation([
    "common",
    "transactions",
    "business",
  ]);
  const navigate = useNavigate();
  const [isDirty, setIsDirty] = useState([0, 0, 0, 0, 0]);
  const legalRef = useRef(null);
  useDocumentTitle(`${t("about-business")}`, values);
  const { state } = useHeaderProvider(i18n.language, id, getHeaderInfo);
  const { canManageOrg, canReadOrg } = useOperationsProvider();
  const { steps } = useSteps(id);

  const submitCallback =
    (modalOpen, setModalOpen) => (e, loading, setLoading) => {
      setModalOpen(false);
    };

  const handleUpdateStateCallback = (index) => (state, childIsDirty) => {
    const newDirty = [...isDirty];
    newDirty[index] = childIsDirty ? 1 : 0;
    setIsDirty(newDirty);
  };

  const handleChildrenStatusAndDirty = (index) => (state, isDirty) => {};

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor={"#fff"}
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header
                  title={state?.basicData?.orgName}
                  goBack
                  breadcrumbs={[
                    {
                      description: t("business"),
                      onClick: () => navigate("/business", { replace: true }),
                    },
                    {
                      description: state?.basicData?.orgName,
                      onClick: () =>
                        navigate(`/business/${id}`, { replace: true }),
                    },
                    {
                      description: t("about-business"),
                    },
                  ]}
                  subHeaderLeft={
                    <Steps
                      steps={steps}
                      currentStep={"about-business"}
                      checkCurrentByLabel
                    />
                  }
                  fullSubHeaderLeft
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <HistoryBlockWrapper
            mustBlockLeaving={isDirty.find((f) => f === 1)}
            submitCallback={submitCallback}
          >
            <div className={styles["sc-business--about"]}>
              <Container>
                <div className="row">
                  <div className="col-12">
                    <Accordion title={t("business-info")} defaultOpen={true}>
                      <WidgetDataWrapper
                        id={id}
                        fnData={getBusinessAbout}
                        Component={WidgetBusinessInfo}
                        fnParameters={{
                          locale: i18n.language,
                          org: id,
                          module: "basicData",
                        }}
                        extraData={{
                          permissionManage: canManageOrg,
                          permissionRead: canReadOrg,
                        }}
                        updateStateCallback={handleUpdateStateCallback(0)}
                        parentCallback={handleChildrenStatusAndDirty(0)}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Accordion title={t("tax-residence")} defaultOpen={true}>
                      <WidgetDataWrapper
                        id={id}
                        fnData={getBusinessAbout}
                        fnParameters={{
                          locale: i18n.language,
                          org: id,
                          module: "fiscalAddress",
                        }}
                        Component={WidgetBusinessFiscal}
                        extraData={{
                          permissionManage: canManageOrg,
                          permissionRead: canReadOrg,
                        }}
                        updateStateCallback={handleUpdateStateCallback(2)}
                        parentCallback={handleChildrenStatusAndDirty(2)}
                      />
                    </Accordion>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Accordion
                      ref={legalRef}
                      title={t("legal-representative")}
                      defaultOpen={true}
                    >
                      <WidgetDataWrapper
                        id={id}
                        fnData={getBusinessAbout}
                        fnParameters={{
                          locale: i18n.language,
                          org: id,
                          module: "legalReprenstative",
                        }}
                        Component={WidgetBusinessLegal}
                        updateStateCallback={handleUpdateStateCallback(1)}
                        parentCallback={handleChildrenStatusAndDirty(1)}
                        extraData={{
                          accordionRef: legalRef?.current,
                          permissionManage: canManageOrg,
                          permissionRead: canReadOrg,
                        }}
                      />
                    </Accordion>
                  </div>
                </div>
              </Container>
            </div>
          </HistoryBlockWrapper>
        }
      />
    </>
  );
};

export default BusinessAbout;
