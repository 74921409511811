import React from "react";
import IconButton from "../icon-button/IconButton";
import styles from "./Avatar.module.scss";

/**
 * UI Avatar component.
 * @param {Object} props - Component properties.
 * @param {String} [props.src="../../../assets/img/icons/scrapad_avatar.png"] - Image src.
 * @param {String} [props.alt=""] - Image alt.
 * @param {String|Number} [props.width="auto"] - Width of the avatar.
 * @param {String|Number} [props.height="auto"] - Height of the avatar.
 * @param {Boolean} [props.rounded=true] - Tells if avatar shoud be rounded.
 * @param {Function} props.handleEditAvatar - Edit avatar callback.
 * @param {React.CSSProperties} [props.style={}] - Inline styles for the container.
 * @param {React.CSSProperties} [props.imageStyle={}] - Inline styles for the image.
 * @returns {JSX.Element}
 */
const Avatar = ({
  src,
  alt = "",
  width = "auto",
  height = "auto",
  rounded = true,
  handleEditAvatar,
  style = {},
  imageStyle = {},
}) => {
  return src ? (
    <div className={styles["sc-avatar"]} style={{ width, height, ...style }}>
      <img
        src={src}
        style={{
          width,
          height,
          borderRadius: rounded ? "50%" : 0,
          ...imageStyle,
        }}
        alt={alt}
      />
      {handleEditAvatar && (
        <IconButton name={"edit"} onClick={handleEditAvatar} />
      )}
    </div>
  ) : (
    <></>
  );
};

export default Avatar;
