import styles from "./Radio.module.scss";

const Radio = ({ text = "", ...props }) => {
  return (
    <>
      <input
        id={props.id}
        className={styles["sc-radio"]}
        type={"radio"}
        {...props}
      />
      <label htmlFor={props.id}>{text}</label>
    </>
  );
};

export default Radio;
