import React, { useEffect, useState } from "react";
import { actions } from "../../../providers/transactions/actions/transactions-forms";
import { useTransactionsFormsProvider } from "../../../providers/transactions/TransactionsFormContext";
import Container from "../../ui/container/Container";
import Label from "../../ui/input/label/Label";
import InputText from "../../ui/input/text/Text";
import InputNumber from "../../ui/input/number/Number";
import styles from "./Verification.module.scss";
import { useTranslation } from "../../../utils/hooks";
import { buildFormVerification } from "./functions";
import { postClient } from "../../../utils/restclient";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import {
  formEvents,
  rawVerificationEvents,
  transactionFormVerificationDefault,
} from "../../../utils/variables";
import {
  checkPermission,
  dropdownGetValueFromUuid,
  handleFormOK,
} from "../../../utils/functions";
import {
  toastError,
  Dropdown,
  Button,
  Loading,
} from "@scrapadev/scrapad-front-sdk";
import { useParams } from "react-router-dom";
import { useRawEventBO } from "../../../utils/transactions/hooks";
import { parseVerificationRawEventData } from "../../../utils/transactions/functions";

/**
 * Verification form for transactions.
 * @returns {JSX.Element}
 */
const VerificationForm = ({ fetchData, permission }) => {
  const { id } = useParams();
  const { t } = useTranslation(["common", "transactions"]);
  const { state, dispatch } = useTransactionsFormsProvider();
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const checkdPermission = checkPermission(permission);
  const {
    rawLoading,
    eventData,
    fetchData: rawFetchData,
  } = useRawEventBO({
    uuidConversation: id,
    slug: rawVerificationEvents,
    eventAdapter: parseVerificationRawEventData,
    actions,
    dispatch,
  });

  const options = [
    {
      uuid: "LAUNCHED",
      text: t("launched"),
    },
    {
      uuid: "PENDING_COORDINATION",
      text: t("pending-coordinated"),
    },
    {
      uuid: "COORDINATED",
      text: t("coordinated"),
    },
    {
      uuid: "CANCELLED_BY_USER",
      text: t("cancelled-user"),
    },
  ];

  const checkIfAddOrUpdate = () => {
    return eventData
      ? formEvents.UPDATE_VERIFICATION
      : formEvents.ADD_VERIFICATION;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: actions.UPDATE_INPUT, payload: { loading: true } });
    const data = buildFormVerification(
      user.userUuid,
      document.getElementById("slug").value,
      state
    );
    try {
      await postClient({
        external: true,
        url: `${process.env.REACT_APP_API_EVENTS}`,
        method: "POST",
        body: data,
        signOut,
        setDataUser,
      });
      handleFormOK(t, fetchData);
    } catch (error) {
      toastError(error.message);
    } finally {
      rawFetchData();
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: { loading: false },
      });
    }
  };
  const handleOnChange = (key) => (e) => {
    const obj = {};
    obj[key] = key === "price" ? e.target.valueAsNumber : e.target.value;
    dispatch({ type: actions.UPDATE_INPUT, payload: obj });
  };
  const handleItemClick = (option, open, setOpen) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { state: option.uuid },
    });
    setOpen(!open);
  };

  if (rawLoading) return <Loading />;

  return (
    <form onSubmit={handleSubmit} className={styles["sc-form"]}>
      <Container>
        <div className="row">
          <div className="col-12">
            <div className={styles["sc-item"]}>
              <Label>{t("provider")}</Label>
              <InputText
                required
                readOnly={!checkdPermission}
                placeholder={t("write-here")}
                value={state.suplier}
                onChange={handleOnChange("suplier")}
              />
            </div>
          </div>
          <div className="col-6 col-lg-6 col-xl-3 col-xxl-3">
            <div className={styles["sc-item"]}>
              <Label>{t("price")}</Label>
              <InputNumber
                readOnly={!checkdPermission}
                required
                placeholder={t("write-here")}
                min={0}
                step={".01"}
                value={state.price}
                trailingText={"€"}
                onChange={handleOnChange("price")}
              />
            </div>
          </div>
          <div className="col-6 col-lg-6 col-xl-5 col-xxl-5">
            <div className={styles["sc-item"]}>
              <Label>{t("status")}</Label>
              <Dropdown
                readOnly={!checkdPermission}
                required
                placeholder={t("select-value")}
                options={options}
                value={dropdownGetValueFromUuid(state, options)}
                handleItemClick={handleItemClick}
              />
            </div>
          </div>
          <div
            className={`col-6 col-lg-6 col-xl-4 col-xxl-4 offset-6 offset-xl-0 ${styles["sc-submit"]}`}
          >
            <Button
              component="button"
              text={t("save")}
              fullwidth={true}
              loading={state.loading}
              disabled={!checkdPermission}
            />
          </div>
        </div>
      </Container>
      <input
        type="hidden"
        id={"slug"}
        name={"slug"}
        value={checkIfAddOrUpdate()}
      />
    </form>
  );
};

export default VerificationForm;
