import React from "react";
import styles from "./TabPanelWrap.module.scss";
import { IconButton } from "@scrapadev/scrapad-front-sdk";

function TabPanelWrap({ title, onClose, children }) {
  return (
    <div className={styles["tab-panel-wrap"]}>
      {title || onClose ? (
        <>
          <div className={styles["header"]}>
            {title && <h6 className={styles["title"]}>{title}</h6>}
            {onClose && (
              <div className={styles["action"]}>
                <IconButton
                  name="close"
                  onClick={onClose}
                  data-cy="transaction_message_tab_close_button"
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
      {children}
    </div>
  );
}

export default TabPanelWrap;
