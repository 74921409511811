import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { useMemo } from "react";

/*const authLink = setContext((_, { headers }) => {
  const aud = localStorage.getItem("aud");
  const userUuid = localStorage.getItem("user-uuid");
  const idToken = localStorage.getItem(
    `CognitoIdentityServiceProvider.${aud}.${userUuid}.idToken`
  );
  console.log(idToken);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: idToken ? `Bearer ${idToken}` : "",
    },
  };
});*/

export const createApolloClient = (withWebSockets = false) => {
  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const aud = localStorage.getItem("aud");
    const userUuid = localStorage.getItem("user-uuid");
    const idToken = localStorage.getItem(
      `CognitoIdentityServiceProvider.${aud}.${userUuid}.idToken`
    );
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: idToken ? `Bearer ${idToken}` : "",
      },
    }));

    return forward(operation);
  });
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  };
  function exponentialBackoff(attempt) {
    return Math.min(1000 * 2 ** attempt, 30000);
  }

  const url = process.env.REACT_APP_MARKETPLACE_URL?.replace("https", "wss");
  const wsLink = !withWebSockets
    ? null
    : new GraphQLWsLink(
        createClient({
          url: `${url}/${process.env.REACT_APP_SCRAPAD_API_VERSION}/notifications/gql/query`,
          lazy: false,
          keepAlive: 15000,
          retryAttempts: 10,
          retryTimeout: (attempt) => exponentialBackoff(attempt),
          connectionParams: () => {
            const aud = localStorage.getItem("aud");
            const userUuid = localStorage.getItem("user-uuid");
            const idToken = localStorage.getItem(
              `CognitoIdentityServiceProvider.${aud}.${userUuid}.idToken`
            );
            return {
              headers: {
                authorization: idToken ? `Bearer ${idToken}` : "",
              },
            };
          },
        })
      );

  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_MARKETPLACE_URL}/${process.env.REACT_APP_SCRAPAD_API_VERSION}/gql/query`,
  });
  const splitLink = wsLink
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
          );
        },
        wsLink,
        concat(authMiddleware, httpLink)
      )
    : concat(authMiddleware, httpLink);
  return new ApolloClient({
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
    link: splitLink,
  });
};
const client = createApolloClient(false);

export const useScpApolloClient = (withWebSockets = false) => {
  const cl = useMemo(
    () => createApolloClient(withWebSockets),
    [withWebSockets]
  );

  return cl;
};

export default client;
