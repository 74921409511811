import {
  Divider,
  Paper,
  Row,
  Typography,
  UnderlinedLink,
  WARM_NEUTRAL_10,
  WARM_NEUTRAL_60,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";
import WidgetOpportunitiesFormItem from "./OpportunitiesCostFormItem";
import WidgetOportunitiestextitem from "./OpportunitiesTextItem";
import WidgetOpportunitiesFormItemAlt from "./OpportunitiesCostFormItemAlt";
import _ from "lodash";

/**
 * Helper component to show costs totals.
 * @param {object} props - Component properties.
 * @param {object} props.state - Costs current state.
 * @param {Function} props.onEditPaymentConditions - on edit click
 * @returns {JSX.Element}
 */
const WidgetOpportunitiesTotals = ({
  state,
  hideNetMargin,
  opportunityType,
  itsMineOpportunity,
  onEditPaymentConditions = () => {},
}) => {
  const { t } = useTranslation(["common"]);

  const renderAmount = (amount) => {
    if (!amount) return "";
    return `${amount}`;
  };

  if (opportunityType === "sale" && itsMineOpportunity) {
    return (
      <Paper whiteMode style={{ alignItems: "stretch" }}>
        {!hideNetMargin && (
          <WidgetOpportunitiesFormItem
            key={1}
            slug="cost-net-margin"
            leftComponent={
              <WidgetOportunitiestextitem
                text={`${t("net-margin")} (${t("scrapad")})`}
              />
            }
            rightComponent={
              <WidgetOportunitiestextitem text={state.margin} bolder />
            }
          />
        )}

        <WidgetOpportunitiesFormItemAlt
          key={3}
          title={t("to-deposit-buyer")}
          price={renderAmount(state.toDeposit.value)}
          subTitle={
            !_.isEmpty(renderAmount(state.toDeposit.perTon))
              ? t("total")
              : undefined
          }
          subPrice={
            !_.isEmpty(renderAmount(state.toDeposit.perTon))
              ? renderAmount(state.toDeposit.perTon)
              : undefined
          }
          bottomComponent={
            <Paper>
              <Row align="center" justify="space-between">
                <Typography
                  noMargin
                  small
                  style={{ fontWeight: 500, color: WARM_NEUTRAL_60 }}
                >
                  {state.toDeposit.paymentMethod}
                </Typography>
              </Row>
            </Paper>
          }
        />

        <Divider
          style={{
            marginTop: "5px",
            marginBottom: "5px",
            borderColor: WARM_NEUTRAL_10,
          }}
        />

        <WidgetOpportunitiesFormItemAlt
          key={2}
          title={t("to-perceive-seller")}
          price={renderAmount(state.toPerceive.value)}
          subTitle={
            !_.isEmpty(renderAmount(state.toPerceive.perTon))
              ? t("total")
              : undefined
          }
          subPrice={
            !_.isEmpty(renderAmount(state.toPerceive.perTon))
              ? renderAmount(state.toPerceive.perTon)
              : undefined
          }
          bottomComponent={
            <Paper style={{ alignItems: "stretch" }}>
              <Row align="center" justify="space-between">
                <Typography
                  noMargin
                  small
                  style={{ fontWeight: 500, color: WARM_NEUTRAL_60 }}
                >
                  {state.toPerceive.paymentMethod}
                </Typography>
                <UnderlinedLink onClick={() => onEditPaymentConditions(state)}>
                  {t("edit")}
                </UnderlinedLink>
              </Row>
            </Paper>
          }
        />
      </Paper>
    );
  }

  return (
    <Paper whiteMode>
      {!hideNetMargin && (
        <WidgetOpportunitiesFormItem
          key={1}
          slug="cost-net-margin"
          leftComponent={
            <WidgetOportunitiestextitem
              text={`${t("net-margin")} (${t("scrapad")})`}
            />
          }
          rightComponent={
            <WidgetOportunitiestextitem text={state.margin} bolder />
          }
        />
      )}

      <WidgetOpportunitiesFormItem
        key={2}
        alignItems="flex-start"
        slug="cost-perceive-value"
        leftComponent={
          <WidgetOportunitiestextitem
            text={t("to-perceive-seller")}
            description={state.toPerceive.paymentMethod}
          />
        }
        rightComponent={
          <WidgetOportunitiestextitem
            text={renderAmount(state.toPerceive.perTon)}
            description={renderAmount(state.toPerceive.value)}
            light
            style={{ alignItems: "flex-end" }}
          />
        }
      />
      <WidgetOpportunitiesFormItem
        key={3}
        alignItems="flex-start"
        slug="cost-deposit-value"
        leftComponent={
          <WidgetOportunitiestextitem
            text={t("to-deposit-buyer")}
            description={state.toDeposit.paymentMethod}
          />
        }
        rightComponent={
          <WidgetOportunitiestextitem
            text={renderAmount(state.toDeposit.perTon)}
            description={renderAmount(state.toDeposit.value)}
            light
            style={{ alignItems: "flex-end" }}
          />
        }
        isLast
      />
    </Paper>
  );
};

export default WidgetOpportunitiesTotals;
