import { gql } from "@apollo/client";
import client from "../utils/gqlclient";

export async function getNewQuotaion({ lang, uuidRequest }) {
  if (JSON.parse(process.env.REACT_APP_QUERY_QUOTATIONS) && uuidRequest) {
    const data = await client.query({
      query: gqlGetQuotationInfo,
      variables: {
        lang,
        uuidRequest,
      },
    });
    return data?.data?.getQuotationInfo;
  } else {
    const data = await Promise.resolve({
      data: {
        quotation: {
          type: "purchase",
          Destination: "",
          Origin: "",
          TypeOfMerchandise: "",
          TypeOfTransport: "empty",
          WeightOfBunddle: 0,
          Weight: null,
          Volume: null,
          FormatOfMerchandise: "empty",
          Dimensions: [0],
          providers: [],
        },
      },
    });
    return data.errors ? { errors: data.errors } : data.data.quotation;
  }
}

export async function getTracking({ uuidConversation, lang = "es" }) {
  const data = await client.query({
    query: gqlGetTracking,
    variables: {
      uuidConversation,
      lang,
    },
  });
  return data?.data?.getTrackingForConversation;
}

export async function getQuotationRequest({ uuidRequest, lang }) {
  const data = await client.query({
    query: gqlGetQuotationRequest,
    variables: {
      uuidRequest,
      lang,
    },
  });
  return data?.data?.getQuotationRequestDetails;
}

export async function getPricingQuotations({ locale = "es" }) {
  const data = await client.query({
    query: gqlGetLogisticPricingData,
    variables: {
      lang: locale,
    },
  });
  return data?.data?.getLogisticPricingData?.logisticPricing || {};

  // await sleep(1500);
  // return Promise.resolve({
  //   data: {
  //     quotations: [
  //       {
  //         key: "pending",
  //         label: "Cotización pendiente",
  //         items: [
  //           {
  //             uuid: "5a8c3d74-138f-4794-a3d2-4bb3124cde58",
  //             type: "opportunity",
  //             status: "pending",
  //             title: "Briquetas de aluminio",
  //             image: "https://picsum.photos/200/300",
  //             buyer: "Deyvid EOOD",
  //             seller: "SCRAPAD",
  //             address: "EXW Loiu (España) - CIF Port Blair (India)",
  //             isPort: false,
  //             date: 1712746895501,
  //           },
  //           {
  //             uuid: "5a8c3d74-138f-4794-a3d2-4bb3124cde58",
  //             type: "opportunity",
  //             status: "pending",
  //             title: "Briquetas de aluminio",
  //             image: "https://picsum.photos/200/300",
  //             buyer: "Deyvid EOOD",
  //             seller: "SCRAPAD",
  //             address: "EXW Loiu (España) - CIF Port Blair (India)",
  //             isPort: false,
  //             date: 1712746895501,
  //           },
  //         ],
  //       },
  //       {
  //         key: "launched",
  //         label: "Cotización lanzada",
  //         items: [
  //           {
  //             uuid: "5a8c3d74-138f-4794-a3d2-4bb3124cde58",
  //             type: "opportunity",
  //             status: "pending",
  //             title: "Briquetas de aluminio",
  //             image: "https://picsum.photos/200/300",
  //             buyer: "Deyvid EOOD",
  //             seller: "SCRAPAD",
  //             address: "EXW Loiu (España) - CIF Port Blair (India)",
  //             isPort: false,
  //             date: 1712746895501,
  //           },
  //         ],
  //       },
  //       {
  //         key: "expired",
  //         label: "Cotización expirada",
  //         items: [],
  //       },
  //     ],
  //   },
  // });
}

export async function getLogisticTracking({ locale = "es" }) {
  const data = await client.query({
    query: gqlGetLogisticTrackingData,
    variables: {
      lang: locale,
    },
  });
  return data?.data?.getLogisticTrackingData || [];

  // await sleep(1500);
  // return Promise.resolve({
  //   logisticTracking: [
  //     {
  //       key: "origin",
  //       label: "Origen",
  //       items: [
  //         {
  //           uuid: "943gn9k",
  //           imageSrc: "https://placehold.co/45",
  //           title: "Calderín de cobre con estaño, libre de Fe y Al",
  //           address: "EXW Loiu (España) - CIF Port Blair (India)",
  //           isPort: false,
  //           status: "Carga - Pendiente de coordinación",
  //           date: 1713953379942,
  //           originOrg: "ReciclicSA",
  //           destinationOrg: "Metalino",
  //           tracking: [
  //             {
  //               desc: "test",
  //               phase: "success",
  //               entries: [
  //                 {
  //                   desc: "entrie 1",
  //                   date: 1713947996352,
  //                 },
  //               ],
  //             },
  //             {
  //               desc: "test",
  //               phase: "success",
  //               entries: [
  //                 {
  //                   desc: "entrie 1",
  //                   date: 1713947996352,
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           uuid: "943gn9k",
  //           imageSrc: "https://placehold.co/45",
  //           title: "Calderín de cobre con estaño, libre de Fe y Al",
  //           address: "EXW Loiu (España) - CIF Port Blair (India)",
  //           isPort: false,
  //           status: "Carga - Pendiente de coordinación",
  //           date: 1713953379942,
  //           originOrg: "ReciclicSA",
  //           destinationOrg: "Metalino",
  //           tracking: [
  //             {
  //               desc: "test",
  //               phase: "success",
  //               entries: [
  //                 {
  //                   desc: "entrie 1",
  //                   date: 1713947996352,
  //                 },
  //               ],
  //             },
  //             {
  //               desc: "test",
  //               phase: "success",
  //               entries: [
  //                 {
  //                   desc: "entrie 1",
  //                   date: 1713947996352,
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           uuid: "943gn9k",
  //           imageSrc: "https://placehold.co/45",
  //           title: "Calderín de cobre con estaño, libre de Fe y Al",
  //           address: "EXW Loiu (España) - CIF Port Blair (India)",
  //           isPort: false,
  //           status: "Carga - Pendiente de coordinación",
  //           date: 1713953379942,
  //           originOrg: "ReciclicSA",
  //           destinationOrg: "Metalino",
  //           tracking: [
  //             {
  //               desc: "test",
  //               phase: "success",
  //               entries: [
  //                 {
  //                   desc: "entrie 1",
  //                   date: 1713947996352,
  //                 },
  //               ],
  //             },
  //             {
  //               desc: "test",
  //               phase: "success",
  //               entries: [
  //                 {
  //                   desc: "entrie 1",
  //                   date: 1713947996352,
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //         {
  //           uuid: "943gn9k",
  //           imageSrc: "https://placehold.co/45",
  //           title: "Calderín de cobre con estaño, libre de Fe y Al",
  //           address: "EXW Loiu (España) - CIF Port Blair (India)",
  //           isPort: false,
  //           status: "Carga - Pendiente de coordinación",
  //           date: 1713953379942,
  //           originOrg: "ReciclicSA",
  //           destinationOrg: "Metalino",
  //           tracking: [
  //             {
  //               desc: "test",
  //               phase: "success",
  //               entries: [
  //                 {
  //                   desc: "entrie 1",
  //                   date: 1713947996352,
  //                 },
  //               ],
  //             },
  //             {
  //               desc: "test",
  //               phase: "success",
  //               entries: [
  //                 {
  //                   desc: "entrie 1",
  //                   date: 1713947996352,
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       key: "transit",
  //       label: "Tránsito",
  //       items: [],
  //     },
  //     {
  //       key: "destination",
  //       label: "Destino",
  //       items: [],
  //     },
  //   ],
  // });
}

const gqlGetTracking = gql`
  query ($uuidConversation: String!, $lang: String!) {
    getTrackingForConversation(
      uuidConversation: $uuidConversation
      lang: $lang
    ) {
      tracking {
        desc
        phase
        entries {
          date
          desc
          estimatedHours
          state
        }
      }
      lastPhase
    }
  }
`;
const gqlGetQuotationInfo = gql`
  query ($uuidRequest: String!, $lang: String!) {
    getQuotationInfo(uuidRequest: $uuidRequest, lang: $lang) {
      Destination
      Origin
      TypeOfMerchandise
      TypeOfTransport
      WeightOfBunddle
      Weight
      Volume
      FormatOfMerchandise
      Dimensions
    }
  }
`;

const gqlGetQuotationRequest = gql`
  query ($lang: String!, $uuidRequest: String!) {
    getQuotationRequestDetails(lang: $lang, uuidRequest: $uuidRequest) {
      Expired
      Destination
      Origin
      TypeOfMerchandise
      TypeOfTransport
      Weight
      Characteristics
      NumberOfPackages
    }
  }
`;

const gqlGetLogisticPricingData = gql`
  query ($lang: String!) {
    getLogisticPricingData(lang: $lang) {
      logisticPricing {
        key
        label
        items {
          uuid
          quotationid
          type
          status
          title
          image
          origin
          target
          address
          isPort
          date
        }
      }
    }
  }
`;

const gqlGetLogisticTrackingData = gql`
  query ($lang: String!) {
    getLogisticTrackingData(lang: $lang) {
      logisticTracking {
        key
        label
        items {
          id
          quotationid
          image_src
          title
          address
          is_port
          status
          date
          originOrg
          destinationOrg
        }
      }
    }
  }
`;
