import InputText from "../text/Text";

/**
 * Validation component for multiple fields. For example: two checkboxes.
 * @param {Object} props - Component properties.
 * @param {String} props.value - Value.
 * @param {String} props.customValidityMessage - Validation message.
 * @returns {JSX.Element}
 */
const ValidateGroup = ({ value, customValidityMessage }) => {
  return (
    <InputText
      required={true}
      value={value}
      customValidityMessage={customValidityMessage}
      style={{
        visibility: "hidden",
        height: 0,
        padding: 0,
        margin: `0 0 .5rem 0`,
      }}
    />
  );
};

export default ValidateGroup;
