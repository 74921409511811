import _ from "lodash";
import React, {
  useContext,
  useReducer,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { selectedOrgKey } from "../../utils/variables";
import { actions } from "./actions";

const FiltersContext = React.createContext({
  state: null,
  dispatch: null,
});

const getInitialState = () => {
  return {};
};

const addFilter = (state, payload) => {
  const { item, filter } = payload;
  const isAlreadySelected = state[filter.slug]?.selected?.find(
    (f) => f.uuid === item.uuid
  );
  return isAlreadySelected
    ? {
        ...state,
        [filter.slug]: {
          ...state[filter.slug],
          selected: state[filter.slug]?.selected?.filter(
            (f) => f.uuid !== item.uuid
          ),
        },
      }
    : {
        ...state,
        [filter.slug]: {
          ...state[filter.slug],
          selected: [...state[filter.slug]?.selected, item],
        },
      };
};

const addUniqueFilter = (state, payload) => {
  const { item, filter } = payload;
  return {
    ...state,
    [filter.slug]: {
      ...state[filter.slug],
      selected: [item],
    },
  };
};

const addMultipleFilter = (state, payload) => {
  const { item, filter } = payload;
  return {
    ...state,
    [filter.slug]: {
      ...state[filter.slug],
      selected: [...item],
    },
  };
};

const addSingleFilter = (state, payload) => {
  const { item, filter } = payload;
  return {
    ...state,
    [filter.slug]: {
      ...state[filter.slug],
      selected: [item],
    },
  };
};

const removeFilter = (state, payload) => {
  const { item, filter } = payload;
  return {
    ...state,
    [filter.slug]: {
      ...state[filter.slug],
      selected: state[filter.slug]?.selected.filter(
        (f) => f.uuid !== item.uuid
      ),
    },
  };
};

const removeMultipleFilter = (state, payload) => {
  const { item, filter } = payload;
  return {
    ...state,
    [filter.slug]: {
      ...state[filter.slug],
      selected: state[filter.slug]?.selected.filter(
        (f) => !item.uuids.includes(f.uuid)
      ),
    },
  };
};

const clearAll = (state) => {
  const removedFilters = { ...state };
  Object.keys(removedFilters).forEach((key) => {
    removedFilters[key].selected = [];
  });
  return removedFilters;
};

const updateWhole = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};

const updateData = (state, payload) => {
  const { slug } = payload;
  if (!state[slug]?.data) return state;
  return {
    ...state,
    [slug]: {
      ...state[slug],
      data: { [slug]: { ...state[slug].data[slug], ...payload[slug] } },
    },
  };
};

const clearData = (state, payload) => {
  const { slug } = payload;
  if (!state[slug]?.data) return state;
  return {
    ...state,
    [slug]: {
      ...state[slug],
      data: { [slug]: payload },
    },
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.INITIAL_LOAD:
      return { ...action.payload };
    case actions.ADD_FILTER:
      return addFilter(state, action.payload);
    case actions.ADD_UNIQUE_FILTER:
      return addUniqueFilter(state, action.payload);
    case actions.ADD_MULTIPLE_FILTER:
      return addMultipleFilter(state, action.payload);
    case actions.ADD_SINGLE_FILTER:
      return addSingleFilter(state, action.payload);
    case actions.REMOVE_FILTER:
      return removeFilter(state, action.payload);
    case actions.REMOVE_MULTIPLE_FILTER:
      return removeMultipleFilter(state, action.payload);
    case actions.CLEAR_FILTER:
      return clearAll(state);
    case actions.UPDATE_WHOLE_FILTER:
      return updateWhole(state, action.payload);
    case actions.UPDATE_INPUT:
      return updateData(state, action.payload);
    case actions.CLEAR_INPUT:
      return clearData(state, action.payload);
    default:
      return state;
  }
};

const filtersKey = "filters";

export const FiltersProvider = ({ storageKey, keysMerged, children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());
  const filterChanged = useRef(false);
  const forceFiltersFromStore = useRef(false);

  useEffect(() => {
    if (storageKey) {
      let savedFilters = {};
      const keys = Object.keys(state || {});
      keys.forEach((key) => {
        const filter = state[key];
        if (!savedFilters[key]) {
          savedFilters[key] = {};
        }
        savedFilters[key]["selected"] = filter?.selected;
        if (filter?.data) {
          savedFilters[key]["data"] = filter?.data;
        }
      });
      if (keysMerged) {
        if (!localStorage.getItem(filtersKey)) {
          localStorage.setItem(filtersKey, JSON.stringify({}));
        }
        const filter = JSON.parse(localStorage.getItem(filtersKey));
        if (Object.keys(savedFilters).length > 0 && storageKey) {
          filter[storageKey] = savedFilters;
          localStorage.setItem(filtersKey, JSON.stringify(filter));
        }
      } else {
        if (Object.keys(savedFilters).length > 0 && storageKey) {
          localStorage.setItem(storageKey, JSON.stringify(savedFilters));
        }
      }
    }
  }, [state]);

  const contextValue = useMemo(
    () => ({
      storageKey,
      state,
      dispatch,
      actions,
      checkForFilterSelected: (filters) => {
        let found = false;
        if (filters === undefined) return false;
        Object.keys(filters)?.forEach((key) => {
          if (!found) {
            const filter = filters[key];
            found = filter.selected.length > 0;
          }
        });
        return found;
      },
      buildFiltersAndFetch: (filters, fetchData, extraOps, label) => {
        let ops = [];
        let filtersKeys = Object.keys(filters || {});
        const otherFilters = {};
        filtersKeys.forEach((key) => {
          const filter = filters[key];
          if (filter?.selected?.length > 0) {
            if (key !== selectedOrgKey) {
              switch (key) {
                case "materials":
                  otherFilters["byMaterial"] = {};
                  const materials = filter?.data?.materials;
                  if (materials?.category?.uuidSelected?.length > 0) {
                    otherFilters["byMaterial"]["materialFamily"] =
                      materials?.category?.uuidSelected?.join(",");
                  }
                  if (materials?.material?.uuidSelected?.length > 0) {
                    otherFilters["byMaterial"]["materialComposition"] =
                      materials?.material?.uuidSelected?.join(",");
                  }
                  if (materials?.type?.uuidSelected?.length > 0) {
                    otherFilters["byMaterial"]["materialType"] =
                      materials?.type?.uuidSelected;
                  }
                  break;
                case "dates":
                case "createdAt":
                case "updatedAt":
                case "lastMovement":
                  ops.push({
                    field: key,
                    value: `${filter?.selected?.[0]?.from},${filter?.selected?.[0]?.to}`,
                  });
                  break;
                case "phase":
                  const parentUuids = filter?.selected?.filter(
                    (f) => !f.parent
                  );
                  const children = filter?.selected?.filter((f) => f.parent);
                  const phaseNewSelected = [];
                  parentUuids?.forEach((parent) => {
                    const selectedChildren = children?.filter(
                      (f) => f?.parent === parent?.uuid
                    );
                    const subItems = state[key]?.items?.find(
                      (f) => f?.uuid === parent?.uuid
                    )?.subItems;
                    if (selectedChildren?.length === 0)
                      phaseNewSelected.push(parent?.uuid);
                    else if (selectedChildren?.length === subItems?.length)
                      phaseNewSelected.push(parent?.uuid);
                    else
                      phaseNewSelected.push(
                        ...selectedChildren?.map((f) => f?.uuid)
                      );
                  });
                  ops.push({
                    field: key,
                    value: phaseNewSelected.join(","),
                  });
                  break;
                default:
                  ops.push({
                    field: key,
                    value: filter?.selected?.map((f) => f.uuid).join(","),
                  });
                  break;
              }
            }
          }
        });
        let finalFilters = {};
        if (ops.length > 0) finalFilters = { ...finalFilters, ops: ops };
        if (extraOps && extraOps.length > 0) {
          finalFilters = {
            ...finalFilters,
            ops: [...(finalFilters?.ops || []), ...extraOps],
          };
        }
        if (Object.keys(otherFilters).length > 0)
          finalFilters = { ...finalFilters, ...otherFilters };
        if (!fetchData) return finalFilters;
        Object.keys(finalFilters).length > 0
          ? fetchData({ filter: finalFilters })
          : fetchData();
      },
      getStoredFilters: () => {
        if (keysMerged) {
          return JSON.parse(localStorage.getItem(filtersKey))?.[storageKey];
        }
        return JSON.parse(localStorage.getItem(storageKey));
      },
      cleanFilters: () => {
        filterChanged.current = true;
        dispatch({
          type: actions.CLEAR_FILTER,
        });
      },
      cleanStoredFilters: () => {
        if (keysMerged) {
          const filter = JSON.parse(localStorage.getItem(filtersKey));
          delete filter[storageKey];
        } else {
          if (storageKey) localStorage.removeItem(storageKey);
        }
      },
      checkIfSomeStoredFilter: () => {
        if (storageKey) {
          let found = false;
          const parsed = JSON.parse(localStorage.getItem(storageKey));
          const keys = Object.keys(parsed || {});
          keys.forEach((key) => {
            const filter = parsed[key];
            if (filter?.selected?.length > 0) {
              found = true;
            }
          });
          return found;
        }
        return false;
      },
      filterChanged,
      forceFiltersFromStore,
    }),
    [state, dispatch, storageKey]
  );

  return (
    <FiltersContext.Provider value={contextValue}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useFiltersProvider = () => useContext(FiltersContext);
