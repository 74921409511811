import React from "react";
import styles from "./BulkActions.module.scss";
import { useDocumentsProvider } from "../../../../../providers/documents/DocumentsContext";
import {
  Icon,
  IconButton,
  Separator,
  Spinner,
  WARM_NEUTRAL_20,
} from "@scrapadev/scrapad-front-sdk";
import { documentActions } from "../../../../../providers/documents/actions/DocumentsActions";
import { useTranslation } from "../../../../../utils/hooks";

function BulkActions() {
  const { t } = useTranslation("common");
  const { state, dispatch, bulkDownload } = useDocumentsProvider();

  if (state?.bulkSelected.length > 0) {
    return (
      <div className={styles["bulk_actions"]}>
        <div className={styles["elements_selected"]}>
          <span className={styles["text"]}>
            {state?.bulkSelected.length} {t("selected_elements")}
          </span>
          <div
            className={styles["close"]}
            onClick={() => {
              dispatch({
                type: documentActions.CLEAN_SELECTED_FILES,
              });
            }}
          >
            <Icon name={"close"} style={{ fontSize: "16px" }} />
          </div>
        </div>
        <Separator style={{ backgroundColor: WARM_NEUTRAL_20 }} />
        {state?.downloading ? (
          <Spinner />
        ) : (
          <IconButton
            name="file_download"
            onClick={() => {
              bulkDownload();
            }}
          />
        )}

        {/* <IconButton name="person_add_alt" onClick={() => {}} /> */}
        <Separator style={{ backgroundColor: WARM_NEUTRAL_20 }} />
      </div>
    );
  }

  return <></>;
}

export default BulkActions;

