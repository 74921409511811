import { ClearButton, Flex, Icon } from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import SearchFilterExport from "../../components/ui/search-filter-export/SearchFilterExport";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { useTranslation } from "../../utils/hooks";
import { TEXT_COLOR_LIGHT } from "../../utils/variables";

const AdsSearchExport = ({
  filterKey = "adText",
  open,
  setOpen,
  mode,
  exportProps,
}) => {
  const { t } = useTranslation(["common"]);

  return (
    <SearchFilterExport
      searchPlaceholder={t("search")}
      open={open}
      setOpen={setOpen}
      exportProps={exportProps}
      hasSeparator={false}
      //hasFilters={false}
      hasExport={mode === "table"}
    />
  );
};

export default AdsSearchExport;
