import React, { useEffect, useRef, useState } from "react";
import Container from "../../components/ui/container/Container";
import NavBar from "../../components/ui/navbar/NavBar";
import { getTransactionsByObject } from "../../model/tables";
import { useTranslation } from "../../utils/hooks";
import SearchFilterExport from "../../components/ui/search-filter-export/SearchFilterExport";
import Header from "../../components/ui/header/Header";
import { useDocumentTitle } from "../../utils/document/hooks";
import { sortTable } from "../../utils/functions";
import { useSetDataUser, useSignOut, useUser } from "../../providers/AuthHooks";
import { gqlWrapper } from "../../utils/gqlwrapper";
import { TableManager, toastError } from "@scrapadev/scrapad-front-sdk";
import { useLocation, useNavigate } from "react-router-dom";
import {
  QUERY_UPPER_LIMIT,
  TABLE_LIMIT,
  transactionTypeInfo,
} from "../../utils/variables";
import Filters from "../../components/ui/filters/Filters";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { usePaginationProvider } from "../../providers/pagination/PaginationContext";
import {
  useDateFilter,
  useMaterialsFilter,
  usePhaseFilter,
  useTurnFilter,
} from "../../utils/filters/hooks";
import { useSortProvider } from "../../providers/sort/SortContext";
import { useTableActions } from "../../utils/tables/hooks";
import { applySortToFilters } from "../../utils/tables/functions";
import { getRoomSlug, WS_ROOM } from "../../utils/websockets/functions";
import _ from "lodash";
import WebsocketsWrapper from "../../widgets/websockets/Wrapper";

/**
 * Transactions page
 * @returns {JSX.Element}
 */
const Transactions = () => {
  const { pathname } = useLocation();
  const [data, setData] = useState({ header: [], body: [] });
  const dataBuffer = useRef({ header: [], body: [] });
  const [loading, setLoading] = useState(true);
  const { t, i18n, values } = useTranslation(["common", "transactions"], true);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const user = useUser();
  const sortedField = useRef(null);
  const sortedDirection = useRef(null);
  const navigate = useNavigate();
  const { checkIfSomeStoredFilter, buildFiltersAndFetch, getStoredFilters } =
    useFiltersProvider();
  const {
    handlePagination,
    getStoredPagination,
    getStoredPaginationIfBackPressed,
  } = usePaginationProvider();
  const filters = buildFiltersAndFetch(getStoredFilters() || {});
  const dateFilterStart = useDateFilter("dates", t("start-date"));
  const dateFilterLastMovement = useDateFilter(
    "lastMovement",
    t("last-action")
  );
  const materialsFilter = useMaterialsFilter("materials", t("material"));
  const phaseFilter = usePhaseFilter();
  const turnFilter = useTurnFilter();
  const { state: sortState, handleSorting, buildSort } = useSortProvider();
  const sortFields = buildSort(sortState);
  const isFetched = useRef(false);

  const buildAndFetch = (isWithBackButton = false) => {
    buildFiltersAndFetch(getStoredFilters(), (params) => {
      // Tenemos que usar una función distinta si es la carga inicial o si es el long polling.
      // Si es carga inicial tenemos en cuenta si viene de pulsar el boton de atras, en caso contrario usamos los filtros almacenados
      const pagintionFn = isWithBackButton
        ? getStoredPaginationIfBackPressed
        : getStoredPagination;
      fetchData(
        {
          offset: pagintionFn("transactionsPagination")?.current
            ? pagintionFn("transactionsPagination")?.current
            : 0,
          filter: applySortToFilters(
            checkIfSomeStoredFilter() ? params?.filter : undefined,
            sortFields,
            "REACT_APP_SORT_TRANSACTIONS"
          ),
        },
        true
      );
    });
  };

  useDocumentTitle(`${t("transactions")}`, values);

  useEffect(() => {
    if (!isFetched.current) {
      buildAndFetch(true);
      isFetched.current = true;
    }
  }, [i18n.language]);

  useTableActions({
    paginationCallback: (paginationState) => {
      const newFilters = buildFiltersAndFetch(
        getStoredFilters() || {},
        undefined
      );
      fetchData({
        offset: paginationState?.current,
        filter: applySortToFilters(
          newFilters,
          sortFields,
          "REACT_APP_SORT_TRANSACTIONS"
        ),
      });
    },
  });

  const webSocketCallback = (data) => {
    if (!_.isEmpty(data) && data?.subscribeRoom?.type === WS_ROOM.transaction) {
      buildAndFetch();
    }
  };

  const fetchData = async (fnParameters, quiet = false) => {
    if (!quiet) setLoading(true);
    const tableParams = {
      locale: i18n.language,
      limit: TABLE_LIMIT,
      offset: getStoredPaginationIfBackPressed("transactionsPagination")
        ?.current,
      sort: sortState?.field ? sortState : undefined,
    };
    const fn = await gqlWrapper(getTransactionsByObject, setDataUser, signOut);
    const results = fnParameters
      ? await fn({
          ...tableParams,
          ...fnParameters,
        })
      : await fn(tableParams);
    if (results === null) {
      setData({ header: [], body: [], pagination: null });
    } else {
      if (results?.errors?.length > 0) {
        setData({ header: [], body: [] });
        toastError(results?.errors[0]?.message, {
          toastId: "transactions_data_error",
        });
      } else {
        const rows =
          sortedField.current !== null
            ? sortTable(
                results.rows,
                sortedField.current.ref,
                sortedDirection.current
              )
            : results.rows;
        setData({
          header: results.columns,
          body: rows,
          pagination: results.pagination,
        });
        dataBuffer.current = {
          header: results.columns,
          body: rows,
          pagination: results.pagination,
        };
      }
    }
    setLoading(false);
  };

  return (
    <>
      <NavBar />
      <Container isFluid={true}>
        <div className="row">
          <div className="col-12">
            <Header
              title={t("transactions")}
              titleRightComponent={
                <SearchFilterExport
                  filterKey="transactionText"
                  searchPlaceholder={t("search")}
                  searchProps={{ id: "TransSearch" }}
                  exportProps={{
                    fnData: getTransactionsByObject,
                    fnParameters: {
                      locale: i18n.language,
                      limit: QUERY_UPPER_LIMIT,
                      offset: 0,
                      filter: applySortToFilters(
                        filters,
                        sortFields,
                        "REACT_APP_SORT_TRANSACTIONS"
                      ),
                    },
                    fileName: "transactions",
                  }}
                  hasSeparator={false}
                  defaultOpen={checkIfSomeStoredFilter()}
                />
              }
              subHeaderLeft={
                <Filters
                  initialFilters={{
                    transactionText: {
                      external: true,
                    },
                    dates: dateFilterStart,
                    lastMovement: dateFilterLastMovement,
                    materials: materialsFilter,
                    phase: phaseFilter,
                    turn: turnFilter,
                  }}
                />
              }
              fullSubHeaderLeft
            />
          </div>
        </div>
      </Container>
      <Container isFluid={true}>
        <TableManager
          loading={loading}
          data={data}
          setData={setData}
          emptyText={t("empty-transactions")}
          rowTriggerPageChange={true}
          onAfterSort={(field, sortedAsc) => {
            sortedField.current = { ...field };
            sortedDirection.current = sortedAsc;
          }}
          navigate={(uuid) => navigate(uuid)}
          pagination={{
            currentPage: Math.ceil(data?.pagination?.current / TABLE_LIMIT),
            totalPages: Math.ceil(data?.pagination?.total / TABLE_LIMIT),
            threshold: 3,
            onChangePage: handlePagination,
            onChangePrev: handlePagination,
            onChangeNext: handlePagination,
          }}
          externalSort={
            JSON.parse(process.env.REACT_APP_SORT_TRANSACTIONS)
              ? handleSorting
              : undefined
          }
          sorting={buildSort(sortState)}
          itemsProps={{
            chip: {
              mapTransactionValues: transactionTypeInfo,
              translate: (code) => {
                if (!code) return "";
                if (code.includes("#")) {
                  const parsed = code.split("#");
                  const literals = parsed[1].split(",");
                  return literals.map((l) => t(l)).join(", ");
                }
                return t(code);
              },
            },
            split: {
              mapTransactionValues: transactionTypeInfo,
            },
            date: {
              languageData: i18n.getLanguageVariable(i18n.language),
            },
          }}
        />
      </Container>
      {user && (
        <WebsocketsWrapper
          callback={webSocketCallback}
          room={getRoomSlug(pathname)}
        />
      )}
    </>
  );
};

export default Transactions;
