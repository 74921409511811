import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "../../utils/hooks";
import WidgetDataWrapper from "../../widgets/wrappers/DataWrapper";
import { getOpportunity } from "../../model/opportunities";
import _ from "lodash";
import { parseAdStateToCheckIfDirty } from "../../utils/ads/functions";
import NavBar from "../../components/ui/navbar/NavBar";
import LayoutDetail from "../../layout/detail/Detail";

import Header from "../../components/ui/header/Header";

import { Center, Container, Loading } from "@scrapadev/scrapad-front-sdk";
import OpportunityForm from "../../widgets/opportunities/OpportunityForm";
import HistoryBlockWrapper from "../../wrappers/HistoryBlockWrapper";

function OpportunityPage({
  loading,
  setLoading,
  state,
  dispatch,
  actions,
  fetchData,
  extraData,
  isDirty,
  setIsDirty,
  bufferState,
  checkIfStateIsDirty,
  uuid,
  ...props
}) {
  const { id } = useParams();
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        isSticky
        headerStyle={{ marginBottom: 0 }}
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                {loading ? (
                  <Center>
                    <Loading />
                  </Center>
                ) : (
                  <Header
                    title={
                      id
                        ? `${t("edit", { ns: "common" })}: ${state?.title}`
                        : t("new-opportunity", { ns: "common" })
                    }
                    goBack
                    breadcrumbs={[
                      {
                        description: t("opportunities"),
                        onClick: () =>
                          navigate(`/opportunities`, { replace: true }),
                      },
                      {
                        description: id
                          ? state?.title
                          : t("new-opportunity", { ns: "common" }),
                      },
                    ]}
                    fullSubHeaderLeft
                    lowerLeftStyles={{ marginBottom: 20 }}
                  />
                )}
              </div>
            </div>
          </Container>
        }
        customRenderBody={(headerHeight, setHeaderHeight) => {
          return (
            <HistoryBlockWrapper
              mustBlockLeaving={isDirty}
              submitCallback={(modalOpen, setModalOpen) =>
                (e, loading, setLoading) => {
                  setModalOpen(false);
                }}
            >
              <Container style={{ marginTop: 20 }}>
                <OpportunityForm
                  state={state}
                  dispatch={dispatch}
                  actions={actions}
                  fetchData={fetchData}
                  extraData={{}}
                  isEdit={uuid}
                  headerHeight={headerHeight}
                  setHeaderHeight={setHeaderHeight}
                  isDirty={isDirty}
                  setIsDirty={setIsDirty}
                  uuid={uuid}
                  {...props}
                />
              </Container>
            </HistoryBlockWrapper>
          );
        }}
      />
    </>
  );
}

function Opportunity() {
  const { id } = useParams();
  const { i18n } = useTranslation(["common"]);
  return (
    <WidgetDataWrapper
      Component={OpportunityPage}
      fnData={getOpportunity}
      fnParameters={{
        uuid: id,
        locale: i18n.language,
        edit: true,
      }}
      uuid={id}
      customCompareFunction={(state, bufferState, checkIfStateIsDirty) => {
        const newState = _.cloneDeep(state);
        const newBuffer = _.cloneDeep(bufferState);
        parseAdStateToCheckIfDirty(newState);
        parseAdStateToCheckIfDirty(newBuffer);
        return checkIfStateIsDirty(newState, newBuffer);
      }}
    />
  );
}

export default Opportunity;
