import React, { useContext, useMemo, useState } from "react";

const OperationsContext = React.createContext({});

const roleOperationsName = {
  MANAGEMENT_ADS: "MANAGEMENT_ADS",
  MANAGEMENT_BUSINESS: "MANAGEMENT_BUSINESS",
  MANAGEMENT_ORG: "MANAGEMENT_ORG",
  MANAGEMENT_NEGOTIATIONS: "MANAGEMENT_NEGOTIATIONS",
  MANAGEMENT_FAVS: "MANAGEMENT_FAVS",
  MANAGEMENT_KYC: "MANAGEMENT_KYC",
  MANAGEMENT_WALLET: "MANAGEMENT_WALLET",
  MANAGEMENT_USER: "MANAGEMENT_USER",
  MANAGEMENT_TRANSACTIONS: "MANAGEMENT_TRANSACTIONS",
  MANAGEMENT_ADDRESS: "MANAGEMENT_ADDRESS",
  MANAGEMENT_FINANCES: "MANAGEMENT_FINANCES",
  MANAGEMENT_LOGISTICS: "MANAGEMENT_LOGISTICS",
  MANAGEMENT_USER_ROLES: "MANAGEMENT_USER_ROLES",
  MANAGEMENT_REPORTS: "MANAGEMENT_REPORTS",
  MANAGEMENT_FEE: "MANAGEMENT_FEE",
  MANAGEMENT_TRANSACTIONS_MESSAGES: "MANAGEMENT_TRANSACTIONS_MESSAGES",
  MANAGEMENT_TRANSACTIONS_SAMPLES_VERIFICATION_TRANSPORT:
    "MANAGEMENT_TRANSACTIONS_SAMPLES_VERIFICATION_TRANSPORT",
  MANAGEMENT_TRANSACTIONS_RETURN: "MANAGEMENT_TRANSACTIONS_RETURN",
  MANAGEMENT_OPPORTUNITIES: "MANAGEMENT_OPPORTUNITIES",
};

const roleOperationsMode = {
  ALL: "ALL",
  READ: "READ",
  NONE: "NONE",
};

export const OperationsProvider = ({
  emptyPermissionAllowOperations = false,
  children,
}) => {
  const storedRights = JSON.parse(localStorage.getItem("rights"));
  const [operations, setOperations] = useState(storedRights || []);

  const handleManageOperation = (operationName) => {
    if (!operations || operations.length === 0)
      return emptyPermissionAllowOperations ? true : false;
    const operation = operations.find(
      (f) => f?.operation === roleOperationsName[operationName]
    );
    if (!operation) return emptyPermissionAllowOperations ? true : false;
    return operation?.mode === roleOperationsMode.ALL ? true : false;
  };

  const handleReadOperation = (operationName) => {
    if (!operations || operations.length === 0)
      return emptyPermissionAllowOperations ? true : false;
    const operation = operations.find(
      (f) => f?.operation === roleOperationsName[operationName]
    );
    if (!operation) return emptyPermissionAllowOperations ? true : false;
    return operation?.mode === roleOperationsMode.NONE ? false : true;
  };

  const contextValue = useMemo(
    () => ({
      operations,
      setOperations,
      roleOperationsName,
      roleOperationsMode,
      handleReadOperation,
      handleManageOperation,
      canManageAds: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_ADS),
      canManageBusiness: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_BUSINESS),
      canManageOrg: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_ORG),
      canManageNegotiations: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_NEGOTIATIONS),
      canManageFavs: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_FAVS),
      canManageUsers: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_USER),
      canManageKYC: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_KYC),
      canManageWallet: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_WALLET),
      canManageAddress: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_ADDRESS),
      canManageTransaction: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_TRANSACTIONS),
      canManageFinances: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_FINANCES),
      canManageLogistics: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_LOGISTICS),
      canManageUserRoles: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_USER_ROLES),
      canManageReports: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_REPORTS),
      canManageFee: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_FEE),
      canManageTransactionMessages: () =>
        handleManageOperation(
          roleOperationsName.MANAGEMENT_TRANSACTIONS_MESSAGES
        ),
      canManageTransactionSamplesVerificationTransport: () =>
        handleManageOperation(
          roleOperationsName.MANAGEMENT_TRANSACTIONS_SAMPLES_VERIFICATION_TRANSPORT
        ),
      canManageTransactionReturn: () =>
        handleManageOperation(
          roleOperationsName.MANAGEMENT_TRANSACTIONS_RETURN
        ),
      canManageOpportunities: () =>
        handleManageOperation(roleOperationsName.MANAGEMENT_OPPORTUNITIES),
      canReadAds: () => handleReadOperation(roleOperationsName.MANAGEMENT_ADS),
      canReadBusiness: () =>
        handleReadOperation(roleOperationsName.MANAGEMENT_BUSINESS),
      canReadOrg: () => handleReadOperation(roleOperationsName.MANAGEMENT_ORG),
      canReadNegotiations: () =>
        handleReadOperation(roleOperationsName.MANAGEMENT_NEGOTIATIONS),
      canReadFavs: () =>
        handleReadOperation(roleOperationsName.MANAGEMENT_FAVS),
      canReadUsers: () =>
        handleReadOperation(roleOperationsName.MANAGEMENT_USER),
      canReadKYC: () => handleReadOperation(roleOperationsName.MANAGEMENT_KYC),
      canReadWallet: () =>
        handleReadOperation(roleOperationsName.MANAGEMENT_WALLET),
      canReadAddress: () =>
        handleReadOperation(roleOperationsName.MANAGEMENT_ADDRESS),
      canReadTransaction: () =>
        handleReadOperation(roleOperationsName.MANAGEMENT_TRANSACTIONS),
      canReadFinances: () =>
        handleReadOperation(roleOperationsName.MANAGEMENT_FINANCES),
      canReadLogistics: () =>
        handleReadOperation(roleOperationsName.MANAGEMENT_LOGISTICS),
      canReadUserRoles: () =>
        handleReadOperation(roleOperationsName.MANAGEMENT_USER_ROLES),
      canReadReports: () =>
        handleReadOperation(roleOperationsName.MANAGEMENT_REPORTS),
      canReadFee: () => handleReadOperation(roleOperationsName.MANAGEMENT_FEE),
      canReadTransactionMessages: () =>
        handleReadOperation(
          roleOperationsName.MANAGEMENT_TRANSACTIONS_MESSAGES
        ),
      canReadTransactionSamplesVerificationTransport: () =>
        handleReadOperation(
          roleOperationsName.MANAGEMENT_TRANSACTIONS_SAMPLES_VERIFICATION_TRANSPORT
        ),
      canReadTransactionReturn: () =>
        handleReadOperation(roleOperationsName.MANAGEMENT_TRANSACTIONS_RETURN),
      canReadOpportunities: () =>
        handleReadOperation(roleOperationsName.MANAGEMENT_OPPORTUNITIES),
    }),
    [operations, setOperations]
  );

  return (
    <OperationsContext.Provider value={contextValue}>
      {children}
    </OperationsContext.Provider>
  );
};

export const useOperationsProvider = () => useContext(OperationsContext);
