import NavBar from "../../components/ui/navbar/NavBar";
import { useSteps, useTranslation } from "../../utils/hooks";
import Header from "../../components/ui/header/Header";
import {
  checkPermission,
  dataURLtoFile,
  searchOnTable,
  uploadFilesFromBase64,
} from "../../utils/functions";
import styles from "./BusinessUsers.module.scss";
import { useNavigate } from "react-router-dom";
import Steps from "../../components/ui/steps/Steps";
import InviteUser from "./invite/Invite";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import { useState } from "react";
import SearchFilterExport from "../../components/ui/search-filter-export/SearchFilterExport";
import { useDocumentTitle } from "../../utils/document/hooks";
import { Container, Spacer, TableManager } from "@scrapadev/scrapad-front-sdk";
import { useTableDataProvider } from "../../providers/TableContext";
import {
  mapSlugToChip,
  userRoleOptions,
  orgStatusOptions,
  QUERY_UPPER_LIMIT,
  tableModules,
} from "../../utils/variables";
import GQLFetchWrapper from "../../wrappers/FetchWrapper";
import { getHeaderInfo, getUserData } from "../../model/business";
import { handleCRUD } from "../../utils/crud/functions";
import { useSetDataUser, useSignOut } from "../../providers/AuthHooks";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { getUsers } from "../../model/tables";

/**
 * Business users page.
 * @returns {JSX.Element}
 */
const BusinessUsers = ({ id }) => {
  const { t, i18n, values } = useTranslation(["common"]);
  const navigate = useNavigate();
  useDocumentTitle(`${t("users")}`, values);
  const [modalOpen, setModalOpen] = useState(false);
  const [uuidUser, setUuidUser] = useState(null);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const { steps } = useSteps(id);
  const { data, setData, dataBuffer, loading, setLoading, fetchData } =
    useTableDataProvider();
  const { state } = useHeaderProvider(i18n.language, id, getHeaderInfo);
  const handleRoleActionClick = (item) => async (uuid) => {
    await handleCRUD({
      endpoint: `org/${id}/users/${uuid}?from_dashboard=true`,
      method: "PUT",
      data: { role: item },
      successCallback: () => fetchData(),
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
    });
  };
  const handleStatusActionClick = (item) => async (uuid) => {
    await handleCRUD({
      endpoint: `org/${id}/users/${uuid}?from_dashboard=true`,
      method: "PUT",
      data: { status: item },
      successCallback: () => fetchData(),
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
    });
  };
  const { canManageUsers, canReadUsers } = useOperationsProvider();

  const renderRoleActions = () => {
    return userRoleOptions.map((role) => ({
      uuid: role?.uuid,
      label: t(role?.text),
      callBack: handleRoleActionClick(role?.uuid),
    }));
  };

  const renderStatusActions = () => {
    return orgStatusOptions.map((status) => ({
      uuid: status?.uuid,
      label: t(status?.text),
      callBack: handleStatusActionClick(status?.uuid),
    }));
  };

  return (
    <>
      <NavBar />
      <Container isFluid={true}>
        <div className="row">
          <div className="col-12">
            <Header
              title={t("users")}
              goBack
              breadcrumbs={[
                {
                  description: t("business"),
                  onClick: () => navigate(`/business/${id}`, { replace: true }),
                },
                {
                  description: state?.basicData?.orgName,
                  onClick: () => navigate("/business", { replace: true }),
                },
                { description: t("users") },
              ]}
              subHeaderLeft={
                <>
                  <Spacer height={10} />
                  <div className={styles["sc-business--steps"]}>
                    <Steps
                      steps={steps}
                      currentStep={"users"}
                      checkCurrentByLabel
                    />
                  </div>
                  <SearchFilterExport
                    isLocalSearch
                    searchPlaceholder={t("search")}
                    searchOnChange={searchOnTable(
                      data,
                      setData,
                      dataBuffer.current,
                      i18n
                    )}
                    exportProps={{
                      fnData: getUsers,
                      fnParameters: {
                        locale: i18n.language,
                        limit: QUERY_UPPER_LIMIT,
                        tableModule: tableModules.USERS,
                        uuidModule: id,
                      },
                      fileName: "users",
                    }}
                    hasFilters={false}
                    hasSeparator={false}
                    extraComponents={
                      JSON.parse(process.env.REACT_APP_INVITE_USER) &&
                      checkPermission(canManageUsers) ? (
                        <InviteUser uuidOrg={id} />
                      ) : undefined
                    }
                  />
                </>
              }
              fullSubHeaderLeft
            />
          </div>
        </div>
      </Container>
      <Container isFluid={true}>
        <div className="row">
          <div className="col-12">
            <TableManager
              loading={loading}
              data={data}
              setData={setData}
              emptyText={t("empty-users")}
              rowTriggerPageChange={false}
              onRowClick={(uuid) => {
                setUuidUser(uuid);
                setModalOpen(true);
              }}
              itemsProps={{
                avatar: {
                  imageStyle: {
                    width: 34,
                    height: 34,
                  },
                },
                dropdown: {
                  items: canManageUsers
                    ? canManageUsers()
                      ? renderRoleActions()
                      : []
                    : renderRoleActions(),
                },
                chip: {
                  mapSlugValues: mapSlugToChip,
                  translate: (code) => t(code),
                },
                chip_dropdown: {
                  items: canManageUsers
                    ? canManageUsers()
                      ? renderStatusActions()
                      : []
                    : renderStatusActions(),
                  translate: (code) => t(code),
                  mapValues: mapSlugToChip,
                  dropDownStyle: {
                    paddingTop: 7.5,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingBottom: 7.5,
                  },
                  dropDownItemStyle: {
                    paddingTop: 7.5,
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingBottom: 7.5,
                  },
                },
              }}
            />
            {uuidUser && (
              <GQLFetchWrapper
                key={uuidUser}
                fnData={getUserData}
                fnParameters={{
                  locale: i18n.language,
                  org: id,
                  user: uuidUser,
                }}
                Component={ModalFactory}
                type="user_info"
                open={modalOpen}
                setOpen={setModalOpen}
                uuidUser={uuidUser}
                deps={[uuidUser]}
                delegateLoading
                permissionManage={canManageUsers}
                permissionRead={canReadUsers}
                onSubmit={async (state, imageLoading, setImageLoading) => {
                  setImageLoading(true);
                  const data = { ...state };
                  data.lang = i18n.language;
                  data.job_position = data?.jobPosition;
                  if (data?.avatar?.includes("data:image")) {
                    let extension = data.avatar.split(";")[0].split("/")[1];
                    if (extension === "jpeg") extension = "jpg";
                    const files = await uploadFilesFromBase64({
                      files: [
                        dataURLtoFile(
                          data.avatar,
                          `avatar-${uuidUser}-${new Date().getTime()}.${extension}`
                        ),
                      ],
                      fileType: "user-profile",
                      setDataUser,
                      signOut,
                      setLoading: () => {},
                      t,
                    });
                    if (files.length > 0) data.profileImgId = files?.[0]?.uuid;
                  }
                  delete data.avatar;
                  delete data.jobPosition;
                  handleCRUD({
                    endpoint: `org/${id}/users/${uuidUser}`,
                    method: "PUT",
                    data: data,
                    successCallback: async () => {
                      await fetchData();
                      setImageLoading(false);
                    },
                    errorCallback: () => {
                      setImageLoading(false);
                    },
                    messageOk: t("action-ok"),
                    signOut,
                    setDataUser,
                    setLoading: setModalOpen,
                  });
                }}
              />
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default BusinessUsers;
