import React from "react";
import { BackButton } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";
import { useNavigate } from "react-router-dom";

function BackButtonWrap({ name = "keyboard_backspace", text }) {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();

  if (!text) {
    text = t("back");
  }

  return (
    <BackButton
      name={name}
      text={text}
      onClick={() => {
        navigate(-1);
      }}
    />
  );
}

export default BackButtonWrap;
