import { useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import {
  BREAKPOINT_LG,
  Container,
  Modal,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import ModalText from "../../ui/modal/text/ModalText";

/**
 * Modal for warning about verification changes
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalVerificationWarning = ({ open = false, setOpen, ...props }) => {
  const { t } = useTranslation(["verification", "common"]);
  const [loading, setLoading] = useState(false);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (props.handleSubmit) props.handleSubmit(loading, setLoading);
  };

  const renderTitle = () => {
    if (props?.title) {
      return props.title;
    }
    props?.checked ? t("disable-verification") : t("enable-verification");
  };

  const renderBodyText = () => {
    if (props?.bodyText) {
      return props.bodyText;
    }
    return "";
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={renderTitle()}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={
        props?.checked ? t("disable-verification") : t("enable-verification")
      }
      cancelText={t("cancel")}
      reverseActions
      loading={loading}
      maxWidth={900}
      modalStyles={{ width: matches ? "auto" : 900 }}
      BodyComponent={
        <Container>
          <div className="row">
            <div className="col-12">
              <ModalText text={renderBodyText()} />
            </div>
          </div>
        </Container>
      }
    />
  );
};

export default ModalVerificationWarning;
