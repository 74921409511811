import React from "react";
import UserCard from "../../user_card/UserCard";
import { Alert, Icon, Label, Row } from "@scrapadev/scrapad-front-sdk";
import styles from "./UserAccessSelector.module.scss";
import { useTranslation } from "../../../../utils/hooks";
import { useOperationsProvider } from "../../../../providers/organization/OperationsContext";
import SearchUsersInput from "./SearchUsersInput";

function UserAccessSelector() {
  const { t } = useTranslation(["common"]);

  const { canManageUsers } = useOperationsProvider();

  return (
    <div className={styles["user_access_selector"]}>
      <Row wrap align="stretch">
        <UserCard onRemoveAccess={() => {}} />
        <UserCard />
        <UserCard />
        <UserCard />
        <UserCard />
      </Row>
      <Alert type="warning">
        <Row gap={10} align="center" justify="flex-start">
          <Icon name={"error_outline"} style={{ fontSize: "16px" }} />
          <span>No tienes permiso para gestionar accesos</span>
        </Row>
      </Alert>
      <Label text={t("add_people")}>
        <SearchUsersInput />
      </Label>

      

    </div>
  );
}

export default UserAccessSelector;

