import { Link, useLocation } from "react-router-dom";
import { checkActiveRouteClass } from "../../../utils/routes/functions";
import styles from "./Menu.module.scss";

const MenuItem = ({ to, icon, text }) => {
  const location = useLocation();
  return (
    <Link to={to} className={checkActiveRouteClass([to], location, styles)}>
      {icon && (
        <i
          className={`${styles["c-navbar__menu-icon"]} large material-icons-outlined icon`}
        >
          {icon}
        </i>
      )}
      {text}
    </Link>
  );
};

export default MenuItem;
