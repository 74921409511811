import React from "react";
import styles from "./KanbanItemPricing.module.scss";
import MiniImage from "../../image/MiniImage";
import {
  Icon,
  LinkStyled,
  Row,
  WARM_NEUTRAL_60,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../../utils/hooks";
import { GRAY_NEUTRAL_40, negotiationType } from "../../../../utils/variables";
import { formatDate } from "../../../../utils/functions";
import ChipOutlined from "../../chip/ChipOutlined";
import _ from "lodash";

const linkStyles = {
  color: "#3784F6",
  fontSize: 12,
  lineHeight: 1.5,
  fontFamily: "Silka",
  fontWeight: 500,
  width: "100%",
  textDecoration: "none",
};

function KanbanItemPricing({
  uuid,
  type,
  status,
  title,
  image,
  address,
  isPort,
  date,
  origin,
  target,
  group,
}) {
  const { i18n } = useTranslation("common");

  const url =
    type === negotiationType.TRANSACTION
      ? `/transactions/${uuid}/transport?from=pricing`
      : `/opportunities/${uuid}?from=pricing`;

  const TYPE_ICON_NAME = {
    [negotiationType.TRANSACTION]: "swap_horiz",
    [negotiationType.OPPORTUNITY]: "campaign",
  };

  let typeIcon = (
    <Icon
      name={TYPE_ICON_NAME[type]}
      color={WARM_NEUTRAL_60}
      style={{
        fontSize: "16px",
      }}
    />
  );

  return (
    <LinkStyled
      href={url}
      // target="_blank"
      style={linkStyles}
      data-cy="logistic-card-link"
    >
      <div className={styles["kanban_item"]}>
        <div className={styles["header"]}>
          <div className={styles["feature_image"]}>
            <MiniImage
              src={image || " "}
              width={45}
              height={45}
              containerStyle={{
                minWidth: "45px",
                minHeight: "45px",
                backgroundColor: GRAY_NEUTRAL_40,
                borderRadius: "5px",
              }}
            />
          </div>
          <div className={styles["title_wrap"]}>
            <h5 className={styles["title"]}>{title}</h5>
            <Row gap={5}>
              <ChipOutlined iconComponent={typeIcon}>
                <span style={{ textTransform: "uppercase" }}>
                  {uuid?.split("-")[0]}
                </span>
              </ChipOutlined>
              {/* <ChipOutlined iconComponent={typeIcon}>
                {uuid?.split("-")[0]}
              </ChipOutlined> */}
            </Row>

            {/* <span className={styles["desc"]}>
              {typeIcon} {uuid?.split("-")[0]}
            </span> */}
          </div>
        </div>
        <div className={styles["body"]}>
          <div className={styles["address"]}>
            <AddressRow isPort={isPort} text={address} />
          </div>
          {/* <div className={styles["actions"]}> */}
          {/* <ItemStatus>{status}</ItemStatus> */}
          {/* <ChipDropdown
            items={[{ uuid: "e23" }].map((item, index) => (
              <ClearButton key={item.uuid} onClick={() => {}}>
                botón
              </ClearButton>
            ))}
            type="clear"
            text={t("select-value")}
          /> */}
          {/* </div> */}
        </div>

        <div className={styles["footer"]}>
          <div className={styles["business_name"]}>
            {origin} - {target}
          </div>
          <div className={styles["date"]}>{formatDate(date, i18n)}</div>
        </div>
      </div>
    </LinkStyled>
  );
}

function AddressRow({ isPort, text }) {
  if (_.isEmpty(text)) {
    return <div style={{ height: "25px" }}></div>;
  }

  return (
    <div className={styles["address_row"]}>
      <div className={styles["icon"]}>
        {isPort ? (
          <Icon
            name={"directions_boat"}
            color="#9E9E9E"
            style={{
              fontSize: "15px",
              paddingTop: "4px",
              paddingRight: "5px",
            }}
          />
        ) : (
          <Icon
            name={"directions_bus"}
            color="#9E9E9E"
            style={{
              fontSize: "15px",
              paddingTop: "4px",
              paddingRight: "5px",
            }}
          />
        )}
      </div>
      <div className={styles["text"]}>{text}</div>
    </div>
  );
}

function ItemStatus({ children }) {
  return <span className={styles["item_status"]}>{children}</span>;
}

export default KanbanItemPricing;
