import React from "react";

function BoxIcon({ color = "#777986" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M8.186 1.11298C8.06662 1.06513 7.93338 1.06513 7.814 1.11298L1.846 3.49998L4.25 4.46098L10.404 1.99998L8.186 1.11298ZM11.75 2.53898L5.596 4.99998L8 5.96098L14.154 3.49998L11.75 2.53898ZM15 4.23898L8.5 6.83898V14.761L15 12.161V4.23998V4.23898ZM7.5 14.762V6.83798L1 4.23898V12.162L7.5 14.762ZM7.443 0.183979C7.80057 0.0409786 8.19943 0.0409786 8.557 0.183979L15.686 3.03598C15.7787 3.07313 15.8581 3.13717 15.9141 3.21987C15.9701 3.30257 16 3.40013 16 3.49998V12.162C15.9999 12.3618 15.9399 12.557 15.8278 12.7224C15.7157 12.8878 15.5566 13.0158 15.371 13.09L8.186 15.964C8.06662 16.0118 7.93338 16.0118 7.814 15.964L0.63 13.09C0.444251 13.016 0.284942 12.888 0.172642 12.7226C0.0603417 12.5572 0.000206329 12.3619 0 12.162L0 3.49998C2.32399e-05 3.40013 0.0299437 3.30257 0.085907 3.21987C0.14187 3.13717 0.221313 3.07313 0.314 3.03598L7.443 0.183979Z"
        fill={color}
      />
    </svg>
  );
}

export default BoxIcon;
