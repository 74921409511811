export const documentActions = {
  INITIAL_LOAD: "INITIAL_LOAD",
  MANAGE_ACCESS_OPEN: "MANAGE_ACCESS_OPEN",
  MANAGE_ACCESS_CLOSE: "MANAGE_ACCESS_CLOSE",
  UPLOAD_FILE_OPEN: "UPLOAD_FILE_OPEN",
  UPLOAD_FILE_CLOSE: "UPLOAD_FILE_CLOSE",
  SHARE_OPEN: "SHARE_OPEN",
  SHARE_CLOSE: "SHARE_CLOSE",
  MOVE_OPEN: "MOVE_OPEN",
  MOVE_CLOSE: "MOVE_CLOSE",
  SET_SELECTED_FILES: "SET_SELECTED_FILES",
  CLEAN_SELECTED_FILES: "CLEAN_SELECTED_FILES",
  MODAL_CLOSE: "MODAL_CLOSE",
  DETAIL_PANEL_OPEN: "DETAIL_PANEL_OPEN",
  DETAIL_PANEL_CLOSE: "DETAIL_PANEL_CLOSE",
  LOAD_DATA: "LOAD_DATA",
  LOADING_SET: "LOADING_SET",
  LOADING_UNSET: "LOADING_UNSET",
  BREADCRUMBS_SET: "BREADCRUMBS_SET",
  DATA_VISIBLE_SET: "DATA_VISIBLE_SET",
  DOWNLOADING_SET: "DOWNLOADING_SET",
  DOWNLOADING_UNSET: "DOWNLOADING_UNSET",
  SORTING_SET: "SORTING_SET",
  SORTING_UNSET: "SORTING_UNSET",
  RENAME_SET: "RENAME_SET",
  RENAME_UNSET: "RENAME_UNSET",
  DELETE_FILE_SET: "DELETE_FILE_SET",
  DELETE_FILE_UNSET: "DELETE_FILE_UNSET",
};
