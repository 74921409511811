import { toastSuccess } from "@scrapadev/scrapad-front-sdk";
import deepmerge from "deepmerge";
import _ from "lodash";
import { useUser } from "../../providers/AuthHooks";
import { useCRUD } from "../../utils/crud/hooks";
import { useTranslation } from "../hooks";
import { opportunityEvents } from "./variables";

/**
 * Parse cost field with some default values
 * @param {{slug: string,  value: number, optional: boolean}} field - Field to parse.
 * @returns {slug: string,  value: number, optional: boolean}}
 */
export const costValuesWithDefaults = (field) => {
  return {
    ...{
      slug: "",
      value: 0,
      optional: true,
      active: false,
      name: "",
    },
    ...field,
  };
};

/**
 * Parse perceive or deposit field with some default values
 * @param {{slug: string,  value: number, optional: boolean}} field - Field to parse.
 * @returns {slug: string,  value: number, optional: boolean}}
 */
export const perceiveDepositWithDefaults = (field) => {
  return {
    ...{
      paymentMethod: "",
      value: 0,
    },
    ...{
      paymentMethod: field?.title,
      value: field?.amount,
      perTon: field?.perTon,
    },
  };
};

/**
 * Builder data for impute costs.
 * @param {string} uuidUser - User uuid.
 * @param {"LOGISTICS_COSTS_IMPUTED" | "FINANCES_COSTS_IMPUTED"} slug - Event slug
 * @param {string} uuidEvent - Opportunity uuid.
 * @param {boolean} value - True or false
 * @returns
 */
export const buildImputeCost = ({
  uuidUser,
  slug,
  uuidEvent,
  value,
  uuidOfferOpportunity,
}) => {
  // return {
  //   uuidUser,
  //   slug,
  //   uuidEvent,
  //   uuidOfferOpportunity,
  //   value,
  // };
  return {
    uuidUser,
    slug,
    uuidEvent: uuidEvent,
    value,
  };
};

/**
 * Custom hook to send impute event.
 * @returns {{handleSendCost: (id: string, type: string, value: boolean, setLoading: (b: boolean) => void, fetchData: () => void) => void}}
 */
export const useImputeCosts = () => {
  const { CRUD } = useCRUD();
  const user = useUser();
  const { t } = useTranslation(["common"]);

  const handleSendCost = async ({
    id,
    type,
    value,
    setLoading,
    fetchData,
    fields,
    costName,
    uuidOfferOpportunity,
  }) => {
    if (!type || !id || !setLoading || !fetchData) return;
    setLoading(true);
    // const data = buildImputeCost(user.userUuid, type, id, value);
    const data = buildImputeCost({
      uuidUser: user.userUuid,
      slug: type,
      uuidEvent: id,
      value: value,
      uuidOfferOpportunity: uuidOfferOpportunity,
    });

    await CRUD({
      external: true,
      data: costName ? { ...data, costName: costName } : data,
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      successCallback: async () => {
        // toastSuccess(t("action-ok"));
        await fetchData(getFetchData(false, id, fields));
        setLoading(false);
      },
    });
  };

  return {
    handleSendCost,
  };
};

/**
 * Helper function to pass to get costs function call.
 * @param {boolean} initial - If initial load.
 * @param {string} id - Opportinut uuid
 * @param {Array<{slug: string, value: number}>} fields - Costs fields
 * @returns {{ uuid: string, isComplete: false, slugs?: Array<{slug: string, value: number}> }}
 */
export const getFetchData = (initial = false, id, fields = []) => {
  if (!id) return undefined;
  if (initial) {
    return {
      uuid: id,
      isComplete: true,
    };
  }
  const slugs = fields.map((field) => {
    return {
      slug: field.slug,
      value: field.value,
    };
  });
  return {
    uuid: id,
    isComplete: false,
    slugs,
  };
};

/**
 * Helper function to get opportunity url.
 * @param {string} uuid - Opportunity uuid
 * @returns {string}
 */
export const navigateToOpportunity = (uuid) => `/opportunities/${uuid}`;

/**
 * Helper function to parse and give defaults in a tab notification
 * @param {{news: number, pendingCosts: number}} notifications - Tab notifications object
 * @returns {news: number, pendingCosts: number}
 */
export const parseTabNotifications = (notifications) => {
  const defaults = {
    news: 0,
    pendingCosts: 0,
  };
  if (notifications === null || notifications === undefined) {
    return defaults;
  }

  return {
    ...defaults,
    ...notifications,
  };
};

/**
 * Builder data for converting an offer into a transaction.
 * @param {string} uuidEvent - Offer uuid.
 * @param {string} uuidUser - User uuid.
 * @returns {{uuidEvent: string}}
 */
export const buildCOnvertToTransaction = ({
  uuidEvent,
  uuidUser,
  uuidOpportunity,
  amount,
  price,
}) => {
  return {
    slug: opportunityEvents.INIT_NEGOTIATION_FROM_OPPORTUNITY,
    uuidEvent,
    uuidUser,
    uuidAd: uuidOpportunity,
    amount: amount * 100,
    proposedPrice: price * 100,
  };
};

export const buildFinishOpportunity = (uuidEvent, uuidUser) => {
  return {
    slug: opportunityEvents.FINISH_OPPORTUNITY,
    uuidEvent,
    uuidUser,
  };
};

export const buildMarkReadOpportunity = (uuidEvent, uuidUser) => {
  return {
    slug: opportunityEvents.READED_DETAILS,
    uuidEvent,
    uuidUser,
  };
};

export const buildNewOpportunityAddress = (address, onlyPort = false) => {
  let data = deepmerge(address, {
    street: _.isEmpty(address?.port)
      ? `${address?.street} ${address?.streetNumber}`
      : "",
    countryCode: !address?.countryCode ? 0 : address?.countryCode,
  });
  delete data?.streetNumber;
  delete data?.basicData?.countryName;
  if (_.isEmpty(data?.port)) {
    delete data?.port;
  }
  delete data.__typename;

  if (onlyPort && !_.isEmpty(data?.port)) {
    data = {
      port: data?.port,
    };
  }

  return data;
};
