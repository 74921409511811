import {
  Alert,
  BREAKPOINT_LG,
  Flex,
  Loading,
  Modal,
  toastError,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import ListTile from "../../ui/list-tile/ListTile";
import { gqlWrapper } from "../../../utils/gqlwrapper";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import { getOpportunityOfferDetail } from "../../../model/opportunities";
import { getCatalog } from "../../../model/filters";
import { postClient } from "../../../utils/restclient";

function EditPaymentConditions({
  open,
  setOpen = () => {},
  offerUuid,
  ...props
}) {
  const { t, i18n } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [loading, setLoading] = useState(false);
  const user = useUser();
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();
  const [data, setData] = useState({});
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [selected, setSelected] = useState();
  const [requiredFinancing, setRequiresFinancing] = useState(false);

  useEffect(() => {
    fetchData(offerUuid);
  }, [offerUuid]);

  const fetchData = async (uuid) => {
    setLoading(true);
    const fn = await gqlWrapper(
      getOpportunityOfferDetail,
      setDataUser,
      signOut
    );
    const results = await fn({
      locale: i18n.language,
      uuid: uuid,
    });
    if (results !== null) {
      if (results?.errors?.length > 0) {
        toastError("Error");
      } else {
        setData(results);
        fetchPaymentMethodList(results?.paymentMethod?.uuid);
        setLoading(false);
      }
    }
  };

  const fetchPaymentMethodList = async (uuid) => {
    setLoading(true);
    const fn = await gqlWrapper(getCatalog, setDataUser, signOut);
    const results = await fn({
      locale: i18n.language,
      name: "payment-method-seller",
      term: uuid,
    });
    if (results !== null) {
      if (results?.errors?.length > 0) {
        toastError("Error");
      } else {
        setPaymentMethodList(results);
        setLoading(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setLoading(true);

    try {
      await postClient({
        external: true,
        url: `${process.env.REACT_APP_API_EVENTS}`,
        method: "POST",
        body: {
          slug: "CHANGE_PAYMENT_METHOD_OPP_SELLER",
          uuidEvent: offerUuid,
          uuidUser: user?.userUuid,
          paymentMethod: selected,
        },
        signOut,
        setDataUser,
      });
      setOpen(false);
    } catch (error) {
      console.log(error);
      toastError("Error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t("edit_payment_conditions")}
      hasForm={true}
      hasActions={true}
      submitDisabled={!selected}
      cancelText={t("cancel")}
      submitText={t("save")}
      actionsMode="end"
      maxWidth={900}
      modalStyles={{ width: matches ? "auto" : 900 }}
      onSubmit={handleSubmit}
      loading={loading}
      BodyComponent={
        <>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Flex
                flexDirection="column"
                alignItems="stretch"
                style={{ gap: "10px" }}
              >
                {paymentMethodList?.map((item, index) => {
                  return (
                    <ListTile
                      key={item?.uuid}
                      radio={true}
                      id={"payment_conditions"}
                      name={"payment_conditions"}
                      value={item?.uuid}
                      title={item?.name}
                      onClick={() => {
                        setSelected(item?.uuid);
                        setRequiresFinancing(item?.extra === "true");
                      }}
                    />
                  );
                })}
                {requiredFinancing && (
                  <Alert type="info">
                    {t("this_payment_method_required_financing")}
                  </Alert>
                )}
              </Flex>
            </>
          )}
        </>
      }
    />
  );
}

export default EditPaymentConditions;
