import { toastError } from "@scrapadev/scrapad-front-sdk";
import React, { useContext, useMemo, useState, useRef } from "react";
import { gqlWrapper } from "../utils/gqlwrapper";
import { useTranslation } from "../utils/hooks";
import { useSetDataUser, useSignOut } from "./AuthHooks";

const TableApiContext = React.createContext({});
const TableDataContext = React.createContext({});

export const TableProvider = ({
  fnData,
  fnParameters,
  subFieldParams,
  children,
}) => {
  const { i18n } = useTranslation();
  const [data, setData] = useState({ header: [], body: [], subFields: [] });
  const [loading, setLoading] = useState(true);
  const dataBuffer = useRef({ header: [], body: [], subFields: [] });
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();

  const setEmpty = () => {
    setData({ header: [], body: [], subFields: [] });
  };

  const contextApi = useMemo(() => ({ fnData, fnParameters }), [fnParameters]);
  const contextData = useMemo(
    () => ({
      data,
      setData,
      dataBuffer,
      loading,
      setLoading,
      fetchSubFields: async ({ tableModule, selected_uuids = [] }) => {
        if (!fnData) return;
        const fn = await gqlWrapper(fnData, setDataUser, signOut);
        const results = await fn({
          locale: i18n.language,
          limit: 0,
          filter: {
            ops: [
              {
                field: "selected_uuids",
                value: selected_uuids,
              },
            ],
          },
          tableModule,
        });
        if (results === null) {
          setEmpty();
        } else {
          if (results?.errors?.length > 0) {
            toastError("Error");
            setEmpty();
          } else {
            setData({
              ...data,
              subFields: [...dataBuffer.current?.subFields, ...results.rows],
            });
            dataBuffer.current = {
              ...dataBuffer.current,
              subFields: [...dataBuffer.current?.subFields, ...results.rows],
            };
          }
        }
      },
      fetchData: async (customParams, silent = false) => {
        if (!fnData) return;
        if (!silent) {
          setLoading(true);
        }
        const fn = await gqlWrapper(fnData, setDataUser, signOut);
        const params = customParams ? customParams : fnParameters;
        const results =
          fnParameters || customParams
            ? await fn({ locale: i18n.language, ...params })
            : await fn(i18n.language);
        if (results === null) {
          setEmpty();
        } else {
          if (results?.errors?.length > 0) {
            toastError("Error");
            setEmpty();
          } else {
            setData({
              header: results.columns,
              body: results.rows,
              subFields: [],
              pagination: results.pagination,
            });
            dataBuffer.current = {
              header: results.columns,
              body: results.rows,
              subFields: [],
              pagination: results.pagination,
            };
          }
        }

        setLoading(false);
      },
    }),
    [data, loading]
  );

  return (
    <TableApiContext.Provider value={contextApi}>
      <TableDataContext.Provider value={contextData}>
        {children}
      </TableDataContext.Provider>
    </TableApiContext.Provider>
  );
};

export const useTableApiProvider = () => useContext(TableApiContext);
export const useTableDataProvider = () => useContext(TableDataContext);
