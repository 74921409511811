import styles from "./EndTransaction.module.scss";
import { useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import { Modal } from "@scrapadev/scrapad-front-sdk/dist/index";
import Alert from "../../ui/alert/Alert";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import { postClient } from "../../../utils/restclient";
import ModalText from "../../ui/modal/text/ModalText";
import { handleFormOK } from "../../../utils/functions";
import { toastError } from "@scrapadev/scrapad-front-sdk";
import { buildEndTransaction } from "../../forms/transaction/functions";

/**
 * Modal for ending transaction.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalEndTransaction = ({ open = false, setOpen, ...props }) => {
  const { t } = useTranslation(["transactions", "common"]);
  const [loading, setLoading] = useState(false);
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!props.uuidEvent) return;
    setLoading(true);
    const data = buildEndTransaction(user.userUuid, props.uuidEvent);
    try {
      await postClient({
        external: true,
        url: `${process.env.REACT_APP_API_EVENTS}`,
        method: "POST",
        body: data,
        signOut,
        setDataUser,
      });
      handleFormOK(t, props.fetchData);
    } catch (error) {
      toastError(error.message);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("end-transaction-title")}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("end-transaction")}
      cancelText={t("end-transaction-cancel")}
      reverseActions={true}
      loading={loading}
      maxWidth={700}
      BodyComponent={
        <div className={styles["sc-transactions--end"]}>
          <ModalText text={t("end-transaction-text")} />
          <Alert type="warning">{t("end-transaction-warning")}</Alert>
        </div>
      }
    />
  );
};

export default ModalEndTransaction;
