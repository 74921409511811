import {
  BREAKPOINT_LG,
  Container,
  Modal,
  useMatchMedia,
  InputText,
  InputEmail,
  toastSuccess,
  SearchAutoComplete,
  Loading,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { getCatalogNoTerm } from "../../../model/filters";
import { actions } from "../../../providers/data/actions/data";
import { useDataProvider } from "../../../providers/data/DataContext";
import { useCRUD } from "../../../utils/crud/hooks";
import { handleItemClick, handleOnChange } from "../../../utils/functions";
import { useGQL } from "../../../utils/gql/hooks";
import { useTranslation } from "../../../utils/hooks";
import {
  coverageTypes,
  serviceTypes,
} from "../../../utils/logistics/variables";
import { DEFAULT_LANGUAGE, languages } from "../../../utils/variables";
import Label from "../../ui/input/label/Label";

const defaultData = {
  name: "",
  email: "",
  activity: "",
  equipment: [],
  origin: "",
  service: "",
  coverage: "",
  notification_language: DEFAULT_LANGUAGE,
};

const ModalAddEditTransportProvider = ({ open, setOpen, ...props }) => {
  const { isEdit, data, uuid } = props;
  const { state, dispatch } = useDataProvider();
  const [loading, setLoading] = useState();
  const { t, i18n } = useTranslation(["logistics", "common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const { CRUD } = useCRUD();
  const { data: catalogData, loading: catalogLoading } = useGQL(
    [getCatalogNoTerm, getCatalogNoTerm, getCatalogNoTerm, getCatalogNoTerm],
    [
      {
        locale: i18n.language,
        name: "transporter-equipments",
      },
      {
        locale: i18n.language,
        name: "logistics-services",
      },
      {
        locale: i18n.language,
        name: "logistics-coverage",
      },
      {
        locale: i18n.language,
        name: "logistics-activity",
      },
    ]
  );
  const equipments = catalogData ? catalogData[0] : [];
  const services = catalogData ? catalogData[1] : [];
  const coverage = catalogData ? catalogData[2] : [];
  const activity = catalogData ? catalogData[3] : [];
  const adaptedLanguages = languages.map((language) => ({
    uuid: language?.uuid,
    text: language?.text,
    translated: t(language?.text),
  }));
  const selectedLanguageValue = adaptedLanguages?.find((language) => {
    return (
      language.uuid === state?.notification_language ||
      language.translated === t(state?.notification_language)
    );
  })?.uuid;

  /** Cuando se edita un registro de la tabla cienen los campos en el siguiente modo: equipamiento1,equipamiento2...
   * Esta función sirve para parsear ese formato */
  const equipmentDataAdapter = () => {
    if (!Array.isArray(state?.equipment)) {
      const splitted = state?.equipment?.split(",");
      return equipments
        ?.filter((f) => {
          return splitted?.find((e) => e === f?.name);
        })
        ?.map((f) => f?.uuid);
    }
    return state?.equipment;
  };

  const catalogDataApapter = (key) => {
    const mapped = {
      service: services,
      coverage: coverage,
      activity: activity,
    };
    const items = mapped[key];
    return items.find((f) => f?.name === state[key] || f?.uuid === state[key])
      ?.uuid;
  };

  const adaptedStateEquipment = equipmentDataAdapter();
  const adaptedStateService = catalogDataApapter("service");
  const adaptedStateCoverage = catalogDataApapter("coverage");
  const adaptedStateActivity = catalogDataApapter("activity");

  const coverageCheck =
    adaptedStateService === serviceTypes.PROVINCE ||
    adaptedStateService === serviceTypes.AUTONOMIC;

  useEffect(() => {
    if (isEdit) {
      if (!data?.notification_language) {
        data.notification_language = DEFAULT_LANGUAGE;
      }
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: data || defaultData,
      });
    } else {
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: defaultData,
      });
    }
  }, []);

  const sendDataAdapter = () => {
    const adaptedData = {
      transporter_name: state?.name,
      quotation_email: state?.email,
      activity_type: adaptedStateActivity,
      equipments: adaptedStateEquipment,
      origin: state?.origin,
      service: adaptedStateService,
      coverage: coverageCheck ? coverageTypes.APPLY : adaptedStateCoverage,
      lang: state?.notification_language,
    };
    if (isEdit && uuid) {
      adaptedData.id = uuid;
    }
    return adaptedData;
  };

  const handleSubmit = async () => {
    setLoading(true);
    const url = isEdit
      ? `logistics/transporter/${props.uuid}`
      : `logistics/transporter`;
    await CRUD({
      endpoint: url,
      method: isEdit ? "PUT" : "POST",
      data: sendDataAdapter(data, i18n, isEdit, uuid),
      errorCallback: () => {
        setLoading(false);
        setOpen(false);
      },
      successCallback: async () => {
        toastSuccess(
          t("saved-changes-ok"),
          {
            toastId: "remove_transport_provider",
          },
          "check_circle"
        );
        setLoading(false);
        setOpen(false);
        dispatch({
          type: actions.INITIAL_LOAD,
          payload: defaultData,
        });
        if (props.submitCallback) props.submitCallback();
      },
    });
  };

  const handleMultipleItemClick = (option, open, setOpen, e) => {
    e.stopPropagation();
    const obj = {};
    const alreadySelected = adaptedStateEquipment?.includes(option.uuid);
    if (alreadySelected) {
      obj["equipment"] = adaptedStateEquipment.filter((f) => f !== option.uuid);
    } else {
      obj["equipment"] = adaptedStateEquipment
        ? [...adaptedStateEquipment, option.uuid]
        : [option.uuid];
    }
    dispatch({ type: actions.UPDATE_INPUT, payload: obj });
  };

  if (Object.keys(state).length === 0) return <></>;

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        setLoading(false);
        dispatch({ type: actions.UPDATE_INPUT, payload: defaultData });
      }}
      title={
        isEdit ? t("edit-transport-provider") : t("add-transport-provider")
      }
      cancelText={t("cancel")}
      submitText={t("save")}
      modalStyles={{ width: matches ? "auto" : 900 }}
      hasForm
      onSubmit={handleSubmit}
      loading={loading}
      BodyComponent={
        <Container style={{ margin: "0 -12px" }}>
          <div className="row">
            <div className="col-12 col-lg-6">
              <Label htmlFor="name">{t("name")} *</Label>
              <InputText
                id="name"
                placeholder={t("write-here")}
                value={state?.name}
                required
                onChange={handleOnChange("name", dispatch)}
              />
            </div>
            <div className="col-12 col-lg-6">
              <Label htmlFor="email">{t("contact-email")} *</Label>
              <InputEmail
                id="email"
                placeholder={t("write-here")}
                value={state?.email}
                required
                onChange={handleOnChange("email", dispatch)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <Label htmlFor="language">{t("language")}</Label>
              <SearchAutoComplete
                useSearch={false}
                options={adaptedLanguages?.map((language) => {
                  return {
                    uuid: language?.uuid,
                    text: language?.translated,
                  };
                })}
                value={t(selectedLanguageValue || DEFAULT_LANGUAGE)}
                handleItemClick={handleItemClick(
                  "notification_language",
                  dispatch
                )}
                placeholder={t("write-or-select")}
                handleOnClickWithEvent={(e, value, open, setOpen) => {
                  setOpen(!open);
                }}
              />
            </div>
            <div className="col-12 col-lg-6">
              <Label htmlFor="activity">{t("activity-type")}</Label>
              {catalogLoading ? (
                <Loading />
              ) : (
                <SearchAutoComplete
                  inputProps={{ placeholder: t("write-or-select") }}
                  options={activity.map((item) => ({
                    uuid: item.uuid,
                    text: t(item.name),
                  }))}
                  value={t(adaptedStateActivity)}
                  handleItemClick={handleItemClick("activity", dispatch)}
                  placeholder={t("write-or-select")}
                  handleOnClickWithEvent={(e, value, open, setOpen) => {
                    setOpen(!open);
                  }}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <Label htmlFor="equipment">{t("equipment")} *</Label>
              {catalogLoading ? (
                <Loading />
              ) : (
                <SearchAutoComplete
                  inputProps={{ placeholder: t("write-or-select") }}
                  value={equipments
                    ?.filter((f) => {
                      return adaptedStateEquipment.find((e) => e === f?.uuid);
                    })
                    ?.map((f) => f?.name)}
                  options={equipments}
                  model={{ uuid: "uuid", text: "name" }}
                  multiple
                  required
                  newMultipleItemFormat
                  handleItemClick={handleMultipleItemClick}
                  placeholder={t("write-or-select")}
                  handleIsChecked={(option, innerOptions) => {
                    if (Array.isArray(state?.equipment)) {
                      return state?.equipment?.find((f) => f === option?.uuid);
                    }
                    const equipment = state?.equipment?.split(",");
                    const equipmentSelected = innerOptions.filter((f) =>
                      equipment.includes(f?.name)
                    );
                    return equipmentSelected?.find(
                      (f) => f?.name === option?.name
                    );
                  }}
                  handleOnClickWithEvent={(e, value, open, setOpen) => {
                    setOpen(!open);
                  }}
                />
              )}
            </div>
            <div className="col-12 col-lg-6">
              <Label htmlFor="origin">{t("origin")}</Label>
              <InputText
                id="origin"
                placeholder={t("write-here")}
                value={state?.origin}
                onChange={handleOnChange("origin", dispatch)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <Label htmlFor="service">{t("service")}</Label>
              {catalogLoading ? (
                <Loading />
              ) : (
                <SearchAutoComplete
                  id="service"
                  inputProps={{ placeholder: t("write-or-select") }}
                  options={services.map((item) => ({
                    uuid: item.uuid,
                    text: t(item.name),
                  }))}
                  value={t(adaptedStateService)}
                  handleItemClick={handleItemClick("service", dispatch)}
                  placeholder={t("write-or-select")}
                  handleOnClickWithEvent={(e, value, open, setOpen) => {
                    setOpen(!open);
                  }}
                />
              )}
            </div>
            <div className="col-12 col-lg-6">
              <Label htmlFor="coverage">{t("coverage")}</Label>
              {catalogLoading ? (
                <Loading />
              ) : (
                <SearchAutoComplete
                  id="coverage"
                  inputProps={{ placeholder: t("write-or-select") }}
                  options={coverage.map((item) => ({
                    uuid: item.uuid,
                    text: t(item.name),
                  }))}
                  value={
                    coverageCheck
                      ? t(coverageTypes.APPLY)
                      : t(adaptedStateCoverage)
                  }
                  disabled={coverageCheck}
                  handleItemClick={handleItemClick("coverage", dispatch)}
                  placeholder={t("write-or-select")}
                  handleOnClickWithEvent={(e, value, open, setOpen) => {
                    setOpen(!open);
                  }}
                />
              )}
            </div>
          </div>
        </Container>
      }
    />
  );
};

export default ModalAddEditTransportProvider;
