import { Center, Empty } from "@scrapadev/scrapad-front-sdk";
import { useNavigate } from "react-router-dom";
import Ads from "../../../components/ui/ads/Ads";
import CardBox from "../../../components/ui/box/CardBox";
import { useTranslation } from "../../../utils/hooks";

/**
 * Ads widget for dashboard.
 * @param {Object} props - Component properties.
 * @param {String} props.id - Business id.
 * @returns {JSX.Element}
 */
const WidgetBusinessAds = ({ id, state, extraData }) => {
  const { t } = useTranslation(["common", "transactions"]);
  const navigate = useNavigate();
  const { permission } = extraData || {};

  const handleNavigate = () => {
    navigate(`/business/${id}/ads`);
  };

  return (
    <CardBox
      title={t("ads")}
      action={t("see-more")}
      handleAction={handleNavigate}
      hasOverflow={false}
    >
      {state?.ads?.length > 0 ? (
        <Ads
          data={state}
          emptyMessageCode={"empty-section"}
          link={permission ? (permission() ? `/ads` : undefined) : `/ads`}
        />
      ) : (
        <Center>
          <Empty
            spacerHeight={10}
            description={t("empty-section")}
            icon="ads"
            iconWidth={100}
          />
        </Center>
      )}
    </CardBox>
  );
};

export default WidgetBusinessAds;
