import { useState } from "react";
import { useTranslation } from "../../../../utils/hooks";
import {
  Alert,
  BLUE_60,
  BREAKPOINT_LG,
  Center,
  Chip,
  ERROR_COLOR,
  Flex,
  Heading,
  Icon,
  Modal,
  Padding,
  Row,
  Spinner,
  TextArea,
  Typography,
  useMatchMedia,
  WARM_NEUTRAL_20,
} from "@scrapadev/scrapad-front-sdk";
import TransactionFiles from "../../../../components/ui/input/file/File";
import deepmerge from "deepmerge";
import _ from "lodash";
import {
  getOrgBuyerOrgSellerFromTransactionDetail,
  uploadFile,
  // uploadFile,
} from "../../../../utils/functions";
import { useCRUD } from "../../../../utils/crud/hooks";
import { useDocumentsProvider } from "../../DocumentsContext";
import { useSetDataUser, useSignOut, useUser } from "../../../AuthHooks";
import { formEvents, OWNER_TYPES, RoleType } from "../../../../utils/variables";
import { gqlWrapper } from "../../../../utils/gqlwrapper";
import { getTransactionDataBO } from "../../../../model/transactions";

/**
 * Modal for show a warning when data needs to be saved at leaving the page.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalUploadFile = ({ open = false, setOpen, ...props }) => {
  const { t, i18n } = useTranslation(["common"]);
  const { state, reloadData } = useDocumentsProvider();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [loading, setLoading] = useState(false);
  const [fileType, setFileType] = useState();
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState();
  const [loadingMessage, setLoadingMessage] = useState();
  const { CRUD } = useCRUD();
  const user = useUser();
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();
  const [noteType, setNoteType] = useState();
  const localModeActive = true;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (_.isEmpty(files)) {
      setErrors("files");
      return;
    }

    if (!_.isEmpty(state?.breadcrumbs) && _.isEmpty(message)) {
      setErrors("message");
      return;
    }

    setLoading(true);
    setLoadingMessage(t("uploading_files"));
    const uploadPromises = [];
    let filesUuid = [];
    try {
      if (localModeActive) {
        for (const file of files) {
          uploadPromises.push(
            uploadFile({
              file: file,
              type: "transaction",
              allowedExtensions: ["jpg", "jpeg", "png", "pdf"],
              t: t,
              limitSize: true,
            })
          );
        }
        const results = await Promise.all(uploadPromises);
        filesUuid = results?.map((f) => f[0]?.uuid_file);
      } else {
        filesUuid = files?.map((f) => f.uuid);
      }

      await CRUD({
        external: false,
        data: {
          folderId: state?.dataVisible?.id,
          uuidFiles: filesUuid,
        },
        endpoint: `integration/upload`,
        method: "POST",
        successCallback: async () => {
          if (!_.isEmpty(message)) {
            setLoadingMessage(t("sending_message"));
            let owner = "";
            state?.breadcrumbs?.forEach((bc) => {
              if (!_.isEmpty(bc?.owner)) {
                owner = bc?.owner;
              }
            });
            // const fn = await gqlWrapper(
            //   getTransactionDetail,
            //   setDataUser,
            //   signOut
            // );
            const fn = await gqlWrapper(
              getTransactionDataBO,
              setDataUser,
              signOut
            );
            const results = await fn(i18n.language, state?.transactionUuid);
            const { uuidSeller, uuidBuyer } =
              getOrgBuyerOrgSellerFromTransactionDetail(results?.sideData);
            let destinationsOrg = [];
            switch (owner) {
              case OWNER_TYPES.buyer:
                destinationsOrg = [uuidBuyer];
                break;
              case OWNER_TYPES.seller:
                destinationsOrg = [uuidSeller];
                break;
              default:
                break;
            }
            await CRUD({
              external: true,
              endpoint: `${process.env.REACT_APP_API_EVENTS}`,
              data: {
                uuidUser: user.userUuid,
                destinationsOrg: destinationsOrg,
                content: message,
                fileType: state?.fileType,
                files: filesUuid,
                slug: formEvents.SEND_MD,
                uuidEvent: state?.transactionUuid,
              },
              method: "POST",
              successCallback: () => {
                reloadData(500);
                setLoadingMessage(undefined);
                setOpen(false);
              },
              errorCallback: async (e) => {
                setLoadingMessage(undefined);
                setLoading(false);
                throw new Error(e);
              },
            });
          } else {
            reloadData(500);
            setOpen(false);
            setLoadingMessage(undefined);
            setLoading(false);
          }
        },
        errorCallback: async (e) => {
          setLoadingMessage(undefined);
          setLoading(false);
          throw new Error(e);
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingMessage(undefined);
      setLoading(false);
    }

    if (props.submitCallback) props.submitCallback(e, loading, setLoading);
  };

  const afterUpload = (newFiles) => {
    if (localModeActive) {
      const result = transformFileList(newFiles);
      setFiles([...files, ...result]);
    } else {
      setFiles(deepmerge(files, newFiles));
    }

    setErrors(undefined);
  };

  const afterFileNameChange = (newFiles) => {
    setFiles(newFiles);
    setErrors(undefined);
  };

  const transformFileList = (fileList) => {
    if (!fileList) {
      return [];
    }

    return Array.from(fileList);
  };

  const afterDelete = (newFiles) => {
    setFiles(newFiles);
  };

  const onFileTypeChange = (e, open, setOpen) => {
    setFileType(e?.uuid);
    setTimeout(() => {
      setOpen(false);
    }, 5);
  };

  const handleType = (t) => {
    let hiddenTypes = [];
    let newType = state?.destinations || [];
    if (newType?.includes(t)) {
      newType = newType.filter((e) => e !== t);
    } else {
      newType.push(t);
    }
    hiddenTypes = [...newType];

    setNoteType({
      destinations: newType,
      hiddenDestination: hiddenTypes.join("|"),
    });
  };

  let owner = undefined;
  state?.breadcrumbs?.forEach((bc) => {
    if (!_.isEmpty(bc?.owner)) {
      owner = bc?.owner;
    }
  });

  return (
    <>
      <Modal
        t={t}
        open={open}
        setOpen={setOpen}
        title={t("upload_files")}
        hasForm={true}
        onCancel={props.onCancel}
        onSubmit={handleSubmit}
        submitText={t("upload")}
        cancelText={t("cancel")}
        actionsMode="end"
        submitProps={{
          ultrawide: true,
          style: {
            width: "195px",
          },
          loading: loading,
        }}
        cancelProps={{
          ultrawide: true,
          style: {
            width: "195px",
          },
          loading: loading,
        }}
        loading={loading}
        maxWidth={900}
        modalStyles={{ width: matches ? "auto" : 900 }}
        BodyComponent={
          <>
            <div>
              <TransactionFiles
                files={files}
                placeholder={t("select-drop-files")}
                dropPlaceholder={t("drop-files")}
                afterUpload={afterUpload}
                afterDelete={afterDelete}
                onFileTypeChange={onFileTypeChange}
                afterFileNameChange={afterFileNameChange}
                allowedExtension={[".jpg", ".jpeg", ".png", ".pdf"]}
                readOnly={false}
                hideFileType={true}
                fileType={fileType}
                showFilesAndInput={true}
                localMode={localModeActive}
              />
              {errors === "files" && (
                <Typography style={{ color: ERROR_COLOR }}>
                  {t("required-field", { ns: "common" })}
                </Typography>
              )}

              {/* <Heading type={7}>{t("who_have_access_in_this_path")}</Heading>
            <UserAccessSelector /> */}
              {!_.isEmpty(state?.breadcrumbs) ? (
                <>
                  {owner === undefined ? (
                    <>
                      <Row
                        gap={10}
                        style={{ marginBottom: "10px", marginTop: "15px" }}
                      >
                        <span>{t("for")}: </span>
                        <Chip
                          type={
                            noteType?.destinations?.includes(
                              RoleType.commercial
                            )
                              ? "white-outlined-active"
                              : "white-outlined"
                          }
                          handleClick={() => {
                            console.log(RoleType.commercial);
                            handleType(RoleType.commercial);
                          }}
                          showIcon={noteType?.destinations?.includes(
                            RoleType.commercial
                          )}
                          text={t("commercial")}
                        />
                        <Chip
                          type={
                            noteType?.destinations?.includes(RoleType.logistics)
                              ? "white-outlined-active"
                              : "white-outlined"
                          }
                          handleClick={() => {
                            handleType(RoleType.logistics);
                          }}
                          showIcon={noteType?.destinations?.includes(
                            RoleType.logistics
                          )}
                          text={t("logistic")}
                        />
                        <Chip
                          type={
                            noteType?.destinations?.includes(RoleType.finances)
                              ? "white-outlined-active"
                              : "white-outlined"
                          }
                          handleClick={() => {
                            handleType(RoleType.finances);
                          }}
                          showIcon={noteType?.destinations?.includes(
                            RoleType.finances
                          )}
                          text={t("finances")}
                        />
                        <Chip
                          type={
                            noteType?.destinations?.includes(
                              "required-document-for-payments"
                            )
                              ? "white-outlined-active"
                              : "white-outlined"
                          }
                          handleClick={() => {
                            handleType("required-document-for-payments");
                          }}
                          showIcon={state?.destinations?.includes(
                            "required-document-for-payments"
                          )}
                          text={t("payment_documentation")}
                        />
                      </Row>
                    </>
                  ) : (
                    <></>
                  )}
                  <Heading type={7} style={{ margin: "20px 0 0 0" }}>
                    {t("add_message")}
                  </Heading>
                  <Typography small style={{ marginBottom: "15px" }}>
                    {t("add_message_warn")}
                  </Typography>
                  <TextArea
                    required
                    placeholder={t("write_message_here")}
                    onChange={(e) => {
                      setMessage(e?.target?.value);
                    }}
                  />
                </>
              ) : (
                <></>
              )}

              <Alert type="info">
                <Row gap={8}>
                  <Icon name="info" />
                  <span>{t("upload_info_warn")}</span>
                </Row>
              </Alert>
            </div>
          </>
        }
      />

      {!_.isEmpty(loadingMessage) && (
        <Modal
          t={t}
          open={true}
          setOpen={() => {}}
          title={""}
          hasForm={false}
          hasActions={false}
          closeStyles={{ display: "none" }}
          modalStyles={{
            backgroundColor: WARM_NEUTRAL_20,
          }}
          BodyComponent={
            <>
              <Center>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Padding>
                    <Spinner size={75} color={BLUE_60} />
                  </Padding>
                  <Typography>{loadingMessage}</Typography>
                </Flex>
              </Center>
            </>
          }
        />
      )}
    </>
  );
};

export default ModalUploadFile;
