import { gql } from "@apollo/client";
import client from "../utils/gqlclient";
import { tableModules, TABLE_LIMIT } from "../utils/variables";

export async function getTransactions(locale, limit = 10) {
  const data = await client.query({
    query: gqlGetTable,
    variables: {
      lang: locale,
      tableModule: tableModules.TRANSACTIONS,
      offset: 0,
      limit: limit,
    },
  });
  return data.errors ? { errors: data.errors } : data.data.tableData;
}
export async function getTransactionsByObject({
  locale,
  offset = 0,
  limit = 12,
  filter,
  sort,
}) {
  const variables = {
    lang: locale,
    tableModule: tableModules.TRANSACTIONS,
    offset: offset * limit,
    limit: limit,
  };
  if (filter) {
    variables.filter = filter;
  }
  if (sort) {
    variables.sort = sort;
  }
  const data = await client.query({
    query: gqlGetTable,
    variables,
  });
  return data.errors ? { errors: data.errors } : data.data.tableData;
}
export async function getDocuments(locale, uuidEvent, limit = 10) {
  const data = await client.query({
    query: gqlGetDocuments,
    variables: {
      lang: locale,
      uuidModule: uuidEvent,
      tableModule: tableModules.DOCUMENTS,
      offset: 0,
      limit: limit,
    },
  });
  return data.errors ? { errors: data.errors } : data.data.tableData;
}
export async function getDocumentsByObject({
  locale,
  uuidModule,
  limit = 10,
  offset = 0,
  filter,
}) {
  const variables = {
    lang: locale,
    tableModule: tableModules.DOCUMENTS,
    offset: offset * limit,
    limit: limit,
    uuidModule,
  };
  if (filter) {
    variables.filter = filter;
  }
  const data = await client.query({
    query: gqlGetDocuments,
    variables,
  });
  return data.errors ? { errors: data.errors } : data.data.tableData;
}
export async function getOrganizations({
  locale,
  limit = TABLE_LIMIT,
  offset = 0,
  filter,
  sort,
}) {
  const variables = {
    lang: locale,
    tableModule: tableModules.ORGANIZATIONS,
    offset: offset * limit,
    limit,
  };
  if (filter) {
    variables.filter = filter;
  }
  if (sort) {
    variables.sort = sort;
  }
  const data = await client.query({
    query: gqlGetTable,
    variables,
  });
  return data.errors ? { errors: data.errors } : data.data.tableData;
}

export async function getUsers({ locale, uuidModule, limit = TABLE_LIMIT }) {
  const data = await client.query({
    query: gqlGetTableUsers,
    variables: {
      lang: locale,
      tableModule: tableModules.USERS,
      offset: 0,
      limit: limit,
      uuidModule,
    },
  });
  return data.errors ? { errors: data.errors } : data.data.tableData;
}

export async function getTable({
  locale,
  offset = 0,
  limit = TABLE_LIMIT,
  filter,
  sort,
  tableModule,
}) {
  const variables = {
    lang: locale,
    tableModule,
    offset: offset * limit,
    limit: limit,
  };
  if (filter) {
    variables.filter = filter;
  }
  if (sort) {
    variables.sort = sort;
  }
  const data = await client.query({
    query: gqlGetTable,
    variables,
  });
  return data.errors ? { errors: data.errors } : data.data.tableData;
}

const gqlGetTable = gql`
  query (
    $lang: String!
    $tableModule: String!
    $offset: Int!
    $limit: Int!
    $filter: FilterOpsTables
  ) {
    tableData(
      lang: $lang
      tableModule: $tableModule
      offset: $offset
      limit: $limit
      filter: $filter
    ) {
      columns {
        name
        filterable
        sortable
        ref
      }
      rows {
        uuid
        cells {
          value
          format
          colRef
        }
        subrows {
          uuid
          cells {
            value
            format
            colRef
          }
        }
      }
      pagination {
        total
        current
        next
      }
    }
  }
`;

const gqlGetDocuments = gql`
  query (
    $lang: String!
    $tableModule: String!
    $uuidModule: UUID
    $offset: Int!
    $limit: Int!
    $filter: FilterOpsTables
  ) {
    tableData(
      lang: $lang
      tableModule: $tableModule
      uuidModule: $uuidModule
      offset: $offset
      limit: $limit
      filter: $filter
    ) {
      columns {
        name
        filterable
        sortable
        ref
      }
      rows {
        uuid
        cells {
          value
          format
          colRef
        }
      }
    }
  }
`;

const gqlGetTableUsers = gql`
  query (
    $lang: String!
    $tableModule: String!
    $offset: Int!
    $limit: Int!
    $uuidModule: UUID!
  ) {
    tableData(
      lang: $lang
      tableModule: $tableModule
      offset: $offset
      limit: $limit
      uuidModule: $uuidModule
    ) {
      columns {
        name
        filterable
        sortable
        ref
      }
      rows {
        uuid
        cells {
          value
          format
          colRef
        }
      }
    }
  }
`;
