import {
  ClearButton,
  Divider,
  Flex,
  Heading,
  Icon,
  Paper,
  Row,
  Spinner,
  handleFormOK,
  toastError,
} from "@scrapadev/scrapad-front-sdk";
import React, { useState } from "react";
import WidgetOpportunitiesFormItem from "../../opportunities/OpportunitiesCostFormItem";
import WidgetOportunitiestextitem from "../../opportunities/OpportunitiesTextItem";
import { useTranslation } from "../../../utils/hooks";
import styles from "./CostBreakdown.module.scss";
import DropdownRow from "../../../components/ui/dropdown-row/DropdownRow";
import DepositTermsItem from "../../../components/ui/deposit-terms-item/DepositTermsItem";
import { postClient } from "../../../utils/restclient";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import _ from "lodash";

function CostBreakdown({ id, offerData = {}, fetchData = () => {} }) {
  const { t } = useTranslation(["common"]);
  const [isLoading, setLoading] = useState(false);
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();

  const handleDepositEvent = async (slug) => {
    if (isLoading) {
      return;
    }
    setLoading(true);
    const data = {
      uuidUser: user.userUuid,
      slug: slug,
      uuidEvent: id,
    };
    try {
      await postClient({
        external: true,
        url: `${process.env.REACT_APP_API_EVENTS}`,
        method: "POST",
        body: data,
        signOut,
        setDataUser,
      });
      handleFormOK(t, () => {});
    } catch (error) {
      toastError(error.message);
    } finally {
      setLoading(false);
      fetchData();
    }
  };

  return (
    <div className={styles["cost-breakdown"]}>
      <Paper whiteMode>
        <Heading type={5} style={{ margin: 0 }}>
          Totales
        </Heading>
        <WidgetOpportunitiesFormItem
          isLast
          leftComponent={
            <WidgetOportunitiestextitem text={`${t("to_perceive_seller")}`} />
          }
          rightComponent={
            <WidgetOportunitiestextitem text={offerData?.totalSeller} bolder />
          }
          style={{ padding: 0 }}
        />
        <DropdownRow
          label={offerData?.sellerPaymentMethod}
          defaultOpen={!_.isEmpty(offerData?.sellerTerms)}
        >
          <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ width: "100%", gap: "12px" }}
          >
            {offerData?.sellerTerms?.map((term, index) => (
              <DepositTermsItem
                key={`cost_breakdown_st_${index}`}
                label={term?.desc}
                disabled={!term?.active && !term?.date}
                date={
                  <>
                    {term?.date ? (
                      <Row gap={4}>
                        <Icon name={"done"} style={{ fontSize: "12px" }} />{" "}
                        <span>{term?.date}</span>
                      </Row>
                    ) : (
                      <>
                        {term?.active && term?.slug && (
                          <MarkAsResolvedButton
                            isLoading={isLoading}
                            onClick={() =>
                              handleDepositEvent(term?.slug, term?.uuidDeposit)
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                }
                amount={term?.amount}
              />
            ))}
          </Flex>
        </DropdownRow>
        <Divider style={{ width: "100%", margin: "10px 0" }} />
        <WidgetOpportunitiesFormItem
          isLast
          leftComponent={
            <WidgetOportunitiestextitem text={`${t("to_deposit_buyer")}`} />
          }
          rightComponent={
            <WidgetOportunitiestextitem text={offerData?.totalBuyyer} bolder />
          }
          style={{ padding: 0 }}
        />
        <DropdownRow
          label={offerData?.buyerPaymentMethod}
          defaultOpen={!_.isEmpty(offerData?.buyerTerms)}
        >
          <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ width: "100%", gap: "12px" }}
          >
            {offerData?.buyerTerms?.map((term, index) => (
              <DepositTermsItem
                key={`cost_breakdown_bt_${index}`}
                label={term?.desc}
                disabled={!term?.active && !term?.date}
                date={
                  <>
                    {term?.date ? (
                      <Row gap={4}>
                        <Icon name={"done"} style={{ fontSize: "12px" }} />{" "}
                        <span>{term?.date}</span>
                      </Row>
                    ) : (
                      <>
                        {term?.active && term?.slug && (
                          <MarkAsResolvedButton
                            isLoading={isLoading}
                            onClick={() =>
                              handleDepositEvent(term?.slug, term?.uuidDeposit)
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                }
                amount={term?.amount}
              />
            ))}
          </Flex>
        </DropdownRow>
        <Divider style={{ width: "100%", margin: "10px 0" }} />
        <WidgetOpportunitiesFormItem
          isLast
          leftComponent={
            <WidgetOportunitiestextitem text={`${t("net_margin")}`} />
          }
          rightComponent={
            <WidgetOportunitiestextitem
              text={offerData?.marginScrapAd || "0"}
              bolder
            />
          }
          style={{ padding: 0 }}
        />
      </Paper>
    </div>
  );
}

function MarkAsResolvedButton({ onClick, isLoading }) {
  const { t } = useTranslation(["common"]);
  return (
    <ClearButton
      onClick={isLoading ? undefined : onClick}
      style={{
        color: "#3784F6",
        textDecoration: "underline",
        display: "flex",
        gap: "10px",
        alignItems: "center",
        fontWeight: 500,
        fontSize: "12px",
      }}
    >
      {isLoading && <Spinner />}
      {t("mark_as_resolved")}
    </ClearButton>
  );
}

export default CostBreakdown;
