import React, { useContext, useReducer, useMemo } from "react";

const TransactionsContext = React.createContext({
  state: null,
  dispatch: null,
});

const getInitialState = () => {
  return {};
};

const reducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const TransactionsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, getInitialState());

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <TransactionsContext.Provider value={contextValue}>
      {children}
    </TransactionsContext.Provider>
  );
};

export const useTransactionsProvider = () => useContext(TransactionsContext);
