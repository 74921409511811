import { Flex } from "@scrapadev/scrapad-front-sdk";
import BlueCheckbox from "../../components/ui/input/checkbox/BlueCheckBox";
import { TEXT_COLOR } from "../../utils/variables";

/**
 * Helper component to logistics and finances costs checkboxes.
 * @param {object} props - Component properties.
 * @param {object} [props.checked=false] - Checked state.
 * @param {object} [props.onChange] - On change callback.
 * @param {object} props.text - Checkbox text.
 * @param {object} [props.readOnly=false] - .
 * @returns {JSX.Element}
 */
const WidgetOpportunitiesCheck = ({
  checked = false,
  onChange,
  text,
  readOnly = false,
  slug,
}) => {
  const textStyles = {
    fontFamily: "Silka",
    fontSize: 14,
    lineHeight: "14px",
    fontWeight: 400,
    color: TEXT_COLOR,
  };
  return (
    <Flex data-cy={slug || undefined}>
      <BlueCheckbox
        text={text}
        textStyles={textStyles}
        checked={checked}
        readOnly={readOnly}
        itemCheckedStyle={
          readOnly
            ? {
                background: "#f5f5f5",
                borderColor: "#f5f5f5",
                color: "rgb(177, 177, 177)",
              }
            : {}
        }
        onChange={
          readOnly
            ? undefined
            : (e) => {
                if (onChange) onChange(e);
              }
        }
      />
    </Flex>
  );
};

export default WidgetOpportunitiesCheck;
