import { useLayoutEffect } from "react";
import { useParams } from "react-router-dom";
import { getUserData } from "../../../model/business";
import { getUserProfileForEdit } from "../../../model/login";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import { handleCRUD } from "../../../utils/crud/functions";
import { dataURLtoFile, uploadFilesFromBase64 } from "../../../utils/functions";
import { useTranslation } from "../../../utils/hooks";
import GQLFetchWrapper from "../../../wrappers/FetchWrapper";
import ModalFactory from "../modal/ModalFactory";

const EditProfile = ({ open, setOpen }) => {
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const { t, i18n } = useTranslation(["common"]);
  const body = document.querySelector("body");

  useLayoutEffect(() => {
    if (!open) {
      body.style.overflow = "";
    }
  }, [open]);

  return (
    open && (
      <GQLFetchWrapper
        fnData={getUserProfileForEdit}
        Component={ModalFactory}
        type="user_info"
        open={open}
        setOpen={setOpen}
        uuidUser={user.userUuid}
        isProfile
        delegateLoading
        onSubmit={async (state, imageLoading, setImageLoading) => {
          const data = { ...state };
          data.lang = i18n.language;
          delete data.__typename;
          setImageLoading(true);
          if (data?.avatar?.includes("data:image")) {
            let extension = data.avatar.split(";")[0].split("/")[1];
            if (extension === "jpeg") extension = "jpg";
            setImageLoading(true);
            const files = await uploadFilesFromBase64({
              files: [
                dataURLtoFile(
                  data.avatar,
                  `avatar-${user.uuidUser}-${new Date().getTime()}.${extension}`
                ),
              ],
              fileType: "user-profile",
              setDataUser,
              signOut,
              setLoading: setImageLoading,
              t,
            });
            if (files.length > 0) data.profileImgId = files?.[0]?.uuid;
          }
          delete data.avatar;
          // Will add some day
          /*handleCRUD({
            endpoint: `org/${id}/users/${uuidUser}`,
            method: "PUT",
            data: data,
            successCallback: () => fetchData(),
            signOut,
            setDataUser,
            setLoading: setImageLoading,
          });*/
        }}
      />
    )
  );
};

export default EditProfile;
