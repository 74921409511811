import { useEffect, useState } from "react";
import ModalFactory from "../components/ui/modal/ModalFactory";
import { useHistoryBlock } from "../utils/hooks";
import { modalTypes } from "../utils/variables";

/**
 * Wrapper for blockinh history functionality
 * @param {Object} props - Component properties.
 * @param {boolean} props.mustBlockLeaving - Condition that tells if the user can leave the page.
 * @param {boolean} props.afterCantLeaveCallback - Callback after the block.
 * @param {boolean} props.beforeCanLeaveCallback - Callback before the user can leave.
 * @param {boolean} props.submitCallback - Callback after save changes has been clicked.
 * @returns {JSX.Element}
 */
const HistoryBlockWrapper = ({
  mustBlockLeaving,
  afterCantLeaveCallback,
  beforeCanLeaveCallback,
  submitCallback,
  children,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { unblock, action, location, retry } = useHistoryBlock(
    (action, location, retry, unblock) => {
      if (mustBlockLeaving) {
        setModalOpen(true);
        afterCantLeaveCallback &&
          afterCantLeaveCallback(action, location, retry, unblock);
      } else {
        beforeCanLeaveCallback &&
          beforeCanLeaveCallback(action, location, retry, unblock);
        unblock();
        retry();
      }
    },
    mustBlockLeaving
  );

  const cancelModal = () => {
    setModalOpen(false);
    unblock();
    retry();
  };

  return (
    <>
      {children}
      <ModalFactory
        type={modalTypes.SAVE_DATA}
        open={modalOpen}
        setOpen={setModalOpen}
        onCancel={cancelModal}
        submitCallback={submitCallback(modalOpen, setModalOpen)}
      />
    </>
  );
};

export default HistoryBlockWrapper;
