import React, { useEffect } from "react";
import {
  TableProvider,
  useTableDataProvider,
} from "../../providers/TableContext";

/**
 * Table pages layout
 * @param {Object} props - Component properties.
 * @returns {JSX.Element}
 */
const LayoutTable = ({ fnData, fnParameters, subFieldParams, children }) => {
  return (
    <TableProvider
      fnData={fnData}
      fnParameters={fnParameters}
      subFieldParams={subFieldParams}
    >
      <InnerComponent>{children}</InnerComponent>
    </TableProvider>
  );
};

const InnerComponent = ({ children }) => {
  const { fetchData } = useTableDataProvider();
  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      fetchData();
    }
    return () => {
      ignore = true;
    };
  }, []);
  return <>{children}</>;
};

export default LayoutTable;
