import { Paper, Typography } from "@scrapadev/scrapad-front-sdk";
import { costValuesWithDefaults } from "../../utils/opportunities/functions";
import WidgetOpportunitiesFormItem from "./OpportunitiesCostFormItem";
import WidgetOpportunitiesEmpty from "./OpportunitiesEmpty";

/**
 * Helper widget to show costs in read only mode
 * @param {object} props - Component properties
 * @param {object} props.state - Costs current state.
 * @returns {JSX.Element}
 */
const WidgetOpportunitiesCostsReadOnly = ({ state }) => {
  const fields = state.fields;
  if (fields.length === 0) {
    return <WidgetOpportunitiesEmpty />;
  }

  return (
    <Paper whiteMode>
      {fields.map((field, index) => {
        const values = costValuesWithDefaults(field);
        return (
          <WidgetOpportunitiesFormItem
            key={values.slug}
            slug={values.slug}
            isLast={index === fields.length - 1}
            leftComponent={
              <Typography type="2" style={{ margin: 0, fontWeight: 500 }}>
                {values.name}
              </Typography>
            }
            rightComponent={
              values.value ? (
                <Typography type="2" style={{ margin: 0, fontWeight: 500 }}>
                  {`${values.value} ${values?.unit || ""}`}
                </Typography>
              ) : (
                <></>
              )
            }
          />
        );
      })}
    </Paper>
  );
};

export default WidgetOpportunitiesCostsReadOnly;
