import {
  Empty,
  MaterialsCard,
  Spacer,
  Typography,
} from "@scrapadev/scrapad-front-sdk";
import MiniImage from "../../../components/ui/image/MiniImage";
import { useTranslation } from "../../../utils/hooks";
import { TEXT_COLOR_LIGHT } from "../../../utils/variables";
import FavoriteItem from "./FavoriteItem";

const WidgetFavoriteAds = ({ ads }) => {
  const { tMultiple } = useTranslation(["common"]);
  const leftoverAds = false;

  if (ads?.length === 0) {
    return <Empty icon="favorite" iconWidth={100} iconHeight={104} />;
  }

  return (
    <>
      {ads?.map((ad) => {
        return (
          <>
            <MaterialsCard
              boxStyles={{
                marginBottom: 7,
              }}
              containerStyles={{ alignItems: "flex-start" }}
            >
              <FavoriteItem
                leftComponent={<MiniImage src={ad?.images?.[0]} />}
                title={ad?.title}
                subTitle={`${ad?.materialComposition} - ${ad?.materialType}`}
                isBuy={ad?.type === "purchase"}
                isSell={ad?.type === "sale"}
              />
            </MaterialsCard>
          </>
        );
      })}
      {leftoverAds > 0 && (
        <>
          <Spacer height={12} />
          <Typography
            light
            style={{
              color: TEXT_COLOR_LIGHT,
              fontSize: 16,
              marginLeft: 15,
            }}
          >
            {tMultiple("has-more-ads", [leftoverAds])}
          </Typography>
        </>
      )}
    </>
  );
};

export default WidgetFavoriteAds;
