import React, { useEffect, useRef, useState } from "react";
import styles from "./Input.module.scss";

/**
 * UI Base input component.
 * @param {Object} props - Component properties.
 * @param {"outline"|"rounded"} [props.mode="outline"] - Style of dropdown.
 * @param {Object} ref - Forwarded reference.
 * @returns {JSX.Element}
 */
const Input = ({ mode = "outline", ...props }, ref) => {
  const propClassName = props.className;
  //delete props.className;

  const [valid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const innerRef = useRef(null);

  useEffect(() => {
    const pointerRef = ref ? ref : innerRef;
    // Input date case
    if (typeof pointerRef === "function") {
      if (props.value) {
        setValid();
      }
    } else {
      if (pointerRef && pointerRef.current.validity.valid) {
        setValid();
      }
    }
  }, [props.value]);

  const setValid = () => {
    removeErrorClass();
    setIsValid(true);
    setErrorMessage("");
  };

  const addErrorClass = () => {
    const pointerRef = ref ? ref : innerRef;
    if (pointerRef && pointerRef.current) {
      pointerRef.current.classList.add(styles["c-input-invalid"]);
    }
  };

  const removeErrorClass = () => {
    const pointerRef = ref ? ref : innerRef;
    if (pointerRef && pointerRef.current) {
      pointerRef.current.classList.remove(styles["c-input-invalid"]);
    }
  };

  const handleOnInvalid = (e) => {
    e.preventDefault();
    addErrorClass();
    setIsValid(false);
    setErrorMessage(props.customValidityMessage || e.target.validationMessage);
    if (props.afterOnInvalid) {
      props.afterOnInvalid(
        e,
        false,
        valid,
        setIsValid,
        errorMessage,
        setErrorMessage
      );
    }
  };

  const handleOnInput = (e) => {
    if (props.afterOnInput) {
      props.afterOnInput(
        e,
        false,
        valid,
        setIsValid,
        errorMessage,
        setErrorMessage
      );
    }
  };

  const handleLeadingCallback = (e) => {
    if (props.leadingCallback) props.leadingCallback(e);
  };

  const handleTrailingCallback = (e) => {
    if (props.trailingCallback) props.trailingCallback(e);
  };

  const cleanClass = props.cleanInput ? styles["c-input-clean"] : "";
  const leadingClass = props.leadingIcon ? styles["c-input-leading"] : "";
  const trailingClass = props.leadingIcon ? styles["c-input-trailing"] : "";

  return (
    <div className={`${styles["c-input-container"]}`}>
      <div
        className={`${styles["c-input-item"]} ${
          mode === "outline" ? "" : styles["c-input-item--rounded"]
        }`}
      >
        {props.leadingIcon && (
          <span
            className={styles["c-input-leading--icon"]}
            onClick={handleLeadingCallback}
            style={{ cursor: props.leadingCallback ? "pointer" : "default" }}
          >
            {props.leadingIcon}
          </span>
        )}
        <input
          className={`${
            mode === "outline" ? styles["c-input"] : styles["c-input--rounded"]
          } ${leadingClass} ${trailingClass} ${cleanClass} ${
            propClassName ? propClassName : ""
          }`}
          ref={ref || innerRef}
          onInvalid={handleOnInvalid}
          onInput={handleOnInput}
          {...props}
          placeholder={
            props.required && !props.skipRequiredHelp && props.placeholder
              ? `${props.placeholder} *`
              : props.placeholder
          }
        />
        {props.trailingIcon && (
          <span
            className={styles["c-input-trailing--icon"]}
            onClick={handleTrailingCallback}
            style={{
              cursor: props.trailingCallback ? "pointer" : "default",
              pointerEvents: props.trailingCallback ? "auto" : "none",
            }}
          >
            {props.trailingIcon}
          </span>
        )}
        {props.trailingText && (
          <span className={styles["c-input-trailing--text"]}>
            {props.trailingText}
          </span>
        )}
      </div>
      {!valid && (
        <p className={`${styles["c-input-validation-message"]}`}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default React.forwardRef(Input);
