import {
  Divider,
  Flex,
  Heading,
  ImageBox,
  Paper,
  Row,
  Spacer,
  Spinner,
  Typography,
} from "@scrapadev/scrapad-front-sdk";
import React, { useState } from "react";
import styles from "./OpportunityOffer.module.scss";
import OpportunityInfoRow from "../OpportunityInfoRow";
import { useTranslation } from "../../../../utils/hooks";
import ExpandToggler from "../../../../components/ui/expand-toggler/ExpandToggler";

import Menu from "../../../../components/ui/menu/Menu";
import MenuItem from "../../../../components/ui/menu/MenuItem";
import OpportunityStatusIcon from "./OpportunityStatusIcon";
import WidgetDataWrapper from "../../../wrappers/DataWrapper";
import { getOpportunityOfferDetail } from "../../../../model/opportunities";
import _ from "lodash";
import ModalFactory from "../../../../components/ui/modal/ModalFactory";
import { RoleType, modalTypes } from "../../../../utils/variables";
import ModalAcceptOpportunityOffer from "../../../../components/modals/opportunities/Accept";
import ModalRejectOpportunityOffer from "../../../../components/modals/opportunities/RejectOffer";
import ModalDeleteOffer from "../../../../components/modals/opportunities/DeleteOffer";
import { Link } from "react-router-dom";
import { useUser } from "../../../../providers/AuthHooks";
import { offerStatus } from "../../../../utils/opportunities/variables";

function OpportunityOffer({
  uuid,
  title,
  amount,
  price,
  currency,
  owner,
  date,
  status,
  onClick = () => {},
  active = false,
  opportunityType,
  itsMine = false,
  opportunityId,
  transactionUuid,
  opportunityOwner,
  uuidUserCounterOffer,
  opportunityTitle,
  material,
  files = [],
  onEdit = () => {},
  fetchData = () => {},
  onRemoved = () => {},
  closeCostArea = () => {},
}) {
  const { t } = useTranslation(["common"]);
  const user = useUser();

  const [open, setOpen] = useState(false);
  const [openCounter, setOpenCounter] = useState(false);
  const [openAccepted, setOpenAccepted] = useState(false);
  const [openRejected, setOpenRejected] = useState(false);
  const [openRemove, setOpenRemove] = useState(false);

  const onAccept = () => {
    setOpenAccepted(true);
  };
  const onReject = () => {
    setOpenRejected(true);
  };
  const onCounter = () => {
    closeCostArea();
    setOpenCounter(true);
  };

  const onRemove = () => {
    setOpenRemove(true);
  };

  let actionsAvailable = {
    counter: (
      <MenuItem onClick={onCounter}>
        <Typography small reduced style={{ margin: 0 }}>
          {t("counter-offer")}
        </Typography>
      </MenuItem>
    ),
    edit: (
      <MenuItem onClick={onEdit}>
        <Typography small reduced style={{ margin: 0 }}>
          {t("edit")}
        </Typography>
      </MenuItem>
    ),
    remove: (
      <MenuItem onClick={onRemove}>
        <Typography small reduced style={{ margin: 0 }}>
          {t("close")}
        </Typography>
      </MenuItem>
    ),
    accept: (
      <MenuItem onClick={onAccept}>
        <Typography small reduced style={{ margin: 0 }}>
          {t("accept")}
        </Typography>
      </MenuItem>
    ),
    reject: (
      <MenuItem onClick={onReject}>
        <Typography small reduced style={{ margin: 0 }}>
          {t("reject")}
        </Typography>
      </MenuItem>
    ),
  };

  if (
    user?.userData?.role === RoleType.commercial ||
    user?.userData?.role === RoleType.trader
  ) {
    if (!itsMine) {
      if (opportunityOwner !== user?.userUuid) {
        actionsAvailable = {};
      } else {
        delete actionsAvailable.edit;
        delete actionsAvailable.remove;
      }
    } else {
      if (uuidUserCounterOffer === user?.userUuid) {
        delete actionsAvailable.accept;
        delete actionsAvailable.reject;
      }
    }

    if (offerStatus.ACCEPTED === status) {
      actionsAvailable = {};
    }
  }

  if (offerStatus.TRANSACTION_CREATED === status) {
    actionsAvailable = {};
  }

  return (
    <Flex style={{ width: "100%" }} data-cy="opportunity-offer">
      <Paper whiteMode style={{ padding: 0 }}>
        <div
          className={`${styles["opportunity-offer"]} ${
            active ? styles["active"] : ""
          }`}
          onClick={() => onClick(uuid)}
        >
          <div className={styles["header"]}>
            <div className={styles["content"]}>
              <div className={styles["title-wrap"]}>
                {status && (
                  <div className={styles["status"]}>
                    <OpportunityStatusIcon type={status} />
                  </div>
                )}

                <h6
                  className={styles["title"]}
                  data-cy="opportunity-offer-title"
                >
                  {title}
                </h6>
                {status === offerStatus.TRANSACTION_CREATED && (
                  <>
                    -
                    <Link
                      to={`/transactions/${transactionUuid}`}
                      target="_blank"
                      className={styles["transaction-link"]}
                    >
                      <span data-cy="opportunity-offer-status">
                        {t("transaction")}
                      </span>
                    </Link>
                  </>
                )}
              </div>
              <div className={styles["meta"]} data-cy="opportunity-offer-price">
                {amount} {t("to")} {price}
              </div>
            </div>
            <div className={styles["actions"]}>
              <Row
                align="flex-end"
                justify="flex-end"
                style={{ marginTop: "-10px", marginRight: "-8px" }}
                slug="opportunity-offer-menu"
              >
                {!_.isEmpty(actionsAvailable) && (
                  <Menu
                    selfManaged={true}
                    items={Object.values(actionsAvailable)}
                  />
                )}
              </Row>
              <ExpandToggler
                open={open}
                onClick={() => setOpen(!open)}
                slug="opportunity-offer-toggle"
              />
            </div>
          </div>
          {open && (
            <>
              <OpportunityOfferDetailWrapper id={uuid} />
            </>
          )}
          <Divider />
          <div className={styles["footer"]}>
            <p className={styles["owner"]} data-cy="opportunity-offer-owner">
              {owner}
            </p>
            <div className={styles["meta"]}>
              <span className={styles["date"]} data-cy="opportunity-offer-date">
                {date}
              </span>
            </div>
          </div>
        </div>
      </Paper>
      {openCounter && (
        <ModalFactory
          type={modalTypes.OPPORTUNITY_COUNTER_OFFER}
          open={openCounter}
          setOpen={() => {
            setOpenCounter(false);
          }}
          price={price}
          amount={amount}
          uuid={uuid}
          opportunityId={opportunityId}
          fetchData={fetchData}
          currency={currency}
          itsMine={itsMine}
          opportunityType={opportunityType}
          opportunityTitle={opportunityTitle}
          material={material?.name}
          files={files}
        />
      )}

      <ModalAcceptOpportunityOffer
        open={openAccepted}
        setOpen={setOpenAccepted}
        uuid={uuid}
        opportunityId={opportunityId}
        fetchData={fetchData}
        price={price}
        amount={amount}
      />
      <ModalRejectOpportunityOffer
        open={openRejected}
        setOpen={setOpenRejected}
        uuid={uuid}
        opportunityId={opportunityId}
        fetchData={fetchData}
      />
      <ModalDeleteOffer
        open={openRemove}
        setOpen={setOpenRemove}
        uuid={uuid}
        opportunityId={opportunityId}
        fetchData={() => {
          fetchData();
          onRemoved();
        }}
      />
    </Flex>
  );
}

function OpportunityOfferDetailWrapper({ id }) {
  const { i18n } = useTranslation(["common"]);
  return (
    <div style={{ width: "100%" }}>
      <WidgetDataWrapper
        id={id}
        Component={OpportunityOfferDetailBlock}
        fnData={getOpportunityOfferDetail}
        fnParameters={{
          uuid: id,
          locale: i18n.language,
          mock: false,
        }}
      />
    </div>
  );
}

function OpportunityOfferDetailBlock({
  loading,
  setLoading,
  state,
  dispatch,
  actions,
  fetchData,
  extraData,
  isDirty,
  setIsDirty,
  bufferState,
  checkIfStateIsDirty,
  ...props
}) {
  const { t } = useTranslation(["common"]);

  if (loading) {
    return <Spinner />;
  }

  const getAddressName = (address = {}, hideStreet = false) => {
    let output = "";

    if (!_.isEmpty(address?.street) && !hideStreet) {
      output += `${address?.street} \n`;
    }
    if (!_.isEmpty(address?.postalCode)) {
      output += `${address?.postalCode}, `;
    }
    if (!_.isEmpty(address?.city)) {
      output += `${address?.city}, `;
    }
    if (!_.isEmpty(address?.state)) {
      output += `${address?.state}, `;
    }
    if (!_.isEmpty(address?.country)) {
      output += `${address?.country} `;
    }
    if (!_.isEmpty(address?.port?.name)) {
      output += `- ${address?.port?.name} `;
    }

    return output;
  };

  return (
    <>
      <Divider />
      <div className={styles["body"]}>
        {state?.paymentMethod && (
          <OpportunityInfoRow
            label={t("payment-method")}
            desc={state?.paymentMethod?.name}
          />
        )}
        {state?.incoterm && (
          <OpportunityInfoRow
            label={t("incoterm")}
            desc={state?.incoterm?.name}
          />
        )}
        {!_.isEmpty(state?.address) && (
          <OpportunityInfoRow
            label={t("address")}
            desc={getAddressName(state?.address)}
          />
        )}
        {state?.specifications && (
          <>
            <Spacer height={5} />
            <Heading type={6} style={{ fontSize: "14px", margin: 0 }}>
              {t("specifications")}
            </Heading>
            <OpportunityInfoRow desc={`${state?.specifications}`} />
          </>
        )}
        <Row gap={15}>
          {state?.files?.map((file) => (
            <ImageBox
              key={`op_of_file_${file?.location}`}
              url={file?.location}
              label={file?.name}
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.open(file?.location, "_blank", "noreferrer");
              }}
            />
          ))}
        </Row>
      </div>
    </>
  );
}

export default OpportunityOffer;
