import {
  Center,
  Padding,
  Spacer,
  Empty,
  useMatchMedia,
  BREAKPOINT_LG,
  MaterialsCard,
  MaterialsItem,
  Favorite,
  ClearButton,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import Separator from "../../../components/ui/separator/Separator";
import { DataProvider } from "../../../providers/data/DataContext";
import { useTranslation } from "../../../utils/hooks";
import { modalTypes, TEXT_COLOR_LIGHT } from "../../../utils/variables";

const WidgetFavouritesSearchs = ({ state }) => {
  const { tMultiple } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleEdit = () => setOpenEditModal(!openEditModal);

  const handleDelete = () => setOpenDeleteModal(!openDeleteModal);

  if (state?.searchs?.length === 0) {
    return (
      <Center>
        <Padding>
          {matches ? <></> : <Spacer height={80} />}
          <Empty
            heading="Todavía no hay nada guardado en esta sección"
            icon="favorite"
          />
        </Padding>
      </Center>
    );
  }
  return (
    <>
      <MaterialsCard>
        <MaterialsItem
          beforeTitleComponent={
            <Favorite
              uuid={1}
              active
              containerStyles={{
                background: "#F5F5F5",
                borderRadius: 20,
                cursor: "pointer",
              }}
            />
          }
          beforeMenuComponent={
            JSON.parse(
              process.env.REACT_APP_FAVORITES_SHOW_VIEW_ADS_STORED_SEARCHS
            ) ? (
              <>
                <ClearButton
                  onClick={() => {}}
                  style={{
                    fontWeight: 300,
                    fontSize: 14,
                    lineHeight: "18px",
                    textDecoration: "underline",
                    color: "#7E7E7E",
                  }}
                >
                  {tMultiple("view-number-ads", [6])}
                </ClearButton>
                <Separator color={"#B1B1B1"} height={18} hideOnMobile={false} />
              </>
            ) : (
              <></>
            )
          }
          title={"Hierro de Reino Unido en venta"}
          titleBold
          isBuy
          handleEdit={handleEdit}
          handleRemove={handleDelete}
          itemsIconProps={{
            color: TEXT_COLOR_LIGHT,
            iconStyle: { fontSize: "1.25rem" },
          }}
          iconColor={"#9E9E9E"}
          matches={matches}
        />
      </MaterialsCard>
      <DataProvider>
        <ModalFactory
          type={modalTypes.FAVORITES_EDIT_SEARCH}
          open={openEditModal}
          setOpen={setOpenEditModal}
          onSubmit={(search) => {}}
          parentSearch={{
            value: "aluminio",
            selected: "",
            isSell: true,
            isBuy: false,
            results: 0,
          }}
        />
      </DataProvider>
      <ModalFactory
        type={modalTypes.FAVORITES_DELETE}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        onSubmit={(search) => {}}
      />
    </>
  );
};

export default WidgetFavouritesSearchs;
