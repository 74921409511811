import { Button } from "@scrapadev/scrapad-front-sdk";
import { useMatchMedia, useTranslation } from "../../utils/hooks";
import { BREAKPOINT_MD } from "../../utils/variables";

/**
 * Widget for save discard data.
 * @param {Object} props - Component properties.
 * @param {Boolean} props.isDirty - Widget is dirty, must show the buttons.
 * @param {Function} props.handleDiscard - Widget reset function.
 * @returns {JSX.Element}
 */
const WidgetFieldSaveDiscard = ({
  isDirty,
  handleDiscard,
  loading,
  permission,
}) => {
  const { t } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);
  return (
    isDirty && (
      <div className="row">
        <div
          className="col-12"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            flexDirection: matches ? "column" : "row",
          }}
        >
          <Button
            fullwidth={matches ? true : undefined}
            component="button"
            type="button"
            mode="normal"
            text={t("discard-changes")}
            onClick={handleDiscard}
            style={{
              marginRight: matches ? 0 : "1.5rem",
              marginBottom: matches ? "1.5rem" : 0,
            }}
          />
          <Button
            disabled={!permission()}
            fullwidth={matches ? true : undefined}
            component="button"
            type="submit"
            text={t("save-changes")}
            loading={loading}
            style={loading ? { paddingLeft: "4rem", paddingRight: "4rem" } : {}}
          />
        </div>
      </div>
    )
  );
};

export default WidgetFieldSaveDiscard;
