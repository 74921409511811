import { IconItem } from "@scrapadev/scrapad-front-sdk";
import { useFiltersProvider } from "../../../providers/filters/FiltersContext";
import { useSortProvider } from "../../../providers/sort/SortContext";
import { useTranslation } from "../../../utils/hooks";
import { TEXT_COLOR_LIGHT } from "../../../utils/variables";

/**
 * Button for clear pagination, filters and sorting, restoring tables to default state.
 *
 * @param {Object} props - Component properties
 * @param {string} [props.removeText] - Text of the button.
 * @param {string} [props.clearAll] - Clear all filters override.
 * @returns {JSX.Element}
 */
const RestoreTable = ({ removeText, clearAll, ...props }) => {
  const { t } = useTranslation("common");
  const { cleanSort } = useSortProvider();
  const { state: filters, cleanFilters } = useFiltersProvider();

  return (
    <IconItem
      name={"u_turn_left"}
      onClick={() => {
        cleanSort();
        if (clearAll) {
          clearAll();
        } else {
          cleanFilters();
        }
        Object.keys(filters).forEach((key) => {
          const filter = filters[key];
          if (filter.clearAll) {
            filter.clearAll(filter);
          }
        });
      }}
      text={removeText || t("restore-table")}
      iconStyle={{ transform: "scaleY(1) rotate(90deg)" }}
      buttonStyle={{ whiteSpace: "nowrap" }}
      color={TEXT_COLOR_LIGHT}
      textStyle={{ textDecoration: "underline", color: TEXT_COLOR_LIGHT }}
      {...props}
    />
  );
};

export default RestoreTable;
