import { Chip, Icon } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import Separator from "../../../components/ui/separator/Separator";
import { useTranslation } from "../../../utils/hooks";
import {
  GRAY_NEUTRAL_40,
  RED_COLOR_100,
  INFO_COLOR,
} from "../../../utils/variables";
import styles from "./Type.module.scss";

const SeaIcon = () => {
  return (
    <Icon
      name="directions_boat"
      color={INFO_COLOR}
      style={{ marginRight: 6 }}
    />
  );
};

const FavoriteIcon = () => {
  return (
    <Icon name="favorite" color={RED_COLOR_100} style={{ marginRight: 6 }} />
  );
};

/**
 * Helper component for type of address chips.
 * @param {Object} props - Component properties.
 * @param {Object} props.address - Address data.
 * @param {Boolean} [props.separator=false] - Use a separator.
 * @param {React.CSSProperties} [props.separatorStyle={}] - SEparator inline style.
 * @returns {JSX.Element}
 */
const AddressesType = ({ address, separator = false, separatorStyle = {} }) => {
  const { t } = useTranslation(["common"]);
  return address?.type === "" ? (
    <></>
  ) : (
    <div className={styles["sc-addresses--chips"]}>
      {address?.port?.uuid && (
        <Chip
          text={
            <>
              <SeaIcon />
              {t("by-sea")}
            </>
          }
        />
      )}
      {(address?.type === "collection" || address?.type === "both") && (
        <Chip
          text={
            address?.isCollectionDefault ? (
              <>
                <FavoriteIcon />
                {t("collection")}
              </>
            ) : (
              t("collection")
            )
          }
        />
      )}
      {(address?.type === "delivery" || address?.type === "both") && (
        <Chip
          text={
            address?.isDeliveryDefault ? (
              <>
                <FavoriteIcon />
                {t("delivery")}
              </>
            ) : (
              t("delivery")
            )
          }
        />
      )}
      {separator && (
        <Separator color={GRAY_NEUTRAL_40} style={separatorStyle} />
      )}
    </div>
  );
};

export default AddressesType;
