import { ClearButton } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import { useTranslation } from "../../../utils/hooks";
import { TEXT_COLOR_LIGHT } from "../../../utils/variables";
import AdItem from "../../ads/Item";

/**
 * Helper component to render favorites widget item.
 * @param {Object} props - Component properties.
 * @param {React.ReactNode} [props.leftComponent] - Custom left component, an image for example.
 * @param {String} props.title - Item title.
 * @param {String} props.description - Item description.
 * @param {String} [props.subTitle] - Item subtitle.
 * @param {Boolean} [props.isSell] - Is a sell ad.
 * @param {Boolean} [props.isBuy] - Is a buy ad.
 * @param {Number} [props.ads] - Number of ads.
 * @returns {JSX.Element}
 */
const FavoriteItem = ({
  leftComponent,
  title,
  subTitle,
  description,
  isSell,
  isBuy,
  ads,
}) => {
  const { t, tMultiple } = useTranslation(["common"]);
  const parsedAds = parseInt(ads);
  const handleGoToAds = () => {};
  return (
    <AdItem
      leftComponent={leftComponent}
      title={title}
      subTitle={subTitle}
      description={description}
      isSell={isSell}
      isBuy={isBuy}
      ads={
        ads !== undefined ? (
          <ClearButton
            onClick={parsedAds === 0 ? undefined : handleGoToAds}
            style={{
              fontWeight: 300,
              fontSize: 14,
              lineHeight: "18px",
              color: TEXT_COLOR_LIGHT,
              textDecoration: parsedAds === 0 ? "none" : "underline",
            }}
          >
            {parsedAds === 0
              ? t("no-ads")
              : tMultiple("view-number-ads", [ads])}
          </ClearButton>
        ) : (
          <></>
        )
      }
    />
  );
};

export default FavoriteItem;
