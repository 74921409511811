import ModalFactory from "../../components/ui/modal/ModalFactory";
import { modalTypes } from "../../utils/variables";

const WidgetAdsTransactions = ({
  adUuid,
  transactionsOpen,
  setTransactionsOpen,
  ad,
}) => {
  return (
    <ModalFactory
      type={modalTypes.ADS_TRANSACTIONS}
      open={transactionsOpen}
      setOpen={setTransactionsOpen}
      adUuid={adUuid}
      ad={ad}
    />
  );
};

export default WidgetAdsTransactions;
