import {
  Flex,
  Heading,
  IconItem,
  Loading,
  Spacer,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useRef, useState } from "react";
import { getOpportunitiesCosts } from "../../model/opportunities";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { checkPermission } from "../../utils/functions";
import { useTranslation } from "../../utils/hooks";
import {
  costValuesWithDefaults,
  getFetchData,
  perceiveDepositWithDefaults,
} from "../../utils/opportunities/functions";
import { offerStatus } from "../../utils/opportunities/variables";
import {
  INFO_COLOR,
  modalTypes,
  RoleType,
  TEXT_COLOR,
} from "../../utils/variables";
import WidgetDataWrapper from "../wrappers/DataWrapper";
import WidgetOpportunitiesCostsForm from "./OpportunitiesCostForm";
import WidgetOpportunitiesCostsReadOnly from "./OpportunitiesCostsRead";
import WidgetOpportunitiesEmpty from "./OpportunitiesEmpty";
import WidgetOpportunitiesImputed from "./OpportunitiesImputed";
import WidgetOpportunitiesTotals from "./OpportunitiesTotal";
import OpportunityOfferAccepted from "./widgets/Offer/OpportunityOfferAccepted";
import { useUser } from "../../providers/AuthHooks";
import ModalFactory from "../../components/ui/modal/ModalFactory";

/**
 * Opportunities costs form.
 * @param {object} props - Function properties.
 * @param {string} props.id - Offer id.
 * @param {object} props.state - Costs current state.
 * @param {Function} props.dispatch - Widget provider dispatch.
 * @param {object} props.actions - Widget provider actions.
 * @param {boolean} props.loading - Widget loading state.
 * @param {Function} props.setLoading - Loading setter.
 * @param {Function} props.fetchData - Fetching function.
 * @returns {JSX.Element}
 */
const InnerWidgetOpportunitiesCosts = ({
  id,
  state,
  dispatch,
  fetchData,
  loading,
  setLoading,
  actions,
  opportunityId,
  offers,
  opportunityType,
  opportunityOwner,
  updateList = () => {},
}) => {
  const user = useUser();
  const fields = Object.values(state.fields || []);
  const { t } = useTranslation(["common"]);
  const { canReadFinances, canReadLogistics } = useOperationsProvider();
  const permission =
    checkPermission(canReadFinances) || checkPermission(canReadLogistics);
  const financesOnlyPermission = checkPermission(canReadFinances);
  const firstLoaded = useRef(false);
  const prevLoading = useRef(loading);
  const [editOpen, setEditOpen] = useState(false);
  const isAccepted =
    offers?.find((f) => f?.uuidOffer === id)?.status === offerStatus.ACCEPTED;

  useEffect(() => {
    firstLoaded.current = false;
  }, [id]);

  // Here we calculate when its first loading to separate from cost blur loading.
  useEffect(() => {
    if (prevLoading.current && !loading && !firstLoaded.current) {
      firstLoaded.current = true;
    }
    prevLoading.current = loading;
  }, [loading]);

  useEffect(() => {
    handleUpdate();
  }, [editOpen]);

  const handleUpdate = async () => {
    setLoading(true);
    fetchData(false, getFetchData(true, id));
  };

  const renderHead = (child) => {
    return (
      <Flex
        flexDirection={"column"}
        alignItems={"flex-start"}
        style={{ width: "100%", paddingLeft: 5, paddingRight: 5 }}
        data-accepted={isAccepted ? 1 : 0}
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ width: "100%" }}
        >
          <Heading
            type={3}
            style={{
              margin: 0,
              color: TEXT_COLOR,
              fontSize: 17,
              lineHeight: "25px",
            }}
          >
            {t("costs")}
          </Heading>
          <Flex style={{ height: 25 }}>
            {loading ? <Loading /> : <></>}
            <IconItem
              name={"refresh"}
              text={loading ? t("updating") : t("update")}
              onClick={handleUpdate}
              buttonStyle={{ color: INFO_COLOR }}
              textStyle={{
                color: INFO_COLOR,
                fontSize: 13,
                fontWeight: 600,
                lineHeight: "25px",
                textDecoration: "underline",
                marginLeft: -10,
              }}
            />
          </Flex>
        </Flex>
        {isAccepted && financesOnlyPermission && (
          <OpportunityOfferAccepted
            opportunityId={opportunityId}
            id={id}
            loading={loading}
            setLoading={setLoading}
            fetchData={fetchData}
            offers={offers}
          />
        )}
        {child}
      </Flex>
    );
  };

  if (loading && !firstLoaded.current) {
    return <Loading />;
  }

  if (fields.length === 0) {
    return <WidgetOpportunitiesEmpty />;
  }

  if (!permission) {
    return (
      <>
        {renderHead(
          <>
            <Spacer />
            <WidgetOpportunitiesImputed
              readOnly
              id={id}
              state={state}
              dispatch={dispatch}
              actions={actions}
              loading={loading}
              setLoading={setLoading}
              fetchData={fetchData}
              opportunityId={opportunityId}
            />
            <Spacer />
            <WidgetOpportunitiesTotals
              state={state}
              opportunityId={opportunityId}
              hideNetMargin
            />
            {permission && (
              <>
                <Spacer />
                <WidgetOpportunitiesCostsReadOnly
                  state={state}
                  opportunityId={opportunityId}
                />
              </>
            )}
          </>
        )}
      </>
    );
  }

  return renderHead(
    <>
      <Spacer />
      <WidgetOpportunitiesImputed
        id={id}
        state={state}
        dispatch={dispatch}
        actions={actions}
        loading={loading}
        setLoading={setLoading}
        fetchData={fetchData}
        opportunityId={opportunityId}
      />
      <Spacer />
      <WidgetOpportunitiesTotals
        state={state}
        opportunityId={opportunityId}
        hideNetMargin
        opportunityType={opportunityType}
        itsMineOpportunity={
          opportunityOwner === user?.userUuid ||
          user?.userData?.role === RoleType.superadmin
        }
        onEditPaymentConditions={() => {
          setEditOpen(true);
        }}
      />
      {permission && (
        <>
          <Spacer />
          <WidgetOpportunitiesCostsForm
            id={id}
            state={state}
            dispatch={dispatch}
            actions={actions}
            loading={loading}
            setLoading={setLoading}
            fetchData={fetchData}
            opportunityId={opportunityId}
            updateList={updateList}
          />
        </>
      )}
      {editOpen && (
        <ModalFactory
          open={editOpen}
          setOpen={setEditOpen}
          type={modalTypes.OPPORTUNITY_OFFER_EDIT_PAYMENT_CONDITIONS}
          offerUuid={id}
        />
      )}
    </>
  );
};

const WidgetOpportunitiesCosts = ({
  opportunityId,
  uuid,
  offers,
  opportunityType,
  opportunityOwner,
  updateList = () => {},
}) => {
  if (!uuid) return <></>;
  return (
    <div style={{ width: "100%" }}>
      <WidgetDataWrapper
        id={uuid}
        Component={InnerWidgetOpportunitiesCosts}
        fnData={getOpportunitiesCosts}
        fnParameters={getFetchData(true, uuid)}
        delegateLoading
        opportunityId={opportunityId}
        opportunityType={opportunityType}
        opportunityOwner={opportunityOwner}
        dataAdapter={(data) => {
          const parsedData = {
            logistics: data?.statusCosts?.logistic || false,
            finances: data?.statusCosts?.finance || false,
            isAccepted: data?.statusCosts?.isAccepted || false,
            fields: data.costs
              ? data.costs.map((field, index) => {
                  const values = costValuesWithDefaults(field);
                  return {
                    ...values,
                  };
                })
              : [],
            margin: data?.offerData?.marginScrapAd || 0,
            toPerceive: perceiveDepositWithDefaults({
              title: data?.offerData?.sellerPaymentMethod,
              amount: data?.offerData?.totalSeller,
              perTon: data?.offerData?.pricePerTonSeller,
            }),
            toDeposit: perceiveDepositWithDefaults({
              title: data?.offerData?.buyerPaymentMethod,
              amount: data?.offerData?.totalBuyyer,
              perTon: data?.offerData?.pricePerTonBuyer,
            }),
            currency: "",
          };
          return parsedData;
        }}
        offers={offers}
        updateList={updateList}
      />
    </div>
  );
};

export default WidgetOpportunitiesCosts;
