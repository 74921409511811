import { useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import {
  BREAKPOINT_LG,
  Flex,
  InputNumber,
  Modal,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import { useSideBarEdit } from "../../../utils/transactions/hooks";
import ModalText from "../../ui/modal/text/ModalText";
import Label from "../../ui/input/label/Label";
import { buildChangeFinanciation } from "../../forms/transaction/functions";

/**
 * Modal for changing icoterm.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalChangeFinanciation = ({ open = false, setOpen, ...props }) => {
  const { uuidEvent, fetchData, financing } = props;
  const { t } = useTranslation(["finances", "common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [financingNew, setFinancingNew] = useState(
    financing?.replace(" €", "")
  );
  const { loading, setLoading, handleAction, userUuid } = useSideBarEdit();

  const handleSubmit = async (e) => {
    if (!uuidEvent) return;
    const data = buildChangeFinanciation(userUuid, uuidEvent, financingNew);
    handleAction(data, fetchData);
  };

  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("financing-service")}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("save-changes")}
      cancelText={t("cancel")}
      loading={loading}
      maxWidth={790}
      modalStyles={{ width: matches ? "auto" : 790 }}
      BodyComponent={
        <Flex flexDirection="column" alignItems={"flex-start"}>
          <ModalText
            text={t("financing-define")}
            style={{ marginBottom: 20 }}
          />
          <Label htmlFor="financing">{t("financing-interest")}</Label>
          <InputNumber
            required
            placeholder={t("write-here")}
            min={0.01}
            id={"financing"}
            step="0.01"
            value={`${financingNew}`}
            onChange={(e) => {
              setFinancingNew(e.target.value);
            }}
            trailingText={"%"}
          />
        </Flex>
      }
    />
  );
};

export default ModalChangeFinanciation;
