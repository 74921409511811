import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { authInCognito, verifySession } from "../model/cognito";
import { getUserProfile } from "../model/login";
import { getLSValueByPartialKey } from "../utils/functions";
import { useTranslation } from "../utils/hooks";
import { postClient } from "../utils/restclient";
import {
  publicPathsThatDontTriggerRedirectoToLogin,
  storageKeys,
} from "../utils/variables";
import localforage from "localforage";
import { firebaseCloudMessaging } from "../utils/firebase";
import { deleteApp } from "firebase/app";
import { useOperationsProvider } from "./organization/OperationsContext";

export const AuthContext = createContext({
  user: null,
  // eslint-disable-next-line no-unused-vars
  signIn: async (input) => {},
  signOut: async () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const location = useLocation();
  const { setOperations } = useOperationsProvider();

  useEffect(() => {
    window.addEventListener("storage", reloadState);
    return () => {
      window.removeEventListener("storage", reloadState);
    };
  }, []);

  useEffect(() => {
    let active = true;
    const check = async () => {
      try {
        const uuidUserFromLocalStorage =
          window.localStorage.getItem("user-uuid");
        if (!uuidUserFromLocalStorage) {
          setUser(null);
          if (
            !publicPathsThatDontTriggerRedirectoToLogin.includes(
              location.pathname
            )
          ) {
            navigate("/login", { replace: true });
          }
        } else {
          const session = await verifySession(uuidUserFromLocalStorage);
          setDataUser(session, active);
        }
      } catch (e) {
        console.error(e);
        if (active) {
          setUser(null);
          navigate("/login", { replace: true });
        }
      }
    };
    check();
    return () => {
      active = false;
    };
  }, []);

  const setDataUser = (session, active) => {
    putAuthData(session);

    const userData = {
      userData: {
        fullName: window.localStorage.getItem("fullName"),
        userOrgName: window.localStorage.getItem("userOrgName"),
        orgUuid: window.localStorage.getItem("orgUuid"),
        role: window.localStorage.getItem("role"),
      },
      authToken: session.idToken.jwtToken,
      refresToken: session.refreshToken.token,
      accessToken: session.accessToken.jwtToken,
      userUuid: session.idToken.payload.sub,
    };
    if (active) setUser(userData);
  };

  const reloadState = () => {
    if (typeof window !== "undefined") {
      const lskeys = Object.keys(window.localStorage);
      const exist = lskeys.filter((lsk) => lsk.includes(".idToken"));
      if (exist.length > 0) {
        const userUuid = window.localStorage.getItem("user-uuid");
        const authToken = getLSValueByPartialKey(`.idToken`);
        const refresToken = getLSValueByPartialKey(`.refreshToken`);
        const accessToken = getLSValueByPartialKey(`.accessToken`);
        const fullname = window.localStorage.getItem("fullName");
        const userOrgName = window.localStorage.getItem("userOrgName");
        const orgUuid = window.localStorage.getItem("orgUuid");
        setUser({
          userData: {
            fullName: fullname,
            userOrgName: userOrgName,
            orgUuid: orgUuid,
          },
          authToken: authToken,
          refresToken: refresToken,
          accessToken: accessToken,
          userUuid: userUuid,
        });
      }
    }
  };

  const putAuthData = (payload) => {
    const userUuid = payload.idToken.payload.sub;
    const aud = payload.idToken.payload.aud;
    const idToken = `CognitoIdentityServiceProvider.${aud}.${userUuid}.idToken`;
    const refresToken = `CognitoIdentityServiceProvider.${aud}.${userUuid}.refreshToken`;
    const lastUser = `CognitoIdentityServiceProvider.${aud}.LastAuthUser`;
    const clockDrift = `CognitoIdentityServiceProvider.${aud}.${userUuid}.clockDrift`;
    const accessToken = `CognitoIdentityServiceProvider.${aud}.${userUuid}.accessToken`;
    window.localStorage.setItem(idToken, payload.idToken.jwtToken);
    window.localStorage.setItem(refresToken, payload.refreshToken.token);
    window.localStorage.setItem(lastUser, userUuid);
    window.localStorage.setItem(clockDrift, payload.clockDrift);
    window.localStorage.setItem(accessToken, payload.accessToken.jwtToken);
    window.localStorage.setItem(`aud`, aud);
    window.localStorage.setItem("user-uuid", userUuid);
  };

  const signIn = useCallback(
    async ({ email, password }) => {
      const payload = await authInCognito({ email, password });
      putAuthData(payload);
      let profileData = {
        userName: "",
        orgUuid: "",
      };
      const results = await getUserProfile();

      if (results?.errors?.length > 0) {
        if (
          results.errors[0].extensions.code === "401" ||
          results.errors[0].extensions.code === "403"
        ) {
          signOut();
        }
      } else {
        profileData["userName"] = results?.person?.userName;
        profileData["orgUuid"] = results?.person?.orgUuid;
        profileData["rights"] = results?.rights || [];
        profileData["role"] = results?.role;
        profileData[storageKeys.LANGUAGE] = results?.person?.lang || "es";
        setOperations(profileData?.rights);
        /*setOperations([
          {
            operation: "MANAGEMENT_ADS",
            mode: "READ",
          },
          {
            operation: "MANAGEMENT_ORG",
            mode: "READ",
          },
        ]);*/
      }
      window.localStorage.setItem("fullName", profileData["userName"]);
      window.localStorage.setItem("orgUuid", profileData["orgUuid"]);
      window.localStorage.setItem("role", profileData["role"]);
      window.localStorage.setItem(
        storageKeys.LANGUAGE,
        profileData[storageKeys.LANGUAGE]
      );
      /*window.localStorage.setItem(
        "rights",
        JSON.stringify([
          {
            operation: "MANAGEMENT_ADS",
            mode: "READ",
          },
          {
            operation: "MANAGEMENT_ORG",
            mode: "READ",
          },
        ])
      );*/
      window.localStorage.setItem("rights", JSON.stringify(results?.rights));
      const userData = {
        userData: {
          fullName: profileData["userName"],
          orgUuid: profileData["orgUuid"],
          language: profileData[storageKeys.LANGUAGE],
          rights: profileData?.rights,
          role: profileData?.role,
        },
        authToken: payload.idToken.jwtToken,
        refresToken: payload.refreshToken.token,
        accessToken: payload.accessToken.jwtToken,
        userUuid: payload.idToken.payload.sub,
      };
      setUser(userData);
    },
    [user]
  );

  const signOut = useCallback(async () => {
    await deleteFirebaseToken();
    const dataKeys = [
      "fullName",
      "aud",
      "orgUuid",
      "user-uuid",
      "userOrgName",
      "rights",
      "role",
      storageKeys.LANGUAGE,
    ];
    Object.keys(window.localStorage)
      .filter(
        (key) =>
          key.includes(`CognitoIdentityServiceProvider`) ||
          key.includes(`Filters`) ||
          key.includes(`filters`) ||
          key.includes(`Pagination`) ||
          key.includes(`pagination`) ||
          key.includes(`sort`)
      )
      .forEach((key) => window.localStorage.removeItem(key));
    dataKeys.forEach((d) => {
      window.localStorage.removeItem(d);
    });
    setUser(null);
    navigate("/login", { replace: true });
  }, [user]);

  const setFirebaseToken = useCallback(async (token) => {
    try {
      const payload = await postClient({
        url: "/v2/notifications/user-token",
        method: "POST",
        body: { token: token, channel: "push", deviceType: "web" },
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const deleteFirebaseToken = async () => {
    const tokenInLocalForage = await localforage.getItem("fcm_token");
    try {
      postClient({
        url: `/v2/notifications/user-token/${tokenInLocalForage}`,
        method: "DELETE",
        signOut,
        setDataUser,
      });
    } catch (error) {
      console.log(error);
    }
    await localforage.removeItem("fcm_token");
    const fapp = await firebaseCloudMessaging.getApp();
    deleteApp(fapp);
  };

  const contextValue = useMemo(() => {
    return { user, signIn, signOut, setDataUser, setFirebaseToken };
  }, [user, signIn, signOut, setDataUser, setFirebaseToken]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
