import styles from "./Text.module.scss";

/**
 * UI Text component.
 * @param {Object} props - Component properties.
 * @param {Boolean} [props.light=false] - Displays text in a lighter color and reduced font size.
 * @param {Boolean} [props.bold=false] - Displays text in bold and reduced font size..
 * @param {Boolean} [props.withEllipsis=false] - Sets text-overflow: ellipsis
 * @param {React.CSSProperties} [props.style={}] - Inline styles
 * @returns {JSX.Element}
 */
const Text = ({
  light = false,
  bold = false,
  withEllipsis = false,
  style = {},
  children,
  ...props
}) => {
  const boldClass = bold ? styles["sc-text--bold"] : "";
  const lightClass = light ? styles["sc-text--light"] : "";
  const ellipsisClass = withEllipsis ? styles["sc-text--ellipsis"] : "";
  return (
    <p
      className={`${styles["sc-text"]} ${lightClass} ${boldClass} ${ellipsisClass}`}
      style={style}
      {...props}
    >
      {children}
    </p>
  );
};

export default Text;
