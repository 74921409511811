import {
  Dropdown,
  Flex,
  InputText,
  Label,
  Padding,
  Spinner,
} from "@scrapadev/scrapad-front-sdk";
import React, { useEffect, useRef, useState } from "react";
import { useSetDataUser, useSignOut, useUser } from "../../providers/AuthHooks";
import { gqlWrapper } from "../../utils/gqlwrapper";
import styles from "./AddressForm.module.scss";
import { useTranslation } from "../../utils/hooks";
import { getFavAddresses } from "../../model/business";
import InputCountries from "../../components/ui/input/country/Country";
import { printAddress } from "../../utils/functions";

function AddressForm({
  id,
  state,
  uuidOrg,
  readOnly,
  type,
  streetProps = {},
  zipProps = {},
  townProps = {},
  provinceProps = {},
  countryProps = {},
  savedDirectionProps = {},
  markFavorite,
  fullWidth,
}) {
  const { t } = useTranslation(["common", "talk"]);
  const [favDirections, setFavDirections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const user = useUser();
  const { i18n } = useTranslation();
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();
  const addressIDRef = useRef(state?.address?.id);

  useEffect(() => {
    if (state?.address?.id) {
      addressIDRef.current = state?.address?.id;
    }
  }, [state?.address?.id]);

  useEffect(() => {
    if (uuidOrg) fetchData();
  }, [uuidOrg]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      //const countriesData = await getCountries(i18n.language);
      //setCountries(countriesData);
      const fn = await gqlWrapper(getFavAddresses, setDataUser, signOut);
      const response = await fn({
        locale: i18n.language,
        module: "locations",
        org: uuidOrg,
      });

      const res = response.getOrgLocations;
      let parseAddress = res.map((loc) => ({
        uuid: loc.id,
        place: loc.alias,
        type: loc.type,
        city: loc.city,
        state: loc.state,
        street: loc.street,
      }));

      if (type) {
        if (type === "purchase") {
          parseAddress = parseAddress.filter(
            (pa) => pa.type === "both" || pa.type === "collection"
          );
        } else if (type === "sale") {
          parseAddress = parseAddress.filter(
            (pa) => pa.type === "both" || pa.type === "delivery"
          );
        }
      }
      setFavDirections(parseAddress);
      if (parseAddress.length === 0 || !id) {
        markFavorite(null)();
      }
    } catch (err) {
      console.log(err);
      setFavDirections([]);
      markFavorite(null)();
    } finally {
      setIsLoading(false);
    }
  };

  const favAddressData = favDirections?.find(
    (f) => f.uuid === savedDirectionProps?.val
  );

  let extraStyles = {};
  if (fullWidth) {
    extraStyles = {
      maxWidth: "none",
    };
  }

  return state?.address?.id === null || state?.address?.id === undefined ? (
    <div className={`container`} style={{ padding: 0, ...extraStyles }}>
      <div className="row">
        <div className="col-12 col-md-7">
          <Label text={t("street-number")} style={{ paddingBottom: 0 }}>
            <InputText {...streetProps} />
          </Label>
        </div>
        <div className="col-12 col-md-5">
          <Label text={t("postal-code")} style={{ paddingBottom: 0 }}>
            <InputText {...zipProps} />
          </Label>
        </div>
        <div className="col-6">
          <Label text={t("town")} reduced={true} style={{ paddingBottom: 0 }}>
            <InputText {...townProps} />
          </Label>
        </div>
        <div className="col-6">
          <Label
            text={t("province")}
            reduced={true}
            style={{ paddingBottom: 0 }}
          >
            <InputText {...provinceProps} />
          </Label>
        </div>
        <div className="col-12">
          <Label
            text={t("country")}
            reduced={true}
            style={{ paddingBottom: 0 }}
          >
            <div>
              <InputCountries
                handleItemClick={(option, open, setOpen) => {
                  if (countryProps.onChangeCountry)
                    countryProps.onChangeCountry(option, open, setOpen);
                }}
                style={{ width: "100%" }}
                {...countryProps}
              />

              {isLoading ? (
                <Padding>
                  <Spinner />
                </Padding>
              ) : (
                <></>
              )}
            </div>
          </Label>
        </div>
        {!readOnly && (
          <div className="col-12">
            <button
              type="button"
              className={`${styles["sc-addresses-delivery-button"]} ${styles["sc-addresses-use-saved"]}`}
              onClick={markFavorite(addressIDRef.current)}
            >
              {t("stored-address")}
            </button>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="col-12">
      <div className={styles["sc-addresses"]}>
        <span style={{ width: "100%" }}>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Dropdown
                name="favourite_address"
                required={true}
                options={favDirections.map((fd) => ({
                  uuid: fd.uuid,
                  text: printAddress(fd), //`${fd.street} ${fd.state} ${fd.city} - ${fd.place}`,
                }))}
                value={
                  favAddressData
                    ? printAddress(favAddressData) //`${favAddressData?.street} ${favAddressData?.state} ${favAddressData?.city} - ${favAddressData?.place}`
                    : ""
                }
                handleItemClick={(option, open, setOpen) => {
                  if (savedDirectionProps.onChange)
                    savedDirectionProps.onChange(option, open, setOpen);
                }}
                {...savedDirectionProps}
              />
            </>
          )}
        </span>
      </div>
      {!readOnly && (
        <button
          type="button"
          className={styles["sc-addresses-delivery-button"]}
          onClick={markFavorite(null)}
        >
          {t("enter-other-address")}
        </button>
      )}
    </div>
  );
}

export default AddressForm;
