import { Alert, ClearButton, Flex, Icon } from "@scrapadev/scrapad-front-sdk";
import { useNavigate } from "react-router-dom";
import CardBox from "../../../components/ui/box/CardBox";
import ProgressBar from "../../../components/ui/progress/Bar";
import Text from "../../../components/ui/text/Text";
import { useTranslation } from "../../../utils/hooks";
import { WARNING_COLOR } from "../../../utils/variables";
import styles from "./Verification.module.scss";

/**
 * Verification widget for dashboard.
 * @param {Object} props - Component properties.
 * @param {String} props.id - Business id.
 * @param {Object} props.state - Data state.
 * @returns {JSX.Element}
 */
const WidgetBusinessVerification = ({ id, state }) => {
  const { t, tMultiple } = useTranslation(["common", "transactions"]);
  const navigate = useNavigate();
  const validation = state?.validation;
  const pending = validation
    ?.map((v) => v?.pendingDocs)
    ?.reduce((acc, current) => acc + current, 0);

  const parseText = (accepted, total) => {
    return tMultiple(
      accepted > 1 ? "accepted-documents" : "accepted-documents-plural",
      [accepted, total]
    );
  };

  const navigateTo = () => navigate(`/business/${id}/verification`);

  return (
    <CardBox
      title={t("verification")}
      action={t("see-more")}
      handleAction={navigateTo}
    >
      {pending && pending > 0 ? (
        <ClearButton onClick={navigateTo} style={{ width: "100%" }}>
          <Alert
            type="warning"
            onClick={navigateTo}
            style={{ marginBottom: "1.5rem" }}
            iconStyle={{ fontSize: "1.1rem" }}
          >
            <Flex justifyContent={"space-between"}>
              {tMultiple(
                state?.pending > 1
                  ? "pending-documents-plural"
                  : "pending-documents",
                [pending]
              )}
              <Icon
                name="navigate_next"
                color={WARNING_COLOR}
                style={{ fontSize: 16 }}
              />
            </Flex>
          </Alert>
        </ClearButton>
      ) : (
        <></>
      )}
      {validation?.map((v) => {
        return (
          <div className={styles["sc-verification"]}>
            <h3 className={styles["sc-verification--title"]}>
              {t(v?.verificationType)}
            </h3>
            <ProgressBar currentValue={v?.validatedDocs} total={v?.totalDocs} />
            <Text style={{ lineHeight: "1.1rem" }}>
              {parseText(v?.validatedDocs, v?.totalDocs)}
            </Text>
          </div>
        );
      })}
    </CardBox>
  );
};

export default WidgetBusinessVerification;
