import React from "react";

export const FlagMap = {
  EUR: <FlagEUR />,
  USD: <FlagUSD />,
};

export function FlagUSD() {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_69_1335)">
        <path d="M0 0.75H18V14.25H0" fill="#BD3D44" />
        <path
          d="M0 2.30518H18H0ZM0 4.37799H18H0ZM0 6.45924H18H0ZM0 8.54049H18H0ZM0 10.6217H18H0ZM0 12.703H18H0Z"
          fill="black"
        />
        <path
          d="M0 2.30518H18M0 4.37799H18M0 6.45924H18M0 8.54049H18M0 10.6217H18M0 12.703H18"
          stroke="white"
          stroke-width="1.04062"
        />
        <path d="M0 0.75H10.26V8.02031H0" fill="#192F5D" />
      </g>
      <defs>
        <clipPath id="clip0_69_1335">
          <rect
            width="18"
            height="13.5"
            fill="white"
            transform="translate(0 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function FlagEUR() {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_69_1352)">
        <path d="M0 0.75H18V14.25H0V0.75Z" fill="#003399" />
        <path
          d="M9.00005 2.89795L8.80005 3.56462L9.13338 3.63128L9.00005 2.89795Z"
          fill="#FFCC00"
        />
        <path
          d="M8.99995 2.89795L9.19995 3.56462L8.86662 3.63128L8.99995 2.89795Z"
          fill="#FFCC00"
        />
        <path
          d="M9.63407 3.35867L8.93823 3.37447L8.97783 3.71209L9.63407 3.35867Z"
          fill="#FFCC00"
        />
        <path
          d="M9.634 3.35862L9.06176 3.75484L8.89535 3.45842L9.634 3.35862Z"
          fill="#FFCC00"
        />
        <path
          d="M9.39191 4.10389L9.16185 3.44698L8.853 3.58898L9.39191 4.10389Z"
          fill="#FFCC00"
        />
        <path
          d="M9.39203 4.10412L8.83837 3.68234L9.06885 3.43247L9.39203 4.10412Z"
          fill="#FFCC00"
        />
        <path
          d="M8.36593 3.35867L9.06177 3.37447L9.02217 3.71209L8.36593 3.35867Z"
          fill="#FFCC00"
        />
        <path
          d="M8.366 3.35862L8.93824 3.75484L9.10465 3.45842L8.366 3.35862Z"
          fill="#FFCC00"
        />
        <path
          d="M8.60809 4.10389L8.83815 3.44698L9.147 3.58898L8.60809 4.10389Z"
          fill="#FFCC00"
        />
        <path
          d="M8.60797 4.10412L9.16163 3.68234L8.93115 3.43247L8.60797 4.10412Z"
          fill="#FFCC00"
        />
        <path
          d="M9.00005 10.8979L8.80005 11.5646L9.13338 11.6313L9.00005 10.8979Z"
          fill="#FFCC00"
        />
        <path
          d="M8.99995 10.8979L9.19995 11.5646L8.86662 11.6313L8.99995 10.8979Z"
          fill="#FFCC00"
        />
        <path
          d="M9.63407 11.3587L8.93823 11.3745L8.97783 11.7121L9.63407 11.3587Z"
          fill="#FFCC00"
        />
        <path
          d="M9.634 11.3586L9.06176 11.7548L8.89535 11.4584L9.634 11.3586Z"
          fill="#FFCC00"
        />
        <path
          d="M9.39191 12.1039L9.16185 11.447L8.853 11.589L9.39191 12.1039Z"
          fill="#FFCC00"
        />
        <path
          d="M9.39203 12.1041L8.83837 11.6823L9.06885 11.4325L9.39203 12.1041Z"
          fill="#FFCC00"
        />
        <path
          d="M8.36593 11.3587L9.06177 11.3745L9.02217 11.7121L8.36593 11.3587Z"
          fill="#FFCC00"
        />
        <path
          d="M8.366 11.3586L8.93824 11.7548L9.10465 11.4584L8.366 11.3586Z"
          fill="#FFCC00"
        />
        <path
          d="M8.60809 12.1039L8.83815 11.447L9.147 11.589L8.60809 12.1039Z"
          fill="#FFCC00"
        />
        <path
          d="M8.60797 12.1041L9.16163 11.6823L8.93115 11.4325L8.60797 12.1041Z"
          fill="#FFCC00"
        />
        <path
          d="M5.00005 6.89795L4.80005 7.56462L5.13338 7.63128L5.00005 6.89795Z"
          fill="#FFCC00"
        />
        <path
          d="M4.99995 6.89795L5.19995 7.56462L4.86662 7.63128L4.99995 6.89795Z"
          fill="#FFCC00"
        />
        <path
          d="M5.63407 7.35867L4.93823 7.37447L4.97783 7.71209L5.63407 7.35867Z"
          fill="#FFCC00"
        />
        <path
          d="M5.634 7.35862L5.06176 7.75484L4.89535 7.45842L5.634 7.35862Z"
          fill="#FFCC00"
        />
        <path
          d="M5.39191 8.10389L5.16185 7.44698L4.853 7.58898L5.39191 8.10389Z"
          fill="#FFCC00"
        />
        <path
          d="M5.39203 8.10412L4.83837 7.68234L5.06885 7.43247L5.39203 8.10412Z"
          fill="#FFCC00"
        />
        <path
          d="M4.36593 7.35867L5.06177 7.37447L5.02217 7.71209L4.36593 7.35867Z"
          fill="#FFCC00"
        />
        <path
          d="M4.366 7.35862L4.93824 7.75484L5.10465 7.45842L4.366 7.35862Z"
          fill="#FFCC00"
        />
        <path
          d="M4.60809 8.10389L4.83815 7.44698L5.147 7.58898L4.60809 8.10389Z"
          fill="#FFCC00"
        />
        <path
          d="M4.60797 8.10412L5.16163 7.68234L4.93115 7.43247L4.60797 8.10412Z"
          fill="#FFCC00"
        />
        <path
          d="M6.65729 4.67004L7.21095 4.24825L6.98046 3.99839L6.65729 4.67004Z"
          fill="#FFCC00"
        />
        <path
          d="M6.65741 4.6698L6.88746 4.0129L7.19632 4.1549L6.65741 4.6698Z"
          fill="#FFCC00"
        />
        <path
          d="M6.41507 3.92453L6.98731 4.32076L7.15372 4.02434L6.41507 3.92453Z"
          fill="#FFCC00"
        />
        <path
          d="M6.415 3.92459L7.11084 3.94039L7.07124 4.27801L6.415 3.92459Z"
          fill="#FFCC00"
        />
        <path
          d="M7.04912 3.46387L6.84912 4.13053L7.18245 4.1972L7.04912 3.46387Z"
          fill="#FFCC00"
        />
        <path
          d="M7.04902 3.46387L7.24902 4.13053L6.91569 4.1972L7.04902 3.46387Z"
          fill="#FFCC00"
        />
        <path
          d="M7.44086 4.67004L6.8872 4.24825L7.11768 3.99839L7.44086 4.67004Z"
          fill="#FFCC00"
        />
        <path
          d="M7.44074 4.6698L7.21068 4.0129L6.90182 4.1549L7.44074 4.6698Z"
          fill="#FFCC00"
        />
        <path
          d="M7.68307 3.92453L7.11084 4.32076L6.94443 4.02434L7.68307 3.92453Z"
          fill="#FFCC00"
        />
        <path
          d="M7.68314 3.92459L6.9873 3.94039L7.0269 4.27801L7.68314 3.92459Z"
          fill="#FFCC00"
        />
        <path
          d="M5.95807 6.1532L5.72802 5.4963L5.41916 5.63829L5.95807 6.1532Z"
          fill="#FFCC00"
        />
        <path
          d="M5.95819 6.15344L5.40453 5.73165L5.63502 5.48179L5.95819 6.15344Z"
          fill="#FFCC00"
        />
        <path
          d="M5.17438 6.15344L5.72804 5.73165L5.49755 5.48179L5.17438 6.15344Z"
          fill="#FFCC00"
        />
        <path
          d="M5.1745 6.1532L5.40455 5.4963L5.71341 5.63829L5.1745 6.1532Z"
          fill="#FFCC00"
        />
        <path
          d="M4.93216 5.40793L5.5044 5.80416L5.67081 5.50774L4.93216 5.40793Z"
          fill="#FFCC00"
        />
        <path
          d="M4.93209 5.40798L5.62793 5.42378L5.58833 5.7614L4.93209 5.40798Z"
          fill="#FFCC00"
        />
        <path
          d="M6.20016 5.40793L5.62793 5.80416L5.46152 5.50774L6.20016 5.40793Z"
          fill="#FFCC00"
        />
        <path
          d="M6.20023 5.40798L5.50439 5.42378L5.54399 5.7614L6.20023 5.40798Z"
          fill="#FFCC00"
        />
        <path
          d="M5.56611 4.94727L5.76611 5.61393L5.43278 5.6806L5.56611 4.94727Z"
          fill="#FFCC00"
        />
        <path
          d="M5.56621 4.94727L5.36621 5.61393L5.69954 5.6806L5.56621 4.94727Z"
          fill="#FFCC00"
        />
        <path
          d="M6.20096 9.41775L5.50512 9.43355L5.54472 9.77117L6.20096 9.41775Z"
          fill="#FFCC00"
        />
        <path
          d="M6.20089 9.4177L5.62866 9.81392L5.46225 9.5175L6.20089 9.4177Z"
          fill="#FFCC00"
        />
        <path
          d="M5.9588 10.163L5.72875 9.50607L5.41989 9.64806L5.9588 10.163Z"
          fill="#FFCC00"
        />
        <path
          d="M5.95892 10.1632L5.40526 9.74142L5.63575 9.49155L5.95892 10.1632Z"
          fill="#FFCC00"
        />
        <path
          d="M5.17511 10.1632L5.72877 9.74142L5.49828 9.49155L5.17511 10.1632Z"
          fill="#FFCC00"
        />
        <path
          d="M5.17523 10.163L5.40528 9.50607L5.71414 9.64806L5.17523 10.163Z"
          fill="#FFCC00"
        />
        <path
          d="M5.56685 8.95703L5.76685 9.6237L5.43351 9.69036L5.56685 8.95703Z"
          fill="#FFCC00"
        />
        <path
          d="M5.56694 8.95703L5.36694 9.6237L5.70028 9.69036L5.56694 8.95703Z"
          fill="#FFCC00"
        />
        <path
          d="M4.93283 9.41775L5.62867 9.43355L5.58906 9.77117L4.93283 9.41775Z"
          fill="#FFCC00"
        />
        <path
          d="M4.9329 9.4177L5.50513 9.81392L5.67154 9.5175L4.9329 9.4177Z"
          fill="#FFCC00"
        />
        <path
          d="M7.64799 10.7595L6.95214 10.7753L6.99175 11.113L7.64799 10.7595Z"
          fill="#FFCC00"
        />
        <path
          d="M7.64791 10.7595L7.07568 11.1557L6.90927 10.8593L7.64791 10.7595Z"
          fill="#FFCC00"
        />
        <path
          d="M7.40582 11.5048L7.17577 10.8479L6.86691 10.9899L7.40582 11.5048Z"
          fill="#FFCC00"
        />
        <path
          d="M7.40594 11.505L6.85228 11.0832L7.08277 10.8334L7.40594 11.505Z"
          fill="#FFCC00"
        />
        <path
          d="M6.62213 11.505L7.17579 11.0832L6.94531 10.8334L6.62213 11.505Z"
          fill="#FFCC00"
        />
        <path
          d="M6.62225 11.5048L6.85231 10.8479L7.16116 10.9899L6.62225 11.5048Z"
          fill="#FFCC00"
        />
        <path
          d="M7.01387 10.2988L7.21387 10.9655L6.88053 11.0322L7.01387 10.2988Z"
          fill="#FFCC00"
        />
        <path
          d="M7.01396 10.2988L6.81396 10.9655L7.1473 11.0322L7.01396 10.2988Z"
          fill="#FFCC00"
        />
        <path
          d="M6.37985 10.7595L7.07569 10.7753L7.03609 11.113L6.37985 10.7595Z"
          fill="#FFCC00"
        />
        <path
          d="M6.37992 10.7595L6.95215 11.1557L7.11856 10.8593L6.37992 10.7595Z"
          fill="#FFCC00"
        />
        <path
          d="M13 6.89795L13.2 7.56462L12.8666 7.63128L13 6.89795Z"
          fill="#FFCC00"
        />
        <path
          d="M13 6.89795L12.8 7.56462L13.1334 7.63128L13 6.89795Z"
          fill="#FFCC00"
        />
        <path
          d="M12.3659 7.35867L13.0618 7.37447L13.0222 7.71209L12.3659 7.35867Z"
          fill="#FFCC00"
        />
        <path
          d="M12.366 7.35862L12.9382 7.75484L13.1046 7.45842L12.366 7.35862Z"
          fill="#FFCC00"
        />
        <path
          d="M12.6081 8.10389L12.8381 7.44698L13.147 7.58898L12.6081 8.10389Z"
          fill="#FFCC00"
        />
        <path
          d="M12.608 8.10412L13.1616 7.68234L12.9311 7.43247L12.608 8.10412Z"
          fill="#FFCC00"
        />
        <path
          d="M13.6341 7.35867L12.9382 7.37447L12.9778 7.71209L13.6341 7.35867Z"
          fill="#FFCC00"
        />
        <path
          d="M13.634 7.35862L13.0618 7.75484L12.8954 7.45842L13.634 7.35862Z"
          fill="#FFCC00"
        />
        <path
          d="M13.3919 8.10389L13.1619 7.44698L12.853 7.58898L13.3919 8.10389Z"
          fill="#FFCC00"
        />
        <path
          d="M13.392 8.10412L12.8384 7.68234L13.0689 7.43247L13.392 8.10412Z"
          fill="#FFCC00"
        />
        <path
          d="M11.3427 4.67004L10.7891 4.24825L11.0195 3.99839L11.3427 4.67004Z"
          fill="#FFCC00"
        />
        <path
          d="M11.3426 4.6698L11.1125 4.0129L10.8037 4.1549L11.3426 4.6698Z"
          fill="#FFCC00"
        />
        <path
          d="M11.5849 3.92453L11.0127 4.32076L10.8463 4.02434L11.5849 3.92453Z"
          fill="#FFCC00"
        />
        <path
          d="M11.585 3.92459L10.8892 3.94039L10.9288 4.27801L11.585 3.92459Z"
          fill="#FFCC00"
        />
        <path
          d="M10.9509 3.46387L11.1509 4.13053L10.8175 4.1972L10.9509 3.46387Z"
          fill="#FFCC00"
        />
        <path
          d="M10.951 3.46387L10.751 4.13053L11.0843 4.1972L10.951 3.46387Z"
          fill="#FFCC00"
        />
        <path
          d="M10.5591 4.67004L11.1128 4.24825L10.8823 3.99839L10.5591 4.67004Z"
          fill="#FFCC00"
        />
        <path
          d="M10.5593 4.6698L10.7893 4.0129L11.0982 4.1549L10.5593 4.6698Z"
          fill="#FFCC00"
        />
        <path
          d="M10.3169 3.92453L10.8892 4.32076L11.0556 4.02434L10.3169 3.92453Z"
          fill="#FFCC00"
        />
        <path
          d="M10.3169 3.92459L11.0127 3.94039L10.9731 4.27801L10.3169 3.92459Z"
          fill="#FFCC00"
        />
        <path
          d="M12.0419 6.1532L12.272 5.4963L12.5808 5.63829L12.0419 6.1532Z"
          fill="#FFCC00"
        />
        <path
          d="M12.0418 6.15344L12.5955 5.73165L12.365 5.48179L12.0418 6.15344Z"
          fill="#FFCC00"
        />
        <path
          d="M12.8256 6.15344L12.272 5.73165L12.5024 5.48179L12.8256 6.15344Z"
          fill="#FFCC00"
        />
        <path
          d="M12.8255 6.1532L12.5954 5.4963L12.2866 5.63829L12.8255 6.1532Z"
          fill="#FFCC00"
        />
        <path
          d="M13.0678 5.40793L12.4956 5.80416L12.3292 5.50774L13.0678 5.40793Z"
          fill="#FFCC00"
        />
        <path
          d="M13.0679 5.40798L12.3721 5.42378L12.4117 5.7614L13.0679 5.40798Z"
          fill="#FFCC00"
        />
        <path
          d="M11.7998 5.40793L12.3721 5.80416L12.5385 5.50774L11.7998 5.40793Z"
          fill="#FFCC00"
        />
        <path
          d="M11.7998 5.40798L12.4956 5.42378L12.456 5.7614L11.7998 5.40798Z"
          fill="#FFCC00"
        />
        <path
          d="M12.4339 4.94727L12.2339 5.61393L12.5672 5.6806L12.4339 4.94727Z"
          fill="#FFCC00"
        />
        <path
          d="M12.4338 4.94727L12.6338 5.61393L12.3005 5.6806L12.4338 4.94727Z"
          fill="#FFCC00"
        />
        <path
          d="M11.799 9.41775L12.4949 9.43355L12.4553 9.77117L11.799 9.41775Z"
          fill="#FFCC00"
        />
        <path
          d="M11.7991 9.4177L12.3713 9.81392L12.5378 9.5175L11.7991 9.4177Z"
          fill="#FFCC00"
        />
        <path
          d="M12.0412 10.163L12.2713 9.50607L12.5801 9.64806L12.0412 10.163Z"
          fill="#FFCC00"
        />
        <path
          d="M12.0411 10.1632L12.5947 9.74142L12.3643 9.49155L12.0411 10.1632Z"
          fill="#FFCC00"
        />
        <path
          d="M12.8249 10.1632L12.2712 9.74142L12.5017 9.49155L12.8249 10.1632Z"
          fill="#FFCC00"
        />
        <path
          d="M12.8248 10.163L12.5947 9.50607L12.2859 9.64806L12.8248 10.163Z"
          fill="#FFCC00"
        />
        <path
          d="M12.4332 8.95703L12.2332 9.6237L12.5665 9.69036L12.4332 8.95703Z"
          fill="#FFCC00"
        />
        <path
          d="M12.4331 8.95703L12.6331 9.6237L12.2997 9.69036L12.4331 8.95703Z"
          fill="#FFCC00"
        />
        <path
          d="M13.0672 9.41775L12.3713 9.43355L12.4109 9.77117L13.0672 9.41775Z"
          fill="#FFCC00"
        />
        <path
          d="M13.0671 9.4177L12.4949 9.81392L12.3285 9.5175L13.0671 9.4177Z"
          fill="#FFCC00"
        />
        <path
          d="M10.352 10.7595L11.0479 10.7753L11.0083 11.113L10.352 10.7595Z"
          fill="#FFCC00"
        />
        <path
          d="M10.3521 10.7595L10.9243 11.1557L11.0907 10.8593L10.3521 10.7595Z"
          fill="#FFCC00"
        />
        <path
          d="M10.5942 11.5048L10.8242 10.8479L11.1331 10.9899L10.5942 11.5048Z"
          fill="#FFCC00"
        />
        <path
          d="M10.5941 11.505L11.1477 11.0832L10.9172 10.8334L10.5941 11.505Z"
          fill="#FFCC00"
        />
        <path
          d="M11.3779 11.505L10.8242 11.0832L11.0547 10.8334L11.3779 11.505Z"
          fill="#FFCC00"
        />
        <path
          d="M11.3777 11.5048L11.1477 10.8479L10.8388 10.9899L11.3777 11.5048Z"
          fill="#FFCC00"
        />
        <path
          d="M10.9861 10.2988L10.7861 10.9655L11.1195 11.0322L10.9861 10.2988Z"
          fill="#FFCC00"
        />
        <path
          d="M10.986 10.2988L11.186 10.9655L10.8527 11.0322L10.986 10.2988Z"
          fill="#FFCC00"
        />
        <path
          d="M11.6202 10.7595L10.9243 10.7753L10.9639 11.113L11.6202 10.7595Z"
          fill="#FFCC00"
        />
        <path
          d="M11.6201 10.7595L11.0478 11.1557L10.8814 10.8593L11.6201 10.7595Z"
          fill="#FFCC00"
        />
      </g>
      <defs>
        <clipPath id="clip0_69_1352">
          <rect
            width="18"
            height="13.5"
            fill="white"
            transform="translate(0 0.75)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
