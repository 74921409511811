import { Button, Flex } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";
import { modalTypes } from "../../../utils/variables";
import ModalFactory from "../../ui/modal/ModalFactory";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import { useEffect, useState } from "react";
import { handleCRUD } from "../../../utils/crud/functions";

const ModalAcceptOpportunityOffer = ({
  open,
  setOpen,
  opportunityId,
  uuid,
  amount,
  price,
  fetchData = () => {},
  ...props
}) => {
  const { t } = useTranslation(["common"]);
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const [loading, setLoading] = useState(false);
  const [amountData, setAmount] = useState(amount?.split("t")[0]);
  const [priceData, setPrice] = useState(price?.split("€")[0]);

  useEffect(() => {
    if (open) {
      handleSubmit();
    }
  }, [open]);

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setLoading(true);
    await handleCRUD({
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      external: true,
      data: {
        slug: "ACCEPT_OFFER_OPPORTUNITY",
        uuidUser: user?.userUuid,
        //uuidEvent: opportunityId,
        //uuidOfferOpportunity: uuid,
        uuidEvent: uuid,
        amount: amountData * 100,
        proposedPrice: priceData * 100,
      },
      successCallback: (e) => {
        fetchData();
        setLoading(false);
      },
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading: setLoading,
      errorCallback: () => {
        setLoading(false);
      },
    });
  };

  return (
    <ModalFactory
      {...props}
      open={open}
      setOpen={setOpen}
      loading={loading}
      type={modalTypes.USER_WARNING}
      title={t("offer_accepted")}
      text={t("offer_accepted_desc")}
      cancelText={t("cancel")}
      submitText={t("understood")}
      reverseActions={false}
      submitButtonMode={"info"}
      actionsComponent={
        <Flex
          flexDirection={"column"}
          alignItems={"flex-end"}
          style={{ width: "100%" }}
        >
          <Button
            text={t("understood")}
            component="button"
            mode="info"
            loading={loading}
            onClick={() => {
              setOpen(false);
            }}
            style={{
              paddingLeft: "50px",
              paddingRight: "50px",
            }}
          />
        </Flex>
      }
      onSubmit={() => {
        setOpen(false);
      }}
    />
  );
};

export default ModalAcceptOpportunityOffer;
