import { ClearButton, Icon } from "@scrapadev/scrapad-front-sdk";
import { useFiltersProvider } from "../../../providers/filters/FiltersContext";
import { useTranslation } from "../../../utils/hooks";

/**
 * Button for clear filters and pagination
 *
 * @param {Object} props - Component properties
 * @param {string} [props.removeText] - Text of the button.
 * @param {string} [props.clearAll] - Clear all filters override.
 * @returns {JSX.Element}
 */
const RestoreFilters = ({ removeText, clearAll }) => {
  const { t } = useTranslation("common");
  const {
    state: filters,
    dispatch,
    actions,
    cleanStoredFilters,
    checkForFilterSelected,
    filterChanged,
  } = useFiltersProvider();
  const hasFilters = checkForFilterSelected(filters);
  if (!hasFilters) return <></>;
  return (
    <ClearButton
      onClick={() => {
        if (clearAll) {
          clearAll();
        } else {
          filterChanged.current = true;
          dispatch({
            type: actions.CLEAR_FILTER,
          });
        }
        cleanStoredFilters();
        Object.keys(filters).forEach((key) => {
          const filter = filters[key];
          if (filter.clearAll) {
            filter.clearAll(filter);
          }
        });
      }}
      style={{ display: "flex", alignItems: "center" }}
    >
      <Icon
        name="close"
        color="#9E9E9E"
        style={{ marginRight: 12, fontSize: 18 }}
      />
      <span
        style={{
          color: "#9E9E9E",
          fontSize: "14px",
          lineHeight: "16px",
          textDecoration: "underline",
        }}
      >
        {removeText || t("restore-filters")}
      </span>
    </ClearButton>
  );
};

export default RestoreFilters;
