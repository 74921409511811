import React from "react";
import Input from "../Input";

/**
 * UI Email input component.
 * @param {Object} props - Component properties.
 * @param {Object} ref - Forwarded reference.
 * @returns {JSX.Element}
 */
const InputEmail = (props, ref) => {
  return <Input ref={ref} type="email" {...props} />;
};

export default React.forwardRef(InputEmail);
