import { useContext } from "react";
import { AuthContext } from "./AuthContext";

export function useAuth() {
  const { user, signIn, signOut, setDataUser, setFirebaseToken } =
    useContext(AuthContext);
  return { user, signIn, signOut, setDataUser, setFirebaseToken };
}

export function useUser() {
  const { user } = useContext(AuthContext);
  if (!user) {
    return null;
  }
  return user;
}

export function useSignIn() {
  return useContext(AuthContext).signIn;
}

export function useSignOut() {
  return useContext(AuthContext).signOut;
}

export function useSetDataUser() {
  return useContext(AuthContext).setDataUser;
}

export function useSetFirebaseToken() {
  return useContext(AuthContext).setFirebaseToken;
}
