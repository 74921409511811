import { ClearButton } from "@scrapadev/scrapad-front-sdk";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../../utils/hooks";
import styles from "./Steps.module.scss";

/**
 * UI Steps component.
 * @param {Object} props - Component properties.
 * @param {Array} props.steps - All the steps to show.
 * @param {Number} props.currentStep - Selected value.
 * @returns {JSX.Element}
 */
const Steps = ({
  steps,
  currentStep = 0,
  checkCurrentByLabel = false,
  minimal,
}) => {
  const { t } = useTranslation(["common", "transactions"]);
  const navigate = useNavigate();
  const navRef = useRef(null);

  const handleNavigate = (to) => (e) => {
    navigate(to);
  };

  if (!steps) return <></>;
  return (
    <nav
      ref={navRef}
      className={`${styles["sc-steps"]} ${minimal ? styles["minimal"] : ""}`}
    >
      <ul>
        {steps.map((step, index) => {
          return (
            <li
              key={index}
              className={
                checkCurrentByLabel
                  ? step.label === currentStep
                    ? styles["active"]
                    : ""
                  : index === currentStep
                  ? styles["active"]
                  : ""
              }
            >
              <ClearButton type="button" onClick={handleNavigate(step.to)}>
                {t(step.label)}
              </ClearButton>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Steps;
