export const costEvents = {
  LOGISTICS_COSTS_IMPUTED: "LOGISTICS_COSTS_IMPUTED",
  FINANCES_COSTS_IMPUTED: "FINANCES_COSTS_IMPUTED",
  PUT_TRANSACTION_COST: "PUT_TRANSACTION_COST",
};

export const opportunityEvents = {
  FINISH_OPPORTUNITY: "FINISH_OPPORTUNITY",
  READED_DETAILS: "READED_DETAILS",
  INIT_NEGOTIATION_FROM_OPPORTUNITY: "INIT_NEGOTIATION_FROM_OPPORTUNITY",
};

export const offerStatus = {
  ACCEPTED: "accepted",
  TRANSACTION_CREATED: "transaction-created",
  REJECTED: "rejected",
  PENDING: "pending",
  ACCEPTED_PENDING: "accepted-pending",
};
