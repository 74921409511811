import styles from "./TextLogo.module.scss";

/**
 * UI Text logo.
 */
const TextLogo = () => {
  return (
    <a href={"/"} className={styles["c-text-logo"]}>
      <span className={[styles["c-text-logo-blue"]]}>Scrap</span>
      <span className={[styles["c-text-logo-green"]]}>Back</span>
    </a>
  );
};

export default TextLogo;
