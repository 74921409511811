import { TableManager } from "@scrapadev/scrapad-front-sdk";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { usePaginationProvider } from "../../providers/pagination/PaginationContext";
import { useSortProvider } from "../../providers/sort/SortContext";
import { useTableDataProvider } from "../../providers/TableContext";
import { checkPermission } from "../../utils/functions";
import { useTranslation } from "../../utils/hooks";
import { applySortToFilters } from "../../utils/tables/functions";
import { useTableActions } from "../../utils/tables/hooks";
import { TABLE_LIMIT } from "../../utils/variables";

/**
 * Helper component to show table on all the opportunities list.
 * @param {object} props - Component properties.
 * @param {object} props.actions - Table actions.
 * @param {object} props.selectedRow - Useref to store selected row.
 * @param {object} props.baseTableParams - Base params of table.
 * @returns {JSX.Element}
 */
const WidgetOpportunitiesTable = ({
  actions,
  selectedRow,
  baseTableParams,
  ...props
}) => {
  const { t, i18n } = useTranslation(["common"], true);
  const { data, setData, loading, fetchData } = useTableDataProvider();
  const { handlePagination } = usePaginationProvider();
  const { buildFiltersAndFetch, getStoredFilters } = useFiltersProvider();
  const { state: sortState, handleSorting, buildSort } = useSortProvider();
  const { canReadFinances, canReadLogistics } = useOperationsProvider();
  const sortFields = buildSort(sortState);

  useTableActions({
    paginationCallback: (paginationState) => {
      const newFilters = buildFiltersAndFetch(getStoredFilters() || {});
      const params = {
        ...baseTableParams,
        sort: sortState?.field ? sortState : undefined,
        offset: paginationState?.current,
      };
      if (Object.keys(newFilters).length > 0) {
        params.filter = newFilters;
      }
      fetchData({
        ...params,
        filter: applySortToFilters(newFilters, sortFields),
      });
    },
  });

  return (
    <TableManager
      loading={loading}
      data={data}
      setData={setData}
      emptyText={t("empty-data")}
      pagination={
        data?.pagination
          ? {
              currentPage: Math.ceil(data?.pagination?.current / TABLE_LIMIT),
              totalPages: Math.ceil(data?.pagination?.total / TABLE_LIMIT),
              threshold: 3,
              onChangePage: handlePagination,
              onChangePrev: handlePagination,
              onChangeNext: handlePagination,
            }
          : undefined
      }
      sorting={buildSort(sortState)}
      onClickActionCallback={(uuid, index, row) => {
        selectedRow.current = row;
      }}
      externalSort={handleSorting}
      itemsProps={{
        notify: {
          translate: (value, colRef) => {
            if (typeof value === "string") {
              const splittedID = value.split("-");
              if (splittedID.length > 0) {
                return splittedID[0];
              }
              return value;
            }
            return value;
          },
          tooltip: (type) => {
            if (type === "pending") {
              return t("pending-costs");
            }
            return t("news");
          },
          permission:
            checkPermission(canReadFinances) ||
            checkPermission(canReadLogistics),
          text: {
            hideTooltip: true,
          },
        },
        date: {
          languageData: i18n.getLanguageVariable(i18n.language),
        },
      }}
      actions={actions}
      {...props}
    />
  );
};

export default WidgetOpportunitiesTable;
