import _ from "lodash";
import { orgStatus } from "../variables";

export const parseAdsDataToCSV = (ads) => {};

export const parseStatusToPublishPermission = (state, verificationState) => {
  if (state?.type === "sale" && verificationState !== orgStatus.KYC_COMPLETE) {
    return false;
  }
  if (
    verificationState !== orgStatus.ACTIVE &&
    verificationState !== orgStatus.KYC_COMPLETE
  ) {
    return false;
  }
  return true;
};

export const canNotPublishBasic = (verificationState) => {
  return (
    verificationState !== orgStatus.ACTIVE &&
    verificationState !== orgStatus.KYC_COMPLETE
  );
};

export const canNotPublishKyc = (state, verificationState) => {
  return state?.type === "sale" && verificationState !== orgStatus.KYC_COMPLETE;
};

export const parseAdStateToCheckIfDirty = (target) => {
  if (!target) return;
  if (target?.updatedadDate) delete target.updatedadDate;
  if (target?.title) delete target.title;
  if (target?.desc) delete target.desc;
  // Ahora en el formulario de direcciones el mostrar o no la dirección guardada
  // se comprueba si el address.id es nulo o indefinido por lo que en caso de que
  // el address.id sea una cadena vacia hay que eliminarla para comprobarla acorde
  // a la nueva validación
  if (target?.address?.id === "") {
    delete target.address.id;
  }
  target.lerCodes = target?.lerCodes?.map((f) => (f.name ? f.name : f));
};
