import React, { forwardRef } from "react";
import Icon from "../icon/Icon";
import Spinner from "../spinner/Spinner";
import styles from "./IconButton.module.scss";

function IconButton(
  {
    name,
    loading,
    children = <></>,
    onClick = () => {},
    style = {},
    color,
    iconStyle = {},
    ...props
  },
  ref
) {
  return (
    <button
      ref={ref}
      onClick={onClick}
      className={styles["c-icon-button"]}
      style={style}
      disabled={loading}
      {...props}
    >
      {loading ? (
        <Spinner color={"#FFF"} />
      ) : (
        <>
          {name ? <Icon name={name} color={color} style={iconStyle} /> : <></>}
          {children}
        </>
      )}
    </button>
  );
}

export default forwardRef(IconButton);
