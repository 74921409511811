import React from "react";
import styles from "./Item.module.scss";

/**
 * UI Item component
 * @param {Object} props - Component properties.
 * @param {String} props.text - Text to show in the left side.
 * @param {React.Component} props.rightComponent - Component to show in the right side.
 * @param {React.CSSProperties} [props.style={}] - Inline styles.
 * @param {React.CSSProperties} [props.contentStyle={}] - Content inline styles.
 * @returns {JSX.Element}
 */
const Item = ({
  text,
  rightComponent,
  style = {},
  contentStyle = {},
  children,
  alternativeStyle = false,
}) => {
  return (
    <div
      className={`${styles["sc-items--item"]} ${
        alternativeStyle && styles["alternative"]
      }`}
      style={style}
    >
      <div className={styles["sc-grey--block"]}>
        <p className={styles["sc-grey--block-left"]}>{text}</p>
        <span className={styles["sc-grey--block-right"]}>{rightComponent}</span>
      </div>
      <div className={styles["sc-items--item-content"]} style={contentStyle}>
        {children}
      </div>
    </div>
  );
};

export default Item;

