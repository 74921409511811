import React, { useState } from "react";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import { handleCRUD } from "../../../utils/crud/functions";
import { useTranslation } from "../../../utils/hooks";
import {
  BREAKPOINT_LG,
  Flex,
  Label,
  Modal,
  TextArea,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import ModalText from "../../ui/modal/text/ModalText";

function RequestInformation({ id, open, setOpen = () => {} }) {
  const { t } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setLoading(true);
    await handleCRUD({
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      external: true,
      data: {
        slug: "REQUEST_INFORMATION_OPPORTUNITY",
        uuidUser: user?.userUuid,
        uuidEvent: id,
        content: message,
      },
      successCallback: (e) => {
        setOpen(false);
        setLoading(false);
      },
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading: setLoading,
      errorCallback: () => {
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("opportunity_request_info_title")}
      hasForm={true}
      loading={loading}
      onSubmit={handleSubmit}
      submitText={t("send")}
      cancelText={t("cancel")}
      maxWidth={900}
      modalStyles={{ width: matches ? "auto" : 900 }}
      actionsMode={"end"}
      submitButtonMode="info"
      BodyComponent={
        <>
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            style={{ gap: "15px", width: "100%" }}
          >
            <ModalText text={t("opportunity_request_info_desc")} />
            <Label text={t("what_info_do_you_need")}>
              <TextArea
                required
                placeholder={t("write-here")}
                onChange={(e) => setMessage(e.target.value)}
                mode="underline"
              >
                {message}
              </TextArea>
            </Label>
          </Flex>
        </>
      }
    />
  );
}

export default RequestInformation;

