import {
  BREAKPOINT_LG,
  Container,
  Icon,
  Modal,
  toastError,
  toastSuccess,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import Label from "../../ui/input/label/Label";
import CatalogSearch from "../../ui/input/search/CatalogSearch";
import { postClient } from "../../../utils/restclient";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";

const ModalFinancesLink = ({ open, setOpen, ...props }) => {
  const { t } = useTranslation(["common", "finances"]);
  const { uuid, data } = props;
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const user = useUser();

  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const handleSubmit = async (e) => {
    const id_income = data?.body
      ?.filter((e) => e.uuid === uuid)[0]
      ?.cells?.filter((c) => c.colRef === "id_income")[0]?.value;

    try {
      await postClient({
        external: true,
        url: `${process.env.REACT_APP_API_EVENTS}`,
        method: "POST",
        body: {
          uuidEvent: value?.uuid,
          uuidUser: user.userUuid,
          slug: "LINK_PAYMENT",
          providerId: id_income,
        },
        signOut,
        setDataUser,
      });

      toastSuccess(
        t("action-ok"),
        { toastId: "wallet_actions_success" },
        "check_circle"
      );
      setOpen(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toastError("Error");
    } finally {
      setLoading(false);
    }
  };

  const handleReadOnly = () => {
    if (!props.permission) return false;
    return !props.permission();
  };

  const handleRequired = () => {
    if (!props.permission) return true;
    return props.permission();
  };

  const handleSubmitDisabled = () => {
    if (!props.permission) return value === null || value === "";
    if (value === null || value === "") return true;
    return !props.permission();
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t("link-income")}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("link")}
      cancelText={t("cancel")}
      submitDisabled={handleSubmitDisabled()}
      loading={loading}
      modalStyles={{
        width: matches ? "auto" : 650,
        overflow: "visible",
      }}
      BodyComponent={
        <Container style={{ margin: "0 -12px", width: "calc(100% + 12px)" }}>
          <div className="row">
            <div className="col-12">
              <Label>{t("transaction-id")}</Label>
              {/*<InputText
                placeholder={t("write-here")}
                required={handleRequired()}
                readOnly={handleReadOnly()}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />*/}
              <CatalogSearch
                inputProps={{
                  required: handleRequired(),
                  readOnly: handleReadOnly(),
                  position: "absolute",
                  leadingIcon: (
                    <Icon
                      name={"search"}
                      color={"#B1B1B1"}
                      style={{
                        position: "relative",
                        left: -10,
                        fontSize: 20,
                      }}
                    />
                  ),
                  trailingIcon: <></>,
                }}
                catalogName={"transaction-autocomplete"}
                onClickCallback={(option, open, setOpen) => {
                  setValue(option);
                }}
              />
            </div>
          </div>
        </Container>
      }
    />
  );
};

export default ModalFinancesLink;
