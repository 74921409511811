import {
  Accordion,
  Button,
  Center,
  Container,
  Empty,
  Flex,
  Heading,
  InputText,
  Label,
  Loading,
  Spacer,
} from "@scrapadev/scrapad-front-sdk";
import deepmerge from "deepmerge";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/ui/header/Header";
import TextArea from "../../components/ui/input/textarea/TextArea";
import Item from "../../components/ui/item/Item";
import NavBar from "../../components/ui/navbar/NavBar";
import LayoutDetail from "../../layout/detail/Detail";
import { getAdTranslations } from "../../model/ads";
import { useAdActions } from "../../utils/ads/hooks";
import { useGQL } from "../../utils/gql/hooks";
import { useTranslation } from "../../utils/hooks";

const TranslationItem = ({
  title,
  titleProps = {},
  description,
  descriptionProps = {},
}) => {
  return (
    <>
      <Flex justifyContent={"flex-start"} style={{ width: "100%" }}>
        <Label text="Título del anuncio" dark bold style={{ paddingBottom: 5 }}>
          <InputText value={title} {...titleProps} />
        </Label>
      </Flex>
      <Flex justifyContent={"flex-start"} style={{ width: "100%" }}>
        <Label text="Descripción" dark bold style={{ paddingBottom: 5 }}>
          <TextArea
            value={description}
            rows={5}
            {...descriptionProps}
          ></TextArea>
        </Label>
      </Flex>
    </>
  );
};

const AdsTranslation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "ads"]);
  const originalData = useRef({});
  const {
    data,
    setData,
    loading: dataLoading,
    fetchData,
  } = useGQL(getAdTranslations, { uuid: id });
  const location = useLocation();
  const [actionLoadig, setActionLoading] = useState(false);
  const { handleTranslationAction } = useAdActions();
  const original = data ? data?.find((f) => f?.code === "original") : {};
  const translations = data ? data?.filter((f) => f?.code !== "original") : [];

  useEffect(() => {
    if (data && Object.keys(originalData.current).length === 0) {
      originalData.current = data.map((d) => deepmerge({}, d));
    }
  }, [data]);

  const handleRestoreLanguage = (language) => () => {
    const newData = [...data];
    const index = data?.findIndex((f) => f?.code === language);
    newData[index] = { ...originalData.current[index] };
    setData(newData);
  };

  const handleUpdateLanguage = (language) => (e) => {
    e.preventDefault();
    const newData = data?.find((f) => f?.code === language);
    if (newData)
      handleTranslationAction({
        uuid: id,
        newData,
        fetchData: fetchData,
        setLoading: setActionLoading,
      });
  };

  const handleChangeItem = (language, key) => (e) => {
    const newData = [...data];
    const index = data?.findIndex((f) => f?.code === language);
    newData[index][key] = e.target.value;
    setData(newData);
  };

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header
                  title={t("ad-translations")}
                  goBack
                  breadcrumbs={[
                    {
                      description: t("ads"),
                      onClick: () => navigate(`/ads`, { replace: true }),
                    },
                    {
                      description: location?.state?.originalTitle,
                      onClick: () =>
                        navigate(`/ads/${location?.state?.id}`, {
                          replace: true,
                        }),
                    },
                    {
                      description: t("translations"),
                    },
                  ]}
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <Container>
            {dataLoading ? (
              <Center>
                <Loading />
              </Center>
            ) : (
              <div className="row">
                {!data ? (
                  <Center>
                    <Empty heading={t("empty-data")} />
                  </Center>
                ) : (
                  <>
                    <div className="col-12 col-lg-6">
                      <Heading type={5} style={{ marginBottom: 0 }}>
                        {t("original-text")}
                      </Heading>
                      {original?.code && (
                        <>
                          <Spacer height={28} />
                          <Item
                            text={t(original?.code)}
                            style={{ padding: 10 }}
                          >
                            <TranslationItem
                              title={original?.title}
                              description={original?.desc}
                              titleProps={{ readOnly: true }}
                              descriptionProps={{ readOnly: true }}
                            />
                          </Item>
                        </>
                      )}
                    </div>
                    <div className="col-12 col-lg-6">
                      <Heading type={5} style={{ marginBottom: 0 }}>
                        {t("translations")}
                      </Heading>
                      <Spacer height={38} />
                      {translations?.map((translation, index) => {
                        return (
                          <Accordion
                            title={t(translation?.code)}
                            defaultOpen={index === 0}
                          >
                            <form
                              onSubmit={handleUpdateLanguage(translation?.code)}
                            >
                              <Flex
                                flexDirection="column"
                                alignItems={"flex-start"}
                              >
                                <TranslationItem
                                  title={translation?.title}
                                  description={translation?.desc}
                                  readOnly={false}
                                  titleProps={{
                                    required: true,
                                    onChange: handleChangeItem(
                                      translation?.code,
                                      "title"
                                    ),
                                  }}
                                  descriptionProps={{
                                    required: true,
                                    onChange: handleChangeItem(
                                      translation?.code,
                                      "desc"
                                    ),
                                  }}
                                />
                                <Flex style={{ width: "100%", gap: 25 }}>
                                  <Button
                                    component="button"
                                    type="button"
                                    text={t("discard-changes")}
                                    onClick={handleRestoreLanguage(
                                      translation?.code
                                    )}
                                    mode={"normal"}
                                    fullwidth
                                  />
                                  <Button
                                    component="button"
                                    text={t("save-changes")}
                                    fullwidth
                                    loading={actionLoadig}
                                  />
                                </Flex>
                              </Flex>
                            </form>
                          </Accordion>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            )}
          </Container>
        }
      />
    </>
  );
};

export default AdsTranslation;
