import InputCountries from "../../components/ui/input/country/Country";
import Label from "../../components/ui/input/label/Label";
import InputText from "../../components/ui/input/text/Text";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import { renderDisabledComponentWithTooltip } from "../../utils/functions";
import { useTranslation } from "../../utils/hooks";
import { orgStatus } from "../../utils/variables";

/**
 * Helper component for location fields.
 * @param {Object} props - Component properties.
 * @param {string} props.streetValue - Value of street.
 * @param {string} props.postalValue - Value of postal code.
 * @param {string} props.townValue - Value of town.
 * @param {string} props.stateValue - Value of state.
 * @param {Function} props.handleOnChange - .
 * @param {string} [props.streetClassName="col-12 col-lg-9"] - Street classname.
 * @param {string} [props.postalClassName="col-12 col-lg-3"] - Postal code classname.
 * @param {string} [props.townClassName="col-12 col-lg-6"] - Town classname.
 * @param {string} [props.stateClassName="col-12 col-lg-6"] - State classname.
 * @param {Object} [props.streetProps={}] - Street classname.
 * @param {Object} [props.postalProps={}] - Postal code classname.
 * @param {Object} [props.townProps={}]- Town classname.
 * @param {Object} [props.stateProps={}] - State classname.
 * @returns {JSX.Element}
 */
const WidgetFieldLocation = ({
  streetValue,
  postalValue,
  townValue,
  stateValue,
  handleOnChange,
  streetClassName = "col-12 col-lg-9",
  postalClassName = "col-12 col-lg-3",
  townClassName = "col-12 col-lg-6",
  stateClassName = "col-12 col-lg-6",
  streetKey = "",
  postalKey = "",
  townKey = "",
  stateKey = "",
  streetProps = {},
  postalProps = {},
  townProps = {},
  stateProps = {},
  extraComponent = false,
  withTooltipReadOnly,
}) => {
  const { t } = useTranslation(["talk", "common", "business"]);
  const { state: headerState } = useHeaderProvider();

  const renderwithTooltip = (cmp) => {
    if (!headerState) return cmp;
    if (!withTooltipReadOnly) return cmp;
    return renderDisabledComponentWithTooltip(
      cmp,
      headerState?.verificationState === orgStatus.KYC_COMPLETE,
      t
    );
  };

  return (
    <>
      <div className="row">
        <div className={streetClassName}>
          <Label>
            {t("street-number")} {streetProps.required ? "*" : ""}
          </Label>
          {renderwithTooltip(
            <InputText
              value={streetValue}
              onChange={handleOnChange(streetKey || "street")}
              placeholder={t("write-here")}
              {...streetProps}
            />
          )}
        </div>
        <div className={postalClassName}>
          <Label>
            {t("postal-code")} {postalProps.required ? "*" : ""}
          </Label>
          {renderwithTooltip(
            <InputText
              value={postalValue}
              onChange={handleOnChange(postalKey || "postal")}
              placeholder={t("write-here")}
              {...postalProps}
            />
          )}
        </div>
      </div>
      <div className="row">
        <div className={townClassName}>
          <Label>
            {t("town")} {townProps.required ? "*" : ""}
          </Label>
          {renderwithTooltip(
            <InputText
              value={townValue}
              onChange={handleOnChange(townKey || "town")}
              placeholder={t("write-here")}
              {...townProps}
            />
          )}
        </div>
        <div className={stateClassName}>
          <Label>
            {t("state")} {stateProps.required ? "*" : ""}
          </Label>
          {renderwithTooltip(
            <InputText
              value={stateValue}
              onChange={handleOnChange(stateKey || "state")}
              placeholder={t("write-here")}
              {...stateProps}
            />
          )}
        </div>
        {extraComponent && extraComponent}
      </div>
    </>
  );
};

export default WidgetFieldLocation;
