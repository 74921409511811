import OpenFilters from "../filters/Open";
import { Flex, Icon } from "@scrapadev/scrapad-front-sdk/dist/index";
import Search from "../input/search/Search";
import Separator from "../separator/Separator";
import ExportTable from "../table/Export";
import styles from "./SearchFilterExport.module.scss";
import ExportData from "../export/Export";
import { useFiltersProvider } from "../../../providers/filters/FiltersContext";
import { useEffect, useState } from "react";
import GrowSearch from "../input/search/GrowSearch";

const SearchFilterExport = ({
  filterKey = "adText",
  hasSearch = true,
  hasExport = true,
  hasFilters = false,
  hasSeparator = true,
  extraComponents,
  extraComponentsStyle = {},
  open,
  setOpen,
  searchPlaceholder,
  searchOnChange,
  searchProps = {},
  exportData,
  exportProps,
  isLocalSearch = false,
  hasGrow = false,
  containerStyles = {},
}) => {
  const {
    state: filtersState,
    dispatch: filtersDispatch,
    actions: filtersActions,
    getStoredFilters,
    filterChanged,
  } = useFiltersProvider();
  const [searchValue, setSearchValue] = useState(
    getStoredFilters
      ? getStoredFilters()?.[filterKey]?.selected?.[0]?.name || ""
      : ""
  );

  useEffect(() => {
    if (filtersState?.[filterKey]?.selected?.length === 0) {
      setSearchValue("");
    }
  }, [filtersState]);

  const handleApplyFilter = () => {
    filterChanged.current = true;
    filtersDispatch({
      type: filtersActions.ADD_SINGLE_FILTER,
      payload: {
        filter: { slug: filterKey },
        item: { uuid: searchValue, name: searchValue },
      },
    });
  };
  return (
    <Flex
      justifyContent="flex-end"
      className={styles["sc-search--export"]}
      style={containerStyles}
    >
      {hasSearch &&
        (isLocalSearch ? (
          <Search
            leadingIcon={<Icon name={"search"} color={"#7E7E7E"} />}
            placeholder={searchPlaceholder}
            onChange={searchOnChange}
            isLocalSearch={isLocalSearch}
            {...searchProps}
          />
        ) : hasGrow ? (
          <GrowSearch
            placeholder={searchPlaceholder}
            onEnterCallback={(e) => {
              setTimeout(() => {
                handleApplyFilter();
              }, 100);
            }}
            onCancelSearch={() => {
              filterChanged.current = true;
              filtersDispatch({
                type: filtersActions.REMOVE_FILTER,
                payload: {
                  filter: { slug: filterKey },
                  item: { uuid: searchValue, name: searchValue },
                },
              });
            }}
            onApplySearch={() => handleApplyFilter()}
            initialValue={
              getStoredFilters
                ? getStoredFilters()?.[filterKey]?.selected?.[0]?.name || ""
                : ""
            }
            value={searchValue}
            controlled
            onChange={(e) => {
              const val = e.target.value;
              setSearchValue(val);
              //val.length > 0 ? setCanSetFilter(true) : setCanSetFilter(false);
            }}
            {...searchProps}
          />
        ) : (
          <Search
            placeholder={searchPlaceholder}
            onEnterCallback={(e) => {
              setTimeout(() => {
                handleApplyFilter();
              }, 100);
            }}
            onCancelSearch={() => {
              filterChanged.current = true;
              filtersDispatch({
                type: filtersActions.REMOVE_FILTER,
                payload: {
                  filter: { slug: filterKey },
                  item: { uuid: searchValue, name: searchValue },
                },
              });
            }}
            onApplySearch={() => handleApplyFilter()}
            initialValue={
              getStoredFilters
                ? getStoredFilters()?.[filterKey]?.selected?.[0]?.name || ""
                : ""
            }
            value={searchValue}
            controlled
            onChange={(e) => {
              const val = e.target.value;
              setSearchValue(val);
              //val.length > 0 ? setCanSetFilter(true) : setCanSetFilter(false);
            }}
            {...searchProps}
          />
        ))}
      {hasFilters || hasExport || hasSeparator ? (
        <Flex className={styles["sc-open--export"]} style={{ gap: 15 }}>
          {hasFilters && <OpenFilters open={open} setOpen={setOpen} />}
          {hasSeparator && <Separator color={"#b1b1b1"} />}
          {hasExport ? (
            exportProps ? (
              <ExportData {...exportProps} />
            ) : (
              <ExportTable data={exportData} />
            )
          ) : (
            <></>
          )}
          {extraComponents && (
            <div style={extraComponentsStyle}>{extraComponents}</div>
          )}
        </Flex>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default SearchFilterExport;
