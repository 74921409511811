import {
  BREAKPOINT_XL,
  Center,
  Container,
  Empty,
  Flex,
  Icon,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useNavigate } from "react-router-dom";
import CardBox from "../../../components/ui/box/CardBox";
import { useTranslation } from "../../../utils/hooks";
import { RED_COLOR_100 } from "../../../utils/variables";
import WidgetFavoriteAds from "./FavoriteAds";
import WidgetFavoriteMaterial from "./FavoriteMaterial";
import styles from "./Favorites.module.scss";
import WidgetFavouritesSearchs from "./FavoriteSearch";

/**
 * Favorites widget for dashboard.
 * @param {Object} props - Component properties.
 * @param {String} props.id - Business id.
 * @returns {JSX.Element}
 */
const WidgetBusinessFavorites = ({ id, state }) => {
  const { t } = useTranslation(["common", "transactions"]);
  const navigate = useNavigate();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_XL}px)`);
  const ads = state?.data?.[0]?.ads || [];
  const searchs = state?.data?.[1]?.searchs || [];
  const materials = state?.data?.[2]?.getFavouriteMaterials || [];
  const isEmpty = JSON.parse(
    process.env.REACT_APP_FAVORITES_SHOW_VIEW_ADS_STORED_SEARCHS
  )
    ? ads?.length === 0 && searchs?.length === 0 && materials?.length === 0
    : ads?.length === 0 && materials?.length === 0;

  const navigateTo = () => navigate(`/business/${id}/favourites`);

  if (state)
    return (
      <CardBox
        title={t("favorites")}
        action={t("see-more")}
        handleAction={navigateTo}
        hasOverflow={false}
      >
        {isEmpty ? (
          <Center>
            <Empty
              spacerHeight={10}
              description={t("empty-section")}
              icon="favorite"
              iconWidth={100}
              iconHeight={104}
            />
          </Center>
        ) : (
          <Container style={{ margin: "0 -12px" }}>
            <div className="row">
              <div
                className="col-12 col-lg-6 col-xl-6"
                style={{
                  marginBottom: matches ? 16 : 0,
                }}
              >
                <Flex
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  style={{
                    marginBottom: 16,
                  }}
                >
                  <Icon
                    name="favorite"
                    color={RED_COLOR_100}
                    style={{ fontSize: 16, marginRight: 10 }}
                  />
                  <h4 className={styles["sc-title"]}>{t("ads")}</h4>
                  <span>({ads?.length})</span>
                </Flex>
                <div style={{ maxHeight: 500, overflowY: "auto" }}>
                  <WidgetFavoriteAds ads={ads} />
                </div>
              </div>
              {JSON.parse(
                process.env.REACT_APP_FAVORITES_SHOW_VIEW_ADS_STORED_SEARCHS
              ) && (
                <div
                  className="col-12 col-lg-6 col-xl-4"
                  style={{ marginBottom: matches ? 16 : 0 }}
                >
                  <Flex
                    alignItems={"center"}
                    justifyContent={"flex-start"}
                    style={{ marginBottom: 16 }}
                  >
                    <Icon
                      name="favorite"
                      color={RED_COLOR_100}
                      style={{ fontSize: 16, marginRight: 10 }}
                    />
                    <h4 className={styles["sc-title"]}>{t("searchs")}</h4>
                    <span>(2)</span>
                  </Flex>
                  <WidgetFavouritesSearchs />
                </div>
              )}
              <div
                className="col-12 col-lg-6 col-xl-6"
                style={{ marginBottom: matches ? 16 : 0 }}
              >
                <Flex
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                  style={{ marginBottom: 16 }}
                >
                  <Icon
                    name="favorite"
                    color={RED_COLOR_100}
                    style={{ fontSize: 16, marginRight: 10 }}
                  />
                  <h4 className={styles["sc-title"]}>{t("materials")}</h4>
                  <span>({materials?.length})</span>
                </Flex>
                <div style={{ maxHeight: 500, overflowY: "auto" }}>
                  <WidgetFavoriteMaterial materials={materials} />
                </div>
              </div>
            </div>
          </Container>
        )}
      </CardBox>
    );
};

export default WidgetBusinessFavorites;
