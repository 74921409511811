import React from "react";
import OpportunityInfoRow from "./OpportunityInfoRow";
import { useTranslation } from "../../../utils/hooks";
import {
  Flex,
  Heading,
  ImageBox,
  Row,
  Spacer,
} from "@scrapadev/scrapad-front-sdk";
import _ from "lodash";

function OpportunityInfoData({ state = {} }) {
  const { t } = useTranslation(["common", "ads", "transactions"]);

  const getAddressName = (address = {}, hideStreet = false) => {
    let output = "";

    if (!_.isEmpty(address?.street) && !hideStreet) {
      output += `${address?.street}, `;
    }
    if (!_.isEmpty(address?.postalCode)) {
      output += `${address?.postalCode}, `;
    }
    if (!_.isEmpty(address?.city)) {
      output += `${address?.city}, `;
    }
    if (!_.isEmpty(address?.state)) {
      output += `${address?.state} `;
    }
    if (!_.isEmpty(address?.country)) {
      output += `${address?.country} `;
    }
    if (!_.isEmpty(address?.port?.name)) {
      output += `${address?.port?.name} `;
    }

    return output;
  };

  return (
    <Flex
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="stretch"
      style={{
        gap: "10px",
      }}
    >
      <OpportunityInfoRow label={t("material")} desc={state?.material?.name} />
      {!_.isEmpty(state?.paymentMethod?.name) && (
        <OpportunityInfoRow
          slug="opportunity-info-payment-method"
          label={t("payment-method")}
          desc={state?.paymentMethod?.name}
        />
      )}
      {!_.isEmpty(state?.incoterm?.name) && (
        <OpportunityInfoRow
          slug="opportunity-info-incoterm"
          label={t("incoterm")}
          desc={state?.incoterm?.name}
        />
      )}
      <OpportunityInfoRow
        slug="opportunity-info-address"
        label={t("address")}
        desc={getAddressName(state?.address)}
        //desc={`${state?.address?.street} ${state?.address?.state} ${state?.address?.postalCode} ${state?.address?.city} ${state?.address?.port?.name}`}
      />
      <Spacer height={10} />
      <Heading type={6} style={{ fontSize: "14px", margin: 0 }}>
        {t("specifications")}
      </Heading>
      <OpportunityInfoRow
        desc={`${state?.specifications}`}
        slug="opportunity-info-specifications"
      />
      <Row gap={15} slug={"opportunity-info-files"}>
        {state?.files?.map((file) => (
          <ImageBox
            url={file?.location}
            label={file?.name}
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(file?.location, "_blank", "noreferrer");
            }}
          />
        ))}
      </Row>
    </Flex>
  );
}

export default OpportunityInfoData;
