import React from "react";

function Icon({ name, color = "inherit", style = {} }) {
  const mergedStyle = {
    ...style,
    color: color,
  };
  return (
    <i className={`material-icons-outlined icon`} style={mergedStyle}>
      {name}
    </i>
  );
}

export default Icon;
