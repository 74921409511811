import { useState } from "react";
import InputNumber from "../../../components/ui/input/number/Number";
import { useTranslation } from "../../../utils/hooks";
import { buildRawWeight } from "../../forms/transaction/functions";
import { Modal } from "@scrapadev/scrapad-front-sdk/dist/index";
import styles from "./RawWeight.module.scss";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import { postClient } from "../../../utils/restclient";
import { handleFormOK } from "../../../utils/functions";
import { toastError } from "@scrapadev/scrapad-front-sdk";

/**
 * Modal for sending a response to a log message.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalRawWeight = ({ open = false, setOpen, ...props }) => {
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!props.uuidEvent) return;
    setLoading(true);
    const data = buildRawWeight(user.userUuid, props.uuidEvent, raw);
    try {
      await postClient({
        external: true,
        url: `${process.env.REACT_APP_API_EVENTS}`,
        method: "POST",
        body: data,
        signOut,
        setDataUser,
      });
      handleFormOK(t, props.fetchData);
    } catch (error) {
      toastError(error.message);
    } finally {
      setLoading(false);
      setOpen(false);
      setRaw("");
    }
  };
  const [raw, setRaw] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["transactions", "common"]);
  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("enter-net-weight")}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("send")}
      cancelText={t("cancel")}
      loading={loading}
      BodyComponent={
        <div className={styles["sc-transactions--raw"]}>
          <InputNumber
            placeholder={t("write-here")}
            required
            onChange={(e) => setRaw(e.target.valueAsNumber)}
            value={raw}
            trailingText={"t"}
          />
        </div>
      }
    />
  );
};

export default ModalRawWeight;
