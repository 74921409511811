import {
  BREAKPOINT_LG,
  Center,
  Chip,
  Container,
  DropdownIconActions,
  Flex,
  Loading,
  Typography,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import LayoutDetail from "../../layout/detail/Detail";
import WidgetDataWrapper from "../../widgets/wrappers/DataWrapper";
import { getAdOrgUuid, getEditAd } from "../../model/ads";
import { useTranslation } from "../../utils/hooks";
import {
  mapSlugToChipDoubleGrid,
  modalTypes,
  TEXT_COLOR_LIGHT,
} from "../../utils/variables";
import { useAdActions } from "../../utils/ads/hooks";
import Separator from "../../components/ui/separator/Separator";
import AdForm from "../../widgets/ads/Form";
import AdStats from "../../widgets/ads/AdStats";
import AdStatusAction from "../../widgets/ads/AdStatusAction";
import { useEffect } from "react";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import { useGQL } from "../../utils/gql/hooks";
import _ from "lodash";
import { parseDate } from "../../utils/date/functions";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import HistoryBlockWrapper from "../../wrappers/HistoryBlockWrapper";
import { parseAdStateToCheckIfDirty } from "../../utils/ads/functions";

const InnerAdsDetail = ({
  loading,
  setLoading,
  state,
  dispatch,
  actions,
  fetchData,
  extraData,
  isDirty,
  setIsDirty,
  bufferState,
  checkIfStateIsDirty,
  ...props
}) => {
  const { id } = useParams();
  const { t, i18n } = useTranslation(["common", "ads"]);
  const navigate = useNavigate();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const isMobile = matches;
  const {
    data: orgData,
    loading: orgLoading,
    error: orgError,
    fetchData: orgFetchData,
  } = useGQL(getAdOrgUuid, {}, true);
  const { canManageAds } = useOperationsProvider();
  const {
    renderActionsForEdit,
    renderActionsAsButtons,
    titleMessage,
    bodyMessage,
    okMessage,
    open,
    setOpen,
    type,
    handleEditAction,
    handleCloneAction,
    handleUnpublishAction,
    handlePublishAction,
    handleBlockAction,
    handleUnBlockAction,
    handleRestoreAction,
    handlePublishChanges,
    handleDemandCovered,
    handleDemandCoveredAction,
    handleRemovedAction,
    handleSaveDraft,
  } = useAdActions({
    transactions: state?.transactionsActive,
    ad: state,
    id,
    verificationState: orgData?.verificationState,
    permission: canManageAds,
  });

  useEffect(() => {
    if (state.uuidOrg && !_.isEmpty(state) && _.isEmpty(orgData)) {
      orgFetchData({
        locale: i18n.language,
        org: state.uuidOrg,
      });
    }
  }, [state]);

  const renderActionsEdit = renderActionsForEdit(
    state?.status,
    extraData?.isEdit
  );

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        isSticky
        headerStyle={{ marginBottom: 0 }}
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                {loading ? (
                  <Center>
                    <Loading />
                  </Center>
                ) : (
                  <Header
                    title={state?.originalTitle}
                    afterTitle={
                      <Chip
                        big
                        type={
                          state?.type === "sale" || state?.type === "sell"
                            ? "seller"
                            : "buyer"
                        }
                        text={t(state?.type)}
                        style={{ marginLeft: 20 }}
                      />
                    }
                    titleRightComponent={
                      orgLoading ? (
                        <Loading />
                      ) : (
                        <AdStatusAction
                          uuid={id}
                          fetchData={async () => {
                            setLoading(true);
                            await fetchData();
                            setLoading(false);
                          }}
                          ad={state}
                          verificationState={orgData?.verificationState}
                          status={state?.status}
                          isEdit={extraData?.isEdit}
                          handlePublishChanges={handlePublishChanges}
                          handleDemandCovered={handleDemandCovered}
                          handleSaveDraft={handleSaveDraft}
                          setOpen={setOpen}
                          bufferState={bufferState}
                        />
                      )
                    }
                    goBack
                    breadcrumbs={[
                      {
                        description: t("ads"),
                        onClick: () => navigate(`/ads`, { replace: true }),
                      },
                      {
                        description: state?.originalTitle,
                      },
                    ]}
                    subHeaderLeft={
                      <Container>
                        <Flex
                          justifyContent={"space-between"}
                          style={{ flexWrap: "wrap" }}
                        >
                          <Flex justifyContent={"flex-start"}>
                            <Flex style={{ marginRight: 20, gap: 12 }}>
                              <Chip
                                big
                                type={mapSlugToChipDoubleGrid[state?.status]}
                                text={t(state?.status)}
                              />
                              {state?.transactionsActive > 0 && (
                                <Chip
                                  type="info"
                                  text={state?.transactionsActive}
                                  showIcon
                                  icon="swap_horiz"
                                />
                              )}
                            </Flex>
                            {orgLoading ? (
                              <Loading />
                            ) : (
                              <Link
                                to={`/business/${state.uuidOrg}`}
                                style={{
                                  color: TEXT_COLOR_LIGHT,
                                  marginBottom: 0,
                                }}
                              >
                                <Typography
                                  light
                                  style={{
                                    color: TEXT_COLOR_LIGHT,
                                    marginBottom: 0,
                                  }}
                                >
                                  {orgData?.basicData?.orgName}
                                </Typography>
                              </Link>
                            )}
                            <Separator style={{ backgroundColor: "#9E9E9E" }} />
                            <AdStats
                              likes={state?.likes}
                              updatedAt={
                                state?.updatedadDate
                                  ? `${parseDate(
                                      state?.updatedadDate,
                                      i18n,
                                      "dd MMM yyyy"
                                    )}`
                                  : undefined
                              }
                              views={state?.views}
                              isMobile={isMobile}
                              adUuid={id}
                              ad={state}
                            />
                          </Flex>
                          <Flex
                            justifyContent={"flex-start"}
                            style={{ marginLeft: "auto" }}
                          >
                            {renderActionsAsButtons(
                              state,
                              extraData?.isEdit,
                              orgData?.verificationState
                            )}
                            {renderActionsEdit?.length > 0 && (
                              <DropdownIconActions items={renderActionsEdit} />
                            )}
                          </Flex>
                        </Flex>
                      </Container>
                    }
                    fullSubHeaderLeft
                    lowerLeftStyles={{ marginBottom: 20 }}
                  />
                )}
              </div>
            </div>
          </Container>
        }
        customRenderBody={(headerHeight, setHeaderHeight) => {
          return (
            <HistoryBlockWrapper
              mustBlockLeaving={isDirty}
              submitCallback={(modalOpen, setModalOpen) =>
                (e, loading, setLoading) => {
                  setModalOpen(false);
                }}
            >
              <Container style={{ marginTop: 20 }}>
                <AdForm
                  state={state}
                  dispatch={dispatch}
                  actions={actions}
                  fetchData={fetchData}
                  extraData={{}}
                  isEdit={extraData?.isEdit}
                  headerHeight={headerHeight}
                  setHeaderHeight={setHeaderHeight}
                  isDirty={isDirty}
                  verificationState={orgData?.verificationState}
                  {...props}
                />
              </Container>
            </HistoryBlockWrapper>
          );
        }}
      />
      <ModalFactory
        key={id}
        type={modalTypes.ADS_ACTIONS}
        open={open}
        setOpen={setOpen}
        titleMessage={titleMessage}
        bodyMessage={bodyMessage}
        okMessage={okMessage}
        adUuid={id}
        ad={state}
        isEdit={extraData?.isEdit}
        handleEditAction={handleEditAction}
        handleCloneAction={handleCloneAction}
        handleUnpublishAction={handleUnpublishAction}
        handlePublishAction={handlePublishAction}
        handleBlockAction={handleBlockAction}
        handleUnBlockAction={handleUnBlockAction}
        handleRestoreAction={handleRestoreAction}
        handleDemandCoveredAction={handleDemandCoveredAction}
        handleRemovedAction={handleRemovedAction}
        typeAd={type}
        fetchData={fetchData}
        fromDetail={true}
      />
    </>
  );
};

const AdsDetail = ({
  state,
  dispatch,
  actions,
  fetchData,
  extraData,
  isEdit,
  ...props
}) => {
  const { id } = useParams();
  const { i18n } = useTranslation(["common", "ads"]);
  return (
    <WidgetDataWrapper
      Component={InnerAdsDetail}
      fnData={getEditAd}
      fnParameters={{
        uuidAd: id,
        locale: i18n.language,
      }}
      extraData={{ isEdit }}
      customCompareFunction={(state, bufferState, checkIfStateIsDirty) => {
        const newState = _.cloneDeep(state);
        const newBuffer = _.cloneDeep(bufferState);
        parseAdStateToCheckIfDirty(newState);
        parseAdStateToCheckIfDirty(newBuffer);
        return checkIfStateIsDirty(newState, newBuffer);
      }}
    />
  );
};

export default AdsDetail;
