import IconButton from "../icon-button/IconButton";

/**
 * Export table a csv component.
 * @param {Object} props - Component properties.
 * @param {Boolean} [props.open=defaultOpen] - Tells if filter are open to show.
 * @returns {JSX.Element}
 */
const OpenFilters = ({ open, setOpen }) => {
  return (
    <IconButton
      name={"filter_list"}
      onClick={() => setOpen(!open)}
      style={{
        width: 45,
        height: 45,
        background: open ? "#7F85A6" : "#F5F5F5",
        border: "0.5px solid #E1E1E1",
        borderRadius: "50%",
        color: open ? "#FFF" : "#7E7E7E",
        margin: 0,
      }}
      iconStyle={{ fontSize: 18 }}
    />
  );
};

export default OpenFilters;
