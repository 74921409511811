/**
 * Helper function to apply sorting to filters when necessary.
 * @param {object} filters - FIlters currently applied.
 * @param {Objet} sorting - Sorting currently applied.
 * @param {String} flag - Feature flag to check.
 * @returns {Object}
 */
export const applySortToFilters = (filters, sorting, flag) => {
  if (!flag) {
    return {
      ...(filters || {}),
      sort: sorting.length > 0 ? sorting : undefined,
    };
  }
  if (!JSON.parse(process.env[flag])) return filters;
  if (sorting.length === 0 || !sorting) return filters;
  if (!filters && !sorting) return undefined;
  return {
    ...(filters || {}),
    sort: sorting,
  };
};
