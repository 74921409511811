import React, { useState } from "react";
import LoginWrap from "../../components/ui/login/LoginWrap";
import Header from "../../components/public/header/Header";
//import NavBar from "../../components/public/navbar/NavBar";
import { useTranslation } from "../../utils/hooks";
import { LoginForm } from "../../components/forms/login-form";
import { useNavigate } from "react-router-dom";
import { useAuth, useSetFirebaseToken } from "../../providers/AuthHooks";
import { firebaseCloudMessaging } from "../../utils/firebase";

/**
 * Login page
 * @returns {JSX.Element}
 */
const Login = () => {
  const { t } = useTranslation(["common", "login"]);
  const { signIn } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [sucessMessage, setSucessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();
  const setFirebaseToken = useSetFirebaseToken();
  const doLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage(null);
    const data = {
      email: event.target.email.value,
      password: event.target.password.value,
    };

    try {
      await signIn(data);
      const [firebaseApp, token] = await firebaseCloudMessaging.init();
      if (token) {
        await setFirebaseToken(token);
      }
      navigate("/", { replace: true });
    } catch (e) {
      setErrorMessage(t(e.code || "default-auth-error", { ns: "login" }));
    }

    setIsLoading(false);
  };
  return (
    <>
      {/*<NavBar />*/}
      <Header
        heading={t("login-heading")}
        subheading={t("login-subheading")}
        subheading2={t("login-subheading-2")}
      />
      <main>
        <LoginWrap
          title={t("login-title", { ns: "login" })}
          errorMessage={errorMessage}
          sucessMessage={sucessMessage}
        >
          <LoginForm doLogin={doLogin} isLoading={isLoading} />
        </LoginWrap>
      </main>
    </>
  );
};

export default Login;
