import Alert from "../../../components/ui/alert/Alert";
import CardBox from "../../../components/ui/box/CardBox";
import Container from "../../../components/ui/container/Container";
import { useTranslation } from "../../../utils/hooks";
import React from "react";
import { parseDate } from "../../../utils/date/functions";
import {
  BREAKPOINT_LG,
  CardButton,
  Center,
  Empty,
  Flex,
  Heading,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useNavigate } from "react-router-dom";
import WidgetWalletMovement from "../wallet/Movement";
import { getBalanceWithOperator } from "../../../utils/wallet/functions";
import { typeToWalletOrigin } from "../../../utils/wallet/variables";
import { useHeaderProvider } from "../../../providers/header/HeaderContext";
import { orgStatus } from "../../../utils/variables";

const NoWallet = ({ navigateTo, t }) => {
  return (
    <CardBox
      title={t("wallet")}
      action={t("see-more")}
      hasOverflow={false}
      handleAction={navigateTo}
    >
      <Container>
        <div className="row">
          <div className="col-12">
            <Center>
              <Empty
                spacerHeight={10}
                description={t("not-wallet-yet")}
                icon="wallet"
              />
            </Center>
          </div>
        </div>
      </Container>
    </CardBox>
  );
};

/**
 * Wallet widget for dashboard.
 * @param {Object} props - Component properties.
 * @param {String} props.id - Business id.
 * @param {Array} props.keys - Data state keys.
 * @param {Object} props.state - Data state.
 * @returns {JSX.Element}
 */
const WidgetBusinessWallet = ({ id, keys, state }) => {
  const { t, tMultiple, i18n } = useTranslation(["common", "wallet"]);
  const navigate = useNavigate();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const { state: headerState } = useHeaderProvider();

  const balanceText = state?.balance
    ? `${t("current-balance")}: ${getBalanceWithOperator(state?.balance)} €`
    : `${t("current-balance")}: 0 €`;

  const navigateTo = () => navigate(`/business/${id}/wallet`);

  if (headerState?.verificationState !== orgStatus.KYC_COMPLETE)
    return <NoWallet navigateTo={navigateTo} t={t} />;

  return (
    <CardBox
      title={t("wallet")}
      action={t("see-more")}
      hasOverflow={false}
      handleAction={navigateTo}
    >
      <Container>
        <div className="row">
          <div className="col-12">
            {state?.pending > 0 && (
              <Alert
                type="warning"
                onClick={navigateTo}
                style={{ marginBottom: "1.5rem" }}
                iconStyle={{ fontSize: "1.1rem" }}
              >
                {tMultiple(
                  state?.pending > 1
                    ? "pending-movement-plural"
                    : "pending-movement",
                  [state?.pending]
                )}
              </Alert>
            )}
          </div>
          <div
            className="col-12 col-lg-4"
            style={{ marginBottom: matches ? 30 : 0 }}
          >
            <Flex
              flexDirection={"column"}
              alignItems={"flex-start"}
              style={{ flexGrow: 1, gap: 15 }}
            >
              <CardButton
                type={2}
                text={balanceText}
                boxStyle={{ width: "100%" }}
                buttonStyle={{ cursor: "default" }}
              />
              <CardButton
                type={2}
                text={t("accounts")}
                boxStyle={{ width: "100%" }}
                onClick={() => navigate(`/business/${id}/wallet/accounts`)}
                hasArrow
              />
              {/*<CardButton
                type={2}
                text={t("cards")}
                boxStyle={{ width: "100%" }}
                onClick={() => {}}
                hasArrow
              />*/}
            </Flex>
          </div>
          <div className="col-12 col-lg-8">
            <div className="row">
              <div className="col-12">
                <Flex justifyContent={"space-between"}>
                  <Heading type={5} style={{ marginBottom: 16, fontSize: 14 }}>
                    {t("last-movements")}
                  </Heading>
                </Flex>
              </div>
            </div>
            {state?.transactions && state?.transactions.length > 0 ? (
              <div className="row">
                {state?.transactions?.map((transaction) => {
                  return (
                    <div className="col-12">
                      <WidgetWalletMovement
                        key={transaction?.uuid}
                        title={transaction?.type}
                        answer={`${parseDate(
                          transaction?.date * 1000,
                          i18n
                        )} - ${t(typeToWalletOrigin[transaction?.type])}`}
                        amount={transaction?.amount}
                        status={transaction?.status}
                        type={transaction?.type}
                        origin={typeToWalletOrigin[transaction?.type]}
                        hasActions={false}
                        infoStyles={{ border: "none", paddingBottom: 0 }}
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <Center>
                    <Empty
                      spacerHeight={10}
                      description={t("no-movements-yet")}
                      icon="wallet"
                    />
                  </Center>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </CardBox>
  );
};

export default WidgetBusinessWallet;
