import Filters from "../../../components/ui/filters/Filters";
import { useTranslation } from "../../../utils/hooks";
import {
  activityType,
  coverage,
  equipment,
  service,
} from "../../../utils/logistics/variables";

const TransportFilters = () => {
  const { t } = useTranslation(
    ["common", "transactions", "logistics", "ads"],
    true
  );

  const handleFilterLabel = (filter, key) => {
    const l = filter?.selected?.length;
    if (!l) return t(key);
    return `${t(key)} (${l})`;
  };

  return (
    <Filters
      type="white-outlined"
      showChips={false}
      restoreProps={{
        removeText: t("restore"),
      }}
      initialFilters={{
        type: {
          label: (filter) => handleFilterLabel(filter, "activity-type"),
          items: activityType.map((f) => ({
            uuid: f.uuid,
            name: t(f.text),
          })),
        },
        equipment: {
          label: (filter) => handleFilterLabel(filter, "equipment"),
          items: equipment.map((f) => ({
            uuid: f.uuid,
            name: t(f.text),
          })),
        },
        service: {
          label: (filter) => handleFilterLabel(filter, "service"),
          items: service.map((f) => ({
            uuid: f.uuid,
            name: t(f.text),
          })),
        },
        coverage: {
          label: (filter) => handleFilterLabel(filter, "coverage"),
          items: coverage.map((f) => ({
            uuid: f.uuid,
            name: t(f.text),
          })),
        },
      }}
    />
  );
};

export default TransportFilters;
