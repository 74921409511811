import React, { useState } from "react";
import styles from "./FloatingCard.module.scss";

function FloatingCard({ content = <></>, children }) {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={`${styles["floating_card"]} ${open && styles["open"]}`}
      onMouseLeave={() => {
        setOpen(false);
      }}
      onClick={() => {
        setOpen(true);
      }}
    >
      <div className={styles["floating_card__inner"]}>{content}</div>
      {children}
    </div>
  );
}

export default FloatingCard;

