import { Button, Flex } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";
import { modalTypes } from "../../../utils/variables";
import ModalFactory from "../../ui/modal/ModalFactory";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import { useEffect, useState } from "react";
import { handleCRUD } from "../../../utils/crud/functions";

const ModalRejectOpportunityOffer = ({
  open,
  setOpen,
  opportunityId,
  uuid,
  fetchData = () => {},
  ...props
}) => {
  const { t } = useTranslation(["common"]);
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setLoading(true);
    await handleCRUD({
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      external: true,
      data: {
        slug: "REJECT_OFFER_OPPORTUNITY",
        uuidUser: user?.userUuid,
        //uuidEvent: opportunityId,
        //uuidOfferOpportunity: uuid,
        uuidEvent: uuid,
      },
      successCallback: (e) => {
        fetchData();
        setLoading(false);
        setOpen(false);
      },
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading: () => {},
      errorCallback: () => {
        setLoading(false);
        setOpen(false);
      },
    });
  };

  return (
    <ModalFactory
      {...props}
      open={open}
      setOpen={setOpen}
      type={modalTypes.USER_WARNING}
      title={t("sure-reject-opportunity_offer")}
      text={t("cant-undo-action")}
      submitText={t("reject-offer")}
      cancelText={t("cancel")}
      onSubmit={handleSubmit}
      reverseActions
      submitButtonMode={"info"}
      loading={loading}
    />
  );
};

export default ModalRejectOpportunityOffer;
