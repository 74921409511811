import { Center, Empty, Loading } from "@scrapadev/scrapad-front-sdk";
import { useBusinessPreferencesPaymentMethods } from "../../../utils/business/hooks";
import { useTranslation } from "../../../utils/hooks";
import PreferencesBlockItem from "./Item";
import PreferencesPaymentMethodSwitch from "./Switch";

/**
 * Business preferences payment methods configuration. TG-1687
 * @param {object} props - Component properties.
 * @param {string} props.orgUuid - Organization uuid.
 * @returns {React.ReactNode}
 */
const PreferencesPaymentMethods = ({ orgUuid }) => {
  const { t, i18n } = useTranslation("common");
  const {
    loading,
    actionLoading,
    markPaymentMethodAsDefault,
    togglePaymentMethod,
    fetchData,
    data: paymentMethods,
  } = useBusinessPreferencesPaymentMethods(orgUuid);
  if (!orgUuid) {
    return <></>;
  }
  if (loading) {
    return (
      <Center>
        <Loading />
      </Center>
    );
  }
  if (!paymentMethods || paymentMethods.length === 0) {
    return (
      <Center>
        <Empty iconHeight={200} description={t("empty-data")} />
      </Center>
    );
  }
  return paymentMethods.map((method, index) => {
    const uuid = method?.uuidPaymentMethod || "";
    const text = method?.paymentMethodDesc || "";
    const selected = method?.active || false;
    const isDefaul = method?.fav || false;
    return (
      <PreferencesBlockItem
        key={uuid}
        uuid={uuid}
        text={text}
        selected={selected}
        isDefault={isDefaul}
        textLight={!selected}
        onMarkDefault={async () => {
          await markPaymentMethodAsDefault(uuid);
          fetchData();
        }}
        leading={
          <div style={{ pointerEvents: actionLoading ? "none" : "auto" }}>
            <PreferencesPaymentMethodSwitch
              checked={selected}
              isDefault={isDefaul}
              onChange={async (checked) => {
                await togglePaymentMethod(uuid, checked);
                fetchData();
              }}
            />
          </div>
        }
        index={index}
        size={paymentMethods.length}
        loading={actionLoading}
      />
    );
  });
};

export default PreferencesPaymentMethods;
