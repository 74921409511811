import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getTracking } from "../../model/logistics";
import { getTransactionLastRawEvent } from "../../model/transactions";
import { useUser } from "../../providers/AuthHooks";
import { useCRUD } from "../crud/hooks";
import { parseDate } from "../date/functions";
import { handleFormOK, isValidDate } from "../functions";
import { useGQL } from "../gql/hooks";
import { useTranslation } from "../hooks";
import { logActions, transportLoadUnloadStates } from "../variables";

/**
 * Custom hook to manage raw events in notebook.
 * @param {Object} props - Hook properties.
 * @param {String} props.uuidConversation - Conversation uuid.
 * @param {Object} props.slug - Event Slugs.
 * @param {Function} props.eventAdapter - Adapter for event data.
 * @param {Function} props.dispatch - State dispatcher.
 * @param {Function} props.actions - State actions.
 * @param {boolean} props.delegateLoading - Delegates useGQL loading state.
 * @returns {{ rawData: Object, rawLoading: boolean, rawSetLoading: (b: boolean) => boolean, eventData: Object, fetchData: Function }}
 */
export const useRawEventBO = ({
  uuidConversation,
  slug,
  eventAdapter,
  dispatch,
  actions,
  delegateLoading,
}) => {
  const {
    data: rawData,
    loading: rawLoading,
    setLoading: rawSetLoading,
    fetchData,
    fetchDataWithReturn,
  } = useGQL(
    getTransactionLastRawEvent,
    {
      uuidConversation,
      slug,
    },
    false,
    delegateLoading
  );
  const eventData = rawData?.event
    ? eventAdapter
      ? eventAdapter(JSON.parse(rawData?.event)?.EventData)
      : JSON.parse(rawData?.event)?.EventData
    : null;

  useEffect(() => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: eventData,
    });
  }, [rawData]);

  return {
    rawData,
    rawLoading,
    rawSetLoading,
    eventData,
    fetchData,
    fetchDataWithReturn,
  };
};

/**
 * Custom hook to manage edit values in the sidebar.
 * @returns {{ loading: boolean, setLoading: () => boolean, handleAction: (uuidEvent: string, value: string, fetchData: Function) => {}, userUuid:string }}
 */
export const useSideBarEdit = () => {
  const { CRUD } = useCRUD();
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["common"]);

  const handleAction = async (data, fetchData) => {
    setLoading(true);
    await CRUD({
      external: true,
      data,
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      successCallback: async () => {
        await fetchData();
        setLoading(false);
        handleFormOK(t, fetchData);
      },
    });
  };

  return {
    loading,
    setLoading,
    handleAction,
    userUuid: user.userUuid,
  };
};

export async function getDummyTracking() {
  const data = await Promise.resolve({
    data: {
      getTrackingForConversation: {
        entries: [
          {
            date: "Date",
            estimatedHours: [],
            kind: "load",
            state: transportLoadUnloadStates.SHIPMENT_CONFIRMED,
          },
          {
            date: "Date",
            estimatedHours: [],
            kind: "unload",
            state: transportLoadUnloadStates.CONFIRMED,
          },
        ],
      },
    },
  });
  return data.data.getTrackingForConversation;
}

/**
 * Custom hook to display tracking data.
 * @param {object} data - Transaction data.
 * @returns {{ id:string, data:{date: string, estimatedHours: string, kind:string, state: string}, loading: boolean, parseLandData: Function, parseSeaData: Function }}
 */
export const useTracking = (data = {}) => {
  const { id } = useParams();
  const { t, i18n } = useTranslation(["common", "logistics"]);
  const { data: trackingData, loading } = useGQL(getDummyTracking, {
    uuidConversation: id,
  });
  const phase = data?.sideData?.aboutTransaction?.[0]?.value;
  const entries = trackingData?.entries;
  const load = entries?.find((f) => f?.kind === "load");
  const transit = entries?.find((f) => f?.kind === "transit");
  const unload = entries?.find((f) => f?.kind === "unload");

  const parseLandData = () => {
    const pickUpData = {
      heading: t("picked-date-estimated"),
      label: "",
      status: "none",
    };
    const transitData = { status: "none" };
    const deliverData = { heading: t("delivery"), label: "", status: "none" };
    const noTrackingItems = [
      { ...pickUpData },
      { ...transitData },
      { ...deliverData },
    ];

    if (!trackingData) return noTrackingItems;

    if (
      phase?.toLowerCase() !== logActions.TRANSPORT ||
      Object.keys(load || {}).length === 0
    ) {
      return noTrackingItems;
    }
    if (load?.state === "TICKET") {
      pickUpData.status = "error";
      pickUpData.label = t("tracking-error");
    }
    if (load?.state === "COORDINATE") {
      pickUpData.label = `${
        isValidDate(load?.date) ? parseDate(load?.date, i18n) : ""
      } ${load?.estimatedHours ? load?.estimatedHours?.join(" - ") : ""}`;
    }
    if (transit) {
      pickUpData.heading = t("picked");
      pickUpData.status = "none";
      pickUpData.label = `${
        isValidDate(load?.date) ? parseDate(load?.date, i18n) : ""
      } ${load?.estimatedHours ? load?.estimatedHours?.join(" - ") : ""}`;
      transitData.status = "current";
      transitData.heading = t("in-transit");
      deliverData.heading = t("delivery-date-estimated");
      deliverData.label = `${
        isValidDate(unload?.date) ? parseDate(unload?.date, i18n) : ""
      }  ${unload?.estimatedHours ? unload?.estimatedHours?.join(" - ") : ""}`;
    }
    if (unload?.state === "TICKET") {
      deliverData.status = "error";
      deliverData.label = t("tracking-error");
    }
    if (unload?.state === "CONFIRMED") {
      pickUpData.status = "none";
      transitData.status = "none";
      transitData.heading = "";
      deliverData.status = "current";
      deliverData.heading = t("delivered");
      deliverData.label = `${
        isValidDate(unload?.date) ? parseDate(unload?.date, i18n) : ""
      }  ${unload?.estimatedHours ? unload?.estimatedHours?.join(" - ") : ""}`;
    }

    const trackingItems = [pickUpData, transitData, deliverData];

    return trackingItems;
  };

  const parseSeaData = () => {
    const pickUpData = {
      heading: t("pickup-estimated"),
      label: "",
      status: "none",
    };
    const shipmentData = { heading: t("shipment"), label: "", status: "none" };
    const arriveData = { heading: t("dock-arrive"), label: "", status: "none" };
    const deliverData = { heading: t("delivery"), label: "", status: "none" };
    const noTrackingItems = [
      { ...pickUpData },
      { ...shipmentData },
      { ...arriveData },
      { ...deliverData },
    ];

    if (!trackingData) return noTrackingItems;

    if (
      phase?.toLowerCase() !== logActions.TRANSPORT ||
      Object.keys(load || {}).length === 0
    ) {
      return noTrackingItems;
    }
    const trackingItems = [
      pickUpData,
      shipmentData,
      arriveData,
      arriveData,
      deliverData,
    ];

    if (load?.state === transportLoadUnloadStates.COORDINATE) {
      pickUpData.label = t("");
    }

    if (load?.state === transportLoadUnloadStates.CONFIRMED) {
      pickUpData.status = "first";
      pickUpData.heading = t("collection");
      pickUpData.label = t("");
    }

    if (load?.state === transportLoadUnloadStates.SHIPMENT_PENDING) {
      pickUpData.status = "first";
      pickUpData.heading = t("collection");
      pickUpData.label = t("");

      shipmentData.status = "next";
      shipmentData.heading = t("estimated-shipping");
      shipmentData.label = t("");
    }

    if (load?.state === transportLoadUnloadStates.SHIPMENT_CONFIRMED) {
      pickUpData.status = "visible";
      pickUpData.heading = t("collection");
      pickUpData.label = t("");

      shipmentData.status = "current";
      shipmentData.heading = t("shipment");
      shipmentData.label = t("");
    }

    if (unload?.state === transportLoadUnloadStates.ARRIVE_PENDING) {
      pickUpData.status = "visible";
      pickUpData.heading = t("collection");
      pickUpData.label = t("");

      shipmentData.status = "current";
      shipmentData.heading = t("shipment");
      shipmentData.label = t("");

      arriveData.status = "next";
      arriveData.heading = t("estimated-port-arrival");
      arriveData.label = t("");
    }

    if (unload?.state === transportLoadUnloadStates.ARRIVE_CONFIRMED) {
      pickUpData.status = "visible";
      pickUpData.heading = t("collection");
      pickUpData.label = t("");

      shipmentData.status = "visible";
      shipmentData.heading = t("shipment");
      shipmentData.label = t("");

      arriveData.status = "current";
      arriveData.heading = t("dock-arrive");
      arriveData.label = t("");
    }

    if (unload?.state === transportLoadUnloadStates.COORDINATE) {
      pickUpData.status = "visible";
      pickUpData.heading = t("collection");
      pickUpData.label = t("");

      shipmentData.status = "visible";
      shipmentData.heading = t("shipment");
      shipmentData.label = t("");

      arriveData.status = "current";
      arriveData.heading = t("dock-arrive");
      arriveData.label = t("");

      deliverData.status = "next";
      deliverData.heading = t("estimated-delivery");
      deliverData.label = t("");
    }

    if (unload?.state === transportLoadUnloadStates.CONFIRMED) {
      pickUpData.status = "visible";
      pickUpData.heading = t("collection");
      pickUpData.label = t("");

      shipmentData.status = "visible";
      shipmentData.heading = t("shipment");
      shipmentData.label = t("");

      arriveData.status = "visible";
      arriveData.heading = t("dock-arrive");
      arriveData.label = t("");

      deliverData.status = "current";
      deliverData.heading = t("delivery");
      deliverData.label = t("");
    }

    return trackingItems;
  };

  return {
    id,
    data,
    loading,
    parseLandData,
    parseSeaData,
  };
};
