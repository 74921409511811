import React from "react";
import styles from "./DocumentsBreadcrumbs.module.scss";
import { ClearButton, Icon, IconButton } from "@scrapadev/scrapad-front-sdk";

function DocumentsBreadcrumbs({ items, onBack }) {
  if (items === null || items.length === 0) return <></>;
  return (
    <div className={styles["sc-header--breadcrumbs"]}>
      {typeof onBack === "function" && (
        <IconButton name="arrow_back" onClick={onBack} />
      )}
      <ul>
        {items.map((item, index) => {
          let i = [];
          if (item.onClick) {
            i.push(
              <li key={`br_1_${index}`}>
                <ClearButton onClick={item.onClick}>
                  {item.description}
                </ClearButton>
              </li>
            );
          } else {
            i.push(<li>{item.description}</li>);
          }
          if (index < items.length - 1) {
            i.push(
              <li key={`br_2_${index}`}>
                <span className={styles["sc-header--arrow"]}>
                  <Icon name={"chevron_right"} color={"#7E7E7E"} />
                </span>
              </li>
            );
          }
          return i;
        })}
      </ul>
    </div>
  );
}

export default DocumentsBreadcrumbs;

