import { AutoComplete } from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { getMaterials } from "../../../model/ads";
import { useSignOut } from "../../../providers/AuthHooks";
import { actions } from "../../../providers/data/actions/data";
import { useDataProvider } from "../../../providers/data/DataContext";
import { useTranslation } from "../../../utils/hooks";
import { adsDefaults } from "../../../utils/variables";
import Loading from "../loading/Loading";
import ModalText from "../modal/text/ModalText";
import styles from "./Categories.module.scss";

const AdsMAterial = ({ ...props }) => {
  const { t, i18n } = useTranslation("common");
  const { state, dispatch } = useDataProvider();
  const [loading, setLoading] = useState(true);
  const signOut = useSignOut();

  useEffect(() => {
    if (state?.materials?.category?.uuidSelected) {
      setLoading(true);
      fetchData();
    }
  }, [state.materials.category.uuidSelected]);

  const fetchData = async () => {
    if (state?.materials?.category?.uuidSelected) {
      const results = await getMaterials(
        i18n.language,
        state?.materials?.category?.uuidSelected[0]
      );
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: {
          materials: {
            ...state.materials,
            material: {
              ...state.materials.material,
              options: results,
              open: true,
            },
          },
        },
      });
      setLoading(false);
    }
  };

  const handleChangeMaterials = (e) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        materials: {
          ...state.materials,
          material: {
            ...state.materials.material,
            values: [e.target.value],
          },
        },
      },
    });
  };

  const handleClickMaterials = (option) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        materials: {
          ...state.materials,
          type: {
            ...adsDefaults,
            open: true,
          },
          material: {
            ...state.materials.material,
            uuidSelected: [option.uuid],
            nameSelected: [option.name],
            values: [option.name],
          },
        },
      },
    });
  };

  return loading ? (
    <Loading />
  ) : state?.materials.material?.options ? (
    <div className={styles["sc-ads-material"]}>
      {state?.materials.material?.options && (
        <AutoComplete
          placeholder={t("select-one")}
          handleOnChange={handleChangeMaterials}
          model={{ uuid: "uuid", text: "name" }}
          value={state?.materials?.material?.values}
          options={state?.materials?.material?.options}
          handleItemClick={handleClickMaterials}
          position="fixed"
          {...props}
        />
      )}
    </div>
  ) : (
    <ModalText text={t("empty")} />
  );
};

export default AdsMAterial;
