import { actions } from "../../../providers/data/actions/data";
import { useDataProvider } from "../../../providers/data/DataContext";
import { useTranslation } from "../../../utils/hooks";
import WidgetAdOrSearchType from "../../../widgets/fields/Type";

const AdsAdType = () => {
  const { t } = useTranslation(["business"]);
  const { state, dispatch } = useDataProvider();

  const handleOnChecked = (key) => (e) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        materials: {
          ...state.materials,
          adType: {
            ...state.materials.adType,
            [key]: e.target.checked,
          },
        },
      },
    });
  };

  const handleValue = () => {
    return state?.materials?.adType?.purchase || state?.materials?.adType?.sale
      ? "1"
      : "";
  };

  return (
    <WidgetAdOrSearchType
      required={true}
      labelText={t("ad-type-interested")}
      isBuyChecked={state?.materials?.adType?.purchase}
      isSaleChecked={state?.materials?.adType?.sale}
      handleOnChecked={handleOnChecked}
      handleRequiredValue={handleValue}
      containerStyle={{ marginBottom: -30 }}
    />
  );
};

export default AdsAdType;
