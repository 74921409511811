import React, { useState } from "react";
import styles from "./OpportunityInfoHeader.module.scss";
import {
  Chip,
  Icon,
  IconButton,
  Row,
  ThumbnailHeader,
  TooltipSc,
  Typography,
} from "@scrapadev/scrapad-front-sdk";
import _ from "lodash";
import { useTranslation, useIsImageFromlist } from "../../../utils/hooks";
import Menu from "../../../components/ui/menu/Menu";
import MenuItem from "../../../components/ui/menu/MenuItem";
import { useNavigate } from "react-router-dom";
import ModalDeleteOpportunity from "../../../components/modals/opportunities/Delete";
import {
  currencySymbolMapWithWeight,
  modalTypes,
} from "../../../utils/variables";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import { useUser } from "../../../providers/AuthHooks";

function OpportunityInfoHeader({
  state = {},
  style = {},
  isOpen = false,
  id,
  onClick,
}) {
  const { t } = useTranslation(["common"]);
  const user = useUser();
  const navigate = useNavigate();
  const { imageIndex } = useIsImageFromlist(state?.files);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [openRequestInfo, setOpenRequestInfo] = useState(false);

  const onEdit = () => {
    navigate(`/opportunities/edit/${id}`);
  };

  const onStopAdmitOpportunities = () => {
    setOpenDeleteConfirmation(!openDeleteConfirmation);
  };

  return (
    <>
      <div className={`${styles["opportunity_header"]}`} style={style}>
        <ThumbnailHeader
          slug={"opportunity"}
          imageUrl={
            state?.files && imageIndex >= 0
              ? state?.files[imageIndex]?.location
              : ""
          }
          title={state?.title}
          description={`${state?.amount} ${t("to")} ${state?.price} ${
            currencySymbolMapWithWeight[state?.currency]
              ? currencySymbolMapWithWeight[state?.currency]
              : state?.currency
          }`}
          trailingHeading={
            <Row gap={7}>
              <Chip
                text={state?.opportunityTypeDesc}
                small={true}
                type="outlined"
                style={{
                  padding: "3px 10px",
                }}
              />
              {state?.canPublish === true && (
                <Chip
                  text={t("see_in_marketplace")}
                  small={true}
                  type="outlined"
                  style={{
                    padding: "3px 10px",
                    cursor: "pointer",
                  }}
                  handleClick={() => {
                    window.open(
                      `${process.env.REACT_APP_MARKETPLACE_URL}/ad/${id}`,
                      "_blank",
                      "noreferrer"
                    );
                  }}
                />
              )}
            </Row>
          }
          trailingTop={
            <>
              <Row
                align="flex-end"
                justify="flex-end"
                style={{ marginTop: "-10px" }}
              >
                {user?.userUuid !== state?.boUser?.uuid && (
                  <TooltipSc content={t("ask_for_aditional_info")}>
                    <IconButton
                      name="contact_support"
                      onClick={() => {
                        setOpenRequestInfo(true);
                      }}
                    />
                  </TooltipSc>
                )}

                {state?.uuidOrg && (
                  <Row
                    align="flex-end"
                    justify="flex-end"
                    style={{ marginTop: "-10px" }}
                  >
                    <Menu
                      selfManaged={true}
                      items={[
                        <MenuItem
                          key={`menu_item_edit_offer_${id}`}
                          onClick={onEdit}
                        >
                          <Typography small reduced style={{ margin: 0 }}>
                            {t("edit")}
                          </Typography>
                        </MenuItem>,
                        <MenuItem
                          key={`menu_item_stop_offer_${id}`}
                          onClick={onStopAdmitOpportunities}
                        >
                          <Typography
                            small
                            reduced
                            style={{ margin: 0, width: "170px" }}
                          >
                            {t("stop_admit_offers")}
                          </Typography>
                        </MenuItem>,
                      ]}
                    />
                  </Row>
                )}
              </Row>
            </>
          }
          trailingBottom={<ExpandToggler onClick={onClick} open={isOpen} />}
        />
      </div>
      <ModalDeleteOpportunity
        open={openDeleteConfirmation}
        setOpen={setOpenDeleteConfirmation}
        data={{
          key: id || "",
        }}
        successCallback={() => {
          navigate(`/opportunities`);
        }}
      />
      <ModalFactory
        open={openRequestInfo}
        setOpen={setOpenRequestInfo}
        type={modalTypes.OPPORTUNITY_REQUEST_INFORMATION}
        id={id}
      />
    </>
  );
}

function ExpandToggler({ onClick = () => {}, open = false }) {
  const { t } = useTranslation(["common"]);

  return (
    <div className={styles["toggle"]} onClick={onClick}>
      <span className={styles["label"]} data-cy="opportunity-info-showmore">
        {open ? t("see-less") : t("see-more")}
      </span>
      <Icon name={open ? "expand_less" : "expand_more"} color="#9E9E9E" />
    </div>
  );
}

export default OpportunityInfoHeader;
