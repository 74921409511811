import { AutoComplete } from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { getType } from "../../../model/ads";
import { useSignOut } from "../../../providers/AuthHooks";
import { actions } from "../../../providers/data/actions/data";
import { useDataProvider } from "../../../providers/data/DataContext";
import { useTranslation } from "../../../utils/hooks";
import Loading from "../loading/Loading";
import ModalText from "../modal/text/ModalText";
import styles from "./Type.module.scss";

const AdsType = ({ ...props }) => {
  const { t, i18n } = useTranslation(["common"]);
  const { state, dispatch } = useDataProvider();
  const [loading, setLoading] = useState(true);
  const signOut = useSignOut();

  useEffect(() => {
    if (state?.materials?.material?.uuidSelected) {
      setLoading(true);
      fetchData();
    }
  }, [state.materials.material.uuidSelected]);

  const fetchData = async () => {
    const results = await getType(
      i18n.language,
      state.materials.category.uuidSelected[0],
      state.materials.material.uuidSelected[0]
    );

    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        materials: {
          ...state.materials,
          type: {
            ...state.materials.type,
            options: results,
            open: true,
          },
        },
      },
    });
    setLoading(false);
  };

  const handleChangeType = (e) =>
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        materials: {
          ...state.materials,
          type: {
            ...state.materials.type,
            values: [e.target.value],
          },
        },
      },
    });

  const handleClickType = (option) => {
    const alreadySelected = state?.materials?.type?.uuidSelected.includes(
      option.uuid
    );
    if (alreadySelected) {
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: {
          materials: {
            ...state.materials,
            type: {
              ...state.materials.type,
              uuidSelected: state.materials.type.uuidSelected.filter(
                (f) => f !== option.uuid
              ),
              nameSelected: state.materials.type.nameSelected.filter(
                (f) => f !== option.name
              ),
              values: state.materials.type.values.filter(
                (f) => f !== option.name
              ),
            },
          },
        },
      });
    } else {
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: {
          materials: {
            ...state.materials,
            type: {
              ...state.materials.type,
              uuidSelected: [...state.materials.type.uuidSelected, option.uuid],
              nameSelected: [...state.materials.type.nameSelected, option.name],
              values: [...state.materials.type.values, option.name],
            },
          },
        },
      });
    }
  };

  const handleTypeIsChecked = (option) =>
    state?.materials?.type?.uuidSelected?.includes(option.uuid);

  return loading ? (
    <Loading />
  ) : state?.materials.type?.options ? (
    <div className={styles["sc-ads--type"]}>
      <AutoComplete
        multiple={true}
        placeholder={t("select-many")}
        handleOnChange={handleChangeType}
        model={{ uuid: "uuid", text: "name" }}
        value={state.materials.type.values}
        options={state.materials.type.options}
        handleItemClick={handleClickType}
        handleIsChecked={handleTypeIsChecked}
        position="fixed"
        {...props}
      />
    </div>
  ) : (
    <ModalText text={t("empty")} />
  );
};

export default AdsType;
