import { SearchAutoComplete } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";

/**
 * Catalog for equipment in land or sea transport.
 * @param {object} props - Component properties.
 * @param {Array<{uuid:string, name: string}>} props.value - Incoterm value.
 * @param {Function} [props.handleItemClick] - Item click callback.
 * @param {object} [props.autoCompleteProps={}] - Autocomplete override props.
 * @returns {React.ReactNode}
 */
const LandSeaTransportEquipment = ({
  handleItemClick,
  value,
  autoCompleteProps = {},
}) => {
  const { t, i18n } = useTranslation(["common"]);
  const options = [
    { uuid: "1c1d2e48-f22d-43f3-8a94-e9d9bce316f6", name: t("lona") },
    { uuid: "5c077d6f-4c4e-4b5c-b17d-f6f832a6e804", name: t("volquete") },
    { uuid: "a7a8d31f-98dc-4a92-a1c7-06be9d9d02a2", name: t("piso") },
    { uuid: "7b9e2be9-867d-423e-877c-8e09b7a627db", name: "20DV" },
    { uuid: "873541ea-6667-4142-8c1c-47bf8a7a36df", name: "40DV" },
    { uuid: "66c81769-29de-4105-9c54-cfad874b1b90", name: "40HC" },
    { uuid: "18ad6167-7383-41db-9381-e256e44ce2fd", name: "20OT" },
    { uuid: "9ed805a4-2404-439a-a048-4957ad943598", name: "40OT" },
  ];

  return (
    <SearchAutoComplete
      newMultipleItemFormat
      value={options.filter((f) => value.includes(f.uuid))?.map((f) => f.name)}
      options={options || []}
      inputProps={{ placeholder: t("write-or-select") }}
      placeholder={t("select-a-value")}
      model={{ uuid: "uuid", text: "name" }}
      containerStyles={{ width: "100%" }}
      handleIsChecked={(option) => {
        if (!value || !Array.isArray(value)) return false;
        return value.includes(option.uuid);
      }}
      handleItemClick={(option, open, setOpen, e) => {
        if (handleItemClick && typeof handleItemClick === "function") {
          handleItemClick(option, open, setOpen, e);
        }
      }}
      handleOnClickWithEvent={(e, value, open, setOpen) => {
        setOpen(!open);
      }}
      {...autoCompleteProps}
    />
  );
};

export default LandSeaTransportEquipment;
