import _ from "lodash";
import React from "react";
import styles from "./MaterialText.module.scss";

/** Hace un split por - */
function MaterialText({ text }) {
  if (_.isEmpty(text)) {
    return <></>;
  }

  const textSplitted = text.split("-");

  return (
    <div className={styles["material-text"]}>
      <span className={styles["main"]}>{textSplitted[0]}</span>
      {!_.isEmpty(textSplitted[1]) && (
        <>
          - <span className={styles["sub"]}>{textSplitted[1]}</span>
        </>
      )}
      <span className={styles["sub"]}></span>
    </div>
  );
}

export default MaterialText;
