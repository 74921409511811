import React, { useDeferredValue, useEffect, useRef, useState } from "react";
import styles from "./SearchBar.module.scss";
import { useAutoComplete, useTranslation } from "../../../utils/hooks";
import { Heading, Icon } from "@scrapadev/scrapad-front-sdk";
import SearchInput from "./SearchInput";
import { useUser } from "../../../providers/AuthHooks";
import _ from "lodash";

const SearchBar = ({
  value,
  handleOnChange,
  disabled,
  readOnly,
  disableEnterText,
  mode = "outline",
  position = "absolute",
  sideComplements,
  onClearSearch,
  handleOnSubmit,
  placeholder,
  ...props
}) => {
  const { t, i18n } = useTranslation("common");
  const user = useUser();
  const deferredValue = useDeferredValue(value);
  const { nodeRef, containerRef, resultsCallbackRef, open, setOpen } =
    useAutoComplete(position, mode === "rounded" ? 9 : 0);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState({});
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState({});
  const autocompleteThrottled = useRef(null);

  useEffect(() => {
    document
      .querySelector("body")
      .addEventListener("click", handleBodyClick, false);
    return () => {
      document
        .querySelector("body")
        .removeEventListener("click", handleBodyClick);
    };
  }, []);

  useEffect(() => {
    if (open) {
      loadSuggestions();
    }
  }, [open]);

  useEffect(() => {
    if (autocompleteThrottled.current) {
      autocompleteThrottled.current(value);
    } else {
      autocompleteThrottled.current = _.throttle(
        (value) => loadAutocompleteSuggestions(value),
        1000
      );
      loadAutocompleteSuggestions(value);
    }
  }, [value]);

  /**
   * Get Autocomplete Suggestions
   */
  const loadAutocompleteSuggestions = async (term) => {};

  /**
   * Get Suggestions
   */
  const loadSuggestions = async () => {};

  /**
   * Handles open and close the results, form example when clicking in the trailing arrow.
   */
  const handleOnClick = () => {
    setOpen(!open);
  };

  /**
   * Handles change event.
   * @param {React.SyntheticEvent} e - Event.
   */
  const handleChange = (e) => {
    if (disableEnterText) return;

    if (handleOnChange) {
      handleOnChange(e, open, setOpen, loading, setLoading);
    }
    if (e.target.value.length === 0) {
      handleSubmit(e);
    }
  };

  /**
   * Handles closing the results when the body is clicked.
   * @param {React.SyntheticEvent} e - Event.
   */
  const handleBodyClick = (e) => {
    const isClickInsideAutoComplete = e.target.closest(".c-searchbar-ref");
    if (!isClickInsideAutoComplete) {
      setOpen(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleOnSubmit) {
      handleOnSubmit(e, open, setOpen, loading, setLoading);
    }
    // if (handleOnChange) {
    //   handleOnChange(e, open, setOpen, loading, setLoading);
    // }
    // setOpen(false);
  };

  const restoreSearch = (val) => {
    if (handleOnChange) {
      handleOnSubmit(
        {
          target: {
            value: val,
          },
          preventDefault: () => {},
        },
        open,
        setOpen,
        loading,
        setLoading
      );
    }
  };

  const restoreFilter = (filter, materialType) => {};

  const handleFacet = (facet, facetName) => {};

  const handleFacetAmounts = (amount) => {};

  return (
    <div
      ref={containerRef}
      className={`c-searchbar-ref ${styles["c-searchbar"]}`}
    >
      <SearchInput
        ref={nodeRef}
        onChange={handleChange}
        value={deferredValue}
        // value={
        //   Array.isArray(deferredValue)
        //     ? deferredValue.join(", ")
        //     : deferredValue
        // }
        disabled={disabled}
        readOnly={readOnly}
        mode={mode}
        rightSideComponents={sideComplements}
        onClearSearch={() => {
          setAutocompleteSuggestions({});
          onClearSearch();
        }}
        searchBarOpen={open}
        handleOnClick={handleOnClick}
        onKeyDown={(e) => {
          if (e?.key === "Enter") {
            e.preventDefault();
            handleSubmit(e);
          }
        }}
        placeholder={placeholder}
        {...props}
      />

      {open && (
        <div
          className={`${styles["c-searchbar__results"]} c-searchbar__results-ref`}
          ref={resultsCallbackRef}
          style={{
            position,
          }}
        >
          {loading && (
            <ul>
              <li>{t("loading")}</li>
            </ul>
          )}
          {!loading && _.isEmpty(autocompleteSuggestions) && (
            <>
              {(suggestions?.prevSearch || []).length > 0 && (
                <SearchBarBlock title={t("your-last-searchs")}>
                  <RecentSearchList>
                    {suggestions?.prevSearch?.map((ps, i) =>
                      _.isEmpty(ps.term) ? (
                        <></>
                      ) : (
                        <RecentSearchItem
                          key={`res_item_${ps.term}_${i}`}
                          title={ps.term}
                          onClick={() => restoreSearch(ps.term)}
                        />
                      )
                    )}
                  </RecentSearchList>
                </SearchBarBlock>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

function SearchBarBlock({ title, children }) {
  return (
    <div className={`${styles["search-bar-block"]}`}>
      {title && (
        <Heading
          type={6}
          style={{
            fontSize: "12px",
            color: "#777986",
            margin: 0,
            textTransform: "uppercase",
          }}
          weight="medium"
        >
          {title}
        </Heading>
      )}
      {children}
    </div>
  );
}

function RecentSearchList({ children }) {
  return <div className={`${styles["recent-search-list"]}`}>{children}</div>;
}

function RecentSearchItem({ title, onClick }) {
  return (
    <div onClick={onClick} className={`${styles["recent-search-list-item"]}`}>
      <Icon name="update" color="#B3B5BD" />
      <span>{title}</span>
    </div>
  );
}

export default React.forwardRef(SearchBar);
