import { Container } from "@scrapadev/scrapad-front-sdk";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import Steps from "../../components/ui/steps/Steps";
import LayoutDetail from "../../layout/detail/Detail";
import { getWallet, getHeaderInfo } from "../../model/business";
import { getOrgWalletMovements } from "../../model/wallet";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import { useSteps, useTranslation } from "../../utils/hooks";
import WidgetWalletBalance from "../../widgets/business/wallet/Balance";
import WidgetDataWrapper from "../../widgets/wrappers/DataWrapper";

const BusinessWallet = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation(["common", "transactions", "users"]);
  const { state } = useHeaderProvider(i18n.language, id, getHeaderInfo);
  const { steps } = useSteps(id);
  const navigate = useNavigate();
  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header
                  title={state?.basicData?.orgName}
                  goBack
                  breadcrumbs={[
                    {
                      description: t("business"),
                      onClick: () => navigate("/business", { replace: true }),
                    },
                    {
                      description: state?.basicData?.orgName,
                      onClick: () =>
                        navigate(`/business/${id}`, { replace: true }),
                    },
                    { description: t("wallet") },
                  ]}
                  subHeaderLeft={
                    <Steps
                      steps={steps}
                      currentStep={"wallet"}
                      checkCurrentByLabel
                    />
                  }
                  lowerLeftStyles={{ marginBottom: 0 }}
                  fullSubHeaderLeft
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <WidgetDataWrapper
            id={id}
            fnData={getOrgWalletMovements}
            fnParameters={{
              org: id,
            }}
            Component={WidgetWalletBalance}
          />
        }
      />
    </>
  );
};

export default BusinessWallet;
