import React from "react";
import styles from "./OpportunityInfoRow.module.scss";

function OpportunityInfoRow({ label, desc, slug }) {
  return (
    <div className={styles["opportunity_info_row"]}>
      {label && <span className={styles["label"]}>{label}</span>}
      {desc && (
        <span className={styles["desc"]} data-cy={slug || undefined}>
          {desc}
        </span>
      )}
    </div>
  );
}

export default OpportunityInfoRow;
