import Menu from "./Menu";
import Container from "../container/Container";
import styles from "./NavBar.module.scss";
import { useMatchMedia } from "../../../utils/hooks";
import { BREAKPOINT_LG } from "../../../utils/variables";
import React, { useEffect, useRef, useState } from "react";
import Info from "./Info";
import LanguageSelector from "../language/LanguageSelector";
import logo from "./../../../assets/img/main-private-logo.svg";

/**
 * Private NavBar component.
 * @param {Object} props - Component properties.
 * @param {String} props.mobileTitle - Title in mobile.
 * @param {React.Component} props.GoBackComponent - Component to go back.
 * @returns {JSX.Element}
 */
const NavBar = ({ mobileTitle, GoBackComponent }) => {
  const matches = useMatchMedia(`(min-width: ${BREAKPOINT_LG}px)`);
  const [openMenu, setOpenMenu] = useState(false);
  const [hasNotifications, setHasNotificatopns] = useState(false);
  const navRef = useRef(null);
  const handleMenuMobile = () => {
    setOpenMenu(!openMenu);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    if (openMenu) {
      body.style.overflow = "hidden";
      //navRef.current.style.position = "static";
    } else {
      body.style.overflow = "";
      //navRef.current.style.position = "";
    }
  }, [openMenu]);

  const checkOpenMenuClass = () => (openMenu ? styles["open"] : "");

  const renderToggler = () => {
    return (
      <div className={styles["c-navbar__toggle"]}>
        <button type="button" onClick={handleMenuMobile}>
          <i
            className={`${styles["c-navbar__info-icon"]} large material-icons-outlined icon`}
          >
            {openMenu ? "close" : "menu"}
          </i>
        </button>
      </div>
    );
  };

  return (
    <nav ref={navRef} className={styles["c-navbar"]}>
      <Container isFluid>
        {matches ? (
          <div className={styles["c-navbar__container"]}>
            <Menu />
          </div>
        ) : (
          <div
            className={`${
              styles["c-navbar__container-mobile"]
            } ${checkOpenMenuClass()}`}
          >
            <div className={styles["c-navbar__mobile--header"]}>
              <a href={"/"} className={styles["c-navbar__mobile--logo"]}>
                <img src={logo} alt="ScrapAd" />
              </a>
              <a
                href={`/notifications`}
                className={`${styles["c-navbar__mobile--notifications"]}`}
              >
                <i className={`large material-icons-outlined icon`}>
                  notifications
                </i>
                <span
                  className={
                    hasNotifications
                      ? styles["c-navbar__mobile--notifications-pending"]
                      : ""
                  }
                ></span>
              </a>
              {openMenu && renderToggler()}
            </div>
            <div className={styles["c-navbar__mobile--menu"]}>
              <Menu withLogo={false} />
            </div>
            <div className={styles["c-navbar__mobile--info"]}>
              <Info showNotifications={false} showBusinnes={false} />
              <div className={styles["c-navbar__mobile--language"]}>
                <LanguageSelector />
              </div>
            </div>
          </div>
        )}
        <div className={styles["c-navbar__shrink-container"]}>
          {mobileTitle && (
            <h1 className={styles["c-navbar__shrink-title"]}>{mobileTitle}</h1>
          )}
          {GoBackComponent && (
            <div className={styles["c-navbar__shrink-back"]}>
              {<GoBackComponent />}
            </div>
          )}
          {!openMenu && renderToggler()}
        </div>
      </Container>
    </nav>
  );
};

export default NavBar;
