import { Container, Empty, Spacer } from "@scrapadev/scrapad-front-sdk";
import Kanban from "../../components/ui/kanban/column/Kanban";
import WidgetDataWrapper from "../../widgets/wrappers/DataWrapper";
import { getLogisticTracking } from "../../model/logistics";
import _ from "lodash";
import { useTranslation } from "../../utils/hooks";
import { useUser } from "../../providers/AuthHooks";
import WebsocketsWrapper from "../../widgets/websockets/Wrapper";
import { WS_ROOM, WS_SLUGS } from "../../utils/websockets/functions";

const InnerLogisticsTracking = ({
  state,
  loading,
  fetchData = async () => {},
}) => {
  const user = useUser();

  if (!loading && _.isEmpty(state?.logisticTracking)) {
    return (
      <>
        <Spacer height={25} />
        <Empty />
        {user && (
          <WebsocketsWrapper
            callback={async (data) => {
              if (
                !_.isEmpty(data) &&
                data?.subscribeRoom?.type === WS_ROOM.transaction &&
                data?.subscribeRoom?.slug !== WS_SLUGS.ACCESS_TRANSACTION
              ) {
                await fetchData();
              }
            }}
            room={WS_ROOM.transaction}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Container isFluid>
        <Spacer height={25} />
        <Kanban data={state?.logisticTracking} onUpdate={() => fetchData()} />
      </Container>
      {user && (
        <WebsocketsWrapper
          callback={async (data) => {
            if (
              !_.isEmpty(data) &&
              data?.subscribeRoom?.type === WS_ROOM.transaction &&
              data?.subscribeRoom?.slug !== WS_SLUGS.ACCESS_TRANSACTION
            ) {
              await fetchData();
            }
          }}
          room={WS_ROOM.transaction}
        />
      )}
    </>
  );
};

const LogisticsTracking = () => {
  const { i18n } = useTranslation(["common"]);
  return (
    <WidgetDataWrapper
      fnData={getLogisticTracking}
      fnParameters={{
        locale: i18n.language,
      }}
      Component={InnerLogisticsTracking}
    />
  );
};

export default LogisticsTracking;
