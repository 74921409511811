import { getApps, initializeApp } from "firebase/app";
import localforage from "localforage";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseCloudMessaging = {
  init: async () => {
    if (getApps().length < 1) {
      // Initialize the Firebase app with the credentials
      const firebaseApp = initializeApp({
        apiKey: "AIzaSyBKucwbXVYwL5r6YbpCnv_e2PrvUipMEVY",
        authDomain: "infinite-chain-340015.firebaseapp.com",
        projectId: "infinite-chain-340015",
        storageBucket: "infinite-chain-340015.appspot.com",
        messagingSenderId: "387339624056",
        appId: "1:387339624056:web:6de8179bf98b7644a3578d",
      });

      try {
        const tokenInLocalForage = await localforage.getItem("fcm_token");

        // Return the token if it is alredy in our local storage
        if (tokenInLocalForage !== null) {
          return [firebaseApp, tokenInLocalForage];
        }

        const registration = await navigator.serviceWorker.register(
          `/firebase-messaging-sw.js`
        );

        const status = await Notification.requestPermission();

        if (status && status === "granted") {
          const messaging = getMessaging(firebaseApp);

          // Get new token from Firebase
          const fcm_token = await getToken(messaging, {
            serviceWorkerRegistration: registration,
            vapidKey:
              "BDAXZwMmZHey18qU08WDR1JfCzp1GdoeQOzlcBtD9P0LXWwQiioB1rL_e9F0ad1_aJMWhd6zaQeSqPBZ_EUw6Rc",
          });

          // Set token in our local storage
          if (fcm_token) {
            localforage.setItem("fcm_token", fcm_token);
            return [firebaseApp, fcm_token];
          }
        }
      } catch (error) {
        return [null, null];
      }
    } else {
      const fapps = getApps();
      const tokenInLocalForage = await localforage.getItem("fcm_token");
      return [fapps[0], tokenInLocalForage];
    }
  },
  getApp: async () => {
    const appFirebaseList = getApps();
    if (appFirebaseList.length > 0) {
      return appFirebaseList[0];
    }
    return null;
  },
};
export { firebaseCloudMessaging };
