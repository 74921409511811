import { Chip, Flex, Typography } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";
import { TEXT_COLOR_LIGHT } from "../../utils/variables";
import styles from "./Item.module.scss";

const AdItem = ({
  leftComponent,
  title,
  subTitle,
  subTitleComponent,
  description,
  isSell,
  isBuy,
  ads,
  containerStyles = {},
  titleContainerStyles = {},
  subtitleContainerStyles = {},
  titleStyles = {},
  subTitleStyles = {},
  descriptionStyles = {},
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <Flex
      alignItems={"flex-start"}
      style={{ width: "100%", gap: 15, ...containerStyles }}
    >
      {leftComponent && leftComponent}
      <Flex
        style={{ width: "100%", ...titleContainerStyles }}
        alignItems={"flex-start"}
        flexDirection="column"
      >
        {title && (
          <h5
            className={styles["sc-title"]}
            style={titleStyles}
            data-cy="ad-item-title"
          >
            {title}
          </h5>
        )}
        {description && (
          <Typography light style={{ marginBottom: 12, ...descriptionStyles }}>
            <span data-cy="ad-item-description">{description}</span>
          </Typography>
        )}
        {subTitleComponent ? (
          subTitleComponent
        ) : (
          <Flex
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            style={{ width: "100%", ...subtitleContainerStyles }}
          >
            {subTitle && (
              <Typography
                light
                style={{
                  color: TEXT_COLOR_LIGHT,
                  marginBottom: 0,
                  ...subTitleStyles,
                }}
              >
                <span data-cy="ad-item-subtitle">{subTitle}</span>
              </Typography>
            )}
            {(isBuy || isSell) && (
              <Flex style={{ gap: 10 }}>
                {isBuy && <Chip text={t("buy")} />}
                {isSell && <Chip text={t("sell")} />}
              </Flex>
            )}
            {ads && ads}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default AdItem;
