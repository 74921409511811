import { useTranslation } from "../../../utils/hooks";
import {
  Alert,
  Modal,
  Typography,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import { banOrUnBanOrganization } from "../../../utils/functions";
import { orgStatus } from "../../../utils/variables";

/**
 * Modal to warn users of blocking an orgaanization
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalBlockOrganizationWarning = ({ open = false, setOpen, ...props }) => {
  const { id, fetchData, loading, setLoading } = props;
  const { t } = useTranslation(["users", "common"]);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setOpen(false);
    banOrUnBanOrganization({
      id,
      setLoading,
      fetchData,
      status: orgStatus.BANNED,
      setDataUser,
      signOut,
    });
  };
  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("block-business")}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("block")}
      cancelText={t("cancel")}
      loading={loading}
      maxWidth={700}
      reverseActions
      BodyComponent={
        <div>
          <Alert type="warning">{t("block-org-info")}</Alert>
        </div>
      }
    />
  );
};

export default ModalBlockOrganizationWarning;
