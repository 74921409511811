import { Modal } from "@scrapadev/scrapad-front-sdk";
import { INFO_COLOR } from "../../../utils/variables";

/**
 * Modal with blue L&F.
 * @param {props} props - Modal properties.
 * @returns {JSX.Element}
 */
const BlueModal = ({ ...props }) => {
  return (
    <Modal
      {...props}
      modalStyles={{
        padding: "30px 25px",
        ...(props?.modalStyles || {}),
      }}
      titleStyles={{
        fontSize: 17,
        ...(props?.titleStyles || {}),
      }}
      cancelStyles={{
        border: "1px solid #E1E1E1",
        backgroundColor: "#F5F5F5",
      }}
      submitStyles={
        props?.reverseActions
          ? { color: INFO_COLOR, borderColor: INFO_COLOR }
          : { backgroundColor: INFO_COLOR }
      }
    />
  );
};

export default BlueModal;
