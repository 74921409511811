import Switch from "../../components/ui/switch/Switch";

const ModeSwitch = ({ defaultActive, setModeOnTable, setModeOnGrid }) => {
  return (
    <Switch
      defaultActive={defaultActive}
      items={[
        {
          icon: "grid_view",
          label: "Galería",
          onClick: setModeOnGrid,
        },
        {
          icon: "format_list_bulleted",
          label: "Tabla",
          onClick: setModeOnTable,
        },
      ]}
    />
  );
};

export default ModeSwitch;
