import { useLayoutEffect, useRef, useState } from "react";
import { TEXT_COLOR_LIGHT } from "../../../utils/variables";
import Icon from "../icon/Icon";
import styles from "./Switch.module.scss";

/**
 * UI Switch component
 * @param {Object} props - Component properties.
 * @param {Array} [props.items=[]] - Switch items.
 * @returns {JSX.Element}
 */
const Switch = ({ items = [], defaultActive = 0 }) => {
  const [activeIndex, setActiveIndex] = useState(defaultActive);
  const containerRef = useRef(null);
  const shadowRef = useRef(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      shadowRef.current.style.transform = "";
      const list = containerRef.current.querySelector("ul");
      const items = list.querySelectorAll("li");
      activeIndex === 0
        ? (shadowRef.current.style.left = "0px")
        : (shadowRef.current.style.left = `calc(100% - ${shadowRef.current.offsetWidth}px)`);
    }
  }, [activeIndex]);

  const handleItemClick = (callback, currentIndex) => (e) => {
    setActiveIndex(currentIndex);
    if (callback) callback(currentIndex);
  };

  return (
    items.length > 0 && (
      <div className={styles["sc-switch"]} ref={containerRef}>
        <ul>
          {items.map((item, index) => (
            <li
              key={index}
              className={activeIndex === index ? styles["active"] : undefined}
              onClick={handleItemClick(item.onClick, index)}
              style={{ cursor: item.onClick ? "pointer" : "default" }}
            >
              {item.icon && (
                <Icon
                  name={item.icon}
                  color={activeIndex === index ? "white" : TEXT_COLOR_LIGHT}
                />
              )}
              <span
                style={{
                  color: activeIndex === index ? "white" : TEXT_COLOR_LIGHT,
                }}
              >
                {item.label}
              </span>
            </li>
          ))}
        </ul>
        <span ref={shadowRef} className={styles["sc-switch--shadow"]}></span>
      </div>
    )
  );
};

export default Switch;
