import { Flex, AutoComplete, ClearButton } from "@scrapadev/scrapad-front-sdk";
import deepmerge from "deepmerge";
import TimeDropdown from "../../../components/ui/input/time/TimeDropdown";
import Label from "../../../components/ui/input/label/Label";
import { actions } from "../../../providers/data/actions/data";
import { capitalizeFirstLetter, getWeekDays } from "../../../utils/functions";
import { useTranslation } from "../../../utils/hooks";
import styles from "./Warehouse.module.scss";

/**
 * Helper component for address warehouse.
 * @param {Object} props - Component properties.
 * @param {Object} props.address - Address info.
 * @param {number} props.index - Index of current address.
 * @param {Object} props.state - Address state.
 * @param {Function} props.dispatch - Dispatch function.
 * @returns {JSX.Element}
 */
const WidgetAddressWarehouse = ({
  address,
  index,
  state,
  dispatch,
  weeksProps,
  fromProps,
  toProps,
  permissionManage,
}) => {
  const { t, i18n } = useTranslation(["common"]);
  const weekNumbers = getWeekDays(i18n.getLanguageVariable(i18n.language), "i");
  const weekDays = getWeekDays(i18n.getLanguageVariable(i18n.language), "iiii")
    .map((day, index) => {
      const formattedDay = capitalizeFirstLetter(day);
      return { uuid: weekNumbers[index], text: formattedDay };
    })
    .sort((a, b) => a.uuid - b.uuid);

  const handleGroupItemClick =
    (index, key, timeTableIndex) => (option, open, setOpen) => {
      const newState = deepmerge([], state?.getOrgLocations);
      const timetable = newState[index]?.timetables[timeTableIndex];
      timetable[key] = option.uuid;
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: { getOrgLocations: newState },
      });
    };

  const handleGroupMultipleItemClick =
    (index, key, timeTableIndex) => (option, open, setOpen, e) => {
      e.stopPropagation();
      const newState = deepmerge([], state?.getOrgLocations);
      const timetable = newState[index]?.timetables[timeTableIndex];
      timetable[key].includes(option.uuid)
        ? (timetable[key] = timetable[key].filter((f) => f !== option.uuid))
        : (timetable[key] = [...timetable[key], option.uuid]);
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: { getOrgLocations: newState },
      });
    };

  const handleAddSchedule = (index) => (e) => {
    const newState = deepmerge([], state?.getOrgLocations);
    newState[index]["timetables"] = [
      ...newState[index]?.timetables,
      {
        from: "",
        to: "",
        daysWeek: [],
      },
    ];
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { getOrgLocations: newState },
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <Label>
            {t("warehouse-hours")} {weeksProps?.required ? "*" : ""}
          </Label>
        </div>
      </div>
      {address?.timetables?.length > 0 &&
        address?.timetables?.map((timetable, timeTableIndex) => {
          return (
            <div className="row">
              <div className="col-12 col-lg-8">
                <Label reduced>{t("days")}</Label>
                <AutoComplete
                  options={weekDays}
                  multiple={true}
                  placeholder={t("select-many")}
                  value={timetable?.daysWeek
                    ?.sort((a, b) => a - b)
                    ?.map(
                      (d) =>
                        weekDays.find((f) => parseInt(f.uuid) === parseInt(d))
                          ?.text
                    )}
                  handleItemClick={handleGroupMultipleItemClick(
                    index,
                    "daysWeek",
                    timeTableIndex
                  )}
                  handleIsChecked={(option) => {
                    return address?.timetables[timeTableIndex]?.daysWeek.find(
                      (f) => {
                        return f === option.uuid;
                      }
                    );
                  }}
                  position="fixed"
                  skipRequiredHelp
                  required={timetable?.from || timetable?.to}
                  readOnly={permissionManage ? !permissionManage() : false}
                  {...weeksProps}
                />
              </div>
              <div className="col-12 col-lg-4">
                <Flex style={{ gap: "1.5rem" }}>
                  <div>
                    <Label reduced>{t("from")}</Label>
                    <TimeDropdown
                      placeholder={t("write-here")}
                      value={timetable?.from}
                      handleItemClick={handleGroupItemClick(
                        index,
                        "from",
                        timeTableIndex
                      )}
                      skipRequiredHelp
                      readOnly={permissionManage ? !permissionManage() : false}
                      {...fromProps}
                    />
                  </div>
                  <div>
                    <Label reduced>{t("to").toUpperCase()}</Label>
                    <TimeDropdown
                      placeholder={t("write-here")}
                      value={timetable?.to}
                      handleItemClick={handleGroupItemClick(
                        index,
                        "to",
                        timeTableIndex
                      )}
                      skipRequiredHelp
                      readOnly={permissionManage ? !permissionManage() : false}
                      {...toProps}
                    />
                  </div>
                </Flex>
              </div>
            </div>
          );
        })}
      {permissionManage && permissionManage() && (
        <div className="row">
          <div className={`col-12 ${styles["sc-delivery--addmore"]}`}>
            <ClearButton onClick={handleAddSchedule(index)}>
              {t("add-schedule")}
            </ClearButton>
          </div>
        </div>
      )}
    </>
  );
};

export default WidgetAddressWarehouse;
