import { useParams } from "react-router-dom";
import WidgetDataWrapper from "../widgets/wrappers/DataWrapper";

/**
 * Wrapper for pages that needs and id param to fetch data.
 * @param {Object} props - Component properties.
 * @param {React.Component} props.Component - Component to pass id.
 * @param {Function} props.fnData - .
 * @param {Object} props.fnParameters - .
 * @returns {JSX.Element}
 */
const PageWithOrgUuid = ({ Component, fnData, fnParameters, ...props }) => {
  const { id } = useParams();
  return (
    <WidgetDataWrapper
      id={id}
      fnData={fnData}
      fnParameters={{ org: id, ...fnParameters }}
      Component={Component}
      {...props}
    />
  );
};

export default PageWithOrgUuid;
