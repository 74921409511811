import { gql } from "@apollo/client";
import client from "../utils/gqlclient";
import { getTable } from "./tables";

export async function getIncomeFinances({ locale, limit }) {
  const tableModule = "unidentifiable-transactions";

  return await getTable({
    locale: locale,
    tableModule: tableModule,
    limit,
  });
}

export async function getPaymentTransactionComment({ uuid }) {
  const data = await client.query({
    query: gqlGetPaymentTransactionComment,
    variables: {
      uuid: parseInt(uuid),
    },
  });
  return data.data.getPaymentTransactionComment;
}

const gqlGetPaymentTransactionComment = gql`
  query ($uuid: Int!) {
    getPaymentTransactionComment(uuidTransaction: $uuid)
  }
`;
