import { useNavigate } from "react-router-dom";
import CardBox from "../../../components/ui/box/CardBox";
import BusinessUser from "../../../pages/business/users/Users";
import { useTranslation } from "../../../utils/hooks";

/**
 * Users business widget for dashboard.
 * @param {Object} props - Component properties.
 * @param {String} props.id - Business id.
 * @param {Object} props.state - Data state.
 * @returns {JSX.Element}
 */
const WidgetBusinessUser = ({ id, state }) => {
  const { t } = useTranslation(["common", "transactions"]);
  const navigate = useNavigate();

  const navigateTo = () => navigate(`/business/${id}/users`);

  return (
    <CardBox
      title={t("users")}
      action={t("see-more")}
      handleAction={navigateTo}
    >
      {state?.usersByOrg?.map((user) => (
        <BusinessUser
          key={user.uuidPerson}
          avatar={user?.avatar}
          userName={user?.userName}
          isAdmin={user?.role}
        />
      ))}
    </CardBox>
  );
};

export default WidgetBusinessUser;
