import { Container, MiniTabs } from "@scrapadev/scrapad-front-sdk";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import LayoutDetail from "../../layout/detail/Detail";
import { useDocumentTitle } from "../../utils/document/hooks";
import { useTranslation } from "../../utils/hooks";
import { useTabNavigation } from "../../utils/navigation/hooks";
import LogisticsPricing from "./Pricing";
import LogisticsProviders from "./Providers";
import LogisticsTracking from "./Tracking";
import LogisticsPricingTable from "./PricingTable";

const Logistics = () => {
  const { t } = useTranslation(["common", "logistics"]);
  const { currentTab, setCurrentTabWithStorage } = useTabNavigation(
    "logisticsCurrentTab"
  );
  useDocumentTitle(`${t("logistics")}`);

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerWithContainer
        headerComponent={<Header title={t("logistics")} />}
        bodyComponent={
          <Container isFluid={false}>
            <div className="row">
              <div className="col-12">
                <MiniTabs
                  undelinedMode={true}
                  tabs={[
                    {
                      tab: <>Pricing</>,
                      panel: <LogisticsPricing />,
                    },
                    {
                      tab: <>Tracking</>,
                      panel: <LogisticsTracking />,
                    },
                    {
                      tab: <>{t("transport-providers")}</>,
                      panel: <LogisticsProviders />,
                    },
                    {
                      tab: <>{t("logistic-pricing")}</>,
                      panel: <LogisticsPricingTable />,
                    },
                  ]}
                  currentTab={currentTab}
                  changeTab={setCurrentTabWithStorage}
                  tabsProps={{
                    style: { position: "static" },
                  }}
                  tabPanelProps={{
                    style: { position: "static" },
                  }}
                />
              </div>
            </div>
          </Container>
        }
      />
    </>
  );
};

export default Logistics;
