import { CheckBox, Flex } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";

/**
 * Helper component for address is port.
 * @param {number} [props.index=0] - Index of current address.
 * @param {string} props.text - Checkbox label.
 * @param {Function} [props.checked=false]- Checked state.
 * @param {Function} [props.onChange]- On change handleer.
 * @returns {JSX.Element}
 */
const WidgetAddressesBySea = ({
  index = 0,
  text,
  checked = false,
  onChange,
  ...props
}) => {
  const { t } = useTranslation(["common"]);
  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <Flex style={{ marginBottom: 16 }} justifyContent={"flex-start"}>
      <CheckBox
        text={text}
        id={`IsPort${index}`}
        checked={checked}
        onChange={handleOnChange}
        {...props}
      />
    </Flex>
  );
};

export default WidgetAddressesBySea;
