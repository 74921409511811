import { ClearButton, Icon, PublishIcon } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";
import { adStatus, TEXT_COLOR_LIGHT } from "../../utils/variables";

const AdActionsButton = ({ type, handleOnClick }) => {
  const { t } = useTranslation(["common", "ads"]);
  const getIcon = () => {
    switch (type) {
      case "share":
        return "share";
      case "edit":
        return "edit";
      case "publish":
        return "computer";
      case "unpublish":
        return "disabled_visible";
      case "clone":
        return "content_copy";
      case "block":
        return "block";
      case "unblock":
        return "block";
      case "restore":
        return "u_turn_right";
    }
  };
  return (
    <ClearButton
      style={{ display: "flex", alignItems: "center" }}
      onClick={handleOnClick}
    >
      {type === "publish" ? (
        <span style={{ marginRight: 10 }}>
          <PublishIcon />
        </span>
      ) : (
        <Icon
          name={getIcon()}
          color={TEXT_COLOR_LIGHT}
          style={{
            marginRight: 10,
            fontSize: 16,
            transform: type === "restore" ? "rotate(-90deg)" : "none",
          }}
        />
      )}

      <span
        style={{
          fontFamily: "Roboto",
          fontWeight: 300,
          fontSize: 14,
          lineHeight: "21px",
          textDecoration: "underline",
          color: "#7E7E7E",
        }}
      >
        {t(type)}
      </span>
    </ClearButton>
  );
};

export default AdActionsButton;
