import { useState } from "react";
import {
  Container,
  Modal,
  Spacer,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import ModalText from "../../ui/modal/text/ModalText";
import { useTranslation } from "../../../utils/hooks";
import { adsActionsTypes, adStatus } from "../../../utils/variables";
import Radio from "../../ui/radio/Radio";

/**
 * Modal for ads actions.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalAdsActions = ({ open = false, setOpen, ...props }) => {
  const { t } = useTranslation(["users", "common", "ads"]);
  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState(null);
  const {
    fetchData,
    titleMessage,
    bodyMessage,
    okMessage,
    adUuid,
    typeAd,
    handleEditAction,
    handleCloneAction,
    handleUnpublishAction,
    handlePublishAction,
    handleBlockAction,
    handleUnBlockAction,
    handleRestoreAction,
    handleDemandCoveredAction,
    handleRemovedAction,
    ad,
    isEdit,
    fromDetail,
  } = props;
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    switch (typeAd) {
      case adsActionsTypes.EDIT:
        handleEditAction(adUuid);
        setLoading(false);
        setOpen(false);
        break;
      case adsActionsTypes.CLONE:
        handleCloneAction({
          adUuid,
          fetchData,
          setLoading,
          setOpen,
          ad,
          fromDetail,
        });
        break;
      case adsActionsTypes.PUBLISH:
        handlePublishAction({
          adUuid,
          fetchData,
          setLoading,
          setOpen,
          ad,
          fromDetail,
        });
        break;
      case adsActionsTypes.UNPUBLISH:
        switch (reason) {
          case adStatus.DRAFT:
            handleUnpublishAction({
              adUuid,
              fetchData,
              setLoading,
              setOpen,
              ad,
              fromDetail,
            });
            break;
          case adStatus.COVERED:
            handleDemandCoveredAction({
              adUuid,
              fetchData,
              setLoading,
              setOpen,
              ad,
              fromDetail,
            });
            break;
          case adStatus.REMOVED:
            handleRemovedAction({
              adUuid,
              fetchData,
              setLoading,
              setOpen,
              ad,
              fromDetail,
            });
            break;
          default:
            setOpen(false);
            break;
        }

        break;
      case adsActionsTypes.BLOCK:
        handleBlockAction({
          adUuid,
          fetchData,
          setLoading,
          setOpen,
          ad,
          fromDetail,
        });
        break;
      case adsActionsTypes.UNBLOCK:
        handleUnBlockAction({
          adUuid,
          fetchData,
          setLoading,
          setOpen,
          ad,
          fromDetail,
        });
        break;
      case adsActionsTypes.RESTORE:
        handleRestoreAction({ adUuid, fetchData, setLoading, setOpen });
        break;
      case adsActionsTypes.DEMAND_COVERED:
        handleDemandCoveredAction({ adUuid, fetchData, setLoading, setOpen });
        break;
    }
  };
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={titleMessage}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={okMessage}
      cancelText={
        typeAd === adsActionsTypes.DEMAND_COVERED
          ? t("edit-amount")
          : t("cancel")
      }
      reverseActions
      hasActions={typeAd !== adsActionsTypes.CANT_UNPUBLISH}
      loading={loading}
      maxWidth={700}
      BodyComponent={
        <Container>
          {typeAd === adsActionsTypes.UNPUBLISH ? (
            <>
              <ModalText text={t("please-tell-reason")} />
              <div>
                <Radio
                  id="reason_1"
                  name="reason"
                  text={t("not-want-ad-visible")}
                  onChange={() => setReason(adStatus.DRAFT)}
                />
              </div>
              <Spacer height={20} />
              <div>
                <Radio
                  id="reason_2"
                  name="reason"
                  text={t("not-have-ad-material")}
                  onChange={() => setReason(adStatus.COVERED)}
                />
              </div>
              <Spacer height={20} />
              <div>
                <Radio
                  id="reason_3"
                  name="reason"
                  text={t("want-ad-removed")}
                  onChange={() => setReason(adStatus.REMOVED)}
                />
              </div>
            </>
          ) : (
            <ModalText text={bodyMessage} />
          )}
        </Container>
      }
    />
  );
};

export default ModalAdsActions;
