import { useTranslation } from "../../../utils/hooks";
import WidgetFieldFiles from "../../fields/Files";
import InputFileWrapper from "../../../components/ui/input/file/Wrapper";

const WidgetVerificationFiles = ({ state, extraData }) => {
  const { t } = useTranslation(["common"]);
  const { permission = null } = extraData || {};

  return (
    <>
      <div>
        <InputFileWrapper
          placeholder={t("select-drop-files")}
          dropPlaceholder={t("drop-files")}
          iconStyles={{}}
          afterUploadAllFiles={extraData?.afterUploadAllFiles}
          allowedExtension={[".pdf", ".jpeg", ".jpg", ".png"]}
          fileType={"kyc"}
          translation={{
            uploading: t("uploading"),
          }}
          readOnly={permission ? (permission() ? !permission() : true) : false}
        />
      </div>
      <WidgetFieldFiles
        canEdit={extraData?.canEdit}
        id={extraData?.id}
        files={state?.files}
        fetchData={extraData?.fetchData}
        setLoading={extraData?.setLoading}
        permission={permission}
      />
    </>
  );
};

export default WidgetVerificationFiles;
