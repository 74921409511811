import React from "react";
import {
  Row,
  Icon,
  Typography,
  ClearButton,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";

function MarkAsRead({ onClick }) {
  const { t } = useTranslation("notifications");
  return (
    <ClearButton onClick={onClick}>
      <Row gap={10} align="center" style={{ curor: "pointer" }}>
        <Icon
          name="drafts"
          color="#777986"
          style={{
            fontSize: "18px",
          }}
        />
        <Typography
          style={{
            marginBottom: 0,
            fontSize: 16,
            lineHeight: "19px",
            color: "#7E7E7E",
          }}
        >
          {t("mark_read")}
        </Typography>
      </Row>
    </ClearButton>
  );
}

export default MarkAsRead;
