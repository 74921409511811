import {
  Center,
  Flex,
  Heading,
  Loading,
  Paper,
  Typography,
  WARM_NEUTRAL_50,
} from "@scrapadev/scrapad-front-sdk";
import React from "react";
import { useTranslation } from "../../../../utils/hooks";
import DocumentsBreadcrumbs from "../documents-breadcrumbs/DocumentsBreadcrumbs";
import DocumentLocationItemSelectable from "./DocumentLocationItemSelectable";
import DocumentLocationSelectorEmptyIcon from "./DocumentLocationSelectorEmptyIcon";
import { useDocumentsProvider } from "../../DocumentsContext";
import _ from "lodash";

function DocumentLocationSelector({ onSelected = () => {}, selectedFolder }) {
  const { t } = useTranslation(["common"]);
  const { state, openFolder, onBack, fetchData } = useDocumentsProvider();

  return (
    <div>
      <Flex flexDirection="column" alignItems="stretch">
        <Heading type={7}>{t("destination")}:</Heading>
        <Paper style={{ alignItems: "stretch", paddingTop: "5px" }}>
          <DocumentsBreadcrumbs
            onBack={() => {
              if (!state?.loading) {
                if (state?.breadcrumbs.length > 1) {
                  onBack(
                    state?.breadcrumbs[state?.breadcrumbs.length - 2]?.uuid
                  );
                } else if (state?.breadcrumbs.length > 0) {
                  fetchData(state?.transactionUuid, undefined, false, true);
                }
              }
            }}
            items={state?.breadcrumbs?.map((crumb, index) => ({
              onClick: () => {
                if (index < state?.breadcrumbs?.length - 1 && !state?.loading) {
                  onBack(crumb.uuid);
                }
              },
              description: crumb?.name,
            }))}
          />

          <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ gap: "5px" }}
          >
            {_.isEmpty(state?.dataVisible) ? (
              <>{state?.loading ? <Loading /> : <EmptyFolder />}</>
            ) : (
              <>
                {state?.loading ? (
                  <>
                    <Loading />
                  </>
                ) : (
                  <>
                    {state?.dataVisible?.subfolders?.map((folder) => (
                      <DocumentLocationItemSelectable
                        name={folder?.name}
                        selected={_.isEqual(selectedFolder, folder)}
                        onSelected={() => {
                          onSelected(folder);
                        }}
                        onNext={() => {
                          onSelected(folder);
                          openFolder(folder?.id, folder?.owner);
                        }}
                      />
                    ))}
                  </>
                )}
              </>
            )}
          </Flex>
        </Paper>
      </Flex>
    </div>
  );
}

function EmptyFolder() {
  const { t } = useTranslation("common");
  return (
    <Center>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        style={{ gap: "10px" }}
      >
        <DocumentLocationSelectorEmptyIcon />
        <Typography noMargin style={{ color: WARM_NEUTRAL_50 }}>
          {t("empty_folder")}
        </Typography>
      </Flex>
    </Center>
  );
}

export default DocumentLocationSelector;
