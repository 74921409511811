import { Container, Sticky } from "@scrapadev/scrapad-front-sdk";
import React, { useEffect, useRef, useState } from "react";
import styles from "./Detail.module.scss";

/**
 * Detail page layout
 * @param {Object} props - Component properties.
 * @param {React.Component} props.headerComponent - Header component.
 * @param {React.Component} props.bodyComponent - Body component.
 * @param {String} props.backgroundColor - Override for background color.
 * @param {React.CSSProperties} [props.headerStyle={}] - Header inline styles.
 * @param {React.CSSProperties} [props.mainStyle={}] - Main inline styles.
 * @param {boolean} props.headerWithContainer - With this header component will have container, row and col-12 by default.
 * @param {boolean} isSticky - Tells if header is sticky.
 * @returns {JSX.Element}
 */
const LayoutDetail = ({
  headerComponent = <></>,
  bodyComponent = <></>,
  customRenderBody,
  backgroundColor,
  containerStyles = {},
  headerStyle = {},
  mainStyle = {},
  headerWithContainer,
  containerProps,
  isSticky,
}) => {
  const headerRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(0);
  useEffect(() => {
    if (headerRef?.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [headerRef]);

  const renderHeader = () => {
    if (headerWithContainer)
      return (
        <Container {...containerProps}>
          <div className="row">
            <div className="col-12">{headerComponent}</div>
          </div>
        </Container>
      );

    return headerComponent;
  };
  return (
    <div
      className={styles["sc-layout--detail"]}
      style={
        backgroundColor
          ? { backgroundColor, ...containerStyles }
          : containerStyles
      }
    >
      {isSticky ? (
        <Sticky
          style={{
            top: 0,
            background: "#FFF",
            zIndex: 9,
            borderBottom: "1px solid #d0d3db",
          }}
          ref={headerRef}
        >
          <header className={styles["sc-layout--header"]} style={headerStyle}>
            {renderHeader(headerComponent)}
          </header>
        </Sticky>
      ) : (
        <header className={styles["sc-layout--header"]} style={headerStyle}>
          {renderHeader(headerComponent)}
        </header>
      )}
      <main className={styles["sc-layout--main"]} style={mainStyle}>
        {customRenderBody
          ? customRenderBody(headerHeight, setHeaderHeight)
          : bodyComponent}
      </main>
    </div>
  );
};

export default LayoutDetail;
