import { InputDate } from "@scrapadev/scrapad-front-sdk";
import { useRef, useState, forwardRef } from "react";
import { actions } from "../../../providers/filters/actions";
import { useFiltersProvider } from "../../../providers/filters/FiltersContext";
import { capitalizeFirstLetter, formatDate } from "../../../utils/functions";
import { useTranslation } from "../../../utils/hooks";

const FilterDate = ({ filterKey }, ref) => {
  const { i18n } = useTranslation();
  const { dispatch, filterChanged } = useFiltersProvider();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dateRangeNumberClicks = useRef(0);
  const handleDateFilter = (date) => {
    const [start, end] = date;
    dateRangeNumberClicks.current++;
    setStartDate(start);
    setEndDate(end);
    if (dateRangeNumberClicks.current === 2) {
      if (ref?.current?.handleClose) ref.current?.handleClose(false);
      filterChanged.current = true;
      dispatch({
        type: actions.ADD_UNIQUE_FILTER,
        payload: {
          filter: { slug: filterKey },
          item: {
            uuid: `${start.getTime()}-${end.getTime()}`,
            name: `${capitalizeFirstLetter(
              formatDate(start, i18n, false)
            )} - ${capitalizeFirstLetter(formatDate(end, i18n, false))}`,
            from: start.getTime(),
            to: end.getTime(),
          },
        },
      });
    }
  };
  return (
    <InputDate
      inline
      selectsRange
      value={startDate}
      startDate={startDate}
      endDate={endDate}
      handleOnChange={handleDateFilter}
      languageCode={i18n.language}
      numberOfYears={10}
      yearsBackwards
    />
  );
};

export default forwardRef(FilterDate);
