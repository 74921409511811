import { ClearButton, Loading } from "@scrapadev/scrapad-front-sdk";
import React, { useState } from "react";
import LayoutTable from "../../layout/table/Table";
import { getAdTransactions } from "../../model/transactions";
import { useTableDataProvider } from "../../providers/TableContext";
import { useTranslation } from "../../utils/hooks";
import AdStat from "./AdStat";
import styles from "./AdStats.module.scss";
import WidgetAdsTransactions from "./Transactions";

function InnerAdStats({
  ad,
  adUuid,
  updatedAt,
  views = 0,
  likes,
  isMobile,
  style = {},
}) {
  const { t } = useTranslation("common");
  const { data, loading } = useTableDataProvider();
  const [transactionsOpen, setTransactionsOpen] = useState(false);
  return (
    <div
      className={`${styles["sc-ad-stats"]} ${isMobile && styles["mobile"]}`}
      style={style}
    >
      {updatedAt ? (
        <div className={`${styles["sc-ad-stats__block"]}`}>
          <span className={`${styles["sc-ad-stats__text"]}`}>
            {t("updated-at")} {updatedAt}
          </span>
        </div>
      ) : (
        <></>
      )}

      <div
        className={`${styles["sc-ad-stats__block"]} ${styles["sc-ad-stats__block--end"]}`}
      >
        <AdStat icon="visibility" value={views} />
        <AdStat icon="favorite" value={likes} />
        {loading ? (
          <Loading />
        ) : (
          <ClearButton
            style={{ cursor: data?.body?.length > 0 ? "pointer" : "default" }}
            onClick={
              data?.body?.length > 0
                ? () => {
                    setTransactionsOpen(!transactionsOpen);
                  }
                : undefined
            }
          >
            <AdStat icon="sync_alt" value={data?.body?.length} />
          </ClearButton>
        )}
      </div>
      {adUuid && (
        <WidgetAdsTransactions
          key={adUuid}
          adUuid={adUuid}
          ad={ad}
          transactionsOpen={transactionsOpen}
          setTransactionsOpen={setTransactionsOpen}
        />
      )}
    </div>
  );
}

const AdStats = ({ ...props }) => {
  const { i18n } = useTranslation();
  return (
    <LayoutTable
      fnData={getAdTransactions}
      fnParameters={{ locale: i18n.language, uuid: props.adUuid }}
    >
      <InnerAdStats {...props} />
    </LayoutTable>
  );
};

export default AdStats;
