export const actions = {
  FETCH_NOTIFICATIONS: "fetch_notifications",
  SET_NOTIFICATIONS: "set_notifications",
  SET_UNREAD_NOTIFICATIONS: "set_unread_notifications",
  UNSET_NOTIFICATIONS: "unset_notifications",
  FETCH_PREFERENCES: "fetch_preferences",
  SET_CHANNEL_PREFERENCES: "set_channel_preferences",
  SET_LOADING: "set_loading",
  UNSET_LOADING: "unset_loading",
};

