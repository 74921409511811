import { DropDown } from "@scrapadev/scrapad-front-sdk";
import { getHoursForDropdown } from "../../../../utils/functions";

/**
 * UI Dropdown for showing times of day.
 * @param {Object} param0 - Component properties.
 * @returns {JSX.Element}
 */
const TimeDropdown = ({
  placeholder,
  value,
  model,
  handleItemClick,
  ...props
}) => {
  const options = getHoursForDropdown();
  return (
    <DropDown
      position="fixed"
      placeholder={placeholder}
      options={options}
      model={model}
      value={options.find((f) => f.uuid === value)?.text}
      handleItemClick={handleItemClick}
      {...props}
    />
  );
};

export default TimeDropdown;
