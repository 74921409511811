import { useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import { Modal } from "@scrapadev/scrapad-front-sdk/dist/index";
import ModalText from "../../ui/modal/text/ModalText";

/**
 * Modal for show a warning when data needs to be saved at leaving the page.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalSaveData = ({ open = false, setOpen, ...props }) => {
  const { t } = useTranslation(["common"]);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (props.submitCallback) props.submitCallback(e, loading, setLoading);
  };
  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("save-before-leave")}
      hasForm={true}
      onCancel={props.onCancel}
      onSubmit={handleSubmit}
      submitText={t("keep-editing")}
      cancelText={t("leave-not-save")}
      loading={loading}
      maxWidth={600}
      BodyComponent={<ModalText text={t("leave-lost-changes")} />}
    />
  );
};

export default ModalSaveData;
