import { useEffect, useState } from "react";
import {
  BREAKPOINT_LG,
  Container,
  Modal,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import { useTranslation } from "../../../utils/hooks";
import { useDataProvider } from "../../../providers/data/DataContext";
import { actions } from "../../../providers/data/actions/data";
import AdsAdType from "../../ui/ads/AdType";

/**
 * Modal for Edit a favorite material
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalEditFavoriteMaterial = ({ open = false, setOpen, ...props }) => {
  const { t } = useTranslation(["filters", "common", "business"]);
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useDataProvider();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);

  useEffect(() => {
    loadData();
    setLoading(false);
  }, [props.data]);

  const loadData = () => {
    dispatch({
      type: actions.INITIAL_LOAD,
      payload: {
        materials: props.data,
      },
    });
  };

  const handleSetOpen = () => {
    loadData();
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    if (props.onSubmit) props.onSubmit(state, props.data.key);
    handleSetOpen();
  };

  return (
    <Modal
      open={open}
      setOpen={handleSetOpen}
      title={t("edit-favorite-material")}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("save")}
      cancelText={t("cancel")}
      submitDisabled={state?.materials?.material?.uuidSelected.length === 0}
      loading={loading}
      maxWidth={900}
      modalStyles={{ width: matches ? "auto" : 900 }}
      BodyComponent={
        <div style={{ margin: "0 -12px" }}>
          <Container>
            <div className="row">
              <div className="col-12">
                <AdsAdType />
              </div>
            </div>
          </Container>
        </div>
      }
    />
  );
};

export default ModalEditFavoriteMaterial;
