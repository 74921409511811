import { adStatus, INFO_COLOR } from "../variables";

export const adTypeFilterItems = [
  { uuid: "purchase", name: "buy" },
  {
    uuid: "sale",
    name: "sell",
  },
];

export const adStatusItems = [
  adStatus.PUBLISHED,
  adStatus.DRAFT,
  adStatus.REVIEW_PENDING,
  adStatus.COVERED,
  adStatus.BLOCKED,
  adStatus.REMOVED,
];

export const transactionsPhaseItems = [
  "NEGOTIATION",
  "FINISH",
  "TRANSPORT",
  "CONTRACT",
];

export const transactionsNegotiation = ["pending", "launched"];

export const transactionsContracts = ["sign_pending", "deposit_pending"];

export const transactionsTransport = [
  "pending",
  "load_coordinated",
  "load_confirmed",
  "unload_coordinated",
  "unload_confirmed",
];

export const businessVerificationItems = [
  "not_started",
  "pending",
  "closed",
  "documentation_error",
  "verified",
];

export const whiteFilterChipProps = {
  style: {
    backgroundColor: "#fff",
    border: `1px solid #CFCFCF`,
    borderRadius: 18,
    fontFamily: "Silka",
    fontWeight: 400,
    fontSize: 13,
    lineHeight: "19px",
  },
  textStyles: {
    fontFamiliy: "Silka",
    fontWeight: 500,
    fontFamily: 14,
    lineHeight: "19px",
    color: "#626262",
  },
};
export const whiteFilterGroupedStyle = {
  backgroundColor: INFO_COLOR,
  color: "#fff",
};
export const whiteFilterTextStyles = {
  fontFamily: "Silka",
  fontWeight: 400,
  fontSize: 13,
  lineHeight: "19px",
};
export const whiteFilterItemCheckStyle = {
  border: `2px solid #B1B1B1`,
};
export const whiteFilterItemCheckedStyle = {
  border: `2px solid ${INFO_COLOR}`,
  backgroundColor: `${INFO_COLOR}`,
  color: "#FFF",
};
export const whiteFilterAutoCompleteInputProps = {
  permanentOpen: false,
  leadingIcon: undefined,
  containerStyles: {
    padding: 0,
  },
  inputStyles: {
    padding: 0,
    display: "none",
  },
  resultsStyles: {
    border: `1px solid #E1E1E1`,
    borderRadius: 8,
    paddingBottom: 5,
    top: 15,
  },
};

export const whiteFilterProps = {
  chipProps: whiteFilterChipProps,
  groupedStyle: whiteFilterGroupedStyle,
  textStyles: whiteFilterTextStyles,
  itemCheckStyle: whiteFilterItemCheckStyle,
  itemCheckedStyle: whiteFilterItemCheckedStyle,
  autoCompleteInputProps: whiteFilterAutoCompleteInputProps,
};

export const whiteFilterWithAutoCompleteProps = {
  chipProps: whiteFilterChipProps,
  groupedStyle: whiteFilterGroupedStyle,
  textStyles: whiteFilterTextStyles,
  itemCheckStyle: whiteFilterItemCheckStyle,
  itemCheckedStyle: whiteFilterItemCheckedStyle,
  autoCompleteInputProps: {
    ...whiteFilterAutoCompleteInputProps,
    useSearch: true,
    resultsStyles: {
      border: "none",
      borderRadius: 0,
      paddingTop: 0,
      paddingBottom: 5,
      paddingLeft: 15,
      paddingRight: 15,
      top: 15,
    },
  },
};
