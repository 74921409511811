import { actions } from "../../../providers/transactions/actions/transactions-forms";
import { useTransactionsFormsProvider } from "../../../providers/transactions/TransactionsFormContext";
import { useTranslation } from "../../../utils/hooks";
import { buildFormSamples } from "./functions";
import styles from "./Samples.module.scss";
import { postClient } from "../../../utils/restclient";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import { formEvents, rawSampelsEvents } from "../../../utils/variables";
import {
  checkPermission,
  dropdownGetValueFromUuid,
  handleFormOK,
} from "../../../utils/functions";
import {
  toastError,
  Button,
  InputDate,
  Loading,
  Paper,
  ChipDropdown,
  InputText,
  ClearButton,
  Row,
  Label,
  Modal,
  InputNumber,
} from "@scrapadev/scrapad-front-sdk";
import { useParams } from "react-router-dom";
import { useRawEventBO } from "../../../utils/transactions/hooks";
import { parseSamplesRawEventData } from "../../../utils/transactions/functions";
import TabPanelWrap from "../../../pages/transactions/wrappers/TabPanelWrap";
import { useState } from "react";
import TransactionFiles from "../../ui/input/file/File";
import AttachmentButton from "../../ui/attachment-button/AttachmentButton";
import _ from "lodash";
import deepmerge from "deepmerge";

/**
 * Samples form for transactions.
 * @returns {JSX.Element}
 */
const SamplesForm = ({ fetchData, permission, onClose = () => {} }) => {
  const { id } = useParams();
  const { t, i18n } = useTranslation(["common", "transactions"]);
  const [showFile, setShowFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { state, dispatch } = useTransactionsFormsProvider();
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const checkdPermission = checkPermission(permission);
  const {
    rawLoading,
    eventData,
    fetchData: rawFetchData,
  } = useRawEventBO({
    uuidConversation: id,
    slug: rawSampelsEvents,
    eventAdapter: parseSamplesRawEventData,
    actions,
    dispatch,
  });

  const options = [
    {
      uuid: "PENDING",
      text: t("pending"),
    },
    {
      uuid: "SENT",
      text: t("sended"),
    },
    {
      uuid: "DELIVERY",
      text: t("delivered"),
    },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    dispatch({ type: actions.UPDATE_INPUT, payload: { loading: true } });
    const data = buildFormSamples(
      user.userUuid,
      document.getElementById("slug").value,
      state
    );
    try {
      await postClient({
        external: true,
        url: `${process.env.REACT_APP_API_EVENTS}`,
        method: "POST",
        body: data,
        signOut,
        setDataUser,
      });
      handleFormOK(t, fetchData);
    } catch (error) {
      toastError(error.message);
    } finally {
      rawFetchData();
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: { loading: false },
      });
      setIsLoading(false);
    }
  };
  const handleOnChange = (e) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { deliveryPrice: e.target.valueAsNumber },
    });
  };
  const handleItemClick = (option, open, setOpen) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { state: option.uuid },
    });
    setOpen(!open);
  };
  const handleDate = (date) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { estimatedDate: date.getTime() },
    });
  };
  const checkIfAddOrUpdate = () => {
    return eventData
      ? formEvents.UPDATE_SAMPLE_REQUEST_DATA
      : formEvents.ADD_SAMPLE_REQUEST_DATA;
  };
  const afterUpload = (files) =>
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { files: deepmerge(state.files, files) },
    });

  const afterDelete = (files) =>
    dispatch({ type: actions.UPDATE_INPUT, payload: { files: files } });

  const afterFileNameChange = (files) =>
    dispatch({ type: actions.UPDATE_INPUT, payload: { files: files } });

  if (rawLoading) return <Loading />;

  return (
    <>
      <Paper
        whiteMode
        style={{
          alignItems: "stretch",
          marginLeft: "-20px",
          marginRight: "-20px",
          padding: "15px",
        }}
      >
        <TabPanelWrap
          title={
            <Row gap={10}>
              {t("samples")}
              <ChipDropdown
                items={options.map((item, index) => (
                  <ClearButton
                    key={item.uuid}
                    onClick={() => {
                      dispatch({
                        type: actions.UPDATE_INPUT,
                        payload: {
                          state: item?.uuid,
                        },
                      });
                    }}
                  >
                    {t(item.text)}
                  </ClearButton>
                ))}
                type="clear"
                text={
                  dropdownGetValueFromUuid(state, options) || t("select-value")
                }
              />
              <InputText
                id="Status_sample"
                required
                value={!_.isEmpty(state?.state) ? state?.state : undefined}
                customValidityMessage={t("required-field")}
                style={{
                  visibility: "hidden",
                  height: 0,
                  padding: 0,
                  margin: `0 0 .5rem 0`,
                }}
              />
            </Row>
          }
          onClose={onClose}
        >
          <form onSubmit={handleSubmit} className={styles["sc-form"]}>
            <Row gap={30}>
              <Label text={`${t("deliver-cost")} (€)`} noPadding>
                <InputNumber
                  readOnly={!checkdPermission}
                  required
                  placeholder={t("write-here")}
                  min={0}
                  step={".01"}
                  value={state.deliveryPrice}
                  onChange={handleOnChange}
                  mode="underline"
                />
              </Label>
              <Label text={t("deliver-cost-estimated-date")} noPadding>
                <InputDate
                  readOnly={!checkdPermission}
                  placeholder={t("write-here")}
                  value={state.estimatedDate}
                  handleOnChange={handleDate}
                  languageCode={i18n.language}
                  mode="underline"
                />
              </Label>
            </Row>

            <Row gap={10}>
              <AttachmentButton
                onClick={(e) => {
                  setShowFile(true);
                }}
                count={state?.files?.length}
                style={{ marginRight: "auto" }}
              />
              <Button
                component="button"
                reduced
                mode="normal"
                text={t("cancel")}
                loading={state.loading || isLoading}
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                }}
              />
              <Button
                disabled={!checkdPermission}
                component="button"
                text={t("save")}
                loading={state.loading || isLoading}
                mode="info"
                reduced
              />
            </Row>

            <input
              type="hidden"
              id={"slug"}
              name={"slug"}
              value={checkIfAddOrUpdate()}
            />
          </form>
        </TabPanelWrap>
      </Paper>
      <Modal
        t={t}
        open={showFile}
        setOpen={setShowFile}
        title={t("files_attach")}
        hasForm={false}
        onSubmit={() => {
          setShowFile(false);
        }}
        ActionsComponent={
          <>
            <Row gap={20} justify="flex-end" style={{ marginLeft: "auto" }}>
              <Button
                component="button"
                text={t("cancel")}
                mode="normal"
                onClick={(e) => {
                  e?.preventDefault();
                  setShowFile(false);
                }}
              />
              <Button
                component="button"
                text={t("save")}
                mode="info"
                onClick={(e) => {
                  e?.preventDefault();
                  setShowFile(false);
                }}
              />
            </Row>
          </>
        }
        submitText={t("accept")}
        cancelText={t("cancel")}
        maxWidth={900}
        modalStyles={{ width: 900 }}
        BodyComponent={
          <>
            <TransactionFiles
              files={checkdPermission ? state.files : []}
              placeholder={t("select-drop-files")}
              dropPlaceholder={t("drop-files")}
              afterUpload={afterUpload}
              afterDelete={afterDelete}
              afterFileNameChange={afterFileNameChange}
              allowedExtension={[".jpg", ".jpeg", ".png", ".pdf"]}
              readOnly={!checkdPermission}
              hideFileType
              fileType={state?.fileType}
              showFilesAndInput
            />
          </>
        }
      />
    </>
  );
};

export default SamplesForm;
