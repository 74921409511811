import React, { useState } from "react";
import { Divider, Paper, Spinner } from "@scrapadev/scrapad-front-sdk";
import OpportunityInfoHeader from "./widgets/OpportunityInfoHeader";
import OpportunityInfoBottom from "./widgets/OpportunityInfoBottom";
import OpportunityInfoData from "./widgets/OpportunityInfoData";

function OpportunityInfoComponent({ loading, state, id }) {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Paper whiteMode style={{ padding: "15px", alignItems: "stretch" }}>
      <OpportunityInfoHeader
        state={state}
        onClick={toggleDetails}
        isOpen={showDetails}
        id={id}
        style={{ margin: "0 -10px -10px" }}
      />
      <Divider />
      {showDetails && (
        <>
          <OpportunityInfoData state={state} />
          <Divider />
        </>
      )}

      <OpportunityInfoBottom state={state} />
    </Paper>
  );
}

function OpportunityInfo({ state = {}, loading, id }) {
  return <OpportunityInfoComponent state={state} loading={loading} id={id} />;
}

export default OpportunityInfo;
