import { ClearButton, Icon, Row } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import { useTranslation } from "../../../utils/hooks";
import styles from "./AttachmentButton.module.scss";

function AttachmentButton({
  onClick = () => {},
  label,
  count = 0,
  hideCounter = false,
  style = {},
}) {
  const { t } = useTranslation(["common"]);

  return (
    <ClearButton onClick={onClick} style={style}>
      <Row gap={7} align="center" justify="flex-start">
        <Icon name="attach_file" color="#9E9E9E" style={{ fontSize: "20px" }} />
        <span className={styles["label"]}>
          {label ? label : t("attachment-files")}
          {!hideCounter && <> ({count})</>}
        </span>
      </Row>
    </ClearButton>
  );
}

export default AttachmentButton;

