import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "../../../utils/hooks";
import Icon from "../icon/Icon";
import styles from "./BackButton.module.scss";

function BackButton({ name = "keyboard_backspace", text }) {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "login", "home"]);

  if (!text) {
    text = t("back", { ns: "common" });
  }

  return (
    <div
      className={`${styles["sc-back-button"]}`}
      onClick={() => {
        navigate(-1);
      }}
    >
      <div className={`${styles["sc-back-button__icon"]}`}>
        <Icon name={name} />
      </div>
      <div className={`${styles["sc-back-button__text"]}`}>{text}</div>
    </div>
  );
}

export default BackButton;
