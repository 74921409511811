import { actions } from "../../../providers/data/actions/data";
import { useDataProvider } from "../../../providers/data/DataContext";
import Chips from "../chip/Chips";

const AdsSelected = ({ skipRenderMaterial = true, skipRenderType }) => {
  const { state, dispatch } = useDataProvider();

  const handleRemoveMaterial = (index) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        materials: {
          ...state?.materials,
          material: {
            ...state?.materials?.material,
            uuidSelected: state?.materials?.material.uuidSelected.filter(
              (f, idx) => index !== idx
            ),
            nameSelected: state?.materials?.material.nameSelected.filter(
              (f, idx) => index !== idx
            ),
            values: [],
          },
          type: {
            ...state?.materials?.type,
            open: false,
          },
        },
      },
    });
  };

  const handleRemoveType = (index) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        materials: {
          ...state?.materials,
          type: {
            ...state?.materials?.type,
            uuidSelected: state?.materials?.type.uuidSelected.filter(
              (f, idx) => index !== idx
            ),
            nameSelected: state?.materials?.type.nameSelected.filter(
              (f, idx) => index !== idx
            ),
            values: state?.materials?.type.values.filter(
              (f, idx) => index !== idx
            ),
          },
        },
      },
    });
  };

  const renderMaterial = () => {
    if (skipRenderMaterial) return <></>;
    return state?.materials?.material?.uuidSelected?.length > 0 &&
      state?.materials?.type?.uuidSelected?.length === 0 ? (
      <Chips
        items={state?.materials?.material?.nameSelected}
        handleRemove={handleRemoveMaterial}
      />
    ) : (
      <></>
    );
  };

  const renderType = () => {
    if (skipRenderType) return <></>;
    return state?.materials?.type?.uuidSelected?.length > 0 ? (
      <Chips
        items={state?.materials?.type?.nameSelected}
        handleRemove={handleRemoveType}
      />
    ) : (
      <></>
    );
  };

  return (
    <>
      {renderMaterial()}
      {renderType()}
    </>
  );
};

export default AdsSelected;
