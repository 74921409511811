import React from "react";
import styles from "./LinkStyled.module.scss";

function LinkStyled(props) {
  const propClassName = props.className;
  delete props.className;
  return (
    <a
      {...props}
      className={`${styles["sc-link"]} ${propClassName ? propClassName : ""}`}
    >
      {props.children}
    </a>
  );
}

export default LinkStyled;
