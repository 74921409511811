import React from "react";
import styles from "./UserCard.module.scss";
import _ from "lodash";

function UserCard({ name, avatarUrl, onRemoveAccess }) {
  return (
    <div className={styles["user_card"]}>
      <div className={styles["header"]}>
        <div className={styles["avatar"]}></div>
      </div>
      <div className={styles["body"]}>
        <div className={styles["name"]}>Lucía Paláez</div>
        {onRemoveAccess && (
          <div className={styles["actions"]}>
            <button onClick={onRemoveAccess} className={styles["action"]}>
              Quitar acceso
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserCard;

