import { ClearButton, FileIcon, Icon } from "@scrapadev/scrapad-front-sdk";
import React, { useCallback, useRef } from "react";
import styles from "./Files.module.scss";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useDrag, useDrop } from "react-dnd";
import { useTranslation } from "../../utils/hooks";

const type = "FILE";

const File = ({
  file,
  handleDelete,
  handleDrop,
  readOnly,
  index,
  moveCard,
}) => {
  const { t } = useTranslation(["common"]);
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    type: type,
    item: () => {
      return { file, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [{ handlerId }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop() {
      if (handleDrop) handleDrop();
    },
    hover(item, monitor) {
      if (readOnly) {
        return;
      }
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.file.index = hoverIndex;
    },
  });

  const openFile = (url) => {
    window.open(url, "_blank").focus();
  };

  drag(drop(ref));

  return (
    <div
      ref={ref}
      data-handler-id={handlerId}
      className={styles["sc-files--item"]}
    >
      <div
        className={styles["sc-files--file"]}
        style={{ cursor: readOnly ? "default" : "move" }}
      >
        {!readOnly && (
          <span
            style={{
              width: 20,
              height: 20,
              marginRight: 18,
            }}
          >
            <Icon
              name={"drag_indicator"}
              color={"#777986"}
              style={{ marginRight: 16, fontSize: 20 }}
            />
          </span>
        )}
        <div className={styles["sc-files--info"]}>
          <FileIcon height={30} width={31} />
          {/* <Icon
            name={"photo"}
            color={"#777986"}
            style={{ marginRight: 12, fontSize: 20 }}
          /> */}
          <p
            className={styles["name"]}
            onClick={() => openFile(file.cdn)}
            style={{ cursor: "pointer" }}
          >
            {file.name}
          </p>
        </div>
        <div className={styles["actions"]}>
          {handleDelete && (
            <ClearButton
              style={{
                display: "flex",
                gap: "5px",
              }}
              onClick={() => handleDelete(file)}
            >
              <Icon
                name={"delete"}
                color={"#9E9E9E"}
                style={{ fontSize: "18px" }}
              />
              <span style={{ textDecoration: "underline", color: "#9E9E9E" }}>
                {t("delete", { ns: "common" })}
              </span>
            </ClearButton>
          )}
        </div>
      </div>
    </div>
  );
};

function InnerFilesList({
  files = [],
  handleDelete,
  handleSort,
  handleDrop,
  readOnly,
}) {
  const moveCard = useCallback((item, dragIndex, hoverIndex) => {
    if (handleSort) handleSort(item, dragIndex, hoverIndex);
  });
  return (
    <div className={styles["sc-files--container"]}>
      {files?.map((file, index) => {
        return (
          <React.Fragment key={file?.id}>
            <File
              index={index}
              readOnly={readOnly}
              file={file}
              handleDelete={handleDelete}
              moveCard={readOnly ? undefined : moveCard}
              handleDrop={readOnly ? undefined : handleDrop}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}

const Div = ({ children }) => {
  return <div id="AdFiles">{children}</div>;
};

const FilesList = ({ ...props }) => {
  const rootElement = document.getElementById("AdFiles");
  return (
    <Div>
      {rootElement ? (
        <DndProvider backend={HTML5Backend} options={{ rootElement }}>
          <InnerFilesList {...props} />
        </DndProvider>
      ) : (
        <></>
      )}
    </Div>
  );
};

export default FilesList;
