import { flushSync } from "react-dom";
import { verifySession } from "../model/cognito";

export const gqlWrapper = async (fnData, setDataUser, signOut) => {
  const uuidUserFromLocalStorage = window.localStorage.getItem("user-uuid");
  if (!uuidUserFromLocalStorage) {
    signOut();
  } else {
    const session = await verifySession(uuidUserFromLocalStorage);
    if (session) {
      if (setDataUser) {
        flushSync(() => {
          setDataUser(session, true);
        });
      }
    } else {
      signOut();
    }
  }

  return fnData;
};
