import { toastError, toastSuccess } from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useCRUD } from "../crud/hooks";
import { useTranslation } from "../hooks";

export const useWalletActions = () => {
  const { t } = useTranslation(["common"]);
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(false);
  const [modalDescription, setModalDescription] = useState(false);
  const [okText, setOkText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState(false);
  const { CRUD } = useCRUD();
  const types = {
    APPROVE: "aprove",
    DENEGATE: "denegate",
    DELETE: "delete",
    EDIT: "edit",
  };

  const handleAprove = () => {
    setOpen(true);
    setModalTitle(t("aprove-operation-sure"));
    setModalDescription(t("cant-undo-action"));
    setOkText(t("aprove-operation"));
    setType("aprove");
  };

  const handleDenegate = () => {
    setOpen(true);
    setModalTitle(t("denegate-operation-sure"));
    setModalDescription(t("cant-undo-action"));
    setOkText(t("denegate-operation"));
    setType("denegate");
  };

  const handleEdit = () => {
    setOpen(true);
    setModalTitle(t("sure-apply-changes"));
    setModalDescription(t("need-client-consent"));
    setOkText(t("edit"));
    setType("edit");
  };

  const handleRemove = () => {
    setOpen(true);
    setModalTitle(t("sure-disable-account"));
    setModalDescription(t("sure-disable-information"));
    setOkText(t("disable"));
    setType("delete");
  };

  const handleAproveAction = async (
    uuid,
    iban_id,
    setOpen,
    fetchData,
    uuidOrg,
    loading,
    setLoading
  ) => {
    setLoading(true);
    await CRUD({
      endpoint: `org/${uuidOrg}/money-out/${uuid}`,
      method: "PUT",
      data: {
        status: "accepted",
        iban_id,
      },
      successCallback: async () => {
        toastSuccess(
          t("action-ok"),
          { toastId: "wallet_actions_success" },
          "check_circle"
        );
        await fetchData();
        setOpen(false);
        setLoading(false);
      },
      errorCallback: async () => {
        await fetchData();
        setOpen(false);
        setLoading(false);
      },
    });
  };

  const handleDenegateAction = async (
    uuid,
    iban_id,
    setOpen,
    fetchData,
    uuidOrg,
    loading,
    setLoading
  ) => {
    setLoading(true);
    await CRUD({
      endpoint: `org/${uuidOrg}/money-out/${uuid}`,
      method: "PUT",
      data: {
        status: "denied",
        iban_id,
      },
      successCallback: async () => {
        toastSuccess(
          t("action-ok"),
          { toastId: "wallet_actions_success" },
          "check_circle"
        );
        await fetchData();
        setOpen(false);
        setLoading(false);
      },
      errorCallback: async () => {
        await fetchData();
        setOpen(false);
        setLoading(false);
      },
    });
  };

  const handleRemoveAction = async (
    uuid,
    setOpen,
    fetchData,
    uuidOrg,
    loading,
    setLoading
  ) => {
    setLoading(true);
    await CRUD({
      endpoint: `org/${uuidOrg}/bank-account-out/${uuid}`,
      method: "PUT",
      data: {
        status: "disable",
      },
      successCallback: async () => {
        toastSuccess(
          t("action-ok"),
          { toastId: "wallet_actions_success" },
          "check_circle"
        );
        setOpen(false);
        setLoading(false);
        fetchData();
      },
    });
  };

  return {
    open,
    setOpen,
    modalTitle,
    setModalTitle,
    modalDescription,
    setModalDescription,
    okText,
    setOkText,
    loading,
    setLoading,
    handleAprove,
    handleDenegate,
    type,
    setType,
    types,
    handleAproveAction,
    handleDenegateAction,
    handleEdit,
    handleRemove,
    handleRemoveAction,
  };
};

/**
 * Custom hook to toggle money out account approved state.
 * @returns {{handleApproveAccount: (uuid:string, disable: boolean, fetchData: Function)}}
 */
export const useWalletApproveAccount = () => {
  const { t } = useTranslation(["common"]);
  const { CRUD } = useCRUD();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const handleApproveAccount = async (uuid, disable, fetchData) => {
    setLoading(true);
    await CRUD({
      endpoint: `org/${id}/bank-account-out/${uuid}`,
      method: "PUT",
      data: {
        disable,
      },
      successCallback: async () => {
        toastSuccess(
          t("action-ok"),
          { toastId: "wallet_approve_success" },
          "check_circle"
        );
        await fetchData();
        setLoading(false);
      },
      errorCallback: async (error) => {
        toastError(error, { toastId: "wallet_approve_error" }, "check_circle");
        setLoading(false);
      },
    });
  };

  return {
    loading,
    setLoading,
    handleApproveAccount,
  };
};
