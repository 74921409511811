import {
  BREAKPOINT_LG,
  Container,
  Flex,
  Icon,
  Modal,
  toastError,
  toastSuccess,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useUser } from "../../../providers/AuthHooks";
import { useCRUD } from "../../../utils/crud/hooks";
import { useTranslation } from "../../../utils/hooks";
import {
  formEvents,
  TEXT_COLOR_LIGHT,
  transportCostStates,
} from "../../../utils/variables";
import { buildFormTransportCost } from "../../forms/transaction/functions";
import ModalText from "../../ui/modal/text/ModalText";
import Text from "../../ui/text/Text";

const ModalIncorporateTransportProvider = ({ open, setOpen, ...props }) => {
  const { id } = useParams();
  const { t } = useTranslation(["logistics", "common"]);
  const { CRUD } = useCRUD();
  const { rowQuotation, fetchData, uuidQuote, previuosQuotation } = props;
  const [loading, setLoading] = useState();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const user = useUser();

  const handleSubmit = async () => {
    setLoading(true);
    const errorCallback = (error) => {
      toastError(error);
      setLoading(false);
    };

    const dataToBuild = {
      suplier: rowQuotation?.current?.suplier,
      suplierUuid: null,
      untilDate: rowQuotation?.current?.untilDate * 1,
      state: transportCostStates.LAUNCHED,
      numLote: rowQuotation?.current?.numLote,
      unitPrice: rowQuotation?.current?.unitPrice,
      uuidEvent: id,
    };

    if (rowQuotation?.current?.supplierUuid) {
      dataToBuild.suplierUuid = rowQuotation?.current?.supplierUuid;
    }

    const data = buildFormTransportCost(
      user.userUuid,
      formEvents.UPDATE_LOGISTIC,
      dataToBuild
    );

    await CRUD({
      external: true,
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      data,
      method: "POST",
      successCallback: async () => {
        if (previuosQuotation) {
          await CRUD({
            endpoint: `logistics/quotation/${previuosQuotation}`,
            data: {
              status: "received",
            },
            method: "PUT",
            errorCallback: errorCallback,
          });
        }
        await CRUD({
          endpoint: `logistics/quotation/${uuidQuote}`,
          data: {
            status: "selected",
          },
          method: "PUT",
          successCallback: async () => {
            await fetchData();
            setLoading(false);
            setOpen(false);
            toastSuccess(t("incorporate-ok"));
          },
          errorCallback: errorCallback,
        });
      },
      errorCallback: errorCallback,
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t("incorporate-sure")}
      cancelText={t("cancel")}
      submitText={t("incorporate-transaction")}
      modalStyles={{ width: matches ? "auto" : 800 }}
      hasForm
      loading={loading}
      onSubmit={handleSubmit}
      BodyComponent={
        <Container style={{ margin: "0 -12px" }}>
          <div className="row">
            <div className="col-12">
              <ModalText
                style={{ marginBottom: 25 }}
                text={t("incorporate-warning")}
              />
              <Flex justifyContent={"flex-start"} style={{ marginBottom: 10 }}>
                <Icon
                  name="info"
                  color={TEXT_COLOR_LIGHT}
                  style={{ fontSize: 16, marginRight: 6 }}
                />
                <Text style={{ fontSize: 13, lineHeight: "21px" }} bold>
                  {t("incorporate-need")}
                </Text>
              </Flex>
              <Text style={{ fontSize: 12, lineHeight: "18px" }}>
                {t("incorporate-dont-1")}{" "}
                <strong>{t("incorporate-dont-2")}</strong>{" "}
                {t("incorporate-dont-3")}
              </Text>
            </div>
          </div>
        </Container>
      }
    />
  );
};

export default ModalIncorporateTransportProvider;
