import React from "react";
import styles from "./KanbanWrap.module.scss";

function KanbanWrap({ paper, children }) {
  return (
    <div className={`${styles["kanban_wrap"]} ${paper && styles["paper"]}`}>
      <div className={styles["inner"]}>{children}</div>
    </div>
  );
}

export default KanbanWrap;

