import axios from "axios";
import { flushSync } from "react-dom";
import { verifySession } from "../model/cognito";

const statusCodes = {
  SUCCESS: 200,
  REDIRECTION: 300,
  CLIENT_ERROR: 400,
  SERVER_ERROR: 500,
};

export const postClient = async ({
  url,
  body,
  language,
  method = "POST",
  localhost = false,
  external = false,
  extraHeaders = {},
  avoidAuth,
  avoidParsing,
  signOut,
  setDataUser,
}) => {
  const uuidUserFromLocalStorage = window.localStorage.getItem("user-uuid");
  if (!uuidUserFromLocalStorage) {
    signOut();
  } else {
    const session = await verifySession(uuidUserFromLocalStorage);
    if (session) {
      if (setDataUser) {
        flushSync(() => {
          setDataUser(session, true);
        });
      }
    } else {
      signOut();
    }
  }

  let headers = {
    "Content-Type": "application/json",
  };

  const aud = localStorage.getItem("aud");
  const userUuid = localStorage.getItem("user-uuid");
  const idToken = localStorage.getItem(
    `CognitoIdentityServiceProvider.${aud}.${userUuid}.idToken`
  );

  if (!avoidAuth) {
    headers["Authorization"] = `Bearer ${idToken}`;
  }

  if (language) {
    headers["Accept-Language"] = language;
  }
  headers = { ...headers, ...extraHeaders };
  const JSONdata = avoidParsing ? body : JSON.stringify(body);

  const response = await axios({
    method: method,
    url:
      localhost || external ? url : process.env.REACT_APP_MARKETPLACE_URL + url,
    headers: headers,
    data: JSONdata,
  });
  let error = new Error();
  let result = {};
  let responseError = {
    type: "Error",
    message: "SC500",
    data: { error: "SC500" },
    code: "SC500",
  };

  try {
    result = response.json ? await response.json() : response.data;
  } catch (e) {
    console.log(e);
  }
  if (
    response.status >= statusCodes.SUCCESS &&
    response.status < statusCodes.REDIRECTION
  ) {
    return result;
  }
  responseError = {
    type: "Error",
    message: result.message || "SC500",
    data: result.data || { error: "SC500" },
    code: result.code || "SC500",
  };
  error = { ...error, ...responseError };
  throw error;
};
