import { useTranslation } from "../../../utils/hooks";
import { useDeleteOpportunity } from "../../../utils/opportunities/hooks";
import { modalTypes } from "../../../utils/variables";
import ModalFactory from "../../ui/modal/ModalFactory";

/**
 * Modal for deleting an opportunity
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @param {{data: {key: string}}} props.data - Opportunity uuid.
 * @returns {JSX.Element}
 */
const ModalDeleteOpportunity = ({ ...props }) => {
  const { t } = useTranslation(["common"]);
  const { loading, handleDeleteOpportunity } = useDeleteOpportunity();
  const mergedProps = {
    ...{
      open: false,
      setOpen: () => {},
      data: { key: "" },
      successCallback: () => {},
    },
    ...props,
  };
  return (
    <ModalFactory
      {...props}
      slug={"finishOpportunity"}
      type={modalTypes.USER_WARNING}
      title={t("sure-delete-opportunity")}
      text={t("sure-delete-opportunity_desc")}
      cancelText={t("cancel")}
      submitText={t("continue")}
      submitStyles={
        loading
          ? {
              paddingRight: 70,
            }
          : {}
      }
      reverseActions={false}
      submitButtonMode={"info"}
      loading={loading}
      onSubmit={(key) => {
        handleDeleteOpportunity(
          key,
          mergedProps.setOpen,
          mergedProps.successCallback
        );
      }}
    />
  );
};

export default ModalDeleteOpportunity;
