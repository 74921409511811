import {
  BREAKPOINT_LG,
  Container,
  Flex,
  Icon,
  Modal,
  Row,
  Typography,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import { WARM_NEUTRAL_50 } from "../../../utils/variables";

const ModalAddQuotationWarn = ({
  open,
  setOpen,
  isEdit,
  onSubmit,
  ...props
}) => {
  const [loading, setLoading] = useState();
  const { t } = useTranslation(["logistics", "common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        setLoading(false);
      }}
      title={t("launch_quotation_modal_title")}
      cancelText={t("cancel")}
      submitText={t("send_quotation")}
      modalStyles={{ width: matches ? "auto" : 900 }}
      hasForm
      onSubmit={() => {
        if (typeof onSubmit === "function") {
          onSubmit();
        }
      }}
      loading={loading}
      actionsMode="end"
      submitButtonMode="info"
      BodyComponent={
        <Container style={{ margin: "0 -12px" }}>
          <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ gap: "15px" }}
          >
            <Typography type="2" style={{ margin: 0 }}>
              {t("launch_quotation_modal_desc_1")}
            </Typography>
            <Row gap={5}>
              <Icon
                name={"help_outline"}
                style={{ fontSize: "16px" }}
                color={WARM_NEUTRAL_50}
              />{" "}
              <Typography
                type="2"
                style={{
                  margin: 0,
                  fontSize: "13px",
                  fontWeight: 500,
                  color: WARM_NEUTRAL_50,
                }}
              >
                {t("launch_quotation_modal_desc_2")}
              </Typography>
            </Row>
            <Typography
              type="2"
              color={WARM_NEUTRAL_50}
              style={{
                margin: 0,
                fontSize: "12px",
                lineHeight: "18px",
                color: WARM_NEUTRAL_50,
              }}
            >
              {t("launch_quotation_modal_desc_3")}{" "}
              <strong>{t("launch_quotation_modal_desc_3_1")}</strong>{" "}
              {t("launch_quotation_modal_desc_3_2")}
            </Typography>
          </Flex>
        </Container>
      }
    />
  );
};

export default ModalAddQuotationWarn;

