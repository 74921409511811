import { useState } from "react";
import Spinner from "../spinner/Spinner";
import { csvLookUp, exportCSV, getAFileName } from "../../../utils/functions";
import { useTranslation } from "../../../utils/hooks";
import { IconButton } from "@scrapadev/scrapad-front-sdk";

/**
 * Export table a csv component.
 * @param {Object} props - Component properties.
 * @param {{ header: Array, body: Array }} props.data - Table data.
 * @param {React.CSSProperties} props.style - Button styles
 * @returns {JSX.Element}
 */
const ExportTable = ({ data, style, fileName }) => {
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();
  const handleDownloadTable = () => {
    setLoading(true);
    const headers = data.header.map((h) => h.name);
    const rows = [
      headers,
      ...data.body.map((b) =>
        b.cells.map((cell) => csvLookUp(cell.format, cell.value, i18n))
      ),
    ];
    exportCSV(getAFileName(fileName || "business"), rows);
    setLoading(false);
  };
  return (
    <>
      {loading ? (
        <Spinner size={24} />
      ) : (
        <IconButton
          name={"file_download"}
          onClick={handleDownloadTable}
          style={
            style || {
              width: 45,
              height: 45,
              background: "#F5F5F5",
              border: "0.5px solid #E1E1E1",
              borderRadius: "50%",
              color: "#7E7E7E",
              margin: 0,
            }
          }
          iconStyle={{ fontSize: 18 }}
        />
      )}
    </>
  );
};

export default ExportTable;

