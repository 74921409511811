import { Flex, Icon } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import Text from "../text/Text";

const InfoText = ({ text, containerStyle = {} }) => {
  return (
    <Flex justifyContent={"flex-start"} style={containerStyle}>
      <Icon
        name="info"
        color="#EA7A47"
        style={{ marginRight: 6, fontSize: 14 }}
      />
      <Text
        style={{
          color: "#EA7A47",
          fontSize: 10,
          lineHeight: "15px",
        }}
      >
        {text}
      </Text>
    </Flex>
  );
};

export default InfoText;
