import { Container } from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import Steps from "../../components/ui/steps/Steps";
import LayoutDetail from "../../layout/detail/Detail";
import { getBusinessAddresses, getHeaderInfo } from "../../model/business";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { useDocumentTitle } from "../../utils/document/hooks";
import { useSteps, useTranslation } from "../../utils/hooks";
import WidgetAddressesPickDelivery from "../../widgets/business/addresses/Delivery";
import WidgetDataWrapper from "../../widgets/wrappers/DataWrapper";
import HistoryBlockWrapper from "../../wrappers/HistoryBlockWrapper";

const BusinessAddresses = () => {
  const { id } = useParams();
  const { t, i18n, values } = useTranslation(["common"]);
  const navigate = useNavigate();
  const [isDirty, setIsDirty] = useState(false);
  useDocumentTitle(`${t("addresses")}`, values);
  const { state } = useHeaderProvider(i18n.language, id, getHeaderInfo);
  const { steps } = useSteps(id);
  const { canReadAddress, canManageAddress } = useOperationsProvider();

  const submitCallback =
    (modalOpen, setModalOpen) => (e, loading, setLoading) => {
      setModalOpen(false);
    };

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor={"#fff"}
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header
                  title={state?.basicData?.orgName}
                  goBack
                  breadcrumbs={[
                    {
                      description: t("business"),
                      onClick: () => navigate("/business", { replace: true }),
                    },
                    {
                      description: state?.basicData?.orgName,
                      onClick: () =>
                        navigate(`/business/${id}`, { replace: true }),
                    },
                    { description: t("addresses") },
                  ]}
                  fullSubHeaderLeft
                  subHeaderLeft={
                    <Steps
                      steps={steps}
                      currentStep={"addresses"}
                      checkCurrentByLabel
                    />
                  }
                  lowerLeftStyles={{ marginBottom: 0 }}
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <HistoryBlockWrapper
            mustBlockLeaving={isDirty}
            submitCallback={submitCallback}
          >
            <div>
              <Container>
                <div className="row">
                  <div className="col-12">
                    <WidgetDataWrapper
                      id={id}
                      fnData={getBusinessAddresses}
                      fnParameters={{
                        locale: i18n.language,
                        module: "locations",
                        org: id,
                      }}
                      Component={WidgetAddressesPickDelivery}
                      updateStateCallback={(state, isDirty) =>
                        setIsDirty(isDirty)
                      }
                      extraData={{
                        permissionManage: canManageAddress,
                        permissionRead: canReadAddress,
                      }}
                    />
                  </div>
                </div>
              </Container>
            </div>
          </HistoryBlockWrapper>
        }
      />
    </>
  );
};

export default BusinessAddresses;
