import React from "react";
import styles from "./Container.module.scss";

function Container({
  isFluid = false,
  zeroPadding = false,
  children,
  style = {},
}) {
  const containerClass = isFluid ? "container-fluid" : "container";

  return (
    <div
      className={`${containerClass} ${styles["sc-container"]}`}
      style={{ padding: zeroPadding ? 0 : "", ...style }}
    >
      {children}
    </div>
  );
}

export default Container;
