import { TableManager } from "@scrapadev/scrapad-front-sdk";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { usePaginationProvider } from "../../providers/pagination/PaginationContext";
import { useSortProvider } from "../../providers/sort/SortContext";
import { useAdActions } from "../../utils/ads/hooks";
import { useTranslation } from "../../utils/hooks";
import { applySortToFilters } from "../../utils/tables/functions";
import { useTableActions } from "../../utils/tables/hooks";
import {
  mapSlugToChip,
  mapSlugToChipDouble,
  modalTypes,
  tableModules,
  TABLE_LIMIT,
  transactionTypeInfo,
} from "../../utils/variables";

const WidgetAdsTable = ({
  data,
  setData,
  dataBuffer,
  loading,
  transactionsOpen,
  setTransactionsOpen,
  setAdUuid,
  fetchData,
  adsProps = {},
  uuidOrg,
  permission,
}) => {
  const { t, i18n } = useTranslation(["common", "transactions", "ads"]);
  const aUuid = useRef(null);
  const {
    renderActions,
    titleMessage,
    bodyMessage,
    okMessage,
    open,
    setOpen,
    type,
    handleEditAction,
    handleCloneAction,
    handleUnpublishAction,
    handlePublishAction,
    handleBlockAction,
    handleUnBlockAction,
    handleRestoreAction,
    handleRemovedAction,
    handleDemandCoveredAction,
  } = useAdActions({
    id: aUuid.current,
  });
  const { buildFiltersAndFetch, getStoredFilters } = useFiltersProvider();
  const [adStatus, setAdStatus] = useState(null);
  const navigate = useNavigate();
  const [transactionsActive, setTransactionsActive] = useState(0);

  const { getStoredPaginationIfBackPressed, handlePagination } =
    usePaginationProvider();
  const { state: sortState, handleSorting, buildSort } = useSortProvider();
  const sortFields = buildSort(sortState);

  useTableActions({
    paginationCallback: (paginationState) => {
      const newFilters = buildFiltersAndFetch(
        getStoredFilters() || {},
        undefined,
        uuidOrg
          ? [
              {
                field: "byOrgId",
                value: uuidOrg,
              },
            ]
          : undefined
      );
      fetchData({
        locale: i18n.language,
        limit: TABLE_LIMIT,
        offset: paginationState?.current,
        tableModule: tableModules.ADS,
        filter: applySortToFilters(
          newFilters,
          sortFields,
          "REACT_APP_SORT_ADS"
        ),
      });
    },
  });

  return (
    <>
      <TableManager
        loading={loading}
        data={data}
        setData={setData}
        emptyText={t("empty-transactions")}
        rowTriggerPageChange={true}
        navigate={(uuid) => {
          navigate(`/ads/${uuid}`);
        }}
        actions={
          permission
            ? permission()
              ? renderActions(adStatus, transactionsActive)
              : undefined
            : renderActions(adStatus, transactionsActive)
        }
        onClickActionCallback={(uuid, index, row) => {
          const statusCell = row?.cells?.find((f) => f.colRef === "status");
          if (statusCell) {
            const status = statusCell?.value?.split("#");
            setAdStatus(status[0]);
            setTransactionsActive(status[1]);
            aUuid.current = uuid;
          }
        }}
        itemsProps={{
          date: {
            languageData: i18n.getLanguageVariable(i18n.language),
          },
          split: {
            mapTransactionValues: transactionTypeInfo,
            translate: (code) => t(code),
          },
          chip: {
            mapSlugValues: mapSlugToChip,
            translate: (code) => t(code),
          },
          chip_double: {
            mapChipDoubleValues: mapSlugToChipDouble,
            translate: (code) => t(code),
            rightChipClick: (uuid, value, col) => {
              setTransactionsOpen(true);
              setAdUuid(uuid);
            },
          },
        }}
        externalSort={handleSorting}
        sorting={buildSort(sortState)}
        pagination={{
          currentPage: Math.ceil(data?.pagination?.current / TABLE_LIMIT),
          totalPages: Math.ceil(data?.pagination?.total / TABLE_LIMIT),
          threshold: 3,
          onChangePage: handlePagination,
          onChangePrev: handlePagination,
          onChangeNext: handlePagination,
        }}
        {...adsProps}
      />
      <ModalFactory
        key={aUuid.current}
        type={modalTypes.ADS_ACTIONS}
        open={open}
        setOpen={setOpen}
        titleMessage={titleMessage}
        bodyMessage={bodyMessage}
        okMessage={okMessage}
        adUuid={aUuid.current}
        handleEditAction={handleEditAction}
        handleCloneAction={handleCloneAction}
        handleUnpublishAction={handleUnpublishAction}
        handlePublishAction={handlePublishAction}
        handleBlockAction={handleBlockAction}
        handleUnBlockAction={handleUnBlockAction}
        handleRestoreAction={handleRestoreAction}
        handleDemandCoveredAction={handleDemandCoveredAction}
        handleRemovedAction={handleRemovedAction}
        typeAd={type}
        fetchData={() => {
          fetchData({
            locale: i18n.language,
            limit: TABLE_LIMIT,
            offset: getStoredPaginationIfBackPressed()?.current,
            tableModule: tableModules.ADS,
            filter: applySortToFilters(
              { ops: buildFiltersAndFetch(getStoredFilters() || {}) },
              sortFields,
              "REACT_APP_SORT_ADS"
            ),
          });
        }}
      />
    </>
  );
};

export default WidgetAdsTable;
