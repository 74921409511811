import React, { useEffect, useRef, useState } from "react";
import styles from "./SortingMenu.module.scss";
import { useOutsideAlerter } from "../../../utils/hooks";
import MenuItem from "../menu/MenuItem";
import { Icon } from "@scrapadev/scrapad-front-sdk";

export const SortingMenuTypes = {
  ASC: "ASC",
  DESC: "DESC",
  DATE_ASC: "DATE_ASC",
  DATE_DESC: "DATE_DESC",
};

function SortingMenu({
  open = false,
  onChange = () => {},
  onClickOutside,
  selfManaged,
  defaultSort,
}) {
  const [isOpen, setIsOpen] = useState(open);
  const [selectedSort, setSelectedSort] = useState(defaultSort);
  const wrapperRef = useRef(null);
  useOutsideAlerter(
    wrapperRef,
    onClickOutside ? onClickOutside : () => setIsOpen(false)
  );

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    onChange(selectedSort);
    setTimeout(() => {
      setIsOpen(false);
    }, 10);
  }, [selectedSort]);

  const autoManagedOnClick = () => {
    setIsOpen(!isOpen);
  };

  const renderSelected = () => {
    switch (selectedSort) {
      case SortingMenuTypes.ASC:
        return <SelectedItemComponent icon={"straight"} text={"Nombre A-Z"} />;
      case SortingMenuTypes.DESC:
        return (
          <SelectedItemComponent
            icon={"straight"}
            inversed={true}
            text={"Nombre Z-A"}
          />
        );
      case SortingMenuTypes.DATE_ASC:
        return (
          <SelectedItemComponent icon={"straight"} text={"Fecha de creación"} />
        );
      case SortingMenuTypes.DATE_DESC:
        return (
          <SelectedItemComponent
            icon={"straight"}
            inversed={true}
            text={"Fecha de creación"}
          />
        );
      default:
        return (
          <SelectedItemComponent
            icon={"sort_by_alpha"}
            inversed={false}
            text={"Selecciona"}
          />
        );
    }
  };

  return (
    <div className={styles["sorting_menu_wrap"]}>
      <div
        className={styles["sorting_menu_selector"]}
        onClick={autoManagedOnClick}
      >
        {renderSelected()}
      </div>
      {isOpen ? (
        <div ref={wrapperRef} className={styles["sorting_menu"]}>
          <div className={styles["inner"]}>
            <MenuItem
              icon={"straight"}
              onClick={() => {
                setSelectedSort(SortingMenuTypes.ASC);
              }}
            >
              Nombre A-Z
            </MenuItem>
            <MenuItem
              icon={"straight"}
              iconStyles={{
                transform: "rotate(180deg)",
              }}
              onClick={() => {
                setSelectedSort(SortingMenuTypes.DESC);
              }}
            >
              Nombre Z-A
            </MenuItem>
            <MenuItem
              icon={"straight"}
              onClick={() => {
                setSelectedSort(SortingMenuTypes.DATE_ASC);
              }}
            >
              fecha de creación
            </MenuItem>
            <MenuItem
              icon={"straight"}
              iconStyles={{
                transform: "rotate(180deg)",
              }}
              onClick={() => {
                setSelectedSort(SortingMenuTypes.DATE_DESC);
              }}
            >
              fecha de creación
            </MenuItem>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function SelectedItemComponent({ icon, text, inversed }) {
  return (
    <div
      className={`${styles["selector"]} ${inversed ? styles["inversed"] : ""}`}
    >
      <Icon name={icon} />
      <span className={styles["selector_label"]}>{text}</span>
    </div>
  );
}

export default SortingMenu;
