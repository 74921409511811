import { Center, Empty } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";
import WidgetWalletAccount from "./Account";
import WidgetWalletAccountsEdit from "./Edit";

const WidgetWalletEnterAccounts = ({ state }) => {
  const { t } = useTranslation(["common"]);

  if (!state?.moneyInAccount)
    return (
      <Center>
        <Empty
          spacerHeight={10}
          heading={t("not-accounts-saved")}
          icon="wallet"
          iconWidth={175}
          iconHeight={175}
        />
      </Center>
    );

  return (
    <>
      <WidgetWalletAccount
        swift={state?.moneyInAccount?.bicCode}
        iban={state?.moneyInAccount?.viban}
        address={state?.moneyInAccount?.address}
        files={state?.moneyInAccount?.urlVIBANUser}
        holder={state?.holder}
      />
    </>
  );
};

export default WidgetWalletEnterAccounts;
