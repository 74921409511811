import {
  Icon,
  ClearButton,
  Content,
  IconItem,
  useMatchMedia,
  BREAKPOINT_LG,
} from "@scrapadev/scrapad-front-sdk";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import Separator from "../../../components/ui/separator/Separator";
import { renderComponentWithPermission } from "../../../utils/functions";
import { useClickOnBody, useTranslation } from "../../../utils/hooks";
import { TEXT_COLOR_LIGHT } from "../../../utils/variables";
import styles from "./Detail.module.scss";

const TransactionDetailHeader = ({
  uuidEvent,
  title,
  subtitle,
  fetchData,
  permission,
  permissionLogistics,
  onlyMenu,
}) => {
  const { t } = useTranslation(["common", "transactions", "logistics"]);
  const navigate = useNavigate();
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const subMenuRef = useRef(null);
  const [openFinish, setOpenFinish] = useState(false);
  useClickOnBody(subMenuRef, () => setOpenSubMenu(false));
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const renderDropdown = () => {
    let items = [];
    if (JSON.parse(process.env.REACT_APP_FEATURE_EXPORT_TRANSACTION_DETAILS))
      items.push({
        component: (
          <ClearButton
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Icon name={"file_download"} color={"#7E7E7E"} />
            {t("history-download")}
          </ClearButton>
        ),
      });
    items.push({
      component: (
        <ClearButton
          onClick={(e) => {
            e.stopPropagation();
            setOpenFinish(true);
          }}
        >
          <Icon name={"cancel"} color={"#7E7E7E"} />
          {t("end-transaction")}
        </ClearButton>
      ),
    });
    return items;
  };
  return (
    <div className={styles["sc-transaction--detail-header"]}>
      <>
        {!onlyMenu && (
          <>
            <IconItem
              name="folder"
              text={t("transaction-documents")}
              buttonStyle={{ marginRight: matches ? 10 : 0, textAlign: "left" }}
              textStyle={{
                textDecoration: "underline",
                color: TEXT_COLOR_LIGHT,
                textDecoration: "underline",
              }}
              iconStyle={{ marginRight: 0 }}
              onClick={() =>
                navigate(`documents`, {
                  state: { uuidEvent, title, subtitle },
                })
              }
            />
            <Separator style={{ backgroundColor: "#fff" }} space={10} />
            <IconItem
              name="point_of_sale"
              text={t("costs")}
              buttonStyle={{ marginRight: matches ? 10 : 0, textAlign: "left" }}
              textStyle={{
                textDecoration: "underline",
                color: TEXT_COLOR_LIGHT,
                textDecoration: "underline",
              }}
              iconStyle={{ marginRight: 0 }}
              onClick={() =>
                navigate(`costs`, {
                  state: { uuidEvent, title, subtitle },
                })
              }
            />
            {JSON.parse(process.env.REACT_APP_QUOTATIONS) &&
              renderComponentWithPermission(
                permissionLogistics,
                <>
                  <Separator />
                  <IconItem
                    name="local_shipping"
                    text={t("transport-quotes")}
                    textStyle={{
                      textDecoration: "underline",
                      color: TEXT_COLOR_LIGHT,
                      textDecoration: "underline",
                    }}
                    buttonStyle={{
                      marginRight: matches ? 10 : 0,
                      textAlign: "left",
                    }}
                    iconStyle={{ marginRight: 0 }}
                    onClick={() =>
                      navigate(`transport`, {
                        state: { uuidEvent, title, subtitle },
                      })
                    }
                  />
                </>
              )}
            <Separator style={{ backgroundColor: "#fff" }} space={10} />
          </>
        )}
      </>

      {renderComponentWithPermission(
        permission,
        <>
          <ClearButton
            onClick={(e) => setOpenSubMenu(!openSubMenu)}
            style={{ marginLeft: matches ? "auto" : 0 }}
          >
            <div ref={subMenuRef} style={{ height: 24 }}>
              <Icon name={"more_vert"} color={"#7E7E7E"} />
              {openSubMenu && (
                <Content
                  position="absolute"
                  style={{
                    top: 30,
                    right: 10,
                    zIndex: 2,
                  }}
                  items={renderDropdown()}
                />
              )}
            </div>
          </ClearButton>
        </>
      )}

      <ModalFactory
        type="end-transaction"
        open={openFinish}
        setOpen={setOpenFinish}
        uuidEvent={uuidEvent}
        fetchData={fetchData}
      />
    </div>
  );
};

export default TransactionDetailHeader;
