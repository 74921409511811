import React, { useState } from "react";
import { useTranslation } from "../../../../utils/hooks";
import {
  Alert,
  BLUE_60,
  BREAKPOINT_LG,
  Center,
  ERROR_COLOR,
  Flex,
  Heading,
  Icon,
  Modal,
  Padding,
  Row,
  Spinner,
  TextArea,
  toastError,
  Typography,
  useMatchMedia,
  WARM_NEUTRAL_20,
} from "@scrapadev/scrapad-front-sdk";
import TransactionFiles from "../../../../components/ui/input/file/File";
import { clientTypes, formEvents } from "../../../../utils/variables";
import _ from "lodash";
import { useCRUD } from "../../../../utils/crud/hooks";
import { useDocumentsProvider } from "../../DocumentsContext";
import { gqlWrapper } from "../../../../utils/gqlwrapper";
import { getTransactionDataBO } from "../../../../model/transactions";
import { useSetDataUser, useSignOut, useUser } from "../../../AuthHooks";
import { getOrgBuyerOrgSellerFromTransactionDetail } from "../../../../utils/functions";

function ModalDocumentShare({
  open = false,
  setOpen,
  clientType = clientTypes.BUYER,
  files = [],
  bulkSelected = [],
  filesTree = {},
  ...props
}) {
  const { CRUD } = useCRUD();
  const user = useUser();
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();
  const { state, reloadData } = useDocumentsProvider();
  const { t, i18n } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState();
  const filesInner = _.uniq([...files, ...bulkSelected]);
  const [filesComplete, setFilesComplete] = useState(
    _.cloneDeep(filesTree?.files?.filter((f) => filesInner?.includes(f?.id)))
  );
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState();

  const afterFileNameChange = (newFiles) => {
    setFilesComplete(newFiles);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (_.isEmpty(files)) {
      setErrors("files");
      return;
    }

    if (!_.isEmpty(state?.breadcrumbs) && _.isEmpty(message)) {
      setErrors("message");
      return;
    }

    let rootFolder = "";
    if (clientType === clientTypes.BUYER) {
      rootFolder = state?.data?.subfolders?.find(
        (e) => e.owner === clientTypes.BUYER
      );
    } else {
      rootFolder = state?.data?.subfolders?.find(
        (e) => e.owner === clientTypes.SELLER
      );
    }

    setLoading(true);
    await CRUD({
      external: false,
      data: {
        id: filesComplete?.map((e) => e?.id),
        names: filesComplete?.map((e) => e?.name),
        destination: rootFolder?.id,
      },
      endpoint: `integration/share`,
      method: "PUT",
      successCallback: async () => {
        if (!_.isEmpty(message)) {
          setLoadingMessage(t("sending_message"));
          const fn = await gqlWrapper(
            getTransactionDataBO,
            setDataUser,
            signOut
          );
          const results = await fn(i18n.language, state?.transactionUuid);
          const { uuidSeller, uuidBuyer } =
            getOrgBuyerOrgSellerFromTransactionDetail(results?.sideData);
          let destinationsOrg = [];
          switch (clientType) {
            case clientTypes.BUYER:
              destinationsOrg = [uuidBuyer];
              break;
            case clientTypes.SELLER:
              destinationsOrg = [uuidSeller];
              break;
            default:
              break;
          }
          await CRUD({
            external: true,
            endpoint: `${process.env.REACT_APP_API_EVENTS}`,
            data: {
              uuidUser: user.userUuid,
              destinationsOrg: destinationsOrg,
              content: message,
              slug: formEvents.SEND_MD,
              uuidEvent: state?.transactionUuid,
            },
            method: "POST",
            successCallback: () => {
              reloadData(500);
              setLoadingMessage(undefined);
              setOpen(false);
            },
            errorCallback: async (e) => {
              setLoadingMessage(undefined);
              setLoading(false);
              throw new Error(e);
            },
          });
        } else {
          setOpen(false);
          setLoadingMessage(undefined);
          setLoading(false);
        }
      },
      errorCallback: async (e) => {
        setLoading(false);
        toastError(`Error: ${e?.message}`);
      },
    });

    if (props.submitCallback) props.submitCallback(e, loading, setLoading);
  };

  return (
    <>
      <Modal
        t={t}
        open={open}
        setOpen={setOpen}
        title={t(
          clientType === clientTypes.BUYER
            ? "share_with_buyer"
            : "share_with_seller"
        )}
        hasForm={true}
        onCancel={props.onCancel}
        onSubmit={handleSubmit}
        submitText={t("share")}
        cancelText={t("cancel")}
        actionsMode="end"
        submitProps={{
          ultrawide: true,
          style: {
            width: "195px",
          },
        }}
        cancelProps={{
          ultrawide: true,
          style: {
            width: "195px",
          },
        }}
        loading={loading}
        maxWidth={900}
        modalStyles={{ width: matches ? "auto" : 900 }}
        BodyComponent={
          <>
            <div>
              <Flex
                flexDirection="column"
                alignItems="stretch"
                style={{ gap: "15px" }}
              >
                <Alert type="info" style={{ margin: 0 }}>
                  <Row gap={10} align="center">
                    <Icon name={"info"} />
                    <span>
                      {clientType === clientTypes.BUYER
                        ? t("share_disclaimer_buyer")
                        : t("share_disclaimer_seller")}
                    </span>
                  </Row>
                </Alert>
                <TransactionFiles
                  files={filesComplete}
                  readOnly={false}
                  hideFileType={true}
                  showFilesAndInput={false}
                  hideFileInput
                  forceShowFileList
                  afterFileNameChange={afterFileNameChange}
                />
                {errors === "files" && (
                  <Typography style={{ color: ERROR_COLOR }}>
                    {t("required-field", { ns: "common" })}
                  </Typography>
                )}
                <div>
                  <Heading type={7} style={{ margin: 0 }}>
                    {t("add_message")}
                  </Heading>
                  <Typography small style={{ marginBottom: "15px" }}>
                    {t("add_message_warn")}
                  </Typography>
                  <TextArea
                    required
                    placeholder={t("write_message_here")}
                    onChange={(e) => {
                      setMessage(e?.target?.value);
                    }}
                  />
                  {errors === "message" && (
                    <Typography style={{ color: ERROR_COLOR }}>
                      {t("must_write_message_for_client", { ns: "common" })}
                    </Typography>
                  )}
                </div>
              </Flex>
            </div>
          </>
        }
      />
      {!_.isEmpty(loadingMessage) && (
        <Modal
          t={t}
          open={true}
          setOpen={() => {}}
          title={""}
          hasForm={false}
          hasActions={false}
          closeStyles={{ display: "none" }}
          modalStyles={{
            backgroundColor: WARM_NEUTRAL_20,
          }}
          BodyComponent={
            <>
              <Center>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Padding>
                    <Spinner size={75} color={BLUE_60} />
                  </Padding>
                  <Typography>{loadingMessage}</Typography>
                </Flex>
              </Center>
            </>
          }
        />
      )}
    </>
  );
}

export default ModalDocumentShare;
