import styles from "./SendAnswer.module.scss";
import { Modal } from "@scrapadev/scrapad-front-sdk/dist/index";
import ModalText from "../../ui/modal/text/ModalText";
import { useTranslation } from "../../../utils/hooks";
import { useState } from "react";
import TextArea from "../../ui/input/textarea/TextArea";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import { buildFormReply } from "../../forms/transaction/functions";
import TransactionFiles from "../../ui/input/file/File";
import { postClient } from "../../../utils/restclient";
import { handleFormOK } from "../../../utils/functions";
import { toastError } from "@scrapadev/scrapad-front-sdk";

/**
 * Modal for sending a response to a log message.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalSendAnswer = ({ open = false, setOpen, ...props }) => {
  const [message, setMessage] = useState("");
  const { t } = useTranslation(["transactions", "common"]);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!props.uuidEvent) return;
    setLoading(true);
    const data = buildFormReply(
      user.userUuid,
      props.uuidEvent,
      props.uuidMessageToReply,
      message,
      files
    );
    try {
      await postClient({
        external: true,
        url: `${process.env.REACT_APP_API_EVENTS}`,
        method: "POST",
        body: data,
        signOut,
        setDataUser,
      });
      handleFormOK(t, props.fetchData);
    } catch (error) {
      toastError(error.message);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };
  const afterUpload = (files) => setFiles(files);
  const afterDelete = (files) => setFiles(files);

  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("send-answer")}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("send")}
      cancelText={t("cancel")}
      loading={loading}
      BodyComponent={
        <div className={styles["sc-transactions--delete"]}>
          <ModalText text={t("send-answer-desc")} />
          <TransactionFiles
            files={files}
            placeholder={t("select-drop-files")}
            dropPlaceholder={t("drop-files")}
            afterUpload={afterUpload}
            afterDelete={afterDelete}
            allowedExtension={[".jpg", ".jpeg", ".png", ".pdf"]}
          />
          <TextArea
            required
            placeholder={t("write-here")}
            onChange={(e) => setMessage(e.target.value)}
          >
            {message}
          </TextArea>
        </div>
      }
    />
  );
};

export default ModalSendAnswer;
