import {
  BREAKPOINT_MD,
  GREEN_COLOR_100,
  Switch,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import { useVerificationChangeStatus } from "../../../utils/business/hooks";
import { useTranslation } from "../../../utils/hooks";
import {
  modalTypes,
  orgStatus,
  verificationTypes,
} from "../../../utils/variables";

const CompleteVerificationValidation = ({ state, fetchData, id }) => {
  const { t } = useTranslation("verification");
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);
  const [open, setOpen] = useState(false);
  const { handleChangeVerificationStatus } = useVerificationChangeStatus();
  const checked =
    state?.getOrgData?.verificationState === orgStatus.KYC_COMPLETE;

  return (
    <div style={matches ? { marginTop: 8 } : {}}>
      <Switch
        isLabelInLeft
        label={t("complete-verification-active")}
        labelStyle={{
          color: "#777986",
          fontSize: 14,
          lineHeight: "21px",
        }}
        checked={checked}
        onChange={() => {
          setOpen(true);
        }}
        inputStyle={
          checked
            ? {
                color: GREEN_COLOR_100,
                backgroundColor: GREEN_COLOR_100,
              }
            : {}
        }
      />
      <ModalFactory
        type={modalTypes.VERIFICATION_WARNING}
        open={open}
        setOpen={setOpen}
        checked={checked}
        title={
          checked
            ? t("about-disable-complete-verification")
            : t("about-enable-complete-verification")
        }
        bodyText={
          checked
            ? t("disable-complete-verification-text")
            : t("enable-complete-verification-text")
        }
        handleSubmit={(loading, setLoading) => {
          handleChangeVerificationStatus(
            id,
            verificationTypes.COMPLETE,
            !checked,
            fetchData,
            loading,
            setLoading
          );
        }}
      />
    </div>
  );
};

export default CompleteVerificationValidation;
