import { Flex, Loading } from "@scrapadev/scrapad-front-sdk";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { checkPermission } from "../../utils/functions";
import { parseTabNotifications } from "../../utils/opportunities/functions";
import { INFO_COLOR } from "../../utils/variables";

/**
 * Helper widget for tab title and notification badget.
 * @param {object} props - Component properties.
 * @param {string} props.slug - Tab slug, for testing purposes.
 * @param {string} props.title - Tab title.
 * @param {number} props.notifications - Amount of news.
 * @param {boolean} props.loading - Loading state.
 * @returns {JSX.Element}
 */
const WidgetOpportunitiesTabTitle = ({
  slug,
  title,
  notifications,
  loading,
}) => {
  const mergedNotifications = parseTabNotifications(notifications);
  const { canManageFinances, canManageLogistics } = useOperationsProvider();
  const permission =
    checkPermission(canManageFinances) || checkPermission(canManageLogistics);

  const render = (badge) => {
    return (
      <Flex style={{ gap: 10 }}>
        <>{title}</>
        {badge}
      </Flex>
    );
  };

  return render(
    loading ? (
      <Flex style={{ width: 10, height: 10, marginLeft: 10 }}>
        <Loading />
      </Flex>
    ) : true ? (
      <Flex style={{ gap: 5 }} data-cy={slug}>
        {mergedNotifications.news > 0 ? (
          <span
            style={{
              padding: "1px 5px",
              backgroundColor: INFO_COLOR,
              color: "#fff",
              width: 12,
              height: 13,
              borderRadius: 10,
              boxSizing: "content-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 10,
              fontWeight: 600,
            }}
          >
            {mergedNotifications.news}
          </span>
        ) : (
          <></>
        )}
        {mergedNotifications.pendingCosts > 0 && permission ? (
          <span
            style={{
              padding: "1px 5px",
              backgroundColor: "#ECBE30",
              color: "#fff",
              width: 12,
              height: 13,
              borderRadius: 10,
              boxSizing: "content-box",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 10,
              fontWeight: 600,
            }}
          >
            {mergedNotifications.pendingCosts}
          </span>
        ) : (
          <></>
        )}
      </Flex>
    ) : (
      <></>
    )
  );
};

export default WidgetOpportunitiesTabTitle;
