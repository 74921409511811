import {
  BREAKPOINT_LG,
  Button,
  Container,
  Flex,
  MiniTabs,
  Spacer,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import Steps from "../../components/ui/steps/Steps";
import LayoutDetail from "../../layout/detail/Detail";
import {
  getFavouritesSearchs,
  getFavoriteMaterials,
  getFavoriteAds,
  getHeaderInfo,
} from "../../model/business";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { renderComponentWithPermission } from "../../utils/functions";
import { useSteps, useTranslation } from "../../utils/hooks";
import WidgetFavouritesAds from "../../widgets/business/favourites/Ads";
import WidgetFavouritesMaterials from "../../widgets/business/favourites/Materials";
import WidgetFavouritesSearchs from "../../widgets/business/favourites/Searchs";
import WidgetDataWrapper from "../../widgets/wrappers/DataWrapper";

const Favourites = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation(["common"]);
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const [openModalMaterial, setOpenModalMaterial] = useState(false);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const { state } = useHeaderProvider(i18n.language, id, getHeaderInfo);
  const { canManageFavs, canReadFavs } = useOperationsProvider();
  const { steps } = useSteps(id);

  const handleAddMaterial = () => {
    setOpenModalMaterial(!openModalMaterial);
  };

  const renderTabs = () => {
    let tabs = [];
    tabs.push({
      tab: <>{t("ads")}</>,
      panel: (
        <WidgetDataWrapper
          id={id}
          fnData={getFavoriteAds}
          fnParameters={{
            locale: i18n.language,
            org: id,
          }}
          Component={WidgetFavouritesAds}
        />
      ),
    });
    if (
      JSON.parse(process.env.REACT_APP_FAVORITES_SHOW_VIEW_ADS_STORED_SEARCHS)
    ) {
      tabs.push({
        tab: <>{t("searchs")}</>,
        panel: (
          <WidgetDataWrapper
            id={id}
            fnData={getFavouritesSearchs}
            fnParameters={{
              locale: i18n.language,
              org: id,
            }}
            Component={WidgetFavouritesSearchs}
          />
        ),
      });
    }
    tabs.push({
      tab: <>{t("materials")}</>,
      panel: (
        <WidgetDataWrapper
          id={id}
          fnData={getFavoriteMaterials}
          fnParameters={{
            locale: i18n.language,
            org: id,
          }}
          Component={WidgetFavouritesMaterials}
          extraData={{
            openModalMaterial,
            setOpenModalMaterial,
            permission: canManageFavs,
          }}
        />
      ),
    });
    return tabs;
  };

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header
                  title={state?.basicData?.orgName}
                  goBack
                  breadcrumbs={[
                    {
                      description: t("business"),
                      onClick: () => navigate("/business", { replace: true }),
                    },
                    {
                      description: state?.basicData?.orgName,
                      onClick: () =>
                        navigate(`/business/${id}`, { replace: true }),
                    },
                    { description: t("favourites") },
                  ]}
                  subHeaderLeft={
                    <Steps
                      steps={steps}
                      currentStep={"favorites"}
                      checkCurrentByLabel
                    />
                  }
                  fullSubHeaderLeft
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Flex justifyContent={"flex-end"}>
                  {currentTab === 1 &&
                    renderComponentWithPermission(
                      canManageFavs,
                      <Button
                        component="button"
                        type="button"
                        mode="outline-success"
                        text={t("add-favorite-material")}
                        onClick={handleAddMaterial}
                        style={{
                          marginTop: -32,
                          marginBottom: matches ? 24 : 0,
                        }}
                      />
                    )}
                </Flex>
              </div>
              <div className="col-12">
                <MiniTabs
                  tabs={renderTabs()}
                  currentTab={currentTab}
                  changeTab={(index) => setCurrentTab(index)}
                  spacer={<Spacer height={25} />}
                  tabsProps={{
                    style: { marginTop: currentTab === 2 ? 0 : -24 },
                  }}
                />
              </div>
            </div>
          </Container>
        }
      />
    </>
  );
};

export default Favourites;

