export const getRoomSlug = (url) => {
  const defaultRoom = "notification";
  if (!url) return defaultRoom;
  const mapped = {
    "/transactions": WS_ROOM.table_transaction,
    "/opportunities": WS_ROOM.opportunity,
    "/opportunities_created": WS_ROOM.opportunity_created,
    "/opportunities_participating": WS_ROOM.opportunity_participating,
  };
  return mapped[url] || defaultRoom;
};

export const WS_ROOM = {
  table_transaction: "table_transaction",
  transaction: "transaction",
  organization: "organization",
  notification: "notification",
  opportunity: "opportunity",
  opportunity_created: "opportunity_created",
  opportunity_participating: "opportunity_participating",
};

export const WS_SLUGS = {
  ACCESS_TRANSACTION: "ACCESS_TRANSACTION",
};
