import { Flex } from "@scrapadev/scrapad-front-sdk";
import React from "react";

/**
 * Helper widget to show items left and right in the costs form.
 * @param {object} props - Function properties.
 * @param {React.ReactNode} props.leftComponent - Componet to the left.
 * @param {React.ReactNode} props.rightComponent - Componet to the right.
 * @param {string} [props.alignItems="center"] - Items alignment.
 * @param {boolean} [props.isLast="false"] - Items alignment.
 * @param {React.CSSProperties} [props.style={}] - Items alignment.
 * @param {string} [props.slug] - Items alignment.
 * @returns {JSX.Element}
 */
const WidgetOpportunitiesFormItem = ({
  leftComponent,
  rightComponent,
  alignItems = "center",
  isLast = false,
  style = {},
  slug,
}) => {
  return (
    <Flex
      data-cy={slug || undefined}
      justifyContent={"space-between"}
      alignItems={alignItems}
      style={{
        width: "100%",
        paddingTop: 12,
        paddingBottom: isLast ? 5 : 12,
        paddingLeft: 5,
        paddingRight: 5,
        borderBottom: isLast ? "none" : ".5px solid #E1E1E1",
        ...style,
      }}
    >
      {leftComponent || <></>}
      {rightComponent || <></>}
    </Flex>
  );
};

export default WidgetOpportunitiesFormItem;
