import {
  BREAKPOINT_LG,
  Container,
  Modal,
  toastSuccess,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useCRUD } from "../../../utils/crud/hooks";
import { useTranslation } from "../../../utils/hooks";
import ModalText from "../../ui/modal/text/ModalText";

const ModalDeleteTransportProvider = ({ open, setOpen, ...props }) => {
  const [loading, setLoading] = useState();
  const { t } = useTranslation(["logistics", "common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const { CRUD } = useCRUD();

  const handleSubmit = async () => {
    setLoading(true);
    await CRUD({
      endpoint: `logistics/transporter/${props.uuid}`,
      method: "DELETE",
      errorCallback: () => {
        setLoading(false);
        setOpen(false);
      },
      successCallback: async () => {
        toastSuccess(
          t("removed-provider"),
          {
            toastId: "remove_transport_provider",
          },
          "delete"
        );
        setLoading(false);
        setOpen(false);
        if (props.submitCallback) props.submitCallback();
      },
    });
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t("sure-remove-provider")}
      cancelText={t("cancel")}
      submitText={t("remove-provider")}
      modalStyles={{ width: matches ? "auto" : 650 }}
      hasForm
      reverseActions
      loading={loading}
      onSubmit={handleSubmit}
      BodyComponent={
        <Container style={{ margin: "0 -12px" }}>
          <div className="row">
            <div className="col-12">
              <ModalText
                style={{ marginBottom: 0 }}
                text={t("cant-recover-provider")}
              />
            </div>
          </div>
        </Container>
      }
    />
  );
};

export default ModalDeleteTransportProvider;
