import { gql } from "@apollo/client";
import client from "../utils/gqlclient";

export async function getUserProfile() {
  const data = await client.query({
    query: gqlGetUserProfile,
  });
  return data.errors ? { errors: data.errors } : data.data.getUserProfile;
}

export async function getUserProfileForEdit() {
  const data = await client.query({
    query: gqlGetUserProfileForEdit,
  });
  return data.errors ? { errors: data.errors } : data.data.getUserProfile;
}

const gqlGetUserProfile = gql`
  query {
    getUserProfile {
      person {
        userName
        uuidPerson
        orgUuid
        lang
      }
      status
      rights {
        operation
        mode
      }
      role
    }
  }
`;

const gqlGetUserProfileForEdit = gql`
  query {
    getUserProfile {
      firstName
      lastName
      avatar
    }
  }
`;
