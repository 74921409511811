import { useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import {
  BREAKPOINT_LG,
  Flex,
  InputNumber,
  Modal,
  Typography,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import { useCRUD } from "../../../utils/crud/hooks";
import ModalText from "../../ui/modal/text/ModalText";
import { useUser } from "../../../providers/AuthHooks";
import { handleFormOK } from "../../../utils/functions";
import { buildChangeFee } from "../../forms/transaction/functions";

/**
 * Modal for changing scrapad fee.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalChangeFee = ({ open = false, setOpen, ...props }) => {
  const { CRUD } = useCRUD();
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation(["transactions", "common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const { uuidEvent, fetchData, calculatedComissionn } = props;
  const [fee, setFee] = useState(calculatedComissionn?.replace(" €", ""));

  const handleSubmit = async (e) => {
    if (!uuidEvent) return;
    const data = buildChangeFee(user.userUuid, uuidEvent, fee);
    setLoading(true);
    await CRUD({
      external: true,
      data,
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      successCallback: async () => {
        await fetchData();
        setLoading(false);
        handleFormOK(t, fetchData);
      },
    });
  };

  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("fee-change")}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("save-changes")}
      cancelText={t("cancel")}
      loading={loading}
      maxWidth={850}
      modalStyles={{ width: matches ? "auto" : 850 }}
      BodyComponent={
        <Flex flexDirection="column" alignItems={"flex-start"}>
          <ModalText text={t("new-fee")} style={{ marginBottom: 20 }} />
          <Typography type="2" style={{ marginBottom: 20 }}>
            <strong>{t("calculated-comission")}</strong> {calculatedComissionn}
          </Typography>
          <Typography
            type="2"
            style={{
              fontWeight: 500,
              marginBottom: 12,
            }}
          >
            {t("new-fee-comission")}
          </Typography>
          <InputNumber
            required
            placeholder={t("write-here")}
            min={1}
            step="0.01"
            value={fee}
            onChange={(e) => {
              setFee(e.target.value);
            }}
          />
        </Flex>
      }
    />
  );
};

export default ModalChangeFee;
