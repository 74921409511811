import { toastError, toastSuccess } from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { getCatalogNoTerm } from "../../model/filters";
import { useUser } from "../../providers/AuthHooks";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { useCRUD } from "../crud/hooks";
import { useWhiteDateFilter, useWhiteMaterialsFilter } from "../filters/hooks";
import { checkPermission } from "../functions";
import { useGQL } from "../gql/hooks";
import { useTranslation } from "../hooks";
import { buildCOnvertToTransaction } from "./functions";
import { buildFinishOpportunity, buildMarkReadOpportunity } from "./functions";
import { adTypeFilterItems } from "../filters/variables";

/**
 * Hook to delete an opportunity
 * @param {Function} fetchData - Fetch data callback
 * @returns {{loading: boolean, setLoading: () => boolean, handleDeleteOpportunity: Function}}
 */
export const useDeleteOpportunity = () => {
  const { t } = useTranslation(["common"]);
  const [loading, setLoading] = useState(false);
  const { CRUD } = useCRUD();
  const user = useUser();

  const handleDeleteOpportunity = async (uuid, setOpen, successCallback) => {
    if (!uuid || !setOpen || !successCallback) return;
    setLoading(true);
    const data = buildFinishOpportunity(uuid, user.userUuid);
    await CRUD({
      external: true,
      data,
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      errorCallback: (e) => {
        toastError(e);
        setOpen(false);
        setLoading(false);
      },
      successCallback: async () => {
        toastSuccess(t("action-ok"));
        await successCallback();
        setOpen(false);
        setLoading(false);
      },
    });
  };

  return {
    loading,
    setLoading,
    handleDeleteOpportunity,
  };
};

/**
 * Hook to withdraw from an opportunity
 * @param {Function} fetchData - Fetch data callback
 * @returns {{loading: boolean, setLoading: () => boolean, handleDeleteOpportunity: Function}}
 */
export const useWithdrawOpportunity = () => {
  const [loading, setLoading] = useState(false);
  const { CRUD } = useCRUD();

  const handleWithdrawOpportunity = async (uuid, setOpen, fetchData) => {
    if (!uuid || !setOpen || !fetchData) return;
    setOpen(false);
  };

  return {
    loading,
    setLoading,
    handleWithdrawOpportunity,
  };
};

/**
 * Custom hook for common filters in opportunities.
 * @returns {{ materialsFilter: object, dateFilterCreated: object, orgData: {uuid: string, name: string}, methodLoading: boolean }}
 */
export const useOpportunitiesBaseFilters = () => {
  const { t, i18n } = useTranslation(["common"], true);
  const materialsFilter = useWhiteMaterialsFilter("materials", t("material"));
  const dateFilterCreated = useWhiteDateFilter("createdAt", t("creation-date"));
  const { canManageLogistics, canManageFinances } = useOperationsProvider();
  const permission =
    checkPermission(canManageLogistics) || checkPermission(canManageFinances);
  const { data: orgData, loading: methodLoading } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: permission ? "org-autocomplete" : "org-bo-asigned",
  });

  return {
    materialsFilter,
    dateFilterCreated,
    orgData,
    methodLoading,
    canManageLogistics,
    canManageFinances,
    permission,
  };
};

/**
 * Custom hook for by me filters.
 * @returns {deps: [], initialFilters: { materials: object, organization: object, createdAt: object }}
 */
export const useByMeFilters = () => {
  const { t } = useTranslation(["common"], true);
  const { materialsFilter, dateFilterCreated, orgData, methodLoading } =
    useOpportunitiesBaseFilters();

  return {
    deps: [orgData],
    initialFilters: {
      organization: {
        label: t("organization"),
        items: orgData?.errors ? [] : orgData,
      },
      opportunityType: {
        label: t("ad-type"),
        items: adTypeFilterItems.map((type) => ({
          uuid: type.uuid,
          name: t(type.name),
        })),
      },
      materials: materialsFilter,
      createdAt: dateFilterCreated,
    },
  };
};

/**
 * Custom hook for participating filters.
 * @returns {deps: [], initialFilters: { materials: object, organization: object, createdAt: object, createdBy: object }}
 */
export const useParticipatingFilters = () => {
  const { t, i18n } = useTranslation(["common"], true);
  const { data: createdByData, loading: createdByLoading } = useGQL(
    getCatalogNoTerm,
    {
      locale: i18n.language,
      name: "users-bo",
    }
  );
  const {
    materialsFilter,
    dateFilterCreated,
    orgData,
    methodLoading,
    permission,
  } = useOpportunitiesBaseFilters();

  const filters = {};
  if (permission) {
    filters.organization = {
      label: t("organization"),
      items: orgData,
    };
  }
  filters.opportunityType = {
    label: t("ad-type"),
    items: adTypeFilterItems.map((type) => ({
      uuid: type.uuid,
      name: t(type.name),
    })),
  };
  filters.materials = materialsFilter;
  filters.createdAt = dateFilterCreated;
  filters.createdBy = {
    label: t("creator"),
    items: createdByData?.errors ? [] : createdByData,
  };

  return {
    deps: permission ? [orgData, createdByData] : [createdByData],
    initialFilters: filters,
  };
};

/**
 * Custom hook for new opportunities filters.
 * @returns {deps: [], initialFilters: { materials: object, organization: object, createdAt: object, createdBy: object }}
 */
export const useNewFilters = () => {
  return useParticipatingFilters();
};

/**
 * Custom hook to get opportunities badges.
 * @returns {{ notifications: number, setNotifications: (n: number) => void, loading: boolean, setLoading: (b: boolean) => void, handleGetNotifications: Function, }}
 */
export const useOpportunitiesNotifications = () => {
  const [notifications, setNotifications] = useState(0);
  const [loading, setLoading] = useState(false);
  const { CRUD } = useCRUD();

  const handleGetNotifications = async () => {
    setNotifications([
      { news: 1, pendingCosts: 0 },
      { news: 0, pendingCosts: 0 },
      { news: 1, pendingCosts: 2 },
    ]);
  };

  return {
    notifications,
    setNotifications,
    loading,
    setLoading,
    handleGetNotifications,
  };
};

export const useOpportunitiesTabTitle = () => {
  const {
    notifications,
    setNotifications,
    loading,
    setLoading,
    handleGetNotifications,
  } = useOpportunitiesNotifications();

  useEffect(() => {
    handleGetNotifications();
  }, []);

  return {
    notifications,
    setNotifications,
    loading,
    setLoading,
    handleGetNotifications,
  };
};

/**
 * Custom hook to convert an opportunity to transaction.
 * @returns {{ handleConvert: (uuidOffer: string, fetchData: Function, setLoading: (b: boolean) => void) => void, }}
 */
export const useConvertOpportunityToTransaction = () => {
  const { t } = useTranslation(["common"]);
  const { CRUD } = useCRUD();
  const user = useUser();

  const handleConvert = async ({
    uuidOffer,
    fetchData,
    setLoading,
    uuidOpportunity,
    amount,
    price,
  }) => {
    if (
      !uuidOffer ||
      !fetchData ||
      !setLoading ||
      !uuidOpportunity ||
      !amount ||
      !price
    )
      return;
    setLoading(true);
    const data = buildCOnvertToTransaction({
      uuidEvent: uuidOffer,
      uuidUser: user.userUuid,
      uuidOpportunity,
      amount,
      price,
    });
    await CRUD({
      external: true,
      data,
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      successCallback: async (e) => {
        toastSuccess(t("action-ok"));
        await fetchData(e);
        setLoading(false);
      },
    });
  };

  return {
    handleConvert,
  };
};

/**
 * Custom hook to mark an opportunity as read.
 * @returns {{ handleMarkReadOpportunity: Function, }}
 */
export const useMarkReadOpportunity = () => {
  const { CRUD } = useCRUD();
  const user = useUser();

  const checkIfMustMarkAsRead = (uuid, row) => {
    const notifyCell = row?.cells?.find((f) => f?.format === "notify");
    if (notifyCell) {
      const value = notifyCell?.value?.split("#");
      if (value[0] === "true" || value[0] === true || value[0] === "pending") {
        handleMarkReadOpportunity(uuid);
      }
    }
  };

  const handleMarkReadOpportunity = async (uuid) => {
    if (!uuid) return;
    const data = buildMarkReadOpportunity(uuid, user.userUuid);
    await CRUD({
      external: true,
      data,
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      errorCallback: (e) => {
        toastError(e);
      },
    });
  };

  return {
    checkIfMustMarkAsRead,
    handleMarkReadOpportunity,
  };
};
