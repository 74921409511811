import { Dropdown, Loading } from "@scrapadev/scrapad-front-sdk";
import { useEffect, useRef, useState } from "react";
import { getCatalog } from "../../../../model/filters";
import { useGQL } from "../../../../utils/gql/hooks";
import { useTranslation } from "../../../../utils/hooks";

const InputActivity = ({ value, handleItemClick, ...props }) => {
  const { t, i18n } = useTranslation(["common"]);
  const [innerValue, setInnerValue] = useState("");
  const firstRender = useRef(true);
  const { data, setData, loading } = useGQL(getCatalog, {
    locale: i18n.language,
    name: "activities",
  });

  useEffect(() => {
    if (!firstRender.current) {
      setInnerValue(data?.find((f) => f.uuid === value)?.name);
    }
  }, [data, value]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, []);

  const handleClick = (option, open, setOpen) => {
    if (props.readOnly) return;
    setData([...data]); // Little dirty hack to show again all option un filtered.
    handleItemClick(option, open, setOpen);
  };

  return loading ? (
    <Loading />
  ) : (
    <Dropdown
      position="fixed"
      value={[innerValue]}
      options={data}
      handleItemClick={handleClick}
      placeholder={t("select-value")}
      model={{ text: "name", uuid: "uuid" }}
      readOnly={loading}
      disableEnterText={false}
      handleOnChange={(e) => setInnerValue(e.target.value)}
      {...props}
    />
  );
};

export default InputActivity;
