import React, { useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useHistoryListen } from "../../utils/hooks";

const BackNavigationContext = React.createContext({});

/**
 * Provider to handle back button history.
 * @param {props} props - Component properties.
 * @param {React.ReactNode[]} children - Children items.
 * @returns {JSX.Element}
 */
export const BackNavigationProvider = ({ children }) => {
  const isFromBackButton = useRef(false);
  const location = useLocation();

  useEffect(() => {
    if (isFromBackButton.current) {
      isFromBackButton.current = false;
    }
  }, [location]);

  useHistoryListen((history, loc) => {
    if (history.action === "POP") {
      isFromBackButton.current = true;
    }
  });

  return (
    <BackNavigationContext.Provider value={isFromBackButton}>
      {children}
    </BackNavigationContext.Provider>
  );
};

export const useBackNavigationProvider = () =>
  useContext(BackNavigationContext);
