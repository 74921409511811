import { ClearButton, Box } from "@scrapadev/scrapad-front-sdk";
import styles from "./CardBox.module.scss";

/**
 * UI Cardbox component.
 * @param {Object} props - Component properties.
 * @param {String} props.title - Card title.
 * @param {String} props.action - Action text.
 * @param {Function} props.handleAction - Callback to handle card action.
 * @param {Boolean} [props.zeroPaddingBottom=false] - Set padding bottom to 0.
 * @returns {JSX.Element}
 */
const CardBox = ({
  title,
  action,
  handleAction,
  zeroPaddingBottom = false,
  hasOverflow = true,
  maxHeight = 300,
  children,
}) => {
  const paddingBottom = zeroPaddingBottom === true ? { paddingBottom: 0 } : {};
  const scroll = hasOverflow ? { overflowY: "auto", maxHeight: maxHeight } : {};
  return (
    <Box
      style={{
        paddingTop: 25,
        paddingLeft: 20,
        paddingRight: 20,
        height: "100%",
        ...paddingBottom,
      }}
    >
      <div className={styles["sc-cardbox"]}>
        <div className={styles["sc-cardbox--header"]}>
          {title && <h3 className={styles["sc-cardbox--title"]}>{title}</h3>}
          {action && handleAction && (
            <ClearButton type="button" onClick={handleAction}>
              <span className={styles["sc-cardbox--action"]}>{action}</span>
            </ClearButton>
          )}
        </div>
        <div className={styles["sc-cardbox--content"]} style={scroll}>
          {children}
        </div>
      </div>
    </Box>
  );
};

export default CardBox;
