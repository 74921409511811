import {
  BREAKPOINT_LG,
  Flex,
  InputText,
  Modal,
  toastError,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import React, { useState } from "react";
import { useTranslation } from "../../../../utils/hooks";
import { useCRUD } from "../../../../utils/crud/hooks";
import { useDocumentsProvider } from "../../DocumentsContext";
import {
  getFileExtension,
  getFileNameWithoutExtension,
} from "../../../../utils/functions";

function ModalDocumentRename({
  open = false,
  setOpen,
  formerName = "",
  uuid,
  ...props
}) {
  const { CRUD } = useCRUD();
  const { t } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(getFileNameWithoutExtension(formerName));
  const { reloadData } = useDocumentsProvider();
  const extension = getFileExtension(formerName);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await CRUD({
      external: false,
      data: {
        name: `${name}.${extension}`,
        id: uuid,
      },
      endpoint: `integration/rename`,
      method: "PUT",
      successCallback: async () => {
        reloadData();
        setLoading(false);
        setOpen(false);
      },
      errorCallback: async (e) => {
        setLoading(false);
        toastError(`Error: ${e?.message}`);
      },
    });

    if (props.submitCallback) props.submitCallback(e, loading, setLoading);
  };

  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("rename_file")}
      hasForm={true}
      onCancel={props.onCancel}
      onSubmit={handleSubmit}
      submitText={t("save")}
      cancelText={t("cancel")}
      actionsMode="end"
      submitProps={{
        ultrawide: true,
        style: {
          width: "195px",
        },
      }}
      cancelProps={{
        ultrawide: true,
        style: {
          width: "195px",
        },
      }}
      loading={loading}
      maxWidth={650}
      modalStyles={{ width: matches ? "auto" : 650 }}
      BodyComponent={
        <>
          <div>
            <Flex
              flexDirection="row"
              alignItems="stretch"
              style={{ gap: "15px" }}
            >
              <InputText
                required
                placeholder={t("write-here")}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <div style={{ width: "auto" }}>
                <InputText
                  required
                  placeholder={t("write-here")}
                  value={extension}
                  disabled
                  readOnly
                />
              </div>
            </Flex>
          </div>
        </>
      }
    />
  );
}

export default ModalDocumentRename;
