import {
  ClearButton,
  Icon,
  Row,
  Typography,
} from "@scrapadev/scrapad-front-sdk";
import { useNavigate } from "react-router-dom";
import { useMatchMedia, useTranslation } from "../../../utils/hooks";
import { BREAKPOINT_SM } from "../../../utils/variables";

const NotificationsPreferencesLink = () => {
  const { t } = useTranslation("notifications");
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_SM}px)`);
  const navigate = useNavigate();
  const buttonStyles = matches
    ? {
        width: 46,
        height: 32,
        background: "#E1E1E1",
        border: "0.5px solid #E0E2EB",
        borderRadius: 25,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }
    : {};
  return (
    <ClearButton onClick={() => navigate("/notifications/preferences")}>
      <Row gap={10} align="center" style={{ curor: "pointer" }}>
        <Icon
          name="settings"
          color="#777986"
          style={{
            fontSize: "18px",
            ...buttonStyles,
          }}
        />
        {!matches && (
          <Typography
            style={{
              marginBottom: 0,
              fontSize: 16,
              lineHeight: "19px",
              color: "#7E7E7E",
            }}
          >
            {t("preferences")}
          </Typography>
        )}
      </Row>
    </ClearButton>
  );
};

export default NotificationsPreferencesLink;
