import styles from "./Add.module.scss";
import { useEffect, useState } from "react";
import { Container, Modal } from "@scrapadev/scrapad-front-sdk/dist/index";
import Label from "../../ui/input/label/Label";
import AdsCategories from "../../ui/ads/Categories";
import AdsMAterial from "../../ui/ads/Material";
import AdsType from "../../ui/ads/Type";
import AdsSelected from "../../ui/ads/Selected";
import { useTranslation } from "../../../utils/hooks";
import { useDataProvider } from "../../../providers/data/DataContext";
import AdsAdType from "../../ui/ads/AdType";
import { actions } from "../../../providers/data/actions/data";
import { adsDefaults } from "../../../utils/variables";

/**
 * Modal for invite a new user to the organization
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalAddMaterial = ({ open = false, setOpen, ...props }) => {
  const { t } = useTranslation(["filters", "common", "business"]);
  const [loading, setLoading] = useState(true);
  const { state, dispatch } = useDataProvider();

  useEffect(() => {
    initialLoad();
    setLoading(false);
  }, []);

  const initialLoad = () => {
    dispatch({
      type: actions.INITIAL_LOAD,
      payload: {
        materials: {
          category: adsDefaults,
          material: adsDefaults,
          type: adsDefaults,
          adType: { sale: false, purchase: false },
        },
      },
    });
  };

  const handleSetOpen = () => {
    initialLoad();
    setOpen(false);
  };

  const handleSubmit = async (e) => {
    if (props.onSubmit) props.onSubmit(state, loading, setLoading);
    handleSetOpen();
  };

  return (
    <Modal
      open={open}
      setOpen={handleSetOpen}
      title={t("add-favorite-material")}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("add")}
      cancelText={t("cancel")}
      submitDisabled={state?.materials?.material?.uuidSelected.length === 0}
      loading={loading}
      maxWidth={900}
      BodyComponent={
        <div className={styles["sc-materials--add"]}>
          <Container>
            <div className="row">
              <div className="col-12">
                <Label style={{ color: "#626262" }}>
                  {t("category") + " *"}
                </Label>
                <AdsCategories />
              </div>
              {state?.materials?.material?.open && (
                <>
                  <div className="col-12">
                    <Label style={{ color: "#626262" }}>
                      {t("material") + " *"}
                    </Label>
                    <AdsMAterial />
                  </div>
                </>
              )}
              {state?.materials?.type?.open && (
                <>
                  <div className="col-12">
                    <Label style={{ color: "#626262" }}>{t("type")}</Label>
                    <AdsType />
                  </div>
                </>
              )}
              <div className="col-12">
                <AdsSelected />
              </div>
              <div className="col-12">
                <AdsAdType />
              </div>
            </div>
          </Container>
        </div>
      }
    />
  );
};

export default ModalAddMaterial;
