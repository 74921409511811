import React from "react";
import styles from "./Padding.module.scss";

function Padding({ children, style = {} }) {
  return (
    <div className={`${styles["sc-padding"]}`} style={style}>
      {children}
    </div>
  );
}

export default Padding;
