import { useState } from "react";
import { useTranslation } from "../../../../utils/hooks";
import {
  Alert,
  BREAKPOINT_LG,
  Flex,
  Heading,
  Icon,
  Modal,
  Row,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import TransactionFiles from "../../../../components/ui/input/file/File";
import UserAccessSelector from "../../../../components/ui/input/user_access_input/UserAccessSelector";

/**
 * Modal for show a warning when data needs to be saved at leaving the page.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalManageAccess = ({ open = false, setOpen, ...props }) => {
  const { t } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (props.submitCallback) props.submitCallback(e, loading, setLoading);
  };
  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("manage_access")}
      hasForm={true}
      onCancel={props.onCancel}
      onSubmit={handleSubmit}
      submitText={t("save")}
      cancelText={t("cancel")}
      loading={loading}
      actionsMode="end"
      submitProps={{
        ultrawide: true,
        style: {
          width: "195px",
        },
      }}
      cancelProps={{
        ultrawide: true,
        style: {
          width: "195px",
        },
      }}
      maxWidth={900}
      modalStyles={{ width: matches ? "auto" : 900 }}
      BodyComponent={
        <>
          <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ gap: "15px" }}
          >
            <Alert type="info" style={{ margin: 0 }}>
              <Row gap={10} align="center">
                <Icon name={"info"} />
                <span>
                  Todos los elementos de esta ubicación son visibles para el
                  comprador.
                </span>
              </Row>
            </Alert>
            <TransactionFiles
              files={files}
              readOnly={false}
              hideFileType={true}
              showFilesAndInput={false}
              hideFileInput
              forceShowFileList
            />
            <Heading type={7} style={{ margin: 0 }}>
              Quienes tienen acceso
            </Heading>
            <UserAccessSelector />
          </Flex>
        </>
      }
    />
  );
};

export default ModalManageAccess;
