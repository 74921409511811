/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Chip,
  Flex,
  InputNumber,
  Label,
  Row,
  Spacer,
  Switch,
  Typography,
} from "@scrapadev/scrapad-front-sdk";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { getMaterialQuestions } from "../../../../model/ads";
import { useGQL } from "../../../../utils/gql/hooks";
import { useTranslation } from "../../../../utils/hooks";
import { questionsTypes } from "../../../../utils/variables";
import Select from "../../select/Select";

function Questions({
  readOnly,
  type,
  stateValue = [],
  catalogName,
  onChange = () => {},
}) {
  const { i18n } = useTranslation("common");
  const { data, setData, fetchData } = useGQL(getMaterialQuestions, {}, true);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    fetchQuestions(type);
  }, [type]);

  const fetchQuestions = async (type) => {
    setloading(true);
    await fetchData({
      locale: i18n.language,
      name: catalogName,
      term: type,
    });
    setloading(false);
  };

  //console.log("QUESTIONS");
  //console.log(stateValue);
  //console.log(data);
  const mergedData = _.unionBy(stateValue, data, "uuidQuestion");
  //console.log(mergedData);
  //console.log("******************");

  const renderQuestion = (question) => {
    let result = <></>;
    switch (question?.type) {
      case questionsTypes.INTEGER:
        result = (
          <InputNumber
            readOnly={readOnly}
            value={question?.value?.[0]}
            onChange={onChange(question)}
          />
        );
        break;
      case questionsTypes.PERCENTAGE:
        result = (
          <Row align={"center"} gap={5}>
            <InputNumber
              readOnly={readOnly}
              value={question?.value?.[0]}
              onChange={onChange(question)}
            />
            <Typography style={{ margin: 0 }}>%</Typography>
          </Row>
        );
        break;
      case questionsTypes.SINGLE_CHOICE:
        result = (
          <Select
            readOnly={readOnly}
            options={question?.options?.map((o, i) => (
              <option value={o?.uuid} key={`${question?.uuid}_${i}`}>
                {o.label}
              </option>
            ))}
            value={question?.value?.[0]}
            onChange={!readOnly ? onChange(question) : undefined}
            style={{ marginBottom: "15px" }}
          />
        );
        break;
      case questionsTypes.MULTIPLE_CHOICE:
        result = (
          <Row wrap gap={15} style={{ paddingBottom: "15px" }}>
            {question?.options?.map((o, i) => (
              <Chip
                key={`${question?.uuidQuestion}_${i}`}
                text={o.label}
                big
                showIcon={
                  readOnly
                    ? false
                    : !_.isEmpty(
                        question?.value.filter((qvf) => qvf === o.uuid)
                      )
                }
                handleClick={
                  !readOnly
                    ? () => {
                        onChange(question)({
                          target: {
                            value: o.uuid,
                          },
                        });
                      }
                    : undefined
                }
                type={
                  !_.isEmpty(question?.value.filter((qvf) => qvf === o.uuid))
                    ? readOnly
                      ? "gray40"
                      : "data"
                    : "normal"
                }
              />
            ))}
          </Row>
        );
        break;
      case questionsTypes.YES_NO:
        result = (
          <Flex
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            style={{ paddingBottom: "15px" }}
          >
            <Switch
              readOnly={readOnly}
              checked={question?.value?.[0]}
              onChange={readOnly ? undefined : onChange(question)}
            />
          </Flex>
        );
        break;
    }

    return (
      <Label text={question?.label} style={{ paddingBottom: 0 }} dark bold>
        {result}
      </Label>
    );
  };

  if (!_.isEmpty(mergedData)) {
    return (
      <>
        {_.sortBy(mergedData, "uuidQuestion")?.map((d) => (
          <React.Fragment key={`Question_list_${d.uuidQuestion}`}>
            {renderQuestion(d)}
          </React.Fragment>
        ))}{" "}
        <Spacer height={25} />
      </>
    );
  }

  return <></>;
}

export default Questions;
