import {
  Alert,
  Chip,
  Container,
  DropDown,
  Flex,
  Icon,
  IconItem,
  RED_COLOR_100,
  Spinner,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/ui/header/Header";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import NavBar from "../../components/ui/navbar/NavBar";
import Separator from "../../components/ui/separator/Separator";
import LayoutDetail from "../../layout/detail/Detail";
import { getAllAdsEdit } from "../../model/ads";
import {
  getBusinessAddresses,
  getBusinessDashboardContact,
  getBusinessDashboardUsers,
  getBusinessDashboardVerification,
  getFavoriteAds,
  getFavoriteMaterials,
  getFavouritesSearchs,
  getHeaderInfo,
} from "../../model/business";
import { getOrgWalletMovements } from "../../model/wallet";
import { getTransactionsByObject } from "../../model/tables";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { parseDate } from "../../utils/date/functions";
import { useDocumentTitle } from "../../utils/document/hooks";
import { renderComponentWithPermission } from "../../utils/functions";
import { useTranslation } from "../../utils/hooks";
import {
  modalTypes,
  orgStatus,
  QUERY_UPPER_LIMIT,
  TABLE_LIMIT,
  TEXT_COLOR_LIGHT,
} from "../../utils/variables";
import WidgetBusinessAddresses from "../../widgets/business/dashboard/Addresses";
import WidgetBusinessAds from "../../widgets/business/dashboard/Ads";
import WidgetBusineesContact from "../../widgets/business/dashboard/Contact";
import WidgetBusinessFavorites from "../../widgets/business/dashboard/Favorites";
import WidgetBusinessTransactions from "../../widgets/business/dashboard/Transactions";
import WidgetBusinessUser from "../../widgets/business/dashboard/Users";
import WidgetBusinessVerification from "../../widgets/business/dashboard/Verification";
import WidgetBusinessWallet from "../../widgets/business/dashboard/Wallet";
import WidgetDataWrapper from "../../widgets/wrappers/DataWrapper";
import styles from "./BusinessDetail.module.scss";
import VerificationStatus from "./verification/Verification";
import { useGQL } from "../../utils/gql/hooks";
import { getCatalogNoTerm } from "../../model/filters";
import { useVerificationChangeStatus } from "../../utils/business/hooks";

/**
 * Business detail page
 * @returns {JSX.Element}
 */
const BusinessDetail = () => {
  const { id } = useParams();
  const { t, tMultiple, i18n, values } = useTranslation([
    "common",
    "transactions",
    "users",
  ]);
  const { data: userList, loading: userListLoading } = useGQL(
    getCatalogNoTerm,
    {
      locale: i18n.language,
      name: "users-bo",
    }
  );
  const { handleAssignedAgent } = useVerificationChangeStatus();
  const navigate = useNavigate();

  const [userSelected, setUserSelected] = useState({});
  const [blockOpen, setBlockOpen] = useState(false);
  const {
    canManageOrg,
    canManageAds,
    canReadAds,
    canReadOrg,
    canReadWallet,
    canReadUsers,
    canReadFavs,
    canReadKYC,
    canReadAddress,
    canReadTransaction,
  } = useOperationsProvider();

  useDocumentTitle(`${t("dashboard")}`, values);
  const { state, loading, setLoading, fetchData } = useHeaderProvider(
    i18n.language,
    id,
    getHeaderInfo
  );

  const renderBlockAction = () => {
    return state?.basicData?.state !== orgStatus.BANNED ? (
      <IconItem
        name={"not_interested"}
        onClick={handleBlockBusiness}
        text={t("block-business")}
        textColor={TEXT_COLOR_LIGHT}
        buttonStyle={{ whiteSpace: "nowrap" }}
        color={TEXT_COLOR_LIGHT}
      />
    ) : (
      <></>
    );
  };

  const handleBlockBusiness = () => {
    setBlockOpen(!blockOpen);
  };

  const handleChangeUser = async (e) => {
    setUserSelected(e);
    setLoading(true);
    await handleAssignedAgent({
      id: id,
      agentAssigned: e?.uuid,
      fetchData: fetchData,
      setLoading: setLoading,
    });
  };

  // Commented until crm
  //const handleAddressStateCallback = (state) => {
  //  setUsers([...state.users]);
  //  setUser({ ...state.user });
  //};

  return (
    <>
      <NavBar />
      <LayoutDetail
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header
                  loading={loading}
                  title={
                    <>
                      <span
                        style={{
                          marginRight: ".75rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {<VerificationStatus type={state?.verificationState} />}
                      </span>
                      {state?.basicData?.orgName}
                    </>
                  }
                  goBack
                  breadcrumbs={[
                    {
                      description: t("business"),
                      onClick: () => navigate("/business", { replace: true }),
                    },
                    { description: state?.basicData?.orgName },
                  ]}
                  subHeaderLeft={
                    <div className={styles["sc-business--subheader"]}>
                      {state?.basicData?.state === orgStatus.BANNED && (
                        <>
                          <Chip type="error" text={t("blocked")} />
                          <Separator />
                        </>
                      )}
                      {JSON.parse(
                        process.env.REACT_APP_DASHBOARD_USERS_CRM
                      ) && (
                        <>
                          <DropDown
                            leadingIcon={<Icon name={"support_agent"} />}
                            position="fixed"
                            placeholder={t("select-value")}
                            options={userList}
                            model={{ uuid: "uuid", text: "name" }}
                            value={
                              userList?.find(
                                (f) =>
                                  f.uuid === state?.basicData?.assignedBO?.uuid
                              )?.name
                            }
                            handleItemClick={handleChangeUser}
                            clearMargin
                            mode="rounded"
                          />

                          <Separator />
                        </>
                      )}
                      {state?.basicData?.createdDate && (
                        <span>
                          {tMultiple("member-since", [
                            parseDate(state?.basicData?.createdDate, i18n),
                          ])}
                        </span>
                      )}
                    </div>
                  }
                  subHeaderRight={
                    canManageOrg
                      ? canManageOrg()
                        ? renderBlockAction()
                        : undefined
                      : renderBlockAction()
                  }
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <>
            {loading ? (
              <>
                <Spinner />
              </>
            ) : (
              <>
                <div className={styles["sc-business--detail"]}>
                  <Container>
                    {state?.verificationState === orgStatus.CLOSED && (
                      <div className="row">
                        <div className="col-12">
                          <Alert style={{ margin: 0 }}>
                            <Flex justifyContent={"flex-start"}>
                              <Icon
                                name="info"
                                color={RED_COLOR_100}
                                style={{ marginRight: 10 }}
                              />
                              {t("closed-lemonway-account")}
                            </Flex>
                          </Alert>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      {renderComponentWithPermission(
                        canReadOrg,
                        <div className={`col-12 col-lg-6 ${styles["col-ref"]}`}>
                          <WidgetDataWrapper
                            id={id}
                            fnData={getBusinessDashboardContact}
                            fnParameters={{
                              locale: i18n.language,
                              org: id,
                            }}
                            userList={userList}
                            Component={WidgetBusineesContact}
                            updateStateCallback={(stateInner) => {
                              if (
                                state?.basicData?.assignedBO?.uuid !==
                                stateInner?.basicData?.assignedBO?.uuid
                              ) {
                                fetchData();
                              }
                            }}
                          />
                        </div>
                      )}
                      {renderComponentWithPermission(
                        canReadKYC,
                        <div className={`col-12 col-lg-6 ${styles["col-ref"]}`}>
                          <WidgetDataWrapper
                            id={id}
                            fnData={getBusinessDashboardVerification}
                            fnParameters={{
                              locale: i18n.language,
                              module: "verification",
                              org: id,
                            }}
                            Component={WidgetBusinessVerification}
                          />
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {renderComponentWithPermission(
                        canReadUsers,
                        <div className={`col-12 col-lg-6 ${styles["col-ref"]}`}>
                          <WidgetDataWrapper
                            id={id}
                            fnData={getBusinessDashboardUsers}
                            fnParameters={{
                              locale: i18n.language,
                              module: "usersByOrg",
                              org: id,
                            }}
                            Component={WidgetBusinessUser}
                          />
                        </div>
                      )}
                      {renderComponentWithPermission(
                        canReadAddress,
                        <div className={`col-12 col-lg-6 ${styles["col-ref"]}`}>
                          <WidgetDataWrapper
                            id={id}
                            fnData={getBusinessAddresses}
                            fnParameters={{
                              locale: i18n.language,
                              module: "locations",
                              org: id,
                            }}
                            Component={WidgetBusinessAddresses}
                          />
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {renderComponentWithPermission(
                        canReadFavs,
                        <div className={`col-12 ${styles["col-ref"]}`}>
                          <WidgetDataWrapper
                            id={id}
                            Component={WidgetBusinessFavorites}
                            fnData={[
                              getFavoriteAds,
                              getFavouritesSearchs,
                              getFavoriteMaterials,
                            ]}
                            fnParameters={{
                              locale: i18n.language,
                              org: id,
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="row">
                      {renderComponentWithPermission(
                        canReadWallet,
                        <div className={`col-12${styles["col-ref"]}`}>
                          <WidgetDataWrapper
                            id={id}
                            fnData={getOrgWalletMovements}
                            fnParameters={{
                              org: id,
                              limit: 3,
                            }}
                            Component={WidgetBusinessWallet}
                          />
                        </div>
                      )}
                    </div>

                    {renderComponentWithPermission(
                      canReadTransaction,
                      <div className="row">
                        <div className={`col-12 ${styles["col-ref"]}`}>
                          <WidgetDataWrapper
                            id={id}
                            fnData={getTransactionsByObject}
                            fnParameters={{
                              locale: i18n.language,
                              limit: 5,
                              filter: {
                                ops: [
                                  {
                                    field: "uuidOrg",
                                    value: id,
                                  },
                                ],
                              },
                            }}
                            Component={WidgetBusinessTransactions}
                          />
                        </div>
                      </div>
                    )}

                    <div className="row">
                      {renderComponentWithPermission(
                        canReadAds,
                        <div className={`col-12 ${styles["col-ref"]}`}>
                          <WidgetDataWrapper
                            id={id}
                            Component={WidgetBusinessAds}
                            fnData={getAllAdsEdit}
                            fnParameters={{
                              locale: i18n.language,
                              limit: 3,
                              filter: {
                                ops: [{ field: "byOrgId", value: id }],
                              },
                            }}
                            extraData={{
                              permission: canManageAds,
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Container>
                </div>
              </>
            )}

            <ModalFactory
              type={modalTypes.BLOCK_ORG_WARNING}
              id={id}
              fetchData={fetchData}
              loading={loading}
              setLoading={setLoading}
              open={blockOpen}
              setOpen={setBlockOpen}
            />
          </>
        }
      />
    </>
  );
};

export default BusinessDetail;

