import { Container, Flex, Spacer } from "@scrapadev/scrapad-front-sdk";
import React, { useState } from "react";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import LayoutDetail from "../../layout/detail/Detail";
import LayoutTable from "../../layout/table/Table";
import { getAllAdsEdit } from "../../model/ads";
import { useTableDataProvider } from "../../providers/TableContext";
import { useTranslation } from "../../utils/hooks";
import ModeSwitch from "../../widgets/fields/ModeSwitch";
import GQLFetchWrapper from "../../wrappers/FetchWrapper";
import Filters from "../../components/ui/filters/Filters";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import AdsSearchExport from "../../widgets/ads/SearchExport";
import WidgetAdsTable from "../../widgets/ads/Table";
import WidgetAdsGrid from "../../widgets/ads/Grid";
import WidgetAdsTransactions from "../../widgets/ads/Transactions";
import { getTable } from "../../model/tables";
import { usePaginationProvider } from "../../providers/pagination/PaginationContext";
import {
  ADS_LIMIT,
  QUERY_UPPER_LIMIT,
  tableModules,
  TABLE_LIMIT,
} from "../../utils/variables";
import { getAdTransactions } from "../../model/transactions";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { useDateFilter } from "../../utils/filters/hooks";
import {
  adStatusItems,
  adTypeFilterItems,
} from "../../utils/filters/variables";
import { useSortProvider } from "../../providers/sort/SortContext";
import { applySortToFilters } from "../../utils/tables/functions";

/**
 * Ads page
 * @returns {JSX.Element}
 */
const InnerAds = ({
  loading,
  state,
  dispatch,
  actions,
  bufferState,
  fetchData,
}) => {
  const { t, i18n } = useTranslation(["common", "filters", "ads"], true);
  const { getStoredFilters, buildFiltersAndFetch } = useFiltersProvider();
  const { paginationChanged } = usePaginationProvider();
  const [mode, setMode] = useState("table");
  const [transactionsOpen, setTransactionsOpen] = useState(false);
  const [adUuid, setAdUuid] = useState(null);
  const { state: sortState, buildSort } = useSortProvider();
  const {
    data,
    setData,
    dataBuffer,
    loading: tableLoading,
    fetchData: tableFetchData,
  } = useTableDataProvider();
  const { canManageAds } = useOperationsProvider();
  const filters = buildFiltersAndFetch(getStoredFilters() || {});
  const dateFilterCreated = useDateFilter("createdAt", t("creation-date"));
  const dateFilterUpdated = useDateFilter("updatedAt", t("update-date"));
  const sortFields = buildSort(sortState);

  const exportParams = {
    locale: i18n.language,
    limit: QUERY_UPPER_LIMIT,
    offset: 0,
  };

  if (mode === "table") {
    exportParams.tableModule = tableModules.ADS;
  }

  if (Object.keys(filters).length > 0) {
    exportParams.filter = filters;
  }

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#FFF"
        headerComponent={
          <Container isFluid>
            <Header
              title={t("ads")}
              titleRightComponent={
                <AdsSearchExport
                  mode={mode}
                  exportProps={{
                    fnData: mode === "table" ? getTable : getAllAdsEdit,
                    fnParameters: {
                      ...exportParams,
                      filter: applySortToFilters(
                        exportParams.filter,
                        sortFields,
                        "REACT_APP_SORT_ADS"
                      ),
                    },
                    fileName: "ads",
                  }}
                />
              }
              subHeaderLeft={
                <div>
                  <div>
                    <Filters
                      removeText={
                        mode === "grid" ? t("restore-grid") : undefined
                      }
                      initialFilters={{
                        adText: {
                          external: true,
                        },
                        adType: {
                          label: t("ad-type"),
                          multiple: true,
                          items: adTypeFilterItems.map((type) => ({
                            uuid: type.uuid,
                            name: t(type.name),
                          })),
                        },
                        createdAt: dateFilterCreated,
                        updatedAt: dateFilterUpdated,
                        status: {
                          label: t("status"),
                          multiple: true,
                          items: adStatusItems.map((status) => ({
                            uuid: status,
                            name: t(status),
                          })),
                        },
                      }}
                    />
                    <Spacer height={25} />
                  </div>
                  <Flex justifyContent={"flex-start"}>
                    <ModeSwitch
                      defaultActive={1}
                      setModeOnTable={() => {
                        setMode("table");
                        paginationChanged.current = true;
                      }}
                      setModeOnGrid={() => {
                        setMode("grid");
                        paginationChanged.current = true;
                      }}
                    />
                  </Flex>
                </div>
              }
              fullSubHeaderLeft={true}
              lowerLeftStyles={{ marginBottom: 0 }}
            />
          </Container>
        }
        bodyComponent={
          <Container isFluid>
            {mode === "grid" ? (
              <WidgetAdsGrid
                loading={loading}
                state={state}
                dispatch={dispatch}
                actions={actions}
                bufferState={bufferState}
                transactionsOpen={transactionsOpen}
                setTransactionsOpen={setTransactionsOpen}
                setAdUuid={setAdUuid}
                fetchData={fetchData}
                permission={canManageAds}
              />
            ) : (
              <WidgetAdsTable
                data={data}
                setData={setData}
                dataBuffer={dataBuffer}
                loading={tableLoading}
                transactionsOpen={transactionsOpen}
                setTransactionsOpen={setTransactionsOpen}
                setAdUuid={setAdUuid}
                fetchData={tableFetchData}
                permission={canManageAds}
              />
            )}
            {adUuid && (
              <LayoutTable
                key={adUuid}
                fnData={getAdTransactions}
                fnParameters={{ locale: i18n.language, uuid: adUuid }}
              >
                <WidgetAdsTransactions
                  key={adUuid}
                  adUuid={adUuid}
                  transactionsOpen={transactionsOpen}
                  setTransactionsOpen={setTransactionsOpen}
                />
              </LayoutTable>
            )}
          </Container>
        }
      />
    </>
  );
};

const Ads = ({}) => {
  const { i18n } = useTranslation();
  const { getStoredPaginationIfBackPressed } = usePaginationProvider();
  const { getStoredFilters, buildFiltersAndFetch } = useFiltersProvider();
  const { state: sortState, buildSort } = useSortProvider();
  const filters = buildFiltersAndFetch(getStoredFilters() || {});
  const sortFields = buildSort(sortState);
  const tableParams = {
    locale: i18n.language,
    limit: TABLE_LIMIT,
    offset: getStoredPaginationIfBackPressed()?.current
      ? getStoredPaginationIfBackPressed()?.current
      : 0,
    tableModule: tableModules.ADS,
  };
  const adsParams = {
    locale: i18n.language,
    limit: ADS_LIMIT,
    offset: getStoredPaginationIfBackPressed()?.current
      ? getStoredPaginationIfBackPressed()?.current
      : 0,
  };
  if (Object.keys(filters).length > 0) {
    tableParams.filter = filters;
    adsParams.filter = filters;
  }
  return (
    <LayoutTable
      fnData={getTable}
      fnParameters={{
        ...tableParams,
        filter: applySortToFilters(
          tableParams.filter,
          sortFields,
          "REACT_APP_SORT_ADS"
        ),
      }}
    >
      <GQLFetchWrapper
        Component={InnerAds}
        fnData={getAllAdsEdit}
        fnParameters={adsParams}
        delegateLoading
      />
    </LayoutTable>
  );
};

export default Ads;
