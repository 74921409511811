import React from "react";
import { useTranslation } from "../../../utils/hooks";

function LoginClaim() {
  const { t } = useTranslation(["login", "common", "home"]);

  return (
    <>
      {t("already-account-1", { ns: "login" })}{" "}
      <span style={{ width: "5px" }}></span>
      <strong>{t("already-account-2", { ns: "login" })}</strong>
    </>
  );
}

export default LoginClaim;
