import React from "react";
import Icon from "../icon/Icon";
import styles from "./Label.module.scss";

function Label({
  children,
  text,
  hint,
  horizontal = false,
  horizontal_reverse = false,
  reverse = false,
  style = {},
  bold = false,
  reduced = false,
}) {
  return (
    <label
      style={style}
      className={`${styles["sc-label"]} ${
        horizontal ? styles["sc-label--horizontal"] : ""
      } ${horizontal_reverse ? styles["sc-label--h-reverse"] : ""} ${
        reverse ? styles["sc-label--reverse"] : ""
      } ${bold ? styles["sc-label--bold"] : ""} ${
        reduced ? styles["sc-label--reduced"] : ""
      }
      `}
    >
      <div className={`${text || hint ? styles["sc-label__heading"] : ""}`}>
        {text ? (
          <span className={`${styles["sc-label__desc"]}`}>{text}</span>
        ) : (
          <></>
        )}
        {hint ? (
          <div className={`${styles["sc-label__hint"]}`}>
            <Icon name={"info"} />
            <div className={`${styles["sc-label__hint__desc"]}`}>{hint}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {children}
    </label>
  );
}

export default Label;
