export function DefaultOption() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="4" fill="#FFFCDF" />
      <mask
        id="mask0_3592_1583"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="16"
        height="16"
      >
        <rect x="2" y="2" width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_3592_1583)">
        <path
          d="M5.88331 15.9999L6.96665 11.3166L3.33331 8.16659L8.13331 7.74992L9.99998 3.33325L11.8666 7.74992L16.6666 8.16659L13.0333 11.3166L14.1166 15.9999L9.99998 13.5166L5.88331 15.9999Z"
          fill="#F3BF00"
        />
      </g>
    </svg>
  );
}
