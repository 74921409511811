import { modalTypes } from "../../../utils/variables";
import ModalDocumentDetails from "../../modals/business/DocumentDetails";
import ModalInviteUser from "../../modals/business/InviteUser";
import ModalNewAddress from "../../modals/business/NewAddress";
import UserInfo from "../../modals/business/UserInfo";
import ModalAddMaterial from "../../modals/materials/Add";
import ModalSaveData from "../../modals/save/Save";
import ModalNotificationsHelp from "../../modals/notifications/Help";
import ModalEndTransaction from "../../modals/transactions/EndTransaction";
import ModalRawWeight from "../../modals/transactions/RawWeight";
import ModalSendAnswer from "../../modals/transactions/SendAnswer";
import { Modal } from "@scrapadev/scrapad-front-sdk/dist/index";
import ModalEditFavoriteSearch from "../../modals/favorites/EditSearch";
import ModalEditFavoriteMaterial from "../../modals/favorites/EditMaterials";
import ModalBlockOrganizationWarning from "../../modals/business/BlockOrganization";
import ModalUserWarning from "../../modals/UserWarning";
import ModalAdsTransactions from "../../modals/ads/Transactions";
import ModalAdsActions from "../../modals/ads/Actions";
import ModalWalletActions from "../../modals/wallet/Actions";
import ModalFinancesLink from "../../modals/finances/Link";
import ModalFinancesComments from "../../modals/finances/Comment";
import ModalVerificationWarning from "../../modals/business/VerificationWarning";
import ModalAddEditTransportProvider from "../../modals/logistics/AddEdit";
import ModalDeleteTransportProvider from "../../modals/logistics/Delete";
import ModalChangeFee from "../../modals/transactions/ChangeFee";
import ModalIncorporateTransportProvider from "../../modals/logistics/Incorporate";
import ModalViewTransportProvider from "../../modals/logistics/View";
import IncotermInfoModal from "../../modals/ads/IncotermInfoModal";
import PaymentMethodInfoModal from "../../modals/ads/PaymentMethodInfoModal";
import ModalChangeIcoterm from "../../modals/transactions/ChangeIcoterm";
import ModalChangePaymentMethod from "../../modals/transactions/ChangePaymentMethod";
import ModalMarkReceived from "../../modals/finances/MarkReceived";
import ModalMakePaymentsDeposits from "../../modals/finances/PaymentsDeposit";
import ModalChangeFinanciation from "../../modals/transactions/ChangeFinanciation";
import CounterOffer from "../../modals/opportunities/CounterOffer";
import RequestInformation from "../../modals/opportunities/RequestInformation";
import NifAlreadyExists from "../../modals/register/NifAlreadyExists";
import ModalAddQuotation from "../../modals/logistics/AddQuotation";
import ModalEditRoute from "../../modals/logistics/EditRoute";
import ModalEditLoadCharacteristics from "../../modals/logistics/EditLoadCharacteristics";
import ModalAddQuotationWarn from "../../modals/logistics/AddQuotationWarn";
import ChangeLogisticTrackingStatus from "../../modals/logistics/ChangeLogisticTrackingStatus";
import UploadLogisticsPricing from "../../modals/logistics/UploadLogisticsPricing";
import EditPaymentConditions from "../../modals/opportunities/EditPaymentConditions";
import { TransactionsFormsProvider } from "../../../providers/transactions/TransactionsFormContext";
import { addMonths } from "date-fns";

/**
 * Modal factory.
 * @param {Object} props - Component properties.
 * @param {Boolean} [props.open=false] - Open/close modal state.
 * @param {Function} props.setOpen - Setter for the open state.
 * @param {String} props.type - Type of modal.
 * @returns
 */
const ModalFactory = ({ open, setOpen, type, ...props }) => {
  const options = {
    open,
    setOpen,
    ...props,
  };
  const lookUpTable = {
    [modalTypes.TRANSACTIONS_ANSWER]: <ModalSendAnswer {...options} />,
    [modalTypes.TRANSACTIONS_RAW_WEIGHT]: <ModalRawWeight {...options} />,
    [modalTypes.END_TRANSACTION]: <ModalEndTransaction {...options} />,
    [modalTypes.INVITE_USER]: <ModalInviteUser {...options} />,
    [modalTypes.USER_INFO]: <UserInfo {...options} />,
    [modalTypes.ADD_MATERIAL]: <ModalAddMaterial {...options} />,
    [modalTypes.SAVE_DATA]: <ModalSaveData {...options} />,
    [modalTypes.VERIFICATION_DOCUMENT_DETAILS]: (
      <ModalDocumentDetails {...options} />
    ),
    [modalTypes.DASHBOARD_NEW_ADDRESS]: <ModalNewAddress {...options} />,
    [modalTypes.FAVORITES_EDIT_SEARCH]: (
      <ModalEditFavoriteSearch {...options} />
    ),
    [modalTypes.FAVORITES_EDIT_MATERIAL]: (
      <ModalEditFavoriteMaterial {...options} />
    ),
    [modalTypes.FAVORITES_DELETE]: <ModalUserWarning {...options} />,
    "notifications-help": <ModalNotificationsHelp {...options} />,
    [modalTypes.BLOCK_ORG_WARNING]: (
      <ModalBlockOrganizationWarning {...options} />
    ),
    [modalTypes.USER_WARNING]: <ModalUserWarning {...options} />,
    [modalTypes.ADS_TRANSACTIONS]: <ModalAdsTransactions {...options} />,
    [modalTypes.ADS_ACTIONS]: <ModalAdsActions {...options} />,
    [modalTypes.ADS_TRANSLATIONS]: <ModalAdsTransactions {...options} />,
    [modalTypes.WALLET_BALACE_ACTIONS]: <ModalWalletActions {...options} />,
    [modalTypes.FINANCES_LINK]: <ModalFinancesLink {...options} />,
    [modalTypes.FINANCES_COMMENT]: <ModalFinancesComments {...options} />,
    [modalTypes.VERIFICATION_WARNING]: (
      <ModalVerificationWarning {...options} />
    ),
    [modalTypes.LOGISTICS_ADD_EDIT_PROVIDER]: (
      <ModalAddEditTransportProvider {...options} />
    ),
    [modalTypes.LOGISTICS_DELETE_PROVIDER]: (
      <ModalDeleteTransportProvider {...options} />
    ),
    [modalTypes.CHANGE_FEE]: <ModalChangeFee {...options} />,
    [modalTypes.LOGISTICS_INCORPORATE_TRANSACTION]: (
      <ModalIncorporateTransportProvider {...options} />
    ),
    [modalTypes.LOGISTICS_VIEW_QUOTATION]: (
      <ModalViewTransportProvider {...options} />
    ),
    [modalTypes.LOGISTICS_ADD_QUOTATION]: <ModalAddQuotation {...options} />,
    [modalTypes.LOGISTICS_ADD_QUOTATION_WARN]: (
      <ModalAddQuotationWarn {...options} />
    ),
    [modalTypes.LOGISTICS_EDIT_ROUTE]: <ModalEditRoute {...options} />,
    [modalTypes.LOGISTICS_EDIT_LOAD_CHARACTERISTICS]: (
      <ModalEditLoadCharacteristics {...options} />
    ),
    [modalTypes.INCOTERM_INFO]: <IncotermInfoModal {...options} />,
    [modalTypes.PAYMENT_METHOD_INFO]: <PaymentMethodInfoModal {...options} />,
    [modalTypes.CHANGE_ICOTERM]: <ModalChangeIcoterm {...options} />,
    [modalTypes.CHANGE_PAYMENT_METHOD]: (
      <ModalChangePaymentMethod {...options} />
    ),
    [modalTypes.MARK_PAYMENTS]: <ModalMarkReceived {...options} />,
    [modalTypes.MANUAL_PAYMENTS_DEPOSIT]: (
      <ModalMakePaymentsDeposits {...options} />
    ),
    [modalTypes.CHANGE_FINANCIATION]: <ModalChangeFinanciation {...options} />,
    [modalTypes.OPPORTUNITY_COUNTER_OFFER]: <CounterOffer {...options} />,
    [modalTypes.OPPORTUNITY_REQUEST_INFORMATION]: (
      <RequestInformation {...options} />
    ),
    [modalTypes.NIF_ALREADY_EXISTS]: <NifAlreadyExists {...options} />,
    [modalTypes.LOGISTIC_CHANGE_TRACKING_STATUS]: (
      <ChangeLogisticTrackingStatus {...options} />
    ),
    [modalTypes.LOGISTIC_PRICING_UPLOAD]: (
      <UploadLogisticsPricing {...options} />
    ),
    [modalTypes.OPPORTUNITY_OFFER_EDIT_PAYMENT_CONDITIONS]: (
      <EditPaymentConditions {...options} />
    ),
  };
  const getModalComponent = () => {
    const modal = lookUpTable[type];
    return modal ? modal : <Modal {...options} />;
  };

  return getModalComponent();
};

export default ModalFactory;
