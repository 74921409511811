import { formEvents } from "../../../utils/variables";

export const buildFormMessage = (uuidUser, slug, state) => {
  return {
    uuidUser,
    destinationsOrg: state.hiddenDestination.split("|"),
    content: state.content,
    fileType: state?.fileType,
    files: state.files.map((f) => f.uuid),
    slug,
    uuidEvent: state.uuidEvent,
  };
};

export const buildFormNotes = (uuidUser, slug, state) => {
  return {
    uuidUser,
    destinations: state.hiddenDestination.split("|"),
    content: state.content,
    fileType: state?.fileType,
    files: state.files.map((f) => f.uuid),
    slug,
    uuidEvent: state.uuidEvent,
  };
};

export const buildFormVerification = (uuidUser, slug, state) => {
  return {
    uuidUser,
    suplier: state.suplier,
    price: state.price * 100,
    state: state.state,
    slug,
    uuidEvent: state.uuidEvent,
  };
};

export const buildFormTransportCost = (uuidUser, slug, state) => {
  return {
    uuidUser,
    suplier: state.suplier,
    untilDate: state.untilDate,
    state: state.state,
    numLote: state.numLote * 1,
    unitPrice: state.unitPrice * 100,
    slug,
    uuidEvent: state.uuidEvent,
    suplierUuid: state?.suplierUuid,
    incoterm: state?.incoterm,
    transportType: state?.transportType,
    shippingDeadline: state?.shippingDeadline * 1,
    equipment: state?.equipment,
    canDispatch: state?.canDispatch,
    originPort: state?.originPort,
  };
};

export const buildFormTransportLoad = (uuidUser, slug, state, type) => {
  const data = {
    uuidUser,
    estimatedDate: state.estimatedDate,
    estimatedSchedule: state.estimatedSchedule,
    state: state.state,
    slug,
    uuidEvent: state.uuidEvent,
  };
  if (type === "load") {
    data.newWeight = state.newWeight * 100;
  }
  return data;
};

export const buildFormSamples = (uuidUser, slug, state) => {
  const obj = {
    uuidUser,
    deliveryPrice: state.deliveryPrice * 100,
    state: state.state,
    slug,
    uuidEvent: state.uuidEvent,
    files: state?.files?.map((f) => f.uuid),
  };
  if (state.estimatedDate) {
    obj["estimatedDate"] = state.estimatedDate;
  }
  return obj;
};

export const buildFormReturnTolerance = (
  uuidUser,
  slug,
  state,
  destination
) => {
  return {
    uuidUser,
    destination,
    value: state.valueTolerance * 100,
    slug,
    uuidEvent: state.uuidEvent,
  };
};

export const buildFormReturnBail = (uuidUser, slug, state, destination) => {
  return {
    uuidUser,
    destination,
    value: state.valueBail * 100,
    comments: state.comments,
    slug,
    uuidEvent: state.uuidEvent,
  };
};

export const buildFormReturnOther = (uuidUser, slug, state, destination) => {
  return {
    uuidUser,
    destination,
    value: state.valueOther * 100,
    comments: state.comments,
    content: state.content,
    slug,
    uuidEvent: state.uuidEvent,
  };
};

export const buildRawWeight = (uuidUser, uuidEvent, value) => {
  return {
    uuidUser,
    uuidEvent: uuidEvent,
    slug: formEvents.ADD_GROSS_WEIGHT,
    value: value * 100,
  };
};

export const buildFormReply = (
  uuidUser,
  uuidEvent,
  uuidMessageToReply,
  content,
  files
) => {
  return {
    uuidUser,
    uuidMessageToReply,
    uuidEvent,
    content,
    files: files.map((f) => f.uuid),
    slug: formEvents.REPLY_MD,
  };
};

export const buildFormSign = (uuidUser, uuidEvent, type, key) => {
  const slug =
    type === "ok"
      ? formEvents.PURCHASE_SALE_SCRAPAD_OK
      : formEvents.PURCHASE_SALE_SCRAPAD_KO;
  return {
    uuidUser,
    uuidEvent,
    uuidContract: key,
    slug,
  };
};

export const buildEndTransaction = (uuidUser, uuidEvent) => {
  return {
    uuidUser,
    uuidEvent,
    slug: formEvents.END_TRANSACTION,
  };
};

export const buildChangeFee = (uuidUser, uuidEvent, value) => {
  return {
    uuidUser,
    uuidEvent: uuidEvent,
    slug: formEvents.CHANGE_FEE,
    value: value * 100,
  };
};

export const buildChangeIcoterm = (uuidUser, uuidEvent, value) => {
  return {
    uuidUser,
    uuidEvent: uuidEvent,
    slug: formEvents.CHANGE_ICOTERM,
    value,
  };
};

export const buildChangePaymentMethod = (uuidUser, uuidEvent, value) => {
  return {
    uuidUser,
    uuidEvent: uuidEvent,
    slug: formEvents.CHANGE_PAYMENT_METHOD,
    value,
  };
};

export const buildChangeFinancesRequest = (uuidUser, state) => {
  return {
    uuidUser,
    uuidEvent: state?.uuidEvent,
    slug: state?.deposit,
    amount: state?.amount * 100,
  };
};

export const buildChangeFinancesRequestDeposit = (
  uuidUser,
  state,
  destination
) => {
  return {
    uuidUser,
    uuidEvent: state?.uuidEvent,
    concept: state?.concept,
    amount: state?.amount * 100,
    destination,
    currency: state?.currency || "EUR",
    slug: formEvents.REQUEST_DEPOSIT,
    files: state?.files?.map((f) => f.uuid),
  };
};

export const buildChangeFinancesMake = (uuidUser, state, destination) => {
  return {
    uuidUser,
    uuidEvent: state?.uuidEvent,
    concept: state?.concept,
    amount: state?.amount * 100,
    destination,
    currency: state?.currency || "EUR",
    slug: formEvents.MAKE_PAYMENT,
    files: state?.files?.map((f) => f.uuid),
  };
};

export const buildMarkCompleted = (uuidUser, uuidEvent, uuidDeposit) => {
  return {
    uuidUser,
    uuidEvent,
    uuidDeposit,
    slug: formEvents.MARK_DEPOSIT_RECEIVED,
  };
};

export const buildChangeFinanciation = (uuidUser, uuidEvent, value) => {
  return {
    uuidUser,
    uuidEvent: uuidEvent,
    slug: formEvents.SET_QUOTATION_FINANCING,
    value: value * 100,
  };
};

export const buildNestedTableActions = (
  uuidUser,
  uuidEvent,
  uuidDeposit,
  slug
) => {
  return {
    uuidUser,
    uuidEvent,
    uuidDeposit,
    slug,
  };
};

export const buildConfirmDeposit = (uuidUser, uuidEvent, uuidDeposit) => {
  return {
    slug: formEvents.DEPOSIT_DONE,
    uuidEvent,
    uuidUser,
    uuidDeposit,
  };
};
