import React, { useEffect, useMemo, useState } from "react";
import { es } from "date-fns/locale/";
import { storageKeys } from "../../utils/variables";

const defaultLanguage = "es";

export const LanguageContext = React.createContext({});

export const LanguageProvider = ({ language, children }) => {
  const [innerLanguage, setInnerLanguage] = useState(
    language || defaultLanguage
  );

  useEffect(() => {
    window.addEventListener("storage", checkStorage);
    return () => {
      window.removeEventListener("storage", checkStorage);
    };
  }, []);

  useEffect(() => {
    const lang = language ? language : defaultLanguage;
    setInnerLanguage(lang);
  }, [language]);

  const checkStorage = (e) => {
    if (e.key === storageKeys.USER_DEFINED_LANGUAGE && e.newValue === null) {
      setInnerLanguage(window.localStorage.getItem(storageKeys.LANGUAGE));
    }
  };

  const getLanguageVariable = (code = innerLanguage) => {
    const lookUpTable = {
      es: es,
    };
    return lookUpTable[code];
  };

  const contextValue = useMemo(() => {
    return { innerLanguage, setInnerLanguage, getLanguageVariable };
  }, [innerLanguage, setInnerLanguage, getLanguageVariable]);

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};
