import {
  BREAKPOINT_LG,
  Center,
  Loading,
  Modal,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import ModalText from "../../ui/modal/text/ModalText";
import { useGQL } from "../../../utils/gql/hooks";
import { getPaymentTransactionComment } from "../../../model/finances";
import _ from "lodash";

const ModalFinancesComments = ({ open, setOpen, ...props }) => {
  const { t } = useTranslation(["finances"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);

  const { data, loading, fetchData } = useGQL(
    getPaymentTransactionComment,
    {
      uuid: props.uuid,
    },
    true
  );

  useEffect(() => {
    if (open) fetchData();
  }, [open, props.uuid]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t("income-comment")}
      hasForm={false}
      hasActions={false}
      modalStyles={{
        width: matches ? "auto" : 650,
      }}
      BodyComponent={
        loading ? (
          <Center>
            <Loading />
          </Center>
        ) : (
          <div style={{ marginBottom: -30 }}>
            <ModalText text={!_.isEmpty(data) ? data : t("no-coments")} />
          </div>
        )
      }
    />
  );
};

export default ModalFinancesComments;
