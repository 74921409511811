import { INFO_COLOR } from "../../../../utils/variables";
import CheckBoxSc from "./CheckBox";

/**
 * UI Blue Checkbox component.
 * @param {object} props - Checkboc properties.
 * @param {object} [props.itemCheckStyle={}] - - Checbox styles.
 * @param {object} [props.itemCheckedStyle={}] - Checbox checked styles.
 * @returns {JSX.Element}
 */
const BlueCheckbox = ({
  itemCheckStyle = {},
  itemCheckedStyle = {},
  ...props
}) => {
  return (
    <CheckBoxSc
      {...props}
      itemCheckStyle={{
        borderColor: "#B1B1B1",
        ...itemCheckStyle,
      }}
      itemCheckedStyle={{
        background: INFO_COLOR,
        borderColor: INFO_COLOR,
        color: "#fff",
        ...itemCheckedStyle,
      }}
    />
  );
};

export default BlueCheckbox;
