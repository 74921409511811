import { async } from "@firebase/util";
import {
  BREAKPOINT_LG,
  ClearButton,
  Content,
  Flex,
  Icon,
  Typography,
  useClickOnBody,
  useMatchMedia,
  WARM_NEUTRAL_20,
  WARM_NEUTRAL_40,
  WARM_NEUTRAL_60,
  WARM_NEUTRAL_80,
} from "@scrapadev/scrapad-front-sdk";
import React, { cloneElement, useRef, useState } from "react";
import { DefaultOption } from "../../../components/ui/icons/general/DefaultOption";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import { useTranslation } from "../../../utils/hooks";
import { modalTypes } from "../../../utils/variables";

/**
 * Helper component to render an item in every section of business preferences.
 * @param {object} props - Component properties.
 * @param {string} props.uuid - Item uuid.
 * @param {string} props.text - Description.
 * @param {React.ReactNode} props.leading - Extra leading component. Ex: A flag.
 * @param {boolean} props.selected - If this item selected or not.
 * @param {boolean} props.isDefault - If this item is marked as a defaul.
 * @param {() => void} props.onMarkDefault - Call back to mark as default.
 * @param {number} props.index - Current item.
 * @param {number} props.size - Number of items.
 * @param {boolean} props.loading - Loading state.
 * @param {boolean} props.textLight - Lighter text for some cases.
 * @returns {React.ReactNode}
 */
const PreferencesBlockItem = ({
  uuid,
  text,
  leading,
  selected,
  isDefault,
  onMarkDefault,
  index,
  size,
  loading,
  textLight,
}) => {
  const { t } = useTranslation("common");
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [open, setOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const containerRef = useRef(null);
  const isLast = index + 1 === size;
  useClickOnBody(containerRef, () => setOpenSubmenu(false));
  return (
    <>
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        style={{
          width: "100%",
          paddingTop: index === 0 ? 0 : 20,
          paddingBottom: isLast ? 0 : 20,
          borderBottom: isLast ? 0 : `1px solid ${WARM_NEUTRAL_20}`,
        }}
      >
        <Flex
          alignItems={"center"}
          justifyContent={"flex-start"}
          style={{ gap: 10 }}
        >
          {leading}
          {
            <Typography color={textLight ? "light" : "normal"} noMargin>
              {text}
            </Typography>
          }
        </Flex>
        <Flex
          alignItems={"center"}
          justifyContent={"flex-start"}
          style={{ gap: 15 }}
        >
          {isDefault && <DefaultOption />}
          {selected && !isDefault && (
            <ClearButton
              onClick={(e) => setOpenSubmenu(!openSubmenu)}
              style={{ marginLeft: matches ? "auto" : 0 }}
            >
              <div ref={containerRef} style={{ height: 24 }}>
                <Icon name={"more_vert"} color={WARM_NEUTRAL_60} />
                {openSubmenu && (
                  <Content
                    position="absolute"
                    items={[
                      {
                        component: (
                          <ClearButton
                            onClick={() => {
                              setOpen(!open);
                            }}
                          >
                            {t("mark_as_default")}
                          </ClearButton>
                        ),
                      },
                    ]}
                  />
                )}
              </div>
            </ClearButton>
          )}
        </Flex>
      </Flex>
      {open && (
        <ModalFactory
          key={uuid}
          type={modalTypes.USER_WARNING}
          open={open}
          setOpen={setOpen}
          loading={loading}
          onSubmit={async () => {
            await onMarkDefault();
            setOpen(false);
          }}
        />
      )}
    </>
  );
};

export default PreferencesBlockItem;
