import React from "react";
import styles from "./MenuItem.module.scss";
import { Icon, WARM_NEUTRAL_50 } from "@scrapadev/scrapad-front-sdk";

function MenuItem({
  children,
  icon,
  iconStyles = {},
  onClick = () => {},
  title,
  withSubitems = false,
  disabled = false,
}) {
  return (
    <div
      className={`${styles["sc-menu-item"]} ${disabled && styles["disabled"]}`}
      onClick={!disabled ? onClick : undefined}
    >
      {icon ? (
        <i
          className={`large material-icons-outlined  icon`}
          style={{
            ...iconStyles,
          }}
        >
          {icon}
        </i>
      ) : (
        <></>
      )}
      {withSubitems ? title : children}
      {withSubitems && (
        <>
          <div className={styles["more_items_icon_wrap"]}>
            <Icon
              name={"chevron_right"}
              style={{
                fontSize: "18px",
                lineHeight: 0,
                display: "flex",
                color: WARM_NEUTRAL_50,
              }}
              color={WARM_NEUTRAL_50}
            />
          </div>
          <div className={`${styles["subitems_wrap"]}`}>
            <div className={styles["sc-menu__inner"]}>{children}</div>
          </div>
        </>
      )}
    </div>
  );
}

export default MenuItem;
