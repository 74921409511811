import deepmerge from "deepmerge";
import { useTranslation } from "../../utils/hooks";
import {
  Alert,
  Chip,
  CombinedWithOperator,
  Container,
  DropDown,
  Flex,
  ImageViewer,
  InputNumber,
  InputText,
  Label,
  Row,
  Spacer,
  Sticky,
  Typography,
  ImageCarousel,
} from "@scrapadev/scrapad-front-sdk";
import Item from "../../components/ui/item/Item";
import AddressForm from "../../wrappers/address/AddressForm";
import { handleOnChange } from "../../utils/functions";
import _ from "lodash";
import {
  adStatus,
  adTypes,
  currencyOptions,
  LoadCharacteristicasTypes,
  mapOperators,
  modalTypes,
  operatorsOptions,
  orgStatus,
  questionsTypes,
} from "../../utils/variables";
import CatalogSearch from "../../components/ui/input/search/CatalogSearch";
import FilesList from "../fields/FilesList";
import LoadCharacteristicsAttributes from "../../widgets/ads/LoadCharacteristicsAttributes";
import Questions from "../../components/ui/input/search/Questions";
import AdFiles from "./AdFile";
import TextArea from "../../components/ui/input/textarea/TextArea";
import { useGQL } from "../../utils/gql/hooks";
import { getCatalogNoTerm } from "../../model/filters";
import { useEffect, useRef, useState } from "react";
import { getMaterialQuestions } from "../../model/ads";
import carouselPlaceholder from "../../assets/img/no-results/image_placeholder.jpg";
import { checkFormRequiredFields } from "./AdStatusAction";
import {
  canNotPublishBasic,
  canNotPublishKyc,
} from "../../utils/ads/functions";
import ModalFactory from "../../components/ui/modal/ModalFactory";

const canEdit = (state, key, isEdit) => {
  if (!state.status) return false;
  if (state?.status === adStatus.BLOCKED || state?.status === adStatus.REMOVED)
    return false;
  if (
    state?.status === adStatus.COVERED ||
    (state?.status === adStatus.PUBLISHED &&
      state.transactionsActive &&
      state.transactionsActive > 0)
  ) {
    if (isEdit && (key === "amount" || key === "price" || key === "currency"))
      return true;
    else return false;
  }
  return isEdit ? true : false;
};

const AdForm = ({
  index,
  state,
  dispatch,
  actions,
  fetchData,
  extraData,
  headerHeight,
  setHeaderHeight,
  isEdit,
  isMobile,
  isDirty,
  verificationState,
  ...props
}) => {
  const [showimageviewer, setshowimageviewer] = useState(false);
  const [imageviewerindex, setimageviewerindex] = useState(0);
  const { t, i18n } = useTranslation(["common", "ads"], true);
  const [modalType, setModalType] = useState(false);
  const [isPort, setIsPort] = useState(state?.address?.port);
  const handleItemSelected = (key) => (option, open, setOpen) => {
    const obj = {};
    obj[key] = option.uuid;
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: obj,
    });
    setOpen(!open);
  };
  const { data: materialCompositionData } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: "material-composition",
  });
  const { data: materialQuestionData, fetchData: fetchMaterialQuestions } =
    useGQL(getMaterialQuestions, {}, true);

  const { data: currencyList } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: "currency",
  });

  useEffect(() => {
    if (materialQuestionData) {
      const newState = deepmerge([], state);
      const mqd = materialQuestionData || [];
      newState["materialQuestions"] = [...mqd];
      dispatch({ type: actions.UPDATE_INPUT, payload: newState });
    }
  }, [materialQuestionData]);

  const filesBuffer = useRef([]);

  const handleAddress =
    (dispatch = () => {}) =>
    (e = {}, isFav = false, keepId = false) => {
      const newState = deepmerge([], state);
      if (e?.port) {
        newState["address"] = { ...newState?.address, ...e };
      } else {
        if (isFav) {
          newState["address"] = { ...e };
        } else {
          newState["address"] = { ...newState?.address, ...e };
          if (!keepId) delete newState["address"]["id"];
        }
      }
      dispatch({ type: actions.UPDATE_INPUT, payload: newState });
    };

  const handleAddLerCode = (e) => {
    const newState = deepmerge([], state);
    const lerCodesCpy = newState?.lerCodes || [];
    newState["lerCodes"] = [...lerCodesCpy, e || ""];
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleRemoveCollection = (tIndex, key) => {
    const newState = deepmerge([], state);
    newState[key] = newState[key].filter((element, i) => i !== tIndex);
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleAddImage = (e) => {
    const newState = deepmerge([], state);
    const filesCpy = newState?.files || [];
    newState["files"] = [...filesCpy, e || ""];
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleAddComponent = (e) => {
    const newState = deepmerge([], state);
    const componentsCpy = newState?.components || [];
    newState["components"] = [...componentsCpy, e || ""];
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleLoadCharacteristics = (key, value = {}, clean = false) => {
    const newState = deepmerge([], state);
    if (clean) {
      newState["loadCharacteristics"] = [];
    }

    if (state?.type !== "purchase") {
      if (key !== LoadCharacteristicasTypes.GRANEL) {
        newState["loadCharacteristics"] = newState[
          "loadCharacteristics"
        ].filter((e) => e.type !== LoadCharacteristicasTypes.GRANEL);
      }
    }
    const index = newState["loadCharacteristics"]?.findIndex((lc) => {
      return lc?.type === key;
    });

    if (index !== -1) {
      if (value?.type) {
        newState["loadCharacteristics"] = newState[
          "loadCharacteristics"
        ].filter((lc) => lc?.type !== key);
      } else {
        newState["loadCharacteristics"] = newState["loadCharacteristics"].map(
          (lc) => {
            if (lc.type === key) {
              lc.value = { ...lc.value, ...value };
            }
            return lc;
          }
        );
      }
    } else {
      newState["loadCharacteristics"].push(value);
    }

    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleDeleteImage = (e) => {
    const newState = deepmerge([], state);
    newState["files"] = newState["files"]?.filter(
      (element) => e.uuid !== element.uuid
    );
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const handleSortImage = (dragIndex, hoverIndex) => {
    if (state?.files?.length === 0) return;
    const newState = deepmerge([], state);
    const bkState = deepmerge([], state);
    newState?.files?.splice(dragIndex, 1);
    newState?.files?.splice(hoverIndex, 0, bkState?.files?.[dragIndex]);
    filesBuffer.current = [...newState?.files];
  };

  const handleQuestionOnChange = (q) => (e) => {
    const newState = deepmerge([], state);
    const materialQuestionsCpy = newState?.materialQuestions || [];
    newState["materialQuestions"] = [...materialQuestionsCpy];

    const exist = newState["materialQuestions"].filter(
      (mq) => mq.uuidQuestion === q.uuidQuestion
    );

    if (exist.length > 0) {
      if (q?.type === questionsTypes.MULTIPLE_CHOICE) {
        newState["materialQuestions"] = newState["materialQuestions"].map(
          (mq) => {
            if (mq.uuidQuestion === q.uuidQuestion) {
              const valueExist = mq.value?.filter(
                (mqf) => mqf === e.target.value
              );
              if (valueExist?.length > 0) {
                mq.value = mq.value.filter((mqf) => mqf !== e.target.value);
              } else {
                if (_.isEmpty(mq.value)) {
                  mq.value = [];
                }
                mq.value.push(e.target.value);
              }
              return mq;
            }
            return mq;
          }
        );
      } else if (q?.type === questionsTypes.YES_NO) {
        newState["materialQuestions"] = newState["materialQuestions"].map(
          (mq) => {
            if (mq.uuidQuestion === q.uuidQuestion) {
              mq.value = [mq.value ? !mq.value[0] : true];
              return mq;
            }
            return mq;
          }
        );
      } else {
        newState["materialQuestions"] = newState["materialQuestions"].map(
          (mq) => {
            if (mq.uuidQuestion === q.uuidQuestion) {
              mq.value = [e.target.value];
              return mq;
            }
            return mq;
          }
        );
      }
    } else {
      if (q?.type === questionsTypes.MULTIPLE_CHOICE) {
        const newValue = { ...q };
        newValue.value = [e.target.value];
        newState["materialQuestions"].push(newValue);
      } else if (q?.type === questionsTypes.YES_NO) {
        const newValue = { ...q };
        newValue.value = [true];
        newState["materialQuestions"].push(newValue);
      } else {
        const newValue = { ...q };
        newValue.value = [e.target.value];
        newState["materialQuestions"].push(newValue);
      }
    }

    dispatch({
      type: actions.UPDATE_INPUT,
      payload: newState,
    });
  };

  const renderPreviewImages = () => {
    if (isMobile && !isEdit) {
      return <></>;
    }

    const urlImages = state?.files?.map((image) => (
      <span
        style={{
          boxSizing: "border-box",
          display: "block",
          overflow: "hidden",
          width: "initial",
          height: "initial",
          background: "none",
          opacity: 1,
          border: "0px",
          margin: "0px",
          padding: "0px",
          position: "absolute",
          inset: "0px",
        }}
      >
        <img
          alt=""
          objectFit="cover"
          src={image.location || image.cdn}
          style={{
            position: "absolute",
            inset: "0px",
            boxSizing: "border-box",
            padding: "0px",
            border: "none",
            margin: "auto",
            display: "block",
            width: "0px",
            height: "0px",
            minWidth: "100%",
            maxWidth: "100%",
            minHeight: "100%",
            maxHeight: "100%",
            objectFit: "cover",
          }}
        />
      </span>
    ));

    return (
      <>
        <div className="col-12 col-md-6">
          <Sticky
            style={{
              top: `${headerHeight + 25}px`,
            }}
          >
            <ImageCarousel
              items={
                _.isEmpty(urlImages)
                  ? [
                      <img
                        key={`c_image_${carouselPlaceholder}`}
                        alt=""
                        src={carouselPlaceholder}
                        style={{
                          position: "absolute",
                          inset: "0px",
                          boxSizing: "border-box",
                          padding: "0px",
                          border: "none",
                          margin: "auto",
                          display: "block",
                          width: "0px",
                          height: "0px",
                          minWidth: "100%",
                          maxWidth: "100%",
                          minHeight: "100%",
                          maxHeight: "100%",
                          objectFit: "cover",
                        }}
                      />,
                    ]
                  : urlImages
              }
              onItemClick={(e) => {
                if (!_.isEmpty(urlImages)) {
                  setshowimageviewer(true);
                  setimageviewerindex(e);
                }
              }}
            />
          </Sticky>
        </div>
        {showimageviewer && (
          <ImageViewer
            open={showimageviewer}
            items={state?.files?.map((image) => ({
              url: image.location || image.cdn,
              name: image.name,
              uuid: image.uuid,
            }))}
            setOpen={setshowimageviewer}
            defaultIndex={imageviewerindex}
          />
        )}
      </>
    );
  };

  const renderAlert = () => {
    if (canNotPublishBasic(verificationState)) {
      return <Alert type="warning">{t("cant-publish-basic")}</Alert>;
    }
    if (canNotPublishKyc(state, verificationState)) {
      return <Alert type="warning">{t("cant-publish-full")}</Alert>;
    }
    if (!checkFormRequiredFields(state)) {
      return <Alert type="error">{t("fill-required-fields")}</Alert>;
    }
  };

  return (
    <Container>
      <div className="row">
        <div className="col-12">
          {renderAlert()}
          <Spacer />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <form id="adForm">
            <Item text={t("ad-basic-info")}>
              <Label text={`${t("ad-type")} *`} dark bold>
                <Row
                  style={{
                    width: "100%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                  gap={15}
                >
                  <Chip
                    big
                    type={
                      state?.type === "purchase"
                        ? !canEdit(state, "type", isEdit)
                          ? "gray40"
                          : "data"
                        : "normal"
                    }
                    text={t("buy")}
                    handleClick={
                      canEdit(state, "type", isEdit)
                        ? () =>
                            handleOnChange(
                              "type",
                              dispatch
                            )({ target: { value: "purchase" } })
                        : undefined
                    }
                    style={{ flex: "1 1", justifyContent: "center" }}
                  />
                  <Chip
                    big
                    type={
                      state?.type === "sale"
                        ? !canEdit(state, "type", isEdit)
                          ? "gray40"
                          : "data"
                        : "normal"
                    }
                    text={t("sell")}
                    handleClick={
                      canEdit(state, "type", isEdit)
                        ? () => {
                            handleAddress(dispatch)({
                              port: undefined,
                            });

                            handleOnChange(
                              "type",
                              dispatch
                            )({ target: { value: "sale" } });

                            handleOnChange(
                              "incoterm",
                              dispatch
                            )({ target: { value: undefined } });
                          }
                        : undefined
                    }
                    style={{ flex: "1 1", justifyContent: "center" }}
                  />
                </Row>
              </Label>
              <Label text={`${t("ad-title")}*`} dark bold>
                <InputText
                  required
                  readOnly={!canEdit(state, "originalTitle", isEdit)}
                  placeholder={t("ad-title-placeholder")}
                  value={state?.originalTitle}
                  onChange={handleOnChange("originalTitle", dispatch)}
                  skipRequiredHelp
                  {...props}
                />
              </Label>
              <Label text={`${t("ad-material")} *`} dark bold>
                <CatalogSearch
                  inputProps={{
                    readOnly: !canEdit(state, "materialTypeEdit", isEdit),
                    disabled: !canEdit(state, "materialTypeEdit", isEdit),
                    required: true,
                  }}
                  initialValue={state?.materialTypeEdit?.name}
                  catalogName={"material-autocomplete"}
                  onClickCallback={
                    canEdit(state, "materialTypeEdit", isEdit)
                      ? (option, open, setOpen) => {
                          setTimeout(() => {
                            setOpen(false);
                          }, 100);
                          handleOnChange(
                            "materialTypeEdit",
                            dispatch
                          )({
                            target: {
                              value: option.uuid,
                            },
                          });
                          fetchMaterialQuestions({
                            uuid: option.uuid,
                            locale: i18n.language,
                          });
                        }
                      : undefined
                  }
                />
              </Label>
              <Row gap={15}>
                <Label
                  text={`${t(
                    state?.type === "purchase"
                      ? "ad-amount-wish"
                      : "ad-amount-available"
                  )} *`}
                  dark
                  bold
                >
                  <Row gap={15}>
                    <InputNumber
                      readOnly={!canEdit(state, "amount", isEdit)}
                      min={1}
                      required={true}
                      placeholder={t("write-here")}
                      name="amount"
                      onChange={handleOnChange("amount", dispatch)}
                      value={state?.amount}
                    />
                    <div style={{ maxWidth: "40px" }}>
                      <InputText value={"t"} readOnly />
                    </div>
                  </Row>
                </Label>
                <Label text={`${t("ad-proposed-price")} *`} dark bold>
                  <Row gap={15}>
                    <InputNumber
                      readOnly={!canEdit(state, "price", isEdit)}
                      min={1}
                      required={true}
                      placeholder={t("write-here")}
                      name="price"
                      onChange={handleOnChange("price", dispatch)}
                      value={state?.price}
                    />
                    <DropDown
                      readOnly={!canEdit(state, "currency", isEdit)}
                      id="currency"
                      name="currency"
                      placeholder={t("select-value")}
                      options={
                        currencyList
                          ? currencyList.map((item) => ({
                              uuid: item.uuid,
                              text: item?.name?.includes("%")
                                ? item.name
                                : `${item.name}/t`,
                            }))
                          : currencyOptions
                      }
                      required={true}
                      value={
                        currencyList
                          ? currencyList
                              ?.find((f) => f.uuid === state?.currency)
                              ?.name.includes("%")
                            ? currencyList?.find(
                                (f) => f.uuid === state?.currency
                              )?.name
                            : `${
                                currencyList?.find(
                                  (f) => f.uuid === state?.currency
                                )?.name
                              }/t`
                          : currencyOptions
                              ?.find((f) => f.uuid === state?.currency)
                              ?.name.includes("%")
                          ? currencyOptions?.find(
                              (f) => f.uuid === state?.currency
                            )?.name
                          : `${
                              currencyOptions?.find(
                                (f) => f.uuid === state?.currency
                              )?.name
                            }/t`
                      }
                      handleItemClick={
                        canEdit(state, "currency", isEdit)
                          ? handleItemSelected("currency")
                          : undefined
                      }
                      style={{ maxWidth: "130px" }}
                    />
                  </Row>
                </Label>
              </Row>
            </Item>
            <Item text={t("ad-more-details")}>
              <Label
                text={
                  state?.type === "purchase"
                    ? t("ad-composition-requeriments")
                    : `${t("ad-composition")} *`
                }
                dark
                bold
                style={{ paddingBottom: 0 }}
              ></Label>
              <Flex
                flexDirection="column"
                alignItems={"flex-start"}
                justifyContent="flex-start"
                style={{
                  gap: "15px",
                  paddingBottom: "15px",
                }}
              >
                <CombinedWithOperator
                  dark={true}
                  firstInputLabel={t("component")}
                  firstInputProps={{
                    required:
                      state?.type === "sale" && state?.components?.length === 0,
                    readOnly: !canEdit(state, "components", isEdit),
                    placeholder: t("select-value"),
                    options: (_.isEmpty(materialCompositionData?.errors)
                      ? materialCompositionData
                      : []
                    )?.map((o) => ({
                      uuid: o.uuid,
                      text: t(o.name),
                      required:
                        state?.type === "sell" &&
                        state?.components?.length === 0,
                      readOnly: !canEdit(state, "components", isEdit),
                    })),
                  }}
                  operatorLabel={t("operator")}
                  operatorProps={{
                    required:
                      state?.type === "sale" && state?.components?.length === 0,
                    placeholder: t("select-value"),
                    options: operatorsOptions?.map((o) => ({
                      uuid: o.uuid,
                      text: t(o.text),
                    })),
                  }}
                  valueLabel={t("percentage")}
                  valueProps={{
                    required:
                      state?.type === "sale" && state?.components?.length === 0,
                  }}
                  onComplete={(e) => {
                    handleAddComponent({
                      type: e[0].uuid,
                      materialDesc: e[0].text,
                      operator: e[1].uuid,
                      value: e[2],
                    });
                  }}
                />
              </Flex>
              {!_.isEmpty(state?.components) ? (
                <>
                  <Label dark bold>
                    <Row key={`combined_operator`} gap={15} wrap={true}>
                      {state?.components.map((e, i) => {
                        return (
                          <Chip
                            key={`cop_${i}`}
                            text={`${e.materialDesc} ${
                              mapOperators[e.operator]
                            } ${e.value}%`}
                            showIcon={canEdit(state, "components", isEdit)}
                            handleClick={
                              canEdit(state, "components", isEdit)
                                ? () => handleRemoveCollection(i, "components")
                                : undefined
                            }
                            big
                            type={
                              !canEdit(state, "components", isEdit)
                                ? "gray40"
                                : "blue60"
                            }
                          />
                        );
                      })}
                    </Row>
                  </Label>
                </>
              ) : (
                <></>
              )}
              <Questions
                readOnly={!canEdit(state, "materialQuestions", isEdit)}
                type={state?.materialTypeEdit}
                stateValue={state?.materialQuestions}
                onChange={handleQuestionOnChange}
              />
              <Label
                text={t("ler-code")}
                style={{ paddingBottom: 0 }}
                dark
                bold
                hint={t("ad_ler_tooltip")}
                useHintTooltip
                hintTooltipProps={{
                  alignment: "bottom",
                  style: {
                    maxWidth: 320,
                  },
                }}
                hintProps={{
                  color: "#777986",
                  style: {
                    fontSize: 20,
                    position: "relative",
                    top: 3,
                  },
                }}
              >
                <CatalogSearch
                  inputProps={{
                    readOnly: !canEdit(state, "lerCodes", isEdit),
                  }}
                  catalogName={"ler"}
                  optionsAdapter={(data) => {
                    return data.map((d) => {
                      return {
                        uuid: d.uuid,
                        name: `${d.uuid} ${d.name}`,
                      };
                    });
                  }}
                  cleanAfterSelected
                  onClickCallback={(option, open, setOpen, value, setValue) => {
                    handleAddLerCode(option?.uuid);
                    setTimeout(() => {
                      setOpen(false);
                    }, 100);
                  }}
                />
              </Label>
              <Label dark bold>
                <Row gap={15} wrap>
                  {!_.isEmpty(state?.lerCodes) &&
                    state?.lerCodes?.map((e, i) => {
                      return (
                        <Chip
                          key={`ler_code_${i}`}
                          text={e?.name ? e.name : e}
                          big
                          showIcon={canEdit(state, "lerCodes", isEdit)}
                          handleClick={
                            canEdit(state, "lerCodes", isEdit)
                              ? () => handleRemoveCollection(i, "lerCodes")
                              : undefined
                          }
                          type={
                            !canEdit(state, "lerCodes", isEdit)
                              ? "gray40"
                              : "blue60"
                          }
                        />
                      );
                    })}
                </Row>
              </Label>
              <Label text={`${t("description")} *`} dark bold>
                <TextArea
                  required={true}
                  readOnly={!canEdit(state, "originalText", isEdit)}
                  placeholder={t("ad-material-other-details")}
                  value={state?.originalText}
                  onChange={handleOnChange("originalText", dispatch)}
                />
              </Label>
              <Label
                text={`${t("images")}*`}
                dark
                bold
                style={{ paddingBottom: 0 }}
              >
                <>
                  <div>
                    <AdFiles
                      readOnly={!canEdit(state, "images", isEdit)}
                      required={state?.type === "sale"}
                      files={state?.files}
                      placeholder={t("select-drop-files")}
                      dropPlaceholder={t("drop-files")}
                      afterUpload={(e) => {
                        if (!_.isEmpty(e)) {
                          handleAddImage(e[0]);
                        }
                      }}
                      onChange={(e) => {}}
                      type="ad"
                      iconStyles={{
                        background: "#F5F5F5",
                        borderRadius: 15,
                        padding: "7px 12px",
                      }}
                      t={t}
                    />
                  </div>
                </>
              </Label>
              <FilesList
                readOnly={!canEdit(state, "images", isEdit)}
                files={state?.files?.map((f, index) => ({
                  ...f,
                  index,
                }))}
                handleDrop={() => {
                  dispatch({
                    type: actions.UPDATE_INPUT,
                    payload: { ...state, files: filesBuffer.current },
                  });
                  filesBuffer.current = [];
                }}
                handleSort={handleSortImage}
                handleDelete={
                  canEdit(state, "files", isEdit)
                    ? handleDeleteImage
                    : undefined
                }
              />
            </Item>
            <Item text={t("delivery-info")}>
              {state?.type === "purchase" && (
                <>
                  <Alert
                    style={{
                      marginTop: 0,
                    }}
                    type="warning"
                  >
                    {t("incoterm_warm_desc")}
                  </Alert>
                  <Label
                    text={`${t("incoterm")}*`}
                    dark
                    bold
                    small
                    style={{
                      paddingBottom: "0",
                      cursor: "pointer",
                    }}
                    onMoreInfo={() => {
                      setModalType(modalTypes.INCOTERM_INFO);
                    }}
                    moreInfoText={t("more-information")}
                  ></Label>
                  <CatalogSearch
                    preload
                    disableEnterText
                    avoidReload
                    inputProps={{
                      readOnly: !canEdit(state, "", isEdit),
                      required: true,
                      style: {
                        marginBottom: 20,
                      },
                    }}
                    initialValue={state?.incoterm?.name}
                    catalogName={"incoterms"}
                    onClickCallback={(
                      option,
                      open,
                      setOpen,
                      value,
                      setValue
                    ) => {
                      if (option.extra === "port") {
                        setIsPort(true);
                        handleAddress(dispatch)(
                          {
                            port: "empty",
                          },
                          false,
                          true
                        );
                      } else {
                        setIsPort(false);
                        handleAddress(dispatch)(
                          {
                            port: undefined,
                          },
                          false,
                          true
                        );
                      }
                      handleOnChange(
                        "incoterm",
                        dispatch
                      )({
                        target: {
                          value: option.uuid,
                        },
                      });
                      setTimeout(() => {
                        setOpen(false);
                      }, 100);
                    }}
                  />
                </>
              )}
              <Typography>
                {state?.type === "purchase"
                  ? `${t("address-unload")} *`
                  : `${t("address-load")} *`}
              </Typography>
              <AddressForm
                id={state?.address?.uuid || state?.address?.id}
                state={state}
                uuidOrg={state?.uuidOrg}
                readOnly={!canEdit(state, "uuidOrg", isEdit)}
                streetProps={{
                  readOnly: !canEdit(state, "", isEdit),
                  required: true,
                  placeholder: t("write-here"),
                  name: "street",
                  onChange: (e) =>
                    handleAddress(dispatch)({
                      street: e.target.value,
                    }),
                  value: state?.address?.street,
                }}
                townProps={{
                  readOnly: !canEdit(state, "", isEdit),
                  required: true,
                  placeholder: t("write-here"),
                  name: "city",
                  onChange: (e) =>
                    handleAddress(dispatch)({
                      city: e.target.value,
                    }),
                  value: state?.address?.city,
                }}
                countryProps={{
                  readOnly: !canEdit(state, "", isEdit),
                  required: true,
                  placeholder: t("write-here"),
                  name: "country-id",
                  onChangeCountry: (option, open, setOpen) => {
                    handleAddress(dispatch)({
                      countryCode: option?.uuid?.split("-")?.[0],
                    });
                    setTimeout(() => setOpen(false), 20);
                  },
                  value: state?.address?.countryCode,
                }}
                provinceProps={{
                  readOnly: !canEdit(state, "", isEdit),
                  required: true,
                  placeholder: t("write-here"),
                  name: "state",
                  onChange: (e) =>
                    handleAddress(dispatch)({
                      state: e.target.value,
                    }),
                  value: state?.address?.state,
                }}
                zipProps={{
                  readOnly: !canEdit(state, "", isEdit),
                  required: true,
                  placeholder: t("write-here"),
                  name: "postal-code",
                  onChange: (e) =>
                    handleAddress(dispatch)({
                      postalCode: e.target.value,
                    }),
                  value: state?.address?.postalCode,
                }}
                markFavorite={(id) => (e) => {
                  handleAddress(dispatch)({ id }, id !== null);
                }}
                savedDirectionProps={{
                  readOnly: !canEdit(state, "", isEdit),
                  onChange: canEdit(state, "", isEdit)
                    ? (option, open, setOpen) => {
                        handleAddress(dispatch)({ id: option?.uuid }, true);
                      }
                    : undefined,
                  val: state?.address?.uuid || state?.address?.id,
                }}
              />
              <Spacer height={20} />
              {isPort && (
                <>
                  <Label
                    text={`${t("delivery_port")} *`}
                    dark
                    bold
                    small
                    style={{
                      paddingBottom: "20px",
                    }}
                  >
                    <CatalogSearch
                      remote
                      id="SelectedPort"
                      inputProps={{
                        readOnly: !canEdit(state, "", isEdit),
                        required: true,
                        placeholder: t("write-or-select"),
                      }}
                      initialValue={
                        state?.address?.port && state?.address?.port !== "empty"
                          ? state?.address?.port?.name
                          : undefined
                      }
                      catalogName={"ports"}
                      avoidInitalLoad={true}
                      onClickCallback={(
                        option,
                        open,
                        setOpen,
                        value,
                        setValue
                      ) => {
                        handleAddress(dispatch)({
                          port: option.uuid,
                        });
                        setTimeout(() => {
                          setOpen(false);
                        }, 100);
                      }}
                    />
                  </Label>
                </>
              )}
              <Spacer height={25} />
              <Label
                text={
                  state?.type === "purchase"
                    ? `${t("ad-load-preferences")}`
                    : `${t("ad-load-carateristics")} *`
                }
                dark
                bold
              >
                <Row
                  style={{
                    width: "100%",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                  gap={15}
                >
                  <Chip
                    big
                    type={
                      !_.isEmpty(
                        state?.loadCharacteristics?.filter(
                          (lc) => lc.type === LoadCharacteristicasTypes.GRANEL
                        )
                      )
                        ? !canEdit(state, "", isEdit)
                          ? "gray40"
                          : "data"
                        : "normal"
                    }
                    text={t(LoadCharacteristicasTypes.GRANEL)}
                    handleClick={
                      canEdit(state, "", isEdit)
                        ? () => {
                            handleLoadCharacteristics(
                              LoadCharacteristicasTypes.GRANEL,
                              {
                                type: LoadCharacteristicasTypes.GRANEL,
                                value: {
                                  weight: 0,
                                },
                              },
                              state?.type !== "purchase"
                            );
                          }
                        : undefined
                    }
                    style={{ flex: "1 1", justifyContent: "center" }}
                  />
                  <Chip
                    big
                    type={
                      !_.isEmpty(
                        state?.loadCharacteristics?.filter(
                          (lc) => lc.type === LoadCharacteristicasTypes.SACAS
                        )
                      )
                        ? !canEdit(state, "", isEdit)
                          ? "gray40"
                          : "data"
                        : "normal"
                    }
                    text={t(LoadCharacteristicasTypes.SACAS)}
                    handleClick={
                      canEdit(state, "", isEdit)
                        ? () => {
                            handleLoadCharacteristics(
                              LoadCharacteristicasTypes.SACAS,
                              {
                                type: LoadCharacteristicasTypes.SACAS,
                              },
                              false
                            );
                          }
                        : undefined
                    }
                    style={{ flex: "1 1", justifyContent: "center" }}
                  />
                  <Chip
                    big
                    type={
                      !_.isEmpty(
                        state?.loadCharacteristics?.filter(
                          (lc) => lc.type === LoadCharacteristicasTypes.PALET
                        )
                      )
                        ? !canEdit(state, "", isEdit)
                          ? "gray40"
                          : "data"
                        : "normal"
                    }
                    text={t("paletizada")}
                    handleClick={
                      canEdit(state, "", isEdit)
                        ? () => {
                            handleLoadCharacteristics(
                              LoadCharacteristicasTypes.PALET,
                              {
                                type: LoadCharacteristicasTypes.PALET,
                              },
                              false
                            );
                          }
                        : undefined
                    }
                    style={{ flex: "1 1", justifyContent: "center" }}
                  />
                </Row>
              </Label>
              {!_.isEmpty(
                state?.loadCharacteristics?.filter(
                  (lc) => lc.type === LoadCharacteristicasTypes.GRANEL
                )
              ) && (
                <LoadCharacteristicsAttributes
                  readOnly={!canEdit(state, "", isEdit)}
                  volumeProps={{
                    required: false,
                    value: state?.loadCharacteristics?.find(
                      (f) => f.type === LoadCharacteristicasTypes.GRANEL
                    )?.value?.weight,
                  }}
                  onVolumeChange={(e) => {
                    handleLoadCharacteristics(
                      LoadCharacteristicasTypes.GRANEL,
                      {
                        weight: e?.target?.value * 1,
                      }
                    );
                  }}
                />
              )}
              {!_.isEmpty(
                state?.loadCharacteristics?.filter(
                  (lc) => lc.type === LoadCharacteristicasTypes.SACAS
                )
              ) && (
                <LoadCharacteristicsAttributes
                  readOnly={!canEdit(state, "", isEdit)}
                  label={t("ad-saca-carateristics")}
                  weightProps={{
                    required: state?.type === "sale",
                    value: state?.loadCharacteristics?.find(
                      (f) => f.type === LoadCharacteristicasTypes.SACAS
                    )?.value?.weight,
                  }}
                  onWeightChange={(e) => {
                    handleLoadCharacteristics(LoadCharacteristicasTypes.SACAS, {
                      weight: e?.target?.value * 1,
                    });
                  }}
                  depthProps={{
                    required: state?.type === "sale",
                    value: state?.loadCharacteristics?.find(
                      (f) => f.type === LoadCharacteristicasTypes.SACAS
                    )?.value?.depth,
                  }}
                  onDepthChange={(e) => {
                    handleLoadCharacteristics(LoadCharacteristicasTypes.SACAS, {
                      depth: e?.target?.value * 1,
                    });
                  }}
                  heightProps={{
                    required: state?.type === "sale",
                    value: state?.loadCharacteristics?.find(
                      (f) => f.type === LoadCharacteristicasTypes.SACAS
                    )?.value?.height,
                  }}
                  onHeightChange={(e) => {
                    handleLoadCharacteristics(LoadCharacteristicasTypes.SACAS, {
                      height: e?.target?.value * 1,
                    });
                  }}
                  widthProps={{
                    required: state?.type === "sale",
                    value: state?.loadCharacteristics?.find(
                      (f) => f.type === LoadCharacteristicasTypes.SACAS
                    )?.value?.width,
                  }}
                  onWidthChange={(e) => {
                    handleLoadCharacteristics(LoadCharacteristicasTypes.SACAS, {
                      width: e?.target?.value * 1,
                    });
                  }}
                  hint={t("ad_saca_pale_tooltip")}
                />
              )}
              {!_.isEmpty(
                state?.loadCharacteristics?.filter(
                  (lc) => lc.type === LoadCharacteristicasTypes.PALET
                )
              ) && (
                <LoadCharacteristicsAttributes
                  readOnly={!canEdit(state, "", isEdit)}
                  label={t("ad-pale-carateristics")}
                  weightProps={{
                    required: state?.type === "sale",
                    value: state?.loadCharacteristics?.find(
                      (f) => f.type === LoadCharacteristicasTypes.PALET
                    )?.value?.weight,
                  }}
                  onWeightChange={(e) => {
                    handleLoadCharacteristics(LoadCharacteristicasTypes.PALET, {
                      weight: e?.target?.value * 1,
                    });
                  }}
                  depthProps={{
                    required: state?.type === "sale",
                    value: state?.loadCharacteristics?.find(
                      (f) => f.type === LoadCharacteristicasTypes.PALET
                    )?.value?.depth,
                  }}
                  onDepthChange={(e) => {
                    handleLoadCharacteristics(LoadCharacteristicasTypes.PALET, {
                      depth: e?.target?.value * 1,
                    });
                  }}
                  heightProps={{
                    required: state?.type === "sale",
                    value: state?.loadCharacteristics?.find(
                      (f) => f.type === LoadCharacteristicasTypes.PALET
                    )?.value?.height,
                  }}
                  onHeightChange={(e) => {
                    handleLoadCharacteristics(LoadCharacteristicasTypes.PALET, {
                      height: e?.target?.value * 1,
                    });
                  }}
                  widthProps={{
                    required: state?.type === "sale",
                    value: state?.loadCharacteristics?.find(
                      (f) => f.type === LoadCharacteristicasTypes.PALET
                    )?.value?.width,
                  }}
                  onWidthChange={(e) => {
                    handleLoadCharacteristics(LoadCharacteristicasTypes.PALET, {
                      width: e?.target?.value * 1,
                    });
                  }}
                  hint={t("ad_saca_pale_tooltip")}
                />
              )}
            </Item>
            {state?.type === adTypes.PURCHASE && (
              <Item text={t("payment_conditions")}>
                <Alert
                  style={{
                    marginTop: 0,
                  }}
                  type="warning"
                >
                  {t("payment_conditions_desc")}
                </Alert>
                <Label
                  text={`${t("payment-method")} *`}
                  style={{
                    paddingBottom: 0,
                    cursor: "pointer",
                  }}
                  dark
                  bold
                  small
                  onMoreInfo={() => {
                    setModalType(modalTypes.PAYMENT_METHOD_INFO);
                  }}
                  moreInfoText={t("more-information")}
                ></Label>
                <CatalogSearch
                  withSearch
                  preload
                  disableEnterText
                  avoidReload
                  inputProps={{
                    readOnly: !canEdit(state, "paymentMethod", isEdit),
                    style: {
                      marginBottom: 20,
                    },
                  }}
                  initialValue={state?.paymentMethod?.name}
                  catalogName={"payment-methods"}
                  onClickCallback={(option, open, setOpen, value, setValue) => {
                    handleOnChange(
                      "paymentMethod",
                      dispatch
                    )({
                      target: {
                        value: option.uuid,
                      },
                    });
                    setTimeout(() => {
                      setOpen(false);
                    }, 100);
                  }}
                />
              </Item>
            )}
          </form>
        </div>
        {renderPreviewImages()}
      </div>
      <ModalFactory open={modalType} setOpen={setModalType} type={modalType} />
    </Container>
  );
};

export default AdForm;
