import Label from "../../../components/ui/input/label/Label";
import InputText from "../../../components/ui/input/text/Text";
import InputEmail from "../../../components/ui/input/email/Email";
import { useTranslation } from "../../../utils/hooks";
import Container from "../../../components/ui/container/Container";
import {
  handleCountryClick,
  handleDate,
  handleOnChange,
  renderComponentWithPermission,
  renderDisabledComponentWithTooltip,
} from "../../../utils/functions";
import WidgetFieldSaveDiscard from "../../fields/Save";
import { InputDate } from "@scrapadev/scrapad-front-sdk";
import InputCountries from "../../../components/ui/input/country/Country";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import { handleCRUD } from "../../../utils/crud/functions";
import { useState } from "react";
import { useHeaderProvider } from "../../../providers/header/HeaderContext";
import { orgStatus } from "../../../utils/variables";

const WidgetBusinessLegal = ({
  id,
  state,
  dispatch,
  isDirty,
  handleDiscard,
  handleParentCallback,
  extraData,
  fetchData,
}) => {
  const { t, i18n } = useTranslation(["business", "common"]);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { state: headerState } = useHeaderProvider();
  const { permissionManage = null, permissionRead = null } = extraData || {};

  const handleSubmitWidget = async (e) => {
    e.preventDefault();
    handleParentCallback();
    const data = { ...state };
    const url = data.id
      ? `org/${id}/representative-legal/${data.id}`
      : `org/${id}/representative-legal`;
    delete data.__typename;
    await handleCRUD({
      endpoint: url,
      method: "PUT",
      data: data,
      successCallback: () => fetchData(),
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading: setSubmitLoading,
    });
  };

  const handleOpenCalendar = () => {
    if (extraData.accordionRef)
      extraData.accordionRef.style.overflow = "visible";
  };

  const handleCloseCalendar = () => {
    if (extraData.accordionRef) extraData.accordionRef.style.overflow = "";
  };

  const verified = headerState?.verificationState === orgStatus.KYC_COMPLETE;

  return (
    <form onSubmit={handleSubmitWidget}>
      <Container>
        <div className="row">
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-6">
              <Label>{t("name")}</Label>
              {renderDisabledComponentWithTooltip(
                <InputText
                  readOnly={
                    verified
                      ? verified
                      : permissionManage
                      ? !permissionManage()
                      : false
                  }
                  value={state?.firstName}
                  onChange={handleOnChange("firstName", dispatch)}
                  placeholder={t("write-here")}
                />,
                verified,
                t
              )}
            </div>
          )}
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-6">
              <Label>{t("surname")}</Label>
              {renderDisabledComponentWithTooltip(
                <InputText
                  readOnly={
                    verified
                      ? verified
                      : permissionManage
                      ? !permissionManage()
                      : false
                  }
                  value={state?.lastName}
                  onChange={handleOnChange("lastName", dispatch)}
                  placeholder={t("write-here")}
                />,
                verified,
                t
              )}
            </div>
          )}
        </div>
        <div className="row">
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-6">
              <Label>{t("email")}</Label>
              <InputEmail
                readOnly={permissionManage ? !permissionManage() : false}
                value={state?.email}
                onChange={handleOnChange("email", dispatch)}
                placeholder={t("write-here")}
              />
            </div>
          )}
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-6">
              <Label>{t("dni-number")}</Label>
              {renderDisabledComponentWithTooltip(
                <InputText
                  readOnly={
                    verified
                      ? verified
                      : permissionManage
                      ? !permissionManage()
                      : false
                  }
                  value={state?.identityCard}
                  onChange={handleOnChange("identityCard", dispatch)}
                  placeholder={t("write-here")}
                />,
                verified,
                t
              )}
            </div>
          )}
        </div>

        {/* <div className="row">
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-4">
              <Label>{t("dni-number")}</Label>
              {renderDisabledComponentWithTooltip(
                <InputText
                  readOnly={
                    verified
                      ? verified
                      : permissionManage
                      ? !permissionManage()
                      : false
                  }
                  value={state?.identityCard}
                  onChange={handleOnChange("identityCard", dispatch)}
                  placeholder={t("write-here")}
                />,
                verified,
                t
              )}
            </div>
          )}
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-4">
              <Label>{t("nationality")}</Label>
              {renderDisabledComponentWithTooltip(
                <InputCountries
                  readOnly={
                    verified
                      ? verified
                      : permissionManage
                      ? !permissionManage()
                      : false
                  }
                  handleItemClick={handleCountryClick("nationality", dispatch)}
                  value={state?.nationality}
                />,
                verified,
                t
              )}
            </div>
          )}
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-4">
              <Label>{t("country-residence")}</Label>
              <InputCountries
                readOnly={permissionManage ? !permissionManage() : false}
                handleItemClick={handleCountryClick(
                  "residenceCountry",
                  dispatch
                )}
                value={state?.residenceCountry}
              />
            </div>
          )}
        </div> */}
        {/* <div className="row">
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-4">
              <Label>{t("birthdate")}</Label>
              {renderDisabledComponentWithTooltip(
                <InputDate
                  readOnly={
                    verified
                      ? verified
                      : permissionManage
                      ? !permissionManage()
                      : false
                  }
                  placeholder={t("write-here")}
                  value={state?.birthDate}
                  handleCalendarOpen={handleOpenCalendar}
                  handleCalendarClose={handleCloseCalendar}
                  handleOnChange={handleDate("birthDate", dispatch)}
                  languageCode={i18n.language}
                  numberOfYears={100}
                  yearsBackwards
                />,
                verified,
                t
              )}
            </div>
          )}
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-4">
              <Label>{t("date-towm")}</Label>
              {renderDisabledComponentWithTooltip(
                <InputText
                  required={verified ? false : true}
                  readOnly={
                    verified
                      ? verified
                      : permissionManage
                      ? !permissionManage()
                      : false
                  }
                  value={state?.birthPlace}
                  onChange={handleOnChange("birthPlace", dispatch)}
                  placeholder={t("write-here")}
                />,
                verified,
                t
              )}
            </div>
          )}
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-4">
              <Label>{t("country-date")}</Label>
              {renderDisabledComponentWithTooltip(
                <InputCountries
                  required={verified ? false : true}
                  readOnly={
                    verified
                      ? verified
                      : permissionManage
                      ? !permissionManage()
                      : false
                  }
                  handleItemClick={handleCountryClick("birthCountry", dispatch)}
                  value={state?.birthCountry}
                />,
                verified,
                t
              )}
            </div>
          )}
        </div> */}
        <WidgetFieldSaveDiscard
          permission={permissionManage}
          isDirty={isDirty}
          handleDiscard={handleDiscard}
          loading={submitLoading}
        />
      </Container>
    </form>
  );
};

export default WidgetBusinessLegal;
