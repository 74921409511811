import { Loading, Switch, WARM_NEUTRAL_20 } from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { INFO_COLOR } from "../../../utils/variables";

/**
 * Helper component to render a on/off switch for business preferences.
 * @param {object} props. - Component properties.
 * @param {checked} props.checked - Checked property.
 * @param {() => void} props.onChange - On change event.
 * @param {boolean} props.isDefault - If this item is marked as a defaul.
 * @returns {React.ReactNode}
 */
const PreferencesPaymentMethodSwitch = ({
  checked,
  onChange,
  isDefault,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  if (loading) {
    return (
      <div style={{ width: 30, height: 18, margin: "-30px 0 0 0" }}>
        <Loading />
      </div>
    );
  }
  return (
    <Switch
      checked={checked}
      onChange={
        isDefault
          ? undefined
          : async (event) => {
              setLoading(true);
              await onChange(event.target.checked);
              setLoading(false);
            }
      }
      inputStyle={{
        cursor: isDefault ? "not-allowed" : "pointer",
        backgroundColor: checked
          ? isDefault
            ? "#9CC8FF"
            : INFO_COLOR
          : WARM_NEUTRAL_20,
      }}
      {...props}
    />
  );
};

export default PreferencesPaymentMethodSwitch;
