import {
  Button,
  ChipDropdown,
  ClearButton,
  Disclaimer,
  DropDown,
  Flex,
  InputText,
  Label,
  Loading,
  Modal,
  Paper,
  Row,
  SearchAutoComplete,
  Typography,
} from "@scrapadev/scrapad-front-sdk";
import { actions } from "../../../providers/transactions/actions/transactions-forms";
import { useTransactionsFormsProvider } from "../../../providers/transactions/TransactionsFormContext";
import {
  checkPermission,
  handleFormOK,
  handleItemClick,
} from "../../../utils/functions";
import { useTranslation } from "../../../utils/hooks";
import {
  currencyOptionsMap,
  currencySymbolMapWithWeight,
  depositPendingTypes,
  financesCurrencyOptions,
  transactionFinancesTypes,
} from "../../../utils/variables";
import { useMakePayment } from "../../../utils/finances/hooks";
import { useState } from "react";
import TabPanelWrap from "../../../pages/transactions/wrappers/TabPanelWrap";
import TransactionFiles from "../../ui/input/file/File";
import AttachmentButton from "../../ui/attachment-button/AttachmentButton";
import deepmerge from "deepmerge";
import { useGQL } from "../../../utils/gql/hooks";
import { getCatalogNoTerm } from "../../../model/filters";
import { buildChangePaymentMethod } from "./functions";
import { useUser } from "../../../providers/AuthHooks";
import { useCRUD } from "../../../utils/crud/hooks";

/**
 * Finances forms for transactions.
 * @param {Object} props - Component properties.
 * @param {("request"|"make")} props.type - Type of form.
 * @param {String} props.uuidVendedor - Seller uuid.
 * @param {String} props.uuidComprador - Buyer uuid.
 * @param {Function} props.permission - Permission check function.
 * @returns {JSX.Element}
 */
const FinancesForm = ({
  fetchData,
  type = transactionFinancesTypes.REQUEST,
  uuidVendedor,
  uuidComprador,
  permission,
  onClose = () => {},
  buyerPaymentMethod,
  sellerPaymentMethod,
}) => {
  const { t, i18n } = useTranslation(["common", "transactions", "finances"]);
  const { state, dispatch } = useTransactionsFormsProvider();
  const user = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const checkdPermission = checkPermission(permission);
  const [showFile, setShowFile] = useState(false);
  const [methodNew, setMethodNew] = useState();
  const { CRUD } = useCRUD();
  const { handleMakePayment, handleRequestDeposit } = useMakePayment(
    () => {
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: {
          loading: false,
          destination: {
            seller: false,
            buyer: true,
          },
        },
      });
    },
    () => {
      handleFormOK(t, fetchData);
    }
  );
  const { data: methodData, loading: methodLoading } = useGQL(
    getCatalogNoTerm,
    {
      locale: i18n.language,
      name: "payment-methods",
    }
  );
  const selleerBuyerData = [
    { uuid: uuidComprador, name: t("buyer") },
    { uuid: uuidVendedor, name: t("seller") },
  ];
  const paymentData = [
    {
      uuid: depositPendingTypes.FIRST_DEPOSIT_PENDING,
      text: `${t("first-deposit-pending")}`,
    },
    {
      uuid: depositPendingTypes.SECOND_DEPOSIT_PENDING,
      text: `${t("second-deposit-pending")}`,
    },
  ];

  const handleType = (t, other) => {
    const newType = { ...state.destination };
    let uuidType = "";
    newType[t] = !newType[t];
    if (newType[other] && newType[t]) {
      newType[other] = false;
    }
    if (newType["buyer"]) uuidType = uuidComprador;
    if (newType["seller"]) uuidType = uuidVendedor;
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        destination: newType,
        hiddenDestination: uuidType,
      },
    });
  };

  const handleOnChange = (key) => (e) => {
    const obj = {};
    obj[key] = key === "value" ? e.target.valueAsNumber : e.target.value;
    dispatch({ type: actions.UPDATE_INPUT, payload: obj });
  };

  const renderTitle = () => {
    switch (type) {
      case transactionFinancesTypes.REQUEST:
        return t("request-payment-impersonal");
      case transactionFinancesTypes.MAKE:
        return t("income");
      case transactionFinancesTypes.EDIT:
        return t("edit_payment_modes", { ns: "common" });
      default:
        return t("finances", { ns: "common" });
    }
  };

  const renderChipHelper = () =>
    type === transactionFinancesTypes.REQUEST
      ? t("request-payment-to")
      : t("make-deposit-to");

  const renderChipItemText = () => {
    if (state?.destination.buyer) return t("buyer");
    if (state?.destination.seller) return t("seller");
    return "";
  };

  const renderChips = () => {
    return (
      <ChipDropdown
        items={selleerBuyerData.map((item, index) => (
          <ClearButton
            key={item.uuid}
            onClick={() => {
              if (item.uuid === uuidVendedor) {
                handleType("seller", "buyer");
              } else {
                handleType("buyer", "seller");
              }
            }}
          >
            {t(item.name)}
          </ClearButton>
        ))}
        type={!checkdPermission ? "normal" : "data"}
        text={renderChipItemText()}
      />
    );
  };

  const afterUpload = (files) =>
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { files: deepmerge(state.files, files) },
    });

  const afterDelete = (files) =>
    dispatch({ type: actions.UPDATE_INPUT, payload: { files: files } });

  const afterFileNameChange = (files) =>
    dispatch({ type: actions.UPDATE_INPUT, payload: { files: files } });

  // Not deleted just in case
  /*const renderRequestForm = () => {
    return (
      <>
        <div className="col-12 col-xl-5 col-lg-4 col-md-6">
          <Label>{t("concept")}</Label>
          <SearchAutoComplete
            required
            useSearch={false}
            options={paymentData}
            value={paymentData?.find((f) => f.uuid === state?.deposit)?.text}
            handleItemClick={handleItemClick("deposit", dispatch)}
            placeholder={t("write-or-select")}
            handleOnClickWithEvent={(e, value, open, setOpen) => {
              setOpen(!open);
            }}
          />
        </div>
        <div className="col-12 col-xl-4 col-lg-4 col-md-6">
          <Label>
            {type === transactionFinancesTypes.REQUEST
              ? t("request-amount")
              : t("pay-amount")}
          </Label>
          <InputText
            required
            readOnly={!checkdPermission}
            placeholder={t("write-here")}
            value={state.amount}
            onChange={handleOnChange("amount")}
          />
        </div>
      </>
    );
  };*/

  const renderForm = () => {
    return (
      <>
        <Flex flexDirection="column" alignItems="stretch">
          <Row gap={30}>
            <Label
              text={renderChipHelper()}
              noPadding
              style={{ width: "100%" }}
            >
              <DropDown
                required
                placeholder={t("select-a-value")}
                options={selleerBuyerData.map((e) => ({
                  uuid: e?.uuid,
                  text: e?.name,
                }))}
                handleItemClick={(e, open, setOpen) => {
                  handleOnChange("hiddenDestination")({
                    target: {
                      value: e.uuid,
                    },
                  });
                  // if (e.uuid === uuidVendedor) {
                  //   handleType("seller", "buyer");
                  // } else {
                  //   handleType("buyer", "seller");
                  // }
                  setTimeout(() => {
                    setOpen(false);
                  }, 15);
                }}
                value={
                  selleerBuyerData?.find(
                    (e) => e?.uuid === state?.hiddenDestination
                  )?.name
                }
                mode="underline"
              />
            </Label>
            <Row gap={10} style={{ width: "100%" }}>
              <Label
                text={`${
                  type === transactionFinancesTypes.REQUEST
                    ? t("request-amount")
                    : t("pay-amount")
                }`}
                noPadding
              >
                <InputText
                  required
                  readOnly={!checkdPermission}
                  placeholder={t("write-here")}
                  value={state.amount}
                  onChange={handleOnChange("amount")}
                  mode="underline"
                />
              </Label>
              <Label text={" "} noPadding>
                <SearchAutoComplete
                  mode="underline"
                  useSearch={false}
                  required={true}
                  style={{ marginBottom: "0" }}
                  placeholder={t("select-value")}
                  options={financesCurrencyOptions}
                  value={state?.currency || "EUR"}
                  leadingIcon={
                    currencyOptionsMap[state?.currency || "EUR"]?.icon
                  }
                  handleItemClick={handleItemClick("currency", dispatch)}
                />
              </Label>
            </Row>
          </Row>
          <Row>
            <Label text={t("concept")} noPadding>
              <InputText
                required
                readOnly={!checkdPermission}
                placeholder={t("write-here")}
                value={state.concept}
                onChange={handleOnChange("concept")}
                mode="underline"
              />
            </Label>
          </Row>
        </Flex>
      </>
    );
  };

  const renderEditForm = () => {
    return (
      <>
        {methodLoading ? (
          <Loading />
        ) : (
          <>
            <Label text={t("buyer")}>
              <SearchAutoComplete
                mode="underline"
                required={true}
                value={
                  methodData?.errors
                    ? []
                    : methodData?.find((f) => f?.uuid === methodNew)?.name
                }
                options={methodData?.errors ? [] : methodData}
                inputProps={{ placeholder: t("write-or-select") }}
                placeholder={t("select-a-value")}
                model={{ uuid: "uuid", text: "name" }}
                containerStyles={{ width: "100%" }}
                handleItemClick={(option, open, setOpen, e) => {
                  e.stopPropagation();
                  setMethodNew(option.uuid);
                  setTimeout(() => {
                    setOpen(false);
                  }, 5);
                }}
                handleOnClickWithEvent={(e, value, open, setOpen) => {
                  setOpen(!open);
                }}
                style={{ marginBottom: 0 }}
              />
              <Row>
                <Disclaimer
                  hint
                  text={`Anterior: ${buyerPaymentMethod} `}
                  style={{ border: 0, marginBottom: "0" }}
                />
              </Row>
            </Label>
            <Label text={t("seller")}>
              <InputText
                disabled
                value={sellerPaymentMethod}
                mode="underline"
              />
            </Label>
          </>
        )}
      </>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    if (transactionFinancesTypes.REQUEST === type) {
      await handleRequestDeposit(
        state,
        document.getElementById("Destination").value
      );
    } else {
      await handleMakePayment(
        state,
        document.getElementById("Destination").value
      );
    }
    setIsLoading(false);
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    const data = buildChangePaymentMethod(
      user.userUuid,
      state?.uuidEvent,
      methodNew
    );
    setIsLoading(true);
    await CRUD({
      external: true,
      data,
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      successCallback: async () => {
        await fetchData();
        setIsLoading(false);
        handleFormOK(t, fetchData);
      },
    });
  };

  return (
    <>
      <Paper
        whiteMode
        style={{
          alignItems: "stretch",
          marginLeft: "-20px",
          marginRight: "-20px",
          padding: "15px",
        }}
      >
        <TabPanelWrap title={renderTitle()} onClose={onClose}>
          <form
            onSubmit={
              type === transactionFinancesTypes.EDIT
                ? handleSubmitEdit
                : handleSubmit
            }
          >
            <InputText
              id="Destination"
              required
              value={
                state.hiddenDestination && state.hiddenDestination !== ""
                  ? state.hiddenDestination
                  : uuidComprador
              }
              customValidityMessage={t("must-select-one-receiver")}
              style={{
                visibility: "hidden",
                height: 0,
                padding: 0,
                margin: `0 0 .5rem 0`,
              }}
            />

            {type === transactionFinancesTypes.EDIT ? (
              <>{renderEditForm()}</>
            ) : (
              <>{renderForm()}</>
            )}

            <Row gap={10}>
              {type !== transactionFinancesTypes.EDIT && (
                <AttachmentButton
                  count={state?.files?.length}
                  onClick={(e) => {
                    setShowFile(true);
                  }}
                  style={{ marginRight: "auto" }}
                />
              )}

              <Button
                component="button"
                reduced
                mode="normal"
                text={t("cancel")}
                loading={state.loading}
                onClick={(e) => {
                  e.preventDefault();
                  onClose();
                }}
              />

              <Button
                disabled={!checkdPermission}
                component="button"
                reduced
                mode="info"
                text={
                  type === transactionFinancesTypes.REQUEST
                    ? t("request-payment")
                    : type === transactionFinancesTypes.EDIT
                    ? t("save")
                    : t("make-a-deposit")
                }
                loading={state.loading || isLoading}
              />
            </Row>
          </form>
        </TabPanelWrap>
      </Paper>
      <Modal
        t={t}
        open={showFile}
        setOpen={setShowFile}
        title={t("files_attach")}
        hasForm={false}
        onSubmit={() => {
          setShowFile(false);
        }}
        ActionsComponent={
          <>
            <Row gap={20} justify="flex-end" style={{ marginLeft: "auto" }}>
              <Button
                component="button"
                text={t("cancel")}
                mode="normal"
                onClick={(e) => {
                  e?.preventDefault();
                  setShowFile(false);
                }}
              />
              <Button
                component="button"
                text={t("save")}
                mode="info"
                onClick={(e) => {
                  e?.preventDefault();
                  setShowFile(false);
                }}
              />
            </Row>
          </>
        }
        submitText={t("accept")}
        cancelText={t("cancel")}
        maxWidth={900}
        modalStyles={{ width: 900 }}
        BodyComponent={
          <>
            <TransactionFiles
              files={checkdPermission ? state.files : []}
              placeholder={t("select-drop-files")}
              dropPlaceholder={t("drop-files")}
              afterUpload={afterUpload}
              afterDelete={afterDelete}
              afterFileNameChange={afterFileNameChange}
              allowedExtension={[".jpg", ".jpeg", ".png", ".pdf"]}
              readOnly={!checkdPermission}
              hideFileType
              fileType={state?.fileType}
              showFilesAndInput
            />
          </>
        }
      />
    </>
  );
};

export default FinancesForm;
