import { MaterialsCard } from "@scrapadev/scrapad-front-sdk";
import FavoriteItem from "./FavoriteItem";

const WidgetFavouritesSearchs = ({ searchs }) => {
  return (
    <>
      <MaterialsCard
        boxStyles={{
          marginBottom: 7,
        }}
        containerStyles={{ alignItems: "flex-start" }}
      >
        <FavoriteItem
          title={"Tubos de aluminio recubiertos con baño de zinc"}
          isBuy
          isSell
          ads={20}
        />
      </MaterialsCard>
      <MaterialsCard
        boxStyles={{
          marginBottom: 7,
        }}
        containerStyles={{ alignItems: "flex-start" }}
      >
        <FavoriteItem title={"Botellas limpias de PET"} isBuy ads={0} />
      </MaterialsCard>
    </>
  );
};

export default WidgetFavouritesSearchs;
