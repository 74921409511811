import { Modal } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";
import NotificationsPreferencesHelp from "../../ui/notifications/Help";

/**
 * Modal notifications help.
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalNotificationsHelp = ({ open = false, setOpen }) => {
  const { t } = useTranslation(["notifications"]);
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t("channels")}
      hasForm={false}
      hasActions={false}
      maxWidth={750}
      BodyComponent={<NotificationsPreferencesHelp />}
    />
  );
};

export default ModalNotificationsHelp;
