import { IconButton, Spinner } from "@scrapadev/scrapad-front-sdk";
import { useEffect } from "react";
import { csvLookUp, exportCSV, getAFileName } from "../../../utils/functions";
import { useGQL } from "../../../utils/gql/hooks";
import { useTranslation } from "../../../utils/hooks";

const ExportData = ({
  fnData,
  fnParameters = {},
  fileName = "exported_data",
  customParser,
  style,
}) => {
  const { i18n } = useTranslation();
  const { data, error, fetchData, loading } = useGQL(
    fnData,
    fnParameters,
    true
  );

  useEffect(() => {
    if (data) {
      let csvData = [];
      if (customParser) {
        csvData = customParser(data);
      } else {
        const headers = data.columns.map((h) => h.name);
        csvData = [
          headers,
          ...data.rows.map((b) =>
            b.cells.map((cell) => csvLookUp(cell.format, cell.value, i18n))
          ),
        ];
      }
      exportCSV(getAFileName(fileName), csvData);
    }
  }, [data]);

  const handleDownload = async () => {
    if (!fetchData) return;
    fetchData();
  };

  return (
    <>
      {loading ? (
        <Spinner size={24} />
      ) : (
        <IconButton
          outlined
          name={"file_download"}
          onClick={handleDownload}
          style={
            style || {
              width: 45,
              height: 45,
              background: "#F5F5F5",
              border: "0.5px solid #E1E1E1",
              borderRadius: "50%",
              color: "#7E7E7E",
              margin: 0,
            }
          }
          iconStyle={{ fontSize: 18 }}
        />
      )}
    </>
  );
};

export default ExportData;
