import {
  BREAKPOINT_MD,
  Flex,
  Heading,
  Icon,
  Label,
  Paper,
  Radio,
  Row,
  Typography,
  useMatchMedia,
  WARM_NEUTRAL_30,
  WARM_NEUTRAL_40,
} from "@scrapadev/scrapad-front-sdk";
import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../../utils/hooks";
import { handleOnChange } from "../../../../utils/functions";
import CatalogSearch from "../../../ui/input/search/CatalogSearch";
import LoadPossibilities from "../../../ui/load-possibilities/LoadPossibilities";
import AddressInput from "../../../ui/address/AddressInput";
import { LOAD_POSSIBILITY } from "../../../../utils/variables";

function RouteForm({ dispatch = () => {}, state = {}, uuidSeller, uuidBuyer }) {
  const { t } = useTranslation("common");
  const [isPortSeller, setIsPortSeller] = useState(false);
  const [isPortBuyer, setIsPortBuyer] = useState(false);
  const [loadFacilitiesSetted, setLoadFacilitiesSetter] = useState(false);
  const [loadPossibilities, setLoadPossibilities] = useState(
    state?.loadPossibilities || []
  );
  const [unloadFacilitiesSetted, setUnloadFacilitiesSetter] = useState(false);
  const [unloadPossibilities, setUnloadPossibilities] = useState(
    state?.unloadPossibilities || []
  );
  const matchesMD = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);

  useEffect(() => {
    handleOnChange(
      "loadPossibilities",
      dispatch
    )({
      target: {
        value: loadPossibilities,
      },
    });
  }, [loadPossibilities]);

  useEffect(() => {
    handleOnChange(
      "unloadPossibilities",
      dispatch
    )({
      target: {
        value: unloadPossibilities,
      },
    });
  }, [unloadPossibilities]);

  const handleLoadPossibilities = (choice, unload = false) => {
    if (choice === LOAD_POSSIBILITY.none) {
      if (unload) {
        setUnloadPossibilities([choice]);
      } else {
        setLoadPossibilities([choice]);
      }
    } else {
      if (unload) {
        let lClean = unloadPossibilities.filter(
          (e) => e !== LOAD_POSSIBILITY.none
        );
        if (lClean.includes(choice)) {
          setUnloadPossibilities(lClean.filter((e) => e !== choice));
        } else {
          setUnloadPossibilities([...lClean, choice]);
        }
      } else {
        let lClean = loadPossibilities.filter(
          (e) => e !== LOAD_POSSIBILITY.none
        );
        if (lClean.includes(choice)) {
          setLoadPossibilities(lClean.filter((e) => e !== choice));
        } else {
          setLoadPossibilities([...lClean, choice]);
        }
      }
    }
  };

  let extraStyles = {};
  if (matchesMD) {
    extraStyles = {
      width: "calc(50% - 5px)",
    };
  }

  return (
    <Flex flexDirection="column" alignItems="stretch" style={{ gap: "10px" }}>
      <Paper whiteMode style={{ alignItems: "stretch", marginBottom: "10px" }}>
        <Label
          text={t("transport-type")}
          small
          style={{ paddingBottom: "13px" }}
        >
          <Row gap={20}>
            <Row align="center">
              <Radio
                text={t("by-sea")}
                id="transportType_sea"
                name="transportType"
                onChange={() => {
                  handleOnChange(
                    "transportType",
                    dispatch
                  )({
                    target: {
                      value: "sea",
                    },
                  });
                }}
                checked={state?.transportType === "sea"}
                required
              />
            </Row>
            <Row>
              <Radio
                text={t("ground-only")}
                id="transportType_ground"
                name="transportType"
                onChange={() => {
                  handleOnChange(
                    "transportType",
                    dispatch
                  )({
                    target: {
                      value: "ground",
                    },
                  });
                }}
                checked={state?.transportType === "ground"}
                required
              />
            </Row>
          </Row>
        </Label>
      </Paper>
      <Paper
        whiteMode
        style={{
          alignItems: "stretch",
          marginBottom: "15px",
          gap: "15px",
        }}
      >
        <Heading type={6} style={{ marginBottom: 0 }}>
          {t("origin")}
        </Heading>

        <Label
          text={`${t("incoterm_seller")}`}
          small
          style={{
            paddingBottom: "0",
            marginBottom: 0,
            cursor: "pointer",
          }}
        >
          <CatalogSearch
            preload
            disableEnterText
            avoidReload
            inputProps={{
              required: true,
              style: {
                marginBottom: 20,
              },
              mode: "underline",
            }}
            withDescription
            initialValue={state?.incoterm?.name}
            catalogName={"incoterms"}
            onClickCallback={(option, open, setOpen, value, setValue) => {
              handleOnChange(
                "incoterm",
                dispatch
              )({
                target: {
                  value: option.uuid,
                },
              });

              setTimeout(() => {
                if (option.extra === "port") {
                  setIsPortSeller(true);
                  handleOnChange(
                    "originAddress",
                    dispatch
                  )({
                    target: {
                      value: undefined,
                    },
                  });
                } else {
                  setIsPortSeller(false);
                  handleOnChange(
                    "originPort",
                    dispatch
                  )({
                    target: {
                      value: null,
                    },
                  });
                }
                setOpen(false);
              }, 100);
            }}
          />
          <Row align="center" gap={5} style={{ marginTop: "-15px" }}>
            <Icon
              name={"info"}
              color={WARM_NEUTRAL_40}
              style={{
                fontSize: "12px",
              }}
            />
            <Typography
              color="light"
              small
              style={{ margin: 0, fontSize: "10px" }}
            >
              Incoterms® 2020
            </Typography>
          </Row>
        </Label>

        <Label text={`${t("origin_address")}`} small noPadding>
          <AddressInput
            orgUuid={uuidSeller}
            onValueChange={(e) => {
              handleOnChange(
                "originAddress",
                dispatch
              )({
                target: {
                  value: e,
                },
              });
            }}
            onFacilitiesAlreadySetted={(e) => {
              setLoadFacilitiesSetter(e);
            }}
          />
        </Label>
        {state?.transportType === "sea" ||
          (isPortSeller && (
            <Label text={`${t("origin_port")}`} small noPadding>
              <CatalogSearch
                mode="underline"
                id="SelectedPort_new_offer"
                leadingIcon={<Icon name="search" color={WARM_NEUTRAL_30} />}
                inputProps={{
                  required: true,
                  name: "port",
                  placeholder: t("write-or-select"),
                  style: {
                    marginBottom: "0",
                  },
                }}
                remote
                threshold={0}
                catalogName={"ports"}
                avoidInitalLoad={true}
                onClickCallback={(option, open, setOpen, value, setValue) => {
                  handleOnChange(
                    "originPort",
                    dispatch
                  )({
                    target: {
                      value: option.uuid,
                    },
                  });
                  setTimeout(() => {
                    setOpen(false);
                  }, 100);
                }}
              />
            </Label>
          ))}
        {!loadFacilitiesSetted && (
          <LoadPossibilities
            title={t("load_possibilities")}
            description={t("load_possibilities_desc")}
            // data={(equipmentsData || []).map((item) => {
            //   return { type: item.uuid, text: t(item.name) };
            // })}
            handleClick={(type) => handleLoadPossibilities(type)}
            showIconFn={(type) => loadPossibilities.includes(type)}
            typeFn={(type) => loadPossibilities.includes(type)}
            extraStyles={extraStyles}
          />
        )}
      </Paper>
      <Paper
        whiteMode
        style={{
          alignItems: "stretch",
          marginBottom: "15px",
          gap: "15px",
        }}
      >
        <Heading type={6} style={{ marginBottom: 0 }}>
          {t("destination")}
        </Heading>

        <Label
          text={`${t("incoterm_buyer")}`}
          small
          style={{
            paddingBottom: "0",
            marginBottom: 0,
            cursor: "pointer",
          }}
        >
          <CatalogSearch
            preload
            disableEnterText
            avoidReload
            inputProps={{
              required: true,
              style: {
                marginBottom: 20,
              },
              mode: "underline",
            }}
            withDescription
            initialValue={state?.incoterm?.name}
            catalogName={"incoterms-purchase"}
            onClickCallback={(option, open, setOpen, value, setValue) => {
              handleOnChange(
                "incotermBuyer",
                dispatch
              )({
                target: {
                  value: option.uuid,
                },
              });

              console.log(option);

              setTimeout(() => {
                if (option.extra === "port") {
                  setIsPortBuyer(true);
                  handleOnChange(
                    "destinationAddress",
                    dispatch
                  )({
                    target: {
                      value: undefined,
                    },
                  });
                } else {
                  setIsPortBuyer(false);
                  handleOnChange(
                    "destinationPort",
                    dispatch
                  )({
                    target: {
                      value: null,
                    },
                  });
                }
                setOpen(false);
              }, 100);
            }}
          />
          <Row align="center" gap={5} style={{ marginTop: "-15px" }}>
            <Icon
              name={"info"}
              color={WARM_NEUTRAL_40}
              style={{
                fontSize: "12px",
              }}
            />
            <Typography
              color="light"
              small
              style={{ margin: 0, fontSize: "10px" }}
            >
              Incoterms® 2020
            </Typography>
          </Row>
        </Label>
        {!isPortBuyer ? (
          <Label text={`${t("destination_address")}`} small noPadding>
            <AddressInput
              orgUuid={uuidBuyer}
              onValueChange={(e) => {
                handleOnChange(
                  "destinationAddress",
                  dispatch
                )({
                  target: {
                    value: e,
                  },
                });
              }}
              onFacilitiesAlreadySetted={(e) => {
                setUnloadFacilitiesSetter(e);
              }}
            />
          </Label>
        ) : (
          <Label text={`${t("destination_port")}`} small noPadding>
            <CatalogSearch
              mode="underline"
              id="SelectedPort_new_offer"
              leadingIcon={<Icon name="search" color={WARM_NEUTRAL_30} />}
              inputProps={{
                required: true,
                name: "port",
                placeholder: t("write-or-select"),
                style: {
                  marginBottom: "0",
                },
              }}
              remote
              threshold={0}
              catalogName={"ports"}
              avoidInitalLoad={true}
              onClickCallback={(option, open, setOpen, value, setValue) => {
                handleOnChange(
                  "destinationPort",
                  dispatch
                )({
                  target: {
                    value: option.uuid,
                  },
                });
                setTimeout(() => {
                  setOpen(false);
                }, 100);
              }}
            />
          </Label>
        )}
        {!unloadFacilitiesSetted && (
          <LoadPossibilities
            title={t("load_possibilities")}
            description={t("load_possibilities_desc")}
            // data={(equipmentsData || []).map((item) => {
            //   return { type: item.uuid, text: t(item.name) };
            // })}
            handleClick={(type) => handleLoadPossibilities(type, true)}
            showIconFn={(type) => unloadPossibilities.includes(type)}
            typeFn={(type) => unloadPossibilities.includes(type)}
            extraStyles={extraStyles}
          />
        )}
      </Paper>
    </Flex>
  );
}

export default RouteForm;
