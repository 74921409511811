import { useState } from "react";
import { actions } from "../../../../providers/transactions/actions/transactions-forms";
import { useTransactionsFormsProvider } from "../../../../providers/transactions/TransactionsFormContext";
import { checkPermission } from "../../../../utils/functions";
import IconButton from "../../../ui/icon-button/IconButton";
import TransactionFiles from "../../../ui/input/file/File";
import styles from "./MessageActionsMinimal.module.scss";
import { Button, InputText, Modal, Row } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../../utils/hooks";
import deepmerge from "deepmerge";

/**
 * Messages, upload and buttons component for transactions forms.
 * @param {Object} props - Component properties.
 * @param {String} props.value - Input value.
 * @param {String} props.placeholder - Input placeholder.
 * @param {String} props.filePlaceholder - File placeholder.
 * @param {String} props.fileDropPlaceholder - File placeholder when file is dropping.
 * @returns {JSX.Element}
 */
const MessageActionsMinimal = ({
  value,
  loading = true,
  placeholder,
  filePlaceholder,
  fileDropPlaceholder,
  permission,
  multiple = true,
}) => {
  const { t } = useTranslation(["common"]);
  const [showFile, setShowFile] = useState(false);
  const { state, dispatch } = useTransactionsFormsProvider();
  const checkdPermission = checkPermission(permission);
  const handleShowfile = () => {
    setShowFile(!showFile);
    // dispatch({ type: actions.UPDATE_INPUT, payload: { files: [] } });
  };
  const afterUpload = (files) =>
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { files: multiple ? deepmerge(state.files, files) : files },
    });

  const afterDelete = (files) =>
    dispatch({ type: actions.UPDATE_INPUT, payload: { files: files } });

  const afterFileNameChange = (files) =>
    dispatch({ type: actions.UPDATE_INPUT, payload: { files: files } });

  const handleOnChange = (e) =>
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { content: e.target.value },
    });

  const handleOnFileTypeChange = (e, open, setOpen) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { fileType: e?.uuid },
    });
    setTimeout(() => {
      setOpen(false);
    }, 25);
  };

  return (
    <>
      <Modal
        t={t}
        open={showFile}
        setOpen={setShowFile}
        title={t("files_attach")}
        hasForm={false}
        loading={loading}
        onSubmit={() => {
          setShowFile(false);
        }}
        ActionsComponent={
          <>
            <Row gap={20} justify="flex-end" style={{ marginLeft: "auto" }}>
              <Button
                component="button"
                text={t("cancel")}
                mode="normal"
                onClick={(e) => {
                  e?.preventDefault();
                  setShowFile(false);
                }}
              />
              <Button
                component="button"
                text={t("save")}
                mode="info"
                onClick={(e) => {
                  e?.preventDefault();
                  setShowFile(false);
                }}
              />
            </Row>
          </>
        }
        submitText={t("accept")}
        cancelText={t("cancel")}
        maxWidth={900}
        modalStyles={{ width: 900 }}
        BodyComponent={
          <>
            <TransactionFiles
              files={checkdPermission ? state.files : []}
              placeholder={filePlaceholder}
              dropPlaceholder={fileDropPlaceholder}
              afterUpload={checkdPermission ? afterUpload : undefined}
              afterDelete={checkdPermission ? afterDelete : undefined}
              afterFileNameChange={
                checkPermission ? afterFileNameChange : undefined
              }
              allowedExtension={[".jpg", ".jpeg", ".png", ".pdf"]}
              readOnly={!checkdPermission}
              onFileTypeChange={handleOnFileTypeChange}
              fileType={state?.fileType}
              showFilesAndInput
            />
          </>
        }
      />

      {/* {showFile && (
        <div className={styles["sc-forms-message-file"]}>
          <TransactionFiles
            files={checkdPermission ? state.files : []}
            placeholder={filePlaceholder}
            dropPlaceholder={fileDropPlaceholder}
            afterUpload={checkdPermission ? afterUpload : undefined}
            afterDelete={checkdPermission ? afterDelete : undefined}
            allowedExtension={[".jpg", ".jpeg", ".png", ".pdf"]}
            readOnly={!checkdPermission}
          />
        </div>
      )} */}
      <div className={styles["sc-forms-message-container"]}>
        <span className={styles["sc-forms-message-input"]}>
          <InputText
            required
            readOnly={!checkdPermission}
            value={value}
            cleanInput={true}
            placeholder={placeholder}
            onChange={handleOnChange}
            style={{
              margin: 0,
              border: 0,
            }}
          />
        </span>
        <span className={styles["sc-forms-message-actions"]}>
          <IconButton
            type="button"
            name={"attach_file"}
            onClick={checkdPermission ? handleShowfile : undefined}
            disabled={!checkdPermission}
            style={{
              background: state?.files?.length > 0 ? "#F2F7FF" : "#F5F5F5",
              borderRadius: state?.files?.length > 0 ? "30px" : "50%",
              color: checkdPermission ? "#7E7E7E" : "#FFF",
              width: state?.files?.length > 0 ? "auto" : 35,
              height: 35,
              padding: state?.files?.length > 0 ? "6px 12px 6px 10px" : "5px",
            }}
            iconStyle={{
              fontSize: "18px",
            }}
            color={state?.files?.length > 0 ? "#1674FF" : "#9E9E9E"}
          >
            {state?.files?.length > 0 ? (
              <span
                style={{
                  fontFamily: "Silka",
                  fontSize: "13px",
                  fontWeight: 600,
                  lineHeight: "19px",
                  color: "#1674FF",
                  marginLeft: "2px",
                }}
              >
                {state?.files?.length}
              </span>
            ) : undefined}
          </IconButton>

          <Button
            component={"button"}
            type="submit"
            mode="info"
            loading={loading}
            disabled={!checkdPermission}
            text={t("send", { ns: "common" })}
            reduced
          />
        </span>
      </div>
    </>
  );
};

export default MessageActionsMinimal;
