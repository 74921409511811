import { Icon, CheckBox } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import styles from "./Item.module.scss";

/**
 * TODO
 * @param {Object} props - Component properties.
 * @param {{ uuid: string, text: string }} props.option - Current option.
 * @param {Array<{ uuid: string, text: string }>} props.innerOptions - All options.
 * @param {Array<{ uuid: string, text: string }>} props.handleClick - Handle click callback.
 * @param {Array<{ uuid: string, text: string }>} props.handleIsChecked - Handle if its checked.
 * @param {React.CSSProperties} [props.style={}] - Inline styles.
 * @returns {JSX.Element}
 */
const WidgetAddressCustomItem = ({
  option,
  innerOptions,
  handleClick,
  handleIsChecked,
  style = {},
}) => {
  return (
    <li
      key={option.uuid}
      style={{ justifyContent: "flex-start", ...style }}
      onClick={handleClick(option)}
    >
      <span style={{ width: 16, height: 16, marginRight: 13, paddingRight: 0 }}>
        <CheckBox
          checked={handleIsChecked(option)}
          style={{
            paddingRight: 0,
            border: "2px solid #7E7E7E",
            borderRadius: 2,
            width: 16,
            height: 16,
          }}
        />
      </span>
      <span>{option.text}</span>
    </li>
  );
};

export default WidgetAddressCustomItem;
