import React from "react";
import styles from "./KanbanColumn.module.scss";

function KanbanColumn({ label, count, cleanStyle = false, children }) {
  return (
    <div
      className={`${styles["kanban_column"]} ${cleanStyle && styles["clean"]}`}
    >
      {label && (
        <div className={styles["heading"]}>
          {label}{" "}
          {count > 0 && (
            <span className={styles["counter"]} title={count}>
              {count}
            </span>
          )}
        </div>
      )}
      <div className={`${styles["column_list"]}`}>{children}</div>
    </div>
  );
}

export default KanbanColumn;
