import { useState } from "react";
import styles from "./LanguageSelector.module.scss";
import { setCookie } from "../../../utils/functions";
import { useTranslation } from "../../../utils/hooks";

/**
 * Public language selector
 * @param {Object} props - Component properties.
 * @param {Boolean} [props.isMobile=false] - Tells if is in mobile resolution.
 * @param {Boolean} [props.isSticky=false] - Tells if menu is sticky.
 * @returns {JSX.Element}
 */
const LanguageSelector = ({ isMobile = false, isSticky = false }) => {
  const isMobileClass = isMobile ? "" : "d-none";
  const { i18n } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(i18n.language);

  const handleOnChange = async (e) => {
    const val = e.target.value;
    setSelectedValue(val);
    i18n.changeLanguage(val);
  };

  return (
    <div
      className={`${styles["c-language-selector"]} ${styles["c-language-selector--light"]} ${isMobileClass} d-lg-flex`}
      style={{ marginBottom: isSticky ? ".25rem" : 0 }}
    >
      <i className={`large material-icons icon`}>language</i>
      <select onChange={handleOnChange} defaultValue={selectedValue}>
        {["es", "en"].map((locale) => (
          <option key={locale} value={locale}>
            {locale.toUpperCase()}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;

