import {
  BREAKPOINT_LG,
  ChipDropdown,
  ClearButton,
  Container,
  Flex,
  InputNumber,
  InputText,
  Row,
  SearchAutoComplete,
  Spacer,
  TableItem,
  Typography,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import {
  getBuyerSellerUuids,
  getTransactionId,
} from "../../../utils/finances/functions";
import { useMakePayment } from "../../../utils/finances/hooks";
import { useTranslation } from "../../../utils/hooks";
import {
  currencyOptionsMap,
  financesCurrencyOptions,
  transactionFinancesTypes,
} from "../../../utils/variables";
import Label from "../../ui/input/label/Label";
import BlueModal from "../../ui/modal/BlueModal";

/**
 * Modal for make manual payments and deposits.
 * @param {object} props - Component properties.
 * @param {object} [props.selectedRow] - Table row.
 * @param {"make"|"request"} [props.option] - If modal is for payments or deposits.
 * @param {Function} props.fetchData - Fetch data callback.
 * @returns {JSX.Element}
 */
const ModalMakePaymentsDeposits = ({ open = false, setOpen, ...props }) => {
  const { t } = useTranslation(["common", "finances", "transactions"]);
  const { option = "deposit", selectedRow = {}, fetchData } = props;
  const [loading, setLoading] = useState(false);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [concept, setConcept] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("EUR");
  const [buyerUuid, sellerUuid] = getBuyerSellerUuids(selectedRow);
  const [destination, setDestination] = useState(buyerUuid);
  const transactionCell = selectedRow?.cells?.find(
    (f) => f?.format === "image_with_text"
  );
  const selleerBuyerData = [
    { uuid: buyerUuid, name: t("buyer") },
    { uuid: sellerUuid, name: t("seller") },
  ];
  const { handleMakePayment, handleRequestDeposit } = useMakePayment(() => {
    fetchData();
    setLoading(false);
    setOpen(false);
  });

  useEffect(() => {
    if (!destination) {
      setDestination(buyerUuid);
    }
  }, [buyerUuid]);

  const renderTitle = () => {
    if (option === transactionFinancesTypes.REQUEST) {
      return t("request-payment");
    }
    return t("make-deposit-generic");
  };

  const handleSubmit = async () => {
    setLoading(true);
    const uuidEvent = getTransactionId(selectedRow);
    if (option === transactionFinancesTypes.REQUEST) {
      await handleRequestDeposit(
        { uuidEvent, concept, amount, currency },
        destination
      );
    } else {
      await handleMakePayment(
        { uuidEvent, concept, amount, currency },
        destination
      );
    }
  };

  const renderChipHelper = () =>
    option === transactionFinancesTypes.REQUEST
      ? t("request-payment-to")
      : t("make-deposit-to");

  const renderChipItemText = () => {
    if (destination === buyerUuid) return t("buyer");
    if (destination === sellerUuid) return t("seller");
    return t("buyer");
  };

  const renderChips = () => {
    return (
      <ChipDropdown
        style={{
          backgroundColor: "#fff",
          border: `1px solid #CFCFCF`,
          borderRadius: 18,
        }}
        textStyles={{
          fontFamiliy: "Silka",
          fontWeight: 500,
          fontFamily: 13,
          lineHeight: "19px",
          color: "#626262",
        }}
        items={selleerBuyerData.map((item, index) => (
          <ClearButton
            key={item.uuid}
            onClick={() => {
              setDestination(item.uuid);
            }}
          >
            {t(item.name)}
          </ClearButton>
        ))}
        text={renderChipItemText()}
      />
    );
  };

  if (!selectedRow || !transactionCell) return <></>;

  return (
    <BlueModal
      open={open}
      setOpen={setOpen}
      title={renderTitle()}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={renderTitle()}
      cancelText={t("cancel")}
      loading={loading}
      onClose={() => {
        setLoading(false);
        setAmount("");
        setConcept("");
        setDestination(null);
      }}
      modalStyles={{ width: matches ? "auto" : 950 }}
      BodyComponent={
        <Container style={{ margin: "0 -12px", width: "calc(100% + 12px)" }}>
          <div className="row">
            <div className="col-12">
              <TableItem
                cell={transactionCell}
                uuid={selectedRow?.uuid}
                itemsProps={{
                  image_with_text: {
                    firstTextProps: {
                      disableTooltip: true,
                      withEllipsis: false,
                      withHover: false,
                    },
                  },
                }}
              />
              <Spacer height={20} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Flex justifyContent={"flex-start"} alignItems={"center"}>
                <Typography
                  type="2"
                  style={{
                    marginBottom: 0,
                    marginRight: 10,
                    fontSize: 12,
                    lineHeight: "18px",
                    color: "#626262",
                    fontWeight: 500,
                  }}
                >
                  {renderChipHelper()}
                </Typography>
                {renderChips()}
              </Flex>
              <InputText
                id="Destination"
                required
                value={destination && destination !== "" ? destination : 1}
                customValidityMessage={t("must-select-one-receiver")}
                style={{
                  visibility: "hidden",
                  height: 0,
                  padding: 0,
                  margin: `0 0 .5rem 0`,
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <Label htmlFor="concept">{t("concept")}</Label>
              <InputText
                required
                id="concept"
                placeholder={t("write-here")}
                value={concept}
                onChange={(e) => setConcept(e.target.value)}
              />
            </div>
            <div className="col-12 col-lg-6">
              <Row gap={10} style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <Label htmlFor="amount">
                    {option === transactionFinancesTypes.REQUEST
                      ? t("request-amount")
                      : t("pay-amount")}
                  </Label>
                  <InputNumber
                    required
                    id="amount"
                    placeholder={t("write-here")}
                    value={amount}
                    min={0}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
                <div style={{ paddingBottom: "15px", width: "175px" }}>
                  <Label>{t("currency")}</Label>
                  <SearchAutoComplete
                    useSearch={false}
                    required={true}
                    style={{ marginBottom: "0" }}
                    placeholder={t("select-value")}
                    options={financesCurrencyOptions}
                    value={currency || "EUR"}
                    leadingIcon={currencyOptionsMap[currency || "EUR"]?.icon}
                    handleItemClick={(option, open, setOpen) => {
                      setCurrency(option?.uuid);
                      setTimeout(() => {
                        setOpen(false);
                      }, 5);
                    }}
                  />
                </div>
              </Row>
            </div>
          </div>
        </Container>
      }
    />
  );
};

export default ModalMakePaymentsDeposits;
