import React from "react";
import {
  ERROR_COLOR,
  SUCCESS_COLOR,
  WARNING_COLOR,
} from "../../../utils/variables";
import Icon from "../icon/Icon";
import styles from "./Alert.module.scss";

const mapColorIconToType = {
  error: ERROR_COLOR,
  warning: WARNING_COLOR,
  sucess: SUCCESS_COLOR,
};

/**
 * UI Alert component.
 * @param {Object} props - Component properties.
 * @param {('error'|'sucess'|'warning'|'info')} [props.type='error'] - type of alert
 * @param {React.CSSProperties} [props.style={}] - Inline Styles
 * @param {React.CSSProperties} [props.textStyle={}] - Text inline Styles.
 * @param {React.CSSProperties} [props.iconStyle={}] - Icon inline Styles
 * @returns {JSX.Element}
 */
function Alert({
  children,
  type = "error",
  style = {},
  textStyle = {},
  iconStyle = {},
  light = false,
  center = false,
  onClick,
}) {
  return (
    <div
      style={{ cursor: onClick ? "pointer" : "default", ...style }}
      className={`${styles["sc-alert"]} ${styles[`sc-alert--${type}`]} ${
        light ? styles[`sc-alert--light`] : ""
      }
      ${center ? styles[`sc-alert--center`] : ""}
      `}
      onClick={onClick}
    >
      <span className={`${styles["sc-alert__text"]}`} style={textStyle}>
        {children}
      </span>
      {onClick && (
        <Icon
          name={"navigate_next"}
          color={mapColorIconToType[type]}
          style={iconStyle}
        />
      )}
    </div>
  );
}

export default Alert;
