import {
  BREAKPOINT_LG,
  Chip,
  Flex,
  Heading,
  InputNumber,
  InputText,
  Label,
  Loading,
  Paper,
  Row,
  SearchAutoComplete,
  Spacer,
  toastError,
  toastSuccess,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import SectionHeaderPaper from "../../../components/ui/header-section/SectionHeaderPaper";
import {
  DataProvider,
  useDataProvider,
} from "../../../providers/data/DataContext";
import { useCRUD } from "../../../utils/crud/hooks";
import { useGQL } from "../../../utils/gql/hooks";
import {
  DEFAULT_LANGUAGE,
  LoadCharacteristicasTypes,
  languages,
} from "../../../utils/variables";
import { getCatalogNoTerm } from "../../../model/filters";
import {
  bodyOfHandleItemClick,
  handleItemClick,
  handleOnChange,
} from "../../../utils/functions";
import { actions } from "../../../providers/data/actions/data";
import {
  COMBINED_UUID,
  GOODS_TYPE_GENERAL,
  serviceTypes,
} from "../../../utils/logistics/variables";
import InfoText from "../../../components/ui/info-text/InfoText";
import ValidateGroup from "../../../components/ui/input/validation/ValidateGroup";
import TransportFilters from "../../../widgets/transactions/transport/Filter";
import TransportProviders from "../../../widgets/transactions/transport/Providers";
import FiltersWrapper from "../../../components/ui/filters/Wrapper";
import WidgetDataWrapper from "../../../widgets/wrappers/DataWrapper";
import { useNavigate, useParams } from "react-router-dom";
import { getNewQuotaion } from "../../../model/logistics";
import { useFiltersProvider } from "../../../providers/filters/FiltersContext";

function QuotationRequestForm(props) {
  const { i18n } = useTranslation();
  const { id } = props;

  return (
    <FiltersWrapper>
      <WidgetDataWrapper
        fnData={getNewQuotaion}
        fnParameters={{
          lang: i18n.language,
          uuidRequest: id,
        }}
        dataAdapter={(data) => {
          const load = {};
          const [depth, width, height] = data?.Dimensions;
          load.type =
            data?.FormatOfMerchandise === "empty"
              ? ""
              : data?.FormatOfMerchandise;
          load.width = width === 0 ? null : width;
          load.depth = depth === 0 ? null : depth;
          load.height = height === 0 ? null : height;
          load.raw = data?.Weight == 0 ? null : data?.Weight;
          load.number = "";
          load.bulk = data?.WeightOfBunddle == 0 ? null : data?.WeightOfBunddle;
          load.volume = data?.Volume == 0 ? null : data?.Volume;
          load.dimensions = data?.Dimensions;
          const newData = {
            type: data?.type,
            providers: [],
            origin: data?.Origin,
            destiny: data?.Destination,
            transportType:
              data?.TypeOfTransport === "empty" ? "" : data?.TypeOfTransport,
            goodsType: GOODS_TYPE_GENERAL,
            load: load,
          };
          return newData;
        }}
        Component={QuotationRequestFormInner}
        {...props}
      />
    </FiltersWrapper>
  );
}

function QuotationRequestFormInner({ state, dispatch, actions, onBack }) {
  const { id } = useParams();
  const { t, i18n } = useTranslation(
    ["common", "ads", "talk", "logistics"],
    true
  );
  const navigate = useNavigate();
  const { state: filtersState } = useFiltersProvider();
  const [loading, setLoading] = useState();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const { CRUD } = useCRUD();
  const { data: catalogData, loading: catalogLoading } = useGQL(
    [
      getCatalogNoTerm,
      getCatalogNoTerm,
      getCatalogNoTerm,
      getCatalogNoTerm,
      getCatalogNoTerm,
      getCatalogNoTerm,
    ],
    [
      {
        locale: i18n.language,
        name: "transporter-equipments",
      },
      {
        locale: i18n.language,
        name: "logistics-services",
      },
      {
        locale: i18n.language,
        name: "logistics-coverage",
      },
      {
        locale: i18n.language,
        name: "logistics-activity",
      },
      {
        locale: i18n.language,
        name: "transport-type",
      },
      {
        locale: i18n.language,
        name: "type-goods",
      },
    ]
  );

  const equipments = catalogData ? catalogData[0] : [];
  const services = catalogData ? catalogData[1] : [];
  const coverage = catalogData ? catalogData[2] : [];
  const activity = catalogData ? catalogData[3] : [];
  const equipmentsData = catalogData?.[4]?.errors ? [] : catalogData?.[4];
  const goodsData = catalogData?.[5]?.errors ? [] : catalogData?.[5];
  const transportTypeHasInitialValue = useRef(true);
  const adaptedLanguages = languages.map((language) => ({
    uuid: language?.uuid,
    text: language?.text,
    translated: t(language?.text),
  }));
  const selectedLanguageValue = adaptedLanguages?.find((language) => {
    return (
      language.uuid === state?.notification_language ||
      language.translated === t(state?.notification_language)
    );
  })?.uuid;
  /** Cuando se edita un registro de la tabla cienen los campos en el siguiente modo: equipamiento1,equipamiento2...
   * Esta función sirve para parsear ese formato */
  const equipmentDataAdapter = () => {
    if (!Array.isArray(state?.equipment)) {
      const splitted = state?.equipment?.split(",");
      return equipments
        ?.filter((f) => {
          return splitted?.find((e) => e === f?.name);
        })
        ?.map((f) => f?.uuid);
    }
    return state?.equipment;
  };

  const catalogDataApapter = (key) => {
    const mapped = {
      service: services,
      coverage: coverage,
      activity: activity,
    };
    const items = mapped[key];
    return items.find((f) => f?.name === state[key] || f?.uuid === state[key])
      ?.uuid;
  };

  const adaptedStateEquipment = equipmentDataAdapter();
  const adaptedStateService = catalogDataApapter("service");
  const adaptedStateCoverage = catalogDataApapter("coverage");
  const adaptedStateActivity = catalogDataApapter("activity");

  const coverageCheck =
    adaptedStateService === serviceTypes.PROVINCE ||
    adaptedStateService === serviceTypes.AUTONOMIC;

  const isGranel = state?.load?.type === LoadCharacteristicasTypes.GRANEL;
  const isSacas = state?.load?.type === LoadCharacteristicasTypes.SACAS;
  const isPalet = state?.load?.type === LoadCharacteristicasTypes.PALET;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let requests = [];
    let errors = [];
    state?.providers?.forEach((provider, index) => {
      const data = sendDataAdapter(index, provider?.uuid === "external");
      requests.push(
        CRUD({
          endpoint: `logistics/quotation`,
          data,
          method: "POST",
          customError: () => {},
          errorCallback: () => {
            errors.push(provider?.name);
          },
        })
      );
    });
    await Promise.allSettled(requests);
    setLoading(false);
    if (errors.length > 0) {
      toastError(`${t("one-or-more-request-failed")}: ${errors.join(", ")}`, {
        toastId: "error_quotations",
      });
    } else {
      toastSuccess(
        t("saved-changes-ok"),
        { toastId: "add_quotations" },
        "check_circle"
      );
      navigate(`/transactions/${id}/quotations`, {
        state: { uuidEvent: id },
      });
    }
    setLoading(false);
  };

  const sendDataAdapter = (index, external) => {
    const isGranel = state?.load?.type === LoadCharacteristicasTypes.GRANEL;
    const transporterData = {};
    const requestData = {
      Destination: state?.destiny,
      Origin: state?.origin,
      TypeOfBunddle: state?.load?.type,
      TotalWeight: state?.load?.raw,
      MercantType: state?.load?.type,
    };
    if (isGranel) {
      requestData.Volume = state?.load?.volume * 1;
    } else {
      requestData.WeightOfBunddle = state?.load?.bulk * 1;
      requestData.Dimensions = [
        state?.load?.depth * 1,
        state?.load?.width * 1,
        state?.load?.height * 1,
      ];
    }
    if (external) {
      transporterData.transporter_email = state?.providers?.[index]?.name;
    } else {
      transporterData.transporter_id = state?.providers?.[index]?.uuid;
    }
    const adaptedData = {
      transaction_id: id,
      type_of_goods: state?.goodsType,
      transport_type: state?.transportType,
      num_packages: isGranel ? 0 : state?.load?.number * 1,
      request_data: {
        ...requestData,
      },
      ...transporterData,
    };
    return adaptedData;
  };

  const getTransportTypeValue = () => {
    if (
      state?.transportType === COMBINED_UUID &&
      transportTypeHasInitialValue.current
    )
      return "";
    return equipmentsData?.find((f) => f?.uuid === state?.transportType)?.name;
  };

  const handleTransport = (key, dispatch) => (option, open, setOpen) => {
    transportTypeHasInitialValue.current = false;
    bodyOfHandleItemClick(key, dispatch, option, open, setOpen);
  };

  const handleLoadOnChange = (key, dispatch) => (e) => {
    const obj = { load: { ...(state.load || {}) } };
    obj.load[key] = e.target.value;
    dispatch({ type: actions.UPDATE_INPUT, payload: obj });
  };

  const handleLoadCharacteristics = (type) => () => {
    const obj = { load: { ...(state.load || {}) } };
    if (obj.load?.type !== type) {
      obj.load.type = type;
    }
    dispatch({ type: actions.UPDATE_INPUT, payload: obj });
  };

  if (Object.keys(state).length === 0) return <></>;

  return (
    <form onSubmit={handleSubmit}>
      <Paper style={{ padding: "15px", alignItems: "stretch" }}>
        <SectionHeaderPaper
          onBack={onBack}
          text={t("new-quote-request")}
          actionText={t("send-new-quote")}
          actionOnClick={() => {}}
        />
        <Paper whiteMode style={{ alignItems: "stretch" }}>
          <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ gap: "15px" }}
          >
            <Heading type={7} style={{ margin: 0 }}>
              {t("providers")}
            </Heading>

            <Flex
              flexDirection="column"
              alignItems="stretch"
              style={{ gap: "15px" }}
            >
              <TransportFilters />
              <TransportProviders
                state={state}
                dispatch={dispatch}
                actions={actions}
              />
            </Flex>
          </Flex>
        </Paper>
        <Paper whiteMode style={{ alignItems: "stretch" }}>
          <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ gap: "10px" }}
          >
            <Heading type={7} style={{ margin: 0 }}>
              {t("required-service")}
            </Heading>

            <Row gap={15}>
              <Label text={t("origin")} small noPadding>
                <InputText
                  required
                  value={state?.origin}
                  placeholder={t("write-here")}
                  onChange={handleOnChange("origin", dispatch)}
                  mode="underline"
                />
              </Label>
              <Label text={t("destiny")} small noPadding>
                <InputText
                  required
                  id="destiny"
                  value={state?.destiny}
                  placeholder={t("write-here")}
                  onChange={handleOnChange("destiny", dispatch)}
                  mode="underline"
                />
              </Label>
            </Row>
            <Row gap={15}>
              <Label text={t("goods-type")} small noPadding>
                {catalogLoading ? (
                  <Loading />
                ) : (
                  <SearchAutoComplete
                    required
                    id="goodsType"
                    inputProps={{ placeholder: t("write-or-select") }}
                    value={t(
                      goodsData?.find((a) => a.uuid === state?.goodsType)?.name
                    )}
                    options={goodsData}
                    model={{ uuid: "uuid", text: "name" }}
                    placeholder={t("select-a-value")}
                    handleItemClick={handleItemClick("goodsType", dispatch)}
                    mode="underline"
                  />
                )}
              </Label>
              <Label text={t("transport-type")} small noPadding>
                {catalogLoading ? (
                  <Loading />
                ) : (
                  <>
                    <SearchAutoComplete
                      required
                      id="transportType"
                      inputProps={{ placeholder: t("write-or-select") }}
                      value={getTransportTypeValue()}
                      options={equipmentsData}
                      trailingCallback={() => {}}
                      placeholder={t("select-a-value")}
                      model={{ uuid: "uuid", text: "name" }}
                      handleItemClick={handleTransport(
                        "transportType",
                        dispatch
                      )}
                      mode="underline"
                    />
                    {state?.transportType === COMBINED_UUID &&
                      transportTypeHasInitialValue.current && (
                        <InfoText
                          text={t("maybe-combined-transport")}
                          containerStyle={{ marginTop: -10 }}
                        />
                      )}
                    {state?.transportType === "" &&
                      transportTypeHasInitialValue.current && (
                        <InfoText
                          text={t("consult-load-seller-buyer")}
                          containerStyle={{ marginTop: -10 }}
                        />
                      )}
                  </>
                )}
              </Label>
            </Row>
          </Flex>
        </Paper>
        <Paper whiteMode style={{ alignItems: "stretch" }}>
          <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ gap: "10px" }}
          >
            <Heading type={7} style={{ margin: 0 }}>
              {t("ad-load-carateristics")}
            </Heading>
            <Row gap={15} wrap={matches}>
              <Label text={t("load-raw")} small noPadding>
                <InputNumber
                  required
                  id="raw"
                  value={state?.load?.raw}
                  placeholder={t("write-here")}
                  min={1}
                  step={"0.01"}
                  onChange={handleLoadOnChange("raw", dispatch)}
                  mode="underline"
                />
              </Label>
              <Label text={t("load-format")} small noPadding>
                <Flex
                  justifyContent={"flex-start"}
                  style={{ gap: 15, marginTop: 5 }}
                >
                  <Chip
                    big
                    text={t(LoadCharacteristicasTypes.GRANEL)}
                    handleClick={handleLoadCharacteristics(
                      LoadCharacteristicasTypes.GRANEL
                    )}
                    type={isGranel ? "white-outlined-active" : "white-outlined"}
                    showIcon={isGranel}
                  />
                  <Chip
                    big
                    text={t(LoadCharacteristicasTypes.SACAS)}
                    handleClick={handleLoadCharacteristics(
                      LoadCharacteristicasTypes.SACAS
                    )}
                    type={isSacas ? "white-outlined-active" : "white-outlined"}
                    showIcon={isSacas}
                  />
                  <Chip
                    big
                    text={t(LoadCharacteristicasTypes.PALET)}
                    handleClick={handleLoadCharacteristics(
                      LoadCharacteristicasTypes.PALET
                    )}
                    type={isPalet ? "white-outlined-active" : "white-outlined"}
                    showIcon={isPalet}
                  />
                </Flex>
                <Spacer height={5} />
                <ValidateGroup
                  value={isSacas || isPalet || isGranel ? 1 : undefined}
                />
              </Label>
            </Row>
            {isGranel && (
              <div>
                <Label text={t("load-volume")} small noPadding>
                  <InputNumber
                    required
                    id="volume"
                    value={state?.load?.volume}
                    placeholder={t("write-here")}
                    min={1}
                    step={"0.01"}
                    onChange={handleLoadOnChange("volume", dispatch)}
                    mode="underline"
                  />
                </Label>
              </div>
            )}
            {isSacas || isPalet ? (
              <>
                <Row gap={15} wrap={matches}>
                  <Label text={t("load-bulk")} small noPadding>
                    <InputNumber
                      required
                      id="bulk"
                      value={state?.load?.bulk}
                      placeholder={t("write-here")}
                      min={1}
                      step={"0.01"}
                      onChange={handleLoadOnChange("bulk", dispatch)}
                      mode="underline"
                    />
                  </Label>
                  <Label text={t("load-number")} small noPadding>
                    <InputNumber
                      required
                      id="loadNumber"
                      value={state?.load?.number}
                      placeholder={t("write-here")}
                      min={1}
                      step={"0.01"}
                      onChange={handleLoadOnChange("number", dispatch)}
                      mode="underline"
                    />
                  </Label>
                </Row>

                <div>
                  <Label text={t("load-dimensions")} small noPadding></Label>
                  <Row gap={15} wrap={matches}>
                    <Label text={t("depth")} small noPadding>
                      <InputNumber
                        required
                        id="depth"
                        value={state?.load?.depth}
                        placeholder={t("write-here")}
                        min={1}
                        step={"0.01"}
                        onChange={handleLoadOnChange("depth", dispatch)}
                        mode="underline"
                      />
                    </Label>
                    <Label text={t("width")} small noPadding>
                      <InputNumber
                        required
                        id="width"
                        value={state?.load?.width}
                        placeholder={t("write-here")}
                        min={1}
                        step={"0.01"}
                        onChange={handleLoadOnChange("width", dispatch)}
                        mode="underline"
                      />
                    </Label>
                    <Label text={t("height")} small noPadding>
                      <InputNumber
                        required
                        id="height"
                        value={state?.load?.height}
                        placeholder={t("write-here")}
                        min={1}
                        step={"0.01"}
                        onChange={handleLoadOnChange("height", dispatch)}
                        mode="underline"
                      />
                    </Label>
                  </Row>
                </div>
              </>
            ) : (
              <></>
            )}
          </Flex>
        </Paper>
      </Paper>
    </form>
  );
}

export default QuotationRequestForm;
