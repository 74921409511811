import { Button, InputPassword } from "@scrapadev/scrapad-front-sdk";
import VerificationInput from "react-verification-input";
import { useTranslation } from "../../utils/hooks";
import BackButton from "../ui/back-button/BackButton";

import Label from "../ui/label/Label";

export const ResetPwdForm = (props) => {
  const { t } = useTranslation(["login", "common"]);

  return (
    <>
      <form method="POST" onSubmit={props.doChangePwd}>
        <Label text={t("confirm-code", { ns: "login" })}>
          <VerificationInput
            inputProps={{
              name: "code",
              id: "code",
            }}
          />
        </Label>

        <Label
          text={t("password", { ns: "login" })}
          hint={t("password-policy", { ns: "login" })}
        >
          <InputPassword
            name="password"
            style={{ marginBottom: 0, padding: "0.7rem 0.9rem" }}
            required={true}
            id="password"
          />
        </Label>

        <Label text={t("repeat-password", { ns: "login" })}>
          <InputPassword
            name="passwordRepeat"
            style={{ marginBottom: 0, padding: "0.7rem 0.9rem" }}
            required={true}
            id="passwordRepeat"
          />
        </Label>
        <Label>
          <Button
            component="button"
            fullwidth={true}
            type="submit"
            text={t("recover", { ns: "login" })}
          />
        </Label>
      </form>
      <BackButton />
    </>
  );
};
