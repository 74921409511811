import {
  ClearButton,
  Content,
  Flex,
  Icon,
  useClickOnBody,
  useMatchMedia,
  BREAKPOINT_LG,
} from "@scrapadev/scrapad-front-sdk";
import React, { useRef, useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import styles from "./SubMenu.module.scss";

/**
 *
 * @param {object} props - Component properties.
 * @param {Array<{component: ReactNode}>} props.items - Component properties.
 * @param {string} props.label - Submenu label, useful when parent menu its not clickable.
 * @param {React.CSSProperties} props.containerStyle - Dropdown container inline styles.
 * @param {React.CSSProperties} props.iconStyle - Arrow icon inline styles.
 * @returns {JSX.Element}
 */
const SubMenu = ({ items, label, containerStyle = {}, iconStyle = {} }) => {
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const { t } = useTranslation(["common"]);
  const [open, setOpen] = useState(false);
  const containerRef = useRef(null);

  useClickOnBody(containerRef, () => setOpen(false));

  if (matches) {
    return (
      <>
        <ClearButton
          onClick={(e) => {
            setOpen(!open);
          }}
        >
          <Icon
            style={iconStyle}
            name={open ? "arrow_drop_up" : "arrow_drop_down"}
          />
        </ClearButton>
        {open && items && items.length > 0 && (
          <ul className={styles["c-submenu"]}>
            {items.map((item, index) => (
              <li key={index}>{item?.component}</li>
            ))}
          </ul>
        )}
      </>
    );
  }

  return (
    <ClearButton
      onClick={(e) => {
        setOpen(!open);
      }}
    >
      <div ref={containerRef} style={containerStyle}>
        {label ? `${t("more")} ${" "}` : <></>}
        <Icon
          style={iconStyle}
          name={open ? "arrow_drop_up" : "arrow_drop_down"}
        />
      </div>
      {open && (
        <Content
          position="absolute"
          items={items}
          style={{
            top: 50,
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 0,
            paddingRight: 0,
            border: 0,
          }}
        />
      )}
    </ClearButton>
  );
};

export default SubMenu;
