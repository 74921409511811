import React, { useEffect } from "react";
import "firebase/messaging";
import { toast, ToastContainer } from "react-toastify";
import { firebaseCloudMessaging } from "../../../utils/firebase";
import { getMessaging, onMessage } from "firebase/messaging";
import { TOAST_CONTAINER_ID } from "../../../utils/variables";
import styles from "./PushNotificationLayout.module.scss";
import { useTranslation } from "../../../utils/hooks";
import { toastPush } from "@scrapadev/scrapad-front-sdk";
import { formatDate } from "../../../utils/functions";

function PushNotificationLayout({ children }) {
  const { t, i18n } = useTranslation(["notifications"], true);
  useEffect(() => {
    setToken();

    // Event listener that listens for the push notification event in the background
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        //console.log("event for the service worker", event);
      });
    }

    // Calls the getMessage() function if the token is there
    async function setToken() {
      try {
        const firebaseAppResponse = await firebaseCloudMessaging.init();
        const [firebaseApp, token] = firebaseAppResponse;
        if (token) {
          getMessage(firebaseApp);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  // Get the push notification message and triggers a toast to display it
  function getMessage(firebaseApp) {
    const messaging = getMessaging(firebaseApp); //firebase.messaging();
    onMessage(messaging, (message) => {
      const title = message?.notification?.title;
      const body = message?.notification?.body;
      const link = message?.fcmOptions?.link;
      const now = formatDate(new Date().getTime(), i18n, true, "kk:mm");
      toastPush(
        {
          id: TOAST_CONTAINER_ID,
          title,
          body,
          actionText: t("see_in_scrapad"),
          onClick: () => {
            if (link) window.location.href = link;
          },
          date: now,
          bodyStyle: { fontSize: 16 },
          whoStyle: { fontSize: 13, color: "#7E7E7E" },
        },
        {
          closeOnClick: false,
          hideProgressBar: true,
        }
      );
    });
  }

  return (
    <>
      <ToastContainer
        enableMultiContainer
        containerId={TOAST_CONTAINER_ID}
        position={toast.POSITION.BOTTOM_RIGHT}
        toastClassName={styles["sc-push-toast"]}
        bodyClassName={styles["sc-push-toast__body"]}
        closeButton
        className={styles["sc-push-toast-wrap"]}
      />
      {children}
    </>
  );
}

export default PushNotificationLayout;

