import { modalTypes, TEXT_COLOR_LIGHT } from "../../../utils/variables";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import { DataProvider } from "../../../providers/data/DataContext";
import { useState } from "react";
import {
  BREAKPOINT_LG,
  Favorite,
  MaterialsCard,
  MaterialsItem,
  useMatchMedia,
  ClearButton,
  toastError,
  Empty,
  Padding,
  Spacer,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";
import Separator from "../../../components/ui/separator/Separator";
import {
  buildAddFavoriteMaterialsData,
  buildEditFavoriteMaterialsData,
} from "../../../utils/favorites/functions";
import { handleCRUD } from "../../../utils/crud/functions";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";

const WidgetFavouritesMaterials = ({
  id,
  state,
  extraData,
  fetchData,
  setLoading,
}) => {
  const { t, tMultiple } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editData, setEditdata] = useState(false);
  const [deleteData, setDeletedata] = useState(false);
  const materials = state?.getFavouriteMaterials;
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();
  const { permission = null } = extraData || {};

  const handleEdit = (adType, key) => () => {
    const type = { sale: false, purchase: false };
    // if (adType.includes("sale")) type.sale = true;
    // if (adType.includes("purchase")) type.purchase = true;
    if (adType.includes("sale")) type.purchase = true;
    if (adType.includes("purchase")) type.sale = true;
    setEditdata({
      adType: type,
      key,
    });
    setOpenEditModal(!openEditModal);
  };

  const handleDelete = (index, key) => () => {
    setDeletedata({ key });
    setOpenDeleteModal(!openDeleteModal);
  };

  return (
    <>
      {!materials || materials?.length === 0 ? (
        <Padding>
          <Empty icon="favorite" heading={t("section-empty")} />
        </Padding>
      ) : (
        materials?.map((material) => {
          return (
            <>
              <MaterialsCard title={material?.material}>
                {material.materialsType?.map((type, index) => {
                  return (
                    <>
                      <MaterialsItem
                        beforeTitleComponent={
                          <Favorite
                            uuid={index + 1}
                            active
                            containerStyles={{
                              background: "#F5F5F5",
                              borderRadius: 20,
                              cursor: "pointer",
                            }}
                            onClick={handleDelete(type?.adType, type?.key)}
                          />
                        }
                        beforeMenuComponent={
                          JSON.parse(
                            process.env
                              .REACT_APP_FAVORITES_SHOW_VIEW_ADS_STORED_SEARCHS
                          ) ? (
                            <>
                              <ClearButton
                                onClick={() => {}}
                                style={{
                                  fontWeight: 300,
                                  fontSize: 14,
                                  lineHeight: "18px",
                                  textDecoration: "underline",
                                  color: "#7E7E7E",
                                }}
                              >
                                {tMultiple("view-number-ads", [6])}
                              </ClearButton>
                              <Separator
                                color={"#B1B1B1"}
                                height={18}
                                hideOnMobile={false}
                              />
                            </>
                          ) : (
                            <></>
                          )
                        }
                        title={
                          type.label === "ALL" ? t(type.label) : type.label
                        }
                        // isSell={type.adType?.includes("sale")}
                        // isBuy={type.adType?.includes("purchase")}
                        isSell={type.adType?.includes("purchase")}
                        isBuy={type.adType?.includes("sale")}
                        handleEdit={handleEdit(type?.adType, type?.key)}
                        handleRemove={handleDelete(type?.adType, type?.key)}
                        itemsIconProps={{
                          color: TEXT_COLOR_LIGHT,
                          iconStyle: { fontSize: "1.25rem" },
                        }}
                        iconColor={"#9E9E9E"}
                        matches={matches}
                        hasActions={
                          permission ? (permission() ? true : false) : true
                        }
                      />
                      {index + 1 < material.materialsType?.length && (
                        <Spacer height={15} />
                      )}
                    </>
                  );
                })}
              </MaterialsCard>
            </>
          );
        })
      )}
      <DataProvider>
        <ModalFactory
          type={modalTypes.ADD_MATERIAL}
          open={extraData.openModalMaterial}
          setOpen={extraData.setOpenModalMaterial}
          onSubmit={(modalState) => {
            const [data, error] = buildAddFavoriteMaterialsData(modalState);
            if (error) {
              toastError(t("data-error"));
              return;
            }
            setLoading(true);
            handleCRUD({
              endpoint: `org/${id}/material`,
              method: "POST",
              data,
              successCallback: () => fetchData(),
              messageOk: t("action-ok"),
              signOut,
              setDataUser,
              setLoading,
            });
          }}
        />
      </DataProvider>
      <DataProvider>
        <ModalFactory
          type={modalTypes.FAVORITES_EDIT_MATERIAL}
          open={openEditModal}
          setOpen={setOpenEditModal}
          data={editData}
          onSubmit={(modalState, key) => {
            const [data, error] = buildEditFavoriteMaterialsData(modalState);
            if (error) {
              toastError(t("data-error"));
              return;
            }
            setLoading(true);
            handleCRUD({
              endpoint: `org/${id}/material/${key}`,
              method: "PUT",
              data,
              successCallback: () => fetchData(),
              messageOk: t("action-ok"),
              signOut,
              setDataUser,
              setLoading,
            });
          }}
        />
      </DataProvider>
      <ModalFactory
        type={modalTypes.FAVORITES_DELETE}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        data={deleteData}
        onSubmit={(key) => {
          setLoading(true);
          handleCRUD({
            endpoint: `org/${id}/material/${key}`,
            method: "DELETE",
            successCallback: () => fetchData(),
            messageOk: t("action-ok"),
            signOut,
            setDataUser,
            setLoading,
          });
        }}
      />
    </>
  );
};

export default WidgetFavouritesMaterials;

