import React, { useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import {
  BREAKPOINT_LG,
  Flex,
  InputNumber,
  Label,
  Modal,
  Paper,
  Row,
  SearchAutoComplete,
  ThumbnailHeader,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { handleCRUD } from "../../../utils/crud/functions";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import {
  currencyOptionsMap,
  currencySymbolMapWithWeight,
} from "../../../utils/variables";
import _ from "lodash";

function CounterOffer({
  open = false,
  setOpen,
  amount,
  price,
  opportunityId,
  uuid,
  currency,
  opportunityType,
  opportunityTitle,
  fetchData = () => {},
  material,
  files = [],
  ...props
}) {
  const { t } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [amountData, setAmount] = useState(amount?.split("t")[0]);
  const [priceData, setPrice] = useState(price?.split("€")[0]);

  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setLoading(true);
    await handleCRUD({
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      external: true,
      data: {
        slug: "COUNTER_OFFER_OPPORTUNITY",
        uuidUser: user?.userUuid,
        //uuidEvent: opportunityId,
        //uuidOfferOpportunity: uuid,
        uuidEvent: uuid,
        amount: amountData * 100,
        proposedPrice: priceData * 100,
      },
      successCallback: (e) => {
        fetchData();
        setOpen(false);
        setLoading(false);
      },
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading: setLoading,
      errorCallback: () => {
        setLoading(false);
      },
    });
  };

  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("counter-offer")}
      hasForm={true}
      loading={loading}
      onSubmit={handleSubmit}
      submitText={t("send")}
      cancelText={t("cancel")}
      maxWidth={800}
      modalStyles={{ width: matches ? "auto" : 800 }}
      BodyComponent={
        <>
          <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ gap: "15px" }}
          >
            <ThumbnailHeader
              imageUrl={!_.isEmpty(files) ? files[0]?.location : undefined}
              title={`${
                opportunityType === "sale" ? t("you_buy") : t("you_sell")
              }: ${opportunityTitle}`}
              description={`${material}`}
              style={{ marginLeft: "-10px", marginRight: "-10px" }}
            />
            <Paper style={{ alignItems: "stretch" }}>
              <Row gap={30} align="flex-start">
                <Label text={`${t("amount")}`} noPadding>
                  <InputNumber
                    mode="underline"
                    required
                    placeholder={t("write-here")}
                    min={0.01}
                    step="0.01"
                    value={`${amountData}`}
                    onChange={(e) => {
                      setAmount(e.target.value);
                    }}
                    trailingText={"t"}
                  />
                </Label>
                <Label
                  text={`${t("price")}`}
                  noPadding
                  style={{ paddingBottom: 0, marginBottom: 0 }}
                >
                  <Row align="flex-start" gap={10}>
                    <InputNumber
                      mode="underline"
                      required
                      placeholder={t("write-here")}
                      min={0.01}
                      step="0.01"
                      value={Number(priceData || 0)}
                      onChange={(e) => {
                        setPrice(e.target.value);
                      }}
                      style={{ marginBottom: 0 }}
                    />
                    <SearchAutoComplete
                      mode="underline"
                      useSearch={false}
                      required={true}
                      style={{ marginBottom: "0" }}
                      placeholder={t("select-value")}
                      readOnly={true}
                      disabled={true}
                      value={currencySymbolMapWithWeight[currency]}
                      leadingIcon={currencyOptionsMap[currency]?.icon}
                    />
                  </Row>
                </Label>
              </Row>
            </Paper>
          </Flex>
        </>
      }
    />
  );
}

export default CounterOffer;
