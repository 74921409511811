import React from "react";
import styles from "./OpportunityOfferAccepted.module.scss";
import { Button } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../../utils/hooks";
import { useConvertOpportunityToTransaction } from "../../../../utils/opportunities/hooks";
import { useNavigate } from "react-router-dom";

function OpportunityOfferAccepted({
  id,
  loading,
  setLoading,
  fetchData = () => {},
  opportunityId,
  offers,
}) {
  const { t } = useTranslation(["common"]);
  const { handleConvert } = useConvertOpportunityToTransaction();
  const navigate = useNavigate();
  const selectedOffer = offers?.find((f) => f.uuidOffer === id);
  const amount = selectedOffer ? selectedOffer?.amount : undefined;
  const price = selectedOffer ? selectedOffer?.price : undefined;

  return (
    <div
      className={styles["offer_accepted"]}
      data-cy="opportunity-offer-accepted"
    >
      <div className={styles["header"]}>
        <h6 className={styles["heading"]}>{t("offer_accepted")}</h6>
      </div>
      <div className={styles["body"]}>
        <p className={styles["text"]}>{t("offer_accepted_desc")}</p>
      </div>
      <div className={styles["footer"]}>
        <Button
          text={t("create_transaction")}
          component="button"
          mode="white"
          reduced={true}
          loading={loading}
          onClick={() => {
            handleConvert({
              uuidOffer: id,
              fetchData: (e) => {
                fetchData();
                navigate(`/transactions/${e?.uuidTransaction}`);
              },
              setLoading,
              uuidOpportunity: opportunityId,
              amount,
              price,
            });
          }}
        />
      </div>
    </div>
  );
}

export default OpportunityOfferAccepted;
