import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  Container,
  Flex,
  TableManager,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useRef } from "react";
import FilterDate from "../../components/ui/filters/FilterDate";
import Filters from "../../components/ui/filters/Filters";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import SearchFilterExport from "../../components/ui/search-filter-export/SearchFilterExport";
import LayoutDetail from "../../layout/detail/Detail";
import LayoutTable from "../../layout/table/Table";
import { getTable } from "../../model/tables";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { usePaginationProvider } from "../../providers/pagination/PaginationContext";
import { useSortProvider } from "../../providers/sort/SortContext";
import { useTableDataProvider } from "../../providers/TableContext";
import { useDocumentTitle } from "../../utils/document/hooks";
import { getDateDropdownProps } from "../../utils/filters/functions";
import { useTranslation } from "../../utils/hooks";
import { applySortToFilters } from "../../utils/tables/functions";
import { useTableActions, useTableConfig } from "../../utils/tables/hooks";
import { QUERY_UPPER_LIMIT, TABLE_LIMIT } from "../../utils/variables";

const InnerRegisterIncomplete = ({ baseTableParams }) => {
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const matchesMD = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);
  const { t, i18n } = useTranslation(["common"], true);
  const { data, setData, loading, fetchData } = useTableDataProvider();
  const { handlePagination } = usePaginationProvider();
  const { buildFiltersAndFetch, getStoredFilters } = useFiltersProvider();
  const filters = buildFiltersAndFetch(getStoredFilters() || {});
  const { state: sortState, handleSorting, buildSort } = useSortProvider();
  const dateFilterRef = useRef({ handleClose: null });
  const sortFields = buildSort(sortState);

  useDocumentTitle(`${t("register-incomplete")}`);

  useTableActions({
    paginationCallback: (paginationState) => {
      const newFilters = buildFiltersAndFetch(getStoredFilters() || {});
      const params = {
        ...baseTableParams,
        offset: paginationState?.current,
        sort: sortState?.field ? sortState : undefined,
      };
      if (Object.keys(newFilters).length > 0) {
        params.filter = newFilters;
      }
      fetchData({
        ...params,
        filter: applySortToFilters(
          newFilters,
          sortFields,
          "REACT_APP_SORT_REGISTER_INCOMPLETE"
        ),
      });
    },
  });

  return (
    <>
      <NavBar />
      <LayoutDetail
        containerStyles={{ marginTop: matches ? 10 : 0 }}
        backgroundColor="#fff"
        headerWithContainer
        containerProps={{ isFluid: true }}
        headerComponent={
          <Header
            title={t("register-incomplete")}
            fullSubHeaderLeft
            lowerLeftStyles={{ width: "100%", marginBottom: 0 }}
            subHeaderLeft={
              <Flex style={{ flexWrap: matchesMD ? "wrap" : "nowrap" }}>
                <Filters
                  removeText={t("restore-filters")}
                  initialFilters={{
                    createdAt: {
                      label: t("creation-date"),
                      externalComponent: true,
                      items: [
                        {
                          uuid: 1,
                          component: (
                            <FilterDate
                              ref={dateFilterRef}
                              filterKey={"createdAt"}
                            />
                          ),
                        },
                      ],
                      dropDownProps: getDateDropdownProps(dateFilterRef),
                    },
                  }}
                  externalSort={
                    JSON.parse(process.env.REACT_APP_SORT_REGISTER_INCOMPLETE)
                      ? handleSorting
                      : undefined
                  }
                  sorting={buildSort(sortState)}
                  containerStyles={{
                    flexWrap: "wrap",
                    gap: matches ? 16 : 0,
                    width: "100%",
                    marginRight: matches ? 0 : 20,
                  }}
                />
                <SearchFilterExport
                  hasSeparator={false}
                  hasSearch={false}
                  hasFilters={false}
                  exportProps={{
                    fnData: getTable,
                    fnParameters:
                      Object.keys(filters).length > 0
                        ? {
                            ...baseTableParams,
                            offset: 0,
                            limit: QUERY_UPPER_LIMIT,
                            filter: applySortToFilters(
                              filters,
                              sortFields,
                              "REACT_APP_SORT_REGISTER_INCOMPLETE"
                            ),
                          }
                        : {
                            ...baseTableParams,
                            limit: QUERY_UPPER_LIMIT,
                            offset: 0,
                            filter: applySortToFilters(
                              {},
                              sortFields,
                              "REACT_APP_SORT_REGISTER_INCOMPLETE"
                            ),
                          },
                    fileName: "register-incomplete",
                  }}
                />
              </Flex>
            }
          />
        }
        bodyComponent={
          <Container isFluid={true}>
            <div className="row">
              <div className="col-12">
                <TableManager
                  loading={loading}
                  data={data}
                  setData={setData}
                  emptyText={t("empty-data")}
                  rowTriggerPageChange={false}
                  itemsProps={{
                    date: {
                      languageData: i18n.getLanguageVariable(i18n.language),
                    },
                  }}
                  externalSort={
                    JSON.parse(process.env.REACT_APP_SORT_LOGISTICS)
                      ? handleSorting
                      : undefined
                  }
                  sorting={buildSort(sortState)}
                  pagination={
                    data?.pagination
                      ? {
                          currentPage: Math.ceil(
                            data?.pagination?.current / TABLE_LIMIT
                          ),
                          totalPages: Math.ceil(
                            data?.pagination?.total / TABLE_LIMIT
                          ),
                          threshold: 3,
                          onChangePage: handlePagination,
                          onChangePrev: handlePagination,
                          onChangeNext: handlePagination,
                        }
                      : undefined
                  }
                />
              </div>
            </div>
          </Container>
        }
      />
    </>
  );
};

const RegisterIncomplete = () => {
  const { i18n } = useTranslation();
  const baseParams = {
    locale: i18n.language,
    limit: TABLE_LIMIT,
    tableModule: "register-pending",
  };
  const { tableParams } = useTableConfig(baseParams, {
    sortingFlag: "REACT_APP_SORT_REGISTER_INCOMPLETE",
  });

  return (
    <LayoutTable fnData={getTable} fnParameters={tableParams}>
      <InnerRegisterIncomplete baseTableParams={baseParams} />
    </LayoutTable>
  );
};

export default RegisterIncomplete;
