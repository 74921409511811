import styles from "./ModalText.module.scss";

/**
 * Component to show text in a modal.
 * @param {Object} props - Component properties.
 * @param {Object} [props.text=''] - Text to show.
 * @returns {JSX.Element}
 */
const ModalText = ({ text = "", style = {} }) => {
  return (
    <p className={styles["sc-modal-text"]} style={style}>
      {text}
    </p>
  );
};

export default ModalText;
