import { useRef } from "react";
import FilterDate from "../../components/ui/filters/FilterDate";
import FilterForMaterials from "../../components/ui/filters/FilterMaterials";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { useTranslation } from "../hooks";
import { adsDefaults } from "../variables";
import {
  dropdownPropsMaterial,
  getDateDropdownProps,
  removeFilterMaterial,
} from "./functions";
import { whiteFilterProps } from "./variables";

export const useDateFilter = (key, label) => {
  const dateFilterRef = useRef({ handleClose: null });

  return {
    label,
    externalComponent: true,
    items: [
      {
        uuid: 1,
        component: <FilterDate ref={dateFilterRef} filterKey={key} />,
      },
    ],
    dropDownProps: getDateDropdownProps(dateFilterRef),
  };
};

/**
 * Custom hook for date filter with the white l&f
 * @param {*} key
 * @param {*} labelProp
 * @returns {object}
 */
export const useWhiteDateFilter = (key, labelProp) => {
  const { label, items, dropDownProps } = useDateFilter(key, labelProp);

  return {
    label,
    externalComponent: true,
    items,
    dropDownProps: {
      ...dropDownProps,
      dropDownStyle: {
        padding: 0,
        boxShadow: "none",
        border: `1px solid #E1E1E1`,
        "--datepicker-shadow": "none",
      },
      style: whiteFilterProps.chipProps.style,
    },
  };
};

export const useMaterialsFilter = (key, label) => {
  const materialFilterRef = useRef({ handleClose: null });
  const { t } = useTranslation(["common"], true);
  const { getStoredFilters } = useFiltersProvider();

  return {
    label,
    externalComponent: true,
    items: [
      {
        uuid: 1,
        component: (
          <FilterForMaterials ref={materialFilterRef} filterKey={key} />
        ),
      },
    ],
    data: getStoredFilters()?.materials?.data
      ? getStoredFilters()?.materials?.data
      : {
          materials: {
            category: adsDefaults,
            material: adsDefaults,
            type: adsDefaults,
          },
        },
    dropDownProps: dropdownPropsMaterial(materialFilterRef),
    removeFilter: removeFilterMaterial,
  };
};

/**
 * Custom hook for materials filter with the white l&f
 * @param {*} key
 * @param {*} labelProp
 * @returns {object}
 */
export const useWhiteMaterialsFilter = (key, labelProp) => {
  const { label, items, data, dropDownProps, removeFilter } =
    useMaterialsFilter(key, labelProp);

  return {
    label,
    externalComponent: true,
    items,
    data,
    removeFilter,
    dropDownProps: {
      ...dropDownProps,
      dropDownStyle: {
        padding: 15,
        border: `1px solid #E1E1E1`,
      },
      style: whiteFilterProps.chipProps.style,
    },
  };
};

/**
 * Custom hook for phase filter.
 * @returns {Object}
 */
export const usePhaseFilter = () => {
  const { t } = useTranslation(["common", "transactions"], true);

  return {
    label: t("phase"),
    subItems: true,
    groupedChips: true,
    items: [
      {
        uuid: "SAMPLE_REQUEST",
        name: t("samples"),
        subItems: [
          { uuid: "SAMPLE_CREATE", name: t("requested") },
          { uuid: "SAMPLE_KO", name: t("request-rejected") },
          { uuid: "SAMPLE_OK", name: t("request-accepted") },
          { uuid: "SAMPLE_SENT", name: t("sended") },
          { uiud: "SAMPLE_DELIVERY", name: t("delivered") },
        ],
      },
      {
        uuid: "NEGOTIATION",
        name: t("negotiation"),
        subItems: [
          {
            uuid: "NEGOTIATION_LOGISTIC_PENDING",
            name: t("cost_pending"),
          },
          {
            uuid: "NEGOTIATION_LOGISTIC_LAUNCHED",
            name: t("cost_sended"),
          },
        ],
      },
      {
        uuid: "CONTRACT",
        name: t("contract"),
        subItems: [
          {
            uuid: "CONTRACT_CONTRACT_PENDING",
            name: t("pending-sign"),
          },
          {
            uuid: "CONTRACT_DEPOSIT_PENDING",
            name: t("pending-deposit"),
          },
        ],
      },
      {
        uuid: "TRANSPORT",
        name: t("transport"),
        subItems: [
          {
            uuid: "TRANSPORT_PENDING_COORDINATION",
            name: t("pending-coordinated"),
          },
          {
            uuid: "TRANSPORT_COLLECTION_COORDINATE",
            name: t("load-coordinated"),
          },
          {
            uuid: "TRANSPORT_COLLECTION_CONFIRMED",
            name: t("load-confirmed"),
          },
          {
            uuid: "TRANSPORT_DELIVERY_CONFIRMED",
            name: t("unload-confirmed"),
          },
          {
            uuid: "TRANSPORT_DELIVERY_COORDINATE",
            name: t("unload-coordinated"),
          },
        ],
      },
      {
        uuid: "FINISH",
        name: t("finish"),
      },
    ],
  };
};

/**
 * Custom hook for turn filter.
 * @returns {Object}
 */
export const useTurnFilter = () => {
  const { t } = useTranslation(["common", "transactions"], true);

  return {
    label: t("turn"),
    items: [
      {
        uuid: "seller",
        name: t("seller"),
      },
      {
        uuid: "buyer",
        name: t("buyer"),
      },
      {
        uuid: "logistics",
        name: t("logistics"),
      },
      {
        uuid: "finances",
        name: t("finances"),
      },
      {
        uuid: "commercial",
        name: t("commercial"),
      },
      {
        uuid: "scrapad",
        name: t("scrapad"),
      },
    ],
  };
};

/**
 * Custom hook for phase filter.
 * @returns {Object}
 */
export const usePortsFilter = (key, labelProp, ports = []) => {
  return {
    label: labelProp,
    subItems: false,
    groupedChips: true,
    items: ports,
    multiple: true,
    catalogName: "ports",
    dropDownProps: {
      dropDownItemStyle: {
        padding: 0,
      },
      dropDownStyle: {
        padding: 0,
        border: `1px solid #E1E1E1`,
        maxHeight: "250px",
        overflow: "hidden",
      },
      style: whiteFilterProps.chipProps.style,
    },
  };
};
