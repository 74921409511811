import { walletStatus, walletTypesIn, walletTypesOut } from "./variables";

export const getAmountWithOperator = (amount, type) => {
  if (!type) return amount;
  const movementsIn = Object.values(walletTypesIn);
  const movementsOut = Object.values(walletTypesOut);
  if (movementsIn.includes(type)) {
    return `+ ${amount} €`;
  }
  if (movementsOut.includes(type)) {
    return `- ${amount} €`;
  }
  return amount;
};

export const getMovementStatus = (type, status) => {
  return type === walletTypesOut.BALANCE_OUT ? walletStatus.APPROVED : status;
};

export const getBalanceWithOperator = (balance) => {
  return parseInt(balance) > 0 ? `+ ${balance}` : `- ${balance}`;
};

export const parseTypeToWalletTitle = (t, title) => {
  const movementsIn = Object.keys(walletTypesIn);
  const movementsOut = Object.keys(walletTypesOut);
  if (movementsIn.includes(title)) {
    return t(walletTypesIn[title]);
  }
  if (movementsOut.includes(title)) {
    return t(walletTypesOut[title]);
  }
  return title;
};
