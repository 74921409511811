import CatalogSearch from "../../../components/ui/input/search/CatalogSearch";
import { useTranslation } from "../../../utils/hooks";

/**
 * Helper component for address selected port.
 * @param {string} props.selectedPort - Selected port uuid.
 * @param {Function} [props.onItemClick]- On item click handleer.
 * @returns {JSX.Element}
 */
const WidgetAddressSelectedPort = ({ selectedPort, onItemClick, ...props }) => {
  const { t } = useTranslation(["common"]);

  return (
    <CatalogSearch
      required
      remote
      id="SelectedPort"
      inputProps={{
        required: true,
        placeholder: t("write-or-select"),
      }}
      initialValue={selectedPort ? selectedPort?.name : undefined}
      catalogName={"ports"}
      avoidInitalLoad={true}
      onClickCallback={(option, open, setOpen, value, setValue, e) => {
        e.stopPropagation();
        if (onItemClick) {
          onItemClick(option, open, setOpen, e);
        }
      }}
    />
  );
};

export default WidgetAddressSelectedPort;
