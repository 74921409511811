import { useSubscription } from "@apollo/client";
import _ from "lodash";
import { useEffect } from "react";
import { gqlWebsockets } from "../../model/websockets";
import { useNotificationProvider } from "../../providers/notifications/NotificationsContext";
import { getRoomSlug, WS_ROOM } from "./functions";

/**
 * Custom hook to handle websocket subscription in the aplication.
 * @returns {{ wsData: TData, wsLoading: boolean }}
 */
export const useNotificationSubscription = () => {
  const { updateDataCall } = useNotificationProvider();
  const { data: wsData, loading: wsLoading } = useSubscription(gqlWebsockets, {
    variables: { room: getRoomSlug() },
  });

  useEffect(() => {
    if (
      !_.isEmpty(wsData) &&
      wsData?.subscribeRoom?.type === WS_ROOM.notification
    ) {
      updateDataCall({ unread: true });
    }
  }, [wsData]);

  return {
    wsData,
    wsLoading,
  };
};
