import {
  Button,
  Container,
  Flex,
  Heading,
  InputText,
  toastError,
  toastSuccess,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FileItem from "../../../components/ui/input/file/Item";
import InputFileWrapper from "../../../components/ui/input/file/Wrapper";
import Label from "../../../components/ui/input/label/Label";
import { actions } from "../../../providers/data/actions/data";
import {
  DataProvider,
  useDataProvider,
} from "../../../providers/data/DataContext";
import { useCRUD } from "../../../utils/crud/hooks";
import { renderComponentWithPermission } from "../../../utils/functions";
import { useTranslation } from "../../../utils/hooks";

const InnerWidgetWalletAccountsEdit = ({
  account,
  permission,
  fetchData,
  setIsEdit,
}) => {
  const { t } = useTranslation(["common"]);
  const { state, dispatch } = useDataProvider();
  const { CRUD } = useCRUD();
  const [loading, setLoading] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const { id } = useParams();

  useLayoutEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = "";
  }, []);

  useEffect(() => {
    dispatch({
      type: actions.INITIAL_LOAD,
      payload: { ...account },
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (state?.file) {
      await CRUD({
        endpoint: `org/${id}/bank-account-out/${account?.uuid}`,
        method: "PUT",
        data: {
          fileId: state?.file,
        },
        errorCallback: (errorMessage) => {
          toastError(
            `${t("error-uploading-proof")}: ${errorMessage}`,
            { toastId: "ad_error" },
            "sentiment_dissatisfied"
          );
        },
        successCallback: async () => {
          toastSuccess(
            t("saved-changes-ok"),
            { toastId: "ad_clone" },
            "check_circle"
          );
          setCanSave(false);
          setLoading(false);
          setIsEdit(false);
          fetchData();
        },
      });
    }
  };

  if (Object.keys(state) === 0) return <></>;

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <div className="row">
          <div className="col-12">
            <Flex justifyContent={"space-between"} style={{ marginBottom: 20 }}>
              <Heading type={3} style={{ marginBottom: 0 }}>
                {t("edit-account")}
              </Heading>
              {renderComponentWithPermission(
                permission,
                <Button
                  disabled={!canSave}
                  component="button"
                  text={t("save-changes")}
                />
              )}
            </Flex>
          </div>
        </div>
        {account?.alias && (
          <div className="row">
            <div className="col-12">
              <Label>{t("account-alias")}</Label>
              <InputText
                readOnly={true}
                value={state?.alias}
                placeholder={t("write-here")}
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 col-lg-6">
            <Label>{t("swift")}</Label>
            <InputText
              readOnly={true}
              value={state?.bicCode}
              placeholder={t("write-here")}
              pattern="^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$"
            />
          </div>
          <div className="col-12 col-lg-6">
            <Label>{t("iban")}</Label>
            <InputText
              readOnly={true}
              value={state?.viban}
              placeholder={t("write-here")}
              pattern="^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|MA|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6">
            <Label>{t("holder")}</Label>
            <InputText
              readOnly={true}
              value={state?.holder}
              placeholder={t("write-here")}
            />
          </div>
          <div className="col-12 col-lg-6">
            <Label>{t("address")}</Label>
            <InputText
              readOnly={true}
              value={state?.address}
              placeholder={t("write-here")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Label>{t("proof-ownership")}</Label>
            <InputFileWrapper
              readOnly={
                state?.urlVIBANUser || state?.status === "activated"
                  ? true
                  : false
              }
              placeholder={t("select-drop-files")}
              dropPlaceholder={t("drop-files")}
              afterUpload={(parsedFiles) => {
                dispatch({
                  type: actions.UPDATE_INPUT,
                  payload: { file: parsedFiles?.[0]?.uuid },
                });
                setCanSave(true);
              }}
              allowedExtension={[".pdf", ".jpeg", ".jpg", ".png"]}
              fileType={"kyc"}
              translation={{
                uploading: t("uploading"),
              }}
            />
            {state?.urlVIBANUser && (
              <FileItem
                handleDelete={() => {
                  dispatch({
                    type: actions.UPDATE_INPUT,
                    payload: { urlVIBANUser: null },
                  });
                }}
                file={state?.urlVIBANUser}
                buttonStyle={{ marginLeft: "auto" }}
                dataAdapter={(file) => {
                  return {
                    ...file,
                    url: state?.urlVIBANUser,
                    name: t("download"),
                  };
                }}
              />
            )}
          </div>
        </div>
      </Container>
    </form>
  );
};

const WidgetWalletAccountsEdit = ({ ...props }) => {
  return (
    <DataProvider>
      <InnerWidgetWalletAccountsEdit {...props} />
    </DataProvider>
  );
};

export default WidgetWalletAccountsEdit;
