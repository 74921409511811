import { Container, MiniTabs, Spacer } from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import GoBack from "../../components/ui/back/GoBack";
import FiltersWrapper from "../../components/ui/filters/Wrapper";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import LayoutDetail from "../../layout/detail/Detail";
import { PaginationProvider } from "../../providers/pagination/PaginationContext";
import { SortProvider } from "../../providers/sort/SortContext";
import { useTranslation } from "../../utils/hooks";
import WidgetFinancesDeposit from "../../widgets/finances/Deposits";
import WidgetFinances from "../../widgets/finances/Finances";

const Finances = () => {
  const { t } = useTranslation(["common", "finances"]);
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header title={t("finances")} />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <MiniTabs
                  tabsProps={{ style: { position: "static" } }}
                  tabPanelProps={{ style: { position: "static" } }}
                  tabs={[
                    {
                      tab: <>{t("deposits")}</>,
                      panel: (
                        <PaginationProvider storageKey={"paymentTracking"}>
                          <FiltersWrapper storageKey={"paymentTracking"}>
                            <SortProvider>
                              <WidgetFinancesDeposit />
                            </SortProvider>
                          </FiltersWrapper>
                        </PaginationProvider>
                      ),
                    },
                    {
                      tab: <>{t("unidentifiable-income")}</>,
                      panel: <WidgetFinances />,
                    },
                  ]}
                  currentTab={currentTab}
                  changeTab={(index) => {
                    setCurrentTab(index);
                  }}
                  spacer={<Spacer height={25} />}
                />
              </div>
            </div>
          </Container>
        }
      />
    </>
  );
};

export default Finances;
