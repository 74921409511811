import { Empty, MaterialsCard } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";
import FavoriteItem from "./FavoriteItem";

const WidgetFavoriteMaterial = ({ materials }) => {
  const { t } = useTranslation("common");
  if (materials?.length === 0) {
    return <Empty icon="favorite" iconWidth={100} iconHeight={104} />;
  }
  return materials?.map((material, index) => {
    return (
      <MaterialsCard
        boxStyles={{
          marginBottom: 7,
        }}
        containerStyles={{ alignItems: "flex-start" }}
      >
        {material?.materialsType.map((type, indexType) => {
          return (
            <>
              <FavoriteItem
                title={indexType === 0 ? material?.material : undefined}
                description={type.label === "ALL" ? t(type.label) : type.label}
                isBuy={type.adType.includes("purchase")}
                isSell={type.adType.includes("sale")}
              />
              {indexType + 1 < material?.materialsType.length && (
                <hr
                  style={{
                    opacity: 1,
                    margin: 0,
                    height: 1,
                    width: "100%",
                    color: "#CFCFCF",
                    margin: "10px 0",
                  }}
                />
              )}
            </>
          );
        })}
      </MaterialsCard>
    );
  });
};

export default WidgetFavoriteMaterial;
