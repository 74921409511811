import { FiltersProvider } from "../../../providers/filters/FiltersContext";

const FiltersWrapper = ({ storageKey, keysMerged, children }) => {
  return (
    <FiltersProvider storageKey={storageKey} keysMerged={keysMerged}>
      {children}
    </FiltersProvider>
  );
};

export default FiltersWrapper;
