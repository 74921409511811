import {
  BREAKPOINT_LG,
  Container,
  Modal,
  useMatchMedia,
  Paper,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { actions } from "../../../providers/data/actions/data";
import { useDataProvider } from "../../../providers/data/DataContext";
import { useTranslation } from "../../../utils/hooks";
import RouteForm from "./RouteForm/RouteForm";

const defaultData = {
  originType: "address",
  address: {},
  destinationType: "address",
  destination: {},
};

const ModalEditRoute = ({
  open,
  setOpen,
  isEdit,
  data,
  uuidSeller,
  uuidBuyer,
  ...props
}) => {
  const { state, dispatch } = useDataProvider();
  const [loading, setLoading] = useState();
  const { t } = useTranslation(["logistics", "common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);

  useEffect(() => {
    if (isEdit) {
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: data || defaultData,
      });
    } else {
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: defaultData,
      });
    }
  }, []);

  const handleSubmit = async () => {
    console.log("handleSubmit");
    console.log(state);
  };

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        setLoading(false);
        dispatch({ type: actions.UPDATE_INPUT, payload: defaultData });
      }}
      title={t("edit_route")}
      cancelText={t("cancel")}
      submitText={t("save-changes")}
      modalStyles={{ width: matches ? "auto" : 900 }}
      hasForm
      onSubmit={handleSubmit}
      loading={loading}
      actionsMode="end"
      submitButtonMode="info"
      BodyComponent={
        <Container style={{ margin: "0 -12px" }}>
          <Paper style={{ alignItems: "stretch" }}>
            <RouteForm
              uuidBuyer={uuidBuyer}
              uuidSeller={uuidSeller}
              dispatch={dispatch}
              state={state}
            />
          </Paper>
        </Container>
      }
    />
  );
};

export default ModalEditRoute;
