import { cloneElement, useEffect, useRef, useState } from "react";
import Loading from "../components/ui/loading/Loading";
import { actions } from "../providers/data/actions/data";
import { DataProvider, useDataProvider } from "../providers/data/DataContext";

const InnerFetchWrapper = ({
  fnData,
  fnParameters,
  dataAdapter,
  Component,
  deps = [],
  delegateLoading = false,
  ...props
}) => {
  const { state, dispatch } = useDataProvider();
  const [loading, setLoading] = useState(true);
  const bufferedData = useRef(false);
  const bufferState = useRef({});
  const targetRef = useRef(null);
  const isMounted = useRef(false);
  const isReady = useRef(true);

  useEffect(() => {
    if (isMounted.current) {
      fetchData();
    }
  }, [...deps]);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      fetchData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const fetchData = async (params) => {
    if (!fnData) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const response = params ? await fnData(params) : await fnData(fnParameters);
    bufferState.current = response;
    bufferedData.current = true;
    dispatch({
      type: actions.INITIAL_LOAD,
      payload: dataAdapter ? dataAdapter(response) : response,
    });
    setLoading(false);
  };

  const cmp = cloneElement(<Component {...props} />, {
    loading,
    setLoading,
    state,
    dispatch,
    actions,
    bufferedData,
    bufferState,
    targetRef,
    fetchData,
  });

  return (
    <div ref={targetRef} className="fetch-wrapper--ref">
      {delegateLoading ? cmp : loading ? <Loading /> : cmp}
    </div>
  );
};

const GQLFetchWrapper = ({
  fnData,
  fnParameters,
  dataAdapter,
  Component,
  ...props
}) => {
  return (
    <DataProvider>
      <InnerFetchWrapper
        fnData={fnData}
        fnParameters={fnParameters}
        dataAdapter={dataAdapter}
        Component={Component}
        {...props}
      />
    </DataProvider>
  );
};

export default GQLFetchWrapper;
