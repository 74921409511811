import { InputNumber } from "@scrapadev/scrapad-front-sdk";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "../../utils/hooks";
import {
  costValuesWithDefaults,
  useImputeCosts,
} from "../../utils/opportunities/functions";
import { TEXT_COLOR } from "../../utils/variables";
import { costEvents } from "../../utils/opportunities/variables";

/**
 * Helper widget to render costs input.
 * @param {object} props - Function properties.
 * @param {object} props.state - Costs current state.
 * @param {Function} props.dispatch - Widget provider dispatch.
 * @param {object} props.actions - Widget provider actions.
 * @param {boolean} props.loading - Widget loading state.
 * @param {Function} props.fetchData - Fetching function.
 * @param {number} props.index - Costs index.
 * @param {object} props.values - Costs data.
 * @returns {JSX.Element}
 */
const WidgetOpportunitiesFormInput = ({
  state,
  dispatch,
  actions,
  values,
  index,
  loading,
  fetchData,
  setLoading = () => {},
  id,
  opportunityId,
}) => {
  const { t } = useTranslation(["common"]);
  const fields = state.fields;
  const [focused, setFocused] = useState(false);
  const isBinded = useRef(false);
  const inputRef = useRef(null);
  const textRef = useRef(null);
  const { handleSendCost } = useImputeCosts();

  useEffect(() => {
    if (!isBinded.current) {
      window.addEventListener("click", handleClickBody);
      isBinded.current = true;
    }

    return () => {
      if (isBinded.current) {
        window.removeEventListener("click", handleClickBody);
        isBinded.current = false;
      }
    };
  }, []);

  const handleClickBody = (e) => {
    if (!e.target.dataset.ref) {
      handleBlur();
    }
  };

  const handleBlur = async () => {
    if (!focused) {
      return;
    }
    await handleSendCost({
      id: id,
      type: costEvents.PUT_TRANSACTION_COST,
      value: fields[index].value * 100,
      setLoading: setLoading,
      fetchData: fetchData,
      fields: fields,
      costName: fields[index]?.slug,
      //uuidOfferOpportunity: opportunityId,
    });
    setFocused(false);
    setTimeout(async () => {
      await fetchData();
    }, 250);
  };

  const handleChangeValue = (field, index) => (e) => {
    const newState = [...fields];
    newState[index] = costValuesWithDefaults({
      slug: field.slug,
      editable: field.editable,
      optional: field.optional,
      name: field.name,
      value: e.target.valueAsNumber,
      active: field.active,
    });
    dispatch({ type: actions.UPDATE_INPUT, payload: { fields: newState } });
  };

  if (!focused) {
    return (
      <div
        ref={textRef}
        style={{ width: "60%", cursor: "text", textAlign: "right" }}
        onClick={fields[index]?.editable ? () => setFocused(true) : undefined}
        data-ref={values.slug}
      >
        <p
          data-ref={values.slug}
          style={{
            margin: 0,
            fontFamily: "Silka",
            fontSize: 14,
            lineHeight: "21px",
            color: values.optional ? "#9E9E9E" : TEXT_COLOR,
          }}
        >
          {`${values.value || "0"} ${values?.unit || ""}`}
        </p>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: 160 }} data-ref={values.slug}>
      <InputNumber
        data-ref={values.slug}
        ref={inputRef}
        placeholder={t("write-here")}
        value={values.value}
        readOnly={loading}
        step={".01"}
        min={0}
        onBlur={handleBlur}
        onChange={handleChangeValue(values, index)}
        trailingText={values?.unit}
        trailingStyles={{
          margin: 0,
          paddingLeft: 5,
          fontFamily: "Silka",
          fontSize: 14,
        }}
        style={{
          fontFamily: "Silka",
          marginBottom: 0,
          padding: 5,
          backgroundColor: "#EFF5FF",
          border: 0,
          textAlign: "right",
        }}
      />
    </div>
  );
};

export default WidgetOpportunitiesFormInput;
