import { actions } from "../../../providers/transactions/actions/transactions-forms";
import { useTransactionsFormsProvider } from "../../../providers/transactions/TransactionsFormContext";
import { useTranslation } from "../../../utils/hooks";
import { postClient } from "../../../utils/restclient";
import { buildFormMessage } from "./functions";
import styles from "./Messages.module.scss";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import {
  formEvents,
  transactionFormMessageDefault,
} from "../../../utils/variables";
import { checkPermission, handleFormOK } from "../../../utils/functions";
import {
  Chip,
  Spacer,
  toastError,
  InputText,
} from "@scrapadev/scrapad-front-sdk";
import MessageActionsMinimal from "./message/MessageActionsMinimal";

/**
 * Messages form for transactions.
 * @param {Object} props - Component properties.
 * @param {String} props.uuidVendedor - Seller uuid.
 * @param {String} props.uuidComprador - Buyer uuid.
 * @returns {JSX.Element}
 */
const MessagesForm = ({
  fetchData,
  uuidVendedor,
  uuidComprador,
  permission,
  minimal,
}) => {
  const { t } = useTranslation(["common", "transactions"]);
  const { state, dispatch } = useTransactionsFormsProvider();
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const checkdPermission = checkPermission(permission);
  const handleType = (t) => () => {
    const newType = { ...state.destination };
    newType[t] = !newType[t];
    let hiddenTypes = [];
    if (newType.seller) {
      hiddenTypes.push(uuidVendedor);
    }
    if (newType.buyer) {
      hiddenTypes.push(uuidComprador);
    }
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        destination: newType,
        hiddenDestination: hiddenTypes.join("|"),
      },
    });
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: actions.UPDATE_INPUT, payload: { loading: true } });
    const data = buildFormMessage(
      user.userUuid,
      document.getElementById("slug").value,
      state
    );
    try {
      await postClient({
        external: true,
        url: `${process.env.REACT_APP_API_EVENTS}`,
        method: "POST",
        body: data,
        signOut,
        setDataUser,
      });
      handleFormOK(t, fetchData);
    } catch (error) {
      toastError(error.message);
    } finally {
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: { ...transactionFormMessageDefault },
      });
    }
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <div
        className={`${styles["sc-message--form"]} ${
          minimal && styles["minimal"]
        }`}
      >
        <div className={styles["sc-message--type"]}>
          <span>Para: </span>
          <Chip
            type={
              state.destination.buyer
                ? "white-outlined-active"
                : "white-outlined"
            }
            handleClick={checkdPermission ? handleType("buyer") : undefined}
            showIcon={state.destination.buyer}
            text={t("buyer")}
          />
          <Chip
            type={
              state.destination.seller
                ? "white-outlined-active"
                : "white-outlined"
            }
            handleClick={checkdPermission ? handleType("seller") : undefined}
            showIcon={state.destination.seller}
            text={t("seller")}
          />
        </div>

        <InputText
          required
          readOnly={!checkdPermission}
          value={state.hiddenDestination}
          customValidityMessage={t("must-select-one-receiver")}
          style={{
            visibility: "hidden",
            height: 0,
            padding: 0,
            margin: minimal ? 0 : `0 0 .5rem 0`,
          }}
        />
        {!state.hiddenDestination && <Spacer height={10} />}
        <MessageActionsMinimal
          value={state.content}
          loading={state.loading}
          placeholder={t("write-here-message")}
          filePlaceholder={t("select-drop-files")}
          fileDropPlaceholder={t("drop-files")}
          permission={permission}
        />
      </div>
      <input
        type="hidden"
        id={"slug"}
        name={"slug"}
        value={formEvents.SEND_MD}
      />
    </form>
  );
};

export default MessagesForm;
