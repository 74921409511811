import React from "react";
import styles from "./Chip.module.scss";

/**
 * UI Chip component.
 * @param {Object} props - Component properties.
 * @param {Boolean} props.small - Small font.
 * @param {Number} props.maxWidth - Max width of the chip.
 * @param {Function} props.handleClick - Click handler.
 * @param {Boolean} props.disabled - Tells if disabled.
 * @param {Boolean} props.showIcon - Show the X icon.
 * @param {String} props.text - Text of the chip.
 * @param {React.Component} props.component - Component to show instead of text.
 * @param {("normal"|"seller"|"buyer"|"logistics"|"finances"|"bo"|"system"|"warning"|"data"|"error"|"success"|"success2"|"info"|"info2"|"removed"|"pending"|"signed"|"canceled"|"expired"|"warning"|"error"|"error2"|"expired"|"removed"|"info"|"info2"|"info3"|"info4"|"info5"|"temporal")} props.type - Type of the chip.
 * @param {String} props.icon - Icon name.
 * @param {React.CSSProperties} [props.style={}] - Custom styles.
 * @returns {JSX.Element}
 */
const Chip = ({
  small,
  maxWidth,
  handleClick,
  disabled,
  showIcon = false,
  text,
  component,
  big,
  type = "normal",
  icon = "close",
  style = {},
}) => {
  const smallClass = small ? styles["sc-chip--small"] : "";
  const bigClass = big ? styles["sc-chip--big"] : "";
  const handleOnClick = (e) => {
    if (disabled) return;
    if (handleClick) handleClick(e);
  };
  return (
    <div
      className={`${styles["sc-chip"]} ${smallClass} ${bigClass} ${
        styles[`sc-chip-${type}`]
      }`}
      onClick={handleOnClick}
      style={{
        maxWidth: maxWidth ? maxWidth : undefined,
        cursor: handleClick ? "pointer" : "default",
        ...style,
      }}
    >
      {component ? component : text}
      {showIcon && (
        <span className={styles["c-chip--close"]}>
          <i className={`material-icons icon`}>{icon}</i>
        </span>
      )}
    </div>
  );
};

export default Chip;
