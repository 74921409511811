import React from "react";
import styles from "./ChipOutlined.module.scss";
import { Icon, WARM_NEUTRAL_60 } from "@scrapadev/scrapad-front-sdk";

function ChipOutlined({ iconComponent, iconName, children, onClick }) {
  return (
    <div
      className={`${styles["chip_outlined"]} ${
        typeof onClick === "function" ? styles["with-hover"] : ""
      }`}
      onClick={onClick}
    >
      {iconComponent ? (
        iconComponent
      ) : (
        <>
          {iconName && (
            <Icon
              name={iconName}
              color={WARM_NEUTRAL_60}
              style={{
                fontSize: "16px",
              }}
            />
          )}
        </>
      )}
      <span>{children}</span>
    </div>
  );
}

export default ChipOutlined;

