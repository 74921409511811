import { Center, Empty, Flex } from "@scrapadev/scrapad-front-sdk";
import { useNavigate } from "react-router-dom";
import CardBox from "../../../components/ui/box/CardBox";
import Item from "../../../components/ui/item/Item";
import Text from "../../../components/ui/text/Text";
import { useTranslation } from "../../../utils/hooks";
import AddressesType from "../addresses/Type";

/**
 * Addresses widget for dashboard.
 * @param {Object} props - Component properties.
 * @param {String} props.id - Business id.
 * @param {Array} props.keys - Data state keys.
 * @param {Object} props.state - Data state.
 * @returns {JSX.Element}
 */
const WidgetBusinessAddresses = ({ id, state }) => {
  const { t } = useTranslation(["common"]);
  const deliveryAddress = state?.getOrgLocations;
  const navigate = useNavigate();

  const navigateTo = () => navigate(`/business/${id}/addresses`);

  const renderAddressStreet = (address) => {
    if (!address) {
      return "";
    }
    if (address.port !== null) {
      return address.port?.name || "";
    }
    return address?.street;
  };

  const renderAddressData = (address) => {
    if (!address) {
      return <></>;
    }
    if (address.port !== null) {
      return <></>;
    }
    return (
      <Text>{`${address?.["postalCode"]} ${address?.["city"]} - ${address?.["state"]}`}</Text>
    );
  };

  return (
    <CardBox
      title={t("addresses")}
      action={t("see-more")}
      handleAction={navigateTo}
      zeroPaddingBottom
      maxHeight={250}
    >
      {deliveryAddress && deliveryAddress.length > 0 ? (
        deliveryAddress?.map((address, index) => {
          const alias = address?.["user-alias"] || t("address");
          return (
            <Item
              key={index}
              text={alias}
              rightComponent={
                <Flex style={{ gap: 10 }}>
                  <AddressesType address={address} />
                </Flex>
              }
            >
              <Flex flexDirection="column" alignItems={"flex-start"}>
                <Text>{renderAddressStreet(address)}</Text>
                {renderAddressData(address)}
              </Flex>
            </Item>
          );
        })
      ) : (
        <Center>
          <Empty
            spacerHeight={10}
            description={t("empty-section")}
            icon="addresses"
          />
        </Center>
      )}
    </CardBox>
  );
};

export default WidgetBusinessAddresses;
