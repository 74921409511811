import {
  AutoComplete,
  Center,
  Loading,
  toastError,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { getCategories } from "../../../model/ads";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import { gqlWrapper } from "../../../utils/gqlwrapper";
import { useTranslation } from "../../../utils/hooks";
import { adsDefaults } from "../../../utils/variables";

const BaseCategory = ({ state, dispatch, actions, customRender }) => {
  const { t, i18n } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      fetchData();
    }
    return () => {
      ignore = true;
    };
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const fn = await gqlWrapper(getCategories, setDataUser, signOut);
    const results = await fn(i18n.language);
    if (results !== null) {
      if (results?.errors?.length > 0) {
        toastError("Error");
      } else {
        dispatch({
          type: actions.UPDATE_INPUT,
          payload: {
            materials: {
              ...state.materials,
              category: {
                ...state.materials.category,
                options: results,
                open: true,
              },
            },
          },
        });
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        materials: {
          ...state.materials,
          category: {
            ...state.materials.category,
            values: [e.target.value],
          },
        },
      },
    });
  };

  const handleClick = (option, open, setOpen, e) => {
    e.stopPropagation();
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        materials: {
          ...state.materials,
          material: {
            ...adsDefaults,
            open: true,
          },
          type: {
            ...adsDefaults,
            open: false,
          },
          category: {
            ...state.materials.category,
            uuidSelected: [option.uuid],
            nameSelected: [option.name],
            values: [option.name],
          },
        },
      },
    });
  };

  return (
    <>
      {customRender ? (
        customRender(state, dispatch, actions, loading, setLoading)
      ) : loading ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        <AutoComplete
          slug="filter-material-family"
          resultsSlug="filter-material-family-results"
          placeholder={t("select-one")}
          handleOnChange={handleChange}
          model={{ uuid: "uuid", text: "name" }}
          value={state?.materials?.category?.values}
          options={state?.materials?.category?.options}
          handleItemClick={handleClick}
          position="fixed"
          handleOnClickWithEvent={(e, value, open, setOpen) => {
            e.stopPropagation();
            setOpen(!open);
          }}
        />
      )}
    </>
  );
};

export default BaseCategory;
