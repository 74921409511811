import { useEffect } from "react";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { usePaginationProvider } from "../../providers/pagination/PaginationContext";
import { useSortProvider } from "../../providers/sort/SortContext";
import { TABLE_LIMIT } from "../variables";
import { applySortToFilters } from "./functions";

export const usePaginationAction = (callback) => {
  const { state: paginationState, paginationChanged } = usePaginationProvider();

  useEffect(() => {
    if (paginationChanged.current) {
      if (callback && typeof callback === "function") callback(paginationState);
      paginationChanged.current = false;
    }
  }, [paginationState]);
};

export const useFiltersAction = (callback) => {
  const { state: filtersState, filterChanged } = useFiltersProvider();

  useEffect(() => {
    if (filterChanged.current) {
      if (callback && typeof callback === "function") callback(filtersState);
      filterChanged.current = false;
    }
  }, [filtersState]);
};

export const useSortAction = (callback) => {
  const { state: sortState, sortChanged } = useSortProvider();

  useEffect(() => {
    if (sortChanged.current) {
      if (callback && typeof callback === "function") callback(sortState);
      sortChanged.current = false;
    }
  }, [sortState]);
};

/**
 * Custom hook to manage table actions: Pagination, sorting and filters.
 *
 * @param {Object} props - Hooks props.
 * @param {Object} props.paginationCallback - Callback when pagination changes.
 * @param {Object} [props.filtersCallback] - Callback when filters changes.
 * @param {Object} [props.sortCallback] - Callback when sorting changes.
 */
export const useTableActions = ({
  paginationCallback,
  filtersCallback,
  sortCallback,
}) => {
  const { buildFiltersAndFetch } = useFiltersProvider();
  const { handlePagination } = usePaginationProvider();

  const defaultAction = (state) => {
    buildFiltersAndFetch(state, (params) => {
      handlePagination(0);
    });
  };

  usePaginationAction(paginationCallback);
  useFiltersAction(filtersCallback || defaultAction);
  useSortAction(sortCallback || defaultAction);
};

/**
 * Custom hook to manage table initial config.
 * @param {Object} moreTableParams - Aditional table params. EX: lang attribute.
 */
export const useTableConfig = (moreTableParams, { sortingFlag } = {}) => {
  const { getStoredPaginationIfBackPressed } = usePaginationProvider();
  const { getStoredFilters, buildFiltersAndFetch } = useFiltersProvider();
  const filters = buildFiltersAndFetch(getStoredFilters() || {});
  const { state: sortState, buildSort } = useSortProvider();
  const sortFields = buildSort(sortState);
  const tableParams = {
    limit: TABLE_LIMIT,
    offset: getStoredPaginationIfBackPressed()?.current
      ? getStoredPaginationIfBackPressed()?.current
      : 0,
    filter:
      Object.keys(filters)?.length > 0
        ? applySortToFilters(filters, sortFields, sortingFlag)
        : applySortToFilters({}, sortFields, sortingFlag),
    ...moreTableParams,
  };
  return {
    tableParams,
  };
};
