import { useSetDataUser, useSignOut } from "../../providers/AuthHooks";
import { handleCRUD } from "./functions";

export const useCRUD = () => {
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();

  const CRUD = async ({
    endpoint,
    method,
    data,
    successCallback,
    errorCallback,
    messageOk,
    customError,
    setLoading,
    external = false,
  }) => {
    await handleCRUD({
      endpoint,
      method,
      data,
      successCallback,
      errorCallback,
      messageOk,
      customError,
      signOut,
      setDataUser,
      setLoading,
      external,
    });
  };

  return {
    CRUD,
  };
};
