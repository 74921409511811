import React, { useEffect, useRef, useState } from "react";
import { useOutsideAlerter } from "../../../utils/hooks";
import styles from "./Menu.module.scss";
import { IconButton } from "@scrapadev/scrapad-front-sdk";

function Menu({
  items = [],
  open = false,
  onClick,
  onClickOutside,
  selfManaged,
}) {
  const [isOpen, setIsOpen] = useState(open);
  const wrapperRef = useRef(null);
  useOutsideAlerter(
    wrapperRef,
    onClickOutside ? onClickOutside : () => setIsOpen(false)
  );

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const autoManagedOnClick = (e) => {
    e?.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <>
      {selfManaged ? (
        <IconButton
          name={"more_vert"}
          onClick={onClick ? onClick : autoManagedOnClick}
        />
      ) : (
        <></>
      )}
      {isOpen ? (
        <div
          ref={wrapperRef}
          className={styles["sc-menu"]}
          onClick={onClick ? onClick : autoManagedOnClick}
        >
          <div className={styles["sc-menu__inner"]}>
            {items.map((item) => item)}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Menu;
