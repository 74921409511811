import React from "react";
import Center from "../center/Center";
import Padding from "../padding/Padding";
import Spinner from "../spinner/Spinner";

function Loading() {
  return (
    <Center>
      <Padding>
        <Spinner />
      </Padding>
    </Center>
  );
}

export default Loading;
