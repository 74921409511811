import { toastError, toastSuccess } from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import {
  buildChangeFinancesMake,
  buildChangeFinancesRequestDeposit,
  buildNestedTableActions,
  buildMarkCompleted,
  buildConfirmDeposit,
} from "../../components/forms/transaction/functions";
import { useUser } from "../../providers/AuthHooks";
import { useCRUD } from "../crud/hooks";
import { useTranslation } from "../hooks";

/**
 * Hook to mark as received a payment/deposit
 * @param {Function} fetchData - Fetch data callback
 * @returns {{loading: boolean, setLoading: () => boolean, handleMarkReceived: Function}}
 */
export const useMarkAsCompleted = (fetchData) => {
  const [loading, setLoading] = useState(false);
  const { CRUD } = useCRUD();
  const { t } = useTranslation("finances");
  const user = useUser();

  const handleMarkReceived = async (uuidEvent, uuidDeposit) => {
    const data = buildMarkCompleted(user.userUuid, uuidEvent, uuidDeposit);
    setLoading(true);
    await CRUD({
      external: true,
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      data,
      method: "POST",
      successCallback: async () => {
        fetchData(true);
        toastSuccess(t("action-ok"));
        setLoading(false);
      },
      errorCallback: (error) => {
        toastError(error);
        setLoading(false);
      },
    });
  };

  return {
    handleMarkReceived,
    loading,
    setLoading,
  };
};

/**
 * Hook to confirm deposit
 * @param {Function} fetchData - Fetch data callback
 * @returns {{loading: boolean, setLoading: () => boolean, handleConfirmDeposit: Function}}
 */
export const useConfirmDeposit = (fetchData) => {
  const [loading, setLoading] = useState(false);
  const { CRUD } = useCRUD();
  const { t } = useTranslation("common");
  const user = useUser();

  const handleConfirmDeposit = async (uuidUser, uuidEvent, uuidDeposit) => {
    if (!fetchData || !uuidEvent || !uuidDeposit) return;
    const checkedUserUuid = uuidUser ? uuidUser : user.userUuid;
    const data = buildConfirmDeposit(checkedUserUuid, uuidEvent, uuidDeposit);
    setLoading(true);
    await CRUD({
      external: true,
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      data,
      method: "POST",
      successCallback: async () => {
        fetchData(true);
        toastSuccess(t("action-ok"));
      },
      errorCallback: (error) => {
        toastError(error);
        setLoading(false);
      },
    });
  };

  return {
    handleConfirmDeposit,
    loading,
    setLoading,
  };
};

/**
 * Hook to make payments
 * @param {Function} [finalAction=()=>{}] - CRUD finally callback
 * @param {Function} [successAction=()=>{}] - CRUD success callback
 * @param {Function} [errorAction=()=>{}] - CRUD error callback
 * @returns {{ handleMakePayment: (state: {{uuidEvent: string, concept: string, amount: string}}, destination: string) => {}, handleRequestDeposit: (state: {{uuidEvent: string, concept: string, amount: string}}, destination: string) => {} }}
 */
export const useMakePayment = (
  finalAction = () => {},
  successAction = () => {},
  errorAction = () => {}
) => {
  const { CRUD } = useCRUD();
  const { t } = useTranslation("finances");
  const user = useUser();

  const handleRequestDeposit = async (state, destination) => {
    const data = buildChangeFinancesRequestDeposit(
      user.userUuid,
      state,
      destination
    );
    await CRUD({
      external: true,
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      data,
      method: "POST",
      successCallback: async () => {
        successAction();
        finalAction();
      },
      errorCallback: (error) => {
        toastError(error);
        errorAction();
        finalAction();
      },
    });
  };

  const handleMakePayment = async (state, destination) => {
    const data = buildChangeFinancesMake(user.userUuid, state, destination);
    await CRUD({
      external: true,
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      data,
      method: "POST",
      successCallback: async () => {
        finalAction();
      },
      errorCallback: (error) => {
        toastError(error);
        finalAction();
      },
    });
  };

  return {
    handleMakePayment,
    handleRequestDeposit,
  };
};

/**
 * Hook to handle nested table actions.
 * @param {Function} fetchData - Fetch data callback
 * @returns {{ handleAction: (uuidEvent: string, uuidDeposit: string, slug: string) => Promise<void>, loading: boolean, setLoading: (b: boolean) => boolean }}
 */
export const useNestedTableActions = (fetchData) => {
  const [loading, setLoading] = useState(false);
  const { CRUD } = useCRUD();
  const { t } = useTranslation(["common", "finances"]);
  const user = useUser();

  const handleAction = async (uuidEvent, uuidDeposit, slug) => {
    const data = buildNestedTableActions(
      user.userUuid,
      uuidEvent,
      uuidDeposit,
      slug
    );
    setLoading(true);
    await CRUD({
      external: true,
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      data,
      method: "POST",
      successCallback: async () => {
        fetchData();
        toastSuccess(t("action-ok"));
        setLoading(false);
      },
      errorCallback: (error) => {
        toastError(error);
        setLoading(false);
      },
    });
  };

  return {
    handleAction,
    loading,
    setLoading,
  };
};
