export const mapTransportStatusToChip = {
  received: "blue20",
  selected: "success",
  pending: "yellow20",
  expired: "red20",
};

export const equipmentValues = {
  TIPPER: "equipment-tipper",
  TAUTLINER: "equipment-tautliner",
  OCTOPUS: "equipment-ocutopus",
  GROUPAGE: "equipment-groupage",
  MOBILE: "equipment-mobile",
};

export const activityTypes = {
  CARRIES: "activity-carries",
  FORWARDER: "activity-forwarder",
  SHIPPING: "activity-shipping",
};

export const serviceTypes = {
  PROVINCE: "service-province",
  AUTONOMIC: "service-autonomic",
  NATIONAL: "service-national",
};

export const coverageTypes = {
  APPLY: "coverage-not-apply",
  INTRACOMMUNITY: "coverage-intracommunity",
  INTERNATIONAL: "coverage-international",
};

export const equipment = [
  {
    uuid: equipmentValues.TIPPER,
    text: equipmentValues.TIPPER,
  },
  {
    uuid: equipmentValues.TAUTLINER,
    text: equipmentValues.TAUTLINER,
  },
  {
    uuid: equipmentValues.OCTOPUS,
    text: equipmentValues.OCTOPUS,
  },
  {
    uuid: equipmentValues.GROUPAGE,
    text: equipmentValues.GROUPAGE,
  },
  {
    uuid: equipmentValues.MOBILE,
    text: equipmentValues.MOBILE,
  },
];

export const activityType = [
  {
    uuid: activityTypes.CARRIES,
    text: activityTypes.CARRIES,
  },
  {
    uuid: activityTypes.FORWARDER,
    text: activityTypes.FORWARDER,
  },
  {
    uuid: activityTypes.SHIPPING,
    text: activityTypes.SHIPPING,
  },
];

export const service = [
  {
    uuid: serviceTypes.PROVINCE,
    text: serviceTypes.PROVINCE,
  },
  {
    uuid: serviceTypes.AUTONOMIC,
    text: serviceTypes.AUTONOMIC,
  },
  {
    uuid: serviceTypes.NATIONAL,
    text: serviceTypes.NATIONAL,
  },
];

export const coverage = [
  {
    uuid: coverageTypes.APPLY,
    text: coverageTypes.APPLY,
  },
  {
    uuid: coverageTypes.INTRACOMMUNITY,
    text: coverageTypes.INTRACOMMUNITY,
  },
  {
    uuid: coverageTypes.INTERNATIONAL,
    text: coverageTypes.INTERNATIONAL,
  },
];

export const GOODS_TYPE_GENERAL = "3f39d35b-8ad9-4c7a-ba85-249d0715435f";

export const COMBINED_UUID = "7e6f45d8-37b2-4e81-a925-5e754ed9de5f";

export const pricingTypes = {
  PENDING: "pending",
  SENDED: "sended",
  EXPIRED: "expired",
};
