import {
  CheckBox,
  ChipDropdown,
  ClearButton,
  Icon,
} from "@scrapadev/scrapad-front-sdk";
import { Fragment, useState } from "react";
import { useFiltersProvider } from "../../../providers/filters/FiltersContext";
import styles from "./FilterNested.module.scss";

/**
 * Component to show filters nested item.
 * @param {object} props - Component properties.
 * @param {object} props.filter - Current filter.
 * @param {object} props.item - Current filter item.
 * @param {object} props.index - Current item index.
 * @param {object} props.parentItem - Parent item of nested filter.
 * @param {object} props.itemsOpen - Filters open.
 * @param {object} props.setItemsOpen - Setter for itemsOpen.
 * @returns {JSX.Element}
 */
const Item = ({ filter, item, index, parentItem, itemsOpen, setItemsOpen }) => {
  const { dispatch, actions, filterChanged } = useFiltersProvider();
  const selected = filter?.selected || [];

  const handleIsChecked = () => {
    const isSelected = selected.find((f) => item?.uuid === f?.uuid);
    if (!parentItem) {
      const selectedSubItems = selected.filter((f) => f?.parent === item.uuid);
      if (selectedSubItems.length === 0) return isSelected ? 1 : 0;
      if (
        selectedSubItems.length > 0 &&
        selectedSubItems.length < item?.subItems.length
      ) {
        return 2;
      }
      if (selectedSubItems.length === item?.subItems.length) return 1;
    }
    return isSelected ? 1 : 0;
  };

  const openCloseDropdown = () => {
    const newValues = [...itemsOpen];
    newValues[index] === 0 ? (newValues[index] = 1) : (newValues[index] = 0);
    setItemsOpen(newValues);
  };

  const isChecked = handleIsChecked();

  return (
    <li className={styles.item} key={item?.uuid}>
      <ClearButton
        onClick={() => {
          if (filter?.addFilter) return;
          filterChanged.current = true;
          if (parentItem) {
            const parentSelected = selected.filter(
              (f) => f?.uuid === parentItem.uuid
            );
            if (parentSelected.length === 0) {
              dispatch({
                type: actions.ADD_MULTIPLE_FILTER,
                payload: {
                  filter: { slug: filter?.slug },
                  item: [
                    ...selected,
                    {
                      uuid: item?.uuid,
                      name: item?.name,
                      parent: parentItem?.uuid,
                    },
                    {
                      uuid: parentItem?.uuid,
                      name: parentItem?.name,
                    },
                  ],
                },
              });
            } else {
              const subItems = selected?.filter(
                (f) => f?.parent === parentItem?.uuid
              );
              const isAlreadyFiltered = selected?.find(
                (f) => f?.uuid === item.uuid || f?.parent === parentItem?.uuid
              );
              if (
                subItems.length === 1 &&
                subItems?.find((f) => f?.uuid === item.uuid)
              ) {
                dispatch({
                  type: actions.REMOVE_FILTER,
                  payload: {
                    filter: { slug: filter?.slug },
                    item: { uuid: parentItem?.uuid },
                  },
                });
              }
              dispatch({
                type: actions.ADD_FILTER,
                payload: {
                  filter: { slug: filter?.slug },
                  item: {
                    uuid: item?.uuid,
                    name: item?.name,
                    parent: parentItem?.uuid,
                  },
                },
              });
            }
          } else {
            const isAlreadyFiltered = selected?.find(
              (f) => f?.uuid === item.uuid || f?.parent === item?.uuid
            );
            const subItems = item?.subItems || [];
            if (isAlreadyFiltered) {
              dispatch({
                type: actions.REMOVE_MULTIPLE_FILTER,
                payload: {
                  filter: { slug: filter?.slug },
                  item: {
                    uuids: [
                      item?.uuid,
                      ...(subItems?.map((f) => f?.uuid) || []),
                    ],
                  },
                },
              });
            } else {
              dispatch({
                type: actions.ADD_MULTIPLE_FILTER,
                payload: {
                  filter: { slug: filter?.slug },
                  item: [
                    ...selected,
                    { uuid: item?.uuid, name: item?.name },
                    ...subItems?.map((f) => {
                      return {
                        uuid: f?.uuid,
                        name: f?.name,
                        parent: item?.uuid,
                      };
                    }),
                  ],
                },
              });
            }
          }
        }}
      >
        <span className={styles.check}>
          <CheckBox
            readOnly
            thirdState={!parentItem && isChecked === 2}
            checked={isChecked === 1 || isChecked === 2}
            style={{
              paddingRight: 0,
              border: "2px solid #9e9e9e",
              borderRadius: 2,
              minWidth: 16,
              width: 16,
              height: 16,
            }}
          />
        </span>
        {item?.name}
      </ClearButton>
      {item?.subItems ? (
        <ClearButton
          onClick={(e) => {
            e.stopPropagation();
            openCloseDropdown();
          }}
        >
          <Icon
            name={itemsOpen[index] === 0 ? "expand_more" : "expand_less"}
            style={{ fontSize: 16, marginLeft: 10 }}
          />
        </ClearButton>
      ) : (
        <></>
      )}
    </li>
  );
};

/**
 * Component to show filter nested items.
 * @param {object} props - Component properties
 * @param {object} props.filter - Current filter
 * @returns {JSX.Element}
 */
const Items = ({ filter }) => {
  const items = filter?.items || [];
  const selected = filter?.selected || [];
  const [itemsOpen, setItemsOpen] = useState(() => {
    let openState = Array(items.length).fill(0);
    items.forEach((item, index) => {
      const selectedSubItems = selected.filter(
        (f) => f?.parent && item?.uuid === f?.parent
      );
      if (
        selectedSubItems.length > 0 &&
        selectedSubItems.length < item?.subItems?.length
      ) {
        openState[index] = 1;
      }
    });
    return openState;
  });
  return (
    <>
      <ul className={styles.items}>
        {items.map((item, index) => {
          return (
            <Fragment key={index}>
              <Item
                filter={filter}
                item={item}
                index={index}
                itemsOpen={itemsOpen}
                setItemsOpen={setItemsOpen}
              />
              {item?.subItems &&
                item?.subItems?.length > 0 &&
                itemsOpen[index] === 1 && (
                  <ul className={styles.subitems}>
                    {item?.subItems.map((subItem) => (
                      <Item
                        filter={filter}
                        item={subItem}
                        index={index}
                        parentItem={item}
                      />
                    ))}
                  </ul>
                )}
            </Fragment>
          );
        })}
      </ul>
    </>
  );
};

/**
 * Component to show filters with nested items (one level).
 * @param {object} props - Component properties
 * @param {object} props.filter - Current filter
 * @returns {JSX.Element}
 */
const FilterNested = ({ filter }) => {
  return (
    <ChipDropdown
      text={
        filter.label && typeof filter.label === "function"
          ? filter.label(filter)
          : filter?.label
      }
      customRenderItem={(item, openDropdown, setOpenDropdown) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (filter.addFilter) {
                filter.addFilter(item, filter);
              }
            }}
          >
            {item.component && item.component}
          </div>
        );
      }}
      items={[
        {
          uuid: 1,
          component: <Items filter={filter} />,
        },
      ]}
      {...{
        dropDownStyle: {
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 20,
          paddingBottom: 5,
        },
        dropDownItemStyle: {
          padding: 0,
        },
      }}
      {...filter.dropDownProps}
    />
  );
};

export default FilterNested;
