import {
  BREAKPOINT_LG,
  Container,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import { useUser } from "../../../providers/AuthHooks";
import { useCRUD } from "../../../utils/crud/hooks";
import { useTranslation } from "../../../utils/hooks";
import BlueModal from "../../ui/modal/BlueModal";
import ModalText from "../../ui/modal/text/ModalText";

/**
 * Modal for mark finances rows as completed.
 * @param {object} props - Component properties.
 * @param {Array} props.selectedRows - Table rows selected.
 * @returns {JSX.Element}
 */
const ModalMarkReceived = ({ open = false, setOpen, ...props }) => {
  const { t } = useTranslation(["common", "finances"]);
  const [loading, setLoading] = useState(false);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const { CRUD } = useCRUD();
  const user = useUser();

  const renderTitle = () => {
    if (!props?.selectedRows) return "";
    if (props?.selectedRows.length === 1)
      return t("mark-x-as-received").replace("X", props?.selectedRows?.length);
    else
      return t("mark-xs-as-received").replace("X", props?.selectedRows?.length);
  };

  const handleSubmit = async () => {
    let requests = [];
    let errors = [];
    if (!props?.selectedRows) return;
  };

  return (
    <BlueModal
      open={open}
      setOpen={setOpen}
      title={renderTitle()}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("continue")}
      cancelText={t("cancel")}
      reverseActions
      loading={loading}
      modalStyles={{ width: matches ? "auto" : 850 }}
      BodyComponent={
        <Container style={{ margin: "0 -12px", width: "calc(100% + 12px)" }}>
          <div className="row">
            <div className="col-12">
              <ModalText
                style={{
                  fontFamily: "Silka",
                  fontSize: 14,
                  lineHeight: "21px",
                  marginBottom: 0,
                }}
                text={t("mark-as-received-warning")}
              />
            </div>
          </div>
        </Container>
      }
    />
  );
};

export default ModalMarkReceived;
