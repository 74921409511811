import React from "react";
import styles from "./Transport.module.scss";
import { Icon } from "@scrapadev/scrapad-front-sdk";

function Transport({ advertiser = {}, interested = {} }) {
  return (
    <div className={`${styles["transport"]}`}>
      <div className={`${styles["item"]}`}>
        <h6 className={`${styles["heading"]}`}>{advertiser?.orgName}</h6>
        <p
          className={`${styles["body"]}`}
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Icon
            name={"place"}
            color="#9E9E9E"
            style={{ fontSize: "14px", paddingTop: "4px", paddingRight: "5px" }}
          />{" "}
          {advertiser?.address} {advertiser?.portAddress}
        </p>
      </div>
      <div className={`${styles["item"]}`}>
        <h6 className={`${styles["heading"]}`}>{interested?.orgName}</h6>
        {interested?.portAddress && (
          <>
            <p className={`${styles["body"]}`}>
              <span>
                <Icon
                  name={"directions_boat"}
                  color="#9E9E9E"
                  style={{
                    fontSize: "15px",
                    paddingTop: "4px",
                    paddingRight: "5px",
                  }}
                />
              </span>
              {interested?.portAddress}
            </p>
          </>
        )}
        {interested?.address && (
          <>
            <p className={`${styles["body"]}`}>
              <span>
                <Icon
                  name={"local_shipping"}
                  color="#9E9E9E"
                  style={{
                    fontSize: "15px",
                    paddingTop: "4px",
                    paddingRight: "5px",
                  }}
                />
              </span>
              {interested?.address}
            </p>
          </>
        )}
        {/* <p className={`${styles["body"]}`}>
          {interested?.portAddress && interested?.address ? (
            <span>
              <Icon
                name={"directions_boat"}
                color="#9E9E9E"
                style={{
                  fontSize: "15px",
                  paddingTop: "4px",
                  paddingRight: "5px",
                }}
              />
              <Icon
                name={"local_shipping"}
                color="#9E9E9E"
                style={{
                  fontSize: "15px",
                  paddingTop: "4px",
                  paddingRight: "5px",
                }}
              />
            </span>
          ) : (
            <>
              {interested?.portAddress ? (
                <Icon
                  name={"directions_boat"}
                  color="#9E9E9E"
                  style={{
                    fontSize: "15px",
                    paddingTop: "4px",
                    paddingRight: "5px",
                  }}
                />
              ) : (
                <Icon
                  name={"local_shipping"}
                  color="#9E9E9E"
                  style={{
                    fontSize: "15px",
                    paddingTop: "4px",
                    paddingRight: "5px",
                  }}
                />
              )}
            </>
          )}{" "}
          {interested?.address} {interested?.portAddress}
        </p> */}
      </div>
    </div>
  );
}

export default Transport;
