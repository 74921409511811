import {
  BREAKPOINT_LG,
  Container,
  Flex,
  Heading,
  Separator,
  Spacer,
  Typography,
  UnderlinedIconButton,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useParams } from "react-router-dom";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import { renderComponentWithPermission } from "../../../utils/functions";
import { useTranslation } from "../../../utils/hooks";
import {
  modalTypes,
  TEXT_COLOR,
  TEXT_COLOR_LIGHT,
} from "../../../utils/variables";
import { useWalletActions } from "../../../utils/wallet/hooks";
import WidgetWalletApprove from "./Approve";

const Field = ({ heading, text, isLink = false, url }) => {
  const headingStyles = {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "18px",
    color: TEXT_COLOR_LIGHT,
  };

  const textStyles = {
    fontWeight: 300,
    fontSize: 14,
    lineHeight: "21px",
    color: TEXT_COLOR,
  };
  return (
    <Flex flexDirection="column" alignItems={"flex-start"}>
      {heading && (
        <Typography type={2} style={headingStyles}>
          {heading}
        </Typography>
      )}
      {isLink ? (
        url ? (
          <a
            style={{ textDecoration: "underline", ...textStyles }}
            href={url}
            target="_blank"
          >
            {text}
          </a>
        ) : (
          <></>
        )
      ) : text ? (
        <Typography style={textStyles}>{text}</Typography>
      ) : (
        <></>
      )}
    </Flex>
  );
};

const WidgetWalletAccount = ({
  uuid,
  alias,
  swift,
  iban,
  holder = "",
  address,
  files,
  canEdit,
  isEdit,
  setIsEdit,
  setUuidEdit,
  permission,
  status,
  fetchData,
}) => {
  const { t } = useTranslation(["common"]);
  const { id } = useParams();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const {
    open,
    setOpen,
    loading,
    setLoading,
    modalTitle,
    modalDescription,
    okText,
    type: actionType,
    types,
    handleEdit,
    handleRemove,
    handleRemoveAction,
  } = useWalletActions();

  return (
    <>
      <Container>
        {canEdit && (
          <div className="row">
            <div className="col-12">
              <Flex
                justifyContent={"space-between"}
                style={{ marginBottom: 20 }}
              >
                <Heading type={6} style={{ marginBottom: 0 }}>
                  {alias}
                </Heading>
                <Flex justifyContent={"flex-end"}>
                  {renderComponentWithPermission(
                    permission,
                    <WidgetWalletApprove
                      uuid={uuid}
                      checked={status !== "activated" ? false : true}
                      fetchData={fetchData}
                    />
                  )}
                  {renderComponentWithPermission(
                    permission,
                    <>
                      {status !== "activated" && (
                        <>
                          <UnderlinedIconButton
                            iconColor={TEXT_COLOR_LIGHT}
                            iconName={"edit"}
                            text={t("edit")}
                            textStyles={{ color: TEXT_COLOR_LIGHT }}
                            onClick={() => {
                              handleEdit();
                              setUuidEdit(uuid);
                            }}
                          />
                          {/*<Separator />
                          <UnderlinedIconButton
                            iconColor={TEXT_COLOR_LIGHT}
                            iconName={"block"}
                            text={t("disable")}
                            textStyles={{ color: TEXT_COLOR_LIGHT }}
                            onClick={handleRemove}
                          />*/}
                        </>
                      )}
                    </>
                  )}
                </Flex>
              </Flex>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-4">
            <Field heading={t("swift")} text={swift} />
          </div>
          <div className="col-12 col-sm-6 col-lg-8">
            <Field heading={t("iban")} text={iban} />
          </div>
          <Spacer height={matches ? 0 : 25} />
          <div className="col-12 col-sm-6 col-lg-4">
            <Field heading={t("holder")} text={holder} />
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <Field heading={t("address")} text={address} />
          </div>
          <div className="col-12 col-sm-6 col-lg-4">
            <Field
              heading={canEdit ? t("proof-ownership") : t("details-document")}
              text={t("download")}
              isLink
              url={files}
            />
          </div>
        </div>
      </Container>
      {canEdit && (
        <ModalFactory
          key={uuid}
          type={modalTypes.WALLET_BALACE_ACTIONS}
          open={open}
          setOpen={setOpen}
          title={modalTitle}
          description={modalDescription}
          okText={okText}
          uuid={uuid}
          uuidOrg={id}
          fetchData={fetchData}
          loading={loading}
          setLoading={setLoading}
          onSubmit={
            actionType === types.EDIT
              ? () => {
                  setIsEdit(true);
                }
              : handleRemoveAction
          }
        />
      )}
    </>
  );
};

export default WidgetWalletAccount;
