import { Chip, CheckBox } from "@scrapadev/scrapad-front-sdk";
import InputText from "../../../components/ui/input/text/Text";
import Label from "../../../components/ui/input/label/Label";
import { useTranslation } from "../../../utils/hooks";
import styles from "./CollectionDelivery.module.scss";
import { dispatchAction } from "./functions";
import deepmerge from "deepmerge";
import { actions } from "../../../providers/data/actions/data";

/**
 * Helper component for address collection and delivery type.
 * @param {Object} props - Component properties.
 * @param {Object} props.address - Address data.
 * @param {number} props.index - Index of current address.
 * @param {Object} props.state - Address state.
 * @param {Function} props.dispatch - Dispatch function.
 * @returns {JSX.Element}
 */
const WidgetAddressCollectionDelivery = ({
  address,
  index,
  state,
  dispatch,
  permissionManage,
  ...props
}) => {
  const { t } = useTranslation(["common", "business", "talk"]);

  const handleAddressUse =
    (key, type, otherType, index, state, dispatch) => (e) => {
      let value = type;
      if (state?.getOrgLocations[index][key] === "both") {
        value = otherType;
      } else {
        if (state?.getOrgLocations[index][key] === type) {
          value = "";
        }
        if (state?.getOrgLocations[index][key] === otherType && value !== "") {
          value = "both";
        }
      }
      const newState = deepmerge([], state?.getOrgLocations);
      newState[index][key] = value;
      if (!value) {
        if (type === "collection") {
          newState[index]["isCollectionDefault"] = false;
        }
        if (type === "delivery") {
          newState[index]["isDeliveryDefault"] = false;
        }
      }
      if (value === otherType) {
        if (type === "collection") {
          newState[index]["isCollectionDefault"] = false;
        } else {
          newState[index]["isDeliveryDefault"] = false;
        }
      }
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: { getOrgLocations: newState },
      });
    };

  const handleAddressDefault = (index, key, state, dispatch) => (e) => {
    dispatchAction(index, key, e.target.checked, state, dispatch);
  };

  const renderChipType = (types = []) => {
    return types.includes(address?.type)
      ? permissionManage
        ? permissionManage()
          ? "data"
          : "gray40"
        : "data"
      : "normal";
  };

  const handleClick = (callback) => {
    if (!permissionManage) return callback;
    return permissionManage() ? callback : undefined;
  };

  return (
    <>
      <Label>
        {t("address-use")} {props.required && props.skipRequiredHelp ? "*" : ""}
      </Label>
      <div className={styles["sc-address--pick-deliver"]}>
        <Chip
          big
          type={renderChipType(["collection", "both"])}
          text={t("address")}
          handleClick={handleClick(
            handleAddressUse(
              "type",
              "collection",
              "delivery",
              index,
              state,
              dispatch
            )
          )}
        />
        <Chip
          big
          type={renderChipType(["delivery", "both"])}
          text={t("delivery-address")}
          handleClick={handleClick(
            handleAddressUse(
              "type",
              "delivery",
              "collection",
              index,
              state,
              dispatch
            )
          )}
        />
      </div>
      <InputText
        required={props.required}
        value={address?.type !== "" ? address?.type : null}
        style={{
          visibility: "hidden",
          height: 0,
          padding: 0,
          margin: `0 0 .5rem 0`,
        }}
      />
      {(address?.type === "collection" || address?.type === "both") && (
        <div className={styles["sc-address--checkbox"]}>
          <CheckBox
            text={t("default-pick-address")}
            id={`AddressPick${index}`}
            checked={address?.isCollectionDefault}
            onChange={handleClick(
              handleAddressDefault(
                index,
                "isCollectionDefault",
                state,
                dispatch
              )
            )}
          />
        </div>
      )}
      {(address?.type === "delivery" || address?.type === "both") && (
        <div className={styles["sc-address--checkbox"]}>
          <CheckBox
            text={t("default-delivery-address")}
            id={`AddressDelivery${index}`}
            checked={address?.isDeliveryDefault}
            onChange={handleClick(
              handleAddressDefault(index, "isDeliveryDefault", state, dispatch)
            )}
          />
        </div>
      )}
    </>
  );
};

export default WidgetAddressCollectionDelivery;
