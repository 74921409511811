import { toastError, toastSuccess } from "@scrapadev/scrapad-front-sdk";
import { postClient } from "../restclient";

export const handleCRUD = async ({
  endpoint,
  method,
  data,
  successCallback,
  errorCallback,
  messageOk,
  customError,
  signOut,
  setDataUser,
  setLoading,
  external,
}) => {
  const options = {
    url: external
      ? endpoint
      : `/${process.env.REACT_APP_SCRAPAD_API_VERSION}/${endpoint}`,
    method: method,
    signOut,
    setDataUser,
    external,
  };
  let response;
  if (data) options.body = data;
  try {
    response = await postClient(options);
    if (messageOk) toastSuccess(messageOk);
    if (successCallback) successCallback(response);
  } catch (error) {
    customError ? customError(error.message) : toastError(error.message);
    if (errorCallback) errorCallback(error);
  } finally {
    if (setLoading) setLoading(false);
  }
};
