import React, { useContext, useReducer, useMemo } from "react";
import { actions } from "./actions/data";
import deepmerge from "deepmerge";

const DataContext = React.createContext({
  state: null,
  dispatch: null,
});

const getInitialState = () => {
  return {};
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.INITIAL_LOAD:
      return { ...action.payload };
    case actions.UPDATE_INPUT:
      return { ...state, ...action.payload };
    case actions.UPDATE_INPUT_MERGED:
      let merged = deepmerge(state, action.payload);
      return { ...merged };
    default:
      return state;
  }
};

export const DataProvider = ({ initialValue, children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialValue ? initialValue : getInitialState()
  );

  const contextValue = useMemo(
    () => ({ state, dispatch, actions }),
    [state, dispatch]
  );

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
};

export const useDataProvider = () => useContext(DataContext);
