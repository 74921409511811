import { SUCCESS_COLOR } from "../../../utils/variables";
import styles from "./Bar.module.scss";

/**
 * UI Progress bar.
 * @param {Object} props - Component properties.
 * @param {String} [props.color="#60C386"]- Bar color.
 * @param {Number} props.currentValue - Current value.
 * @param {Number} props.total - Total value.
 * @returns {JSX.Element}
 */
const ProgressBar = ({ color = SUCCESS_COLOR, currentValue, total }) => {
  const calculateWidth = () => {
    if (currentValue > total) return 100;
    if (currentValue < 0) return 0;
    return (currentValue * 100) / total;
  };

  if (currentValue === null || total === null) return <></>;
  return (
    <div className={styles["sc-progress"]}>
      <span
        className={styles["sc-progress--bar"]}
        style={{ background: color, width: `${calculateWidth()}%` }}
      ></span>
    </div>
  );
};

export default ProgressBar;
