/**
 * Get transaction id from row cells.
 * @param {object} row - Table row.
 * @returns {object}
 */
export const getTransactionId = (row) => {
  return row?.cells?.find((f) => f?.colRef === "transaction_id")?.value;
};

/**
 * Get buyer seller uuids from row cells.
 * @param {object} row - Table row.
 * @returns {Array}
 */
export const getBuyerSellerUuids = (row) => {
  const value = row?.cells?.find(
    (f) => f?.colRef === "organizations_id"
  )?.value;
  if (!value || !value.includes("#")) return "";
  const parsedValue = value?.split("#");
  return [parsedValue[0], parsedValue[1]];
};
