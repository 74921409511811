import React from "react";
import styles from "./Heading.module.scss";

/**
 * UI Heading component.
 * @param {Object} props - Component properties.
 * @param {('1'|'2'|'3'|'4'|'5'|'6')} [props.type='1'] - Align of children
 * @param {Boolean} [props.paddingMobile=false] - Add extra padding on mobile version
 * @param {Object} [props.style={}] - Inline Styles
 * @returns {JSX.Element}
 */
function Heading({ children, type = 1, style = {}, paddingMobile }) {
  switch (type) {
    case 1:
      return (
        <h1
          className={`${styles["sc-heading"]} ${styles[`sc-heading-${type}`]} ${
            paddingMobile ? styles[`padding-mobile`] : ""
          }`}
          style={style}
        >
          {children}
        </h1>
      );
    case 2:
      return (
        <h2
          className={`${styles["sc-heading"]} ${
            styles[`sc-heading-${type}`]
          }  ${paddingMobile ? styles[`padding-mobile`] : ""}`}
          style={style}
        >
          {children}
        </h2>
      );
    case 3:
      return (
        <h3
          className={`${styles["sc-heading"]} ${styles[`sc-heading-${type}`]} ${
            paddingMobile ? styles[`padding-mobile`] : ""
          }`}
          style={style}
        >
          {children}
        </h3>
      );
    case 4:
      return (
        <h4
          className={`${styles["sc-heading"]} ${styles[`sc-heading-${type}`]} ${
            paddingMobile ? styles[`padding-mobile`] : ""
          }`}
          style={style}
        >
          {children}
        </h4>
      );
    case 5:
      return (
        <h5
          className={`${styles["sc-heading"]} ${styles[`sc-heading-${type}`]} ${
            paddingMobile ? styles[`padding-mobile`] : ""
          }`}
          style={style}
        >
          {children}
        </h5>
      );
    default:
      return (
        <h1
          className={`${styles["sc-heading"]} ${styles[`sc-heading-${type}`]} ${
            paddingMobile ? styles[`padding-mobile`] : ""
          }`}
          style={style}
        >
          {children}
        </h1>
      );
  }
}

export default Heading;
