import Label from "../../../components/ui/input/label/Label";
import InputText from "../../../components/ui/input/text/Text";
import { useTranslation } from "../../../utils/hooks";
import { handleOnChange } from "./functions";

/**
 * Helper component for address alias.
 * @param {Object} props - Component properties.
 * @param {string} props.alias - Address alias.
 * @param {number} props.index - Index of current address.
 * @param {Object} props.state - Address state.
 * @param {Function} props.dispatch - Dispatch function.
 * @returns {JSX.Element}
 */
const WidgetAddressAlias = ({
  alias,
  index,
  state,
  dispatch,
  permissionManage,
  ...props
}) => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <Label>
        {t("address-name")}{" "}
        {props.required && props.skipRequiredHelp ? "*" : ""}
      </Label>
      <InputText
        required
        placeholder={t("write-here")}
        value={alias}
        onChange={handleOnChange(index, "alias", state, dispatch)}
        readOnly={permissionManage ? !permissionManage() : false}
        skipRequiredHelp
        {...props}
      />
    </>
  );
};

export default WidgetAddressAlias;
