import { Flex, Row, Typography } from "@scrapadev/scrapad-front-sdk";
import React from "react";

const WidgetOpportunitiesFormItemAlt = ({
  title,
  price,
  subTitle,
  subPrice,
  slug,
  bottomComponent = <></>,
}) => {
  return (
    <Flex
      data-cy={slug || undefined}
      flexDirection="column"
      alignItems="stretch"
      style={{ width: "100%", gap: "10px" }}
    >
      <Row align="center" justify="space-between">
        <Typography noMargin style={{ fontWeight: "500" }}>
          {title}
        </Typography>
        <Typography noMargin style={{ fontWeight: "500" }}>
          {price}
        </Typography>
      </Row>
      {subTitle || subPrice ? (
        <>
          <Row align="center" justify="space-between">
            <Typography noMargin color="light" small>
              {subTitle}
            </Typography>
            <Typography noMargin color="light" small>
              {subPrice}
            </Typography>
          </Row>
        </>
      ) : (
        <></>
      )}
      {bottomComponent}
    </Flex>
  );
};

export default WidgetOpportunitiesFormItemAlt;

