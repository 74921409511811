import React, { useRef, useState } from "react";
import styles from "./TextArea.module.scss";

/**
 * UI Textarea compoennt.
 * @param {Object} props - Component properties
 * @param {React.ReactChildren} props.children - Component properties.
 * @param {Object} ref - Component reference.
 * @returns {JSX.Element}
 */
const TextArea = ({ children, ...props }, ref) => {
  const [valid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const innerRef = useRef(null);
  const cleanClass = props.cleanInput ? styles["c-input-clean"] : "";
  const addErrorClass = () => {
    const pointerRef = ref ? ref : innerRef;
    if (pointerRef && pointerRef.current) {
      pointerRef.current.classList.add(styles["c-input-invalid"]);
    }
  };

  const removeErrorClass = () => {
    const pointerRef = ref ? ref : innerRef;
    if (pointerRef && pointerRef.current) {
      pointerRef.current.classList.remove(styles["c-input-invalid"]);
    }
  };

  const handleOnInvalid = (e) => {
    e.preventDefault();
    addErrorClass();
    setIsValid(false);
    setErrorMessage(e.target.validationMessage);
    if (props.afterOnInvalid) {
      props.afterOnInvalid(
        e,
        false,
        valid,
        setIsValid,
        errorMessage,
        setErrorMessage
      );
    }
  };

  const handleOnInput = (e) => {
    if (e.target.validity.valid) {
      removeErrorClass();
      setIsValid(true);
      setErrorMessage("");
      if (props.afterOnInput) {
        props.afterOnInput(
          e,
          false,
          valid,
          setIsValid,
          errorMessage,
          setErrorMessage
        );
      }
    }
  };

  return (
    <div className={`${styles["c-input-container"]}`}>
      <div className={styles["c-input-item"]}>
        <textarea
          className={`${styles["sc-textarea"]} ${cleanClass}`}
          ref={ref || innerRef}
          onInvalid={handleOnInvalid}
          onInput={handleOnInput}
          {...props}
          placeholder={
            props.required && props.placeholder
              ? `${props.placeholder} *`
              : props.placeholder
          }
        >
          {children}
        </textarea>
        {props.trailingText && (
          <span className={styles["c-input-trailing"]}>
            {props.trailingText}
          </span>
        )}
      </div>
      {!valid && (
        <p className={`${styles["c-input-validation-message"]}`}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default React.forwardRef(TextArea);
