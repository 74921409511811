import {
  BREAKPOINT_LG,
  Modal,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";
import ModalText from "../ui/modal/text/ModalText";

/**
 * Modal for displaying a message when an action is done in a organization data. EX: Delete a favorite
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalUserWarning = ({
  open = false,
  setOpen,
  reverseActions = true,
  submitButtonMode,
  hasForm = true,
  actionsComponent,
  ...props
}) => {
  const { t } = useTranslation(["common", "business"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const mergedProps = {
    ...{
      title: t("sure-apply-changes"),
      cancelText: t("discard-changes"),
      submitText: t("save-changes"),
      text: t("need-client-consent"),
      loading: false,
      submitStyles: {},
      cancelStyles: {},
    },
    ...props,
  };

  const handleSubmit = async (e) => {
    if (props.onSubmit) props.onSubmit(props?.data?.key);
  };

  return (
    <Modal
      slug={props.slug || undefined}
      open={open}
      setOpen={setOpen}
      title={mergedProps.title}
      reverseActions={reverseActions}
      hasForm={hasForm}
      onSubmit={handleSubmit}
      maxWidth={900}
      loading={mergedProps.loading}
      cancelText={mergedProps.cancelText}
      submitText={mergedProps.submitText}
      submitButtonMode={submitButtonMode}
      ActionsComponent={actionsComponent}
      modalStyles={{ width: matches ? "auto" : 900 }}
      BodyComponent={<ModalText text={mergedProps.text} />}
      submitStyles={mergedProps.submitStyles}
      cancelStyles={mergedProps.cancelStyles}
    />
  );
};

export default ModalUserWarning;
