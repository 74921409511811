import {
  BREAKPOINT_LG,
  Modal,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import React from "react";
import ModalText from "../../ui/modal/text/ModalText";
import { useTranslation } from "../../../utils/hooks";

function NifAlreadyExists({ open = false, setOpen, ...props }) {
  const { t } = useTranslation("common");
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);

  const handleSubmit = async () => {};

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t("nif_already_registerd")}
      reverseActions={true}
      hasForm={true}
      hasActions={false}
      onSubmit={handleSubmit}
      loading={false}
      maxWidth={900}
      cancelText={t("close")}
      submitText={t("accept")}
      modalStyles={{ width: matches ? "auto" : 800 }}
      BodyComponent={
        <>
          <ModalText text={t("nif_already_registed_2")} />
          {/* <ModalText text={t("nif_already_registed_3")} /> */}
        </>
      }
    />
  );
}

export default NifAlreadyExists;

