import { Flex } from "@scrapadev/scrapad-front-sdk";
import WhiteFilters from "../../components/ui/filters/WhiteFilters";
import { whiteFilterWithAutoCompleteProps } from "../../utils/filters/variables";
import { useTranslation } from "../../utils/hooks";
import WidgetOpportunitiesTable from "./OpportunitiesTable";
import { RoleType } from "../../utils/variables";
import { useUser } from "../../providers/AuthHooks";
import { getTable } from "../../model/tables";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { applySortToFilters } from "../../utils/tables/functions";
import { useSortProvider } from "../../providers/sort/SortContext";

/**
 * Helper layout component on all the opportunities lists.
 * @param {object} props - Component properties.
 * @param {object} props.filterProps - Filters additional props suchs as initialFilters.
 * @param {object} props.tableProps - Table additional props suchs as actions.
 * @returns {JSX.Element}
 */
const WidgetOpportunitiesLayout = ({ filterProps, tableProps }) => {
  const { t } = useTranslation(["common"], true);
  const user = useUser();
  const { buildFiltersAndFetch, getStoredFilters } = useFiltersProvider();
  const { state: sortState, buildSort } = useSortProvider();
  const sortFields = buildSort(sortState);
  const newFilters = buildFiltersAndFetch(getStoredFilters() || {});

  return (
    <>
      <Flex
        alignItems={"flex-start"}
        flexDirection={"column"}
        style={{ marginTop: 30, marginBottom: 30 }}
      >
        <Flex
          justifyContent={"flex-start"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          style={{ width: "100%" }}
        >
          <WhiteFilters
            showChips
            chipsBelowFilters
            containerStyles={{
              justifyContent: "space-between",
              width: "100%",
            }}
            innerContainerStyles={{
              gap: 10,
            }}
            filtersSelectionMultipleProps={{
              ...whiteFilterWithAutoCompleteProps,
              autoCompleteInputProps: {
                ...whiteFilterWithAutoCompleteProps.autoCompleteInputProps,
                inputProps: {
                  placeholder: t("write-to-search"),
                },
              },
            }}
            canDownload={user?.userData?.role === RoleType.superadmin}
            fileName={"opportunities"}
            exportProps={{
              fnData: getTable,
              fnParameters: {
                ...tableProps.baseTableParams,
                filter: applySortToFilters(newFilters, sortFields),
              },
              fileName: "opportunities",
            }}
            {...filterProps}
          />
        </Flex>
      </Flex>
      <WidgetOpportunitiesTable {...tableProps} />
    </>
  );
};

export default WidgetOpportunitiesLayout;
