import React, { useEffect, useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import {
  BREAKPOINT_LG,
  Flex,
  InputText,
  Label,
  Padding,
  Row,
  Spinner,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import Select from "../input/select/Select";
import _ from "lodash";
import styles from "./AddressInput.module.scss";
import { getCountries } from "../../../model/countries";
import { getFavAddresses } from "../../../model/business";
import { gqlWrapper } from "../../../utils/gqlwrapper";
import { printAddress } from "../../../utils/functions";

function AddressInput({
  showLabel = false,
  labelText,
  onFacilitiesAlreadySetted = () => {},
  avoidPort = false,
  externalToggle = false,
  defaultShowNewAddress = false,
  defaultAddress,
  countryLabelProps = {},
  orgUuid,
  onValueChange = () => {},
}) {
  const { t } = useTranslation("common");
  const [showNewAddress, setShowNewAddress] = useState(defaultShowNewAddress);
  const [favDirections, setFavDirections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const { i18n } = useTranslation();
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [haveSelection, setHaveSelection] = useState(false);
  const [value, setValue] = useState({});

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setShowNewAddress(defaultShowNewAddress);
  }, [defaultShowNewAddress]);

  useEffect(() => {
    onValueChange(value);
  }, [value]);

  useEffect(() => {
    if (showNewAddress) {
      setValue({});
    }
  }, [showNewAddress]);

  const handleNewAddress = (option) => () => {
    setShowNewAddress(option);
    onFacilitiesAlreadySetted(false);
    if (option === false) {
      setTimeout(() => {
        fetchData();
      }, 50);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const countriesData = await getCountries(i18n.language);
      setCountries(countriesData);

      const fn = await gqlWrapper(getFavAddresses, setDataUser, signOut);
      const response = await fn({
        locale: i18n.language,
        module: "locations",
        org: orgUuid,
      });

      const res = response.getOrgLocations;
      let parseAddress = res.map((loc) => ({
        uuid: loc.id,
        place: loc.alias,
        type: loc.type,
        city: loc.city,
        state: loc.state,
        street: loc.street,
        port: loc?.port,
        country: loc?.country || "",
        facilities: loc?.facilities,
      }));

      let addressesFiltered = [];
      if (avoidPort) {
        addressesFiltered = parseAddress?.filter((e) => !e.port);
      } else {
        addressesFiltered = parseAddress;
      }

      setFavDirections(addressesFiltered);

      if (parseAddress.length === 0) {
        setShowNewAddress(true);
      } else {
        setValue({
          id: addressesFiltered[0]?.uuid,
        });
        if (!_.isEmpty(addressesFiltered[0]?.facilities)) {
          onFacilitiesAlreadySetted(true);
        }
      }
    } catch (err) {
      setFavDirections([]);
      setShowNewAddress(true);
    } finally {
      setIsLoading(false);
    }
  };

  return showNewAddress ? (
    <Flex flexDirection="column" alignItems="stretch" style={{ width: "100%" }}>
      {showLabel && (
        <Label
          text={t("new_address")}
          noPadding
          light
          small
          style={{ marginBottom: "5px" }}
        />
      )}
      <Row gap={20} wrap={matches}>
        <Label
          reduced={true}
          small
          semiBold
          light
          text={`${t("street-number", { ns: "talk" })}*`}
        >
          <InputText
            mode="underline"
            required
            placeholder={t("write-here")}
            name="street_number"
            autocomplete="off"
            onChange={(e) => {
              setValue({
                ...value,
                street: e.target.value,
              });
            }}
          />
        </Label>
        <Label
          reduced={true}
          small
          semiBold
          light
          text={`${t("zip-code", { ns: "talk" })}*`}
        >
          <InputText
            mode="underline"
            required
            placeholder={t("write-here")}
            name="zip_code"
            autocomplete="off"
            onChange={(e) => {
              setValue({
                ...value,
                cp: e.target.value,
              });
            }}
          />
        </Label>
      </Row>
      <Row gap={20} wrap={matches}>
        <Label
          reduced={true}
          small
          semiBold
          light
          text={`${t("town", { ns: "talk" })}*`}
        >
          <InputText
            mode="underline"
            required
            placeholder={t("write-here")}
            name="town"
            autocomplete="off"
            onChange={(e) => {
              setValue({
                ...value,
                locality: e.target.value,
              });
            }}
          />
        </Label>
        <Label
          reduced={true}
          small
          semiBold
          light
          text={`${t("country")}*`}
          {...countryLabelProps}
        >
          <div style={{ display: "flex" }}>
            <Select
              name="country"
              required={true}
              style={{
                marginBottom: "15px",
                position: "relative",
                top: "-4px",
              }}
              mode="underline"
              options={[
                <option
                  key={"s_country_placeholder"}
                  value="NONE"
                  disabled
                  selected
                  hidden
                >
                  ...
                </option>,
                ...countries.map((country) => (
                  <option
                    key={"s_country_" + country.uuid}
                    value={country.uuid?.split("-")[0]}
                  >
                    {country.name}
                  </option>
                )),
              ]}
              autocomplete="off"
              onChange={(e) => {
                setValue({
                  ...value,
                  country: e.target.value * 1,
                });
              }}
            />
            {isLoading ? (
              <Padding>
                <Spinner />
              </Padding>
            ) : (
              <></>
            )}
          </div>
        </Label>
      </Row>
      {!externalToggle && (
        <Row gap={20}>
          <button
            type="button"
            className={`${styles["sc-addresses-delivery-button"]} ${styles["sc-addresses-use-saved"]}`}
            onClick={handleNewAddress(false)}
          >
            {t("stored-address", { ns: "talk" })}
          </button>
        </Row>
      )}
    </Flex>
  ) : (
    <div className="col-12">
      <div className={styles["sc-addresses"]}>
        <span style={{ width: "100%" }}>
          {showLabel && (
            <Label
              text={labelText || t("address")}
              noPadding
              style={{ marginBottom: "-10px" }}
            />
          )}

          {isLoading ? (
            <Spinner />
          ) : (
            <Select
              mode="underline"
              name="favourite_address"
              required={true}
              options={favDirections.map((fd) => (
                <option
                  key={"s_fd_" + fd.uuid}
                  value={fd.uuid}
                  selected={
                    !haveSelection ? defaultAddress === fd?.uuid : undefined
                  }
                >
                  {printAddress(fd)}
                </option>
              ))}
              style={{
                fontSize: "14px",
              }}
              autocomplete="off"
              onChange={(e) => {
                const uuidSelected = e?.target?.value;
                const selectedAddress = favDirections?.find(
                  (e) => e.uuid === uuidSelected
                );
                setValue({
                  id: uuidSelected,
                });
                if (selectedAddress?.port) {
                  onFacilitiesAlreadySetted(true);
                } else {
                  onFacilitiesAlreadySetted(
                    !_.isEmpty(selectedAddress?.facilities)
                  );
                }
                setHaveSelection(true);
              }}
            />
          )}
        </span>
      </div>
      {!externalToggle && (
        <button
          type="button"
          className={styles["sc-addresses-delivery-button"]}
          onClick={handleNewAddress(true)}
        >
          {t("enter-not-saved-address", { ns: "talk" })}
        </button>
      )}
    </div>
  );
}

export default AddressInput;

