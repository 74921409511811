import { useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  Center,
  Flex,
  Loading,
  Padding,
  TooltipSc,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import Container from "../../components/ui/container/Container";
import Header from "../../components/ui/header/Header";
import Icon from "../../components/ui/icon/Icon";
import NavBar from "../../components/ui/navbar/NavBar";
import Steps from "../../components/ui/steps/Steps";
import LayoutDetail from "../../layout/detail/Detail";
import { useSteps, useTranslation } from "../../utils/hooks";
import { SUCCESS_COLOR } from "../../utils/variables";
import WidgetDataWrapper from "../../widgets/wrappers/DataWrapper";
import ItemHeading from "./heading/Heading";
import {
  getHeaderInfo,
  getValidationInfo,
  getVerificationFilesModule,
} from "../../model/business";
import Alert from "../../components/ui/alert/Alert";
import WidgetVerificationFiles from "../../widgets/business/verification/Files";
import WidgetVerificationSave from "../../widgets/business/verification/Save";
import { useRef, useState } from "react";
import { businessSteps } from "../../utils/router/functions";
import HistoryBlockWrapper from "../../wrappers/HistoryBlockWrapper";
import { handleCRUD } from "../../utils/crud/functions";
import { useSetDataUser, useSignOut } from "../../providers/AuthHooks";
import { buildVerificationFiles } from "../../utils/verification/functions";
import { useHeaderProvider } from "../../providers/header/HeaderContext";
import BasicVerificationValidation from "../../widgets/business/verification/BasicValidation";
import GQLFetchWrapper from "../../wrappers/FetchWrapper";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import CompleteVerificationValidation from "../../widgets/business/verification/CompleteValidation";

const Approved = () => {
  return (
    <Icon
      name={"check_circle"}
      color={SUCCESS_COLOR}
      style={{ marginRight: 12, fontSize: "1rem" }}
    />
  );
};

const Tooltip = ({ content }) => {
  const { t } = useTranslation(["verification"]);
  if (!content) return <></>;
  return (
    <TooltipSc
      content={<>{t(content)}</>}
      style={{ maxWidth: 250 }}
      elementStyles={{ width: 18, height: 18, marginLeft: 10 }}
    >
      <Icon name="info" color={"#777986"} style={{ fontSize: 18 }} />
    </TooltipSc>
  );
};

const BusinessVerification = ({
  id,
  state,
  loading,
  setLoading,
  fetchData,
}) => {
  const { t, i18n } = useTranslation([
    "common",
    "transactions",
    "verification",
  ]);
  const navigate = useNavigate();
  const { steps } = useSteps(id);
  const [isDirty, setIsDirty] = useState([0, 0, 0, 0, 0, 0]);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const kycDocuments = state?.getKycDocuments;
  const basicFields = kycDocuments?.filter((f) => f?.providerUse === "basic");
  const lemonwayFields = kycDocuments?.filter(
    (f) => f?.providerUse === "complete"
  );

  const { state: headerState } = useHeaderProvider(
    i18n.language,
    id,
    getHeaderInfo
  );
  const { canManageKYC } = useOperationsProvider();

  const fieldToTooltip = (field) => {
    const mapped = {
      "waste-management-license": "tooltip-waste-license",
      "commercial-register": "tooltip-business-registry",
      "certificate-of-incorporation": "tooltip-business-constitution",
      "capital-breakdown": "tooltip-business-ownership",
    };
    return mapped[field] || null;
  };

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor={"#fff"}
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <Header
                  title={headerState?.basicData?.orgName}
                  goBack
                  breadcrumbs={[
                    {
                      description: t("business"),
                      onClick: () => navigate("/business", { replace: true }),
                    },
                    {
                      description: headerState?.basicData?.orgName,
                      onClick: () =>
                        navigate(`/business/${id}`, { replace: true }),
                    },
                    { description: t("verification") },
                  ]}
                  fullSubHeaderLeft
                  subHeaderLeft={
                    <Steps
                      steps={steps}
                      currentStep={"verification"}
                      checkCurrentByLabel
                    />
                  }
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <HistoryBlockWrapper
            mustBlockLeaving={isDirty.find((f) => f === 1)}
            submitCallback={(modalOpen, setModalOpen) =>
              (e, loading, setLoading) => {
                setModalOpen(false);
              }}
          >
            {loading ? (
              <Center>
                <Padding>
                  <Loading />
                </Padding>
              </Center>
            ) : (
              <Container>
                <div className="row">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <ItemHeading
                          title={t("basic-verification")}
                          rightComponent={
                            canManageKYC() ? (
                              <Flex
                                justifyContent={"flex-end"}
                                style={{ width: "100%" }}
                              >
                                <GQLFetchWrapper
                                  fnData={getValidationInfo}
                                  fnParameters={{
                                    locale: i18n.language,
                                    org: id,
                                  }}
                                  id={id}
                                  Component={BasicVerificationValidation}
                                />
                              </Flex>
                            ) : undefined
                          }
                        >
                          {basicFields?.map((basic) => {
                            return (
                              <Accordion
                                beforeTitle={
                                  basic?.isCompleted ? <Approved /> : <></>
                                }
                                title={basic?.description}
                                defaultOpen={true}
                                headerExtra={
                                  <Tooltip content={basic?.helpContent} />
                                }
                              >
                                <WidgetDataWrapper
                                  id={id}
                                  fnData={getVerificationFilesModule}
                                  fnParameters={{
                                    files: basic?.documents
                                      ? [...basic?.documents]
                                      : [],
                                  }}
                                  Component={WidgetVerificationFiles}
                                  extraData={{
                                    permission: canManageKYC,
                                    fetchData: fetchData,
                                    setLoading: setLoading,
                                    canEdit: true,
                                    id: id,
                                    afterUploadAllFiles: async (files) => {
                                      if (files && files.length > 0) {
                                        setLoading(true);
                                        handleCRUD({
                                          endpoint: `org/${id}/verification`,
                                          data: {
                                            files: files.map((f) => ({
                                              id: f.uuid,
                                              type: basic?.type,
                                            })),
                                          },
                                          method: "POST",
                                          setDataUser,
                                          signOut,
                                          successCallback: async () => {
                                            await fetchData();
                                            setLoading(false);
                                          },
                                        });
                                      }
                                    },
                                  }}
                                />
                              </Accordion>
                            );
                          })}
                        </ItemHeading>
                        <ItemHeading
                          title={t("full-verification")}
                          rightComponent={
                            canManageKYC() ? (
                              <Flex
                                justifyContent={"flex-end"}
                                style={{ width: "100%" }}
                              >
                                <GQLFetchWrapper
                                  fnData={getValidationInfo}
                                  fnParameters={{
                                    locale: i18n.language,
                                    org: id,
                                  }}
                                  id={id}
                                  Component={CompleteVerificationValidation}
                                />
                              </Flex>
                            ) : undefined
                          }
                        >
                          {lemonwayFields?.map((lemonway, index) => {
                            return (
                              <Accordion
                                beforeTitle={
                                  lemonway?.isCompleted ? <Approved /> : <></>
                                }
                                title={lemonway?.description}
                                defaultOpen={true}
                                headerExtra={
                                  <Tooltip content={lemonway?.helpContent} />
                                }
                              >
                                <WidgetDataWrapper
                                  id={id}
                                  fnData={getVerificationFilesModule}
                                  fnParameters={{
                                    files: lemonway?.documents
                                      ? [...lemonway?.documents]
                                      : [],
                                  }}
                                  Component={WidgetVerificationFiles}
                                  extraData={{
                                    canEdit: true,
                                    permission: canManageKYC,
                                    afterUploadAllFiles: (files) => {
                                      if (files && files.length > 0) {
                                        setLoading(true);
                                        handleCRUD({
                                          endpoint: `org/${id}/verification`,
                                          data: {
                                            files: files.map((f) => ({
                                              id: f.uuid,
                                              type: lemonway?.type,
                                            })),
                                          },
                                          method: "POST",
                                          setDataUser,
                                          signOut,
                                          successCallback: async () => {
                                            await fetchData();
                                            setLoading(false);
                                          },
                                        });
                                      }
                                    },
                                    fetchData: fetchData,
                                    setLoading: setLoading,
                                    id: id,
                                  }}
                                />
                              </Accordion>
                            );
                          })}
                        </ItemHeading>
                      </div>
                    </div>
                  </div>
                </div>
              </Container>
            )}
          </HistoryBlockWrapper>
        }
      />
    </>
  );
};

export default BusinessVerification;
