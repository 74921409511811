import React from "react";
import styles from "./TabTitle.module.scss";

function TabTitle({ icon, label, alert, small }) {
  return (
    <div
      className={`${styles["sc-tab-title"]} ${small ? styles["small"] : ""} `}
    >
      <div className={`${styles["sc-tab-title__inner"]}`}>
        {icon ? <div className={`${styles["icon"]}`}>{icon}</div> : <></>}
        {label ? <div className={`${styles["label"]}`}>{label}</div> : <></>}
        {alert ? <div className={`${styles["alert"]}`} /> : <></>}
      </div>
    </div>
  );
}

export default TabTitle;
