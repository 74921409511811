import {
  GREEN_COLOR_100,
  Flex,
  Chip,
  useMatchMedia,
  BREAKPOINT_LG,
  Loading,
  SearchAutoComplete,
  AutoCompleteMultipleItem,
  AutoComplete,
} from "@scrapadev/scrapad-front-sdk";
import React, { useRef, useState } from "react";
import { getCatalogNoTerm } from "../../../model/filters";
import { useGQL } from "../../../utils/gql/hooks";
import { useTranslation } from "../../../utils/hooks";

const TransportProviders = ({ state, dispatch, actions }) => {
  const { t, i18n } = useTranslation(["logistics", "common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const serachValueRef = useRef("");
  const { data, loading: catalogLoading } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: "transporters",
  });

  const handleAddProvider = (providerToAdd) => {
    const obj = {};
    const isAlreadySelected = state?.providers.find(
      (provider) => provider?.uuid === providerToAdd?.uuid
    );
    if (isAlreadySelected) {
      handleRemoveProvider(providerToAdd);
    } else {
      obj.providers = [...state?.providers, providerToAdd];
      dispatch({ type: actions.UPDATE_INPUT, payload: obj });
    }
  };

  const handleRemoveProvider = (providerToDelete) => {
    const obj = {
      providers: state?.providers.filter(
        (provider) => provider?.uuid !== providerToDelete?.uuid
      ),
    };
    dispatch({ type: actions.UPDATE_INPUT, payload: obj });
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          {catalogLoading ? (
            <Loading />
          ) : (
            <>
              <SearchAutoComplete
                resultsStyles={{
                  padding: 0,
                }}
                required={state?.providers?.length === 0}
                multiple
                newMultipleItemFormat
                hideInBoxSearchInput
                itemStopPropagation
                mode="underline"
                value={""}
                options={data?.errors ? [] : data}
                trailingIcon={<></>}
                inputProps={{ placeholder: t("write-or-select") }}
                placeholder={t("select-a-value")}
                model={{ uuid: "uuid", text: "name" }}
                handleItemClick={(option, open, setOpen, e) => {
                  e.stopPropagation();
                  handleAddProvider(option);
                }}
                handleOnChange={(e) => {
                  serachValueRef.current = e.target.value;
                }}
                handleIsChecked={(option) =>
                  state?.providers?.find(
                    (provider) => provider?.uuid === option?.uuid
                  )
                }
                handleOnClickWithEvent={(e, value, open, setOpen) => {
                  setOpen(!open);
                }}
                renderNoResultsItem={() => {
                  const isEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
                  if (serachValueRef.current.match(isEmail))
                    return (
                      <>
                        <li
                          style={{
                            fontFamily: "Silka",
                            fontSize: 12,
                            lineHeight: "18px",
                          }}
                          onClick={() => {
                            handleAddProvider({
                              uuid: "external",
                              name: serachValueRef.current,
                            });
                          }}
                        >
                          <strong>
                            {t("use-as-provider-1")}{" "}
                            <span
                              style={{
                                color: GREEN_COLOR_100,
                                padding: 0,
                              }}
                            >
                              {serachValueRef.current}
                            </span>{" "}
                            {t("use-as-provider-2")}
                          </strong>
                        </li>
                        <li
                          style={{
                            height: 1,
                            padding: "0 15px",
                          }}
                        >
                          <hr
                            style={{
                              opacity: 1,
                              width: "100%",
                              borderTop: "1px solid #E0E2EB",
                            }}
                          />
                        </li>
                        <li>{t("empty-data")}</li>
                      </>
                    );
                  return <li>{t("empty-data")}</li>;
                }}
              />
            </>
          )}
        </div>
      </div>
      <Flex
        justifyContent={"flex-start"}
        style={{ gap: 15, marginTop: "-15px" }}
      >
        {state?.providers?.map((provider) => (
          <Chip
            big
            type="white-outlined-active"
            text={provider?.name}
            handleClick={() => {
              handleRemoveProvider(provider);
            }}
            showIcon
          />
        ))}
      </Flex>
    </>
  );
};

export default TransportProviders;
