import React from "react";
import styles from "./FileList.module.scss";
import FileItem from "./file_item/FileItem";
import { useDocumentsProvider } from "../../../../../providers/documents/DocumentsContext";
import { documentActions } from "../../../../../providers/documents/actions/DocumentsActions";
import { Center, Empty, Loading } from "@scrapadev/scrapad-front-sdk";
import _ from "lodash";
import { SortingMenuTypes } from "../../../../../components/ui/sorter/SortingMenu";

function FileList() {
  const { state, dispatch, openFolder, openFile } = useDocumentsProvider();

  const sorter = (data = [], mode) => {
    if (mode) {
      switch (mode) {
        case SortingMenuTypes.DESC:
          return _.reverse(_.sortBy(data, ["name"]));
        case SortingMenuTypes.ASC:
          return _.sortBy(data, ["name"]);
        case SortingMenuTypes.DATE_DESC:
          return _.reverse(_.sortBy(data, ["date"]));
        case SortingMenuTypes.DATE_ASC:
          return _.sortBy(data, ["date"]);
        default:
          return data;
      }
    }

    return data;
  };

  const printItems = () => {
    let folders = sorter(state?.dataVisible?.subfolders, state?.sorting);
    let files = sorter(state?.dataVisible?.files, state?.sorting);

    if (state)
      return (
        <>
          {folders?.map((folder) => (
            <FileItem
              uuid={folder?.id}
              key={`document_folder_${folder?.id}`}
              selected={state?.bulkSelected?.includes(folder?.id)}
              name={folder?.name}
              url={folder?.url}
              isPrivate={folder?.private}
              isFolder={true}
              onClick={(e) => {
                openFolder(folder?.id, folder?.owner);
              }}
              onCheck={(e) => {
                dispatch({
                  type: documentActions.SET_SELECTED_FILES,
                  payload: {
                    uuid: folder?.id,
                  },
                });
              }}
            />
          ))}
          {files?.map((file) => (
            <FileItem
              uuid={file?.id}
              name={file?.name}
              url={file?.url}
              thumbnailUrl={file?.thumbnailURL}
              isFolder={false}
              selected={state?.bulkSelected?.includes(file?.id)}
              onClick={(e) => {
                openFile(file?.url);
              }}
              onCheck={(e) => {
                dispatch({
                  type: documentActions.SET_SELECTED_FILES,
                  payload: {
                    uuid: file?.id,
                  },
                });
              }}
            />
          ))}
        </>
      );
  };

  return (
    <div className={styles["file_list"]}>
      {state?.loading ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        <>
          {printItems()}

          {_.isEmpty(state?.dataVisible?.subfolders) &&
          _.isEmpty(state?.dataVisible?.files) ? (
            <>
              <Center>
                <Empty />
              </Center>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
}

export default FileList;
