import { Flex, Icon, ClearButton } from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import { DataProvider } from "../../../providers/data/DataContext";
import { useTranslation } from "../../../utils/hooks";
import { modalTypes, TEXT_COLOR_LIGHT } from "../../../utils/variables";

/**
 * Helper component for add new address.
 * @param {Object} props - Component properties.
 * @param {Object} props.state - Address state.
 * @param {Function} props.dispatch - Dispatch function.
 * @returns {JSX.Element}
 */
const WidgetAddressNew = ({ id, fetchData }) => {
  const { t } = useTranslation(["common"]);
  const [open, setOpen] = useState(false);

  const handleNew = () => {
    setOpen(!open);
  };

  return (
    <Flex justifyContent={"flex-end"}>
      <ClearButton
        onClick={handleNew}
        style={{
          fontFamily: "Roboto",
          fontWeight: 300,
          fontSize: 14,
          lineHeight: "21px",
          color: "#626262",
        }}
      >
        <Icon
          name="add_circle_outline"
          color={TEXT_COLOR_LIGHT}
          style={{ fontSize: 20, marginRight: 10 }}
        />
        <span style={{ textDecoration: "underline" }}>
          {t("add-new-address")}
        </span>
      </ClearButton>
      <DataProvider>
        <ModalFactory
          type={modalTypes.DASHBOARD_NEW_ADDRESS}
          open={open}
          setOpen={setOpen}
          id={id}
          fetchData={fetchData}
        />
      </DataProvider>
    </Flex>
  );
};

export default WidgetAddressNew;
