import {
  Heading,
  Icon,
  Row,
  Typography,
  WARM_NEUTRAL_50,
} from "@scrapadev/scrapad-front-sdk";
import React from "react";
import styles from "./DocumentLocationItem.module.scss";
import { useTranslation } from "../../../../utils/hooks";

function DocumentLocationItem({ locationName }) {
  const { t } = useTranslation("common");

  return (
    <div className={styles["document_location_item"]}>
      <Heading type={7}>{t("current_location")}:</Heading>
      <Row
        gap={10}
        align="center"
        style={{ paddingLeft: "10px", paddingRight: "10px" }}
      >
        <Icon name={"folder"} color={WARM_NEUTRAL_50} />
        <Typography noMargin>{locationName}</Typography>
      </Row>
    </div>
  );
}

export default DocumentLocationItem;
