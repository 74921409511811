import { Heading, Paper, Switch } from "@scrapadev/scrapad-front-sdk";
import {
  costValuesWithDefaults,
  getFetchData,
  useImputeCosts,
} from "../../utils/opportunities/functions";
import { INFO_COLOR, TEXT_COLOR } from "../../utils/variables";
import WidgetOpportunitiesFormInput from "./OpportunitiesCostFOrmInput";
import WidgetOpportunitiesFormItem from "./OpportunitiesCostFormItem";
import { costEvents } from "../../utils/opportunities/variables";

/**
 * Helper widget to show costs form.
 * @param {object} props - Component properties.
 * @param {object} props.id - Opportinity id.
 * @param {object} props.state - Costs current state.
 * @param {object} props.dispatch - Widget provider dispatch.
 * @param {object} props.actions - Widget provider actions.
 * @param {object} props.loading - Widget loading state.
 * @param {object} props.setLoading - Widget loading setter.
 * @param {object} props.fetchData - Fetching data function.
 * @returns {JSX.Element}
 */
const WidgetOpportunitiesCostsForm = ({
  id,
  state,
  dispatch,
  actions,
  loading,
  setLoading,
  fetchData,
  opportunityId,
  title,
  updateList = () => {},
}) => {
  const fields = state.fields;
  const { handleSendCost } = useImputeCosts();

  const handleFetchData = async () => {
    if (!fetchData || !setLoading) return;
    setLoading(true);
    await fetchData(false, getFetchData(false, id, fields));
    updateList();
  };

  const handleSwitch = (field, index) => async (e) => {
    const newState = [...fields];
    newState[index] = costValuesWithDefaults({
      slug: field.slug,
      value: field.value,
      editable: field.editable,
      optional: field.editable,
      active: e.target.checked,
    });
    dispatch({ type: actions.UPDATE_INPUT, payload: { fields: newState } });
    await handleSendCost({
      id: id,
      type: costEvents.PUT_TRANSACTION_COST,
      value: e.target.checked,
      setLoading: setLoading,
      fetchData: fetchData,
      fields: fields,
      costName: field?.slug,
      //uuidOfferOpportunity: opportunityId,
    });
    await handleFetchData();
  };

  return (
    <Paper
      whiteMode
      style={{
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        gap: 0,
      }}
    >
      {title && (
        <Heading
          type={3}
          style={{
            margin: 0,
            color: TEXT_COLOR,
            fontSize: 17,
            lineHeight: "25px",
            paddingLeft: "5px",
            paddingTop: "10px",
          }}
        >
          {title}
        </Heading>
      )}
      {fields.map((field, index) => {
        const values = costValuesWithDefaults(field);
        return (
          <WidgetOpportunitiesFormItem
            key={values.slug}
            slug={values.slug}
            isLast={index === fields.length - 1}
            leftComponent={
              values.optional ? (
                <Switch
                  disabled={loading}
                  label={values.name}
                  isLabelInLeft={false}
                  checked={values.active}
                  onChange={loading ? undefined : handleSwitch(field, index)}
                  inputStyle={
                    values.active
                      ? {
                          color: INFO_COLOR,
                          backgroundColor: INFO_COLOR,
                          opacity: loading ? ".5" : 1,
                        }
                      : {
                          opacity: loading ? ".5" : 1,
                        }
                  }
                  labelStyle={{
                    color: values.active ? TEXT_COLOR : "#9E9E9E",
                    fontFamily: "Silka",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                />
              ) : (
                <p
                  data-ref={values.slug}
                  style={{
                    margin: 0,
                    fontFamily: "Silka",
                    fontSize: 14,
                    lineHeight: "21px",
                    fontWeight: 500,
                    color: TEXT_COLOR,
                  }}
                >
                  {values.name}
                </p>
              )
            }
            rightComponent={
              <WidgetOpportunitiesFormInput
                state={state}
                dispatch={dispatch}
                actions={actions}
                values={values}
                index={index}
                loading={loading}
                fetchData={handleFetchData}
                setLoading={setLoading}
                id={id}
                opportunityId={opportunityId}
              />
            }
          />
        );
      })}
    </Paper>
  );
};

export default WidgetOpportunitiesCostsForm;
