import React, { useRef } from "react";
import styles from "./SearchInput.module.scss";
import {
  Button,
  Icon,
  IconButton,
  WARM_NEUTRAL_40,
} from "@scrapadev/scrapad-front-sdk";
import _ from "lodash";
import { useTranslation } from "../../../utils/hooks";

function SearchInput(
  {
    handleOnInvalid,
    handleOnInput,
    onClearSearch,
    searchBarOpen,
    onChange = () => {},
    handleOnClick,
    placeholder,
    ...props
  },
  ref
) {
  const { t } = useTranslation("common");
  const innerRef = useRef(null);

  return (
    <div
      className={`${styles["search-input"]} ${
        //searchBarOpen && _.isEmpty(props.value) && styles["isOpen"]
        searchBarOpen && styles["isOpen"]
      }`}
    >
      <div className={`${styles["search-input__inner"]}`}>
        <div className={`${styles["input-wrap"]}`}>
          <div className={`${styles["leading-icon-wrap"]}`}>
            <Icon name="search" color={WARM_NEUTRAL_40} />
          </div>
          <input
            data-cy="ad_search_input"
            ref={ref || innerRef}
            onInvalid={handleOnInvalid}
            onInput={handleOnInput}
            onChange={onChange}
            onClick={() => {
              if (handleOnClick) {
                handleOnClick();
              }
            }}
            {...props}
            placeholder={
              !searchBarOpen
                ? placeholder
                  ? placeholder
                  : t("what-are-you-looking-for")
                : t("write-keywords-to-search")
            }
          />
          {onClearSearch && !_.isEmpty(props.value) && (
            <IconButton
              data-cy="ad_search_button_clear"
              name="close"
              onClick={onClearSearch}
            />
          )}

          <>
            <div
              className={styles["hover-shadow"]}
              style={{
                width: props?.value?.length > 1 ? "auto" : "0",
                opacity: props?.value?.length > 1 ? 1 : 0,
                transition: "all .5s ease-in-out",
                overflow: props?.value?.length > 1 ? "unset" : "hidden",
                transform:
                  props?.value?.length > 1
                    ? "translateX(0)"
                    : "translateX(75px)",
              }}
            >
              <Button
                data-cy="ad_search_button_submit"
                component="button"
                text={t("search")}
                onClick={() => {
                  props.onKeyDown({
                    target: {
                      value: props.value,
                    },
                    key: "Enter",
                    preventDefault: () => {},
                  });
                }}
                mode="success"
                style={{
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }}
              />
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default React.forwardRef(SearchInput);
