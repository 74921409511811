import { Center, Loading, Pagination } from "@scrapadev/scrapad-front-sdk";
import { id } from "date-fns/locale";
import { useEffect } from "react";
import Ads from "../../components/ui/ads/Ads";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { usePaginationProvider } from "../../providers/pagination/PaginationContext";
import { useTranslation } from "../../utils/hooks";
import { useTableActions } from "../../utils/tables/hooks";
import { ADS_LIMIT } from "../../utils/variables";

const WidgetAdsGrid = ({
  loading,
  state,
  dispatch,
  actions,
  bufferState,
  fetchData,
  transactionsOpen,
  setTransactionsOpen,
  setAdUuid,
  adsProps = {},
  uuidOrg,
  permission,
}) => {
  const { t, i18n } = useTranslation(["common"]);
  const {
    dispatch: paginationDispatch,
    actions: paginationActions,
    paginationChanged,
    getStoredPaginationIfBackPressed,
  } = usePaginationProvider();
  const { buildFiltersAndFetch, getStoredFilters } = useFiltersProvider();

  const handlePagination = (page) => {
    paginationDispatch({
      type: paginationActions.SET_PAGINATION,
      payload: { current: page, uuidOrg: uuidOrg },
    });
    paginationChanged.current = true;
  };

  useTableActions({
    paginationCallback: (paginationState) => {
      const newFilters = buildFiltersAndFetch(
        getStoredFilters() || {},
        undefined,
        uuidOrg
          ? [
              {
                field: "byOrgId",
                value: uuidOrg,
              },
            ]
          : undefined
      );
      fetchData({
        locale: i18n.language,
        limit: ADS_LIMIT,
        offset: paginationState?.current,
        filter: newFilters,
      });
    },
  });

  return loading ? (
    <Center>
      <Loading />
    </Center>
  ) : (
    <div className="col-12">
      <Ads
        data={state}
        emptyMessageCode={t("section-empty")}
        link={`/ads`}
        onOffersClick={(uuid) => {
          setTransactionsOpen(true);
          setAdUuid(uuid);
        }}
        fetchData={() => {
          fetchData({
            locale: i18n.language,
            limit: ADS_LIMIT,
            offset: getStoredPaginationIfBackPressed()?.current,
            filter: { ops: buildFiltersAndFetch(getStoredFilters() || {}) },
          });
        }}
        pagination={{
          currentPage: Math.ceil(state?.pagination?.current / ADS_LIMIT),
          totalPages: Math.ceil(state?.pagination?.total / ADS_LIMIT),
          threshold: 3,
          onChangePage: handlePagination,
          onChangePrev: handlePagination,
          onChangeNext: handlePagination,
        }}
        permission={permission}
        {...adsProps}
      />
    </div>
  );
};

export default WidgetAdsGrid;
