import { Loading, SearchAutoComplete } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import { getCatalogNoTerm } from "../../../model/filters";
import { useGQL } from "../../../utils/gql/hooks";
import { useTranslation } from "../../../utils/hooks";

/**
 * Catalog for incoterms.
 * @param {object} props - Component properties.
 * @param {string} props.value - Incoterm value.
 * @param {Function} [props.handleItemClick] - Item click callback.
 * @param {object} [props.autoCompleteProps={}] - Autocomplete props.
 * @returns {React.ReactNode}
 */
const CatalogIncoterm = ({
  handleItemClick,
  value,
  autoCompleteProps = {},
}) => {
  const { t, i18n } = useTranslation(["common"]);
  const { data, loading } = useGQL(getCatalogNoTerm, {
    locale: i18n.language,
    name: "incoterms",
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <SearchAutoComplete
      newMultipleItemFormat
      value={data?.errors ? [] : data?.find((f) => f?.uuid === value)?.name}
      options={data?.errors ? [] : data}
      inputProps={{ placeholder: t("write-or-select") }}
      placeholder={t("select-a-value")}
      model={{ uuid: "uuid", text: "name" }}
      containerStyles={{ width: "100%" }}
      handleItemClick={(option, open, setOpen, e) => {
        e.stopPropagation();
        if (handleItemClick && typeof handleItemClick === "function") {
          handleItemClick(option, open, setOpen, e);
        }
      }}
      handleOnClickWithEvent={(e, value, open, setOpen) => {
        setOpen(!open);
      }}
      {...autoCompleteProps}
    />
  );
};

export default CatalogIncoterm;
