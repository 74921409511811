import React from "react";
import styles from "./ListTile.module.scss";
import { CheckBox, Radio } from "@scrapadev/scrapad-front-sdk";

function ListTile({
  radio,
  checkbox,
  name,
  onClick,
  checked,
  value,
  id,
  leading,
  title,
  trailing,
  subtitle,
}) {
  const labelWrap = (child) => {
    if (radio || checkbox) {
      return (
        <label
          className={styles["list_tile__label"]}
          onClick={() => {
            onClick({
              name,
              value,
              id,
            });
          }}
        >
          {child}
        </label>
      );
    }

    return child;
  };

  return (
    <>
      {labelWrap(
        <div
          className={`${styles["list_tile"]} ${
            radio ? styles["radio_mode"] : ""
          }
          ${checkbox ? styles["checkbox_mode"] : ""}
          `}
        >
          {leading && (
            <div className={styles["list_tile_leading"]}>{leading}</div>
          )}
          {radio && (
            <div className={styles["list_tile_leading"]}>
              <Radio
                style={{ margin: 0 }}
                id={id}
                name={name}
                value={value}
                checked={checked}
              />
            </div>
          )}
          {checkbox && (
            <div className={styles["list_tile_leading"]}>
              <CheckBox
                style={{ margin: 0 }}
                id={id}
                name={name}
                value={value}
                checked={checked}
              />
            </div>
          )}
          <div className={styles["list_tile_body"]}>
            <div className={styles["list_tile_title"]}>
              <div className={styles["list_tile_title_text"]}>{title}</div>
              {trailing && (
                <div className={styles["list_tile_title_trailing"]}>
                  {trailing}
                </div>
              )}
            </div>
            {subtitle && (
              <div className={styles["list_tile_subtitle"]}>{subtitle}</div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ListTile;
