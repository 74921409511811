import { Center, Loading, Padding } from "@scrapadev/scrapad-front-sdk";
import GoBack from "../back/GoBack";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import styles from "./Header.module.scss";

const Header = ({
  title,
  titleSmall = false,
  afterTitle,
  goBack = false,
  breadcrumbs = [],
  titleRightComponent,
  subtitle,
  subHeaderLeft,
  fullSubHeaderLeft,
  subHeaderRight,
  fullSubHeaderRight,
  containerStyles = {},
  upperStyles = {},
  middleStyles = {},
  middleRightStyles = {},
  lowerStyles = {},
  lowerLeftStyles = {},
  lowerRightStyles = {},
  loading,
}) => {
  return (
    <div className={styles["sc-header--container"]} style={containerStyles}>
      {loading ? (
        <Center>
          <Padding>
            <Loading />
          </Padding>
        </Center>
      ) : (
        <>
          <div className={styles["sc-header--upper"]} style={upperStyles}>
            {goBack && (
              <>
                <GoBack />
                <span className={styles["sc-header--separator"]}></span>
              </>
            )}
            {breadcrumbs.length > 0 && <Breadcrumbs items={breadcrumbs} />}
          </div>
          <div className={styles["sc-header--middle"]} style={middleStyles}>
            <h1
              className={
                titleSmall
                  ? styles["sc-header--title-small"]
                  : styles["sc-header--title"]
              }
            >
              {title}
              {afterTitle && afterTitle}
            </h1>
            <div
              className={styles["sc-header--middle-right"]}
              style={middleRightStyles}
            >
              {titleRightComponent && titleRightComponent}
            </div>
          </div>
          <div className={styles["sc-header--lower"]} style={lowerStyles}>
            {(subtitle || subHeaderLeft) && (
              <div
                className={styles["sc-header--lower-left"]}
                style={
                  fullSubHeaderLeft
                    ? { flexGrow: 1, maxWidth: "100%", ...lowerLeftStyles }
                    : lowerLeftStyles
                }
              >
                {subtitle && (
                  <h2 className={styles["sc-header--subtitle"]}>{subtitle}</h2>
                )}
                {subHeaderLeft && subHeaderLeft}
              </div>
            )}

            {subHeaderRight && (
              <div
                className={styles["sc-header--lower-right"]}
                style={
                  fullSubHeaderRight
                    ? { flexGrow: 1, maxWidth: "100%", ...lowerRightStyles }
                    : lowerRightStyles
                }
              >
                {subHeaderRight}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
