import { useEffect, useRef, useState } from "react";
import Loading from "../../loading/Loading";
import { getCountries } from "../../../../model/countries";
import { useTranslation } from "../../../../utils/hooks";
import { Dropdown } from "@scrapadev/scrapad-front-sdk";

const getCountryFromCode = (value, countries) => {
  return countries.find((f) => {
    const parsedUuid = f.uuid.split("-");
    return parseInt(parsedUuid[0]) === parseInt(value);
  })?.name;
};

/**
 * UI Countries component.
 * @param {Object} props - Component properties.
 * @param {String} props.value - Selected value.
 * @param {Function} props.handleItemClick - Item click handler.
 * @returns {JSX.Element}
 */
const InputCountries = ({
  value,
  handleItemClick,
  onInnerValueChanged = () => {},
  ...props
}) => {
  const { t, i18n } = useTranslation(["common"]);
  const [countries, setCountries] = useState([]);
  const [countriesLoading, setCountriesLoading] = useState(false);
  const [innerValue, setInnerValue] = useState("");
  const firstRender = useRef(true);

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (!firstRender.current) {
      setInnerValue(getCountryFromCode(value, countries) || "");
    }
  }, [value]);

  useEffect(() => {
    onInnerValueChanged(innerValue);
  }, [innerValue]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  }, []);

  const handleClick = (option, open, setOpen) => {
    if (props.readOnly) return;
    setCountries([...countries]); // Little dirty hack to show again all option un filtered.
    handleItemClick(option, open, setOpen);
  };

  const fetchCountries = async () => {
    setCountriesLoading(true);
    const response = await getCountries(i18n.language);
    setCountries(response);
    setCountriesLoading(false);
    setInnerValue(getCountryFromCode(value, response));
  };

  return countriesLoading ? (
    <Loading />
  ) : (
    <Dropdown
      position="fixed"
      value={[innerValue]}
      options={countries}
      handleItemClick={handleClick}
      placeholder={t("select-value")}
      model={{ text: "name", uuid: "uuid" }}
      readOnly={countriesLoading}
      disableEnterText={false}
      handleOnChange={(e) => setInnerValue(e.target.value)}
      {...props}
    />
  );
};

export default InputCountries;

