import WidgetFieldLocation from "../../fields/Location";
import { dispatchAction } from "./functions";

/**
 * Helper component for address location.
 * @param {Object} props - Component properties.
 * @param {Object} props.address - Address info.
 * @param {number} props.index - Index of current address.
 * @param {Object} props.state - Address state.
 * @param {Function} props.dispatch - Dispatch function.
 * @returns {JSX.Element}
 */
const WidgetAddressLocation = ({
  address,
  index,
  state,
  dispatch,
  permissionManage,
}) => {
  const handleOnChangeLocation = (index, state, dispatch) => (key) => (e) => {
    const mapped = {
      street: "street",
      state: "state",
      town: "city",
      postal: "postalCode",
    };
    dispatchAction(index, mapped[key], e.target.value, state, dispatch);
  };

  return (
    <>
      <WidgetFieldLocation
        streetValue={address?.street}
        postalValue={address?.["postalCode"]}
        townValue={address?.city}
        stateValue={address?.state}
        handleOnChange={handleOnChangeLocation(index, state, dispatch)}
        streetClassName={"col-12 col-lg-8"}
        postalClassName={"col-12 col-lg-4"}
        streetProps={{
          required: true,
          skipRequiredHelp: true,
          readOnly: permissionManage ? !permissionManage() : false,
        }}
        postalProps={{
          required: true,
          skipRequiredHelp: true,
          readOnly: permissionManage ? !permissionManage() : false,
        }}
        townProps={{
          required: true,
          skipRequiredHelp: true,
          readOnly: permissionManage ? !permissionManage() : false,
        }}
        stateProps={{
          readOnly: permissionManage ? !permissionManage() : false,
        }}
      />
    </>
  );
};

export default WidgetAddressLocation;
