import {
  Button,
  Container,
  Icon,
  IconItem,
  Loading,
  Row,
  TableManager,
  toastError,
  toastWarning,
  WARM_NEUTRAL_60,
} from "@scrapadev/scrapad-front-sdk";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "../../utils/hooks";
import { useEffect, useRef, useState } from "react";
import { useSetDataUser, useSignOut } from "../../providers/AuthHooks";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import {
  QUERY_UPPER_LIMIT,
  TEXT_COLOR,
  modalTypes,
  tableModules,
} from "../../utils/variables";
import { useDocumentTitle } from "../../utils/document/hooks";
import NavBar from "../../components/ui/navbar/NavBar";
import LayoutDetail from "../../layout/detail/Detail";
import TransactionHeader from "./header/TransactionHeader";
import TransactionDetailHeader from "./header/Detail";
import TransactionSteps from "../../widgets/transactions/steps/Steps";
import LayoutTable from "../../layout/table/Table";
import { getTable } from "../../model/tables";
import { useTableDataProvider } from "../../providers/TableContext";
import EmptyQuotes from "../../widgets/transactions/transport/Empty";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import { gqlWrapper } from "../../utils/gqlwrapper";
import {
  getTransactionDataBO,
  getTransactionDetail,
} from "../../model/transactions";
import { mapTransportStatusToChip } from "../../utils/logistics/variables";
import SearchFilterExport from "../../components/ui/search-filter-export/SearchFilterExport";
import { searchOnTable } from "../../utils/functions";
import ChipOutlined from "../../components/ui/chip/ChipOutlined";

const TransactionQuotationsDetail = () => {
  const { id } = useParams();
  const { t, i18n, values } = useTranslation([
    "common",
    "transactions",
    "finances",
    "ads",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const signOut = useSignOut();
  const navigate = useNavigate();
  const setDataUser = useSetDataUser();
  const [data, setData] = useState([]);
  const {
    canManageTransaction,
    canReadLogistics,
    canReadFinances,
    canManageLogistics,
    canManageTransactionMessages,
    canManageTransactionSamplesVerificationTransport,
    canManageTransactionReturn,
    canReadTransactionMessages,
    canReadTransactionSamplesVerificationTransport,
    canManageFinances,
  } = useOperationsProvider();

  const tableParams = {
    locale: i18n.language,
    offset: 0,
    limit: QUERY_UPPER_LIMIT,
    tableModule: tableModules.QUOTATIONS,
    filter: {
      ops: [
        {
          field: "uuidTransaction",
          value: id,
        },
      ],
    },
  };

  useEffect(() => {
    fetchData();
  }, [i18n.language]);

  const fetchData = async (quiet = false) => {
    if (!quiet) setIsLoading(true);
    try {
      // const fn = await gqlWrapper(getTransactionDetail, setDataUser, signOut);
      const fn = await gqlWrapper(getTransactionDataBO, setDataUser, signOut);
      const results = await fn(i18n.language, id);
      if (results === null) {
        setData([]);
      } else {
        if (results?.errors?.length > 0) {
          setData([]);
          toastError(results?.errors[0]?.message);
        } else {
          setData(results);
        }
      }
    } catch (e) {
      setData([]);
      toastError(e.message || e);
    } finally {
      if (!quiet) setIsLoading(false);
    }
  };

  let typeIcon = (
    <Icon
      name={"swap_horiz"}
      color={WARM_NEUTRAL_60}
      style={{
        fontSize: "16px",
      }}
    />
  );
  const subtitle = (
    <ChipOutlined iconComponent={typeIcon}>
      <span style={{ textTransform: "uppercase" }}>
        {data?.header?.uuidConversation?.split("-")?.[0]}
      </span>
    </ChipOutlined>
  );

  useDocumentTitle(`${t("transaction")} ${id.split("-")?.[0]}`, values);

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerWithContainer
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <TransactionHeader
                      goBack
                      imageUrl={data?.sideData?.originAd?.image}
                      breadcrumbs={[
                        {
                          description: t("transactions"),
                          onClick: () =>
                            navigate("/transactions", {
                              replace: true,
                            }),
                        },
                        { description: data?.header?.adTitle },
                      ]}
                      title={data?.header?.adTitle}
                      subtitle={subtitle}
                      rightArea={
                        <TransactionDetailHeader
                          uuidEvent={id}
                          title={data?.header?.adTitle}
                          subtitle={subtitle}
                          fetchData={fetchData}
                          permission={canManageTransaction}
                          permissionLogistics={canReadLogistics}
                          onlyMenu
                        />
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <>
            <Container style={{ marginBottom: "10px" }}>
              <TransactionSteps id={id} currentStep={"quotations"} />
            </Container>
            <LayoutTable fnData={getTable} fnParameters={tableParams}>
              <InnerTransactionQuotation />
            </LayoutTable>
          </>
        }
      />
    </>
  );
};

const InnerTransactionQuotation = () => {
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();
  const [launchWarnOpen, setLaunchWarnOpen] = useState(false);
  const { t, i18n } = useTranslation(
    ["common", "transactions", "logistics"],
    true
  );
  const { data, setData, loading, setLoading, fetchData, dataBuffer } =
    useTableDataProvider();
  useDocumentTitle(`${t("transport-quotes")} ${id.split("-")?.[0]}`);
  const uuidQuote = useRef(0);
  const rowQuotation = useRef({});
  const previuosQuotation = useRef(null);
  const { canManageLogistics } = useOperationsProvider();
  const emptyQuotations = data?.body?.length === 0;

  const actions = [
    {
      component: (
        <IconItem
          name="visibility"
          color={"#777986"}
          textColor={TEXT_COLOR}
          text={t("view")}
          onClick={() => {
            switch (rowQuotation?.current?.state) {
              case "pending":
                if (rowQuotation?.current?.untilDate !== "") {
                  toastError(t("quotation-expired"));
                  return;
                }
                break;
              case "expired":
                toastError(t("quotation-expired"));
                return;
            }
            setOpenView(!openView);
          }}
        />
      ),
    },
    {
      component: (
        <IconItem
          name="check_circle"
          color={"#777986"}
          textColor={TEXT_COLOR}
          text={t("incorporate-transaction")}
          onClick={() => {
            switch (rowQuotation?.current?.state) {
              case "pending":
                toastWarning(t("quotation-not-received"));
                return;
              case "selected":
                toastWarning(t("already-selected-quotation"));
                return;
              case "expired":
                toastError(t("quotation-expired"));
                return;
            }
            const selectedRow = data?.body?.find((f) => {
              return f?.cells?.find((f) => f?.value === "selected");
            });
            if (selectedRow) {
              previuosQuotation.current = selectedRow.uuid;
            }
            setOpen(!open);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Container>
        <Row
          gap={25}
          align="centere"
          justify="space-between"
          style={{ paddingBottom: "15px" }}
        >
          <div>
            <SearchFilterExport
              isLocalSearch
              searchPlaceholder={t("search")}
              searchOnChange={searchOnTable(
                data,
                setData,
                dataBuffer.current,
                i18n
              )}
              hasFilters={false}
              hasSeparator={false}
              hasExport={false}
            />
          </div>
          <Button
            component="button"
            type="button"
            text={t("new_request")}
            ultrawide
            mode="info"
            onClick={() => {
              navigate("new/");
            }}
          />
        </Row>
        <div className="row">
          <div className="col-12">
            <TableManager
              isInfoTable
              loading={loading}
              data={data}
              setData={setData}
              emptyText={
                dataBuffer.current?.body?.length > 0
                  ? t("empty-data")
                  : undefined
              }
              customEmptyElement={
                dataBuffer.current?.body?.length === 0 ? (
                  <EmptyQuotes
                    onClick={() => {
                      navigate("new/");
                      //setNewQuotationOpen(true);
                    }}
                  />
                ) : undefined
              }
              rowTriggerPageChange={false}
              actions={
                !canManageLogistics
                  ? actions
                  : canManageLogistics()
                  ? actions
                  : []
              }
              onClickActionCallback={(uuid, index, row) => {
                uuidQuote.current = row.uuid;
                const cells = row?.cells;
                const state = cells?.[7]?.value;
                const unitPrice = cells?.[5]?.value;
                rowQuotation.current = {
                  suplier: cells?.[0]?.value,
                  supplierUuid: cells?.[8]?.value,
                  untilDate: cells?.[6]?.value,
                  state,
                  numLote: cells?.[4]?.value,
                  unitPrice:
                    state === "received" ? unitPrice?.split(" ")[0] : 0,
                };
              }}
              itemsProps={{
                chip: {
                  mapSlugValues: mapTransportStatusToChip,
                  translate: (code) => t(code),
                },
                date: {
                  languageData: i18n.getLanguageVariable(i18n.language),
                },
              }}
            />
          </div>
        </div>
        <ModalFactory
          key={uuidQuote}
          rowQuotation={rowQuotation}
          fetchData={fetchData}
          uuidQuote={uuidQuote.current}
          previuosQuotation={previuosQuotation.current}
          type={modalTypes.LOGISTICS_INCORPORATE_TRANSACTION}
          open={open}
          setOpen={setOpen}
        />
        <ModalFactory
          key={uuidQuote}
          uuidQuote={uuidQuote.current}
          type={modalTypes.LOGISTICS_VIEW_QUOTATION}
          open={openView}
          setOpen={setOpenView}
        />
        <ModalFactory
          key={uuidQuote}
          uuidQuote={uuidQuote.current}
          type={modalTypes.LOGISTICS_ADD_QUOTATION_WARN}
          open={launchWarnOpen}
          setOpen={setOpenView}
          id={id}
          onSubmit={() => {
            console.log("lanzado");
          }}
        />
      </Container>
    </>
  );
};

export default TransactionQuotationsDetail;
