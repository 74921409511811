import React, { useContext, useReducer, useMemo, useEffect } from "react";
import { actions } from "./actions/transactions-forms";

const TransactionsFormsContext = React.createContext({
  state: null,
  dispatch: null,
});

const getInitialState = () => {
  return {};
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.INITIAL_LOAD:
      return { ...action.payload };
    case actions.UPDATE_INPUT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const TransactionsFormsProvider = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: actions.INITIAL_LOAD, payload: initialState });
  }, [initialState]);

  const contextValue = useMemo(() => {
    return { state, dispatch, actions };
  }, [state]);

  return (
    <TransactionsFormsContext.Provider value={contextValue}>
      {children}
    </TransactionsFormsContext.Provider>
  );
};

export const useTransactionsFormsProvider = () =>
  useContext(TransactionsFormsContext);
