import { gql } from "@apollo/client";
import client from "../utils/gqlclient";

export async function getAllNotifications(
  offset = 0,
  limit = 12,
  byType,
  byDate,
  unread
) {
  const filters = {};
  if (unread) filters["unread"] = unread;
  if (byType) filters["byType"] = byType;
  if (byDate?.from) filters["byDate"] = { from: byDate.from };
  if (byDate?.to) filters["byDate"] = { ...filters["byDate"], to: byDate.to };
  const variables = {
    offset: offset * limit,
    limit: limit,
  };
  if (byType || byDate || unread) variables["ops"] = { ...filters };
  const data = await client.query({
    query: gqlAllNotifications,
    variables,
  });
  return {
    errors: data.errors,
    pagination: {
      total: data.data.getNotifications?.pagination?.total,
      current: data.data.getNotifications?.pagination?.current,
    },
    notifications: (data.data.getNotifications?.notifications || []).map(
      (notification) => {
        return {
          ...notification,
        };
      }
    ),
  };
}

export async function getPreferences(lang) {
  const data = await client.query({
    query: gqlPreferences,
    variables: { lang },
  });
  return {
    errors: data.errors,
    preferences: data?.data?.getUserNotificationPreferences,
  };
}

const gqlAllNotifications = gql`
  query ($offset: Int!, $limit: Int!, $ops: FilterOpsNoti) {
    getNotifications(offset: $offset, limit: $limit, ops: $ops) {
      notifications {
        id
        read
        createdDate
        content
        title
        category
        action
        link
      }
      pagination {
        current
        total
      }
    }
  }
`;

const gqlPreferences = gql`
  query ($lang: String!) {
    getUserNotificationPreferences(lang: $lang) {
      categoryDesc
      categoryName
      channelsPeferences {
        channelName
        selected
        uuidCategory
        uuidChannel
      }
    }
  }
`;
