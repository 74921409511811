import { Flex, Paper, Spacer, Typography } from "@scrapadev/scrapad-front-sdk";

/**
 * Helper widget to show text items in totals.
 * @param {object} props - Component properties.
 * @param {string} props.text - The text of the total. EX "A percibir (vendedor)"
 * @param {string} props.description - The description of the total. EX: Payment method.
 * @param {boolean} props.bolder - To indicate a bold text.
 * @param {boolean} props.light - To indicate a light text.
 * @param {boolean} props.paperMode - Description wrap on paper
 * @returns {JSX.Element}
 */
const WidgetOportunitiestextitem = ({
  text,
  description,
  bolder,
  light,
  paperMode = false,
  style = {},
}) => {
  const renderText = () => (
    <Typography
      type="2"
      style={{
        fontSize: bolder ? 14 : 13,
        lineHeight: bolder ? "21px" : "19px",
        fontWeight: light ? 400 : bolder ? 600 : 500,
        margin: 0,
      }}
    >
      {text}
    </Typography>
  );

  if (!description) {
    return renderText();
  }

  return (
    <Flex flexDirection="column" alignItems="flex-start" style={style}>
      {renderText()}
      <Spacer height={8} />
      <PaperWrap active={paperMode}>
        <Typography
          type="2"
          style={{
            fontSize: 12,
            lineHeight: "18px",
            fontWeight: 400,
            margin: 0,
            color: "#9E9E9E",
          }}
        >
          {description}
        </Typography>
      </PaperWrap>
    </Flex>
  );
};

function PaperWrap({ active, children }) {
  if (!active) {
    return children;
  }
  return <Paper>{children}</Paper>;
}

export default WidgetOportunitiestextitem;
