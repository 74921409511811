import { useNavigate } from "react-router-dom";
import CardBox from "../../../components/ui/box/CardBox";
import Item from "../../../components/ui/item/Item";
import Text from "../../../components/ui/text/Text";
import { useTranslation } from "../../../utils/hooks";
import { TEXT_COLOR_LIGHT } from "../../../utils/variables";
import styles from "./Contact.module.scss";
import { Dropdown, Icon } from "@scrapadev/scrapad-front-sdk";
import _ from "lodash";
import { useVerificationChangeStatus } from "../../../utils/business/hooks";

/**
 * Contact info widget for dashboard.
 * @param {Object} props - Component properties.
 * @param {String} props.id - Business id.
 * @param {Array} props.keys - Data state keys.
 * @param {Object} props.state - Data state.
 * @param {Function} props.dispatch - Dispatch function.
 * @param {Object} props.actions - Actions available.
 * @returns {JSX.Element}
 */
const WidgetBusineesContact = ({
  id,
  state,
  dispatch,
  actions,
  fetchData = () => {},
  userList = [],
}) => {
  const { t } = useTranslation(["common", "transactions"]);
  const navigate = useNavigate();
  const { handleAssignedAgent } = useVerificationChangeStatus();

  const handleChangeUser = (option, open, setOpen) => {
    handleAssignedAgent({
      id: id,
      agentAssigned: option?.uuid,
      fetchData: fetchData,
      setLoading: () => {},
    });
    setTimeout(() => {
      setOpen(false);
    }, 5);
  };

  const navigateTo = () => navigate(`/business/${id}/about`);

  const getAddressName = (address = {}, hideStreet = false) => {
    let output = "";

    if (!_.isEmpty(address?.street) && !hideStreet) {
      output += `${address?.street} \n`;
    }
    if (!_.isEmpty(address?.postalCode)) {
      output += `${address?.postalCode}`;
    }
    if (!_.isEmpty(address?.city)) {
      output += `, ${address?.city}`;
    }
    if (!_.isEmpty(address?.state)) {
      output += `, ${address?.state}`;
    }
    if (!_.isEmpty(address?.country)) {
      output += `, ${address?.country} `;
    }
    if (!_.isEmpty(address?.port?.name)) {
      output += `${address?.port?.name} `;
    }

    return output;
  };

  return (
    <CardBox
      title={t("contact-info")}
      action={t("see-more")}
      handleAction={navigateTo}
      zeroPaddingBottom
    >
      <Item text={state?.basicData?.orgName}>
        <div className={styles["sc-item"]}>
          {state?.legalReprenstative?.email && (
            <>
              <Icon name={"email"} color={TEXT_COLOR_LIGHT} />
              <Text>{state?.legalReprenstative?.email}</Text>
            </>
          )}
        </div>
        <div className={styles["sc-item"]}>
          <Icon name={"call"} color={TEXT_COLOR_LIGHT} />
          <Text>{`${state?.basicData?.prefix || ""} ${
            state?.basicData?.phone || ""
          }`}</Text>
        </div>
        <div
          className={styles["sc-item"]}
          title={getAddressName(state?.fiscalAddress)}
        >
          <Icon name={"place"} color={TEXT_COLOR_LIGHT} />
          <Text>
            {state?.fiscalAddress?.street && (
              <>
                {state?.fiscalAddress?.street}
                <br />
              </>
            )}
            {getAddressName(state?.fiscalAddress, true)}
            {/* {`${state?.fiscalAddress?.street || ""}`}
            <br />
            {`${state?.fiscalAddress?.postalCode || ""} ${
              state?.fiscalAddress?.city || ""
            } - ${state?.fiscalAddress?.state || ""} ${
              state?.fiscalAddress?.port?.name
            }`} */}
          </Text>
        </div>
        {JSON.parse(process.env.REACT_APP_DASHBOARD_USERS_CRM) && (
          <div className={styles["sc-item"]}>
            <Icon name={"support_agent"} color={TEXT_COLOR_LIGHT} />
            <Dropdown
              position="fixed"
              placeholder={t("select-value")}
              options={userList}
              model={{ uuid: "uuid", text: "name" }}
              value={
                state?.basicData?.assignedBO?.uuid
                  ? state?.basicData?.assignedBO?.name
                  : t("no-agent")
              }
              handleItemClick={handleChangeUser}
              clearMargin
              mode="rounded"
            />
          </div>
        )}
      </Item>
    </CardBox>
  );
};

export default WidgetBusineesContact;
