// components/FileUpload.js
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./FileUpload.module.scss";
import {
  Heading,
  Icon,
  Typography,
  UploadIcon,
  WARM_NEUTRAL_50,
} from "@scrapadev/scrapad-front-sdk";

export const imageFilePattern = /\.(jpe?g|png|gif|bmp|svg)$/i;

const defaultTranslations = {
  select_or_drag_files: "Selecciona o arrastra archivos para subirlos",
  files_selected: "Archivos seleccionados",
};

const FileUpload = ({
  onChange = () => {},
  defaultFiles = [],
  inputProps = {},
  acceptedTypeFile = {},
  translation = defaultTranslations,
  hideFileList = false,
}) => {
  const [files, setFiles] = useState(defaultFiles);

  translation = {
    ...defaultTranslations,
    ...translation,
  };

  const onDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  };

  const removeFile = (file) => {
    setFiles(files.filter((f) => f !== file));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedTypeFile,
  });

  useEffect(() => {
    onChange(files);
  }, [files]);

  useEffect(() => {
    setFiles(defaultFiles);
  }, [defaultFiles]);

  const printIcon = (fileName) => {
    if (imageFilePattern.test(fileName)) {
      return <Icon name="image" color={WARM_NEUTRAL_50} />;
    }

    return <Icon name="insert_drive_file" color={WARM_NEUTRAL_50} />;
  };

  return (
    <div className={styles.uploadContainer}>
      <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} {...inputProps} />
        <UploadIcon />
        <Typography color="light" noMargin style={{ marginTop: "10px" }}>
          {translation.select_or_drag_files}
        </Typography>
      </div>
      {!hideFileList && (
        <div className={styles.fileListContainer}>
          <Heading type={7} style={{ marginBottom: "0px" }}>
            {translation.files_selected} ({files.length})
          </Heading>
          <ul className={styles.fileList}>
            {files?.map((file, index) => (
              <li key={index} className={styles.fileItem}>
                {printIcon(file.name)}
                <Typography noMargin>{file.name}</Typography>
                <button
                  onClick={() => removeFile(file)}
                  className={styles.deleteButton}
                >
                  <Icon name="delete" />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
