import React from "react";
import Input from "../Input";

/**
 * UI Text input component.
 * @param {Object} props - Component properties.
 * @param {Object} ref - Forwarded reference.
 * @returns {JSX.Element}
 */
const InputText = (props, ref) => {
  return <Input ref={ref} type="text" {...props} />;
};

export default React.forwardRef(InputText);
