import React, { useState } from "react";
import KanbanWrap from "./KanbanWrap";
import KanbanColumn from "./KanbanColumn";
import KanbanItem from "./KanbanItem";
import KanbanItemPricing from "./KanbanItemPricing";

export const KanbanTypes = {
  TRACKING: "TRACKING",
  PRICING: "PRICING",
};

function Kanban({
  data = [],
  type = KanbanTypes.TRACKING,
  onUpdate = () => {},
}) {
  const [bottomElement, setBottomElement] = useState(<></>);

  return (
    <>
      <KanbanWrap paper={true}>
        {data?.map((column) => {
          return (
            <KanbanColumn
              key={column?.key}
              label={column?.label}
              count={column?.items.length}
            >
              {column?.items?.map((item) => {
                switch (type) {
                  case KanbanTypes.TRACKING:
                    return (
                      <KanbanItem
                        key={item?.id}
                        id={item?.id}
                        title={item?.title}
                        address={item?.address}
                        isPort={item?.is_port}
                        imageSrc={item?.image_src}
                        tracking={item?.transportData}
                        originOrg={item?.originOrg}
                        destinationOrg={item?.destinationOrg}
                        date={item?.date}
                        status={item?.status}
                        onUpdate={onUpdate}
                        setBottomElement={setBottomElement}
                        group={column?.key}
                      />
                    );
                  case KanbanTypes.PRICING:
                  default:
                    return <KanbanItemPricing group={column?.key} {...item} />;
                }
              })}
            </KanbanColumn>
          );
        })}
      </KanbanWrap>
      {bottomElement}
    </>
  );
}

export default Kanban;
