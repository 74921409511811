import React from "react";
import { Icon, Flex, Row, Typography } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";

function NotificationsPreferencesHelp() {
  const { t } = useTranslation("notifications");

  return (
    <Flex
      flexDirection="column"
      alignItems={"flex-start"}
      justifyContent="flex-start"
      style={{ gap: "25px" }}
    >
      <Typography big style={{ margin: 0, color: "#3B3B3B" }}>
        {t("notifications_channels_desc")}
      </Typography>
      <Flex
        flexDirection="column"
        alignItems={"flex-start"}
        justifyContent="flex-start"
        style={{ gap: "25px" }}
      >
        <HelpItem
          icon={"notifications"}
          label={t("web")}
          description={t("web_desc")}
          color={"#60C386"}
        />
        <HelpItem
          icon={"calendar_view_day"}
          label={t("push")}
          description={t("push_desc")}
          color={"#60C386"}
        />
        <HelpItem
          icon={"mail"}
          label={t("email")}
          description={t("email_desc")}
          color={"#60C386"}
        />
      </Flex>
    </Flex>
  );
}

function HelpItem({ icon, label, color, description }) {
  return (
    <Flex
      flexDirection="column"
      alignItems={"flex-start"}
      justifyContent="flex-start"
      style={{ gap: 12 }}
    >
      <Row gap={10}>
        <Icon name={icon} color={color} style={{ fontSize: 20 }} />
        <Typography
          big
          style={{ margin: 0, fontWeight: 600, color: "#515151", fontSize: 14 }}
        >
          {label}
        </Typography>
      </Row>
      <Typography big reduced>
        {description}
      </Typography>
    </Flex>
  );
}

export default NotificationsPreferencesHelp;
