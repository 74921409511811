import { Paper } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";
import { useImputeCosts } from "../../utils/opportunities/functions";
import { costEvents } from "../../utils/opportunities/variables";
import WidgetOpportunitiesCheck from "./OpportunitiesCheckbox";
import WidgetOpportunitiesFormItem from "./OpportunitiesCostFormItem";

/**
 * Widget to impute costs
 * @param {object} props - Function properties.
 * @param {boolean} props.readOnly - Read only checkboxes.
 * @param {string} props.id - Opportunity id.
 * @param {object} props.state - Costs current state.
 * @param {Function} props.dispatch - Widget provider dispatch.
 * @param {object} props.actions - Widget provider actions.
 * @param {boolean} props.loading - Widget loading state.
 * @param {Function} props.setLoading - Loading setter.
 * @param {Function} props.fetchData - Fetching function.
 * @returns {JSX.Element}
 */
const WidgetOpportunitiesImputed = ({
  readOnly = false,
  id,
  state,
  dispatch,
  actions,
  loading,
  setLoading,
  fetchData,
  opportunityId,
}) => {
  const { t } = useTranslation(["common"]);
  const fields = state.fields;
  const { handleSendCost } = useImputeCosts();

  return (
    <Paper whiteMode>
      <WidgetOpportunitiesFormItem
        style={{ paddingTop: 0 }}
        leftComponent={
          <WidgetOpportunitiesCheck
            slug="logistics"
            text={t("logistics-costs-imputed")}
            readOnly={loading ? true : readOnly}
            checked={state.logistics}
            onChange={(e) => {
              const value = e.target.checked;
              dispatch({
                type: actions.UPDATE_INPUT,
                payload: { logistics: value },
              });
              handleSendCost({
                id: id,
                type: costEvents.LOGISTICS_COSTS_IMPUTED,
                value: value,
                setLoading: setLoading,
                fetchData: fetchData,
                fields: fields,
                uuidOfferOpportunity: opportunityId,
              });
            }}
          />
        }
      />
      <WidgetOpportunitiesFormItem
        style={{ paddingTop: 5, paddingBottom: 0 }}
        isLast
        leftComponent={
          <WidgetOpportunitiesCheck
            slug="finances"
            text={t("finances-costs-imputed")}
            readOnly={readOnly}
            checked={state.finances}
            onChange={(e) => {
              const value = e.target.checked;
              dispatch({
                type: actions.UPDATE_INPUT,
                payload: { finances: value },
              });
              handleSendCost({
                id: id,
                type: costEvents.FINANCES_COSTS_IMPUTED,
                value: value,
                setLoading: setLoading,
                fetchData: fetchData,
                fields: fields,
                uuidOfferOpportunity: opportunityId,
              });
            }}
          />
        }
      />
    </Paper>
  );
};

export default WidgetOpportunitiesImputed;
