import { formEvents } from "../variables";

export const parseVerificationRawEventData = (eventData) => {
  if (eventData.price) eventData.price = parseInt(eventData.price) / 100;
  return eventData;
};

export const handleTransactionsRawSlugs = (type) => {
  switch (type) {
    case "cost":
      return [formEvents.ADD_LOGISTIC, formEvents.UPDATE_LOGISTIC];
    case "load":
      return [formEvents.TRANSPORT_COLLECTION_DATE_UPDATED];
    case "unload":
      return [formEvents.TRANSPORT_DELIVERY_DATE_UPDATED];
    case "boarding":
      return [formEvents.TRANSPORT_TRANSIT_DATE_UPDATED];
    default:
      return [];
  }
};

export const parseTransactionsRawEventData = (eventData) => {
  if (eventData.unitPrice)
    eventData.unitPrice = parseInt(eventData.unitPrice) / 100;
  return eventData;
};

export const parseSamplesRawEventData = (eventData) => {
  if (eventData.deliveryPrice)
    eventData.deliveryPrice = parseInt(eventData.deliveryPrice) / 100;
  return eventData;
};

export const parseReturnRawEventData = (eventData) => {
  if (eventData.cost_total)
    eventData.cost_total = parseInt(eventData.cost_total) / 100;
  if (eventData.cost_bail)
    eventData.cost_bail = parseInt(eventData.cost_bail) / 100;
  if (eventData.value) eventData.value = parseInt(eventData.value) / 100;
  delete eventData.destination;
  return eventData;
};

export const handleReturnRawSlugs = (type) => {
  switch (type) {
    case "tolerance":
      return [formEvents.RETURN_PAYMENT_TOLERANCE];
    case "bail":
      return [formEvents.RETURN_DEPOSIT];
    case "other":
      return [formEvents.RETURN_PAYMENT];
  }
};
