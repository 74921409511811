import { Row } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import Avatar from "../../../components/ui/avatar/Avatar";
import { parseDate } from "../../../utils/date/functions";
import { useTranslation } from "../../../utils/hooks";
import styles from "./OpportunityInfoBottom.module.scss";
import { Link } from "react-router-dom";
import { useUser } from "../../../providers/AuthHooks";

function OpportunityInfoBottom({ state = {} }) {
  const { t, i18n } = useTranslation(["common"]);
  const user = useUser();

  const renderUser = () => {
    return state?.boUser?.uuid === user?.userUuid
      ? t("you")
      : state?.boUser?.name;
  };

  return (
    <div>
      <Row align="center" justify="space-between">
        <Link to={`/business/${state?.uuidOrg}`} target="_blank">
          <p
            className={`${styles["text"]} ${styles["underline"]}`}
            style={{ margin: 0 }}
            data-cy="opportunities-info-orgname"
          >
            {state?.orgName}
          </p>
        </Link>
        <div>
          <Row gap={5}>
            <Avatar width={10} height={10} />
            <p className={`${styles["text"]}`} style={{ margin: 0 }}>
              <span data-cy="opportunities-info-user">{renderUser()}</span>
              {` - `}
              <span data-cy="opportunities-info-date">
                {parseDate(
                  state?.updatedDate || Date.now(),
                  i18n,
                  "dd/MM/yyyy"
                )}{" "}
              </span>
              {t("at-time")}{" "}
              <span data-cy="opportunities-info-time">
                {parseDate(state?.updatedDate || Date.now(), i18n, "HH:mm")}
              </span>
            </p>
          </Row>
        </div>
      </Row>
    </div>
  );
}

export default OpportunityInfoBottom;
