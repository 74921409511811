import { Heading, Modal, Typography } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../utils/hooks";
import React from "react";

function PaymentMethodInfoModal({ open, setOpen, ...props }) {
  const { t } = useTranslation("common");

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      hasForm={false}
      maxWidth={1000}
      modalStyles={{
        width: "1000px",
      }}
      hasActions={false}
      title={t("payment-methods")}
      BodyComponent={
        <>
          <Heading
            type={6}
            weight="medium"
            style={{ fontSize: "0.75rem", marginBottom: "10px" }}
          >
            {t("payment_100")}
          </Heading>
          <Typography>{t("payment_100_desc")}</Typography>

          <Heading
            type={6}
            weight="medium"
            style={{ fontSize: "0.75rem", marginBottom: "10px" }}
          >
            {t("payment_80_bl_cad")}
          </Heading>
          <Typography>{t("payment_80_bl_cad_desc")}</Typography>

          <Heading
            type={6}
            weight="medium"
            style={{ fontSize: "0.75rem", marginBottom: "10px" }}
          >
            {t("payment_80_20")}
          </Heading>
          <Typography>{t("payment_80_20_desc")}</Typography>

          <Heading
            type={6}
            weight="medium"
            style={{ fontSize: "0.75rem", marginBottom: "10px" }}
          >
            {t("payment_100_cad")}
          </Heading>
          <Typography>{t("payment_100_cad_desc")}</Typography>
        </>
      }
      {...props}
    />
  );
}

export default PaymentMethodInfoModal;
