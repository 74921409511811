import {
  ClearButton,
  Flex,
  Icon,
  Separator,
  Spacer,
  TableManager,
  BlueButton,
} from "@scrapadev/scrapad-front-sdk";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import WhiteFilters from "../../components/ui/filters/WhiteFilters";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import SearchFilterExport from "../../components/ui/search-filter-export/SearchFilterExport";
import Text from "../../components/ui/text/Text";
import LayoutTable from "../../layout/table/Table";
import { getCatalogNoTerm } from "../../model/filters";
import { getTable } from "../../model/tables";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { usePaginationProvider } from "../../providers/pagination/PaginationContext";
import { useTableDataProvider } from "../../providers/TableContext";
import { useGQL } from "../../utils/gql/hooks";
import { useTranslation } from "../../utils/hooks";
import { useTableActions, useTableConfig } from "../../utils/tables/hooks";
import {
  GRAY_NEUTRAL_40,
  modalTypes,
  tableModules,
  TABLE_LIMIT,
  TEXT_COLOR_LIGHT,
  transactionFinancesTypes,
  transactionTypeInfo,
} from "../../utils/variables";
import WidgetMarkREceived from "./MarkReceived";
import { useNestedTableActions } from "../../utils/finances/hooks";
import { getTransactionId } from "../../utils/finances/functions";
import { BREAKPOINT_LG, useMatchMedia } from "@scrapadev/scrapad-front-sdk";

/**
 * Widget for deposits.
 * @returns {JSX.Element}
 */
const InnerWidgetFinancesDeposit = ({ baseTableParams }) => {
  const { t, i18n } = useTranslation(["common", "finances"], true);
  const { buildFiltersAndFetch, getStoredFilters } = useFiltersProvider();
  const navigate = useNavigate();
  const { data: methodData, loading: methodLoading } = useGQL(
    getCatalogNoTerm,
    {
      locale: i18n.language,
      name: "payment-methods",
    }
  );
  const { handlePagination } = usePaginationProvider();
  const [openDepositPayments, setOpenDepositPayments] = useState(false);
  const [modalOption, setModalOption] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const selectedRow = useRef(null);
  const cleanRadioStatutsFn = useRef(() => {});
  const [rowsOpen, setRowsOpen] = useState(0);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const { data, setData, loading, setLoading, fetchData, fetchSubFields } =
    useTableDataProvider();
  const { handleAction } = useNestedTableActions(fetchData);
  const toggleAccordioncallbacksFn = useRef();
  const toggleAllAccordionFn = useRef();
  const commonStyles = {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "18px",
    color: TEXT_COLOR_LIGHT,
  };

  const actions = [
    {
      component: (
        <ClearButton
          onClick={() => {
            setOpenDepositPayments(!openDepositPayments);
            setModalOption(transactionFinancesTypes.REQUEST);
          }}
        >
          <span>{t("request-payment")}</span>
        </ClearButton>
      ),
    },
    {
      component: (
        <ClearButton
          onClick={() => {
            setOpenDepositPayments(!openDepositPayments);
            setModalOption(transactionFinancesTypes.MAKE);
          }}
        >
          <span>{t("make-deposit-generic")}</span>
        </ClearButton>
      ),
    },
  ];

  useTableActions({
    paginationCallback: (paginationState) => {
      const newFilters = buildFiltersAndFetch(getStoredFilters() || {});
      const params = {
        ...baseTableParams,
        offset: paginationState?.current,
      };
      if (Object.keys(newFilters).length > 0) {
        params.filter = newFilters;
      }
      fetchData({
        ...params,
      });
    },
  });

  return (
    <>
      <Flex alignItems={"flex-start"} flexDirection={"column"}>
        <Flex
          justifyContent={"flex-start"}
          flexDirection={matches ? "column" : "row"}
          alignItems={matches ? "flex-start" : "center"}
          style={{ width: "100%", marginBottom: 12 }}
        >
          <SearchFilterExport
            filterKey="paymentMethodText"
            searchPlaceholder={t("search")}
            hasSeparator={false}
            hasExport={false}
            hasFilters={false}
            hasGrow
            containerStyles={{
              marginRight: 10,
              marginLeft: 0,
              marginBottom: matches ? 10 : 0,
            }}
          />
          <WhiteFilters
            containerStyles={{
              justifyContent: "space-between",
              width: "100%",
            }}
            innerContainerStyles={{
              gap: 10,
            }}
            deps={[methodData]}
            initialFilters={{
              paymentMethodText: {
                external: true,
              },
              paymentMethod: {
                groupedChips: true,
                label: t("payment-mode"),
                items: methodData,
              },
              pendingAction: {
                label: t("pending-actions"),
                groupedChips: true,
                items: [
                  {
                    uuid: "true",
                    name: t("with-pending-actions"),
                  },
                  {
                    uuid: "false",
                    name: t("without-pending-actions"),
                  },
                ],
              },
            }}
          />
        </Flex>
        <Flex justifyContent={"flex-end"} style={{ width: "100%" }}>
          {selectedRows.length > 0 && (
            <>
              <ClearButton
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  setSelectedRows([]);
                  cleanRadioStatutsFn.current();
                }}
              >
                <Text
                  style={{
                    fontFamily: "Silka",
                    fontWeight: 500,
                    fontSize: 12,
                    lineHeight: "18px",
                    color: GRAY_NEUTRAL_40,
                  }}
                >{`${selectedRows.length} ${t("selected-transactions")}`}</Text>
                <Icon
                  name="close"
                  color={GRAY_NEUTRAL_40}
                  style={{ fontSize: 14, marginLeft: 5 }}
                />
              </ClearButton>
              <Separator height={14} style={{ backgroundColor: "#CFCFCF" }} />
              <BlueButton
                onClick={() => {
                  toggleAllAccordionFn.current();
                  setRowsOpen(rowsOpen > 0 ? 0 : selectedRows.length);
                }}
              >
                {rowsOpen ? t("fold-movements") : t("unfold-movements")}
              </BlueButton>
              {/*<Separator height={14} style={{ backgroundColor: "#CFCFCF" }} />*/}
              {/* <WidgetMarkREceived selectedRows={selectedRows} />*/}
            </>
          )}
        </Flex>
      </Flex>
      <Spacer height={12} />
      <TableManager
        isInfoTable
        fetchSubFields={fetchSubFields}
        loading={loading}
        data={data}
        setData={setData}
        emptyText={t("empty-data")}
        multipleSelection={{
          headerCallback: (e, rowsSelected, headerFields) => {
            setSelectedRows([...rowsSelected]);
          },
          rowCallback: (
            e,
            row,
            rowsSelected,
            bodyfields,
            toggleAccordioncallbacks
          ) => {
            setSelectedRows([...rowsSelected]);
            toggleAccordioncallbacksFn.current = toggleAccordioncallbacks;
            let openedRows = 0;
            if (
              toggleAccordioncallbacks &&
              toggleAccordioncallbacks?.length > 0
            ) {
              toggleAccordioncallbacks.forEach((callback) => {
                if (callback?.isOpen()) {
                  openedRows++;
                }
              });
            }
            setRowsOpen(openedRows);
          },
        }}
        pagination={
          data?.pagination
            ? {
                currentPage: Math.ceil(data?.pagination?.current / TABLE_LIMIT),
                totalPages: Math.ceil(data?.pagination?.total / TABLE_LIMIT),
                threshold: 3,
                onChangePage: handlePagination,
                onChangePrev: handlePagination,
                onChangeNext: handlePagination,
              }
            : undefined
        }
        itemsProps={{
          text: {
            textStyles: commonStyles,
          },
          light: {
            textStyles: {
              ...commonStyles,
              color: GRAY_NEUTRAL_40,
            },
          },
          text_two_lines: {
            firstTextProps: {
              style: commonStyles,
            },
            secondTextProps: {
              style: commonStyles,
            },
          },
          pending: {
            translate: (code) => {
              return code ? t("actions-pending") : t("no-actions-pending");
            },
            actionProps: {
              onClick: async (e, uuid, row, loading, setLoading, slug) => {
                const uuidEvent = getTransactionId(row);
                const uuidDeposit = uuid;
                if (uuidDeposit && uuidEvent) {
                  setLoading(true);
                  await handleAction(uuidEvent, uuidDeposit, slug);
                  setLoading(false);
                }
              },
            },
          },
          image_with_text: {
            firstTextProps: {
              onClick: (e, uuid) => {
                navigate(`/transactions/${uuid}`);
              },
              style: {
                fontSize: 14,
                lineHeight: "21px",
              },
            },
            secondTextProps: {
              style: commonStyles,
            },
          },
          dots_two_lines: {
            mapDotValues: transactionTypeInfo,
            textStyles: commonStyles,
          },
        }}
        onRowClick
        onClickActionCallback={(uuid, index, row) => {
          selectedRow.current = row;
        }}
        actions={actions}
        actionStyles={{
          fontFamily: "Silka",
        }}
        onMountConfigure={({ cleanRadioStatuts }) => {
          cleanRadioStatutsFn.current = cleanRadioStatuts;
        }}
        onMountSubFieldsConfigure={({ handleAccordionRows }) => {
          toggleAllAccordionFn.current = handleAccordionRows;
        }}
        subFieldsGroupConfigure={{
          style: {
            fontSize: 12,
            fontWeight: 600,
          },
          spans: {
            sub_payment: ["transaction", "organization"],
            sub_amount: ["amount"],
          },
          groupKey: "paying_actor",
          renderGroup: (row, subrow) => {
            const actor = subrow?.cells?.find(
              (f) => f?.colRef === "paying_actor"
            );
            const org = row?.cells?.find((f) => f?.colRef === "organization");
            const parsedOrg = org?.value?.split("#");
            const orgName =
              actor?.value === "buyer" ? parsedOrg[1] : parsedOrg[3];
            return `${t(actor?.value)} - ${orgName}`;
          },
        }}
      />
      <ModalFactory
        key={selectedRow?.uuid}
        type={modalTypes.MANUAL_PAYMENTS_DEPOSIT}
        open={openDepositPayments}
        setOpen={setOpenDepositPayments}
        option={modalOption}
        selectedRow={selectedRow.current}
        fetchData={fetchData}
      />
      <div id="DepositsModal"></div>
    </>
  );
};

const WidgetFinancesDeposit = () => {
  const { i18n } = useTranslation();
  const baseParams = {
    locale: i18n.language,
    limit: TABLE_LIMIT,
    tableModule: tableModules.PAYMENT_TRACKING,
  };
  const { tableParams } = useTableConfig(baseParams);

  return (
    <LayoutTable fnData={getTable} fnParameters={tableParams}>
      <InnerWidgetFinancesDeposit baseTableParams={baseParams} />
    </LayoutTable>
  );
};

export default WidgetFinancesDeposit;
