import React, {
  useContext,
  useReducer,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { useBackNavigationProvider } from "../navigation/BackNavigationContext";
import { actions } from "./actions";

const PaginationContext = React.createContext({
  state: null,
  dispatch: null,
});

const paginationLimit = 12;

const getInitialState = () => {
  return {
    current: 0,
    limit: paginationLimit,
    threshold: 3,
    uuidOrg: null,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_PAGINATION:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const paginationKey = "pagination";

export const PaginationProvider = ({
  storageKey,
  keysMerged = false,
  children,
}) => {
  const isFromBackButton = useBackNavigationProvider();
  const stored = keysMerged
    ? JSON.parse(localStorage.getItem(paginationKey))?.[storageKey]
    : JSON.parse(localStorage.getItem(storageKey));
  const [state, dispatch] = useReducer(
    reducer,
    stored ? stored : getInitialState()
  );
  const paginationChanged = useRef(false);

  const getPagination = (key) => {
    if (keysMerged) {
      return JSON.parse(localStorage.getItem(paginationKey))?.[
        key || storageKey
      ];
    }
    return JSON.parse(localStorage.getItem(key || storageKey));
  };

  useEffect(() => {
    let savedFilters = {};
    const keys = Object.keys(state || {});
    keys.forEach((key) => {
      savedFilters[key] = state[key];
    });
    if (Object.keys(savedFilters).length > 0 && storageKey) {
      if (keysMerged) {
        if (!localStorage.getItem(paginationKey)) {
          localStorage.setItem(paginationKey, JSON.stringify({}));
        }
        const pagination = JSON.parse(localStorage.getItem(paginationKey));
        pagination[storageKey] = savedFilters;
        localStorage.setItem(paginationKey, JSON.stringify(pagination));
      } else {
        localStorage.setItem(storageKey, JSON.stringify(savedFilters));
      }
    }
  }, [state]);

  /* 
    Se ha añaduido en las funcines de  getStoredPagination y cleanPagination un parametro de key.
    Por alguna razon de momento desconocida en las paginas de transacciones se esta leakenado la paginacion de 
    la pagina anterior visitada. Hasta que se de con el motivo en esas paginas se pone la key a mano
  */
  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
      actions,
      storageKey,
      getStoredPagination: (key) => {
        return getPagination(key);
      },
      getStoredPaginationIfBackPressed: (key) => {
        if (isFromBackButton.current) {
          return getPagination(key);
        } else {
          return null;
        }
      },
      cleanPagination: (key) => {
        if (keysMerged) {
          const keyToDelete = key || storageKey;
          const pagination = JSON.parse(localStorage.getItem(paginationKey));
          delete pagination[keyToDelete];
        } else {
          localStorage.removeItem(key || storageKey);
        }
      },
      paginationChanged,
      handlePagination: (page) => {
        dispatch({
          type: actions.SET_PAGINATION,
          payload: { current: page },
        });
        paginationChanged.current = true;
      },
    }),
    [state, dispatch, storageKey]
  );

  return (
    <PaginationContext.Provider value={contextValue}>
      {children}
    </PaginationContext.Provider>
  );
};

export const usePaginationProvider = () => useContext(PaginationContext);
