import { useEffect, useState } from "react";

/**
 * Custom hook to preserve selected tab across navigation.
 * @param {string} storageKey - Key to store current tab in the session storage.
 * @returns {{ currentTab: number, setCurrentTab: (n: number) => void, setCurrentTabWithStorage: (n: number) => void, }}
 */
export const useTabNavigation = (storageKey) => {
  const storedTab =
    typeof window === undefined ? 0 : window.sessionStorage.getItem(storageKey);
  const [currentTab, setCurrentTab] = useState(
    storedTab ? parseInt(storedTab) : 0
  );

  useEffect(() => {
    // For nextjs
    if (typeof window === undefined) {
      setCurrentTab(parseInt(window.sessionStorage.getItem(storageKey)));
    }
  }, []);

  const setCurrentTabWithStorage = (index) => {
    setCurrentTab(index);
    window.sessionStorage.setItem(storageKey, index);
  };

  return {
    currentTab,
    setCurrentTab,
    setCurrentTabWithStorage,
  };
};
