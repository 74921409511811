import { Button, TooltipSc } from "@scrapadev/scrapad-front-sdk";
import _ from "lodash";
import { useState } from "react";
import {
  canNotPublishBasic,
  canNotPublishKyc,
} from "../../utils/ads/functions";
import { useTranslation } from "../../utils/hooks";
import { adStatus, LoadCharacteristicasTypes } from "../../utils/variables";

export const checkFormRequiredFields = (ad) => {
  let isValidated = true;
  if (!ad?.type) isValidated = false;
  if (!ad?.originalTitle) isValidated = false;
  if (!ad?.originalText) isValidated = false;
  if (!ad?.materialTypeEdit) isValidated = false;
  if (!ad?.amount) isValidated = false;
  if (!ad?.price) isValidated = false;
  if (!ad?.files || ad?.files?.length === 0) isValidated = false;
  if (ad?.type === "sale") {
    if (ad?.components?.length === 0) isValidated = false;
    if (
      ad?.loadCharacteristics?.length === 0 ||
      ad?.loadCharacteristics?.length === 3
    ) {
      isValidated = false;
    }
  } else {
    if (!ad?.incoterm || _.isEmpty(ad?.incoterm)) {
      isValidated = false;
    }

    if (
      (ad?.incoterm || !_.isEmpty(ad?.incoterm)) &&
      ad?.address?.port === "empty"
    ) {
      isValidated = false;
    }

    if (!ad?.paymentMethod || _.isEmpty(ad?.paymentMethod)) {
      isValidated = false;
    }
  }

  if (ad?.loadCharacteristics?.length > 0) {
    const granel = ad?.loadCharacteristics?.find(
      (f) => f?.type === LoadCharacteristicasTypes.GRANEL
    );
    const saca = ad?.loadCharacteristics?.find(
      (f) => f?.type === LoadCharacteristicasTypes.SACAS
    );
    const paletizada = ad?.loadCharacteristics?.find(
      (f) => f?.type === LoadCharacteristicasTypes.PALET
    );
    if (granel) {
      if (parseInt(granel?.value?.weight) < 0) {
        isValidated = false;
      }
    }
    if (saca) {
      if (!saca.value) {
        isValidated = false;
      }
      if (!saca.value?.width || saca.value?.width < 1) {
        isValidated = false;
      }
      if (!saca.value?.height || saca.value?.height < 1) {
        isValidated = false;
      }
      if (!saca.value?.weight || saca.value?.weight < 1) {
        isValidated = false;
      }
      if (!saca.value?.depth || saca.value?.depth < 1) {
        isValidated = false;
      }
    }
    if (paletizada) {
      if (!paletizada.value) {
        isValidated = false;
      }
      if (!paletizada.value?.width || paletizada.value?.width < 1) {
        isValidated = false;
      }
      if (!paletizada.value?.height || paletizada.value?.height < 1) {
        isValidated = false;
      }
      if (!paletizada.value?.weight || paletizada.value?.weight < 1) {
        isValidated = false;
      }
      if (!paletizada.value?.depth || paletizada.value?.depth < 1) {
        isValidated = false;
      }
    }
  }
  if (ad?.components?.length > 0) {
    const incompletes = ad?.components?.filter(
      (f) => !f?.type || !f?.operator || !f?.value
    );
    if (incompletes?.length > 0) isValidated = false;
  }
  if (!ad?.address?.id) {
    if (
      !ad?.address?.street ||
      !ad?.address?.city ||
      !ad?.address?.countryCode ||
      !ad?.address?.state ||
      !ad?.address?.postalCode
    ) {
      isValidated = false;
    }
  }
  return isValidated;
};

const DisabledButton = ({ text }) => {
  return (
    <Button
      component="button"
      type="button"
      text={text}
      disabled
      style={{
        marginLeft: 10,
      }}
    />
  );
};

const ButtonWithRequiredTooltip = ({ t, children }) => {
  return (
    <TooltipSc
      alignment={"bottom"}
      content={t("fill-required")}
      action="hover"
      hide={false}
      style={{
        justifyContent: "center",
        minWidth: 0,
        marginLeft: 10,
      }}
    >
      {children}
    </TooltipSc>
  );
};

const AdStatusAction = ({
  uuid,
  ad,
  verificationState,
  status,
  isEdit,
  isDirty,
  handlePublishChanges,
  handleDemandCovered,
  handleSaveDraft,
  fetchData,
  setOpen,
  bufferState,
}) => {
  const { t } = useTranslation(["ads", "common"]);
  const [loading, setLoading] = useState(false);
  const handlePublishstatus = () => {
    if (canNotPublishBasic(verificationState)) {
      return (
        <TooltipSc
          alignment={"bottom"}
          content={t("need-basic-verification")}
          action="hover"
          hide={false}
          style={{
            justifyContent: "center",
            minWidth: 0,
          }}
        >
          <DisabledButton text={t("publish-changes")} />
        </TooltipSc>
      );
    }
    if (canNotPublishKyc(ad, verificationState)) {
      return (
        <TooltipSc
          alignment={"bottom"}
          content={t("need-full-verification")}
          action="hover"
          hide={false}
          style={{
            justifyContent: "center",
            minWidth: 0,
          }}
        >
          <DisabledButton text={t("publish-changes")} />
        </TooltipSc>
      );
    }
    if (checkFormRequiredFields(ad)) {
      return (
        <>
          <Button
            component="button"
            type="button"
            //form="adForm"
            text={t("publish-changes")}
            style={{
              marginLeft: 10,
            }}
            onClick={() => {
              parseInt(ad?.amount) === 0
                ? handleDemandCovered()
                : handlePublishChanges({ ad, uuid, setLoading, fetchData });
            }}
          />
        </>
      );
    } else {
      return (
        <ButtonWithRequiredTooltip t={t}>
          <DisabledButton text={t("publish-changes")} />
        </ButtonWithRequiredTooltip>
      );
    }
  };

  if (!ad) return <></>;

  if (status === adStatus.DRAFT) {
    return (
      <Button
        component="button"
        type="button"
        text={t("save-draft")}
        loading={loading}
        style={{
          marginLeft: 10,
        }}
        onClick={() => {
          handleSaveDraft({ ad, uuid, setLoading, fetchData });
          bufferState.current = { ...ad };
        }}
      />
    );
  }

  if (status === adStatus.COVERED && isEdit) {
    return (
      <Button
        component="button"
        type="button"
        text={t("save-draft")}
        loading={loading}
        style={{
          marginLeft: 10,
        }}
        disabled={parseInt(ad?.amount) === 0}
        onClick={() => {
          handleSaveDraft({ ad, uuid, setLoading, fetchData });
          bufferState.current = { ...ad };
        }}
      />
    );
  }

  if (status === adStatus.REVIEW_PENDING && isEdit) {
    if (checkFormRequiredFields(ad)) {
      return (
        <Button
          component="button"
          type="button"
          text={t("save-changes")}
          style={{
            marginLeft: 10,
          }}
          loading={loading}
          onClick={() => {
            handlePublishChanges({ ad, uuid, setLoading, fetchData });
            bufferState.current = { ...ad };
          }}
        />
      );
    } else {
      return (
        <ButtonWithRequiredTooltip t={t}>
          <DisabledButton text={t("save-changes")} />
        </ButtonWithRequiredTooltip>
      );
    }
  }

  if (status === adStatus.PUBLISHED) {
    return isEdit ? handlePublishstatus() : <></>;
  }
  return <></>;
};

export default AdStatusAction;
