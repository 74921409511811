import { gql } from "@apollo/client";

export const gqlWebsockets = gql`
  subscription SubscribeRoom($room: String!) {
    subscribeRoom(room: $room) {
      uuid
      slug
      type
    }
  }
`;
