import { Button, Container, MiniTabs } from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FiltersWrapper from "../../components/ui/filters/Wrapper";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import LayoutDetail from "../../layout/detail/Detail";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { PaginationProvider } from "../../providers/pagination/PaginationContext";
import { SortProvider } from "../../providers/sort/SortContext";
import { useDocumentTitle } from "../../utils/document/hooks";
import { checkPermission } from "../../utils/functions";
import { useTranslation } from "../../utils/hooks";
import { useOpportunitiesTabTitle } from "../../utils/opportunities/hooks";
import WidgetOpportunitiesByMe from "../../widgets/opportunities/OpportunitiesByMe";
import WidgetOpportunitiesNew from "../../widgets/opportunities/OpportunitiesNew";
import WidgetOpportunitiesParticipating from "../../widgets/opportunities/OpportunitiesParticipating";
import WidgetOpportunitiesTabTitle from "../../widgets/opportunities/TabTitle";
import _ from "lodash";

const keyOpportunitiesMyMe = "opportunitiesByMe";
const keyOpportunitiesParticipating = "opportunitiesParticipating";
const keyOpportunitiesAvailables = "opportunitiesAvailables";

/**
 * Opportunities page with the 3 tables of opportunities
 * @returns {JSX.Element}
 */
const Opportunities = () => {
  const { t } = useTranslation(["common"], true);
  const { loading } = useOpportunitiesTabTitle();
  const [currentTab, setCurrentTab] = useState(0);
  useDocumentTitle(`${t("opportunities")}`);
  const navigate = useNavigate();
  const { canManageOpportunities } = useOperationsProvider();
  const permission = checkPermission(canManageOpportunities);

  useEffect(() => {
    const cTab = window.sessionStorage.getItem("opportunitiesCurrentTab");
    if (!_.isEmpty(cTab)) {
      setCurrentTab(parseInt(cTab));
    }
  }, []);

  const tabs = [
    {
      tab: (
        <WidgetOpportunitiesTabTitle
          slug={"created-by-me"}
          title={t("created-by-me")}
          loading={loading}
          //notifications={notifications?.[0] || 0}
        />
      ),
      panel: (
        <PaginationProvider storageKey={keyOpportunitiesMyMe} keysMerged>
          <FiltersWrapper storageKey={keyOpportunitiesMyMe} keysMerged>
            <SortProvider storageKey={keyOpportunitiesMyMe} keysMerged>
              <WidgetOpportunitiesByMe />
            </SortProvider>
          </FiltersWrapper>
        </PaginationProvider>
      ),
    },
    {
      tab: (
        <WidgetOpportunitiesTabTitle
          slug={"participating"}
          title={t("participating")}
          loading={loading}
          //notifications={notifications?.[1] || 0}
        />
      ),
      panel: (
        <PaginationProvider
          storageKey={keyOpportunitiesParticipating}
          keysMerged
        >
          <FiltersWrapper storageKey={keyOpportunitiesParticipating} keysMerged>
            <SortProvider storageKey={keyOpportunitiesParticipating} keysMerged>
              <WidgetOpportunitiesParticipating />
            </SortProvider>
          </FiltersWrapper>
        </PaginationProvider>
      ),
    },
    {
      tab: (
        <WidgetOpportunitiesTabTitle
          slug={"availables"}
          title={t("availables")}
          loading={loading}
          //notifications={notifications?.[2] || 0}
        />
      ),
      panel: (
        <PaginationProvider storageKey={keyOpportunitiesAvailables} keysMerged>
          <FiltersWrapper storageKey={keyOpportunitiesAvailables} keysMerged>
            <SortProvider storageKey={keyOpportunitiesAvailables} keysMerged>
              <WidgetOpportunitiesNew />
            </SortProvider>
          </FiltersWrapper>
        </PaginationProvider>
      ),
    },
  ];

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerWithContainer
        headerStyle={{ marginBottom: 0 }}
        headerComponent={
          <Header
            title={t("opportunities")}
            titleRightComponent={
              <Button
                mode={"info"}
                component={"button"}
                onClick={
                  !permission
                    ? undefined
                    : () => {
                        navigate(`/opportunities/new`);
                      }
                }
                text={t("new-opportunity")}
                disabled={!permission}
              />
            }
            upperStyles={{ margin: 0 }}
            middleStyles={{ margin: "30px 0" }}
          />
        }
        bodyComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <MiniTabs
                  tabsProps={{
                    style: { position: "static" },
                  }}
                  tabPanelProps={{ style: { position: "static" } }}
                  tabs={tabs}
                  currentTab={currentTab}
                  changeTab={(index) => {
                    setCurrentTab(index);
                    if (window) {
                      window.sessionStorage.setItem(
                        "opportunitiesCurrentTab",
                        index
                      );
                    }
                  }}
                />
              </div>
            </div>
          </Container>
        }
      />
    </>
  );
};

export default Opportunities;
