import {
  BREAKPOINT_LG,
  Center,
  Container,
  Flex,
  Loading,
  Modal,
  TableManager,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAdForCurrentTransactions } from "../../../model/ads";
import { useTableDataProvider } from "../../../providers/TableContext";
import { useGQL } from "../../../utils/gql/hooks";
import { useTranslation } from "../../../utils/hooks";
import { TEXT_COLOR, transactionTypeInfo } from "../../../utils/variables";
import AdItem from "../../../widgets/ads/Item";
import MiniImage from "../../ui/image/MiniImage";
import Separator from "../../ui/separator/Separator";

const InnerModal = ({ open, setOpen, ...props }) => {
  const { adUuid, ad } = props;
  const { t, i18n } = useTranslation(["common", "transactions"], true);
  const { data, setData, dataBuffer, loading } = useTableDataProvider();
  const {
    data: adData,
    loading: adLoading,
    fetchData: adFetchData,
  } = useGQL(getAdForCurrentTransactions, {}, true);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const navigate = useNavigate();

  useEffect(() => {
    if (!ad) {
      adFetchData({ locale: i18n.language, adUuid });
    }
  }, []);

  const newAdData = ad
    ? {
        images: ad?.files,
        title: ad?.title,
        materialComposition: ad?.materialComposition,
        materialType: ad?.materialType,
      }
    : adData;

  return (
    <Modal
      title="Transacciones en curso"
      open={open}
      setOpen={setOpen}
      ActionsComponent={<></>}
      modalStyles={{ maxHeight: "80%" }}
      BodyComponent={
        <Container>
          {adLoading ? (
            <Center>
              <Loading />
            </Center>
          ) : (
            <Flex
              flexDirection={matches ? "column" : "row"}
              justifyContent={"flex-start"}
              alignItems={matches ? "flex-start" : "center"}
            >
              <AdItem
                leftComponent={<MiniImage src={newAdData?.images?.[0]} />}
                title={newAdData?.title}
                subTitle={`${newAdData?.materialComposition} ${newAdData?.materialType}`}
                containerStyles={{
                  width: "auto",
                  marginBottom: matches ? 15 : 0,
                }}
              />
              <Flex style={{ gap: 15 }}>
                <Flex>
                  <Separator
                    color={"#D0D3DB"}
                    height={50}
                    space={30}
                    style={{ backgroundColor: "#D0D3DB" }}
                  />
                  <AdItem
                    title={"Cantidad disponible"}
                    subTitle={adData?.amount ? `${adData?.amount} t` : ""}
                    titleStyles={{ color: "#626262" }}
                    subTitleStyles={{ color: TEXT_COLOR }}
                  />
                </Flex>
                <Flex>
                  <Separator
                    color={"#D0D3DB"}
                    height={50}
                    space={30}
                    style={{ backgroundColor: "#D0D3DB" }}
                  />
                  <AdItem
                    title={t("initial-price")}
                    subTitle={adData?.price ? `${adData?.price} €/t` : ""}
                    titleStyles={{ color: "#626262" }}
                    subTitleStyles={{ color: TEXT_COLOR }}
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
          <Flex
            style={{ marginTop: 20, marginBottom: loading ? 0 : "-1.875rem" }}
          >
            <TableManager
              loading={loading}
              data={data}
              setData={setData}
              emptyText={t("empty-transactions")}
              navigate={(uuid) => {
                navigate(`/transactions/${uuid}`);
              }}
              itemsProps={{
                chip: {
                  mapTransactionValues: transactionTypeInfo,
                  translate: (code) => {
                    if (!code) return "";
                    if (code.includes("#")) {
                      const parsed = code.split("#");
                      const literals = parsed[1].split(",");
                      return literals.map((l) => t(l)).join(", ");
                    }
                    return t(code);
                  },
                },
                split: {
                  mapTransactionValues: transactionTypeInfo,
                },
                date: {
                  languageData: i18n.getLanguageVariable(i18n.language),
                },
              }}
            />
          </Flex>
        </Container>
      }
    />
  );
};

const ModalAdsTransactions = ({ open, setOpen, ...props }) => {
  return <InnerModal open={open} setOpen={setOpen} {...props} />;
};

export default ModalAdsTransactions;

