import Label from "../../../components/ui/input/label/Label";
import InputText from "../../../components/ui/input/text/Text";
import { useTranslation } from "../../../utils/hooks";
import Container from "../../../components/ui/container/Container";
import InputCountries from "../../../components/ui/input/country/Country";
import {
  countryCodesProps,
  handleItemClick,
  handleOnChange,
  handlePrfixChange,
  renderComponentWithPermission,
  renderDisabledComponentWithTooltip,
} from "../../../utils/functions";
import InputNif from "../../../components/ui/input/nif/Nif";
import { useState } from "react";
import WidgetFieldSaveDiscard from "../../fields/Save";
import { handleCRUD } from "../../../utils/crud/functions";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import { useHeaderProvider } from "../../../providers/header/HeaderContext";
import { orgStatus } from "../../../utils/variables";
import InputActivity from "../../../components/ui/input/activity/Activity";
import { InputPhoneWithPrefix } from "@scrapadev/scrapad-front-sdk";

const WidgetBusinessInfo = ({
  id,
  state,
  dispatch,
  actions,
  isDirty,
  handleDiscard,
  handleParentCallback,
  fetchData,
  extraData,
}) => {
  const { t, i18n } = useTranslation(["business", "common"]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const { state: headerState } = useHeaderProvider();
  const { permissionManage = null, permissionRead = null } = extraData || {};

  const handleSubmitWidget = async (e) => {
    e.preventDefault();
    handleParentCallback();
    //const data = { ...state, lang: i18n.language };
    setSubmitLoading(true);
    const data = { ...state, lang: i18n.language };
    delete data.__typename;
    await handleCRUD({
      endpoint: `org/${id}/basic-data`,
      method: "PUT",
      data: data,
      successCallback: () => fetchData(),
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading: setSubmitLoading,
    });
  };

  const handleNifAndCountry = (key, fiscalKey) => (option, open, setOpen) => {
    const countryData = option.uuid.split("-");
    const countryId = countryData[0];
    const countryName = countryData[1];
    const newNif = state?.fiscalId?.replace(/^.{2}/g, countryName);
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { ...state, [key]: parseInt(countryId), [fiscalKey]: newNif },
    });
    setOpen(false);
  };

  const verified = headerState?.verificationState === orgStatus.KYC_COMPLETE;

  return (
    <form onSubmit={handleSubmitWidget}>
      <Container>
        <div className="row">
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-6">
              <Label>{t("official-name")}</Label>
              {renderDisabledComponentWithTooltip(
                <InputText
                  readOnly={
                    verified
                      ? verified
                      : permissionManage
                      ? !permissionManage()
                      : false
                  }
                  value={state?.orgName}
                  onChange={handleOnChange("orgName", dispatch)}
                  placeholder={t("write-here")}
                />,
                verified,
                t
              )}
            </div>
          )}
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-6">
              <Label>{t("comercial-name")}</Label>
              <InputText
                value={state?.commercialName}
                onChange={handleOnChange("commercialName", dispatch)}
                placeholder={t("write-here")}
                readOnly={permissionManage ? !permissionManage() : false}
              />
            </div>
          )}
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-6">
              <Label>{t("legal-form")}</Label>
              <InputText
                readOnly
                value={t(state?.legalForm)}
                onChange={handleOnChange("legalForm", dispatch)}
                placeholder={t("write-here")}
              />
            </div>
          )}
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-6">
              <Label>{t("nif-number")}</Label>
              {renderDisabledComponentWithTooltip(
                <InputNif
                  readOnly={
                    verified
                      ? verified
                      : permissionManage
                      ? !permissionManage()
                      : false
                  }
                  value={state?.fiscalId}
                  onChange={handleOnChange("fiscalId", dispatch)}
                  placeholder={t("write-here")}
                />,
                verified,
                t
              )}
            </div>
          )}
        </div>
        <div className="row">
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-6">
              <Label>{t("country")}</Label>
              {renderDisabledComponentWithTooltip(
                <InputCountries
                  readOnly={
                    verified
                      ? verified
                      : permissionManage
                      ? !permissionManage()
                      : false
                  }
                  handleItemClick={handleNifAndCountry(
                    "countryCode",
                    "fiscalId"
                  )}
                  value={state?.countryCode}
                />,
                verified,
                t
              )}
            </div>
          )}
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-6">
              <Label>{t("phone")}</Label>
              <InputPhoneWithPrefix
                prefixValue={state?.prefix}
                prefixOnchange={handlePrfixChange("prefix", dispatch)}
                phoneValue={state?.phone}
                phoneOnChange={handleOnChange("phone", dispatch)}
                readOnlyPhone={permissionManage ? !permissionManage() : false}
                readOnlyPrefix={permissionManage ? !permissionManage() : false}
                codesProps={countryCodesProps(
                  state?.prefix,
                  t,
                  i18n,
                  permissionManage ? !permissionManage() : false
                )}
                labelInput={state?.prefix?.length > 4 ? "" : t("phone")}
                prefixLabel="+XX"
              />
            </div>
          )}
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-6">
              <Label>
                {t("business-activity")}{" "}
                {state?.legalForm === "legal-entity" ? "*" : ""}
              </Label>
              {renderDisabledComponentWithTooltip(
                <InputActivity
                  readOnly={
                    verified
                      ? verified
                      : permissionManage
                      ? !permissionManage()
                      : false
                  }
                  handleItemClick={handleItemClick("activity", dispatch)}
                  value={state?.activity}
                  skipRequiredHelp
                />,
                verified,
                t
              )}
            </div>
          )}
          {renderComponentWithPermission(
            permissionRead,
            <div className="col-12 col-lg-6">
              <Label>{t("website")}</Label>
              {renderDisabledComponentWithTooltip(
                <InputText
                  readOnly={
                    verified
                      ? verified
                      : permissionManage
                      ? !permissionManage()
                      : false
                  }
                  value={state?.webSite}
                  onChange={handleOnChange("webSite", dispatch)}
                  placeholder={t("write-here")}
                />,
                verified,
                t
              )}
            </div>
          )}
        </div>
        <WidgetFieldSaveDiscard
          permission={permissionManage}
          isDirty={isDirty}
          handleDiscard={handleDiscard}
          loading={submitLoading}
        />
      </Container>
    </form>
  );
};

export default WidgetBusinessInfo;
