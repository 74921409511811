import { Button, Heading, IconButton, Row } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import { useTranslation } from "../../../utils/hooks";
import styles from "./SectionHeaderPaper.module.scss";

function SectionHeaderPaper({ onBack, text, actionText, actionOnClick }) {
  const { t } = useTranslation(["common"]);
  return (
    <div className={styles["section-header"]}>
      <Row align="center" gap={10}>
        <div className={styles["main"]}>
          {onBack && (
            <IconButton
              type="button"
              name={"arrow_back"}
              onClick={() => {
                onBack(false);
              }}
            />
          )}

          <Heading type={5} style={{ margin: 0 }}>
            {text}
          </Heading>
        </div>
        {actionOnClick && (
          <div className={styles["actions"]}>
            <Button
              text={actionText || t("send")}
              ultrawide
              mode="info"
              component="button"
            />
          </div>
        )}
      </Row>
    </div>
  );
}

export default SectionHeaderPaper;

