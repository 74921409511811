import { InputPassword, Button } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";
import Email from "../ui/input/email/Email";
import Label from "../ui/label/Label";
import LinkStyled from "../ui/link/LinkStyled";

export const LoginForm = (props) => {
  const { t } = useTranslation(["login", "common"]);

  return (
    <>
      <form method="POST" onSubmit={props.doLogin}>
        <Label text={t("email-business")}>
          <Email
            data-cy="email"
            required={true}
            name="email"
            style={{ marginBottom: 0, padding: "0.7rem 0.9rem" }}
            id="email"
          />
        </Label>

        <Label text={t("password")}>
          <InputPassword
            data-cy="password"
            name="password"
            style={{ marginBottom: 0, padding: "0.7rem 0.9rem" }}
            required={true}
            id="password"
          />
        </Label>
        <Label>
          <LinkStyled href={"/recover"}>
            <strong>{t("forgot-pwd")}</strong>
          </LinkStyled>
        </Label>

        <Label>
          <Button
            data-cy="submit"
            component="button"
            fullwidth={true}
            type="submit"
            text={t("access-account")}
            loading={props.isLoading}
          />
        </Label>
      </form>
    </>
  );
};
