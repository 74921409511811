import { Button } from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getCategories } from "../../../model/ads";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import { actions } from "../../../providers/data/actions/data";
import { useDataProvider } from "../../../providers/data/DataContext";
import { gqlWrapper } from "../../../utils/gqlwrapper";
import { useTranslation } from "../../../utils/hooks";
import { adsDefaults } from "../../../utils/variables";
import Loading from "../loading/Loading";
import ModalText from "../modal/text/ModalText";
import styles from "./Categories.module.scss";

const AdsCategories = () => {
  const { t, i18n } = useTranslation(["common"]);
  const { state, dispatch } = useDataProvider();
  const [loading, setLoading] = useState(true);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();

  useEffect(() => {
    setLoading(true);
    fetchData();
  }, [i18n.language]);

  const fetchData = async () => {
    const fn = await gqlWrapper(getCategories, setDataUser, signOut);
    const results = await fn(i18n.language);
    if (results !== null) {
      if (results?.errors?.length > 0) {
        toast.error("Error");
      } else {
        dispatch({
          type: actions.UPDATE_INPUT,
          payload: {
            materials: {
              ...state.materials,
              category: {
                ...state.materials.category,
                options: results,
                open: true,
              },
            },
          },
        });
        setLoading(false);
      }
    }
  };

  const handleSelectCategory = (name, uuid) => (e) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        materials: {
          ...state.materials,
          category: {
            ...state.materials.category,
            uuidSelected: [uuid],
            nameSelected: [name],
          },
          material: {
            ...adsDefaults,
            open: true,
          },
          type: adsDefaults,
        },
      },
    });
  };

  return loading ? (
    <Loading />
  ) : state?.materials.category?.options ? (
    <div className={styles["sc-ads--categories"]}>
      {state?.materials.category?.options.map((cat) => {
        const selectedStyles =
          state?.materials?.category?.uuidSelected[0] === cat.uuid
            ? { backgroundColor: "#7F85A6", color: "#FFF" }
            : {};
        return (
          <Button
            key={cat.uuid}
            type="button"
            component="button"
            fullwidth
            mode={"normal"}
            text={cat.name}
            style={{
              paddingTop: 8,
              paddingBottom: 8,
              fontSize: 14,
              ...selectedStyles,
            }}
            onClick={handleSelectCategory(cat.name, cat.uuid)}
          />
        );
      })}
    </div>
  ) : (
    <ModalText text={t("empty")} />
  );
};

export default AdsCategories;
