import styles from "./Day.module.scss";

/**
 * Day component for the logs.
 * @param {Object} props - Component properties.
 * @param {String|Date} props.date - Date to show.
 * @returns {JSX.Element}
 */
const LogDay = ({ date }) => {
  return <p className={styles["sc-log--day"]}>{date}</p>;
};

export default LogDay;
