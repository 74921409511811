import React, { Component } from "react";
import PropTypes from "prop-types";
import FadeInOut from "../animations/fadeout/FadeInOut";
import styles from "./TooltipSc.module.scss";

/**
 * UI TooltipSc component.
 * @param {Object} props - Component properties
 * @param {String} [props.alignment='top'] - Position of tooltip. ('top'|'right'|'bottom'|'left')
 * @param {String} [props.action='hover'] - Handler. click or hover. ('hover'|'click')
 * @param {Number} [props.fadeOutDelay=2000] - Number. Time to vanish the tooltip in milleseconds. If 0 set, it won't disapear.
 * @param {JSX.Element} [props.content=<></>] - Contento of the tooltip
 * @param {Boolean} [props.hide=false] - Oculta el tooltip
 * @param {Object} [props.style={}] - Inline Styles of the tooltip
 * @returns {JSX.Element}
 */
class TooltipSc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      position: {},
    };

    this.elementRef = React.createRef();
    this.tooltipRef = React.createRef();
    // this.isListenerSet = false;
    this.SPACING = 35;
    this.sizeTimeout = undefined;
  }

  componentWillUnmount() {
    this.cleanTimeouts();
  }

  cleanTimeouts = () => {
    if (this.sizeTimeout) {
      clearTimeout(this.sizeTimeout);
    }
  };

  handleWindowMouseMove = (event) => {
    this.showTooltipPositioned(event.clientX, event.clientY);
  };

  handleOnClick = (e) => {
    this.showTooltipPositioned(e.clientX, e.clientY);
  };

  showTooltipPositioned = (clientX = 0, clientY = 0) => {
    this.cleanTimeouts();
    let width,
      height = 40;

    if (this.tooltipRef.current) {
      width =
        this.tooltipRef.current.offsetWidth !== undefined
          ? this.tooltipRef.current.offsetWidth
          : 40;
      height =
        this.tooltipRef.current.offsetHeight !== undefined
          ? this.tooltipRef.current.offsetHeight
          : 40;
    } else {
      // setTimeout(() => {
      //   this.showTooltipPositioned(clientX, clientY);
      // }, 1);
    }

    if (!width) {
      width = 40;
      this.sizeTimeout = setTimeout(() => {
        this.showTooltipPositioned(clientX, clientY);
      }, 1);
    }

    let top,
      left = 0;

    switch (this.props.alignment) {
      case "top":
        top = clientY - (height + this.SPACING - 5);
        left = clientX - width / 2 - 15;
        break;
      case "left":
        top = clientY - height / 2;
        left = clientX - width - 20;
        break;
      case "right":
        top = clientY - height / 2;
        left = clientX + this.SPACING - 15;
        break;
      case "bottom":
      default:
        top = clientY + this.SPACING;
        left = clientX - width / 2;
        break;
    }

    this.setState({
      visible: true,
      position: {
        top: top,
        left: left,
      },
    });
  };

  hideTooltip = () => {
    this.cleanTimeouts();
    this.hideUpdateState();
  };

  hideUpdateState = () => {
    this.setState({
      visible: false,
      position: {},
    });
  };

  render() {
    return (
      <span
        ref={this.elementRef}
        onMouseOut={this.hideTooltip}
        onClick={
          this.props.action === "click"
            ? (e) => this.handleOnClick(e)
            : undefined
        }
        onMouseMove={
          this.props.action !== "click" ? this.handleWindowMouseMove : undefined
        }
      >
        {this.props.children}
        {this.state.visible && !this.props.hide ? (
          <FadeInOut show={this.state.visible} duration={300}>
            <div
              ref={this.tooltipRef}
              className={`${styles["sc-tooltip"]} ${
                styles[`sc-tooltip--alignment--${this.props.alignment}`]
              }`}
              style={{
                top: this.state.position.top,
                left: this.state.position.left,
                ...this.props.style,
              }}
            >
              <div className={`${styles["sc-tooltip__inner"]}`}>
                {this.props.content}
              </div>
            </div>
          </FadeInOut>
        ) : (
          <></>
        )}
      </span>
    );
  }
}

TooltipSc.propTypes = {
  position: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  action: PropTypes.oneOf(["click", "hover"]),
  content: PropTypes.element,
  fadeOutDelay: PropTypes.number,
  hide: PropTypes.bool,
};

TooltipSc.defaultProps = {
  position: "bottom",
  action: "hover",
  content: <></>,
  fadeOutDelay: 3000,
  hide: false,
};

PropTypes.checkPropTypes(
  TooltipSc.propTypes,
  TooltipSc.defaultProps,
  "prop",
  "TooltipSc"
);

export default TooltipSc;
