import {
  Divider,
  Icon,
  Info,
  InfoAccordion,
  Paper,
  Row,
  ThumbnailHeader,
  Tracking,
} from "@scrapadev/scrapad-front-sdk";
import { useState } from "react";
import Item from "../../../components/ui/item/Item";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import { useTranslation } from "../../../utils/hooks";
import { modalTypes } from "../../../utils/variables";

import styles from "./Sidebar.module.scss";
import _ from "lodash";
import { format } from "date-fns";
import DepositTermsItem from "../../../components/ui/deposit-terms-item/DepositTermsItem";
import Transport from "../../../components/ui/transport/Transport";

/**
 * Component to show log sidebar.
 * @param {Object} props - Component properties.
 * @param {{ aboutTransaction: Array, advertiser: Object, interested: Object, offerData: Object, originAd: Array }} props.data - Component properties.
 * @param {String }} props.uuidEvent - Starter event.
 * @returns {JSX.Element}
 */
const Sidebar = ({
  data,
  headerData,
  log,
  fetchData,
  uuidEvent,
  permission,
}) => {
  // let { id } = useParams();
  // const user = useUser();
  const { t, i18n } = useTranslation([
    "common",
    "transactions",
    "finances",
    "filters",
  ]);
  const [open, setOpen] = useState(false);
  const [openIcoterm, setOpenIcoterm] = useState(false);
  const [openPaymentMethod, setOpenPaymentMethod] = useState(false);
  const uuid = data?.originAd?.uuidAd;

  if (!data) return <></>;

  const interestedTransform = (interested = []) => {
    let output = [];

    if (interested?.portAddress) {
      output.push({
        label: `${interested?.portAddress}`,
        icon: (
          <Icon
            name={"directions_boat"}
            color="#9E9E9E"
            style={{
              fontSize: "15px",
              paddingTop: "4px",
              paddingRight: "5px",
            }}
          />
        ),
      });
    }

    if (interested?.address) {
      output.push({
        label: `${interested?.address}`,
        icon: (
          <Icon
            name={"local_shipping"}
            color="#9E9E9E"
            style={{
              fontSize: "15px",
              paddingTop: "4px",
              paddingRight: "5px",
            }}
          />
        ),
      });
    }

    return output;
  };

  const trackingTransformer = (trackingData) => {
    if (!trackingData) {
      return [];
    }

    let output = [];
    let lastPhaseReached = false;

    if (trackingData?.tracking) {
      trackingData?.tracking?.forEach((data) => {
        const result = {
          heading: data?.desc,
          events: data?.entries?.map((event) => ({
            label: event?.desc,
            extra: format(Number(event?.date), "dd/MM/yyyy - kk:mm", {
              locale: i18n.getLanguageVariable(i18n.language),
            }),
          })),
          status:
            data?.phase === "ticket"
              ? "error"
              : data?.phase === trackingData?.lastPhase
              ? "current"
              : lastPhaseReached
              ? "none"
              : "success",
        };

        if (data?.phase === trackingData?.lastPhase) {
          lastPhaseReached = true;
        }

        output.push(result);
      });
    } else {
      // output.push({
      //   heading: `${trackingData?.advertiser?.orgName}`,
      //   events: [
      //     {
      //       label: `${trackingData?.advertiser?.address} ${trackingData?.advertiser?.portAddress}`,
      //       icon: (
      //         <Icon
      //           name={"location_on"}
      //           color="#9E9E9E"
      //           style={{
      //             fontSize: "15px",
      //             paddingTop: "4px",
      //             paddingRight: "5px",
      //           }}
      //         />
      //       ),
      //     },
      //   ],
      //   status: "none",
      // });
      // output.push({
      //   heading: `${trackingData?.interested?.orgName}`,
      //   events: interestedTransform(trackingData?.interested),
      //   status: "none",
      // });
    }

    return output;
  };

  let uuidBuyer = undefined;
  let uuidSeller = undefined;

  if (!_.isEmpty(data?.transportData?.advertiser)) {
    if (
      data?.transportData?.advertiser?.orgName === data?.aboutTransaction?.buyer
    ) {
      uuidBuyer = data?.transportData?.advertiser?.orgUuid;
      uuidSeller = data?.transportData?.interested?.orgUuid;
    } else if (
      data?.transportData?.advertiser?.orgName ===
      data?.aboutTransaction?.seller
    ) {
      uuidSeller = data?.transportData?.advertiser?.orgUuid;
      uuidBuyer = data?.transportData?.interested?.orgUuid;
    }
  }

  return (
    <div className={styles["sc-transaction--sidebar"]}>
      {data?.aboutTransaction && (
        <>
          <Item text={t("about-transaction")} alternativeStyle>
            <Info
              label={t("start-date")}
              value={data?.aboutTransaction?.initDate}
              expanded
            />
            <Info
              label={t("phase")}
              value={t(data?.aboutTransaction?.phase?.toLowerCase(), {
                ns: "transactions",
              })}
              expanded
            />
            {data?.aboutTransaction?.buyer && (
              <Info
                label={t("buyer")}
                value={data?.aboutTransaction?.buyer}
                link={uuidBuyer && `/business/${uuidBuyer}`}
                target="_blank"
                expanded
              />
            )}
            {data?.aboutTransaction?.seller && (
              <Info
                label={t("seller")}
                value={data?.aboutTransaction?.seller}
                link={uuidSeller && `/business/${uuidSeller}`}
                target="_blank"
                expanded
              />
            )}
          </Item>

          {data?.originAd && (
            <Item
              alternativeStyle
              text={t("origin-ad")}
              rightComponent={
                uuid && (
                  <a
                    href={`/ads/${data?.originAd?.uuidAd}`}
                    target={"_blank"}
                    rel="noreferrer"
                    className={styles["sc-transactions--link"]}
                  >
                    {t("view-ad")}
                  </a>
                )
              }
            >
              <ThumbnailHeader
                imageUrl={data?.originAd?.image}
                title={data?.originAd?.title}
                description={data?.originAd?.material}
                mode="reduced"
              />
              <Info expanded label={t("type")} value={data?.originAd?.adType} />
              <Info
                expanded
                label={t("amount")}
                value={data?.originAd?.amount}
              />
              <Info expanded label={t("price")} value={data?.originAd?.price} />
            </Item>
          )}

          {data?.offerData && (
            <Item
              text={t(
                data?.offerData?.isAccepted
                  ? "agreement-reached"
                  : "total_simulation"
              )}
              alternativeStyle
            >
              <Info
                expanded
                label={t("amount")}
                value={data?.offerData?.amount}
              />
              <Info
                expanded
                label={t("price")}
                value={data?.offerData?.proposedPrice}
              />
              <Divider
                color="#CFCFCF"
                style={{
                  margin: "5px 0",
                }}
              />
              {!data?.offerData?.isAccepted ? (
                <>
                  <Info
                    expanded
                    label={t("to-perceive-seller")}
                    extraValue={data?.offerData?.sellerPaymentMethod}
                    value={data?.offerData?.totalSeller}
                  />
                  <Divider
                    color="#CFCFCF"
                    style={{
                      margin: "5px 0",
                    }}
                  />
                  <Info
                    expanded
                    label={t("to-deposit-buyer")}
                    extraValue={data?.offerData?.buyerPaymentMethod}
                    value={data?.offerData?.totalBuyyer}
                  />
                </>
              ) : (
                <>
                  <InfoAccordion
                    label={t("to-perceive-seller")}
                    value={data?.offerData?.totalSeller}
                    secondaryLabel={data?.offerData?.sellerPaymentMethod}
                    style={{ justifyContent: "space-between", fontWeight: 500 }}
                  >
                    {!_.isEmpty(data?.offerData?.sellerTerms) && (
                      <Paper>
                        {data?.offerData?.sellerTerms?.map((term, index) => (
                          // <Phase
                          //   disabled={!term?.active}
                          //   phase={{
                          //     value: term?.amount,
                          //     phaseName: term?.desc,
                          //   }}
                          // />
                          <DepositTermsItem
                            key={`t_sidebar_st_${index}`}
                            label={term?.desc}
                            disabled={!term?.active && !term?.date}
                            date={
                              <>
                                {term?.date ? (
                                  <Row gap={4}>
                                    <Icon
                                      name={"done"}
                                      style={{ fontSize: "12px" }}
                                    />{" "}
                                    <span>{term?.date}</span>
                                  </Row>
                                ) : (
                                  <></>
                                )}
                              </>
                            }
                            amount={term?.amount}
                          />
                        ))}
                      </Paper>
                    )}
                  </InfoAccordion>
                  <Divider
                    color="#CFCFCF"
                    style={{
                      margin: "5px 0",
                    }}
                  />
                  <InfoAccordion
                    label={t("to-deposit-buyer")}
                    value={data?.offerData?.totalBuyyer}
                    secondaryLabel={data?.offerData?.buyerPaymentMethod}
                    style={{ justifyContent: "space-between", fontWeight: 500 }}
                  >
                    {!_.isEmpty(data?.offerData?.buyerTerms) && (
                      <Paper>
                        {data?.offerData?.buyerTerms?.map((term, index) => (
                          // <Phase
                          //   disabled={!term?.active}
                          //   phase={{
                          //     value: term?.amount,
                          //     phaseName: term?.desc,
                          //   }}
                          // />
                          <DepositTermsItem
                            key={`t_sidebar_bt_${index}`}
                            label={term?.desc}
                            disabled={!term?.active && !term?.date}
                            date={
                              <>
                                {term?.date ? (
                                  <Row gap={4}>
                                    <Icon
                                      name={"done"}
                                      style={{ fontSize: "12px" }}
                                    />{" "}
                                    <span>{term?.date}</span>
                                  </Row>
                                ) : (
                                  <></>
                                )}
                              </>
                            }
                            amount={term?.amount}
                          />
                        ))}
                      </Paper>
                    )}
                  </InfoAccordion>
                </>
              )}

              <Divider
                color="#CFCFCF"
                style={{
                  margin: "5px 0",
                }}
              />
              <Info
                expanded
                label={t("net-margin")}
                value={<strong>{data?.offerData?.marginScrapAd}</strong>}
              />
            </Item>
          )}
          {!_.isEmpty(data?.transportData) && (
            <>
              <Item
                text={t("transport")}
                // rightComponent={
                //   isLogistic ? (
                //     <>
                //       <Link
                //         to={`/transactions/${id}/transport`}
                //         className={styles["sc-transactions--link"]}
                //       >
                //         {t("see-more")}
                //       </Link>
                //     </>
                //   ) : undefined
                // }
                alternativeStyle
              >
                <Transport
                  advertiser={
                    data?.originAd?.type?.toLowerCase().trim() === "sale"
                      ? data?.transportData?.advertiser
                      : data?.transportData?.interested
                  }
                  interested={
                    data?.originAd?.type?.toLowerCase().trim() === "sale"
                      ? data?.transportData?.interested
                      : data?.transportData?.advertiser
                  }
                />
                {!_.isEmpty(data?.transportData?.tracking) && (
                  <Tracking
                    breakpoint={7000}
                    steps={trackingTransformer(data?.transportData)}
                  />
                )}
              </Item>
            </>
          )}
        </>
      )}
      <ModalFactory
        type="transactions-raw-weight"
        open={open}
        setOpen={setOpen}
        uuidEvent={uuidEvent}
        fetchData={fetchData}
      />
      <ModalFactory
        type={modalTypes.CHANGE_ICOTERM}
        open={openIcoterm}
        setOpen={setOpenIcoterm}
        uuidEvent={uuidEvent}
        fetchData={fetchData}
        icoterm={data?.offerData?.incoterm}
      />
      <ModalFactory
        type={modalTypes.CHANGE_PAYMENT_METHOD}
        open={openPaymentMethod}
        setOpen={setOpenPaymentMethod}
        uuidEvent={uuidEvent}
        fetchData={fetchData}
        method={data?.offerData?.paymentMethod}
      />
    </div>
  );
};

export default Sidebar;
