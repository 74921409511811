import { BREAKPOINT_LG, useMatchMedia } from "@scrapadev/scrapad-front-sdk";
import styles from "./Separator.module.scss";

const Separator = ({
  color,
  height = 22,
  hideOnMobile = true,
  space = 16,
  style = {},
}) => {
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  return (
    <span
      className={styles["sc-separator"]}
      style={{
        color,
        height,
        margin: `0 ${space}px`,
        display: matches && hideOnMobile ? "none" : "initial",
        ...style,
      }}
    ></span>
  );
};

export default Separator;
