import { Container, Empty, Spacer } from "@scrapadev/scrapad-front-sdk";
import { getPricingQuotations } from "../../model/logistics";
import { useTranslation } from "../../utils/hooks";
import WidgetDataWrapper from "../../widgets/wrappers/DataWrapper";
import Kanban, { KanbanTypes } from "../../components/ui/kanban/column/Kanban";
import _ from "lodash";
import { useUser } from "../../providers/AuthHooks";
import WebsocketsWrapper from "../../widgets/websockets/Wrapper";
import { WS_ROOM, WS_SLUGS } from "../../utils/websockets/functions";

const InnerLogisticsPricing = ({
  state,
  loading,
  fetchData = async () => {},
}) => {
  const user = useUser();

  if (!loading && _.isEmpty(state)) {
    return (
      <>
        <Spacer height={25} />
        <Empty />
        {user && (
          <WebsocketsWrapper
            callback={async (data) => {
              if (
                !_.isEmpty(data) &&
                data?.subscribeRoom?.type === WS_ROOM.transaction &&
                data?.subscribeRoom?.slug !== WS_SLUGS.ACCESS_TRANSACTION
              ) {
                await fetchData();
              }
            }}
            room={WS_ROOM.transaction}
          />
        )}
      </>
    );
  }

  return (
    <>
      <Container isFluid>
        <Spacer height={25} />
        <Kanban type={KanbanTypes.PRICING} data={Object.values(state)} />
      </Container>
      {user && (
        <WebsocketsWrapper
          callback={async (data) => {
            if (
              !_.isEmpty(data) &&
              data?.subscribeRoom?.type === WS_ROOM.transaction &&
              data?.subscribeRoom?.slug !== WS_SLUGS.ACCESS_TRANSACTION
            ) {
              await fetchData();
            }
          }}
          room={WS_ROOM.transaction}
        />
      )}
    </>
  );

  // return (
  //   <Container isFluid>
  //     <Spacer height={25} />
  //     <div className="row">
  //       <div className="col-12">
  //         <Box style={boxStyles}>
  //           <div className="row">
  //             <div className="col-lg-4 col-md-6 col-12" style={columnStyles}>
  //               <Heading type={2} style={headingStyles}>
  //                 {t("cost_pending")}
  //               </Heading>
  //               {pending && (
  //                 <Flex flexDirection="column" style={itemsStyles}>
  //                   {pending.map((quotation) => (
  //                     <LogisticsCard quotation={quotation} />
  //                   ))}
  //                 </Flex>
  //               )}
  //             </div>
  //             <div className="col-lg-4 col-md-6 col-12" style={columnStyles}>
  //               <Heading type={2} style={headingStyles}>
  //                 {t("cost_sended")}
  //               </Heading>
  //               {sended && (
  //                 <Flex flexDirection="column" style={itemsStyles}>
  //                   {sended.map((quotation) => (
  //                     <LogisticsCard quotation={quotation} />
  //                   ))}
  //                 </Flex>
  //               )}
  //             </div>
  //             <div className="col-lg-4 col-md-6 col-12" style={columnStyles}>
  //               <Heading type={2} style={headingStyles}>
  //                 {t("cost_expired")}
  //               </Heading>
  //               {expired && (
  //                 <Flex flexDirection="column" style={itemsStyles}>
  //                   {expired.map((quotation) => (
  //                     <LogisticsCard quotation={quotation} />
  //                   ))}
  //                 </Flex>
  //               )}
  //             </div>
  //           </div>
  //         </Box>
  //       </div>
  //     </div>
  //   </Container>
  // );
};

const LogisticsPricing = () => {
  const { i18n } = useTranslation(["common"]);
  return (
    <WidgetDataWrapper
      fnData={getPricingQuotations}
      fnParameters={{
        locale: i18n.language,
      }}
      // dataAdapter={(data) => {
      //   const pending = [];
      //   const sended = [];
      //   const expired = [];
      //   if (!data || !data?.data) {
      //     return {
      //       quotations: {
      //         pending,
      //         sended,
      //         expired,
      //       },
      //     };
      //   }
      //   const quotations = data.data.quotations || [];
      //   for (let i = 0; i < quotations.length; i++) {
      //     const quotation = parseQuotation(quotations[i]);
      //     switch (quotation.status) {
      //       case pricingTypes.PENDING:
      //         pending.push(quotation);
      //         break;
      //       case pricingTypes.SENDED:
      //         sended.push(quotation);
      //         break;
      //       case pricingTypes.EXPIRED:
      //         expired.push(quotation);
      //         break;
      //     }
      //   }
      //   return {
      //     quotations: {
      //       pending,
      //       sended,
      //       expired,
      //     },
      //   };
      // }}
      Component={InnerLogisticsPricing}
    />
  );
};

export default LogisticsPricing;
