import { Container, TableManager } from "@scrapadev/scrapad-front-sdk";
import { useRef } from "react";
import Header from "../../components/ui/header/Header";
import NavBar from "../../components/ui/navbar/NavBar";
import SearchFilterExport from "../../components/ui/search-filter-export/SearchFilterExport";
import LayoutDetail from "../../layout/detail/Detail";
import LayoutTable from "../../layout/table/Table";
import { getTable } from "../../model/tables";
import { getUsers } from "../../model/users";
import { useFiltersProvider } from "../../providers/filters/FiltersContext";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { usePaginationProvider } from "../../providers/pagination/PaginationContext";
import { useTableDataProvider } from "../../providers/TableContext";
import { useDocumentTitle } from "../../utils/document/hooks";
import { searchOnTable } from "../../utils/functions";
import { useTranslation } from "../../utils/hooks";
import {
  useFiltersAction,
  usePaginationAction,
} from "../../utils/tables/hooks";
import { roles } from "../../utils/users/variables";
import { tableModules, TABLE_LIMIT } from "../../utils/variables";

const InnerUsers = ({ baseTableParams }) => {
  const rowUuid = useRef(null);
  const { t, i18n } = useTranslation(["common"], true);
  const { handlePagination } = usePaginationProvider();
  const { buildFiltersAndFetch, getStoredFilters } = useFiltersProvider();
  const filters = buildFiltersAndFetch(getStoredFilters() || {});
  const { data, setData, dataBuffer, loading, setLoading, fetchData } =
    useTableDataProvider();
  const { canManageUserRoles } = useOperationsProvider();

  useDocumentTitle(`Gestión de usuarios`);

  usePaginationAction((paginationState) => {
    const newFilters = buildFiltersAndFetch(getStoredFilters() || {});
    const params = {
      ...baseTableParams,
      offset: paginationState?.current,
    };
    if (Object.keys(newFilters).length > 0) {
      params.filter = newFilters;
    }
    fetchData(params);
  });

  useFiltersAction((filtersState) => {
    buildFiltersAndFetch(filtersState, (params) => {
      handlePagination(0);
    });
  });

  const renderRoleActions = () => {
    return roles.map((role) => ({
      uuid: role?.uuid,
      label: t(role?.text),
      callBack: handleRoleAction(role?.uuid),
    }));
  };

  const handleRoleAction = (uuidRole) => async (uuidRow) => {
    console.log(uuidRow, uuidRole);
  };

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerWithContainer
        containerProps={{ isFluid: true }}
        headerComponent={
          <Header
            title={"Gestión de usuarios"}
            subHeaderLeft={
              <SearchFilterExport
                isLocalSearch
                searchPlaceholder={t("search")}
                searchOnChange={searchOnTable(
                  data,
                  setData,
                  dataBuffer.current,
                  i18n
                )}
                exportProps={{
                  fnData: getUsers,
                  fileName: "users",
                }}
                hasFilters={false}
                hasSeparator={false}
              />
            }
            fullSubHeaderLeft
            middleStyles={{ marginBottom: 0 }}
            lowerLeftStyles={{ marginBottom: 10 }}
          />
        }
        bodyComponent={
          <Container isFluid>
            <div className="row">
              <div className="col-12">
                <TableManager
                  loading={loading}
                  data={data}
                  setData={setData}
                  emptyText={t("empty-users")}
                  rowTriggerPageChange={false}
                  onRowClick={(uuid) => {}}
                  itemsProps={{
                    dropdown: {
                      items: !canManageUserRoles
                        ? renderRoleActions()
                        : canManageUserRoles()
                        ? renderRoleActions()
                        : [],
                    },
                  }}
                  pagination={
                    data?.pagination
                      ? {
                          currentPage: Math.ceil(
                            data?.pagination?.current / TABLE_LIMIT
                          ),
                          totalPages: Math.ceil(
                            data?.pagination?.total / TABLE_LIMIT
                          ),
                          threshold: 3,
                          onChangePage: handlePagination,
                          onChangePrev: handlePagination,
                          onChangeNext: handlePagination,
                        }
                      : undefined
                  }
                />
              </div>
            </div>
          </Container>
        }
      />
    </>
  );
};

const Users = () => {
  const { i18n } = useTranslation();
  const { getStoredPagination } = usePaginationProvider();
  const { getStoredFilters, buildFiltersAndFetch } = useFiltersProvider();
  const filters = buildFiltersAndFetch(getStoredFilters() || {});
  const baseParams = {
    locale: i18n.language,
    limit: TABLE_LIMIT,
    tableModule: tableModules.ROLES_USERS,
  };
  const tableParams = {
    ...baseParams,
    offset: getStoredPagination()?.current ? getStoredPagination()?.current : 0,
  };

  if (Object.keys(filters).length > 0) {
    tableParams.filter = filters;
  }

  return (
    <LayoutTable fnData={getUsers} fnParameters={tableParams}>
      <InnerUsers baseTableParams={baseParams} />
    </LayoutTable>
  );
};

export default Users;
