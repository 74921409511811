import { gql } from "@apollo/client";
import { getGQLData } from "../utils/gqlbase";

export async function getOrgWalletMovements({ org, offset = 0, limit = 10 }) {
  const widget = await getGQLData(gqlGetOrgWalletMovements, {
    uuidOrg: org,
    offset,
    limit,
  });
  return widget?.getWalletForOrg;
}

export async function getOrgWalletAccounts({ org, offset = 0, limit = 10 }) {
  const widget = await getGQLData(gqlGetOrgWalletAccounts, {
    uuidOrg: org,
    offset,
    limit,
  });
  return widget?.getWalletForOrg;
}

const gqlGetOrgWalletMovements = gql`
  query ($uuidOrg: String!, $offset: Int!, $limit: Int!) {
    getWalletForOrg(uuidOrg: $uuidOrg, offset: $offset, limit: $limit) {
      balance
      transactions {
        amount
        date
        desc
        status
        type
        uuid
      }
      moneyOutAccount {
        uuid
      }
    }
  }
`;

const gqlGetOrgWalletAccounts = JSON.parse(
  process.env.REACT_APP_DISABLE_MONEY_IN
)
  ? gql`
      query ($uuidOrg: String!, $offset: Int!, $limit: Int!) {
        getWalletForOrg(uuidOrg: $uuidOrg, offset: $offset, limit: $limit) {
          moneyOutAccount {
            address
            bicCode
            status
            viban
            urlVIBANUser
            holder
            uuid
          }
        }
      }
    `
  : gql`
      query ($uuidOrg: String!, $offset: Int!, $limit: Int!) {
        getWalletForOrg(uuidOrg: $uuidOrg, offset: $offset, limit: $limit) {
          moneyInAccount {
            address
            bicCode
            status
            viban
            urlVIBANUser
            holder
          }
          moneyOutAccount {
            address
            bicCode
            status
            viban
            urlVIBANUser
            holder
            uuid
          }
        }
      }
    `;
