import React, { useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import {
  BREAKPOINT_LG,
  Modal,
  toastError,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { uploadFile } from "../../../utils/functions";
import { useCRUD } from "../../../utils/crud/hooks";
import FileUpload from "../../ui/file-upload/FileUpload";
import _ from "lodash";

function UploadLogisticsPricing({ open = false, setOpen, ...props }) {
  const { t } = useTranslation(["common", "transactions"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [loading, setLoading] = useState(false);
  const [filesSelected, setFilesSelected] = useState([]);
  const { CRUD } = useCRUD();

  const handleSubmit = async (e) => {
    e?.preventDefault();
    if (_.isEmpty(filesSelected)) {
      return;
    }
    setLoading(true);

    const uploadPromises = [];
    let filesUuid = [];

    try {
      for (const file of filesSelected) {
        uploadPromises.push(
          uploadFile({
            file: file,
            type: "quotation",
          })
        );
      }
      const results = await Promise.all(uploadPromises);
      filesUuid = results?.map((f) => f[0].uuid_file);

      let haveError = false;

      await Promise.all(
        filesUuid.map((uuid) =>
          CRUD({
            endpoint: `logistics/pricing/${uuid}`,
            method: "POST",
            errorCallback: () => {
              haveError = true;
            },
            successCallback: async () => {},
          })
        )
      );

      if (haveError) {
        toastError(t("upload_error"));
      } else {
        setOpen(false);
      }
    } catch (error) {
      toastError(t("upload_error"));
    }

    setLoading(false);
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t("upload_estimate")}
      reverseActions={false}
      hasForm={true}
      hasActions={true}
      onSubmit={handleSubmit}
      loading={loading}
      maxWidth={900}
      cancelText={t("close")}
      submitText={t("send")}
      modalStyles={{ width: matches ? "auto" : 800 }}
      submitDisabled={_.isEmpty(filesSelected)}
      BodyComponent={
        <>
          <FileUpload
            defaultFiles={filesSelected}
            onChange={(files) => {
              setFilesSelected(files);
            }}
            acceptedTypeFile={{
              "text/csv": [".csv"],
            }}
            translation={{
              select_or_drag_files: `${t("select_or_drag_files")} (${t(
                "only_csv"
              )})`,
              files_selected: t("files_selected"),
            }}
          />
        </>
      }
    />
  );
}

export default UploadLogisticsPricing;
