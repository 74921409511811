import { useEffect } from "react";

/**
 * Custom hook for setting document.title
 * @param {String} title - Title of the current page.
 * @param {Array} [dependencies=[]] - useEffect dependencies.
 * @returns {String}
 */
export const useDocumentTitle = (title, dependencies) => {
  useEffect(() => {
    document.title = `${title} - ${process.env.REACT_APP_SCRAPAD_APP_NAME} `;
  }, [dependencies]);
};
