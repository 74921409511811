import {
  SearchAutoComplete,
  Center,
  Loading,
  toastError,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { getMaterials } from "../../../model/ads";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import { gqlWrapper } from "../../../utils/gqlwrapper";
import { useTranslation } from "../../../utils/hooks";
import { adsDefaults } from "../../../utils/variables";

const BaseMaterials = ({ state, dispatch, actions, customRender }) => {
  const { t, i18n } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();
  const values = state?.materials?.material?.options
    .filter((f) => state?.materials?.material?.uuidSelected?.includes(f?.uuid))
    .map((f) => f?.name);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      fetchData();
    }
    return () => {
      ignore = true;
    };
  }, [state.materials.category.uuidSelected]);

  const fetchData = async () => {
    setLoading(true);
    const fn = await gqlWrapper(getMaterials, setDataUser, signOut);
    const results = await fn(
      i18n.language,
      state?.materials?.category?.uuidSelected[0]
    );
    if (results !== null) {
      if (results?.errors?.length > 0) {
        toastError("Error");
      } else {
        dispatch({
          type: actions.UPDATE_INPUT,
          payload: {
            materials: {
              ...state.materials,
              material: {
                ...state.materials.material,
                options: results,
                open: true,
              },
            },
          },
        });
        setLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        materials: {
          ...state.materials,
          material: {
            ...state.materials.material,
            values: [e.target.value],
          },
        },
      },
    });
  };

  const handleClick = (option, open, setOpen, e) => {
    e.stopPropagation();
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: {
        materials: {
          ...state.materials,
          type: {
            ...adsDefaults,
            open: true,
          },
          material: {
            ...state.materials.material,
            uuidSelected: [option.uuid],
            nameSelected: [option.name],
            values: [option.name],
          },
        },
      },
    });
  };

  return (
    <>
      {customRender ? (
        customRender(state, dispatch, actions, loading, setLoading)
      ) : loading ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        state?.materials.material?.options && (
          <SearchAutoComplete
            slug="filter-material-composition"
            resultsSlug="filter-material-composition-results"
            placeholder={t("select-one")}
            handleOnChange={handleChange}
            model={{ uuid: "uuid", text: "name" }}
            value={values}
            options={state?.materials?.material?.options}
            handleItemClick={handleClick}
            position="fixed"
            inputProps={{ placeholder: t("write-or-select") }}
            handleOnClickWithEvent={(e, value, open, setOpen) => {
              e.stopPropagation();
              setOpen(!open);
            }}
          />
        )
      )}
    </>
  );
};

export default BaseMaterials;
