import {
  BREAKPOINT_LG,
  BREAKPOINT_XL,
  Flex,
  Loading,
  Tracking,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useTracking } from "../../../utils/transactions/hooks";

const TrackingForm = ({ data }) => {
  const {
    id,
    data: trackingData,
    loading,
    parseLandData,
    parseSeaData,
  } = useTracking(data);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);

  if (loading) return <Loading />;

  return (
    <Flex
      alignItems={"flex-start"}
      justifyContent={"flex-start"}
      style={{
        width: "100%",
        height: "100%",
        paddingLeft: matches ? 0 : 50,
        paddingRight: matches ? 0 : 50,
      }}
    >
      <Tracking
        barContainerStyles={matches ? { height: "auto" } : undefined}
        steps={trackingData?.type === "sea" ? parseSeaData() : parseLandData()}
        breakpoint={BREAKPOINT_XL}
      />
    </Flex>
  );
};

export default TrackingForm;
