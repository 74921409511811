import { gql } from "@apollo/client";
import client from "../utils/gqlclient";

export async function getUsers() {
  const data = await Promise.resolve({
    data: {
      tableData: {
        columns: [
          {
            name: "Nombre",
            filterable: true,
            sortable: true,
            ref: "user_name",
          },
          {
            name: "Email",
            filterable: true,
            sortable: true,
            ref: "email",
          },
          {
            name: "Rol",
            filterable: true,
            sortable: true,
            ref: "role",
          },
        ],
        rows: [
          {
            uuid: "e67ebb66-0bdc-4284-803c-22b67cac5590",
            cells: [
              {
                value: "pruebas5",
                format: "text",
                colRef: "user_name",
              },
              {
                value: "deyvid.gavazovv@gmail.com",
                format: "text",
                colRef: "email",
              },
              {
                value: "Super admin",
                format: "dropdown",
                colRef: "role",
              },
            ],
          },
        ],
        pagination: {
          total: 1,
          current: 0,
          next: null,
        },
      },
    },
  });
  return data.errors ? { errors: data.errors } : data.data.tableData;
}
