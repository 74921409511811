import basic from "../../../assets/img/icons/verified.png";
import full from "../../../assets/img/icons/full-verified.png";
import { orgStatus } from "../../../utils/variables";
import { useTranslation } from "../../../utils/hooks";
import { TooltipSc } from "@scrapadev/scrapad-front-sdk";
import Text from "../../../components/ui/text/Text";

const VerificationStatus = ({ type }) => {
  const { t } = useTranslation("verification");
  if (!type) return <></>;
  if (type === orgStatus.ACTIVE)
    return (
      <TooltipSc
        style={{
          justifyContent: "center",
          minWidth: 0,
        }}
        content={
          <Text style={{ color: "#fff", fontWeight: 500 }}>
            {t("basic-verification")}
          </Text>
        }
      >
        <img src={basic} />
      </TooltipSc>
    );
  if (type === orgStatus.KYC_COMPLETE)
    return (
      <TooltipSc
        style={{
          justifyContent: "center",
          minWidth: 0,
        }}
        content={
          <Text style={{ color: "#fff", fontWeight: 500 }}>
            {t("full-verification")}
          </Text>
        }
      >
        <img src={full} />
      </TooltipSc>
    );
  return <></>;
};

export default VerificationStatus;
