import React from "react";
import styles from "./Select.module.scss";

function Select({ options = [], name = "", required, style = {}, ...props }) {
  return (
    <select
      className={`${styles["sc-select"]} ${styles[props.mode]}`}
      name={name}
      required={required}
      style={style}
      autocomplete="off"
      {...props}
    >
      {options}
    </select>
  );
}

export default Select;
