import {
  ClearButton,
  Content,
  Icon,
  useClickOnBody,
  BREAKPOINT_LG,
} from "@scrapadev/scrapad-front-sdk";
import { useRef, useState } from "react";
import { useOperationsProvider } from "../../../providers/organization/OperationsContext";
import { useMatchMedia, useTranslation } from "../../../utils/hooks";
import TextLogo from "../text-logo/TextLogo";
import Info from "./Info";
import MenuItem from "./Item";
import styles from "./Menu.module.scss";
import SubMenu from "./SubMenu";

const MoreItemsDesktop = () => {
  const { t } = useTranslation(["common", "logistics"]);
  const [open, setOpen] = useState(false);
  const { canReadAds, canReadFinances, canReadLogistics } =
    useOperationsProvider();
  const containerRef = useRef(null);

  useClickOnBody(containerRef, () => setOpen(false));

  const getMenuItems = () => {
    let items = [];
    if (canReadAds())
      items.push({
        component: <MenuItem to={"/ads"} icon={"sell"} text={t("ads")} />,
      });
    if (canReadFinances())
      items.push({
        component: (
          <MenuItem to={"/finances"} icon={"show_chart"} text={t("finances")} />
        ),
      });
    if (canReadLogistics())
      items.push({
        component: (
          <MenuItem
            to={"/logistics"}
            icon={"directions_boat"}
            text={t("logistics")}
          />
        ),
      });
    return items;
  };

  const renderMenu = () => {
    let items = getMenuItems();
    if (items.length > 0) {
      return (
        <li>
          <ClearButton onClick={() => setOpen(!open)}>
            <div ref={containerRef}>
              {t("more")}{" "}
              <Icon
                name={open ? "arrow_drop_up" : "arrow_drop_down"}
                style={{ margin: "0 0 0 15px" }}
              />
            </div>
            {open && (
              <Content
                position="absolute"
                items={getMenuItems()}
                style={{
                  top: 50,
                  paddingTop: 10,
                  paddingBottom: 10,
                  paddingLeft: 0,
                  paddingRight: 0,
                  border: 0,
                }}
              />
            )}
          </ClearButton>
        </li>
      );
    }
    return <></>;
  };

  return renderMenu();
};

const MoreItemsMobile = () => {
  const { t } = useTranslation(["common", "logistics"]);
  const { canReadAds, canReadFinances, canReadLogistics } =
    useOperationsProvider();
  return (
    <>
      {canReadAds() && (
        <li>
          <MenuItem to={"/ads"} icon={"sell"} text={t("ads")} />
        </li>
      )}
      {canReadFinances() && (
        <li>
          <MenuItem to={"/finances"} icon={"show_chart"} text={t("finances")} />
        </li>
      )}
      {canReadLogistics() && (
        <li>
          <MenuItem
            to={"/logistics"}
            icon={"directions_boat"}
            text={t("logistics")}
          />
        </li>
      )}
    </>
  );
};

/**
 * Private Menu component.
 * @param {Object} props - Component properties.
 * @param {Boolean} [props.withLogo=false] - Tells the component if the logo must be shown.
 * @returns {JSX.Element}
 */
const Menu = ({ withLogo = true }) => {
  const { t } = useTranslation("common");
  const matches = useMatchMedia(`(min-width: ${BREAKPOINT_LG}px)`);
  const {
    canReadAds,
    canReadOrg,
    canReadTransaction,
    canReadBusiness,
    canManageOpportunities,
  } = useOperationsProvider();

  return (
    <>
      <div className={[styles["c-navbar__menu"]]}>
        {withLogo && <TextLogo />}
        <div className={styles["c-navbar__menu-inner"]}>
          <ul>
            {canReadBusiness() && (
              <li className={styles["c-navbar__item-submenu"]}>
                <MenuItem
                  to={"/business"}
                  icon={"business"}
                  text={t("business")}
                />
                <SubMenu
                  items={[
                    {
                      component: (
                        <MenuItem
                          to={"/register-incomplete"}
                          text={t("register-incomplete")}
                        />
                      ),
                    },
                  ]}
                  containerStyle={{
                    padding: 0,
                    borderRight: matches ? "1px solid #cfcfcf" : 0,
                  }}
                  iconStyle={{ paddingLeft: 15 }}
                />
              </li>
            )}
            {canManageOpportunities() && (
              <li>
                <MenuItem
                  to={"/opportunities"}
                  icon={"campaign"}
                  text={t("opportunities")}
                />
              </li>
            )}
            {canReadTransaction() && (
              <li>
                <MenuItem
                  to={"/transactions"}
                  icon={"swap_horiz"}
                  text={t("transactions")}
                />
              </li>
            )}
            {matches ? <MoreItemsDesktop /> : <MoreItemsMobile />}
          </ul>
          {matches && <Info showBusinnes={false} />}
        </div>
      </div>
    </>
  );
};

export default Menu;
