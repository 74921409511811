import styles from "./Label.module.scss";

/**
 * UI Form label component.
 * @param {Object} props - Component properties.
 * @param {Boolean} [props.reduced=false] - Reduced font size.
 * @param {Boolean} [props.light=false] - Light color.
 * @param {Boolean} [props.dark=false] - Dark color.
 * @returns {JSX.Element}
 */
const Label = ({
  reduced = false,
  light = false,
  dark = false,
  children,
  ...props
}) => {
  const className = reduced ? "sc-label--reduced" : "sc-label";
  const lightClassName = light ? "sc-label--light" : "";
  const darkClassName = dark ? "sc-label--dark" : "";

  return (
    <label
      {...props}
      className={`${styles[className]} ${styles[lightClassName]} ${styles[darkClassName]}`}
    >
      {children}
    </label>
  );
};

export default Label;
