import { Button, GREEN_COLOR_100, Icon } from "@scrapadev/scrapad-front-sdk";
import { useConfirmDeposit } from "../../../utils/finances/hooks";
import { useTranslation } from "../../../utils/hooks";

/**
 * Confirm deposit component
 * @param {object} props - Component properties.
 * @param {Function} props.fetchData - Function to fetch data.
 * @param {string} props.uuidDeposit - Bubble uuid.
 * @param {string} props.uuidUser - Buyer user uuid.
 * @param {string} props.uuidEvent - Transaction uuid.
 * @returns {JSX.Element}
 */
const DepositButton = ({ fetchData, uuidUser, uuidEvent, uuidDeposit }) => {
  const { handleConfirmDeposit, loading } = useConfirmDeposit(fetchData);
  const { t } = useTranslation("common");

  return (
    <Button
      type="button"
      component="button"
      mode="outline-success"
      loading={loading}
      style={{
        padding: loading ? "7px 60px 7px 17px" : "7px 17px",
      }}
      prefix={
        <Icon
          name={"check_circle"}
          color={GREEN_COLOR_100}
          style={{
            fontSize: 18,
            display: "flex",
            marginRight: "0.625rem",
            transform: "none",
          }}
        />
      }
      text={t("confirm-deposit")}
      onClick={() => {
        handleConfirmDeposit(uuidUser, uuidEvent, uuidDeposit);
      }}
    />
  );
};

export default DepositButton;
