import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NotFound from "../../pages/notfound/NotFound";
import { useSignOut, useUser } from "../../providers/AuthHooks";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";

/**
 * Route guard.
 * @param {Object} props - Component properties.
 * @param {React.Component} props.Component - Component to check guard.
 * @param {Boolean} [props.reverse=false] - Reverses the logic taht allows a page to be visited. Hides pages when logged in.
 * @returns {JSX.Element}
 */
const Guard = ({
  Component = <></>,
  reverse = false,
  permission,
  operationName,
  bypassPermission = false,
}) => {
  const user = useUser();
  const location = useLocation();
  const [shallPass, setShallPass] = useState();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const { operations, roleOperationsName } = useOperationsProvider();

  useEffect(() => {
    const publicPaths = ["/login", "/recover"];
    if (reverse) {
      if (publicPaths.includes(location.pathname)) {
        setShallPass(false);
      }
      setShallPass(user ? false : true);
    } else {
      if (publicPaths.includes(location.pathname)) {
        setShallPass(true);
      }
      setShallPass(user ? true : false);
    }
  }, [user]);

  if (!shallPass) return <></>;

  if (bypassPermission) return Component;

  if (!permission) return Component;

  if (
    !operations ||
    operations?.length === 0 ||
    (location.pathname === "/transactions" &&
      !operations.find(
        (f) => f.operation === roleOperationsName.MANAGEMENT_TRANSACTIONS
      ))
  ) {
    return <NotFound />;
  }

  return permission(operationName) ? Component : navigate("/");
};

export default Guard;
