import { AutoComplete, ClearButton, Icon } from "@scrapadev/scrapad-front-sdk";
import React, { useState } from "react";

function SearchUsersInput() {
  const [userList, setUserList] = useState([
    "Daniel",
    "Miguel",
    "Iñaki",
    "Raquel",
    "Aritz",
  ]);

  return (
    <AutoComplete
      leadingIcon={<Icon name={"search"} />}
      mode="underline"
      options={userList}
      handleOnChange={(e) => {
        console.log(e);
      }}
      handleItemClick={(option, open, setOpen, e) => {
        console.log(option);
      }}
      placeholder={"Buscar personas"}
      renderItem={(
        option,
        innerOptions,
        handleClick,
        handleIsChecked,
        index
      ) => {
        return <ClearButton>{option}</ClearButton>;
      }}
    />
  );
}

export default SearchUsersInput;

