import { whiteFilterProps } from "../../../utils/filters/variables";
import { TEXT_COLOR_LIGHT } from "../../../utils/variables";
import Filters from "./Filters";

/**
 * Filters with a white blue L&F.
 * @param {object} props - Filter props
 * @returns {JSX.Element}
 */
const WhiteFilters = ({ ...props }) => {
  return (
    <Filters
      showChips={false}
      filtersSelectionMultipleProps={whiteFilterProps}
      restoreProps={{
        textStyle: {
          textDecoration: "underline",
          color: TEXT_COLOR_LIGHT,
          fontFamily: "Silka",
          fontWeight: 400,
          fontSize: 12,
          lineHeight: "18px",
        },
      }}
      {...props}
    />
  );
};

export default WhiteFilters;
