import { useEffect, useState } from "react";
import { useSetDataUser, useSignOut } from "../../providers/AuthHooks";
import { gqlWrapper } from "../gqlwrapper";

export const useGQL = (
  fnData,
  fnParameters,
  manual = false,
  delegateLoading = false
) => {
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let ignore = false;
    if (!ignore && !manual) {
      if (Array.isArray(fnData)) {
        fetchMultiple();
      } else {
        fetchData();
      }
    }
    return () => {
      ignore = true;
    };
  }, []);

  const fetchMultiple = async () => {
    if (!fnData || fnData.length === 0) {
      setLoading(false);
      return;
    }
    if (!delegateLoading) setLoading(true);
    let results;
    try {
      results = await Promise.all(
        fnData.map(async (fn, index) => {
          const f = await gqlWrapper(fn, setDataUser, signOut);
          return fnParameters
            ? await f(
                Array.isArray(fnParameters) ? fnParameters[index] : fnParameters
              )
            : await f();
        })
      );
      setData(results);
    } catch (e) {
      setError(results?.errors);
    } finally {
      if (!delegateLoading) setLoading(false);
    }
  };

  const fetchData = async (params) => {
    let results;
    if (!fnData) {
      setLoading(false);
      return;
    }
    if (!delegateLoading) setLoading(true);
    const fn = await gqlWrapper(fnData, setDataUser, signOut);
    try {
      results = params
        ? await fn(params)
        : fnParameters
        ? await fn(fnParameters)
        : await fn();
      setData(results);
    } catch {
      setError(results?.errors);
    } finally {
      if (!delegateLoading) setLoading(false);
    }
  };

  const fetchDataWithReturn = async (params) => {
    let results;
    if (!fnData) {
      setLoading(false);
      return;
    }
    if (!delegateLoading) setLoading(true);
    const fn = await gqlWrapper(fnData, setDataUser, signOut);
    try {
      results = params
        ? await fn(params)
        : fnParameters
        ? await fn(fnParameters)
        : await fn();
      if (!delegateLoading) setLoading(false);
      setData(results);
      return results;
    } catch {
      if (!delegateLoading) setLoading(false);
      setError(results?.errors);
      return results;
    }
  };

  return {
    data,
    setData,
    loading,
    setLoading,
    error,
    setError,
    fetchData,
    fetchMultiple,
    fetchDataWithReturn,
  };
};
