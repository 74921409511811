import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

const REACT_APP_COGNITO_APP_CLIENT_ID =
  process.env.REACT_APP_COGNITO_APP_CLIENT_ID;
const REACT_APP_COGNITO_USER_POOL_ID =
  process.env.REACT_APP_COGNITO_USER_POOL_ID;

export function authInCognito({ email, password }) {
  return new Promise((resolve, reject) => {
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const cognitoUser = getCognitoUser(email);
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: function (result) {
        resolve(result);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}

export function confirmRegister({ email, code }) {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUser(email);
    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    });
  });
}

export function resendConfirmation({ email }) {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUser(email);
    cognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        reject(err);
        return;
      }
      resolve(result);
    });
  });
}

export function forgotPwd({ email }) {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUser(email);

    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        resolve(data);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}

export function confirmPwd({ email, code, newpwd }) {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUser(email);
    cognitoUser.confirmPassword(code, newpwd, {
      onSuccess: function (data) {
        resolve(data);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}

export function register({ email, password }) {
  return new Promise((resolve, reject) => {
    const userPool = new CognitoUserPool({
      ClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
      UserPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    });
    const attributeEmail = new CognitoUserAttribute({
      Name: "email",
      Value: email,
    });

    userPool.signUp(
      email,
      password,
      [attributeEmail],
      null,
      function (err, result) {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      }
    );
  });
}
function getCognitoUser(email) {
  const userPool = new CognitoUserPool({
    ClientId: REACT_APP_COGNITO_APP_CLIENT_ID,
    UserPoolId: REACT_APP_COGNITO_USER_POOL_ID,
  });

  const cognitoUser = new CognitoUser({
    Pool: userPool,
    Username: email,
  });
  return cognitoUser;
}

export function getErrorCode(code) {
  const errorCode = errorCodes[code];
  return errorCode ? errorCode : "default-error";
}

export function verifySession(uuidUser) {
  return new Promise((resolve, reject) => {
    const user = getCognitoUser(uuidUser);
    user.getSession((err, sess) => {
      if (err) {
        console.error("Error al comprobar la sesion en cognito " + err);
        reject(err);
      } else {
        if (sess.isValid()) {
          resolve(sess);
        } else {
          user.refreshSession(sess.getRefreshToken(), (err, res) => {
            if (err) {
              console.err("Error al refrescar la sesion " + err);
              reject(err);
            } else {
              console.log("Session refrescada " + res);
              resolve(res);
            }
          });
        }
      }
    });
  });
}

const errorCodes = {
  NotAuthorizedException: "NotAuthorizedException",
  UsernameExistsException: "user-already-exists",
  InvalidPasswordException: "password-policy",
  UserNotConfirmedException: "user-not-confirmed",
  ExpiredCodeException: "expired-recovery-pwd",
  LimitExceededException: "limit-exceeded",
};
