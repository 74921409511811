import { Chip } from "@scrapadev/scrapad-front-sdk";
import Avatar from "../../../components/ui/avatar/Avatar";
import Text from "../../../components/ui/text/Text";
import styles from "./Users.module.scss";
import defaultAvatar from "../../../assets/img/icons/no-avatar-small.jpg";

/**
 * Users component for business page.
 * @param {Object} props - Component properties.
 * @param {String} props.avatar - User avatar.
 * @param {String} props.userName - User name.
 * @param {Boolean} [props.isAdmin=false] - Shows admin chip.
 * @returns {JSX.Element}
 */
const BusinessUser = ({ avatar, userName, isAdmin = false }) => {
  return (
    <div className={styles["sc-business--users"]}>
      <Avatar
        src={avatar || defaultAvatar}
        style={{ marginRight: 20 }}
        width={34}
        height={34}
      />
      <Text>{userName}</Text>
      {isAdmin && <Chip text="Admin" type={"info"} />}
    </div>
  );
};

export default BusinessUser;
