import InputCountries from "../../../components/ui/input/country/Country";
import Label from "../../../components/ui/input/label/Label";
import { useTranslation } from "../../../utils/hooks";
import { dispatchAction } from "./functions";

/**
 * Helper component for address countries.
 * @param {Object} props - Component properties.
 * @param {Object} props.address - Address info.
 * @param {number} props.index - Index of current address.
 * @param {Object} props.state - Addresses state.
 * @param {Function} props.dispatch - Dispatch function.
 * @returns {JSX.Element}
 */
const WidgetAddressCountries = ({
  address,
  index,
  state,
  dispatch,
  permissionManage,
  ...props
}) => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      <Label>
        {t("country")} {props.required && props.skipRequiredHelp ? "*" : ""}
      </Label>
      <InputCountries
        handleItemClick={(option) => {
          const countryId = option.uuid.split("-")[0];
          dispatchAction(
            index,
            "countryCode",
            parseInt(countryId),
            state,
            dispatch
          );
        }}
        value={address?.["countryCode"]}
        readOnly={permissionManage ? !permissionManage() : false}
        skipRequiredHelp
        {...props}
      />
    </>
  );
};

export default WidgetAddressCountries;
