import React, {
  useContext,
  useReducer,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { actions } from "./actions";

const SortContext = React.createContext({
  state: null,
  dispatch: null,
});

const getInitialState = () => {
  return {};
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.CLEAN_SORT:
      return {};
    case actions.SET_SORT:
      const newState = { ...state };
      if (action?.payload?.value === "none") {
        delete newState[action?.payload?.field];
      } else {
        newState[action?.payload?.field] = action?.payload?.value;
      }
      return newState;
    default:
      return state;
  }
};

const sortKey = "sort";

export const SortProvider = ({ storageKey, keysMerged = false, children }) => {
  const stored = keysMerged
    ? JSON.parse(localStorage.getItem(sortKey))?.[storageKey]
    : JSON.parse(localStorage.getItem(storageKey));
  const [state, dispatch] = useReducer(
    reducer,
    stored ? stored : getInitialState()
  );
  const sortChanged = useRef(false);

  useEffect(() => {
    let savedFilters = {};
    const keys = Object.keys(state || {});
    keys.forEach((key) => {
      savedFilters[key] = state[key];
    });
    if (storageKey) {
      if (keysMerged) {
        if (!localStorage.getItem(sortKey)) {
          localStorage.setItem(sortKey, JSON.stringify({}));
        }
        const sort = JSON.parse(localStorage.getItem(sortKey));
        sort[storageKey] = savedFilters;
        localStorage.setItem(sortKey, JSON.stringify(sort));
      } else {
        localStorage.setItem(storageKey, JSON.stringify(savedFilters));
      }
    }
  }, [state]);

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
      actions,
      storageKey,
      sortChanged,
      handleSorting: (field, type) => {
        sortChanged.current = true;
        let derivedType = "none";
        switch (type) {
          case "none":
            derivedType = "asc";
            break;
          case "asc":
            derivedType = "desc";
            break;
          case "desc":
          default:
            derivedType = "none";
            break;
        }
        dispatch({
          type: actions.SET_SORT,
          payload: { field: field?.ref, value: derivedType },
        });
      },
      cleanSort: () => {
        dispatch({
          type: actions.CLEAN_SORT,
        });
        sortChanged.current = true;
      },
      buildSort: (state) => {
        if (!state) return;
        return Object.entries(state)
          .map(([field, value]) => ({
            field,
            value,
          }))
          .filter((f) => f?.value !== "none");
      },
    }),
    [state, dispatch, storageKey]
  );

  return (
    <SortContext.Provider value={contextValue}>{children}</SortContext.Provider>
  );
};

export const useSortProvider = () => useContext(SortContext);
