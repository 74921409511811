import {
  ClearButton,
  Icon,
  WARM_NEUTRAL_50,
} from "@scrapadev/scrapad-front-sdk";
import styles from "./Item.module.scss";
import { imageFilePattern } from "../../file-upload/FileUpload";

const FileItem = ({
  dataAdapter,
  file,
  handleDelete,
  containerStyle = {},
  buttonStyle = {},
  iconStyle = {},
  actionsComponent,
}) => {
  const f = dataAdapter ? dataAdapter(file) : file;

  const openFile = (url) => {
    window.open(url, "_blank").focus();
  };

  const printIcon = (fileName) => {
    if (imageFilePattern.test(fileName)) {
      return <Icon name="image" color={WARM_NEUTRAL_50} />;
    }

    return <Icon name="insert_drive_file" color={WARM_NEUTRAL_50} />;
  };

  return (
    <div
      key={`${f?.uuid}`}
      className={styles["sc-files--file"]}
      style={containerStyle}
    >
      {printIcon(f?.name)}

      <span
        style={{ cursor: "pointer", ...containerStyle }}
        onClick={() => openFile(f.url)}
      >
        {f?.name}
      </span>
      <div className={styles["actions"]}>
        {actionsComponent}
        <ClearButton
          type="button"
          onClick={() => {
            if (handleDelete) handleDelete();
          }}
          style={{
            cursor: handleDelete ? "pointer" : "default",
            ...buttonStyle,
          }}
        >
          <Icon
            name={"clear"}
            color={"#7E7E7E"}
            style={{ fontSize: "1.25rem", ...iconStyle }}
          />
        </ClearButton>
      </div>
    </div>
  );
};

export default FileItem;

