import { ClearButton } from "@scrapadev/scrapad-front-sdk";
import { useNavigate } from "react-router-dom";
import Icon from "../icon/Icon";
import styles from "./GoBack.module.scss";
import { useTranslation } from "../../../utils/hooks";

/**
 * UI Go Back component.
 * @returns {JSX.Element}
 */
const GoBack = ({ onlyText = true, customNavigate, style = {} }) => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  return (
    <div className={styles["sc-back"]} style={style}>
      <ClearButton
        type="button"
        onClick={() => (customNavigate ? customNavigate() : navigate(-1))}
      >
        <Icon name={"arrow_back"} color={"#9E9E9E"} />
        {onlyText && <p>{t("back")}</p>}
      </ClearButton>
    </div>
  );
};

export default GoBack;

