const MiniImage = ({
  src,
  width = 50,
  height = 50,
  style = {},
  containerStyle = {},
}) => {
  if (!src) return <></>;

  return (
    <div style={containerStyle}>
      <img
        src={src}
        width={width}
        height={height}
        style={{
          borderRadius: 5,
          minWidth: width,
          ...style,
        }}
        data-cy="mini-image"
      />
    </div>
  );
};

export default MiniImage;
