import {
  BREAKPOINT_LG,
  Container,
  Loading,
  toastError,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "../../utils/hooks";
import { useEffect, useState } from "react";
import { useSetDataUser, useSignOut, useUser } from "../../providers/AuthHooks";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { QUERY_UPPER_LIMIT, tableModules } from "../../utils/variables";
import { useDocumentTitle } from "../../utils/document/hooks";
import NavBar from "../../components/ui/navbar/NavBar";
import LayoutDetail from "../../layout/detail/Detail";
import TransactionHeader from "./header/TransactionHeader";
import TransactionDetailHeader from "./header/Detail";
import TransactionSteps from "../../widgets/transactions/steps/Steps";
import LayoutTable from "../../layout/table/Table";
import { getTable } from "../../model/tables";
import { gqlWrapper } from "../../utils/gqlwrapper";
import {
  getTransactionDataBO,
  getTransactionDetail,
} from "../../model/transactions";
import QuotationRequestForm from "./forms/QuotationRequestForm";

const TransactionQuotationNewV2 = () => {
  const { id } = useParams();
  const { t, i18n, values } = useTranslation([
    "common",
    "transactions",
    "finances",
    "ads",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const signOut = useSignOut();
  const navigate = useNavigate();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const user = useUser();
  const setDataUser = useSetDataUser();
  const [data, setData] = useState([]);
  const [addQuotationOpen, setAddQuotationOpen] = useState(false);
  const [editRouteOpen, setEditRouteOpen] = useState(false);
  const [editLoadCharacteristics, setEditLoadCharacteristics] = useState(false);
  const {
    canManageTransaction,
    canReadLogistics,
    canReadFinances,
    canManageLogistics,
    canManageTransactionMessages,
    canManageTransactionSamplesVerificationTransport,
    canManageTransactionReturn,
    canReadTransactionMessages,
    canReadTransactionSamplesVerificationTransport,
    canManageFinances,
  } = useOperationsProvider();

  const tableParams = {
    locale: i18n.language,
    offset: 0,
    limit: QUERY_UPPER_LIMIT,
    tableModule: tableModules.QUOTATIONS,
    filter: {
      ops: [
        {
          field: "uuidTransaction",
          value: id,
        },
      ],
    },
  };

  useEffect(() => {
    fetchData();
  }, [i18n.language]);

  const fetchData = async (quiet = false) => {
    if (!quiet) setIsLoading(true);
    try {
      // const fn = await gqlWrapper(getTransactionDetail, setDataUser, signOut);
      const fn = await gqlWrapper(getTransactionDataBO, setDataUser, signOut);
      const results = await fn(i18n.language, id);
      if (results === null) {
        setData([]);
      } else {
        if (results?.errors?.length > 0) {
          setData([]);
          toastError(results?.errors[0]?.message);
        } else {
          setData(results);
        }
      }
    } catch (e) {
      setData([]);
      toastError(e.message || e);
    } finally {
      if (!quiet) setIsLoading(false);
    }
  };

  const subtitle = `ID ${data?.header?.uuidConversation?.split("-")?.[0]}`;
  useDocumentTitle(`${t("transaction")} ${id.split("-")?.[0]}`, values);

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerWithContainer
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <TransactionHeader
                      goBack
                      imageUrl={data?.sideData?.originAd?.image}
                      breadcrumbs={[
                        {
                          description: t("transactions"),
                          onClick: () =>
                            navigate("/transactions", {
                              replace: true,
                            }),
                        },
                        { description: data?.header?.adTitle },
                      ]}
                      title={data?.header?.adTitle}
                      subtitle={subtitle}
                      rightArea={
                        <TransactionDetailHeader
                          uuidEvent={id}
                          title={data?.header?.adTitle}
                          subtitle={subtitle}
                          fetchData={fetchData}
                          permission={canManageTransaction}
                          permissionLogistics={canReadLogistics}
                          onlyMenu
                        />
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <>
            <Container style={{ marginBottom: "10px" }}>
              <TransactionSteps id={id} currentStep={"quotations"} />
            </Container>
            <LayoutTable fnData={getTable} fnParameters={tableParams}>
              <InnerTransactionQuotation />
            </LayoutTable>
          </>
        }
      />
    </>
  );
};

const InnerTransactionQuotation = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(
    ["common", "transactions", "logistics"],
    true
  );
  //useDocumentTitle(`${t("transport-quotes")} ${id.split("-")?.[0]}`);

  return (
    <>
      <Container>
        <QuotationRequestForm
          onBack={() => {
            navigate(-1);
          }}
        />
      </Container>
    </>
  );
};

export default TransactionQuotationNewV2;
