import { Icon } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import styles from "./AdStat.module.scss";

function AdStat({ icon = "", value = 0, style = {} }) {
  return (
    <div className={`${styles["sc-ad-stat-outter"]}`} style={style}>
      <div className={`${styles["sc-ad-stat"]}`}>
        <div className={`${styles["sc-ad-stat__icon"]}`}>
          <Icon name={icon} style={{ fontSize: 18 }} />
        </div>
        <div className={`${styles["sc-ad-stat__value"]}`}>{value}</div>
      </div>
    </div>
  );
}

export default AdStat;
