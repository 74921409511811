import React from "react";
import styles from "./FormWrap.module.scss";

export default function FormWrap({ children, disabled = false }) {
  return (
    <div
      className={`${styles["form-wrap"]} ${disabled ? styles["disabled"] : ""}`}
    >
      {children}
    </div>
  );
}

