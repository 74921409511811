import { Flex, IconItem, Loading, Spacer } from "@scrapadev/scrapad-front-sdk";
import { useEffect, useRef } from "react";
import { getOpportunitiesCostsForTransaction } from "../../../model/opportunities";
import { useOperationsProvider } from "../../../providers/organization/OperationsContext";
import { checkPermission } from "../../../utils/functions";
import { useTranslation } from "../../../utils/hooks";
import {
  costValuesWithDefaults,
  getFetchData,
  perceiveDepositWithDefaults,
} from "../../../utils/opportunities/functions";
import { INFO_COLOR } from "../../../utils/variables";
import WidgetDataWrapper from "../../wrappers/DataWrapper";
import WidgetOpportunitiesEmpty from "../../opportunities/OpportunitiesEmpty";
import WidgetOpportunitiesCostsReadOnly from "../../opportunities/OpportunitiesCostsRead";
import WidgetOpportunitiesTotals from "../../opportunities/OpportunitiesTotal";
import WidgetOpportunitiesCostsForm from "../../opportunities/OpportunitiesCostForm";
import CostBreakdown from "./CostBreakdown";

/**
 * Opportunities costs form.
 * @param {object} props - Function properties.
 * @param {string} props.id - Opportunity id.
 * @param {object} props.state - Costs current state.
 * @param {Function} props.dispatch - Widget provider dispatch.
 * @param {object} props.actions - Widget provider actions.
 * @param {boolean} props.loading - Widget loading state.
 * @param {Function} props.setLoading - Loading setter.
 * @param {Function} props.fetchData - Fetching function.
 * @returns {JSX.Element}
 */
const InnerWidgetOpportunitiesCosts = ({
  id,
  state,
  dispatch,
  fetchData,
  loading,
  setLoading,
  actions,
  opportunityId,
}) => {
  const fields = Object.values(state.fields || []);
  const { t } = useTranslation(["common"]);
  const { canReadFinances, canReadLogistics } = useOperationsProvider();
  const permission =
    checkPermission(canReadFinances) || checkPermission(canReadLogistics);
  const firstLoaded = useRef(false);
  const prevLoading = useRef(loading);

  useEffect(() => {
    firstLoaded.current = false;
  }, [id]);

  // Here we calculate when its first loading to separate from cost blur loading.
  useEffect(() => {
    if (prevLoading.current && !loading && !firstLoaded.current) {
      firstLoaded.current = true;
    }
    prevLoading.current = loading;
  }, [loading]);

  const handleUpdate = async () => {
    setLoading(true);
    fetchData(false, getFetchData(true, id));
  };

  const renderHead = (child) => {
    return (
      <Flex
        flexDirection={"column"}
        alignItems={"flex-start"}
        style={{ width: "100%", paddingLeft: 5, paddingRight: 5 }}
      >
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{ width: "100%" }}
        >
          <Flex style={{ height: 25, marginLeft: "auto" }}>
            {loading ? <Loading /> : <></>}
            <IconItem
              name={"refresh"}
              text={loading ? t("updating") : t("update")}
              onClick={handleUpdate}
              buttonStyle={{ color: INFO_COLOR }}
              textStyle={{
                color: INFO_COLOR,
                fontSize: 13,
                fontWeight: 600,
                lineHeight: "25px",
                textDecoration: "underline",
                marginLeft: -10,
              }}
            />
          </Flex>
        </Flex>
        {child}
      </Flex>
    );
  };

  if (loading && !firstLoaded.current) {
    return <Loading />;
  }

  if (fields.length === 0) {
    return <WidgetOpportunitiesEmpty />;
  }

  if (!permission) {
    return (
      <>
        {renderHead(
          <>
            <Spacer />
            <WidgetOpportunitiesCostsReadOnly
              state={state}
              opportunityId={opportunityId}
              title={t("costs-sheet")}
            />
            <Spacer />
            <WidgetOpportunitiesTotals
              state={state}
              opportunityId={opportunityId}
            />
          </>
        )}
      </>
    );
  }

  return renderHead(
    <>
      <Spacer />
      <WidgetOpportunitiesCostsForm
        id={id}
        state={state}
        dispatch={dispatch}
        actions={actions}
        loading={loading}
        setLoading={setLoading}
        fetchData={fetchData}
        opportunityId={opportunityId}
        title={t("costs-sheet")}
      />
      <Spacer />
      <CostBreakdown
        id={id}
        offerData={state?.offerData}
        fetchData={fetchData}
      />
    </>
  );
};

const WidgetOpportunitiesCostsTransaction = ({ opportunityId, uuid }) => {
  if (!uuid) return <></>;
  return (
    <div style={{ width: "100%" }}>
      <WidgetDataWrapper
        id={uuid}
        Component={InnerWidgetOpportunitiesCosts}
        fnData={getOpportunitiesCostsForTransaction}
        fnParameters={getFetchData(true, uuid)}
        delegateLoading
        opportunityId={opportunityId}
        dataAdapter={(data) => {
          const parsedData = {
            logistics: data?.statusCosts?.logistic || false,
            finances: data?.statusCosts?.finance || false,
            fields: data.costs
              ? data.costs.map((field, index) => {
                  const values = costValuesWithDefaults(field);
                  return {
                    ...values,
                  };
                })
              : [],
            margin: data?.netMargin || 0,
            toPerceive: perceiveDepositWithDefaults(data.toPercive),
            toDeposit: perceiveDepositWithDefaults(data.toDeposit),
            offerData: data?.offerData,
            currency: "€",
          };
          return parsedData;
        }}
      />
    </div>
  );
};

export default WidgetOpportunitiesCostsTransaction;
