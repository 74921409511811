import { Flex, UnderlinedIconButton } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import ServiceItem from "./ServiceItem";
import { BLUE_COLOR } from "../../../../utils/variables";
import { useTranslation } from "../../../../utils/hooks";

function QuotationServicesForm({
  state = {},
  handleServiceOnChange = () => {},
  handleRemoveService = () => {},
  handleAddService = () => {},
}) {
  const { t } = useTranslation("common");
  return (
    <Flex flexDirection="column" alignItems="stretch" style={{ gap: "10px" }}>
      {state?.services?.map((service, index) => (
        <ServiceItem
          index={index}
          service={service}
          onChange={handleServiceOnChange}
          onDelete={
            state?.services.length > 1 ? handleRemoveService : undefined
          }
        />
      ))}

      <UnderlinedIconButton
        iconColor={BLUE_COLOR}
        iconName={"add_circle_outline"}
        text={t("add_more_services")}
        textStyles={{ color: BLUE_COLOR, fontWeight: 600 }}
        onClick={() => {
          handleAddService();
        }}
        buttonStyles={{
          marginTop: "15px",
        }}
      />
    </Flex>
  );
}

export default QuotationServicesForm;
