import React, { useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import {
  BREAKPOINT_LG,
  Modal,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { handleCRUD } from "../../../utils/crud/functions";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import ModalText from "../../ui/modal/text/ModalText";

function ModalDeleteOffer({
  open = false,
  opportunityId,
  uuid,
  setOpen = () => {},
  fetchData = () => {},
  ...props
}) {
  const { t } = useTranslation(["common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const user = useUser();
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e?.preventDefault();
    setLoading(true);
    await handleCRUD({
      endpoint: `${process.env.REACT_APP_API_EVENTS}`,
      method: "POST",
      external: true,
      data: {
        slug: "CANCEL_OFFER_OPPORTUNITY",
        uuidUser: user?.userUuid,
        //uuidEvent: opportunityId,
        //uuidOfferOpportunity: uuid,
        uuidEvent: uuid,
      },
      successCallback: (e) => {
        fetchData();
        setLoading(false);
        setOpen(false);
      },
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading: setLoading,
      errorCallback: () => {
        setLoading(false);
        setOpen(false);
      },
    });
  };

  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("sure-delete-opportunity_offer")}
      hasForm={true}
      loading={loading}
      onSubmit={handleSubmit}
      submitText={t("close-offer")}
      cancelText={t("cancel")}
      submitButtonMode={"info"}
      reverseActions
      maxWidth={800}
      modalStyles={{ width: matches ? "auto" : 800 }}
      BodyComponent={
        <>
          <ModalText text={`${t("sure-delete-opportunity_offer_desc")}.`} />
        </>
      }
    />
  );
}

export default ModalDeleteOffer;
