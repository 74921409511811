import { Chip, ChipDropdown, ClearButton } from "@scrapadev/scrapad-front-sdk";
import { actions } from "../reducer/reducer";

const NotificationsFilterType = ({ data, dispatchData }) => {
  const handleTypeFilter = (item) => () => {
    const isAlreadySelected = data.filters.type.selected.find(
      (f) => f.slug === item.slug
    );
    if (!isAlreadySelected) {
      dispatchData({ type: actions.ADD_FILTER_TYPE, payload: item });
    }
  };

  const handleDeleteTypeFilter = (item) => () => {
    dispatchData({ type: actions.DELETE_FILTER_TYPE, payload: item });
  };

  return (
    <>
      <ChipDropdown
        text={data.filters.type.current}
        items={data.filters.type.options.map((item, index) => (
          <ClearButton key={index} onClick={handleTypeFilter(item)}>
            {item.label}
          </ClearButton>
        ))}
        style={{ backgroundColor: "#E1E1E1", border: "0.5px solid #E1E1E1" }}
      />
      {data.filters.type.selected.map((selected) => (
        <Chip
          text={selected.label}
          handleClick={handleDeleteTypeFilter(selected)}
          showIcon
          type="data"
        />
      ))}
    </>
  );
};

export default NotificationsFilterType;
