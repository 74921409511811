export const actions = {
  INITIAL_LOAD: "INITIAL_LOAD",
  ADD_FILTER: "ADD_FILTER",
  ADD_UNIQUE_FILTER: "ADD_UNIQUE_FILTER",
  ADD_MULTIPLE_FILTER: "ADD_MULTIPLE_FILTER",
  ADD_SINGLE_FILTER: "ADD_SINGLE_FILTER",
  REMOVE_FILTER: "REMOVE_FILTER",
  REMOVE_MULTIPLE_FILTER: "REMOVE_MULTIPLE_FILTER",
  CLEAR_FILTER: "CLEAR_FILTER",
  UPDATE_WHOLE_FILTER: "UPDATE_WHOLE_FILTER",
  UPDATE_INPUT: "UPDATE_INPUT",
  CLEAR_INPUT: "CLEAR_INPUT",
};
