/* eslint-disable no-unused-vars */
import styles from "./AdsFile.module.scss";
import { useEffect, useState } from "react";
import { Icon, Loading, toastError } from "@scrapadev/scrapad-front-sdk";
import { uploadFile } from "../../utils/functions";
import { MAX_UPLOAD_SIZE } from "../../utils/variables";

const AdFiles = ({
  files,
  multiple,
  dropPlaceholder,
  placeholder,
  afterUpload,
  onChange,
  iconStyles = {},
  type,
  t,
  ...props
}) => {
  const [hovered, setHovered] = useState(false);
  const [uploading, setUploading] = useState(false);
  useEffect(() => {
    if (files && files.length === 0 && hovered) {
      setHovered(false);
    }
  }, [files]);
  const handleDragOver = () => {
    if (!hovered) {
      setHovered(true);
    }
  };
  const handleUpload = async (e) => {
    setUploading(true);
    let parsedFiles = [];
    let error = false;
    for (let file of e.target.files) {
      if (file?.size > MAX_UPLOAD_SIZE) {
        toastError(t("file-too-big"));
        error = true;
      } else {
        const [uploadResponse, response2] = await uploadFile({
          file: file,
          type: type,
        });
        parsedFiles.push({
          name: file.name,
          uuid: `${uploadResponse.uuid_file}`,
          url: `${uploadResponse.location}`,
          cdn: `${uploadResponse.cdn}`,
        });
      }
    }

    setUploading(false);
    setHovered(false);

    if (!error && afterUpload) {
      afterUpload(parsedFiles);
    }

    if (!error && onChange) {
      onChange(e);
    }
  };
  const handleDragLeave = () => setHovered(false);
  return (
    <label
      className={`${styles["sc-file"]} ${
        hovered ? styles["sc-file--hover"] : ""
      } ${props.readOnly ? styles["sc-file--read"] : ""}`}
    >
      {!props.readOnly && (
        <input
          type="file"
          onChange={handleUpload}
          multiple={multiple}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          {...props}
        />
      )}
      <span className={styles["sc-file--icon"]}>
        <Icon
          name={"add"}
          color={"#9E9E9E"}
          style={{ fontSize: "1.25rem", ...iconStyles }}
        />
      </span>
      <span className={styles["sc-file--label"]}>
        {hovered ? dropPlaceholder : placeholder}
      </span>
      {uploading && (
        <span className={styles["loading"]}>
          <Loading />
        </span>
      )}
    </label>
  );
};

export default AdFiles;
