import React from "react";
import styles from "./ProviderCard.module.scss";
import { Icon, WARM_NEUTRAL_50 } from "@scrapadev/scrapad-front-sdk";

function ProviderCard({ name, email }) {
  return (
    <div className={styles["provider_card"]}>
      {name && <p className={styles["name"]}>{name}</p>}
      {email && (
        <a
          target="_blank"
          href={`mailto:${email}`}
          className={styles["email"]}
          rel="noreferrer"
        >
          <Icon
            name={"mail"}
            color={WARM_NEUTRAL_50}
            style={{
              fontSize: "16px",
            }}
          />{" "}
          {email}
        </a>
      )}
    </div>
  );
}

export default ProviderCard;

