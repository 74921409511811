import React, { useEffect, useRef, useState } from "react";
import Loading from "../../components/ui/loading/Loading";
import { actions } from "../../providers/data/actions/data";
import {
  DataProvider,
  useDataProvider,
} from "../../providers/data/DataContext";
import { checkIfStateIsDirty } from "../../utils/functions";
import { useInterceptionObserver, useTranslation } from "../../utils/hooks";
import { gqlWrapper } from "../../utils/gqlwrapper";
import { useSetDataUser, useSignOut } from "../../providers/AuthHooks";
import { Center, Typography } from "@scrapadev/scrapad-front-sdk";

/**
 * Wrapper for data widgets
 * @param {Object} props - Component properties.
 * @param {String} props.id - Organization id.
 * @param {Function} props.fnData - Fetcher function.
 * @param {Function} props.parentCallback - Callback from parent passed to children.
 * @param {Function} props.updateStateCallback - Callback when wrapper state changes.
 * @param {React.Component} props.Component - Component to render.
 * @param {Funtion} props.dataAdapter - Adapter between data received and state.
 * @returns {JSX.Element}
 */
const InnerComponent = ({
  id,
  fnData,
  fnParameters,
  parentCallback,
  updateStateCallback,
  Component,
  dataAdapter,
  extraData,
  delegateLoading,
  customCompareFunction,
  extraProps,
}) => {
  const { t } = useTranslation(["common"], true);
  const { state, dispatch } = useDataProvider();
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const targetRef = useRef(null);
  const isAlreadyFetched = useRef(false);
  const bufferedData = useRef(false);
  const bufferState = useRef({});
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const { observer } = useInterceptionObserver(
    targetRef.current,
    (entries, observer) => {
      const entry = entries[0];
      if (!entry) return;
      if (entry.isIntersecting && !isAlreadyFetched.current) {
        isAlreadyFetched.current = true;
        setLoading(true);
        if (Array.isArray(fnData)) {
          fetchMultiple();
        } else {
          fetchData();
        }
      }
    }
  );

  useEffect(() => {
    if (bufferedData.current) {
      const dirty = customCompareFunction
        ? customCompareFunction(state, bufferState.current, checkIfStateIsDirty)
        : checkIfStateIsDirty(state, bufferState.current);
      setIsDirty(dirty);
      if (updateStateCallback) {
        updateStateCallback(state, dirty, loading, setLoading, fetchData);
      }
    }
  }, [state]);

  const fetchMultiple = async () => {
    if (!fnData || fnData.length === 0) {
      setLoading(false);
      return;
    }
    let responses;
    try {
      responses = await Promise.all(
        fnData.map(async (fn, index) => {
          const f = await gqlWrapper(fn, setDataUser, signOut);
          return fnParameters
            ? await f(
                Array.isArray(fnParameters) ? fnParameters[index] : fnParameters
              )
            : await f();
        })
      );
      const data = { data: responses };
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: dataAdapter ? dataAdapter(data) : data,
      });
    } catch (e) {
      setError(t("default-error"));
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (skipBuffer = false, customParams, delegate) => {
    if (!fnData) {
      setLoading(false);
      return;
    }
    const fn = await gqlWrapper(fnData, setDataUser, signOut);
    try {
      const response = customParams
        ? await fn(customParams)
        : fnParameters
        ? await fn(fnParameters)
        : await fn();
      if (!skipBuffer) {
        bufferState.current = response;
        bufferedData.current = true;
      }
      if (!delegate) {
        dispatch({
          type: actions.INITIAL_LOAD,
          payload: dataAdapter ? dataAdapter(response) : response,
        });
      }
      return response;
    } catch (e) {
      console.log(e);
      setError(t("default-error"));
    } finally {
      setLoading(false);
    }
  };

  const handleParentCallback = () => {
    if (parentCallback) parentCallback(state, isDirty);
  };

  const handleDiscard = () => {
    dispatch({ type: actions.INITIAL_LOAD, payload: bufferState.current });
  };

  const cmp = React.cloneElement(<Component />, {
    id,
    loading,
    setLoading,
    state,
    dispatch,
    actions,
    bufferState,
    isDirty,
    setIsDirty,
    isAlreadyFetched,
    bufferedData,
    handleDiscard,
    handleParentCallback,
    extraData,
    fetchData,
    checkIfStateIsDirty,
    ...extraProps,
  });

  if (delegateLoading)
    return (
      <div ref={targetRef} className="data-wrapper--ref">
        {cmp}
      </div>
    );

  return (
    <div ref={targetRef} className="data-wrapper--ref">
      {loading ? (
        <Loading />
      ) : error ? (
        <div style={{ height: "100%" }}>
          <Center>
            <Typography>{error}</Typography>
          </Center>
        </div>
      ) : (
        cmp
      )}
    </div>
  );
};

const WidgetDataWrapper = ({
  id,
  fnData,
  fnParameters,
  parentCallback,
  updateStateCallback,
  Component,
  dataAdapter,
  extraData,
  delegateLoading,
  customCompareFunction,
  ...props
}) => {
  return (
    <DataProvider>
      <InnerComponent
        id={id}
        fnData={fnData}
        fnParameters={fnParameters}
        parentCallback={parentCallback}
        updateStateCallback={updateStateCallback}
        Component={Component}
        dataAdapter={dataAdapter}
        extraData={extraData}
        delegateLoading={delegateLoading}
        customCompareFunction={customCompareFunction}
        extraProps={props}
      />
    </DataProvider>
  );
};

export default WidgetDataWrapper;
