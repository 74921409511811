import styles from "./Heading.module.scss";

/**
 * Heading for business pages.
 * @param {Object} props - Component properties.
 * @param {Array} props.title - Title.
 * @returns {JSX.Element}
 */
const ItemHeading = ({ title, rightComponent, children }) => {
  return (
    <div className={styles["sc-item--heading"]}>
      <div className={styles["sc-item--head"]}>
        <h3 className={styles["sc-item--title"]}>{title}</h3>
        {rightComponent && rightComponent}
      </div>
      {children}
    </div>
  );
};

export default ItemHeading;
