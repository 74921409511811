import React, { useContext, useReducer, useMemo, useEffect } from "react";
import { documentActions } from "./actions/DocumentsActions";
import DocumentModalFactory, {
  documentModalTypes,
} from "./modal/DocumentModalFactory";
import { gqlWrapper } from "../../utils/gqlwrapper";
import {
  getDocumentDownloadLink,
  getDocumentSearchByTerm,
  getItemDetails,
  getSingleFolderDetails,
} from "../../model/documents";
import { useSetDataUser, useSignOut } from "../AuthHooks";
import _ from "lodash";
import { toastSuccess } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";
import { sleep } from "../../utils/functions";

const DocumentsContext = React.createContext({
  state: null,
  dispatch: null,
});

const getInitialState = (data = []) => {
  return {
    transactionUuid: undefined,
    data: data,
    dataVisible: data,
    bulkSelected: [],
    files: [],
    filters: {},
    filterTypes: [
      {
        component: "Contrato de compraventa",
      },
      {
        component: "Factura",
      },
      {
        component: "Packing list",
      },
    ],
    actionOpenedType: undefined,
    selected: undefined,
    modalProps: {},
    detailPanelUuid: undefined,
    loading: true,
    breadcrumbs: [],
    downloading: false,
    sorting: undefined,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case documentActions.INITIAL_LOAD:
      return {
        ...state,
        data: action?.payload?.data || [],
        dataVisible: action?.payload?.data || [],
        transactionUuid: action?.payload?.transactionUuid,
        bulkSelected: [],
        files: [],
        filters: {},
        filterTypes: [
          {
            component: "Contrato de compraventa",
          },
          {
            component: "Factura",
          },
          {
            component: "Packing list",
          },
        ],
        actionOpenedType: undefined,
        selected: undefined,
        modalProps: {},
        detailPanelUuid: undefined,
        loading: false,
        breadcrumbs: [],
      };
    case documentActions.MANAGE_ACCESS_OPEN:
      return {
        ...state,
        ...{
          actionOpenedType: documentModalTypes.MANAGE_ACCESS,
        },
      };
    case documentActions.MANAGE_ACCESS_CLOSE:
      return {
        ...state,
        ...{
          actionOpenedType: undefined,
        },
      };
    case documentActions.MOVE_OPEN:
      return {
        ...state,
        ...{
          actionOpenedType: documentModalTypes.MOVE,
          modalProps: {
            ...action.payload,
            bulkSelected: state?.bulkSelected,
            filesTree: state?.dataVisible,
          },
        },
      };
    case documentActions.MOVE_CLOSE:
      return {
        ...state,
        ...{
          actionOpenedType: undefined,
        },
      };
    case documentActions.SHARE_OPEN:
      return {
        ...state,
        ...{
          actionOpenedType: documentModalTypes.SHARE,
          modalProps: {
            ...action.payload,
            bulkSelected: state?.bulkSelected,
            filesTree: state?.dataVisible,
          },
        },
      };
    case documentActions.SHARE_CLOSE:
      return {
        ...state,
        ...{
          actionOpenedType: undefined,
        },
      };
    case documentActions.SET_SELECTED_FILES:
      if (state.bulkSelected.includes(action?.payload?.uuid)) {
        return {
          ...state,
          bulkSelected: state.bulkSelected.filter(
            (e) => e !== action?.payload?.uuid
          ),
        };
      }
      return {
        ...state,
        bulkSelected: [...state.bulkSelected, action?.payload?.uuid],
      };
    case documentActions.CLEAN_SELECTED_FILES:
      return {
        ...state,
        bulkSelected: [],
      };
    case documentActions.UPLOAD_FILE_OPEN:
      return {
        ...state,
        ...{
          actionOpenedType: documentModalTypes.UPLOAD_FILES,
        },
      };
    case documentActions.UPLOAD_FILE_CLOSE:
      return {
        ...state,
        ...{
          actionOpenedType: undefined,
        },
      };
    case documentActions.MODAL_CLOSE:
      return {
        ...state,
        ...{
          actionOpenedType: undefined,
        },
      };
    case documentActions.DETAIL_PANEL_OPEN:
      return {
        ...state,
        ...{
          detailPanelUuid: action?.payload?.uuid,
        },
      };
    case documentActions.DETAIL_PANEL_CLOSE:
      return {
        ...state,
        ...{
          detailPanelUuid: undefined,
        },
      };
    case documentActions.LOAD_DATA:
      return {
        ...state,
        data: action?.payload?.data || [],
        loading: false,
      };
    case documentActions.LOADING_SET:
      return {
        ...state,
        loading: true,
      };
    case documentActions.LOADING_UNSET:
      return {
        ...state,
        loading: false,
      };
    case documentActions.BREADCRUMBS_SET:
      return {
        ...state,
        breadcrumbs: action?.payload?.breadcrumbs || [],
      };
    case documentActions.DATA_VISIBLE_SET:
      return {
        ...state,
        dataVisible: action?.payload.data,
        loading: false,
      };
    case documentActions.DOWNLOADING_SET:
      return {
        ...state,
        downloading: true,
      };
    case documentActions.DOWNLOADING_UNSET:
      return {
        ...state,
        downloading: false,
      };
    case documentActions.SORTING_SET:
      return {
        ...state,
        sorting: action.payload,
      };
    case documentActions.SORTING_UNSET:
      return {
        ...state,
        sorting: undefined,
      };
    case documentActions.RENAME_SET:
      return {
        ...state,
        ...{
          actionOpenedType: documentModalTypes.RENAME,
          modalProps: { ...action.payload },
        },
      };
    case documentActions.RENAME_UNSET:
      return {
        ...state,
        ...{
          actionOpenedType: undefined,
          modalProps: {},
        },
      };
    case documentActions.DELETE_FILE_SET:
      return {
        ...state,
        ...{
          actionOpenedType: documentModalTypes.DELETE,
          modalProps: { ...action.payload },
        },
      };
    case documentActions.DELETE_FILE_UNSET:
      return {
        ...state,
        ...{
          actionOpenedType: undefined,
          modalProps: {},
        },
      };
    default:
      return state;
  }
};

const finder = (id, folder) => {
  if (folder?.id === id) {
    return folder;
  }

  if (_.isEmpty(folder?.subfolders)) {
    return false;
  }

  for (let index = 0; index < folder?.subfolders?.length; index++) {
    const e = folder?.subfolders[index];
    const result = finder(id, e);
    if (result) {
      return result;
    }
  }
};

export const DocumentsProvider = ({ transactionUuid, data = [], children }) => {
  const { t } = useTranslation(["common"]);
  const [state, dispatch] = useReducer(reducer, getInitialState(data));
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();

  const contextValue = useMemo(() => {
    const fetchData = async (
      transactionUuid,
      itemId,
      finder = false,
      initial = false
    ) => {
      const fn = await gqlWrapper(getSingleFolderDetails, setDataUser, signOut);

      dispatch({
        type: documentActions.LOADING_SET,
      });
      const results = await fn(
        transactionUuid ? transactionUuid : state?.transactionUuid,
        itemId
      );
      dispatch({
        type: finder
          ? documentActions.DATA_VISIBLE_SET
          : initial
          ? documentActions.INITIAL_LOAD
          : documentActions.LOAD_DATA,
        payload: {
          data: results || [],
          transactionUuid: transactionUuid,
        },
      });
    };

    const reloadData = async (delay = 0) => {
      let itemId = undefined;

      try {
        if (!_.isEmpty(state?.breadcrumbs)) {
          itemId = state?.breadcrumbs[state?.breadcrumbs.length - 1]?.uuid;
        }
      } catch (error) {
        console.log(error);
      }
      dispatch({
        type: documentActions.LOADING_SET,
      });

      if (delay > 0) {
        await sleep(delay);
      }

      const fn = await gqlWrapper(getSingleFolderDetails, setDataUser, signOut);

      const results = await fn(
        transactionUuid ? transactionUuid : state?.transactionUuid,
        itemId
      );
      dispatch({
        type: documentActions.DATA_VISIBLE_SET,
        payload: {
          data: results,
          transactionUuid: transactionUuid,
        },
      });
    };

    const openFolder = async (uuid, owner) => {
      dispatch({
        type: documentActions.LOADING_SET,
      });

      try {
        const newBreadcrumbs = [
          ...state.breadcrumbs,
          {
            name: state?.dataVisible?.subfolders?.find((e) => e.id === uuid)
              ?.name,
            uuid: uuid,
            owner: owner,
          },
        ];

        dispatch({
          type: documentActions.BREADCRUMBS_SET,
          payload: {
            breadcrumbs: newBreadcrumbs,
          },
        });
      } catch (error) {
        console.log(error);
      }

      try {
        await fetchData("", uuid, true);
      } catch (error) {
        console.log(error);
        dispatch({
          type: documentActions.DATA_VISIBLE_SET,
          payload: {
            data: [],
          },
        });
      }

      dispatch({
        type: documentActions.LOADING_UNSET,
      });
    };

    const openFolderMove = async (uuid, owner) => {
      dispatch({
        type: documentActions.LOADING_SET,
      });

      try {
        const newBreadcrumbs = [
          ...state.breadcrumbs,
          {
            name: state?.dataVisible?.subfolders?.find((e) => e.id === uuid)
              ?.name,
            uuid: uuid,
            owner: owner,
          },
        ];

        dispatch({
          type: documentActions.BREADCRUMBS_SET,
          payload: {
            breadcrumbs: newBreadcrumbs,
          },
        });
      } catch (error) {
        console.log(error);
      }

      try {
        await fetchData("", uuid, true);
      } catch (error) {
        console.log(error);
        dispatch({
          type: documentActions.DATA_VISIBLE_SET,
          payload: {
            data: [],
          },
        });
      }

      dispatch({
        type: documentActions.LOADING_UNSET,
      });
    };

    const onBack = async (uuid) => {
      try {
        const { breadcrumbs } = state || {};

        if (!breadcrumbs || !uuid) return;

        const indexSelected = breadcrumbs.findIndex((e) => e.uuid === uuid);

        if (indexSelected === -1) return;

        const newBreadcrumbs = breadcrumbs.slice(0, indexSelected + 1);

        dispatch({
          type: documentActions.BREADCRUMBS_SET,
          payload: {
            breadcrumbs: newBreadcrumbs,
          },
        });
      } catch (error) {
        console.error("Error en onBack:", error);
      }

      await fetchData("", uuid, true);

      dispatch({
        type: documentActions.LOADING_UNSET,
      });
    };

    const openFile = async (url) => {
      if (_.isEmpty(url)) {
        return;
      }
      if (typeof window !== "undefined") {
        window.open(url, "_blank");
      }
    };

    const downloadLink = async (uuid) => {
      if (_.isEmpty(uuid)) {
        return;
      }

      const fn = await gqlWrapper(
        getDocumentDownloadLink,
        setDataUser,
        signOut
      );
      const results = await fn(uuid);
      if (typeof window !== "undefined") {
        window.open(results?.downloadLink, "_blank");
      }
    };

    const bulkDownload = async () => {
      if (_.isEmpty(state.bulkSelected)) {
        return;
      }

      state?.bulkSelected.forEach(async (uuid) => {
        await downloadLink(uuid);
      });

      dispatch({
        type: documentActions.DOWNLOADING_SET,
      });

      dispatch({
        type: documentActions.DOWNLOADING_UNSET,
      });
    };

    const copyLink = async (uuid) => {
      if (_.isEmpty(uuid)) {
        return;
      }

      const fn = await gqlWrapper(
        getDocumentDownloadLink,
        setDataUser,
        signOut
      );
      const results = await fn(uuid);
      if (typeof window !== "undefined") {
        navigator.clipboard.writeText(results?.downloadLink);
        toastSuccess(t("link_copied_succesfully"), undefined, "link");
      }
    };

    const searchFiles = async (term) => {
      if (_.isEmpty(term)) {
        return [];
      }

      const fn = await gqlWrapper(
        getDocumentSearchByTerm,
        setDataUser,
        signOut
      );
      const results = await fn({
        term: term,
        orderBy: "name",
        ascending: true,
      });
      return results;
    };

    const documentDetails = async (uuid) => {
      if (_.isEmpty(uuid)) {
        return {};
      }

      const fn = await gqlWrapper(getItemDetails, setDataUser, signOut);
      const result = await fn({
        itemId: uuid,
      });
      return result;
    };

    return {
      state,
      dispatch,
      fetchData,
      openFolder,
      onBack,
      openFile,
      downloadLink,
      searchFiles,
      copyLink,
      bulkDownload,
      documentDetails,
      reloadData,
    };
  }, [state, dispatch]);

  useEffect(() => {
    if (state?.transactionUuid !== transactionUuid) {
      contextValue.fetchData(transactionUuid, undefined, false, true);
    }
  }, [transactionUuid]);

  return (
    <DocumentsContext.Provider value={contextValue}>
      {children}
      <DocumentModalFactory
        open={state.actionOpenedType}
        setOpen={() => {
          dispatch({
            type: documentActions.MODAL_CLOSE,
            payload: {},
          });
        }}
        type={state.actionOpenedType}
        {...state?.modalProps}
      />
    </DocumentsContext.Provider>
  );
};

export const useDocumentsProvider = () => useContext(DocumentsContext);
