import { useTranslation } from "../../../utils/hooks";
import { useWithdrawOpportunity } from "../../../utils/opportunities/hooks";
import { modalTypes } from "../../../utils/variables";
import ModalFactory from "../../ui/modal/ModalFactory";

/**
 * Modal for withdraw an opportunity
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @param {{data: {key: string}}} props.data - Opportunity uuid.
 * @returns {JSX.Element}
 */
const ModalWithdrawOpportunity = ({ ...props }) => {
  const { t } = useTranslation(["common"]);
  const { loading, handleWithdrawOpportunity } = useWithdrawOpportunity();
  const mergedProps = {
    ...{
      open: false,
      setOpen: () => {},
      data: { key: "" },
      fetchData: () => {},
    },
    ...props,
  };
  return (
    <ModalFactory
      {...props}
      type={modalTypes.USER_WARNING}
      title={t("sure-withdraw-opportunity")}
      text={t("cant-undo-action")}
      cancelText={t("cancel")}
      submitText={t("remove")}
      loading={loading}
      onSubmit={(key) => {
        handleWithdrawOpportunity(
          key,
          mergedProps.setOpen,
          mergedProps.fetchData
        );
      }}
    />
  );
};

export default ModalWithdrawOpportunity;
