import {
  Alert,
  BREAKPOINT_LG,
  Chip,
  Container,
  Flex,
  Loading,
  Modal,
  Spacer,
  Tracking,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect } from "react";
import { getQuotationRequest } from "../../../model/logistics";
import { useGQL } from "../../../utils/gql/hooks";
import { useTranslation } from "../../../utils/hooks";
import { LoadCharacteristicasTypes } from "../../../utils/variables";
import Label from "../../ui/input/label/Label";
import ModalText from "../../ui/modal/text/ModalText";

const Chips = ({ chips = [] }) => {
  return (
    <Flex
      justifyContent={"flex-start"}
      alignItems={"center"}
      style={{ gap: 15, marginBottom: 16 }}
    >
      {chips.map((chip, index) => {
        return (
          <Chip
            key={index}
            big
            text={chip}
            style={{
              border: "1px solid #E0E2EB",
              backgroundColor: "#F3F4F8",
              color: "#777986",
              fontSize: 12,
              lineHeight: "18px",
            }}
          />
        );
      })}
    </Flex>
  );
};

const ModalViewTransportProvider = ({ open, setOpen, ...props }) => {
  const { t, i18n } = useTranslation(["logistics", "common", "ads"]);
  const { uuidQuote } = props;
  const { data, error, fetchData, loading } = useGQL(
    getQuotationRequest,
    { uuidRequest: uuidQuote, lang: i18n.language },
    true
  );
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);

  useEffect(() => {
    if (open) fetchData();
  }, [open]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={t("quotation-request")}
      modalStyles={{ width: matches ? "auto" : 500 }}
      hasForm={false}
      hasActions={false}
      loading={loading}
      BodyComponent={
        <Container style={{ margin: "0 -12px" }}>
          {loading ? (
            <Loading />
          ) : error ? (
            <Alert type="error">{t("default-error")}</Alert>
          ) : (
            <>
              <div className="row">
                <div className="col-12">
                  <Tracking
                    steps={[
                      {
                        heading: t("origin"),
                        label: data?.Origin,
                        status: "success",
                      },
                      {
                        heading: t("destiny"),
                        label: data?.Destination,
                        status: "success",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Spacer height={30} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Label light htmlFor={"email"}>
                    {t("goods-type")}
                  </Label>
                  <ModalText text={t(data?.TypeOfMerchandise)} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Label light htmlFor={"email"}>
                    {t("transport-type")}
                  </Label>
                  <ModalText text={t(data?.TypeOfTransport)} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Label light htmlFor={"email"}>
                    {t("load-raw")}
                  </Label>
                  <ModalText text={t(data?.Weight)} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Label light htmlFor={"email"}>
                    {t("load-unit-caracteristics")}
                  </Label>
                  <Chips
                    chips={data?.Characteristics?.map((chip, index) => {
                      if (index === 0) return t(chip, { ns: "common" });
                      if (
                        index === 1 &&
                        data?.Characteristics[0] !==
                          LoadCharacteristicasTypes.GRANEL
                      ) {
                        return `${data?.Characteristics[1]} x ${data?.Characteristics[2]} x ${data?.Characteristics[3]} cm`;
                      }
                      if (
                        index === 4 &&
                        data?.Characteristics[0] !==
                          LoadCharacteristicasTypes.GRANEL
                      ) {
                        return `${data?.Characteristics[4]} kg`;
                      }
                      if (
                        index === 5 &&
                        data?.Characteristics[0] ===
                          LoadCharacteristicasTypes.GRANEL
                      ) {
                        return `${data?.Characteristics[5]} m3`;
                      }
                      return undefined;
                    }).filter((f) => f)}
                  />
                </div>
              </div>
              {data?.NumberOfPackages &&
                data?.Characteristics[0] !==
                  LoadCharacteristicasTypes.GRANEL && (
                  <div className="row">
                    <div className="col-12">
                      <Label light htmlFor={"email"}>
                        {t("load-number")}
                      </Label>
                      <ModalText text={t(data?.NumberOfPackages)} />
                    </div>
                  </div>
                )}
            </>
          )}
        </Container>
      }
    />
  );
};

export default ModalViewTransportProvider;
