import { gql } from "@apollo/client";
import client from "../utils/gqlclient";

export async function getCatalog({ locale, name, term }) {
  const data = await client.query({
    query: gqlGetCatalog,
    variables: {
      lang: locale,
      catalogName: name,
      term,
    },
  });
  return data.errors ? { errors: data.errors } : data.data.getCatalog;
}

const gqlGetCatalog = gql`
  query ($lang: String!, $catalogName: String!, $term: String) {
    getCatalog(catalogName: $catalogName, lang: $lang, term: $term) {
      name
      uuid
      extra
      help
      subSelector {
        name
        uuid
      }
    }
  }
`;

export async function getCatalogNoTerm({ locale, name }) {
  const data = await client.query({
    query: gqlGetCatalogNoTerm,
    variables: {
      lang: locale,
      catalogName: name,
    },
  });
  return data.errors ? { errors: data.errors } : data.data.getCatalog;
}

const gqlGetCatalogNoTerm = gql`
  query ($lang: String!, $catalogName: String!) {
    getCatalog(catalogName: $catalogName, lang: $lang) {
      name
      uuid
      extra
      help
      subSelector {
        name
        uuid
      }
    }
  }
`;
