import { useEffect, useRef, useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import {
  AutoComplete,
  BREAKPOINT_LG,
  Center,
  Icon,
  Loading,
  Modal,
  Padding,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import { useSignOut, useUser } from "../../../providers/AuthHooks";
import WidgetAdOrSearchType from "../../../widgets/fields/Type";
import ModalText from "../../ui/modal/text/ModalText";
import { useDataProvider } from "../../../providers/data/DataContext";
import { searchPublicAds } from "../../../model/ads";
import { actions } from "../../../providers/data/actions/data";
import { TEXT_COLOR_LIGHT } from "../../../utils/variables";

/**
 * Modal for edit a favorite search
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalEditFavoriteSearch = ({ open = false, setOpen, ...props }) => {
  const { t, i18n } = useTranslation(["common", "business"]);
  const threshold = 3;
  const previousQueryLength = useRef(0);
  const [options, setOptions] = useState(false);
  const { state, dispatch } = useDataProvider();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [loading, setLoading] = useState(false);
  const [modaLoading, setModalLoading] = useState(true);
  const [isConfirming, setIsConfirming] = useState(false);
  const user = useUser();
  const signOut = useSignOut();

  useEffect(() => {
    const val = props?.parentSearch?.value;
    if (val) {
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: { ...props.parentSearch },
      });
      if (val.length > threshold) {
        fetchData(val);
      }
      setModalLoading(false);
    }
  }, []);

  const fetchData = async (val) => {
    const results = await searchPublicAds(i18n.language, val);
    setOptions(results);
  };

  const handleTypeChange = (type) => (e) => {
    if (type === "sale") {
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: { ...state, isSell: e.target.checked },
      });
      return;
    }
    if (type === "purchase") {
      dispatch({
        type: actions.UPDATE_INPUT,
        payload: { ...state, isBuy: e.target.checked },
      });
      return;
    }
  };

  const handleSearchChange = async (e, open, setOpen, loading, setLoading) => {
    const val = e.target.value;
    setLoading(true);
    previousQueryLength.current = state?.search?.value.length;
    const emptyInputContidion =
      val.length === 0 && previousQueryLength.current > 0;
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { ...state, value: val },
    });
    if (val.length > threshold || emptyInputContidion) {
      if (emptyInputContidion) {
        setOpen(false);
        dispatch({
          type: actions.UPDATE_INPUT,
          payload: { ...state, selected: "" },
        });
      } else {
        setOpen(true);
        fetchData(val);
      }
    } else {
      setOptions([]);
    }
    setLoading(false);
  };

  const handleItemClick = (option, open, setOpen) => {
    dispatch({
      type: actions.UPDATE_INPUT,
      payload: { ...state, value: option.text, selected: option },
    });
    setOpen(false);
    setOptions(options.filter((f) => f.uuid === option.uuid));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isConfirming) {
      setIsConfirming(true);
      return;
    }
    if (props.onSubmit) props.onSubmit(state);
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={isConfirming ? t("sure-apply-changes") : t("edit-favorite-search")}
      reverseActions={isConfirming}
      hasForm={true}
      onSubmit={handleSubmit}
      loading={loading}
      maxWidth={900}
      cancelText={isConfirming ? t("discard-changes") : t("cancel")}
      submitText={isConfirming ? t("save-changes") : t("save")}
      modalStyles={{ width: matches ? "auto" : 900 }}
      BodyComponent={
        isConfirming ? (
          <ModalText text={t("need-client-consent")} />
        ) : modaLoading ? (
          <Padding>
            <Center>
              <Loading />
            </Center>
          </Padding>
        ) : (
          <>
            <AutoComplete
              value={state?.value}
              options={options}
              remote
              placeholder={t("write-here")}
              handleOnChange={handleSearchChange}
              handleItemClick={handleItemClick}
              leadingIcon={
                <Icon
                  name="search"
                  color="#B1B1B1"
                  style={{ position: "relative", left: -10 }}
                />
              }
              trailingIcon={
                state?.value ? (
                  <Icon name="close" color={TEXT_COLOR_LIGHT} />
                ) : (
                  <></>
                )
              }
              trailingCallback={
                state?.value
                  ? () => {
                      dispatch({
                        type: actions.UPDATE_INPUT,
                        payload: { ...state, value: "", selected: "" },
                      });
                      setOptions([]);
                    }
                  : undefined
              }
            />
            <WidgetAdOrSearchType
              labelText={t("search-type-interested")}
              isBuyChecked={state?.isBuy}
              isSaleChecked={state?.isSell}
              handleOnChecked={handleTypeChange}
            />
          </>
        )
      }
    />
  );
};

export default ModalEditFavoriteSearch;
