import {
  Flex,
  Heading,
  ImageBox,
  Row,
  Typography,
} from "@scrapadev/scrapad-front-sdk";
import React, { useEffect, useRef, useState } from "react";
import GoBack from "../../../components/ui/back/GoBack";
import Breadcrumbs from "../../../components/ui/breadcrumbs/Breadcrumbs";
import { useWindowSize } from "../../../utils/hooks";

function TransactionHeader({
  imageUrl,
  breadcrumbs = [],
  loading,
  goBack,
  title,
  subtitle,
  rightArea = <></>,
  subHeaderRight = <></>,
}) {
  const rightRef = useRef();

  const extraStyles = rightRef?.current?.offsetWidth
    ? {
        maxWidth: `calc(100% - 60px - ${rightRef?.current?.offsetWidth}px)`,
      }
    : {};

  return (
    <>
      <Flex
        flexDirection="column"
        alignItems="stretch"
        style={{ gap: "25px", padding: "15px 0" }}
      >
        <Row align="center" justify="flex-start">
          {goBack && (
            <>
              <GoBack onlyText={false} />
            </>
          )}
          {breadcrumbs.length > 0 && <Breadcrumbs items={breadcrumbs} />}
        </Row>
        <Row gap={12} align="center">
          <div style={{ minWidth: "60px" }}>
            <ImageBox url={imageUrl} />
          </div>
          <Flex
            flexDirection="column"
            alignItems="stretch"
            style={{ gap: "7px", marginRight: "auto", ...extraStyles }}
            title={title}
          >
            <Heading
              type={4}
              style={{
                margin: 0,
                whiteSpace: "nowrap",
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </Heading>
            <Typography type={2} style={{ margin: 0, color: "#7E7E7E" }}>
              {subtitle}
            </Typography>
          </Flex>
          {rightArea && <div ref={rightRef}>{rightArea}</div>}
        </Row>
        {subHeaderRight}
      </Flex>
    </>
  );
}

export default TransactionHeader;
