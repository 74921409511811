import styles from "./Files.module.scss";
import { useTranslation } from "../../utils/hooks";
import React, { useState } from "react";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import {
  modalTypes,
  TEXT_COLOR_LIGHT,
  verificationStatusToChange,
  verificationStatusToChip,
  VERIFICATION_STATUS,
} from "../../utils/variables";
import { DataProvider } from "../../providers/data/DataContext";
import {
  BREAKPOINT_LG,
  Chip,
  ChipDropdown,
  ClearButton,
  Icon,
  toastError,
  IconItem,
  Separator,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { parseDate } from "../../utils/date/functions";
import { handleCRUD } from "../../utils/crud/functions";
import { useSetDataUser, useSignOut } from "../../providers/AuthHooks";
import GQLFetchWrapper from "../../wrappers/FetchWrapper";
import { getVerificationDetail } from "../../model/business";
import WidgetVerificationDeleteFile from "../business/verification/DeleteFile";

/**
 * Verification files component.
 * @param {Object} props - Component properties.
 * @param {Array} [props.files=[]] - Files to show.
 * @param {Function} props.dispatch - Dispatch function.
 * @param {Object} props.actions - State actions.
 * @returns {JSX.Element}
 */
const WidgetFieldFiles = ({
  files = [],
  canEdit,
  id,
  fetchData,
  setLoading,
  hideControls,
  permission,
}) => {
  const { t, i18n } = useTranslation(["common", "verification"]);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const [uuidFile, setUuidFile] = useState(null);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);

  const handleChangeStatus = (file, key) => async () => {
    setLoading(true);
    handleCRUD({
      endpoint: `org/${id}/verification/${file?.id}`,
      data: {
        status: key,
      },
      method: "PUT",
      setDataUser,
      signOut,
      successCallback: async () => {
        await fetchData();
        setLoading(false);
      },
    });
  };

  const permissionCheck = () => {
    if (!permission) return true;
    return permission();
  };

  return (
    <div className={styles["sc-files--container"]}>
      {files.map((file, index) => {
        return (
          <React.Fragment key={file?.id}>
            <div className={styles["sc-files--item"]}>
              <div
                className={styles["sc-files--file"]}
                style={
                  matches
                    ? {
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }
                    : {}
                }
              >
                <div className={styles["sc-files--info"]}>
                  <Icon
                    name={"attach_file"}
                    color={"#777986"}
                    style={{ marginRight: 12, fontSize: "1rem" }}
                  />
                  <p
                    className={styles["name"]}
                    onClick={() => {
                      window.open(file?.fileLocation, "_blank").focus();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {file.fileName}
                  </p>
                  {file?.state ? (
                    canEdit && id && permissionCheck() ? (
                      <ChipDropdown
                        items={Object.keys(verificationStatusToChange).map(
                          (key, index) => (
                            <ClearButton
                              key={index}
                              onClick={handleChangeStatus(file, key)}
                            >
                              {t(key)}
                            </ClearButton>
                          )
                        )}
                        type={verificationStatusToChange[file?.state]}
                        text={t(file?.state)}
                        style={{ cursor: "pointer" }}
                      />
                    ) : (
                      <Chip
                        type={verificationStatusToChange[file?.state]}
                        text={t(file?.state)}
                      />
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <div className={styles["actions"]}>
                  {!file?.new && (
                    <>
                      <span>{`Subido el ${parseDate(
                        file?.requestDate,
                        i18n
                      )}`}</span>
                      <span className={styles["separator"]}></span>
                    </>
                  )}
                  {permissionCheck() &&
                    file?.state === VERIFICATION_STATUS.draft && (
                      <>
                        <WidgetVerificationDeleteFile
                          id={id}
                          fileUuid={file?.id}
                          fetchData={fetchData}
                          setLoading={setLoading}
                        />
                        {!matches && (
                          <Separator
                            hideOnMobile={false}
                            style={{ backgroundColor: TEXT_COLOR_LIGHT }}
                          />
                        )}
                      </>
                    )}
                  <ClearButton
                    onClick={() => {
                      setUuidFile(file?.id);
                      setDetailsOpen(true);
                    }}
                    style={{
                      textDecoration:
                        file?.new || hideControls ? "none" : "underline",
                    }}
                  >
                    {!file?.new ? (
                      <span>{t("more-details")}</span>
                    ) : (
                      <Icon name={"close"} color={"#777986"} />
                    )}
                  </ClearButton>
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      })}
      <ModalFactory
        type={modalTypes.VERIFICATION_DOCUMENT_DETAILS}
        open={detailsOpen}
        setOpen={setDetailsOpen}
        uuidFile={uuidFile}
      />
      {uuidFile && (
        <GQLFetchWrapper
          key={uuidFile}
          fnData={getVerificationDetail}
          fnParameters={{
            uuidValidation: uuidFile,
          }}
          permission={permission}
          Component={ModalFactory}
          type={modalTypes.VERIFICATION_DOCUMENT_DETAILS}
          open={detailsOpen}
          setOpen={setDetailsOpen}
          uuidFile={uuidFile}
          deps={[uuidFile]}
          canEdit={canEdit}
          delegateLoading
          onSubmit={(data, uuidFile) => {
            setLoading(true);
            handleCRUD({
              endpoint: `org/${id}/verification/${uuidFile}`,
              data: {
                status: data?.state,
                scrapadComment: data?.scrapadComment,
              },
              method: "PUT",
              setDataUser,
              signOut,
              errorCallback: (error) => {
                toastError(error.message);
                setLoading(false);
              },
              successCallback: async () => {
                await fetchData();
                setLoading(false);
              },
            });
          }}
        />
      )}
    </div>
  );
};

export default WidgetFieldFiles;
