import { InputPhoneWithPrefix } from "@scrapadev/scrapad-front-sdk";
import Label from "../../../components/ui/input/label/Label";
import { countryCodesProps } from "../../../utils/functions";
import { useTranslation } from "../../../utils/hooks";
import { handleOnChange, handlePrfixChange } from "./functions";

/**
 * Helper component for address phone.
 * @param {Object} props - Component properties.
 * @param {boolean} props.required - Required field.
 * @param {Object} props.address - Address info.
 * @param {number} props.index - Index of current address.
 * @param {Object} props.state - Address state.
 * @param {Function} props.dispatch - Dispatch function.
 * @returns {JSX.Element}
 */
const WidgetAddressPhone = ({
  required,
  address,
  index,
  state,
  dispatch,
  permissionManage,
}) => {
  const { t, i18n } = useTranslation(["common"]);
  return (
    <>
      <Label>
        {t("phone")} {required ? "*" : ""}
      </Label>
      <InputPhoneWithPrefix
        required={required}
        prefixValue={address?.prefix}
        prefixOnchange={handlePrfixChange(index, "prefix", state, dispatch)}
        phoneValue={address?.tel}
        phoneOnChange={handleOnChange(index, "tel", state, dispatch)}
        readOnlyPhone={permissionManage ? !permissionManage() : false}
        readOnlyPrefix={permissionManage ? !permissionManage() : false}
        prefixLabel="+XX"
        labelInput={address?.prefix?.length > 4 ? "" : t("phone")}
        codesProps={{
          ...countryCodesProps(
            state?.prefix,
            t,
            i18n,
            permissionManage ? !permissionManage() : false
          ),
          position: "absolute",
        }}
      />
    </>
  );
};

export default WidgetAddressPhone;
