import { AutoComplete, Loading } from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import Label from "../../../components/ui/input/label/Label";
import { getCatalogNoTerm } from "../../../model/filters";
import { useGQL } from "../../../utils/gql/hooks";
import { useTranslation } from "../../../utils/hooks";
import { addressesEquipment } from "../../../utils/variables";
import { dispatchAction } from "./functions";
import WidgetAddressCustomItem from "./Item";

/**
 * Helper component for load / unload equipment.
 * @param {Object} props - Component properties.
 * @param {Object} props.address - Address data.
 * @param {number} props.index - Current address index.
 * @param {Object} props.state - Address state.
 * @param {Function} props.dispatch - Dispatch function.
 * @returns {JSX.Element}
 */
const WidgetAddressEquipment = ({
  address,
  index,
  state,
  dispatch,
  permissionManage,
  ...props
}) => {
  const { t, i18n } = useTranslation(["common", "business"]);
  const { data: catalogData, loading: catalogLoading } = useGQL(
    getCatalogNoTerm,
    {
      locale: i18n.language,
      name: "equipments",
    }
  );

  const handleItemclick = (option, open, setOpen, e) => {
    e.stopPropagation();
    let value = address?.facilities.find((f) => f === option.uuid)
      ? address?.facilities.filter((f) => f !== option?.uuid)
      : [...address?.facilities, option.uuid];
    dispatchAction(index, "facilities", value, state, dispatch);
  };

  const handleIsChecked = (option) => {
    const checked = address?.facilities?.find((f) => f === option?.uuid)
      ? true
      : false;
    return checked;
  };

  return (
    <>
      <Label>
        {t("load-unload-equipment")}{" "}
        {props.required && props.skipRequiredHelp ? "*" : ""}
      </Label>
      {catalogLoading ? (
        <Loading />
      ) : (
        <AutoComplete
          options={catalogData?.map((e) => ({
            uuid: e.uuid,
            text: t(e.name),
          }))}
          multiple={true}
          placeholder={t("select-many")}
          value={catalogData
            ?.filter((a) => address?.facilities.includes(a.uuid))
            ?.map((m) => t(m.name))}
          handleItemClick={handleItemclick}
          handleIsChecked={handleIsChecked}
          position="fixed"
          renderItem={(option, innerOptions, handleClick, handleIsChecked) => {
            return (
              <WidgetAddressCustomItem
                option={option}
                innerOptions={innerOptions}
                handleClick={handleClick}
                handleIsChecked={handleIsChecked}
              />
            );
          }}
          skipRequiredHelp
          readOnly={permissionManage ? !permissionManage() : false}
          {...props}
        />
      )}
    </>
  );
};

export default WidgetAddressEquipment;
