import { adsDefaults } from "../variables";

export const dropdownPropsMaterial = (materialsRef) => {
  return {
    onChipDropdownClick: (e, open, setOpen) => {
      if (e.target.className.indexOf("sc-chip") > -1) {
        setOpen(!open);
        materialsRef.current.handleClose = setOpen;
      }
    },
    dropDownStyle: {
      padding: 20,
    },
    dropDownItemStyle: {
      padding: 0,
    },
  };
};

export const dropdownPropsSearch = (searchRef) => {
  return {
    onChipDropdownClick: (e, open, setOpen) => {
      e.stopPropagation();
      if (e.target.className.indexOf("sc-chip") > -1) {
        setOpen(!open);
        searchRef.current.handleClose = setOpen;
      }
    },
    dropDownStyle: {
      padding: 20,
    },
    dropDownItemStyle: {
      padding: 0,
    },
  };
};

export const getDateDropdownProps = (dateFilterRef) => {
  return {
    onChipDropdownClick: (e, open, setOpen) => {
      e.stopPropagation();
      if (e.target.className.indexOf("sc-chip") > -1) {
        setOpen(!open);
        dateFilterRef.current.handleClose = setOpen;
      }
    },
    dropDownStyle: {
      padding: 0,
      boxShadow: "none",
    },
    dropDownItemStyle: {
      padding: 0,
    },
  };
};

export const removeFilterMaterial = (
  item,
  filter,
  dispatch,
  actions,
  filterChanged
) => {
  const { slug } = filter;
  const newData = { ...filter.data };
  if (
    newData?.materials?.category?.uuidSelected?.length > 0 &&
    newData?.materials?.material?.uuidSelected?.length > 0 &&
    newData?.materials?.type?.uuidSelected?.length > 0
  ) {
    newData.materials.type.uuidSelected =
      newData?.materials?.type?.uuidSelected?.filter((f) => f !== item.uuid);
    newData.materials.type.nameSelected =
      newData?.materials?.type?.nameSelected?.filter((f) => f !== item.name);
    newData.materials.type.values = newData?.materials?.type?.values?.filter(
      (f) => f !== item.name
    );
  } else if (
    newData?.materials?.category?.uuidSelected?.length > 0 &&
    newData?.materials?.material?.uuidSelected?.length > 0 &&
    newData?.materials?.type?.uuidSelected?.length === 0
  ) {
    newData.materials.material.uuidSelected = [];
    newData.materials.material.nameSelected = [];
    newData.materials.material.values = [];
    newData.materials.type.open = false;
  } else {
    newData.materials.category.uuidSelected = [];
    newData.materials.category.nameSelected = [];
    newData.materials.category.values = [];
    newData.materials.material.open = false;
  }
  dispatch({
    type: actions.UPDATE_INPUT,
    payload: { slug, payload: newData },
  });
  dispatch({
    type: actions.UPDATE_INPUT,
    payload: { slug, payload: newData },
  });
  filterChanged.current = true;
  dispatch({
    type: actions.REMOVE_FILTER,
    payload: { item, filter },
  });
};

export const clearAllFilterMaterial = (dispatch, actions) => (filter) => {
  dispatch({
    type: actions.CLEAR_INPUT,
    payload: {
      slug: filter.slug,
      category: {
        ...filter?.data?.materials?.category,
        uuidSelected: [],
        nameSelected: [],
        values: [],
      },
      material: adsDefaults,
      type: adsDefaults,
    },
  });
  dispatch({
    type: actions.UPDATE_INPUT,
    payload: {
      slug: "materials",
      payload: { materials: adsDefaults },
    },
  });
};

/**
 * Helper function to check the multiple propoerty of a filters. Defaults to true.
 * @param {object} filter - Current filter
 * @returns {boolean}
 */
export const checkIfMultiple = (filter) => {
  return typeof filter.multiple !== undefined &&
    typeof filter.multiple !== null &&
    filter.multiple === false
    ? false
    : true;
};
