import { ClearButton } from "@scrapadev/scrapad-front-sdk";
import React from "react";
import styles from "./UnderlinedLink.module.scss";

function UnderlinedLink({ onClick, children, alternative }) {
  return (
    <div
      className={`${styles["underlined-link"]} ${
        alternative && styles["alternative"]
      }`}
    >
      <ClearButton onClick={onClick}>
        <span className={styles["text"]}>{children}</span>
      </ClearButton>
    </div>
  );
}

export default UnderlinedLink;

