import React from "react";
import styles from "./OpportunityStatusIcon.module.scss";
import { Icon, Row } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../../utils/hooks";
import { offerStatus } from "../../../../utils/opportunities/variables";

function OpportunityStatusIcon({ type = "pending" }) {
  const { t } = useTranslation(["common"]);

  const getIcon = () => {
    switch (type) {
      case offerStatus.ACCEPTED:
        return "done";
      case offerStatus.TRANSACTION_CREATED:
        return "done";
      case offerStatus.REJECTED:
        return "close";
      case offerStatus.PENDING:
        return "priority_high";
      case offerStatus.ACCEPTED_PENDING:
        return "priority_high";
      default:
        return "priority_high";
    }
  };

  const getTooltipText = () => {
    switch (type) {
      case offerStatus.ACCEPTED:
        return t("accepted-offer");
      case offerStatus.TRANSACTION_CREATED:
        return t("accepted-offer");
      case offerStatus.REJECTED:
        return t("rejected-offer");
      case offerStatus.PENDING:
        return t("pending-costs");
      case offerStatus.ACCEPTED_PENDING:
        return t("pending-costs");
      default:
        return t("pending-costs");
    }
  };

  const iconName = getIcon();
  const tooltipText = getTooltipText();

  return (
    <Row gap={7}>
      <div
        className={`${styles["opportunity_status_icon"]} ${styles[type]}`}
        data-tooltip={tooltipText}
      >
        <Icon name={iconName} color="#fff" style={{ fontSize: "12px" }} />
      </div>
      {type === offerStatus.ACCEPTED_PENDING && (
        <div
          className={`${styles["opportunity_status_icon"]} ${styles["accepted"]}`}
          data-tooltip={t("accepted-offer")}
        >
          <Icon name={"done"} color="#fff" style={{ fontSize: "12px" }} />
        </div>
      )}
    </Row>
  );
}

export default OpportunityStatusIcon;
