import {
  BREAKPOINT_LG,
  Modal,
  useMatchMedia,
  Row,
  Paper,
  Stepper,
  BREAKPOINT_MD,
  WARM_NEUTRAL_10,
  Step,
  Button,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useState } from "react";
import { actions } from "../../../providers/data/actions/data";
import { useDataProvider } from "../../../providers/data/DataContext";
import { handleOnChange } from "../../../utils/functions";
import { useTranslation } from "../../../utils/hooks";
import _ from "lodash";
import RouteForm from "./RouteForm/RouteForm";
import QuotationServicesForm from "./QuotationForm/QuotationForm";

const defaultService = {
  suplierUuid: undefined,
  equipment: [],
  numLote: undefined,
  unitPrice: undefined,
  totalCost: 0,
  shippingDeadline: undefined,
  untilDate: undefined,
};

const defaultData = {
  transportType: "sea",
  incoterm: undefined,
  incotermBuyer: undefined,
  originPort: undefined,
  destinationPort: undefined,
  originAddress: undefined,
  destinationAddress: undefined,
  unloadPossibilities: [],
  loadPossibilities: [],
  services: [
    {
      ...defaultService,
    },
  ],
};

const ModalAddQuotation = ({
  open,
  setOpen,
  isEdit = false,
  data,
  uuidSeller,
  uuidBuyer,
  ...props
}) => {
  const { state, dispatch } = useDataProvider();
  const [loading, setLoading] = useState();
  const { t } = useTranslation(["logistics", "common"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [step, setStep] = useState(0);
  const matchesMD = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);
  const [inputErrors, setInputErrors] = useState(false);

  useEffect(() => {
    if (isEdit) {
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: data || defaultData,
      });
    } else {
      dispatch({
        type: actions.INITIAL_LOAD,
        payload: defaultData,
      });
    }
  }, []);

  const handleServiceOnChange = (index, key, e) => {
    const stateCpy = _.cloneDeep(state);

    if (key === "equipment") {
      const obj = {};
      const alreadySelected = state.services[index][key]?.includes(
        e?.target?.value
      );
      if (alreadySelected) {
        obj[key] = state.services[index][key].filter(
          (f) => f !== e?.target?.value
        );
      } else {
        obj[key] = state.services[index][key]
          ? [...state.services[index][key], e?.target?.value]
          : [e?.target?.value];
      }
      stateCpy.services[index][key] = obj[key];
    } else {
      stateCpy.services[index][key] = e?.target?.value;
    }

    handleOnChange(
      "services",
      dispatch
    )({
      target: {
        value: stateCpy.services,
      },
    });
  };

  const handleAddService = () => {
    const stateCpy = _.cloneDeep(state);

    stateCpy.services.push(defaultService);

    handleOnChange(
      "services",
      dispatch
    )({
      target: {
        value: stateCpy.services,
      },
    });
  };

  const handleRemoveService = (index) => {
    const stateCpy = _.cloneDeep(state);

    handleOnChange(
      "services",
      dispatch
    )({
      target: {
        value: stateCpy.services.filter((element, i) => i !== index),
      },
    });
  };

  const handleSubmit = async () => {
    console.log("handleSubmit");
    console.log(state);
    setLoading(true);
    if (validateForm()) {
      setLoading(false);
    } else {
      setLoading(false);

      return;
    }
  };

  const validateForm = () => {
    const fieldsToValidate = [
      { field: "transportType", step: 0 },
      { field: "incoterm", step: 0 },
      { field: "incotermBuyer", step: 0 },
      { field: "originAddress", step: 0, deps: "originPort" },
      { field: "destinationAddress", step: 0, deps: "destinationPort" },
    ];

    const errorList = [];
    let errorSetted = false;

    fieldsToValidate.forEach(({ field, step }) => {
      let haveError = false;
      if (_.isEmpty(state?.[field])) {
        if (state?.[field]?.deps) {
          if (_.isEmpty(state?.[state?.[field]?.deps])) {
            haveError = true;
          }
        } else {
          haveError = true;
        }
      }
      if (haveError) {
        errorList.push(field);
        if (!errorSetted) {
          setStep(step);
          errorSetted = true;
        }
      }
    });
    setInputErrors(errorList);

    return errorList.length === 0;
  };

  const stepCount = 2;

  return (
    <Modal
      open={open}
      setOpen={() => {
        setOpen(false);
        setLoading(false);
        dispatch({ type: actions.UPDATE_INPUT, payload: defaultData });
      }}
      title={t(isEdit ? "edit_quotation_title" : "enter_quotation_title")}
      cancelText={t("cancel")}
      submitText={t(isEdit ? "save-changes" : "send_quotation")}
      modalStyles={{ width: matches ? "auto" : 900 }}
      hasForm
      onSubmit={handleSubmit}
      loading={loading}
      actionsMode="end"
      submitButtonMode="info"
      ActionsComponent={
        !isEdit ? (
          <Row
            align="center"
            justify="end"
            gap={15}
            wrap={matchesMD}
            style={{
              width: "100%",
              flexDirection: matchesMD ? "column-reverse" : "row",
              marginTop: matchesMD ? "-15px" : 0,
            }}
          >
            <div
              style={
                matchesMD
                  ? {
                      width: "100%",
                    }
                  : {}
              }
            >
              <Button
                loading={loading}
                component="button"
                mode={step > 0 ? "outline-success" : "normal"}
                text={t(step > 0 ? "prev" : "cancel")}
                onClick={(e) => {
                  e.preventDefault();
                  if (step === 0) {
                    document.body.classList.remove("overflow-hidden");
                    setOpen(false);
                  } else {
                    setStep(step - 1);
                  }
                }}
                fullwidth={matchesMD}
              />
            </div>
            <div
              style={
                matchesMD
                  ? {
                      width: "100%",
                    }
                  : {}
              }
            >
              <Button
                loading={loading}
                component="button"
                mode="success"
                type={"submit"}
                text={t(
                  step < stepCount - 1
                    ? "next"
                    : isEdit
                    ? "save-changes"
                    : "send_quotation"
                )}
                fullwidth={matchesMD}
                onClick={(e) => {
                  if (step < stepCount - 1) {
                    e.preventDefault();
                    setStep(step + 1);
                  } else {
                    setInputErrors(false);
                    // validateForm();
                    handleSubmit();
                  }
                }}
              />
            </div>
          </Row>
        ) : undefined
      }
      BodyComponent={
        <>
          <Paper style={{ alignItems: "stretch" }}>
            {isEdit ? (
              <>
                <QuotationServicesForm
                  state={state}
                  handleAddService={handleAddService}
                  handleRemoveService={handleRemoveService}
                  handleServiceOnChange={handleServiceOnChange}
                />
              </>
            ) : (
              <>
                <Stepper
                  simple
                  horizontal={true}
                  active={step}
                  hideBullets={false}
                  mode={"solid"}
                  backgroundColor={WARM_NEUTRAL_10}
                  mobile={matchesMD}
                  justify={"start"}
                  onClick={(e) => {
                    setStep(e);
                  }}
                >
                  <Step
                    bullet={1}
                    label={t("define_the_route")}
                    open={step === 0}
                    headerStyle={{ display: "none" }}
                    hideIcon={true}
                    type="minimal"
                    customStyle={{
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <RouteForm
                      uuidBuyer={uuidBuyer}
                      uuidSeller={uuidSeller}
                      dispatch={dispatch}
                      state={state}
                    />
                  </Step>
                  <Step
                    bullet={2}
                    label={t("enter_quotation_title")}
                    open={step === 1}
                    headerStyle={{ display: "none" }}
                    hideIcon={true}
                    type="minimal"
                    customStyle={{
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <QuotationServicesForm
                      state={state}
                      handleAddService={handleAddService}
                      handleRemoveService={handleRemoveService}
                      handleServiceOnChange={handleServiceOnChange}
                    />
                  </Step>
                </Stepper>
              </>
            )}
          </Paper>
        </>
      }
    />
  );
};

export default ModalAddQuotation;
