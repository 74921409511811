import React from "react";
import styles from "./PaperSectionHeader.module.scss";

function PaperSectionHeader({ left, right }) {
  return (
    <div className={styles["paper-section-header"]}>
      <div>{left}</div>
      {right && <div>{right}</div>}
    </div>
  );
}

export default PaperSectionHeader;

