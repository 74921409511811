import {
  Box,
  Flex,
  Heading,
  Spacer,
  Typography,
  WARM_NEUTRAL_20,
  WARM_NEUTRAL_40,
} from "@scrapadev/scrapad-front-sdk";

/**
 * Wrapper for every section in business preferences. Ex: Payment methods.
 * @param {object} props - Component properties.
 * @param {string} [props.title] - Section title.
 * @param {string} [props.title] - Section subtitle.
 * @param {React.ReactNode} [props.children] - Children components.
 * @returns {React.ReactNode}
 */
const PreferencesBlockWrapper = ({ title, subtitle, children }) => {
  return (
    <Box
      style={{
        padding: 20,
        radius: 10,
        border: `1px solid ${WARM_NEUTRAL_20}`,
      }}
    >
      <Flex
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"flex-start"}
      >
        {title && (
          <Heading type={5} style={{ marginBottom: 0 }}>
            {title}
          </Heading>
        )}
        {subtitle && (
          <Typography
            noMargin
            style={{
              fontSize: 10,
              lineHeight: 1.5,
              color: WARM_NEUTRAL_40,
            }}
          >
            {subtitle}
          </Typography>
        )}
        <Spacer />
        <div style={{ width: "100%" }}>{children}</div>
      </Flex>
    </Box>
  );
};

export default PreferencesBlockWrapper;
