import {
  Icon,
  TableManager,
  toastError,
  WARM_NEUTRAL_60,
} from "@scrapadev/scrapad-front-sdk";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "../../components/ui/container/Container";
import NavBar from "../../components/ui/navbar/NavBar";
import SearchFilterExport from "../../components/ui/search-filter-export/SearchFilterExport";
import LayoutDetail from "../../layout/detail/Detail";
import { useDocumentTitle } from "../../utils/document/hooks";
import { searchOnTable } from "../../utils/functions";
import { getDocuments } from "../../model/tables";
import { useSetDataUser, useSignOut } from "../../providers/AuthHooks";
import { handleDownloadDocument } from "../../utils/functions";
import { gqlWrapper } from "../../utils/gqlwrapper";
import { useStepsTransaction, useTranslation } from "../../utils/hooks";
import { QUERY_UPPER_LIMIT } from "../../utils/variables";
import TransactionHeader from "./header/TransactionHeader";
import {
  getTransactionDataBO,
  getTransactionDetail,
} from "../../model/transactions";
import Steps from "../../components/ui/steps/Steps";
import DocumentManager from "./document_manager/DocumentManager";
import { getSingleFolderDetails } from "../../model/documents";
import ChipOutlined from "../../components/ui/chip/ChipOutlined";

/**
 * Documents page
 */
const Documents = () => {
  const { id } = useParams();
  const { t, i18n, values } = useTranslation(["common", "transactions"]);
  const navigate = useNavigate();
  const [data, setData] = useState({ header: [], body: [] });
  const [detailData, setDetailData] = useState({ header: [], body: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const dataBuffer = useRef({ header: [], body: [] });
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const transactionUuid = id?.split("-");
  const { steps } = useStepsTransaction(id);
  const [documentsData, setDocumentsData] = useState([]);
  useDocumentTitle(
    `${t("transaction-documents")} ${transactionUuid?.[0]}`,
    values
  );

  useEffect(() => {
    fetchData();
    fetchDetailData();
    // fetchData2();
  }, [i18n.language]);

  const fetchData = async () => {
    const fn = await gqlWrapper(getDocuments, setDataUser, signOut);
    const results = await fn(i18n.language, id, QUERY_UPPER_LIMIT);
    if (results === null) {
      setData({ header: [], body: [] });
    } else {
      if (results?.errors?.length > 0) {
        setData({ header: [], body: [] });
        toastError(results?.errors[0]?.message);
      } else {
        setData({
          header: results.columns,
          body: results.rows,
        });
        dataBuffer.current = {
          header: results.columns,
          body: results.rows,
        };
      }
    }

    setLoading(false);
  };

  const fetchData2 = async () => {
    const fn = await gqlWrapper(getSingleFolderDetails, setDataUser, signOut);
    const results = await fn(id, "");
    setDocumentsData(results);
    setLoading(false);
  };

  const fetchDetailData = async (quiet = false) => {
    if (!quiet) setIsLoading(true);
    try {
      // const fn = await gqlWrapper(getTransactionDetail, setDataUser, signOut);
      const fn = await gqlWrapper(getTransactionDataBO, setDataUser, signOut);
      const results = await fn(i18n.language, id);
      if (results === null) {
        setDetailData([]);
      } else {
        if (results?.errors?.length > 0) {
          setDetailData([]);
          toastError(results?.errors[0]?.message);
        } else {
          setDetailData(results);
        }
      }
    } catch (e) {
      setDetailData([]);
      toastError(e.message || e);
    } finally {
      if (!quiet) setIsLoading(false);
    }
  };

  // const subtitle = `ID ${
  //   detailData?.header?.uuidConversation?.split("-")?.[0]
  // } - iniciada por ${detailData?.header?.startedBy} el ${formatDate(
  //   detailData?.header?.startedAt,
  //   i18n,
  //   false
  // )}`;

  let typeIcon = (
    <Icon
      name={"swap_horiz"}
      color={WARM_NEUTRAL_60}
      style={{
        fontSize: "16px",
      }}
    />
  );
  const subtitle = (
    <ChipOutlined iconComponent={typeIcon}>
      <span style={{ textTransform: "uppercase" }}>
        {detailData?.header?.uuidConversation?.split("-")?.[0]}
      </span>
    </ChipOutlined>
  );

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor={"#fff"}
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                <TransactionHeader
                  imageUrl={detailData?.sideData?.originAd?.image}
                  title={t("documents")}
                  titleSmall
                  subtitle={subtitle}
                  breadcrumbs={[
                    {
                      description: t("transactions"),
                      onClick: () =>
                        navigate("/transactions", { replace: true }),
                    },
                    {
                      description: detailData?.header?.adTitle,
                      onClick: () => navigate(-1),
                    },
                    { description: t("documents") },
                  ]}
                  goBack
                  subHeaderRight={undefined}
                  middleStyles={{ marginBottom: 10 }}
                  lowerLeftStyles={{ marginBottom: 0 }}
                  lowerRightStyles={{ marginBottom: 0 }}
                />
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <>
            <Container style={{ marginBottom: "10px" }}>
              <Steps
                steps={steps}
                currentStep={"documents"}
                checkCurrentByLabel
                minimal
              />
            </Container>
            {JSON.parse(process.env.REACT_APP_DOCUMENT_MANAGER) ? (
              <>
                <Container>
                  <DocumentManager transactionUuid={id} />
                </Container>
              </>
            ) : (
              <>
                <Container>
                  <SearchFilterExport
                    searchPlaceholder={t("search")}
                    searchOnChange={searchOnTable(
                      data,
                      setData,
                      dataBuffer.current,
                      i18n
                    )}
                    hasFilters={false}
                    hasExport={false}
                    hasSeparator={false}
                  />
                </Container>
                <Container>
                  <TableManager
                    loading={loading}
                    data={data}
                    setData={setData}
                    emptyText={t("empty-document")}
                    navigate={(uuid) => navigate(uuid)}
                    rowTriggerPageChange={false}
                    itemsProps={{
                      chip: {
                        translate: (code) => t(code),
                        customRender: (value, colRef, mapped, props) => {
                          if (value === "void") return <></>;
                          return mapped;
                        },
                      },
                      date: {
                        languageData: i18n.getLanguageVariable(i18n.language),
                        format: "P - kk:mm",
                      },
                      link: {
                        linkStyles: {
                          fontWeight: 500,
                        },
                        handleDownload: (name, endpoint) => {
                          handleDownloadDocument(
                            endpoint,
                            signOut,
                            setDataUser
                          );
                        },
                      },
                    }}
                  />
                </Container>
              </>
            )}
          </>
        }
      />
    </>
  );
};

export default Documents;
