import { Typography } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../utils/hooks";

/**
 * Helper widget to show no results in the opportunities detail.
 * @returns {JSX.Element}
 */
const WidgetOpportunitiesEmpty = () => {
  const { t } = useTranslation(["common"]);
  return (
    <Typography type="2" style={{ margin: 0 }}>
      {t("empty-data")}
    </Typography>
  );
};

export default WidgetOpportunitiesEmpty;
