import React from "react";
import styles from "./DepositTermsItem.module.scss";

function DepositTermsItem({ label, date, amount, disabled }) {
  return (
    <div
      className={`${styles["deposit-terms-item"]} ${
        disabled && styles["disabled"]
      }`}
    >
      <span className={styles["deposit-terms-item__label"]}>{label}</span>
      <div className={styles["deposit-terms-item__right"]}>
        <span className={styles["deposit-terms-item__date"]}>{date}</span>
        <span className={styles["deposit-terms-item__amount"]}>{amount}</span>
      </div>
    </div>
  );
}

export default DepositTermsItem;
