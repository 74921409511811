import {
  CheckBox,
  Icon,
  SearchAutoComplete,
} from "@scrapadev/scrapad-front-sdk";
import { forwardRef, useState } from "react";
import { checkIfMultiple } from "../../../utils/filters/functions";
import { useTranslation } from "../../../utils/hooks";
import FilterSearch from "./FilterSearch";

const FilterAutoComplete = (
  {
    filter,
    catalogName,
    filterKey,
    options = [],
    handleItemClick,
    itemSpanStyle,
    itemCheckStyle,
    itemCheckedStyle,
    textStyles,
    inputProps = {},
  },
  ref
) => {
  const [innerValue, setInnerValue] = useState("");
  const { t } = useTranslation("common");
  const isMultipleFilter = checkIfMultiple(filter);
  const defaultInputProps = {
    useSearch: typeof catalogName !== "undefined",
    trailingIcon: <></>,
    multiple: isMultipleFilter,
    position: "relative",
    permanentOpen: isMultipleFilter,
    handleIsChecked: isMultipleFilter
      ? (option) =>
          filter?.selected?.filter((f) => f.uuid === option.uuid)?.length > 0
      : undefined,
    renderItem:
      typeof catalogName !== "undefined"
        ? undefined
        : isMultipleFilter
        ? (option, innerOptions, handleClick, handleIsChecked) => {
            const isChecked = handleIsChecked(option, innerOptions);
            return (
              <li
                key={option.uuid}
                style={{ justifyContent: "flex-start" }}
                onClick={handleClick(option)}
              >
                <span style={itemSpanStyle}>
                  <CheckBox
                    checked={isChecked}
                    style={isChecked ? itemCheckedStyle : itemCheckStyle}
                  />
                </span>
                <span style={textStyles}>{`${option.name}`}</span>
              </li>
            );
          }
        : undefined,
    leadingIcon: (
      <Icon
        name={"search"}
        color={"#B1B1B1"}
        style={{
          fontSize: 18,
          position: "relative",
          left: -25,
          top: 0,
          zIndex: 9,
        }}
      />
    ),
    inputStyles: {
      border: "none",
      outline: "none",
      position: "relative",
      top: 0,
      left: 0,
      paddingTop: 15,
      paddingBottom: 15,
      paddingRight: 25,
      paddingLeft: 30,
    },
    resultsStyles: {
      border: "none",
      borderTop: "1px solid #D4D6E1",
      boxShadow: "none",
      borderRadius: 0,
      maxHeight: 200,
      overflowY: "auto",
      top: 0,
      left: 0,
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 0,
      paddingRight: 0,
    },
    containerStyles: {
      border: "1px solid #D4D6E1",
      boxShadow: "0px 0px 10px rgba(127, 133, 166, 0.1)",
      paddingRight: 15,
      paddingLeft: 15,
    },
    ...inputProps,
  };

  if (catalogName && filterKey) {
    return (
      <FilterSearch
        catalogName={catalogName}
        filterKey={filterKey}
        permanentOpen
        inputProps={defaultInputProps}
        ref={ref}
      />
    );
  }
  return (
    <SearchAutoComplete
      placeholder={t("write-to-search")}
      handleOnChange={(e, open, setOpen, loading, setLoading) => {
        setInnerValue(e.target.value);
      }}
      value={[innerValue]}
      options={options}
      model={{ uuid: "uuid", text: "name" }}
      handleItemClick={(option, open, setOpen) => {
        if (handleItemClick) handleItemClick(option, open, setOpen);
      }}
      defaultOpen
      {...defaultInputProps}
    />
  );
};

export default forwardRef(FilterAutoComplete);
