import { useSubscription } from "@apollo/client";
import { useEffect } from "react";
import { gqlWebsockets } from "../../model/websockets";

/**
 * Empty component to deal with websockets when user its not set.
 * @param {object} props - Component properties.
 * @param {((data: object) => void) => void} [props.callback] - Callback function.
 * @param {string) => void} [props.room] - Room.
 * @returns {React.ReactNode}
 */
const WebsocketsWrapper = ({ callback, room }) => {
  const { data } = useSubscription(gqlWebsockets, {
    variables: { room },
  });

  useEffect(() => {
    if (callback && typeof callback === "function") {
      callback(data);
    }
  }, [data]);

  return <></>;
};

export default WebsocketsWrapper;
