import React, { useState } from "react";
import { useTranslation } from "../../../../utils/hooks";
import {
  Accordion,
  BREAKPOINT_LG,
  ClearButton,
  Flex,
  Heading,
  Icon,
  InputDate,
  InputNumber,
  InputText,
  Label,
  Paper,
  Row,
  Typography,
  useMatchMedia,
  WARM_NEUTRAL_50,
} from "@scrapadev/scrapad-front-sdk";
import CatalogSearch from "../../../ui/input/search/CatalogSearch";
import LandSeaTransportEquipment from "../../../ui/catalogs/Equipment";

function ServiceItem({
  service = {},
  index = 0,
  onChange = (index, key, value) => {},
  onDelete,
}) {
  const { t, i18n } = useTranslation(["logistics", "common", "transactions"]);
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  const [serviceName, setServiceName] = useState();

  return (
    <Accordion
      toggleAtLead
      minimal
      defaultOpen
      title={
        <Row gap={25} align="center">
          <Heading type={7} style={{ margin: "0 auto 0 0", padding: 0 }}>
            {t("service")} {index + 1} -
            {service?.provider
              ? serviceName
                ? serviceName
                : service?.provider
              : ""}{" "}
            ({`${service?.totalCost}€`})
          </Heading>
        </Row>
      }
    >
      <Flex
        flexDirection="column"
        alignItems="stretch"
        style={{
          gap: "10px",
          marginLeft: "-12px",
          marginRight: "-12px",
          marginTop: "-10px",
        }}
      >
        <Paper whiteMode style={{ alignItems: "stretch", width: "100%" }}>
          <Label text={`${t("provider")}`} small noPadding>
            <CatalogSearch
              preload
              disableEnterText
              avoidReload
              inputProps={{
                required: true,
                style: {
                  marginBottom: 20,
                },
                mode: "underline",
              }}
              initialValue={service?.name}
              catalogName={"transporters"}
              onClickCallback={(option, open, setOpen, value, setValue) => {
                setServiceName(option?.name);
                onChange(index, "provider", {
                  target: {
                    value: option.uuid,
                  },
                });

                setTimeout(() => {
                  setOpen(false);
                }, 100);
              }}
            />
          </Label>
          <Row gap={20} wrap={matches}>
            <Label text={t("transport-equipment")} small noPadding>
              <LandSeaTransportEquipment
                handleItemClick={(option, open, setOpen, e) => {
                  onChange(index, "equipment", {
                    target: {
                      value: option.uuid,
                    },
                  });
                }}
                value={service?.equipment || []}
                autoCompleteProps={{
                  mode: "underline",
                  multiple: false,
                  readOnly: false,
                  required: true,
                }}
              />
            </Label>
            <Label text={`${t("units")}`} noPadding small>
              <InputText
                required
                min={0}
                placeholder={t("write-here")}
                step={"1"}
                value={service?.units}
                onChange={(e) => {
                  onChange(index, "units", {
                    target: {
                      value: e.target.value,
                    },
                  });
                }}
                mode="underline"
              />
            </Label>
          </Row>
          <Row gap={20} wrap={matches}>
            <Label text={`${t("unit-price")} (€)`} small noPadding>
              <InputNumber
                required
                min={0}
                step={".01"}
                placeholder={t("write-here")}
                value={service?.unitPrice}
                onChange={(e) => {
                  onChange(index, "unitPrice", {
                    target: {
                      value: e.target.value,
                    },
                  });
                }}
                mode="underline"
              />
            </Label>
            <Label text={`${t("total-cost")} (€)`} noPadding small>
              <InputText
                required
                readOnly
                min={0}
                placeholder={t("write-here")}
                value={
                  isNaN(service?.units * service?.unitPrice)
                    ? 0
                    : service?.units * service?.unitPrice
                }
                // value={service?.totalCost}
                // onChange={(e) => {
                //   onChange(index, "totalCost", {
                //     target: {
                //       value: e.target.value,
                //     },
                //   });
                // }}
                mode="underline"
              />
            </Label>
          </Row>
          <Row gap={20} wrap={matches}>
            <Label text={t("transit_time_in_days")} small noPadding>
              <InputNumber
                required
                min={1}
                placeholder={t("write-here")}
                mode="underline"
                value={service?.transitDays}
                onChange={(e) => {
                  onChange(index, "transitDays", {
                    target: {
                      value: e.target.value,
                    },
                  });
                }}
              />
            </Label>
            <Label text={t("expire-date")} noPadding>
              <InputDate
                required
                placeholder={t("write-here")}
                value={service?.expirationDate}
                handleOnChange={(e, open, setOpen) => {
                  onChange(index, "expirationDate", {
                    target: {
                      value: e?.getTime(),
                    },
                  });
                }}
                languageCode={i18n.language}
                mode="underline"
              />
            </Label>
          </Row>
          {typeof onDelete === "function" && (
            <RemoveServiceButton
              onClick={(e) => {
                e?.preventDefault();
                onDelete(index);
              }}
            />
          )}
        </Paper>
      </Flex>
    </Accordion>
  );
}

function RemoveServiceButton({ onClick = () => {} }) {
  const { t } = useTranslation(["common"]);
  return (
    <ClearButton onClick={onClick}>
      <Row gap={5} align="center">
        <Icon
          name="delete"
          color={WARM_NEUTRAL_50}
          style={{ fontSize: "18px" }}
        />
        <Typography
          noMargin
          style={{ color: WARM_NEUTRAL_50, textDecoration: "underline" }}
        >
          {t("remove_service")}
        </Typography>
      </Row>
    </ClearButton>
  );
}

export default ServiceItem;
