import React, { cloneElement } from "react";
import { useParams } from "react-router-dom";
import LayoutTable from "../layout/table/Table";
import { useTranslation } from "../utils/hooks";

/**
 * Wrapper for pages that needs id param in tables.
 * @param {Object} props - Component properties.
 * @param {React.Component} props.Component - Component to pass id.
 * @param {Function} props.fnData - .
 * @param {Object} props.fnParameters - .
 * @returns {JSX.Element}
 */
const BusinessTableIDWrapper = ({ Component, fnData, fnParameters }) => {
  const { id } = useParams();
  const { i18n } = useTranslation();

  return (
    <LayoutTable
      fnData={fnData}
      fnParameters={{ uuidModule: id, locale: i18n.language, ...fnParameters }}
    >
      {cloneElement(<Component />, { id })}
    </LayoutTable>
  );
};

export default BusinessTableIDWrapper;
