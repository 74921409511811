import styles from "./InviteUser.module.scss";
import { useState } from "react";
import { useTranslation } from "../../../utils/hooks";
import {
  Modal,
  toastError,
  toastSuccess,
} from "@scrapadev/scrapad-front-sdk/dist/index";
import {
  useSetDataUser,
  useSignOut,
  useUser,
} from "../../../providers/AuthHooks";
import ModalText from "../../ui/modal/text/ModalText";
import InputEmail from "../../ui/input/email/Email";
import Label from "../../ui/input/label/Label";
import { handleCRUD } from "../../../utils/crud/functions";

/**
 * Modal for invite a new user to the organization
 * @param {Object} props - Component properties.
 * @param {Object} [props.open=false] - Open/close modal status.
 * @param {Function} props.setOpen - Setter function to open / close.
 * @returns {JSX.Element}
 */
const ModalInviteUser = ({ open = false, setOpen, ...props }) => {
  const { t, i18n } = useTranslation(["users", "common"]);
  const { uuidOrg } = props;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    await handleCRUD({
      endpoint: `org/${uuidOrg}/users?from_dashboard=true`,
      data: {
        email,
        lang: i18n.language,
      },
      method: "POST",
      setDataUser,
      signOut,
      errorCallback: (errorMessage) => {
        setLoading(false);
        setOpen(false);
        toastError(
          errorMessage,
          { toastId: "invite_user_error" },
          "sentiment_dissatisfied"
        );
      },
      successCallback: async () => {
        toastSuccess(
          "Invitación enviada con éxito",
          { toastId: "invite_user" },
          "check_circle"
        );
        setLoading(false);
        setOpen(false);
      },
    });
  };
  return (
    <Modal
      t={t}
      open={open}
      setOpen={setOpen}
      title={t("invite-user")}
      hasForm={true}
      onSubmit={handleSubmit}
      submitText={t("send-invite")}
      cancelText={t("cancel")}
      loading={loading}
      maxWidth={700}
      BodyComponent={
        <div className={styles["sc-business--invite"]}>
          <ModalText text={t("enter-user-email")} />
          <Label light htmlFor={"email"}>
            {t("business-email")}
          </Label>
          <InputEmail
            id="email"
            name="email"
            placeholder={t("write-here")}
            required
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
      }
    />
  );
};

export default ModalInviteUser;

